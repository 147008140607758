import React from 'react'
import styled from 'styled-components'
import { css } from 'styled-components'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'

interface FinancialFilterProps {
  onDropDownSelect?: (option?: number) => void
  // TODO - Set as react component
  Icon: any
  label: string
  select?: string[]
  disabled?: boolean
  default: string
  className?: any
  selectedIndex?: number
}

export const FinancialFilter = (props: FinancialFilterProps) => {
  let { Icon, label, select, disabled, className, selectedIndex } = props

  const onDropDownSelect = (event: any) => {
    if (props.onDropDownSelect) {
      if (event.target.value === props.default) props.onDropDownSelect(undefined)
      else props.onDropDownSelect(event.target.value)
    }
  }

  // TODO - Loading when props select is null
  return (
    <FilterContainer disabled={disabled} className={className}>
      <Icon />
      <FilterLabel>{label}</FilterLabel>

      <FilterSelect disabled={disabled} onChange={(event: any) => onDropDownSelect(event)} value={selectedIndex}>
        <option key={0} value={undefined}>
          {props.default}
        </option>
        {select &&
          select.map((value: any, index: number) => (
            <option key={index + 1} value={index}>
              {value}
            </option>
          ))}
      </FilterSelect>
    </FilterContainer>
  )
}

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  ${(props: any) =>
    props.disabled &&
    css`
      opacity: 0.2;
      cursor: not-allowed;
    `}
`

const FilterLabel = styled.span`
  flex: 1 1 auto;
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.4px;
  line-height: 38px;
`

const FilterSelect = styled.select`
  flex: 1 1 210px;
  padding: 12px 15px 12px 15px;

  box-sizing: border-box;
  border: 1px solid ${UMEColors.gray.light};
  border-radius: 4px;
  background-color: ${UMEColors.white.primary};

  // TODO - Not in design kit
  color: #3b3b3b;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: -0.47px;
  line-height: 14px;

  ${(props: any) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    // Select Image
    background-color: #fff;
    background: white url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>") no-repeat;
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .95em auto, 100%;
  }
`
