export const APPLICATION_PROFESSIONS = [
  'OUTROS',

  'ABASTECEDOR DE MONTAGEM',

  'ABASTECEDOR(A)',

  'ABATEDOR',

  'ACABADOR DE SUPERFÍCIES DE CONCRETO ARMADO',

  'ACESSOR COMERCIAL',

  'ACESSOR JURIDICO',

  'ACESSOR PARLAMENTAR',

  'ACESSORA COMERCIAL',

  'ACESSORA DE GABINETE',

  'ACOGUEIRA',

  'ACOGUEIRO',

  'ACOMPANHANTE',

  'ACOUGUEIRO',

  'ACOUGUEIRO (A)',

  'ADMINISTRACAO DE CAIXA',

  'ADMINISTRACAO FINANCEIRO',

  'ADMINISTRADOR (A)',

  'ADMINISTRADOR DE EMPRESAS',

  'ADMINISTRADOR DE IMOVEIS',

  'ADMINISTRADOR DE VENDAS',

  'ADMINISTRADOR FINANCEIRO',

  'ADMINISTRADOR(A)',

  'ADMINISTRADORA',

  'ADMINISTRATIVO',

  'ADOLESC APRENDIZ EDUC P/ FUTURO',

  'ADVOGADA',

  'ADVOGADO',

  'AFASTADO PELO INSS',

  'AFIADOR DE FERRAMENTAS',

  'AGENCIADOR',

  'AGENTE ADMINISTRATIVO',

  'AGENTE COMERCIAL',

  'AGENTE COMUNITARIA',

  'AGENTE COMUNITARIO',

  'AGENTE COMUNITARIO DE SAUDE',

  'AGENTE DE AEROPORTO',

  'AGENTE DE APOIO',

  'AGENTE DE ATENDIMENTO',

  'AGENTE DE CARGA',

  'AGENTE DE COBRANCA',

  'AGENTE DE CONSERVACAO',

  'AGENTE DE CONTROLE DE VETORES',

  'AGENTE DE EVENTOS',

  'AGENTE DE NEGOCIOS',

  'AGENTE DE PESQUISA',

  'AGENTE DE POLICIA',

  'AGENTE DE PORTARIA',

  'AGENTE DE SAUDE',

  'AGENTE DE SAÚDE PÚBLICA',

  'AGENTE DE SEGURANCA',

  'AGENTE DE SERVICO TECNICO',

  'AGENTE DE SERVICOS GERAIS',

  'AGENTE DE TELE COBRANCA',

  'AGENTE DE TRANSITO',

  'AGENTE DE TURISMO',

  'AGENTE DE VENDA DE SERVIÇOS ÀS EMPRESAS',

  'AGENTE DE VENDAS',

  'AGENTE DE VIAGEM',

  'AGENTE FUNERÁRIO',

  'AGENTE OPERACIONAL',

  'AGENTE PENITENCIARIO',

  'AGENTE PRISIONAL',

  'AGENTE SERVICOS ADMINISTRATIVOS',

  'AGENTE SERVICOS GERAIS',

  'AGENTE SOCIAL',

  'AGENTE TECNICO',

  'AGENTE TECNICO ADMINISTRATIVO',

  'AGENTE TECNICO DE PRODUCAO',

  'AGENTE TECNICO DE VENDAS',

  'AGRICULTOR',

  'AGRICULTOR(A)',

  'AGRONOMO',

  'AGROPECUARISTA',

  'AJUDANTE',

  'AJUDANTE DE BOMBA',

  'AJUDANTE DE CARGA',

  'AJUDANTE DE CONFEITEIRO',

  'AJUDANTE DE DISTRIBUICAO',

  'AJUDANTE DE ELETRICISTA',

  'AJUDANTE DE ENTREGA',

  'AJUDANTE DE EXPEDICAO',

  'AJUDANTE DE FABRICAÇAO',

  'AJUDANTE DE LAVANDERIA',

  'AJUDANTE DE MARCENEIRO',

  'AJUDANTE DE MOTORISTA',

  'AJUDANTE DE PEDREIRO',

  'AJUDANTE DE PRODUCAO',

  'AJUDANTE DE ZELADORIA',

  'AJUDANTE GERAL',

  'AJUDANTE MECANICO',

  'AJUSTADOR MECANICO',

  'AJUSTADOR MECÂNICO',

  'AJUSTADOR MONTADOR',

  'ALIMENTADOR DE LINHA DE PRODUÇAO',

  'ALINHADOR DE PNEUS',

  'ALINHADOR DE RODAS',

  'ALMOXARIFE',

  'ALMOXERIFADO',

  'ALUGUEL',

  'ANALISTA',

  'ANALISTA ADMINISTRATIVO',

  'ANALISTA COMERCIAL',

  'ANALISTA CONSULTOR',

  'ANALISTA CONTABIL',

  'ANALISTA DE ADMINISTRACAO PESSOAL',

  'ANALISTA DE COBRANCA',

  'ANALISTA DE CONTROLADORIA',

  'ANALISTA DE CREDITO',

  'ANALISTA DE CREDITO E COBRANCA',

  'ANALISTA DE ESTOQUE',

  'ANALISTA DE FINANCEIRO',

  'ANALISTA DE INFORMATICA',

  'ANALISTA DE LABORATORIO',

  'ANALISTA DE LOGISTICA',

  'ANALISTA DE MANUTENCAO',

  'ANALISTA DE MARKETING',

  'ANALISTA DE OPERACOES',

  'ANALISTA DE PLANEJAMENTO',

  'ANALISTA DE PRODUCAO',

  'ANALISTA DE QUALIDADE',

  'ANALISTA DE RECURSOS HUMANOS',

  'ANALISTA DE RH',

  'ANALISTA DE SISTEMAS',

  'ANALISTA DE SUPORTE',

  'ANALISTA DE TECNOLOGIA DA INFORMACAO',

  'ANALISTA DE TELECOMUNICACOES',

  'ANALISTA DE VENDAS',

  'ANALISTA FINANCEIRO',

  'ANALISTA FISCAL',

  'ANALISTA JUDICIARIO',

  'ANALISTA TECNICO',

  'APLICADOR DE INSULFILMES',

  'APOIO',

  'APONTADOR DE MAODEOBRA',

  'APONTADOR DE PRODUÇAO',

  'APOSENTADA',

  'APOSENTADA E DOMESTICA',

  'APOSENTADO',

  'APOSENTADO E ADVOGADO',

  'APOSENTADO E AUXILIAR DE ESCRITORIO',

  'APRENDIZ DE ELETRICISTA',

  'ARMADOR DE CONCRETO ARMADO',

  'ARMADOR DE ESTRUTURA DE CONCRETO',

  'ARMADOR DE FERRAGENS',

  'ARMAZENISTA',

  'ARQUITETA',

  'ARQUITETO',

  'ARQUIVISTA',

  'ARRUMADEIRA',

  'ARRUMADOR',

  'ARTE FINALISTA',

  'ARTE GRAFICO',

  'ARTESA',

  'ARTESAN',

  'ARTESAO',

  'ARTEZA',

  'ARTIFICE',

  'ARTISTA PLASTICA',

  'ASSESOR DE CONTROLE INTERNO',

  'ASSESSOR',

  'ASSESSOR COMERCIAL',

  'ASSESSOR JURIDICO',

  'ASSESSOR PARLAMENTAR',

  'ASSESSOR TECNICO',

  'ASSESSORA',

  'ASSESSORA DE GABINETE',

  'ASSESSORA DE VENDAS',

  'ASSESSORA ESPECIAL',

  'ASSISTENCIA DE MONITORAMENTO',

  'ASSISTENTE ADMINISTRATIVO',

  'ASSISTENTE ADMINISTRATIVO BILINGUE JUNIOR',

  'ASSISTENTE ATENDIMENTO',

  'ASSISTENTE COMERCIAL',

  'ASSISTENTE CONTABIL',

  'ASSISTENTE DE ATENDIMENTO',

  'ASSISTENTE DE BACKOFFICE',

  'ASSISTENTE DE COBRANCA',

  'ASSISTENTE DE COMPRAS',

  'ASSISTENTE DE CONTROLADORIA',

  'ASSISTENTE DE DEPARTAMENTO PESSOAL',

  'ASSISTENTE DE DESING',

  'ASSISTENTE DE EDUCACAO',

  'ASSISTENTE DE FATURAMENTO',

  'ASSISTENTE DE GERENCIA',

  'ASSISTENTE DE LABORATORIO',

  'ASSISTENTE DE LOGISTICA',

  'ASSISTENTE DE MARKETING',

  'ASSISTENTE DE NEGOCIOS',

  'ASSISTENTE DE OPERACOES',

  'ASSISTENTE DE PADARIA',

  'ASSISTENTE DE PCP',

  'ASSISTENTE DE PEDAGOGIA',

  'ASSISTENTE DE PRODUCAO',

  'ASSISTENTE DE PUBLICIDADE',

  'ASSISTENTE DE QUALIDADE',

  'ASSISTENTE DE RH',

  'ASSISTENTE DE SAUDE',

  'ASSISTENTE DE SUPORTE TECNICO',

  'ASSISTENTE DE TESOURARIA',

  'ASSISTENTE DE TI',

  'ASSISTENTE DE TRANSPORTES',

  'ASSISTENTE DE VENDAS',

  'ASSISTENTE DE VENDAS (FINANCEIRO)',

  'ASSISTENTE EDUCACIONAL IV',

  'ASSISTENTE FINANCEIRO',

  'ASSISTENTE FISCAL',

  'ASSISTENTE JURIDICO',

  'ASSISTENTE OPERACIONAL',

  'ASSISTENTE PARLAMENTAR',

  'ASSISTENTE PESSOAL',

  'ASSISTENTE SOCIAL',

  'ASSISTENTE TECNICO(A)',

  'ATELIER DE COSTURA',

  'ATENDENTE',

  'ATENDENTE COMERCIAL',

  'ATENDENTE DE CONSULTORIO',

  'ATENDENTE DE CRECHE',

  'ATENDENTE DE CREDITO',

  'ATENDENTE DE ENFERMAGEM',

  'ATENDENTE DE FARMACIA',

  'ATENDENTE DE LANCHONETE',

  'ATENDENTE DE LAVANDERIA',

  'ATENDENTE DE LOJA',

  'ATENDENTE DE RESTAURANTE',

  'ATENDENTE DE SUPERMERCADO',

  'ATENDENTE DE TELEMARKETING',

  'ATENDENTE DE VENDAS',

  'ATENDENTE E TELE OPERADORA',

  'ATENDENTE ESCOLAR',

  'ATENDENTE INFANTIL',

  'ATENDIMENTO AO CLIENTE',

  'ATLETA',

  'ATLETA PROFISSIONAL DE FUTEBOL',

  'AUDITOR',

  'AUTONOMA',

  'AUTONOMO',

  'AUX. CONTROLE DE QUALIDADE',

  'AUXILAR DE ATENDIMENTO',

  'AUXILIAR',

  'AUXILIAR ADMINISTRATIVO',

  'AUXILIAR ADMINISTRATIVO ESCOLAR',

  'AUXILIAR ADMINISTRATIVO II',

  'AUXILIAR ADMINISTRATIVO III',

  'AUXILIAR COMERCIAL',

  'AUXILIAR CONSULTORIO DENTARIO',

  'AUXILIAR CONTABIL',

  'AUXILIAR CONTROLADOR INSPECAO',

  'AUXILIAR DE ACABAMENTO',

  'AUXILIAR DE ALMOXARIFADO',

  'AUXILIAR DE ARMAZEM',

  'AUXILIAR DE ATENDIMENTO',

  'AUXILIAR DE BIBLIOTECA',

  'AUXILIAR DE CABELEIREIRO',

  'AUXILIAR DE CAIXA',

  'AUXILIAR DE CALDEIRARIA',

  'AUXILIAR DE CARGA',

  'AUXILIAR DE CARTORIO',

  'AUXILIAR DE CLASSIFICACAO',

  'AUXILIAR DE COBRANCA',

  'AUXILIAR DE COMPRAS',

  'AUXILIAR DE CONFECCAO',

  'AUXILIAR DE CONFEITEIRO',

  'AUXILIAR DE CONSTRUCAO',

  'AUXILIAR DE CONSULTORIO',

  'AUXILIAR DE CONSULTORIO DENTARIO',

  'AUXILIAR DE CONTABILIDADE',

  'AUXILIAR DE CONTROLE DE QUALIDADE',

  'AUXILIAR DE COORDENACAO',

  'AUXILIAR DE COPA',

  'AUXILIAR DE CORTE',

  'AUXILIAR DE COSTURA',

  'AUXILIAR DE COZINHA',

  'AUXILIAR DE CREDITO E CADASTRO',

  'AUXILIAR DE CREDITO E COBRANCA',

  'AUXILIAR DE DENTISTA',

  'AUXILIAR DE DEPARTAMENTO PESSOAL',

  'AUXILIAR DE DEPOSITO',

  'AUXILIAR DE DESENVOLVIMENTO INFANTIL',

  'AUXILIAR DE DESINER',

  'AUXILIAR DE EDUCACAO',

  'AUXILIAR DE ELETRECISTA',

  'AUXILIAR DE ENFERMAGEM',

  'AUXILIAR DE ENFERMAGEM DO TRABALHO',

  'AUXILIAR DE ENGARREGADO',

  'AUXILIAR DE ENGENHARIA',

  'AUXILIAR DE ENTREGA',

  'AUXILIAR DE ESCRITA FISCAL',

  'AUXILIAR DE ESCRITORIO',

  'AUXILIAR DE ESTAMPARIA',

  'AUXILIAR DE ESTOQUE',

  'AUXILIAR DE EVENTOS',

  'AUXILIAR DE EXPEDICAO',

  'AUXILIAR DE FARMACIA',

  'AUXILIAR DE FARMÁCIA',

  'AUXILIAR DE FATURAMENTO',

  'AUXILIAR DE FISCAL DE CAIXA',

  'AUXILIAR DE FUNILARIA',

  'AUXILIAR DE GERENTE',

  'AUXILIAR DE HIGIENE',

  'AUXILIAR DE IMPRESSORA',

  'AUXILIAR DE INFORMATICA',

  'AUXILIAR DE INSTALACOES',

  'AUXILIAR DE JARDINEIRO',

  'AUXILIAR DE LABORATORIO',

  'AUXILIAR DE LAVANDERIA',

  'AUXILIAR DE LIMPEZA',

  'AUXILIAR DE LIMPEZA PREDIAL',

  'AUXILIAR DE LOJA',

  'AUXILIAR DE MANIPULACAO',

  'AUXILIAR DE MANUFATURA',

  'AUXILIAR DE MANUTENCAO',

  'AUXILIAR DE MAQUINA',

  'AUXILIAR DE MARCENEIRO',

  'AUXILIAR DE MARMORISTA',

  'AUXILIAR DE MECANICA',

  'AUXILIAR DE MECANICO',

  'AUXILIAR DE MONTAGEM',

  'AUXILIAR DE MOTORISTA',

  'AUXILIAR DE NUTRICAO',

  'AUXILIAR DE ODONTOLOGIA',

  'AUXILIAR DE OPERACAO',

  'AUXILIAR DE OURIVES',

  'AUXILIAR DE PADEIRO',

  'AUXILIAR DE PERECIVEIS',

  'AUXILIAR DE PESSOAL',

  'AUXILIAR DE PINTOR',

  'AUXILIAR DE PINTURA',

  'AUXILIAR DE PROCESSAMENTO',

  'AUXILIAR DE PRODUCAO',

  'AUXILIAR DE PROTESE',

  'AUXILIAR DE QUALIDADE',

  'AUXILIAR DE RAMPA',

  'AUXILIAR DE RECICLAGEM',

  'AUXILIAR DE RECURSOS HUMANOS',

  'AUXILIAR DE REVISAO',

  'AUXILIAR DE RH',

  'AUXILIAR DE SALA',

  'AUXILIAR DE SAUDE',

  'AUXILIAR DE SECRETARIA',

  'AUXILIAR DE SERIGRAFIA',

  'AUXILIAR DE SERRALHEIRO',

  'AUXILIAR DE SERVICO INTERNO/EXTERNO',

  'AUXILIAR DE SERVICOS ADMINISTRATIVOS',

  'AUXILIAR DE SERVICOS ESCOLARES',

  'AUXILIAR DE SERVICOS GERAIS',

  'AUXILIAR DE SUPERVISAO',

  'AUXILIAR DE TALHACAO',

  'AUXILIAR DE TECELAO',

  'AUXILIAR DE TINTURARIA',

  'AUXILIAR DE TOPOGRAFIA',

  'AUXILIAR DE TRAFEGO',

  'AUXILIAR DE TRANSPORTE',

  'AUXILIAR DE VENDAS',

  'AUXILIAR DE VIDRACEIRO',

  'AUXILIAR DENTISTA',

  'AUXILIAR DEPARTAMENTO PESSOAL',

  'AUXILIAR EDUCACIONAL',

  'AUXILIAR ELETRONICO',

  'AUXILIAR ESCOLAR',

  'AUXILIAR FINANCEIRO',

  'AUXILIAR FISCAL',

  'AUXILIAR GERAL',

  'AUXILIAR MECANICO',

  'AUXILIAR METALURGICO',

  'AUXILIAR ODONTOLOGICA',

  'AUXILIAR ODONTOLOGICO',

  'AUXILIAR OPERACIONAL',

  'AUXILIAR PEDAGOGICO',

  'AUXILIAR TECNICO',

  'AUXILIAR TECNICO DE RADIOLAGIA',

  'AUXILIAR TECNICO MANUTENCAO',

  'AUXILIAR TORNEIRO MECANICO',

  'AUXILIO DOENCA',

  'AUXLIAR DE LIMPEZA',

  'AVICULTOR',

  'AZULEJISTA',

  'BABA',

  'BALANCEIRO',

  'BALCONISTA',

  'BALCONISTA III',

  'BANCARIA',

  'BANCARIO',

  'BANHISTA',

  'BANHO E TOSA',

  'BARBEIRO',

  'BARMAM',

  'BARMAN',

  'BIBLIOTECARIA',

  'BIOLOGA',

  'BIOMEDICA',

  'BLOQUISTA',

  'BOBINADOR',

  'BOLSISTA',

  'BOMBEIRO',

  'BORDADEIRA',

  'BORDADOR',

  'BORRACHEIRO',

  'CABELEIREIRO',

  'CABELELEIRA',

  'CABELELEIRO (A)',

  'CABELEREIRA',

  'CABELEREIRO',

  'CABISTA DE TELECOMUNICACOES',

  'CABO',

  'CAIXA',

  'CAIXA DE BANCO',

  'CAIXA II',

  'CALCETEIRO',

  'CALDEIREIRO',

  'CALDEREIRO',

  'CALHEIRO',

  'CAMAREIRA',

  'CAMAREIRO (HOTEL)',

  'CAMINHONEIRO',

  'CANTOR',

  'CANTOR (A)',

  'CAPATAZ DE EXPLORAÇAO AGRÍCOLA',

  'CAPATAZ DE EXPLORAÇAO DE PECUÁRIA',

  'CARGO DE COMISSAO',

  'CARPINTEIRO',

  'CARPINTEIRO (OBRAS)',

  'CARREGADOR (ARMAZÉM)',

  'CARREGADOR (VEÍCULOS DE TRANSPORTES TERRESTRES)',

  'CARTAZISTA',

  'CARTEIRO',

  'CASEIRO',

  'CATADOR DE PAPELAO',

  'CERAMISTA',

  'CERIMONIAL',

  'CHACREIRO',

  'CHAPEADOR',

  'CHAPEIRO (A)',

  'CHAPISTA DE LANCHONETE',

  'CHAVEIRO',

  'CHEF DE COZINHA',

  'CHEFE',

  'CHEFE ADMINISTRATIVO',

  'CHEFE DE CAIXA',

  'CHEFE DE CONFEITARIA',

  'CHEFE DE COZINHA',

  'CHEFE DE DIVISAO',

  'CHEFE DE ESCRITÓRIO (SERVIÇOS GERAIS)',

  'CHEFE DE GABINETE',

  'CHEFE DE LOJA',

  'CHEFE DE MANUTENCAO',

  'CHEFE DE OFICINA',

  'CHEFE DE PRODUCAO',

  'CHEFE DE SETOR',

  'CHURRASQUEIRO',

  'CINEGRAFISTA',

  'CIRURGIAO DENTISTA',

  'CIRURGIAO-DENTISTA',

  'CLASSIFICADOR',

  'COBRADOR',

  'COBRADOR DE TRANSPORTE COLETIVO (EXCETO TREM)',

  'COBRADORA',

  'COBRANCA',

  'COLETADOR',

  'COLETOR',

  'COLHEDOR',

  'COLOCADOR',

  'COLOCADOR DE MARMORES',

  'COLORISTA',

  'COMERCIANTE',

  'COMERCIANTE ATACADISTA',

  'COMERCIANTE VAREJISTA',

  'COMERCIARIA',

  'COMERCIARIO',

  'COMPRADOR (COMÉRCIO ATACADISTA E VAREJISTA)',

  'COMPRADOR(A)',

  'COMPRADORA',

  'COMUNICACAO VISUAL',

  'CONDUTOR DE MAQUINA',

  'CONDUTOR DE MOTO ENTRGA',

  'CONFEITEIRA',

  'CONFEITEIRO',

  'CONFEITEIRO (A)',

  'CONFERENTE',

  'CONFERENTE DE CARGAS',

  'CONFERENTE DE EXPEDICAO',

  'CONFERENTE DE LOGISTICA',

  'CONFERENTE DE MATERIAL',

  'CONSELHEIRO TUTELAR',

  'CONSERTADOR DE CALCADOS',

  'CONSERTADOR DE REFRIGERACAO',

  'CONSTRUTOR',

  'CONSTRUTOR CIVIL',

  'CONSTRUTOR DE OBRAS',

  'CONSULTOR',

  'CONSULTOR COMERCIAL',

  'CONSULTOR DE CREDITO',

  'CONSULTOR DE NEGOCIOS',

  'CONSULTOR DE RELACIONAMENTO COMERCIAL',

  'CONSULTOR DE VENDAS',

  'CONSULTOR TECNICO',

  'CONSULTORA',

  'CONSULTORA DE BELEZA',

  'CONTABILISTA',

  'CONTADOR',

  'CONTADORA (O)',

  'CONTINUO',

  'CONTRA MESTRE',

  'CONTROLADOR DE EMBARQUE',

  'CONTROLADOR DE ESTOQUE',

  'CONTROLADOR DE OPERACOES',

  'CONTROLADOR DE PRODUCAO',

  'CONTROLADOR DE QUALIDADE',

  'CONTROLADOR DE TRAFEGO',

  'CONTROLADOR E/S',

  'CONTROLE DE QUALIDADE',

  'COORDENADOR',

  'COORDENADOR (A) DE VENDAS',

  'COORDENADOR ADMINISTRATIVO',

  'COORDENADOR DE ENSINO',

  'COORDENADOR DE EQUIPE',

  'COORDENADOR DE EVENTOS',

  'COORDENADOR DE LOGISTICA',

  'COORDENADOR(A)',

  'COORDENADORA',

  'COORDENADORA PEDAGOGICA',

  'COPEIRA',

  'COPEIRO',

  'COPEIRO (HOSPITAL)',

  'COPERA',

  'CORDENADOR OPERACIONAL',

  'CORDENADORA CONTABIL',

  'CORRETOR DE IMOVEIS',

  'CORRETOR DE SEGUROS',

  'CORRETOR(A)',

  'CORRETORA DE IMOVEIS',

  'CORRETORA DE SEGUROS',

  'CORTADEIRA',

  'CORTADOR',

  'CORTE',

  'COSTURA',

  'COSTUREIRA',

  'COSTUREIRA AUTONOMA',

  'COSTUREIRA E ARTESA',

  'COSTUREIRO',

  'COVEIRO',

  'COZINHADOR',

  'COZINHEIRA',

  'COZINHEIRO',

  'COZINHEIRO CHEFE',

  'CREDIARISTA',

  'CUIDADOR DE IDOSO',

  'DATILOGRAFO',

  'DECORADOR DE INTERIORES',

  'DECORADOR(A)',

  'DENTISTA',

  'DEPARTAMENTO DE FATURAMENTO',

  'DEPARTAMENTO PESSOAL',

  'DEPILADORA',

  'DEPOSITO',

  'DESCARREGADOR',

  'DESEMPREGADO',

  'DESENHISTA',

  'DESENHISTA INDUSTRIAL (DESIGNER)',

  'DESENHISTA PROJETISTA',

  'DESENHISTA TECNICO',

  'DESIGNER',

  'DESOSSADOR',

  'DESPACHANTE DO DETRAN',

  'DETETIZADOR',

  'DIARISTA',

  'DIGITADOR (A)',

  'DIGITADORA',

  'DIRETOR',

  'DIRETOR ADMINISTRATIVO',

  'DIRETOR COMERCIAL',

  'DIRETOR DE DEPARTAMENTO',

  'DIRETORA',

  'DISTRIBUIDOR',

  'DISTRIBUIDORA DE SERVICOS',

  'DIVULGADOR',

  'DO LAR',

  'DOBRADEIRA',

  'DOCEIRA',

  'DOMADOR',

  'DOMESTICA',

  'DOMESTICA E DIARISTA',

  'EDITOR DE IMAGENS',

  'EDITOR DE VIDEO',

  'EDUCADOR(A)',

  'EDUCADORA',

  'EDUCADORA PEDAGOGICA',

  'EDUCADORA SOCIAL',

  'ELETRECISTA',

  'ELETRECISTA INDUSTRIAL',

  'ELETRICISTA',

  'ELETRICISTA AUTOMOVEIS',

  'ELETRICISTA DE AUTOMOVEIS',

  'ELETRICISTA DE INSTALAÇOES',

  'ELETRICISTA DE MANUTENÇAO',

  'ELETROMECANICO',

  'ELETROTECNICO',

  'EMBALADOR',

  'EMBALADOR (A)',

  'EMISSOR DE PASSAGENS',

  'EMPACOTADOR(A)',

  'EMPACOTADORA',

  'EMPILHADOR',

  'EMPREGADA DOMESTICA',

  'EMPREGADO DOMÉSTICO',

  'EMPREITEIRO',

  'EMPREITERO DE OBRAS',

  'EMPRESARIA',

  'EMPRESARIO (A)',

  'ENCANADOR',

  'ENCAREGADA DE PRODUCAO',

  'ENCAREGADO DE DEPOSITO',

  'ENCAREGADO DE PRODUCAO',

  'ENCAREGADO DE SERVICOS',

  'ENCAREGADO DO ACOUGUE',

  'ENCARREAGADO GERAL',

  'ENCARREGADA DE COMPRAS',

  'ENCARREGADA DE DEPARTAMENTO FISCAL',

  'ENCARREGADA DE OBRAS',

  'ENCARREGADA DE PRODUCAO',

  'ENCARREGADA DE VENDAS',

  'ENCARREGADA DE ZELADORAS',

  'ENCARREGADA(O) DE SERVICOS GERAIS',

  'ENCARREGADO (A)',

  'ENCARREGADO ADMINISTRATIVO',

  'ENCARREGADO DE ALMOXARIFADO',

  'ENCARREGADO DE ASSISTENCIA TECNICA',

  'ENCARREGADO DE DEPARTAMENTO PESSOAL',

  'ENCARREGADO DE EMBALAGEM',

  'ENCARREGADO DE EXPEDICAO',

  'ENCARREGADO DE LOJA',

  'ENCARREGADO DE MANUTENCAO',

  'ENCARREGADO DE OBRAS',

  'ENCARREGADO DE PRODUCAO',

  'ENCARREGADO DE SETOR',

  'ENCARREGADO(A)',

  'ENCOSTADO',

  'ENFERMEIRA',

  'ENFERMEIRO',

  'ENGENHEIRA',

  'ENGENHEIRO',

  'ENGENHEIRO AGRONOMO',

  'ENGENHEIRO CIVIL',

  'ENGENHEIRO ELETRICISTA',

  'ENGENHEIRO MECANICO',

  'ENTREGADOR',

  'ESCREVENTE',

  'ESCRITURARIA',

  'ESCRITURARIO',

  'ESCRIVAO',

  'ESMERILHADOR',

  'ESTAGIARIA',

  'ESTAGIARIO',

  'ESTAMPADOR',

  'ESTAMPADOR DE TECIDOS',

  'ESTETICISTA',

  'ESTETICISTA DE ANIMAIS',

  'ESTILISTA',

  'ESTIVADOR',

  'ESTOFADOR DE MÓVEIS',

  'ESTOFADOR DE VEÍCULOS',

  'ESTOQUISTA',

  'ESTUDANTE',

  'ESTUDANTE UNIVERSITARIO',

  'EXECUTIVO DE VENDAS',

  'EXPEDIDOR DE MATERIAIS',

  'EXPEDIDOR(A)',

  'EXPEDITOR DE QUALIDADE',

  'EXTRUSOR',

  'FACCIONISTA',

  'FACILITADOR',

  'FARMACEUTICA',

  'FARMACEUTICO',

  'FARMACEUTICO (A)',

  'FATURISTA',

  'FAXINEIRA',

  'FAXINEIRO',

  'FEIRANTE',

  'FERRAMENTEIRO',

  'FERREIRO',

  'FINANCEIRO',

  'FISCAL',

  'FISCAL DE CAIXA',

  'FISCAL DE LOJA',

  'FISCAL DE OBRAS',

  'FISCAL DE SEGURANCA',

  'FISIOTERAPEUTA',

  'FLORISTA',

  'FOLGUISTA',

  'FONOAUDIOLOGA',

  'FORNEIRO',

  'FOTOGRAFO',

  'FOTOGRAFO PUBLICITÁRIO',

  'FREE LANCE',

  'FRENTE DE CAIXA',

  'FRENTISTA',

  'FRESADOR (FRESADORA UNIVERSAL)',

  'FRESADOR CNC',

  'FRETISTA',

  'FUNCIONAQRIA PUBLICA',

  'FUNCIONARIA PUBLICA',

  'FUNCIONARIO (A) PUBLICO',

  'FUNCIONARIO PUBLICO',

  'FUNDIDOR DE METAIS',

  'FUNILEIRO',

  'GALVANIZADOR',

  'GARAGISTA',

  'GARCOM',

  'GARCON',

  'GARCONETE',

  'GARI',

  'GARIMPEIRO',

  'GERENCIADOR',

  'GERENTE',

  'GERENTE ADJUNTO',

  'GERENTE ADMINISTRATIVO',

  'GERENTE COMERCIAL',

  'GERENTE DE ADIMINISTRACAO E VENDAS',

  'GERENTE DE AREA',

  'GERENTE DE COMPRAS',

  'GERENTE DE LOJA',

  'GERENTE DE MANUTENCAO',

  'GERENTE DE OPERAÇAO',

  'GERENTE DE PRODUCAO',

  'GERENTE DE PRODUÇAO',

  'GERENTE DE RESTAURANTE',

  'GERENTE DE RH',

  'GERENTE DE VENDAS',

  'GERENTE FINANCEIRO',

  'GERENTE GERAL',

  'GERENTE OPERACIONAL',

  'GESSEIRO',

  'GESTOR DE CONTRATOS',

  'GESTOR DE ESTOQUE',

  'GESTOR DE NOGOCIOS',

  'GESTOR DE SERVICOS GERAIS',

  'GOVERNANTA (HOTELARIA)',

  'GOVERNANTA(CASA DE FAMILIA)',

  'GRAFICO',

  'GUARDA DE SEGURANCA',

  'GUARDA MUNICIPAL',

  'GUINCHEIRO',

  'HIGIENIZACAO',

  'HORTICULTOR',

  'IMPERMEABILIZADOR',

  'IMPRESSOR',

  'IMPRESSOR (SERIGRAFIA)',

  'IMPRESSOR FLEXOGRAFICO',

  'INSPETOR',

  'INSPETOR DE ALUNOS',

  'INSPETOR DE QUALIDADE',

  'INSPETOR DE SEGURANCA',

  'INSPETOR DE VIGILANCIA PATRIMONIAL',

  'INSPETORA',

  'INSTALADOR',

  'INSTALADOR ACESSORIOS',

  'INSTALADOR DE ACESSORIOS',

  'INSTALADOR DE ANTENAS',

  'INSTALADOR DE AR CONDICIONADO',

  'INSTALADOR DE SOM AUTOMOTIVO',

  'INSTALADOR DE TELEFONE',

  'INSTALADOR HIDRAULICO',

  'INSTRUMENTADOR CIRURGICO',

  'INSTRUTOR',

  'INSTRUTOR (A)',

  'INSTRUTOR DE DIRECAO',

  'INSTRUTOR DE ENSINO',

  'INSTRUTOR DE INFORMATICA',

  'INSTRUTOR DE TRANSITO',

  'INSTRUTORA',

  'INVESTIGADOR DE POLICIA',

  'JARDINEIRO',

  'JOGADOR DE FUTEBOL',

  'JORNALISTA',

  'LABORATORISTA',

  'LACTARISTA',

  'LAMINADOR',

  'LANCHEIRO',

  'LATOEIRO',

  'LATOEIRO-PINTOR',

  'LAVADEIRA',

  'LAVADEIRA (O)',

  'LAVADOR DE MAQUINAS',

  'LAVADOR DE VEICULOS',

  'LAVADOR DE VEÍCULOS',

  'LAVADOR(A)',

  'LAVRADOR',

  'LEITURISTA',

  'LIDER',

  'LIDER DE ALMOXARIFADO',

  'LIDER DE APOIO LOGISTICO',

  'LIDER DE CELULA',

  'LIDER DE OPERACAO',

  'LIDER DE PADARIA',

  'LIDER DE PRODUCAO',

  'LIDER DE SECAO',

  'LIDER DE SETOR',

  'LIDER DE VENDAS',

  'LIMPADOR A SECO',

  'LIMPADOR DE PISCINAS',

  'LIXADOR DE MOVEIS',

  'LOCUTOR',

  'LOGISTICA',

  'LUBRIFICADOR',

  'LUBRIFICADOR DE VEÍCULOS AUTOMOTORES (EXCETO EMBARCAÇOES)',

  'MADEREIRO',

  'MAGAREFE',

  'MANICURE',

  'MANICURE E PODOLOGA',

  'MANICURI',

  'MANIPULADORA',

  'MANOBRISTA',

  'MANTENEDOR I',

  'MANUAL',

  'MANUTENCAO',

  'MANUTENCAO PREDIAL',

  'MAQUIADOR',

  'MAQUINISTA DE TREM',

  'MARCENEIRO',

  'MARCINEIRO',

  'MARINHEIRO',

  'MARKETING',

  'MARMORISTA',

  'MASSAGISTA',

  'MASSEIRO (MASSAS ALIMENTÍCIAS)',

  'MASSOTERAPEUTA',

  'MECANICO',

  'MECANICO DE MANUTENCAO',

  'MECANICO DE MANUTENCAO DE AUTOMÓVEIS',

  'MECANICO DE MANUTENCAO DE MOTOCICLETAS',

  'MECANICO DE MANUTENÇAO DE BICICLETAS E VEÍCULOS SIMILARES',

  'MECANICO DE MANUTENÇAO DE MÁQUINAS',

  'MECANICO DE REFRIGERACAO',

  'MECANICO GERAL',

  'MECANICO INDUSTRIAL',

  'MECANICO MONTADOR LIDER',

  'MEDICA',

  'MEDICA VETERINARIA',

  'MEDICO',

  'MEDICO VETERINARIO',

  'MEIO OFICIAL',

  'MEIO OFICIAL DE PEDREIRO',

  'MEIO OFICIAL MARCENEIRO',

  'MENOR APRENDIZ',

  'MENSAGEIRO',

  'MERENDEIRA',

  'MESTRE (CONSTRUÇAO CIVIL)',

  'MESTRE DE OBRAS',

  'METALURGICA',

  'METALURGICO',

  'MICRO EMPRESARIO',

  'MICROEMPRESARIA',

  'MICROEMPRESARIO',

  'MILITAR',

  'MILITAR PATENTE',

  'MINISTRO DE CULTO RELIGIOSO',

  'MISSIONARIO',

  'MODELISTA DE ROUPAS',

  'MODELO DE MODAS',

  'MOLDADOR',

  'MOLEIRO',

  'MONITOR(A)',

  'MONITORA',

  'MONITORADOR',

  'MONTADOR',

  'MONTADOR AUTOMOTIVO',

  'MONTADOR DE ANDAIMES (EDIFICAÇOES)',

  'MONTADOR DE EQUIPAMENTOS ELÉTRICOS',

  'MONTADOR DE ESTRUTURAS METÁLICAS',

  'MONTADOR DE MOVEIS',

  'MONTADOR DE MÁQUINAS',

  'MONTADOR DE PRODUCAO',

  'MONTADORA',

  'MONTAGEM',

  'MOTO BOY',

  'MOTO TAXI',

  'MOTOBOY',

  'MOTOCICLISTA (TRANSPORTE DE MERCADORIAS)',

  'MOTOQUEIRO',

  'MOTORISTA',

  'MOTORISTA AUTONOMA',

  'MOTORISTA CARRETEIRO',

  'MOTORISTA DE CAMINHAO',

  'MOTORISTA DE CARRO DE PASSEIO',

  'MOTORISTA DE ENTREGA',

  'MOTORISTA DE FURGAO OU VEÍCULO SIMILAR',

  'MOTORISTA DE ONIBUS FEDERAIS',

  'MOTORISTA DE TÁXI',

  'MOTORISTA DE ÔNIBUS',

  'MOTORISTA III',

  'MOTORISTA OPERADOR',

  'MOTORISTA PARTICULAR',

  'MOVIMENTADOR DE PRODUTO FIACAO',

  'MUSICO',

  'MUSICO DJ',

  'NEGOCIADOR INTERNO',

  'NUTRICIONISTA',

  'OFFICE BOY',

  'OFFICE-BOY',

  'OFICE BOY',

  'OFICIAL ADMINISTRATIVO',

  'OFICIAL DE ACABAMENTO',

  'OFICIAL DE JUSTICA',

  'OFICIAL DE LIMPEZA JR',

  'OFICIAL DE MANUTENCAO',

  'OFICIAL DE PRODUCAO I',

  'OLEIRO (FABRICAÇAO DE TELHAS)',

  'OLEIRO (FABRICAÇAO DE TIJOLOS)',

  'OPERADOR',

  'OPERADOR (A) DE LOCACAO',

  'OPERADOR CENTRAL DE COMUNICACAO',

  'OPERADOR COMERCIAL',

  'OPERADOR DE ACABAMENTO (INDÚSTRIA GRÁFICA)',

  'OPERADOR DE ACABAMENTO DE PEÇAS FUNDIDAS',

  'OPERADOR DE BETONEIRA',

  'OPERADOR DE CAIXA',

  'OPERADOR DE CALANDRA',

  'OPERADOR DE CALDEIRA',

  'OPERADOR DE CAMERA',

  'OPERADOR DE CARGA',

  'OPERADOR DE CARREGADEIRA',

  'OPERADOR DE CNC',

  'OPERADOR DE COBRANÇAS',

  'OPERADOR DE COLHEDEIRA',

  'OPERADOR DE CORTE E SOLDA',

  'OPERADOR DE CORTE E VINCO',

  'OPERADOR DE EMPILHADEIRA',

  'OPERADOR DE ESCAVADEIRA',

  'OPERADOR DE ESTACAO DE AGUA E ESGOTO',

  'OPERADOR DE ESTAMPARIA I',

  'OPERADOR DE ETA',

  'OPERADOR DE EXTRUSORA',

  'OPERADOR DE FABRICACAO',

  'OPERADOR DE FIACAO',

  'OPERADOR DE FORNOS',

  'OPERADOR DE GUINDASTE (MÓVEL)',

  'OPERADOR DE IMPRESSAO',

  'OPERADOR DE INJETORA',

  'OPERADOR DE LOGISTICA',

  'OPERADOR DE LOJA',

  'OPERADOR DE MANUFATURA',

  'OPERADOR DE MAQUINA COPIADORA',

  'OPERADOR DE MAQUINA CORTE DOBRA',

  'OPERADOR DE MAQUINA DE BORDADO',

  'OPERADOR DE MAQUINA III',

  'OPERADOR DE MAQUINAS',

  'OPERADOR DE MAQUINAS CLASSIFICADORA E TABULADORA',

  'OPERADOR DE MONITORAMENTO',

  'OPERADOR DE MONTAGEM DE CILINDROS E MANCAIS',

  'OPERADOR DE MOTO SERRA',

  'OPERADOR DE MOVIMENTO CONFERENTE',

  'OPERADOR DE MÁQUINAS FIXAS',

  'OPERADOR DE PAINEL CENTRAL',

  'OPERADOR DE PONTE ROLANTE',

  'OPERADOR DE PRENSA',

  'OPERADOR DE PRODUCAO',

  'OPERADOR DE PRODUCAO II',

  'OPERADOR DE PRODUCAO III',

  'OPERADOR DE PÁCARREGADEIRA',

  'OPERADOR DE RECAPAGEM',

  'OPERADOR DE SECADOR (TRATAMENTOS QUÍMICOS E AFINS)',

  'OPERADOR DE SERRAS',

  'OPERADOR DE SISTEMAS',

  'OPERADOR DE TECELAGEM',

  'OPERADOR DE TELEMARKETING',

  'OPERADOR DE TORNO MECANICO',

  'OPERADOR DE TRATOR DE LÂMINA',

  'OPERADOR DE USINA',

  'OPERADOR DE USINAGEM',

  'OPERADOR DE VENDAS',

  'OPERADOR I',

  'OPERADOR II',

  'OPERADOR III',

  'OPERADOR INDUSTRIAL',

  'OPERADOR MULTIFUNCIONAL',

  'OPERADOR(A) ACABAMENTO',

  'OPERADOR(A) DE MAQUINA',

  'OPERADORA',

  'OPERADORA DE CAIXA',

  'OPERADORA DE COBRANCA JR',

  'OPERADORA DE MAQUINA',

  'OPERADORA DE TELEMARKENTING',

  'OPERADORA INDUSTRIAL',

  'ORIENTADOR (A)',

  'ORIENTADOR DE LOJA/ESTACIONAMENTO',

  'ORIENTADORA',

  'ORIENTADORA EDUCACIONAL',

  'OURIVES',

  'OUTRAS RENDAS',

  'OUTROS BOMBEIROS',

  'OUTROS COMERCIANTES (COMÉRCIO ATACADISTA E VAREJISTA)',

  'OUTROS DESPACHANTES',

  'OUTROS ENCANADORES E INSTALADORES DE TUBULAÇOES',

  'OUTROS GARÇONS',

  'OUTROS GUARDAS DE SEGURANÇA E TRABALHADORES ASSEMELHADOS',

  'OUTROS PINTORES DE OBRAS E DE ESTRUTURAS METÁLICAS',

  'OUTROS RECEPCIONISTAS',

  'OUTROS SECRETÁRIOS',

  'OUTROS SUPERVISORES DE VENDAS E TRABALHADORES ASSEMELHADOS',

  'OUTROS TRABALHADORES DA MOVIMENTAÇAO DE CARGAS E DESCARGAS',

  'OUTROS TRABALHADORES DE SERVIÇOS DE PROTEÇAO E SEGURANÇA NAO',

  'OUTROS VENDEDORES AMBULANTES',

  'OUTROS VENDEDORES DE COMÉRCIO ATACADISTA',

  'OUTROS VENDEDORES PRACISTAS',

  'PACOTEIRO',

  'PADEIRO',

  'PADEIRO CONFEITEIRO',

  'PAISAGISMO',

  'PANFLETAGEM',

  'PANFLETEIRA',

  'PASSADEIRA',

  'PASSADOR',

  'PASTELEIRA',

  'PASTOR',

  'PASTORA AUXILIAR',

  'PECUARISTA',

  'PEDAGOGA',

  'PEDAGOGO',

  'PEDREIRO',

  'PEDREIRO (CHAMINÉS INDUSTRIAIS)',

  'PEDREIRO (EDIFICAÇOES)',

  'PENCIONISTA',

  'PENSAO ALIMENTICIA',

  'PENSAO POR MORTE',

  'PENSIONISTA',

  'PENSIONISTA E AUTONOMA',

  'PENSIONISTA E PROFESSORA',

  'PERFUMISTA',

  'PERSONAL TRAINER',

  'PESADOR DE PRODUTOS QUIMICOS',

  'PESCADOR',

  'PESCADOR ARTESANAL',

  'PESQUISADOR',

  'PINTOR',

  'PINTOR DE OBRAS',

  'PINTOR DE VEÍCULOS',

  'PINTOR INDUSTRIAL',

  'PISCINEIRO',

  'PIZZAIOLO',

  'PLANFETAR',

  'PODOLOGA (O)',

  'POLICIAL CIVIL',

  'POLICIAL MILITAR',

  'POLIDOR DE CARROS',

  'POLIDOR DE METAIS',

  'POLIVALENTE',

  'PORTEIRA',

  'PORTEIRO',

  'PORTEIRO DE EDIFÍCIO',

  'PRENSISTA',

  'PREPARADEIRA',

  'PREPARADOR',

  'PREPARADOR DE MAQUINAS',

  'PREPARADOR DE PINTURA',

  'PREPARADOR DE SUCATA E APARAS',

  'PREPARADOR DE TINTAS',

  'PRODUTOR AGROPECUÁRIO',

  'PRODUTOR AGRÍCOLA POLIVALENTE',

  'PRODUTOR DE EVENTOS',

  'PROFESSOR (O)',

  'PROFESSOR DE 1ª A 4ª SÉRIE (ENSINO DE 1º GRAU)',

  'PROFESSOR DE EDUCACAO FISICA',

  'PROFESSOR DE MUSICA',

  'PROFESSOR(A)',

  'PROFESSORA',

  'PROFESSORA ADJUNTO - A -',

  'PROFESSORA DE EDUCAÇÃO FISICA',

  'PROFESSORA DE ENSINO SUPERIOR',

  'PROGETISTA',

  'PROGRAMADOR',

  'PROGRAMADOR DE COMPUTADOR',

  'PROGRAMADOR DE PRODUCAO',

  'PROGRAMADOR DE SISTEMA',

  'PROJETISTA',

  'PROMOTER',

  'PROMOTOR',

  'PROMOTOR DE EVENTOS',

  'PROMOTOR DE MARKETING',

  'PROMOTOR DE MERCHANDISER',

  'PROMOTOR DE VENDAS',

  'PROMOTORA',

  'PROMOTORA COMERCIAL',

  'PROMOTORA DE EVENTOS',

  'PROMOTORA DE VENDAS',

  'PROPAGANDISTA',

  'PROPIETARIO (A)',

  'PROPRIETARIA',

  'PROPRIETARIO(A)',

  'PROTETICA',

  'PROTETICO',

  'PROTETICO DENTARIO',

  'PSICOLOGA',

  'PSICOLOGO',

  'PUBLICITARIA',

  'PUBLICITARIO',

  'QUEIMADOR',

  'QUIMICO',

  'RADIALISTA',

  'RADIOLOGISTA',

  'RASTELEIRO',

  'REBARBADOR DE METAIS',

  'REBOBINADOR(A)',

  'RECEBEDOR DE APOSTAS (LOTERIAS)',

  'RECEPCIONISTA',

  'RECEPCIONISTA BILINGUE',

  'RECEPCIONISTA DE CONSULTÓRIO MÉDICO OU DENTÁRIO',

  'RECEPCIONISTA DE HOTEL',

  'RECICLADOR',

  'RECICLAGEM',

  'RECPECIONISTA',

  'RECREACIONISTA',

  'RECREADORA',

  'RECUPERADOR DE CREDITO',

  'RECURSOS HUMANOS',

  'REPORTER',

  'REPOSITOR',

  'REPOSITORA',

  'REPRESENTANTE COMERCIAL',

  'REPRESENTANTE DE VENDAS',

  'RESPONSAVEL VISUAL MERCHANDISE',

  'RETALHADOR DE CARNE',

  'RETIFICADOR',

  'REVENDEDOR',

  'REVISOR',

  'REVISOR DE TECIDOS',

  'REVISORA',

  'ROCADOR',

  'SAFRISTA',

  'SALADEIRA',

  'SALGADEIRA',

  'SALGADEIRO (A)',

  'SAPATEIRO',

  'SAQUEIRO',

  'SARGENTO (1º)',

  'SARGENTO (2º)',

  'SARGENTO (3º)',

  'SECRETARIA',

  'SECRETARIA (O)',

  'SECRETARIA (O) EXECUTIVO',

  'SECRETARIA ADMINISTRATIVA',

  'SECRETARIA DO LAR',

  'SECRETARIA EXECUTIVO',

  'SECRETARIA RECEPCIONISTA',

  'SECRETARIO ESCOLAR',

  'SECRETARIO GERAL',

  'SECRETARIO PARLAMENTAR',

  'SECURITARIA',

  'SEGURANCA',

  'SEPARADOR',

  'SEPARADOR DE PEDIDO',

  'SERIGRAFISTA',

  'SERINGUEIRO',

  'SERRADOR DE MADEIRA',

  'SERRADOR(B)',

  'SERRALHEIRO',

  'SERVENTE',

  'SERVENTE DE LIMPEZA',

  'SERVENTE DE OBRAS',

  'SERVENTE DE PEDREIRO',

  'SERVENTE ESCOLAR',

  'SERVICOS GERAIS',

  'SERVIDOR PUBLICO',

  'SINALEIRO (PONTE ROLANTE)',

  'SINDICA',

  'SOCIA',

  'SOCIA GERENTE',

  'SOCIA PROPRIETARIA',

  'SOCIO',

  'SOCIO ADMINISTRADOR',

  'SOCIO PROPRIETARIO',

  'SOCIO(A) GERENTE',

  'SOCORRISTA',

  'SOLDADO',

  'SOLDADOR',

  'SOLDADOR GERAL',

  'SUB GERENTE',

  'SUBGERENTE',

  'SUBTENENTE',

  'SUCATEIRO',

  'SUPERVISOR',

  'SUPERVISOR ADMINISTRATIVO',

  'SUPERVISOR COMERCIAL',

  'SUPERVISOR DE ATENDIMENTO',

  'SUPERVISOR DE COBRANCAS',

  'SUPERVISOR DE GESSO',

  'SUPERVISOR DE LOGISTICA',

  'SUPERVISOR DE LOJA',

  'SUPERVISOR DE MANUTENCAO',

  'SUPERVISOR DE OBRAS',

  'SUPERVISOR DE OPERACOES',

  'SUPERVISOR DE PANIFICACAO',

  'SUPERVISOR DE PRODUCAO',

  'SUPERVISOR DE SEGURANCA',

  'SUPERVISOR DE VENDAS',

  'SUPERVISOR TECNICO',

  'SUPERVISORA',

  'SUPORTE TECNICO',

  'SUSHI-MAN',

  'TALHADEIRA',

  'TALHADOR',

  'TAPECEIRO (CENÁRIOS)',

  'TATOADOR',

  'TAXISTA',

  'TEC. INFORMATICA',

  'TECELAO',

  'TECNICA EM ENFERMAGEM',

  'TECNICA EM INFORMATICA',

  'TECNICA EM RAIO X',

  'TECNICA ENFERMAGEM',

  'TECNICO',

  'TECNICO ADMINISTRATIVO',

  'TECNICO AGRICOLA',

  'TECNICO AGROPECUARIO',

  'TECNICO AUTOMOTIVO',

  'TECNICO BANCARIO',

  'TECNICO CONTABIL',

  'TECNICO DE CONTABILIDADE',

  'TECNICO DE ENFERMAGEM',

  'TECNICO DE INFORMATICA',

  'TECNICO DE INSPECAO',

  'TECNICO DE LABORATORIO',

  'TECNICO DE LABORATORIO DE ANALISES CLINICAS',

  'TECNICO DE MANUTENCAO',

  'TECNICO DE MANUTENCAO ELETRICA',

  'TECNICO DE PRODUCAO',

  'TECNICO DE QUALIDADE',

  'TECNICO DE SANEAMENTO',

  'TECNICO DE SEGURANCA DE TRABALHO',

  'TECNICO DE SEGURANCA DO TRABALHO',

  'TECNICO DE SERVICOS',

  'TECNICO DE SOM',

  'TECNICO DE SUPORTE',

  'TECNICO DE TELECOMUNICACAO',

  'TECNICO DE TELECOMUNICACOES',

  'TECNICO EDUCACIONAL',

  'TECNICO ELETROMECANICO',

  'TECNICO ELETRONICA',

  'TECNICO ELETRONICO',

  'TECNICO ELETROTECNICO',

  'TECNICO EM EDIFICACOES',

  'TECNICO EM ELETRONICA',

  'TECNICO EM ELETROTECNICA',

  'TECNICO EM ENFERMAGEM',

  'TECNICO EM FARMACIA',

  'TECNICO EM INFORMATICA',

  'TECNICO EM INSTALACAO',

  'TECNICO EM INSTALACAO E MANUTENCAO',

  'TECNICO EM LABORATORIO',

  'TECNICO EM MANUTENCAO',

  'TECNICO EM MANUTENCAO DE COMPUTADORES',

  'TECNICO EM MECANICA',

  'TECNICO EM RADIOLOGIA',

  'TECNICO EM REFRIGERACAO',

  'TECNICO EM SEGURANCA DO TRABALHO',

  'TECNICO EM TELECOMUNICACOES',

  'TECNICO EM TELEFONIA',

  'TECNICO ENFERMAGEM DO TRABALHO',

  'TECNICO INFORMATICA',

  'TECNICO JUDICIARIO',

  'TECNICO MANUTENCAO',

  'TECNICO MECANICO',

  'TECNICO OPERADOR',

  'TECNICO QUIMICO',

  'TECNICO SEGURANCA DO TRABALHO',

  'TECNOLOGO',

  'TELE ATENDENTE',

  'TELE VENDAS',

  'TELEFONISTA',

  'TELEMARKTING',

  'TENENTE, 2º',

  'TERAPEUTA HOLISTICO',

  'TERAPEUTA OCUPACIONAL',

  'TESOUREIRO',

  'TINTUREIRO',

  'TOPOGRAFO',

  'TORNEIRO MECANICO',

  'TORNEIRO MECÂNICO',

  'TOSADOR',

  'TOSADORA E INSTRUMENTADORA CIRURGICA',

  'TOZADORA',

  'TRABALHADOR AGROPECUÁRIO POLIVALENTE',

  'TRABALHADOR AGRÍCOLA POLIVALENTE',

  'TRABALHADOR DA AVICULTURA',

  'TRABALHADOR DA CULTURA DE BANANA',

  'TRABALHADOR DA CULTURA DE HORTALIÇAS',

  'TRABALHADOR DA MOVIMENTAÇAO DE CARGA E DESCARGA DE MERCADORI',

  'TRABALHADOR DA PECUÁRIA (GADO LEITEIRO)',

  'TRABALHADOR DA SUINOCULTURA',

  'TRABALHADOR DE FABRICAÇAO DE SORVETE',

  'TRABALHADOR HORTIGRANJEIRO',

  'TRABALHADOR RURAL',

  'TRABALHADOR VOLANTE DA AGRICULTURA',

  'TRABALHADORES DE SERVIÇOS GERAIS (SERVIÇOS DE CONSERVACAO',

  'TRANSPORTADOR',

  'TRATADOR',

  'TRATORISTA',

  'TRATORISTA AGRÍCOLA',

  'TREINADOR',

  'VAQUEIRO',

  'VARREDOR',

  'VENDEDOR',

  'VENDEDOR A DOMICILIO',

  'VENDEDOR AMBULANTE',

  'VENDEDOR AUTONOMO',

  'VENDEDOR DE AUTOMOVEIS',

  'VENDEDOR DE COMÉRCIO ATACADISTA',

  'VENDEDOR DE COMÉRCIO VAREJISTA',

  'VENDEDOR EXTERNO',

  'VENDEDOR TECNICO',

  'VENDEDOR(A) EXTERNO',

  'VENDEDORA',

  'VENDEDORA AUTONOMA',

  'VENDEDORA AUTONOMO',

  'VENDEDORA DE BIJU',

  'VENDEDORA PROMOTORA',

  'VERDUREIRO',

  'VEREADOR',

  'VETERINARIA (O)',

  'VETERINARIO',

  'VIDRACEIRO',

  'VIGIA',

  'VIGILANTE',

  'VIGILANTE SANITARIO',

  'VISTORIADOR',

  'VISTORIADOR DE VEICULOS',

  'VULCANIZADOR DE PNEUMÁTICOS',

  'WEB DESIGNER',

  'ZELADOR DO EDIFÍCIO',

  'ZELADOR(A)',

  'ZELADORA',

  'ZOOTECNISTA',

  'APOSENTADA AUTONOMO',

  'APOSENTADA E PROFESSORA',

  'AUXILIAR DE SERVIÇOS ESCOLARES',

  'AUXILIAR JUDICIARIO',

  'BALCONISTA GERENTE',

  'CHEFE DE PISTA',

  'MONITOR DE ATENDIMENTO',
]
