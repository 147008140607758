import { Grid, GridProps, ResponsiveContext } from 'grommet'
import * as React from 'react'

interface ResponsiveProps extends GridProps {
  overrideColumns?: { [size: string]: string[] }
  overrideRows?: { [size: string]: string[] }
  overrideAreas?: { [size: string]: { name?: string; start?: number[]; end?: number[] }[] }
}

type Responsive = (props: ResponsiveProps & JSX.IntrinsicElements['div']) => any

export const ResponsiveGrid: Responsive = ({
  children,
  overrideColumns,
  overrideRows,
  overrideAreas,
  columns,
  rows,
  areas,
  ...props
}) => (
  <ResponsiveContext.Consumer>
    {size => {
      // take into consideration if not array is sent but a simple string
      let columnsVal
      if (overrideColumns) {
        if (overrideColumns[size]) {
          columnsVal = overrideColumns[size]
        }
      } else {
        columnsVal = columns
      }

      let rowsVal
      if (overrideRows) {
        if (overrideRows[size]) {
          rowsVal = overrideRows[size]
        }
      } else {
        rowsVal = rows
      }

      // also if areas is a simple array not an object of arrays for different sizes
      let areasVal
      if (overrideAreas && !Array.isArray(overrideAreas)) areasVal = overrideAreas[size]

      return (
        <Grid
          {...props}
          areas={!areasVal ? undefined : areasVal}
          rows={!rowsVal ? size : rowsVal}
          columns={!columnsVal ? size : columnsVal}
        >
          {children}
        </Grid>
      )
    }}
  </ResponsiveContext.Consumer>
)
