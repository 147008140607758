import { IInvoice } from '../../../services/bff-backoffice/coordinator/interfaces/invoice.interfaces'
import { InvoicesHistoryRow } from '../InvoicesHistory'
import { InvoicesTableRow } from '../InvoicesTable'
import { getOperator } from './get-operator'

export const mapInvoicesHistoryTableRows = (invoices: IInvoice[]): InvoicesHistoryRow[] =>
  invoices?.map<InvoicesHistoryRow>(i => {
    return {
      id: i.id,
      barcode: i.barcode,
      createdOn: i.createdOn,
      dueDate: i.dueDate,
      liquidationDate: i.liquidationDate,
      modifiedOn: i.modifiedOn,
      paidValue: i.paidValue,
      partnerInvoiceId: i.partnerInvoiceId,
      paymentDate: i.paymentDate,
      status: i.status,
      url: i.url,
      value: i.value,
      operator: getOperator(i?.generatedAt, i?.operatorId),
    }
  })

export const mapInvoicesTableRows = (invoices: IInvoice[]): InvoicesTableRow[] =>
  invoices?.map<InvoicesTableRow>(i => {
    return {
      ...i,
      operator: getOperator(i?.generatedAt, i?.operatorId),
    }
  })
