import styled from "styled-components"
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { css } from 'styled-components'

export const TypeLabel = styled.span`
  ${(props: any) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
  display: inline-block;
  height: 100%;
  width: 80%;
  text-align: center;
  border-radius: 2;
  color: ${UMEColors.white.primary};
`

export const TableContainer = styled.div`
  ${(props: any) =>
    props.isLoading &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  margin-top: 2vh;

  max-height: 400px;
  border-radius: 8px;
  background-color: ${UMEColors.white.primary};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 33px;
  overflow-x: auto;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${UMEColors.gray.gainsboro};
    border-left: 2px solid white;
    border-right: 2px solid white;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${UMEColors.gray.eclipse};
    border-radius: 9px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${UMEColors.gray.nobel};
  }
`
