import { SourceProductType } from '../enums/source-product.enum'
import { IInvoice, PaymentMethodType } from './invoice.interfaces'

export type INSTALLMENT_STATUS = 'PENDING' | 'PAYMENT_OVERDUE' | 'PAID' | 'CANCELED'

export enum InstallmentStatusEnum {
  PENDING = `PENDING`,
  PAYMENT_OVERDUE = `PAYMENT_OVERDUE`,
  PAID = `PAID`,
  CANCELED = `CANCELED`,
}

export enum InstallmentTypeEnum {
  FEE = 'fee',
  ORIGINATION = 'origination',
}

export type InstallmentType = InstallmentTypeEnum.FEE | InstallmentTypeEnum.ORIGINATION

export interface ChargeFeeInstallment {
  dueDate: string
  installmentValue: number
  type: InstallmentType
  status: INSTALLMENT_STATUS
  iofAliquotaFixa: number
  iofDiasCorridos: number
  monetaryCorrection: number
  defaultInterest: number
  defaultFine: number
  paymentDue: number
}

export class InstallmentQueryParams {
  borrowerId?: string

  contractId?: string

  paymentDate?: string

  dueDate?: string

  retailerId?: string

  status?: INSTALLMENT_STATUS
}

export interface InstallmentsFeeQueryParams {
  borrowerId: string
}

export interface InstallmentSingleSimulation {
  paymentDue: number
  defaultInterest: number
  defaultFine: number
  earlyPaymentDiscount: number
  monetaryCorrection: number
  expectedValueOnDate: number
}

export interface InstallmentSimulation {
  simulation: InstallmentSingleSimulation
}

export interface ISingleInstallment {
  id: string
  createdOn: string
  contractId: string
  paymentOperatorId?: string
  paymentStoreId?: string
  installmentOrder: number
  dueDate: string
  installmentValue: number
  paymentTimestamp?: string
  paymentDue: number
  defaultInterest: number
  defaultFine: number
  earlyPaymentDiscount: number
  monetaryCorrection: number
  IOFDiasCorridos: number
  IOFAliquotaFixa: number
  amortization: number
  interest: number
  expectedValueOnDate: number
  paidValue?: number
  isRenegotiation: boolean
  inRenegotiation: boolean
  interestCalculationDate: string
  method: PaymentMethodType
  status: 'PENDING' | 'PAID' | 'PAYMENT_OVERDUE'
  isUpfrontPayment: boolean
  invoices?: IInvoice[]
  couponDiscount: number
  type?: InstallmentType
  sourceProduct?: SourceProductType
}

export interface IInstallments {
  installments: ISingleInstallment[]
}

export interface IInstallmentsValues {
  installmentId: string
  simulatedValue: number
  debtFundingPackId?: string
}
