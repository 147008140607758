import { Card } from '@bit/ume.design-kit.ui'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Box } from 'grommet'
import { History } from 'history'
import React from 'react'
import styled from 'styled-components'

interface Props {
  history: History
  batchNumber: number
  numberOfApplications: number
}

const BatchesCard = ({ batchNumber, numberOfApplications, history }: Props) => {
  const handleBatchCardClick = () => {
    history.push(`batches/${batchNumber}`)
  }

  return (
    <CardStyle onClick={handleBatchCardClick}>
      <Title>
        Pacote <Quantity> {batchNumber}</Quantity>
      </Title>
      <Content>Applications:</Content>
      <BatchNumber isEmpty={numberOfApplications === 0}>{numberOfApplications}</BatchNumber>
    </CardStyle>
  )
}

const CardStyle = styled(Card)`
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;

  &:hover {
    cursor: pointer;
    box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4) !important;
    transition: 0.3s;
  }
`

const Quantity = styled.span`
  color: ${UMEColors.black.primary};
  font-weight: bold;
`

const Title = styled.div`
  font-size: 22px;
`

const Content = styled.div`
  margin-bottom: 8px;
`

const BatchNumber = styled(Box)`
  color: #ffffff;
  font-size: 32px;
  font-weight: 700;
  height: 80px;
  width: 80px;
  text-align: center;
  background-color: ${(props: any) => (props.isEmpty ? UMEColors.gray.aluminium : '#00b748')};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default BatchesCard
