import { Header, SideBar } from '@bit/ume.design-kit.ui'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Anchor, Box, Heading } from 'grommet'
import { Clock, Close, Currency, Logout, Money, Optimize, Organization, PhoneVertical, Plan, User } from 'grommet-icons'
import React from 'react'
import styled from 'styled-components'
import umeLogo from '../common/assets/images/ume-logo.jpeg'
import { xlaptop } from '../common/assets/_breakpoints'
import { bffBackofficeApiService } from '../services/bff-backoffice'

export const UMEHeader = (props: any) => {
  const [open, setOpen] = React.useState(false)

  const onOpen = () => setOpen(true)

  const onClose = () => setOpen(false)

  let { history, user } = props

  const onLogout = () => {
    bffBackofficeApiService.auth.logout()
    props.handleUserLogged()
    props.history.push('/')
  }

  if (!user) {
    return (
      <NavBarComponent>
        <Header
          logo={
            <Anchor>
              <LogoImg src={umeLogo} />
            </Anchor>
          }
        />
      </NavBarComponent>
    )
  }

  return (
    <NavBarComponent>
      {/* MOBILE */}
      <Box className={'is-mobile'}>
        <Header
          mobile
          logo={
            <Anchor onClick={() => history.push('/')}>
              <LogoImg src={umeLogo} />
            </Anchor>
          }
          isSideBarOpen={open}
          openSideBar={onOpen}
          sideBar={
            <SideBar
              bottomNavItems={[<Anchor key="logout" label="Sair" color="white" icon={<Logout />} onClick={onLogout} />]}
              topNavItems={[
                <Anchor
                  key="analysis"
                  label="Análises"
                  color="white"
                  icon={<Optimize color={UMEColors.green.darkPastel} />}
                  onClick={() => {
                    history.push('/analysis')
                    onClose()
                  }}
                />,

                <Anchor
                  key="borrowers"
                  label="Tomadores"
                  color="white"
                  icon={<User color={UMEColors.green.darkPastel} />}
                  onClick={() => {
                    history.push('/borrowers')
                    onClose()
                  }}
                />,

                <Anchor
                  key="financial"
                  label="Financeiro"
                  color="white"
                  icon={<Money color={UMEColors.green.darkPastel} />}
                  onClick={() => {
                    history.push('/financial/originations')
                    onClose()
                  }}
                />,

                <Anchor
                  key="risk"
                  label="Risco"
                  color="white"
                  icon={<Currency color={UMEColors.green.darkPastel} />}
                  onClick={() => {
                    history.push('/risk')
                    onClose()
                  }}
                />,

                <Anchor
                  key="retailers"
                  label="Varejistas"
                  color="white"
                  icon={<Organization color={UMEColors.green.darkPastel} />}
                  onClick={() => {
                    history.push('/retailers')
                    onClose()
                  }}
                />,

                <Anchor
                  key="application-online"
                  label="Application Online"
                  color="white"
                  icon={<PhoneVertical color={UMEColors.green.darkPastel} />}
                  onClick={() => {
                    history.push('/applications/batches')
                    onClose()
                  }}
                />,

                <Anchor
                  key="evaluation-bench"
                  label="Banca de Varejistas"
                  color="white"
                  icon={<Clock color={UMEColors.green.darkPastel} />}
                  onClick={() => {
                    history.push('/retailers-bench')
                    onClose()
                  }}
                />,
              ]}
              headIcons={[<Close key="close" color="white" onClick={onClose} />]}
              logo={
                <Anchor
                  onClick={() => {
                    history.push('/')
                    onClose()
                  }}
                >
                  <LogoImg src={umeLogo} />
                </Anchor>
              }
              onClickOutside={onClose}
            >
              <Box margin={{ right: 'small' }}>
                <Heading color="white" size="30px">
                  Olá, como posso te ajudar?
                </Heading>
              </Box>
            </SideBar>
          }
        ></Header>
      </Box>

      {/* DESKTOP */}
      <Box className={'panel-header'}>
        <Header
          logo={
            <Anchor onClick={() => history.push('/')}>
              <LogoImg src={umeLogo} />
            </Anchor>
          }
        >
          <Anchor
            key="application-online"
            icon={<PhoneVertical />}
            label="Application Online"
            color="white"
            onClick={() => history.push('/applications/batches')}
          />

          <Anchor
            key="evaluation-bench"
            icon={<Clock />}
            label="Banca de Varejistas"
            color="white"
            onClick={() => history.push('/retailers-bench')}
          />

          <Anchor
            key="varejo"
            icon={<Organization />}
            label="Varejistas"
            color="white"
            onClick={() => history.push('/retailers')}
          />

          <Anchor key="risco" icon={<Currency />} label="Risco" color="white" onClick={() => history.push('/risk')} />

          <Anchor
            key="analysis"
            icon={<Optimize />}
            label="Análises"
            color="white"
            onClick={() => history.push('/analysis')}
          />

          <Anchor
            key="prioritization"
            icon={<Plan />}
            label="Priorização"
            color="white"
            onClick={() => history.push('/prioritization')}
          />

          <Anchor
            key="borrowers"
            label="Tomadores"
            color="white"
            icon={<User />}
            onClick={() => history.push('/borrowers')}
          />

          <Anchor
            key="financial"
            label="Financeiro"
            color="white"
            icon={<Money />}
            onClick={() => history.push('/financial/originations')}
          />

          <Anchor key="logout" label="Sair" color="white" icon={<Logout />} onClick={onLogout} />
        </Header>
      </Box>
    </NavBarComponent>
  )
}

export const NavBarComponent = styled.div`
  > div {
    &.panel-header {
      display: none;
    }
  }

  // Missing this property on CSS in order to center <a />
  & a {
    align-self: center;
  }

  @media (min-width: ${xlaptop}) {
    > div {
      &.panel-header {
        display: block;
      }

      &.is-mobile {
        display: none;
      }
    }
  }
`

const LogoImg = styled.img`
  alt: ume-logo;
  height: 51px;
`
