import React, { Component } from 'react'
import imgHeroDesktop from '../../../common/assets/images/security-concept-illustration.jpg'
import imgLockerMobile from '../../../common/assets/images/cadeado-icon.png'
import { validateBr } from '../../../common/Validators'
import {
  CpfInvalid,
  Button,
  ImgHeroDesktop,
  ImgLockerMobile,
  InputPassword,
  ParagraphTitle,
  PasswordCard,
  PasswordComponent,
  PasswordContainer,
  TitleContainer,
  UnorderedList,
  ListItem,
  TextResendCode,
} from './style'

import Loading from '../../../atoms/Loading/Loading'
import maskPhone from '../../../common/assets/utils/phoneMask'
import {bffBackofficeApiService} from '../../../services/bff-backoffice'

export interface IPasswordProps {
  history?: any
}
export interface IPasswordState {
  cpf: string,
  borrower?: any,
  code: string,
  loading: boolean,
  isSmsReadytoResend: boolean,
  textResendSms: string,
  isCpfValid: boolean,
  phoneMasked: string,
}

export default class PasswordForgot extends Component<IPasswordProps, IPasswordState> {
  constructor(props: IPasswordProps) {
    super(props)
    this.state = {
      cpf: '',
      code: '',
      loading: false,
      isSmsReadytoResend: false,
      textResendSms: '',
      isCpfValid: true,
      phoneMasked: ''
    }
  }
  handleCpf = (event: any) => {
    this.setState({ cpf: event.target.value })
  }

  handleCode = (event: any) => {
    this.setState({ code: event.target.value })
  }

  triggerSmsColdown = () => {
    const self = this;
    let timeleft = 60;
    let downloadTimer = setInterval(function(){
      self.setState({ textResendSms: "Reenvie em " + timeleft + " segundos" })
      timeleft -= 1;
      if(timeleft <= 0){
        clearInterval(downloadTimer);
        self.setState({
          textResendSms: "Reenviar",
          isSmsReadytoResend: true
        })
      }
    }, 1000);
  }

  formatPhoneNumber = (phone: string) => {
    let phoneNumberMasked = maskPhone(phone)
    phoneNumberMasked = phoneNumberMasked.split('-')[0] + '****'
    this.setState({ phoneMasked: phoneNumberMasked })
    
  }

  handleCheckCpf = () => { /* Check if CPF exists and send SMS with Code validation */
    this.setState({ loading: true })
    // let cleanCpf = this.cleanCpf()
    bffBackofficeApiService.coordinator.startResetPasswordProcess()
    .then((response) => {
      this.setState({ loading: false, borrower: response, isCpfValid: true })
      this.formatPhoneNumber(this.state.borrower.phoneNumber)
    }).catch(() => {
      this.setState({ loading: false })
      // if (!validateBr.cpf(cleanCpf)) {
      //   this.setState({ isCpfValid: false })
      // } else {
        alert('Tente novamente! Se o problema persistir contate nosso suporte!')
      // }
    })
  }

  cleanCpf = () => {
    let cleanCpf = this.state.cpf
    // eslint-disable-next-line
    cleanCpf = cleanCpf.replace(/\./g, "")
    // eslint-disable-next-line
    cleanCpf = cleanCpf.replace(/\-/g, "")
    return cleanCpf;
  }

  validateCode = () => {
    this.setState({ loading: true, isSmsReadytoResend: false })
    this.triggerSmsColdown();
    alert('Serviço indisponível')
    // apiService.checkVerification(this.state.borrower.verificationId ,this.state.code)
    // .then(() => {
    //   this.setState({ loading: false })
    //   this.props.history.push({
    //     pathname: 'alterar-senha',
    //     state: {
    //       verificationId: this.state.borrower.verificationId
    //     }
    //   })
    // })
    // .catch(() => {
    //   this.setState({ loading: false })
    //   alert('Código Inválido')
    // })
  }

  render() {
    const { borrower, loading, isCpfValid, phoneMasked } = this.state
    return (
      <>
      <PasswordContainer>
        <ImgHeroDesktop src={imgHeroDesktop} alt={'security-banner-illustration'} loading="lazy" />
        <PasswordCard>
        <ImgLockerMobile src={imgLockerMobile} alt={'security-banner-illustration'} loading="lazy" />
          { loading ? <Loading /> :
          borrower ?
          <>
            <TitleContainer>
            <h2>Insira o código enviado para</h2>
            <ParagraphTitle>{phoneMasked}</ParagraphTitle>
          </TitleContainer>
            <PasswordComponent>
              <InputPassword onChange={this.handleCode} placeholder="Código" />
              <Button onClick={this.validateCode}>Validar</Button>
            </PasswordComponent>
            <UnorderedList>
              <ListItem>
                Não recebeu o SMS? <TextResendCode disabled={!this.state.isSmsReadytoResend} onClick={this.handleCheckCpf}>{this.state.textResendSms}</TextResendCode>
              </ListItem>
              <ListItem>
                Esse não é seu número? Fale conosco diretamente via Whatsapp clicando no ícone abaixo.
              </ListItem>
            </UnorderedList>
          </> :
          <>
            <TitleContainer>
              <h2>Precisando de uma senha nova?</h2>
              <ParagraphTitle>Coloque seu CPF para iniciarmos o processo de nova senha</ParagraphTitle>
            </TitleContainer>
            <PasswordComponent>
              <InputPassword onChange={this.handleCpf} placeholder="CPF" />
              {!isCpfValid && <CpfInvalid>CPF inválido. Tente novamente!</CpfInvalid>}
              <Button onClick={this.handleCheckCpf} >Verificar</Button>
            </PasswordComponent>
          </>
          }
        </PasswordCard>
      </PasswordContainer>
      </>
    )
  }
}
