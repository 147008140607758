export const FINANCING_OPTIONS_TEMPLATE = [
  { borrowerId: '8280686', retailerId: undefined, storeId: undefined, parcelamento: 1, tx_de_juros: '2.99%' },
  { borrowerId: '8280686', retailerId: undefined, storeId: undefined, parcelamento: 2, tx_de_juros: '3.99%' },
  { borrowerId: '8280686', retailerId: undefined, storeId: undefined, parcelamento: 3, tx_de_juros: '4.99%' },
  { borrowerId: '8280686', retailerId: undefined, storeId: undefined, parcelamento: 4, tx_de_juros: '5.99%' },
  { borrowerId: '8280686', retailerId: undefined, storeId: undefined, parcelamento: 5, tx_de_juros: '6.99%' },
  { borrowerId: '8280686', retailerId: undefined, storeId: undefined, parcelamento: 6, tx_de_juros: '7.99%' },

  { borrowerId: undefined, retailerId: 1, storeId: 1, parcelamento: 1, tx_de_juros: '2.99%' },
  { borrowerId: undefined, retailerId: 1, storeId: 1, parcelamento: 2, tx_de_juros: '3.99%' },
  { borrowerId: undefined, retailerId: 1, storeId: 1, parcelamento: 3, tx_de_juros: '4.99%' },
  { borrowerId: undefined, retailerId: 1, storeId: 1, parcelamento: 4, tx_de_juros: '5.99%' },
  { borrowerId: undefined, retailerId: 1, storeId: 1, parcelamento: 5, tx_de_juros: '6.99%' },
  { borrowerId: undefined, retailerId: 1, storeId: 1, parcelamento: 6, tx_de_juros: '7.99%' },
  { borrowerId: undefined, retailerId: 1, storeId: undefined, parcelamento: 1, tx_de_juros: '2.99%' },
  { borrowerId: undefined, retailerId: 1, storeId: undefined, parcelamento: 2, tx_de_juros: '3.99%' },
  { borrowerId: undefined, retailerId: 1, storeId: undefined, parcelamento: 3, tx_de_juros: '4.99%' },
  { borrowerId: undefined, retailerId: 1, storeId: undefined, parcelamento: 4, tx_de_juros: '5.99%' },
  { borrowerId: undefined, retailerId: 1, storeId: undefined, parcelamento: 5, tx_de_juros: '6.99%' },
  { borrowerId: undefined, retailerId: 1, storeId: undefined, parcelamento: 6, tx_de_juros: '7.99%' },

  { borrowerId: '8280686', retailerId: 1, storeId: undefined, parcelamento: 1, tx_de_juros: '2.99%' },
  { borrowerId: '8280686', retailerId: 1, storeId: undefined, parcelamento: 2, tx_de_juros: '3.99%' },
  { borrowerId: '8280686', retailerId: 1, storeId: undefined, parcelamento: 3, tx_de_juros: '4.99%' },
  { borrowerId: '8280686', retailerId: 1, storeId: undefined, parcelamento: 4, tx_de_juros: '5.99%' },
  { borrowerId: '8280686', retailerId: 1, storeId: undefined, parcelamento: 5, tx_de_juros: '6.99%' },
  { borrowerId: '8280686', retailerId: 1, storeId: undefined, parcelamento: 6, tx_de_juros: '7.99%' },

  { borrowerId: '8280686', retailerId: 1, storeId: 1, parcelamento: 1, tx_de_juros: '2.99%' },
  { borrowerId: '8280686', retailerId: 1, storeId: 1, parcelamento: 2, tx_de_juros: '3.99%' },
  { borrowerId: '8280686', retailerId: 1, storeId: 1, parcelamento: 3, tx_de_juros: '4.99%' },
  { borrowerId: '8280686', retailerId: 1, storeId: 1, parcelamento: 4, tx_de_juros: '5.99%' },
  { borrowerId: '8280686', retailerId: 1, storeId: 1, parcelamento: 5, tx_de_juros: '6.99%' },
  { borrowerId: '8280686', retailerId: 1, storeId: 1, parcelamento: 6, tx_de_juros: '7.99%' },
]
