import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Box, Text } from 'grommet'
import { ContactInfo } from 'grommet-icons'
import React from 'react'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { APPLICATION_PROFESSIONS } from '../../common/assets/utils/professions'
import ApplicationsTitle from '../../molecules/Applications/ApplicationsTitle'
import DetailsCard, { IDetailsCardData } from '../../molecules/RetailersPage/DetailsCard'
import { CardStyle } from '../../molecules/RetailersPage/styles'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { formatCep, formatCPF, formatDate, formatPhoneNumber } from '../../utils'

const BorrowerDetails = () => {
  const { application, borrower, error, isLoadingBorrower } = useTypedSelector(state => ({
    application: state.applications.application,
    borrower: state.applications.borrower,
    isLoadingBorrower: state.applications.isLoadingBorrower,
    error: state.applications.fetchBorrowerError,
  }))

  // Unlikely to occur
  if (!application) return <></>

  const Content = () => {
    if (isLoadingBorrower) {
      return (
        <CardStyle>
          <LoadingIcon />
        </CardStyle>
      )
    }

    if (error || !borrower) {
      return (
        <CardStyle>
          <Text color="red">Não foi possível carregar os dados do cliente.</Text>
        </CardStyle>
      )
    }

    const borrowerData: IDetailsCardData[] = [
      {
        label: 'ID',
        value: borrower.id,
      },
      {
        label: 'CPF',
        value: formatCPF(borrower.cpf),
      },
      {
        label: 'Nome',
        value: borrower.name,
      },
      {
        label: 'Nascimento',
        value: borrower.birthDate ? formatDate(borrower.birthDate) : '-',
      },
      {
        label: 'Telefone Primário',
        value: borrower.phoneNumber ? formatPhoneNumber(borrower.phoneNumber.slice(3)) : '-',
      },
      {
        label: 'Telefone Secundário',
        value: borrower.phones && borrower.phones.length > 0 ? formatPhoneNumber(borrower.phones[0].phoneNumber) : '-',
      },
      {
        label: 'E-mail',
        value: borrower.email ? borrower.email : '-',
      },
      {
        label: 'Profissão',
        value: application.professionId ? APPLICATION_PROFESSIONS[Number(application.professionId) - 1] : '-',
      },
    ]

    const addressData: IDetailsCardData[] = [
      {
        label: 'CEP',
        value: borrower.cep ? formatCep(borrower.cep) : '-',
      },
      {
        label: 'Logradouro',
        value: borrower.zipCodeInfo ? borrower.zipCodeInfo.logradouro : '-',
      },
      {
        label: 'Bairro',
        value: borrower.zipCodeInfo ? borrower.zipCodeInfo.bairro : '-',
      },
      {
        label: 'Localidade',
        value: borrower.zipCodeInfo ? borrower.zipCodeInfo.localidade : '-',
      },
      {
        label: 'Número',
        value: borrower.addressNumber ? borrower.addressNumber : '-',
      },
      {
        label: 'Complemento',
        value: borrower.addressComplement ? borrower.addressComplement : '-',
      },
      {
        label: 'UF',
        value: borrower.zipCodeInfo?.uf ? borrower.zipCodeInfo.uf : '-',
      },
    ]

    return (
      <Box direction="row-responsive">
        <Box width="100%">
          <DetailsCard data={borrowerData} />
        </Box>
        <Box width="100%">
          <DetailsCard data={addressData} />
        </Box>
      </Box>
    )
  }

  return (
    <React.Fragment>
      <ApplicationsTitle title="Dados do Cliente" icon={<ContactInfo size="25px" color={UMEColors.orange.amber} />} />
      <Content />
    </React.Fragment>
  )
}

export default BorrowerDetails
