import React from 'react'
import { Link } from 'react-router-dom'
import { BreadcrumbsBox, BreadcrumbsItem } from '../RetailersPage/styles'

interface IRiskBreadcrumbsProps {
  screenName: string
}

const ApplicationsBreadcrumbs = (props: IRiskBreadcrumbsProps) => {
  return (
    <BreadcrumbsBox>
      <Link to="/">
        <BreadcrumbsItem>Início</BreadcrumbsItem>
      </Link>
      <BreadcrumbsItem>/</BreadcrumbsItem>
      <Link to="/applications/batches">
        <BreadcrumbsItem>Application Online</BreadcrumbsItem>
      </Link>
      <BreadcrumbsItem>/</BreadcrumbsItem>
      <BreadcrumbsItem active>{props.screenName}</BreadcrumbsItem>
    </BreadcrumbsBox>
  )
}

export default ApplicationsBreadcrumbs