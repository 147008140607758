import { Box, Heading, Layer } from 'grommet'
import { FormClose } from 'grommet-icons'
import styled from 'styled-components'
import { laptop } from '../../common/assets/_breakpoints'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'

export const ModalStyle = styled(Layer)`
  border-radius: 8px;
  height: auto;
  width: 50vw;
  padding: 32px 40px;
  margin-top: 12px;

  @media (max-width: ${laptop}) {
    width: 95%;
  }
`

export const ImagesContainer = styled(Box)`
  max-height: 50vh;
  min-height: 10vh;
  overflow-x: hidden;
  overflow-y: auto;
  justify-content: center;

  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${UMEColors.gray.gainsboro};
    border-left: 2px solid white;
    border-right: 2px solid white;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${UMEColors.gray.eclipse};
    border-radius: 9px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${UMEColors.gray.nobel};
  }
`

export const TitleStyle = styled(Heading)`
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin: 0;
`

export const FormCloseIconStyle = styled(FormClose)`
  cursor: pointer;
`
