export interface FetchStoreResponse {
  id: string
  name: string
  zipcode: string
  retailerId: string
  address?: string
  googlePlaceId?: string
  depositAccountOwnerDocument?: string
  depositAccountOwnerName?: string
  depositAccountOwnerPhoneNumber?: string
  intermediaryBankAccountBankCode?: string
  intermediaryBankAccountAccountType?: string
  intermediaryBankAccountAgencyCode?: string
  intermediaryBankAccountAgencyDigit?: string
  intermediaryBankAccountCode?: string
  intermediaryBankAccountDigit?: string
  finalBankAccountBankCode: string
  finalBankAccountAccountType: string
  finalBankAccountAgencyCode: string
  finalBankAccountAgencyDigit: string
  finalBankAccountCode: string
  finalBankAccountDigit: string
  deactivatedOn?: string
  status: StoreStatus
}
export interface FetchRetailerStoresResponse {
  stores: FetchStoreResponse[]
}

export enum StoreStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
