import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import { IDocumentImageResponse, IImageResponse, IProcessStatusResponse } from './interfaces/datalake-acesso.interfaces'

export default class DatalakeAcessoService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async getBorrowerFaceImage(id: string): Promise<IImageResponse> {
    const response = await this.axiosInstance.get<IImageResponse>(`/datalake-acesso/biometry/borrowers/${id}/face`)
    return response.data
  }

  async getBorrowerDocumentImage(id: string): Promise<IDocumentImageResponse> {
    const response = await this.axiosInstance.get<IDocumentImageResponse>(
      `/datalake-acesso/biometry/borrowers/${id}/document`
    )
    return response.data
  }

  async patchBorrowerProcessStatus(id: string): Promise<IProcessStatusResponse> {
    const response = await this.axiosInstance.patch<IProcessStatusResponse>(
      `/datalake-acesso/biometry/borrowers/${id}/status`
    )
    return response.data
  }
}
