import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { toaster } from '../../../App'
import { IEmailResponse} from '../../../services/bff-backoffice/billings/interfaces/billings.interfaces'
import { 
  IPlaceSearchResponse, 
  IPostGooglePlaceSearch, 
  IPutStorePlace, 
  IRetailerResponse, 
  IStoreResponse, 
  PostStoreRequest
} from '../../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'
import { FetchStoreResponse } from '../../../services/bff-backoffice/retailers/interfaces/stores.res'
import { StoresReducerErrors } from './stores.errors'

export type IStoresState = {
  stores?: FetchStoreResponse[]
  store?: FetchStoreResponse
  storesNames: string[]
  retailer?: IRetailerResponse
  error: any
  errorType: any
  emails?: IEmailResponse[]
  isPutStoreLogoLoading: boolean
  googlePlaceSearch?: IPlaceSearchResponse
  isLoadingGooglePlaceSearch: boolean
  isCreateStoreModalOpen: boolean
  isCreateStoreLoading: boolean

  // Edit Google Place Id
  isEditGooglePlaceIdModalOpen: boolean
  isEditGooglePlaceIdLoading: boolean
  googlePlaceSearchEdit?: IPlaceSearchResponse | null
  isLoadingGooglePlaceSearchEdit: boolean
  isFetchingStore: boolean

  name: string
  searchText: string

  zipcode: string
  streetName: string
  streetNumber: string
  complement?: string
  district: string
  uf: string
  city: string
  ibge?: number
}

interface IStoreAddress {
  streetName: string
  district: string
  city: string
  uf: string
  ibge: number
}

const initialState: IStoresState = {
  stores: undefined,
  store: undefined,
  retailer: undefined,
  storesNames: [],
  error: undefined,
  errorType: undefined,
  emails: undefined,
  isPutStoreLogoLoading: false,
  googlePlaceSearch: undefined,
  isLoadingGooglePlaceSearch: false,
  isCreateStoreLoading: false,
  isCreateStoreModalOpen: false,
  isEditGooglePlaceIdLoading: false,
  isEditGooglePlaceIdModalOpen: false,
  isLoadingGooglePlaceSearchEdit: false,
  isFetchingStore: false,
  name: '',
  searchText: '',
  zipcode: '',
  streetName: '',
  streetNumber: '',
  complement: '',
  district: '',
  uf: '',
  city: '',
  ibge: 0,
}

export type PutStoreLogoPayload = {
  storeId: string
  logo: File
}

export type EditGooglePlaceIdPayload = {
  storeId: string
  body: IPutStorePlace
}

export const StoresSliceReducer = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    resetState: (state, action: PayloadAction<undefined>) => {
      return { ...initialState }
    },

    /*********** CREATE STORE ***********/
    createStore: (state, action: PayloadAction<PostStoreRequest>) => {
      // Used in Redux-Saga
      return state
    },
    createStoreSuccess: (state, action: PayloadAction<IStoreResponse>) => {
      toaster.showSuccessToast(`Loja criada com sucesso.`)
      return {
        ...state,
        name: '',
        zipcode: '',
        streetName: '',
        streetNumber: '',
        complement: '',
        district: '',
        uf: '',
        city: '',
        ibge: undefined,
        isCreateStoreLoading: false,
        isCreateStoreModalOpen: false,
        googlePlaceSearch: undefined,
      }
    },
    createStoreError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      toaster.showErrorToast(
        `Não foi possível criar loja. ${
          error.response?.data.statusCode ? `Erro ${error.response?.data.statusCode}` : ''
        }`
      )
      return { ...state, isCreateStoreLoading: false }
    },
    createStoreLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isCreateStoreLoading: true }
    },
    createStoreModalOpen: (state, action: PayloadAction<undefined>) => {
      return { ...state, isCreateStoreModalOpen: true }
    },
    createStoreModalClose: (state, action: PayloadAction<undefined>) => {
      return {
        ...state,
        isCreateStoreModalOpen: false,
      }
    },

    /*********** FETCH RETAILER STORES ***********/
    fetchRetailerStores: (state, action: PayloadAction<string>) => {
      return { ...state, stores: undefined, storesNames: [], error: undefined }
    },
    fetchRetailerStoresSuccess: (state, action: PayloadAction<FetchStoreResponse[]>) => {
      const stores = action.payload
      const storesNames = stores.map(store => store.name)
      return { ...state, storesNames, stores }
    },
    fetchRetailerStoresError: (state, action: PayloadAction<AxiosError>) => {
      toaster.showErrorToast(`Erro ao carregar lojas.`)
      return { ...state, error: action.payload, stores: [] }
    },

    /*********** FETCH STORE ***********/
    fetchStore: (state, action: PayloadAction<string>) => {
      return { ...state, store: undefined, error: undefined, errorType: undefined, isFetchingStore: true }
    },
    fetchStoreSuccess: (state, action: PayloadAction<FetchStoreResponse>) => {
      const store = action.payload
      return { ...state, store, isFetchingStore: false }
    },
    fetchStoreError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      if (error.response?.status === 404) {
        return {
          ...state,
          error: action.payload,
          errorType: StoresReducerErrors.STORE_NOT_FOUND,
          isFetchingStore: false,
        }
      }

      toaster.showErrorToast(`Erro ao carregar loja.`)
      return { ...state, error: action.payload, isFetchingStore: false }
    },

    /*********** FETCH STORE EMAILS ***********/
    fetchStoreEmails: (state, action: PayloadAction<string[]>) => {
      return { ...state, emails: undefined }
    },
    fetchStoreEmailsSuccess: (state, action: PayloadAction<IEmailResponse[]>) => {
      const emails = action.payload
      return { ...state, emails }
    },
    fetchStoreEmailsError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      if (error.response?.status === 404) {
        return { ...state, error: action.payload, emails: [] }
      }

      toaster.showErrorToast(`Erro ao carregar e-mails da loja.`)
      return { ...state, error: action.payload, emails: [] }
    },

    /*********** PUT STORE LOGO ***********/
    putStoreLogo: (state, action: PayloadAction<PutStoreLogoPayload>) => {
      // Used in Redux-Saga
      return state
    },
    putStoreLogoLoading: (state, action: PayloadAction<undefined>) => {
      // Used in Redux-Saga
      return { ...state, isPutStoreLogoLoading: true }
    },
    putStoreLogoSuccess: (state, action: PayloadAction<undefined>) => {
      return { ...state, isPutStoreLogoLoading: false }
    },
    putStoreLogoError: (state, action: PayloadAction<AxiosError>) => {
      toaster.showErrorToast(`Erro ao atualizar logo da loja.`)
      return { ...state, isPutStoreLogoLoading: false }
    },

    /*********** SEARCH GOOGLE PLACE ***********/
    searchGooglePlace: (state, action: PayloadAction<IPostGooglePlaceSearch>) => {
      // Used in Redux-Saga
      return state
    },
    searchGooglePlaceLoading: (state, action: PayloadAction<undefined>) => {
      // If its a search to edit Google Place ID, change the "*Edit" states
      if (state.isEditGooglePlaceIdModalOpen) {
        return { ...state, isLoadingGooglePlaceSearchEdit: true }
      }

      return { ...state, isLoadingGooglePlaceSearch: true }
    },
    searchGooglePlaceSuccess: (state, action: PayloadAction<IPlaceSearchResponse>) => {
      const googlePlaceSearch = action.payload

      // If its a search to edit Google Place ID, change the "*Edit" states
      if (state.isEditGooglePlaceIdModalOpen) {
        return { ...state, googlePlaceSearchEdit: googlePlaceSearch, isLoadingGooglePlaceSearchEdit: false }
      }

      return { ...state, googlePlaceSearch, isLoadingGooglePlaceSearch: false }
    },
    searchGooglePlaceError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      if (error.response?.status !== 404) {
        toaster.showErrorToast(`Erro ao buscar loja na Google Place.`)
      }

      // If its a search to edit Google Place ID, change the "*Edit" states
      if (state.isEditGooglePlaceIdModalOpen) {
        return { ...state, isLoadingGooglePlaceSearchEdit: false, googlePlaceSearchEdit: null }
      }

      return { ...state, isLoadingGooglePlaceSearch: false, googlePlaceSearch: undefined }
    },

    /*********** EDIT GOOGLE PLACE ID ***********/
    editGooglePlaceId: (state, action: PayloadAction<EditGooglePlaceIdPayload>) => {
      // Used in Redux-Saga
      return state
    },
    editGooglePlaceIdLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isEditGooglePlaceIdLoading: true }
    },
    editGooglePlaceIdSuccess: (state, action: PayloadAction<FetchStoreResponse>) => {
      const store = action.payload
      toaster.showSuccessToast('Google Place Id alterado com sucesso.')
      return { ...state, store, isEditGooglePlaceIdLoading: false, googlePlaceSearchEdit: undefined }
    },
    editGooglePlaceIdError: (state, action: PayloadAction<AxiosError>) => {
      toaster.showErrorToast(`Erro ao editar Google Place Id da loja.`)
      return { ...state, isEditGooglePlaceIdLoading: false }
    },
    editGooglePlaceIdModalOpen: (state, action: PayloadAction<undefined>) => {
      return { ...state, isEditGooglePlaceIdModalOpen: true }
    },
    editGooglePlaceIdModalClose: (state, action: PayloadAction<undefined>) => {
      return { ...state, isEditGooglePlaceIdModalOpen: false }
    },
    setStoreAddress: (state, action: PayloadAction<IStoreAddress>) => {
      const storeAddress = action.payload
      return { ...state, ...storeAddress }
    },
    updateStoreFields: (state, action: PayloadAction<IStoresState>) => {
      const fields = action.payload
      return { ...state, ...fields }
    },
  },
})
