import React from 'react'
import { UMETabsContainer } from '../../pages/Retailers/style'
import { RetailersTab } from './RetailersTab'

export const RetailersTabsContainer = (props: any) => {
  let { onTabClicked, selectedTabIndex } = props
  const RETAILER_TAB_INDEX = 0
  const STORE_TAB_INDEX = 1
  const OPERATOR_TAB_INDEX = 2
  const FINANCIAL_TAB_INDEX = 3

  return (
    <UMETabsContainer>
        <RetailersTab
            onClick={() => onTabClicked(RETAILER_TAB_INDEX)}
            title={'Informações'}
            active={selectedTabIndex !== RETAILER_TAB_INDEX}
        />
        <RetailersTab
            onClick={() => onTabClicked(STORE_TAB_INDEX)}
            title={'Lojas'}
            active={selectedTabIndex !== STORE_TAB_INDEX}
        />
        <RetailersTab
            onClick={() => onTabClicked(OPERATOR_TAB_INDEX)}
            title={'Operadores'}
            active={selectedTabIndex !== OPERATOR_TAB_INDEX}
        />
        <RetailersTab
            onClick={() => onTabClicked(FINANCIAL_TAB_INDEX)}
            title={'Financeiro'}
            active={selectedTabIndex !== FINANCIAL_TAB_INDEX}
        />
    </UMETabsContainer>
  )
}