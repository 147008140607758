import { Loading, Text } from '@bit/ume.design-kit.ui'
import { Box } from 'grommet'
import * as React from 'react'
import { ResponsiveGrid } from '../../atoms/ResponsiveGrid/ResponsiveGrid'
import { InfoTextStyle, LabelStyle } from '../../pages/CS-Profile/ContractsSummaryCard/style'
import { RenegotiationPaymentResponse } from '../../services/bff-backoffice/coordinator/interfaces/renegotiation.interfaces'
import { formatDateTreatingError, formatNumberToBRL } from '../../utils'
import { CardStyle, DataBoxStyle } from './style'

export interface IRenegotiationPaymentPaidValuesProps {
  renegotiationPayment?: RenegotiationPaymentResponse | Error
}

export default class RenegotiationPaymentPaidValues extends React.PureComponent<IRenegotiationPaymentPaidValuesProps> {
  public render() {
    const { renegotiationPayment } = this.props

    const errorCard = (
      <Text color="red" light>
        Não foi possível carregar os dados para este pagamento.
      </Text>
    )

    const RenegotiationPaymentPaidValuesCard = (renegotiationPayment: RenegotiationPaymentResponse) => (
      <Box>
        <ResponsiveGrid columns="150px" gap="small">
          <DataBoxStyle>
            <LabelStyle>Juros de mora</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(renegotiationPayment.defaultInterest)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Valor devido</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(renegotiationPayment.paymentDue)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Data do pgto</LabelStyle>
            <InfoTextStyle>
              {renegotiationPayment.paymentTimestamp
                ? formatDateTreatingError(renegotiationPayment.paymentTimestamp)
                : '-'}
            </InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Correção monetária</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(renegotiationPayment.monetaryCorrection)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Desconto</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(renegotiationPayment.earlyPaymentDiscount)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Valor pago</LabelStyle>
            <InfoTextStyle>
              {renegotiationPayment.paidValue ? formatNumberToBRL(renegotiationPayment.paidValue) : '-'}
            </InfoTextStyle>
          </DataBoxStyle>
        </ResponsiveGrid>
      </Box>
    )
    return (
      <CardStyle width="552px">
        {renegotiationPayment === undefined ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : renegotiationPayment instanceof Error ? (
          errorCard
        ) : (
          RenegotiationPaymentPaidValuesCard(renegotiationPayment)
        )}
      </CardStyle>
    )
  }
}
