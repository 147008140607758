import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Box, Text } from 'grommet'
import { PhoneVertical } from 'grommet-icons'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { ResponsiveGrid } from '../../atoms/ResponsiveGrid/ResponsiveGrid'
import { laptop } from '../../common/assets/_breakpoints'
import ApplicationsTitle from '../../molecules/Applications/ApplicationsTitle'
import BatchesBreadcrumbs from '../../molecules/Applications/BatchesBreadcrumbs'
import BatchesCard from '../../molecules/Applications/BatchesCard'
import UnknownError from '../../organisms/Applications/UnknownError'
import { ApplicationsSliceReducer } from '../../redux/reducers/applications/applications.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'

interface MatchParams {
  batchNumber?: string
}

interface Props extends RouteComponentProps<MatchParams> {}

const ApplicationsBatchesPage = ({ history }: Props) => {
  const dispatch = useDispatch()

  const { batches, isLoadingBatches, fetchBatchesError } = useTypedSelector(state => ({
    batches: state.applications.batches,
    isLoadingBatches: state.applications.isLoadingBatches,
    fetchBatchesError: state.applications.fetchBatchesError,
  }))

  useEffect(() => {
    dispatch(ApplicationsSliceReducer.actions.fetchBatches())
  }, [dispatch])

  if (isLoadingBatches) {
    return (
      <Box width="100%" height="90vh">
        <LoadingIcon />
      </Box>
    )
  }

  if (fetchBatchesError || !batches) {
    return <UnknownError />
  }

  return (
    <Container>
      <BatchesBreadcrumbs />
      <ApplicationsTitle
        title="Pacotes a analisar"
        icon={<PhoneVertical size="25px" color={UMEColors.green.darkPastel} />}
      />
      <Legend>Selecione uma caixa para ver os applications dentro dela:</Legend>

      <ResponsiveGrid margin={'24px'} columns="175px" gap="small">
        {batches.map(batch => (
          <BatchesCard
            history={history}
            key={batch.batchNumber}
            batchNumber={batch.batchNumber}
            numberOfApplications={batch.numberOfApplications}
          />
        ))}
      </ResponsiveGrid>
    </Container>
  )
}

const Container = styled(Box)`
  margin-top: 2vh;
  margin-bottom: 5vh;
  margin-left: 2vw;
  width: 60%;

  @media (max-width: ${laptop}) {
    width: 95%;
  }
`

const Legend = styled(Text)`
  text-align: left;
  margin-top: 16px;
  margin-left: 32px;
  color: #5b5b5b;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
`

export default ApplicationsBatchesPage
