import { Box } from 'grommet'
import styled from 'styled-components'

export const ElementsBox = styled(Box)`
  > img {
    position: absolute;
  }

  > img {
    &.top-right {
      top: 0;
      right: 0;

      &.mobile {
        top: 180px;
      }
    }
  }

  > img {
    &.bottom-right {
      bottom: 0;
      right: 0;
    }
  }

  > img {
    &.bottom-left {
      bottom: 0;
      left: 0;
    }
  }

  > img {
    &.top-left {
      top: 0;
      left: 0;

      &.mobile {
        top: 20px;
      }
    }
  }
`
