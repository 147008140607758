import { Box } from 'grommet'
import React, { Component } from 'react'
import { Title, ModalLayer, CloseButton, CloseIcon, CloseLabel, ModalContainer } from './styles'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  width: string
  modalTtitle?: string
}

export default class Modal extends Component<ModalProps> {

  render() {
    if (!this.props.isOpen) return <></>

    return (
      <ModalLayer {...this.props} responsive={false} onClickOutside={this.props.onClose}>
        <ModalContainer width={this.props.width}>
          <Box width="100%" align="center" justify="between" direction="row">
            {this.props.modalTtitle ? (
              <Title>{this.props.modalTtitle}</Title>
            ) : (
              <Title></Title>
            )}
            <CloseButton onClick={this.props.onClose}> <CloseLabel>Fechar</CloseLabel> <CloseIcon /> </CloseButton>
          </Box>
          {this.props.children}
        </ModalContainer>
      </ModalLayer>
    )
  }
}
