import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import React from 'react'
import styled, { css } from 'styled-components'
import XLSX from 'xlsx'
import { toaster } from '../../App'
import { ReactComponent as CabinetBaseIcon } from '../../common/assets/images/bx-cabinet.svg'
import { ReactComponent as StoreBaseIcon } from '../../common/assets/images/bx-store.svg'
import { screenSize } from '../../config/screens.config'
import UMEDataTable from '../../molecules/DataTable/DataTable'
import { FinancialCard } from '../../molecules/FinancialPayments/FinancialCard'
import { FinancialFilter } from '../../molecules/FinancialPayments/FinancialFilter'
import { Tag } from '../../molecules/FinancialPayments/Tag'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import {
  IBillingResponse,
  IBillingStatus,
  IPaymentResponse,
  IPaymentsAggResponse,
} from '../../services/bff-backoffice/billings/interfaces/billings.interfaces'
import {
  IRetailerResponse,
  IRetailersResponse,
  IStoreResponse,
  IStoresResponse,
} from '../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'
import { getRandomColor } from '../../utils'
import BillingModal from './BillingModal'
import {
  financialIncomingPaymentsCard,
  financialOverduePaymentsCard,
  financialPendingPaymentsCard,
  UMEDataTableHeader,
} from './config'
import CreateBillingStoryLine from './CreateBillingStoryLine'

interface IFinancialPaymentsPageProps {
  history: any
}

interface IFinancialPaymentsPageState {
  isModalOpen: boolean
  retailersName: string[]
  storesName: string[]

  isRetailerSelected: boolean

  selectedStores: {
    name: string
    backgroundColor: string
    textColor: string
  }[]

  originalData?: any[]
  data?: any[]
  selectedBilling?: IBillingResponse
  selectedTableIndex: number
  isDownloadingPayments: boolean

  pendingCard?: { quantity: number; price: number }
  overdueCard?: { quantity: number; price: number }
  incomingPaymentsCard?: { quantity: number; price: number }

  selectedRetailerIndex?: number
}

const DEFAULT_SELECT_OPTION = -1

export default class FinancialPaymentsPage extends React.Component<
  IFinancialPaymentsPageProps,
  IFinancialPaymentsPageState
> {
  private retailers?: IRetailerResponse[]
  private allStores?: IStoreResponse[]
  private stores?: IStoreResponse[]

  private selectedRetailerId?: string
  private selectedStoresId: string[]

  constructor(props: IFinancialPaymentsPageProps) {
    super(props)

    this.selectedStoresId = []

    this.state = {
      isModalOpen: false,
      isRetailerSelected: false,
      selectedStores: [],
      originalData: undefined,
      data: undefined,
      selectedBilling: undefined,
      retailersName: [],
      storesName: [],
      isDownloadingPayments: false,
      pendingCard: undefined,
      overdueCard: undefined,
      incomingPaymentsCard: undefined,
      selectedTableIndex: DEFAULT_SELECT_OPTION,

      selectedRetailerIndex: undefined,
    }

    this.retailers = undefined
    this.loadRetailersFromAPI()
    this.loadStoresFromAPI()
  }

  // Loads Retailers from API in order to replace the IDs coming from Billings API
  loadRetailersFromAPI = () => {
    bffBackofficeApiService.retailers
      .getRetailers()
      .then((result: IRetailersResponse) => {
        this.retailers = result.retailers
        // Get an array of retailers name for dropdown
        let retailersName = this.retailers.map((retailer: IRetailerResponse) => retailer.fantasyName)
        this.setState({ retailersName })

        this.fetchBillingsFromAPI()
      })
      .catch((error: any) => {
        toaster.showErrorToast(`Erro ao carregar varejistas`)

        // TODO - What happens if retailers don't load? - It will show only retailerId
        this.fetchBillingsFromAPI()
      })
  }

  // Loads Stores from API in order to replace the IDs coming from Billings API
  loadStoresFromAPI = () => {
    bffBackofficeApiService.retailers
      .getStores()
      .then((result: IStoresResponse) => {
        // TODO - Create an endpoint for this
        this.allStores = result.stores

        // 1. Filter only the retailer's store
        this.stores = result.stores.filter((store: IStoreResponse) => store.retailerId === this.selectedRetailerId)

        // 2. Get only the store's name
        let storesName = this.stores.map((store: IStoreResponse) => store.name)
        this.setState({ storesName })
      })
      .catch((error: any) => {
        // TODO - Toaster with error
        toaster.showErrorToast(`Erro ao carregar lojas`)
      })
  }

  // Fetches Billings from Backend
  fetchBillingsFromAPI = () => {
    this.setState({
      data: undefined,
      incomingPaymentsCard: undefined,
      overdueCard: undefined,
      pendingCard: undefined,
      selectedBilling: undefined,
      selectedTableIndex: DEFAULT_SELECT_OPTION,
    })
    const statuses = ['pending', 'expired']
    if (!!this.selectedRetailerId) statuses.push('paid')
    let params = {
      retailerId: this.selectedRetailerId,
      storeId: this.selectedStoresId,
      statuses,
    }
    this.fetchPaymentsFromAPI()

    bffBackofficeApiService.billings
      .getBillings(params)
      .then((billingsResponse: IBillingResponse[]) => {
        let pendingBills = { quantity: 0, price: 0 }
        let overdueBills = { quantity: 0, price: 0 }

        let billings = billingsResponse.map((billing: IBillingResponse) => {
          // TODO - Invoice is not optional
          let stores = billing.stores
            ? billing.stores.length > 2
              ? 'Várias Lojas'
              : billing.stores.map(s => this.findStoreName(s)).join(', ')
            : 'Todas as lojas'

          // Update cards
          // Renegotiated billings count as expired
          let billingStatus = billing.status as string
          if (billing.status === 'pending' && billing.numberOfInvoices > 1) {
            billingStatus = 're-issuance'
            overdueBills.quantity += billing.paymentsCount
            overdueBills.price += billing.paymentsSum
          } else if (billing.status === 'pending') {
            pendingBills.quantity += billing.paymentsCount
            pendingBills.price += billing.paymentsSum
          } else if (billing.status === 'expired') {
            overdueBills.quantity += billing.paymentsCount
            overdueBills.price += billing.paymentsSum
          }

          return {
            ...billing,
            status: billingStatus,
            retailerName: this.findRetailerName(billing.retailerId),
            storesName: stores,
          }
        })

        this.setState({
          data: billings,
          originalData: billings,
          pendingCard: pendingBills,
          overdueCard: overdueBills,
        })
      })
      .catch((error: any) => {
        toaster.showErrorToast(`Erro ao buscar cobranças`)
      })
  }

  // Fetches payments from API
  //  - Updates card "Em Aberto": financialOpenedPaymentsCard
  fetchPaymentsFromAPI = () => {
    let params = {
      retailerId: this.selectedRetailerId,
      storeId: this.selectedStoresId,
      status: 'unbilled' as IBillingStatus,
    }

    bffBackofficeApiService.billings
      .getPaymentsAgg(params)
      .then((paymentsAgg: IPaymentsAggResponse) => {
        let incomingPaymentsCard = {
          quantity: paymentsAgg.paymentsCount,
          price: paymentsAgg.paymentsSum,
        }

        this.setState({ incomingPaymentsCard })
      })
      .catch(error => {
        toaster.showErrorToast(`Erro ao buscar pagamentos!`)

        if (error && error.response && error.response.data) {
          console.error(error.response.data)
        } else {
          console.error(error)
        }
      })
  }

  // When Retailer is selected from Dropdown: it gives the dropdown index
  onRetailerSelect = (index?: number) => {
    // The default element was selected
    if (!index) {
      this.selectedRetailerId = undefined
      this.selectedStoresId = []

      this.fetchBillingsFromAPI()
      this.setState({
        isRetailerSelected: false,
        storesName: [],
        selectedStores: [],
        selectedRetailerIndex: DEFAULT_SELECT_OPTION,
      })
      return
    }
    if (!this.retailers) return

    this.selectedRetailerId = this.retailers[index].id

    // Updates Stores
    // TODO - This could only filter the already existing stores in this.allStores
    this.loadStoresFromAPI()
    // Updates Billings table and cards accordingly to the chosen retailer
    this.fetchBillingsFromAPI()

    this.setState({ isRetailerSelected: true, storesName: [], selectedStores: [], selectedRetailerIndex: index })
  }

  // When Store is selected from Dropdown: it gives the dropdown index
  onStoreSelect = (index?: number) => {
    if (!index) {
      this.selectedStoresId = []
      this.setState({ selectedStores: [] })
      this.fetchBillingsFromAPI()
      return
    }
    if (!this.stores) return

    let selectedStore = this.stores[index]

    let selectedStores = Object.assign([], this.state.selectedStores)

    // If store was already selected, ignore it
    if (this.state.selectedStores.find(store => store.name === selectedStore.name)) {
      toaster.showWarningToast(`Loja ${selectedStore.name} já selecionada!`)
      return
    }

    // Sets random color
    let color = getRandomColor()
    this.selectedStoresId.push(selectedStore.id)
    selectedStores.push({
      name: selectedStore.name,
      backgroundColor: color.backgroundColor,
      textColor: color.textColor,
    })

    this.fetchBillingsFromAPI()

    this.setState({ selectedStores })
  }

  // When Table Row is clicked, it can be a deselection event
  onTableRowClicked = (element: any, index: number) => {
    this.setState({ selectedBilling: element, selectedTableIndex: index, isModalOpen: true })
  }

  // On tag clicked: remove store
  onTagClicked = (label: string, index: number) => {
    if (!this.state.selectedStores) return
    let selectedStores = Object.assign([], this.state.selectedStores)
    selectedStores.splice(index, 1)
    this.selectedStoresId.splice(index, 1)

    // Updates screen (damn this API suffers)
    this.fetchBillingsFromAPI()
    this.setState({ selectedStores })
  }

  // On Filter selected on the Data Table: Filter data so DataTable can render only the filtered elements
  onFilterSelected = (attribute: string, valueChangedTo: string) => {
    // Reset the filtered data to the original data
    // TODO - Export the default value from Data Table as: DataTable.DEFAULT_FILTER
    if (valueChangedTo === 'Todos') {
      let data = Object.assign([], this.state.originalData)
      this.setState({ data, selectedBilling: undefined, selectedTableIndex: DEFAULT_SELECT_OPTION })
      return
    }

    let data = Object.assign([], this.state.originalData)
    data = data.filter((el: any) => el[attribute] === valueChangedTo)

    this.setState({ data, selectedBilling: undefined, selectedTableIndex: DEFAULT_SELECT_OPTION })
  }

  onDownloadIncomingPayments = async () => {
    let params = {
      retailerId: this.selectedRetailerId,
      storeId: this.selectedStoresId,
      status: 'unbilled' as IBillingStatus,
    }

    // TODO - Set Loading on UI for this
    bffBackofficeApiService.billings
      .getPayments(params)
      .then((data: IPaymentResponse[]) => {
        // TODO - Improve this file name
        let fileName = 'pagamentos_entrando.xlsx'

        this.setState({ isDownloadingPayments: false })
        const ws = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, `Pagamentos`)
        XLSX.writeFile(wb, fileName)

        toaster.showSuccessToast(`Pagamentos baixados!`)
      })
      .catch((error: any) => {
        toaster.showErrorToast(`Erro ao baixar pagamentos`)
        this.setState({ isDownloadingPayments: false })

        if (error && error.response && error.response.data) {
          console.error(error.response.data)
        } else {
          console.error(error)
        }
      })
  }

  // Internal usage only - It could be generalized but the function name wouldn't be as clear as it is now
  findStoreName = (storeId: string) => {
    if (!this.allStores) return storeId
    else {
      let store = this.allStores.find(store => store.id === storeId)
      if (!store) return storeId
      else return store.name
    }
  }

  // Internal usage only - It could be generalized but the function name wouldn't be as clear as it is now
  findRetailerName = (retailerId: string) => {
    if (!this.retailers) return retailerId
    else {
      let retailer = this.retailers.find(retailer => retailer.id === retailerId)
      if (!retailer) return retailerId
      else return retailer.fantasyName
    }
  }

  // When a Billing is created from the Billing Storyline
  //      -- Cool Animation + Show the created billing in the DataTable (also reflect data on financial cards)
  onBillingCreated = (billing: IBillingResponse) => {
    let doc = document.getElementById('financial-breadcumb')
    if (doc) {
      doc.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    this.selectedRetailerId = billing.retailerId
    this.selectedStoresId = billing.stores ? billing.stores : []

    // Set the corresponding select index
    let selectedRetailerIndex = this.retailers
      ? this.retailers.findIndex(retailer => retailer.id === billing.retailerId)
      : DEFAULT_SELECT_OPTION

    let selectedStores = billing.stores
      ? billing.stores.map(storeId => {
          let color = getRandomColor()

          return {
            name: this.findStoreName(storeId),
            backgroundColor: color.backgroundColor,
            textColor: color.textColor,
          }
        })
      : []

    this.setState({
      selectedStores,
      isRetailerSelected: true,
      selectedRetailerIndex,
    })

    this.fetchBillingsFromAPI()
  }

  render() {
    let {
      data,
      selectedStores,
      storesName,
      retailersName,
      pendingCard,
      incomingPaymentsCard,
      overdueCard,
      selectedTableIndex,
      selectedRetailerIndex,
      isRetailerSelected,
    } = this.state

    return (
      <>
        {/* Download payments and generate invoices modal  */}
        <BillingModal
          height="large"
          width="xxlarge"
          round="large"
          isModalOpen={this.state.isModalOpen}
          onClose={() => {
            this.setState({ isModalOpen: false, selectedBilling: undefined, selectedTableIndex: DEFAULT_SELECT_OPTION })
          }}
          selectedBilling={this.state.selectedBilling}
          billing={this.state.data ? this.state.data[this.state.selectedTableIndex] : Error}
        ></BillingModal>

        {/* FILTERS - DROPDOWN */}
        <FiltersContainer content="Filters Container">
          <FinancialUMEFilter
            Icon={RetailerIcon}
            label={'Escolha o varejo'}
            default={'Todos'}
            select={retailersName}
            onDropDownSelect={this.onRetailerSelect}
            selectedIndex={selectedRetailerIndex}
          />
          <FinancialUMEFilter
            Icon={StoreIcon}
            label={'Escolha as lojas'}
            default={'Todas as lojas'}
            select={storesName}
            disabled={!isRetailerSelected}
            onDropDownSelect={this.onStoreSelect}
          />
          <TagsContainer>
            {selectedStores &&
              selectedStores.map((store, index: number) => (
                <Tag
                  index={index}
                  key={index}
                  label={store.name}
                  backgroundColor={store.backgroundColor}
                  onClick={this.onTagClicked}
                />
              ))}
          </TagsContainer>
        </FiltersContainer>

        {/* CARDS */}
        <UMEFinancialCardsContainer>
          <FinancialCard
            data={pendingCard}
            header={financialPendingPaymentsCard.header}
            card={financialPendingPaymentsCard.card}
          />
          <FinancialCard
            data={overdueCard}
            header={financialOverduePaymentsCard.header}
            card={financialOverduePaymentsCard.card}
            red
          />
          <VerticalLine />
          <FinancialCard
            data={incomingPaymentsCard}
            header={financialIncomingPaymentsCard.header}
            card={financialIncomingPaymentsCard.card}
            download={financialIncomingPaymentsCard.download}
            onDownload={this.onDownloadIncomingPayments}
          />
        </UMEFinancialCardsContainer>

        {/* TABLE SECTION */}
        <TableSectionContainer>
          <CabinetIcon />
          <TableSectionTitle> Histórico das Cobranças {data ? `(${data.length})` : ``} </TableSectionTitle>
        </TableSectionContainer>

        <TableContainer isLoading={data ? false : true}>
          <UMEDataTable
            data={data}
            header={UMEDataTableHeader}
            onRowElementClick={this.onTableRowClicked}
            onFilterSelected={this.onFilterSelected}
            selectedIndex={selectedTableIndex}
          />
        </TableContainer>

        <CreateBillingStoryLine onBillingCreated={this.onBillingCreated} />
      </>
    )
  }
}

const FinancialUMEFilter = styled(FinancialFilter)`
  flex: 1 1 400px;

  margin: 10px 10px 10px 0px;

  max-width: 400px;
  &:nth-child(1) {
    margin-right: 5vw;
  }

  @media ${screenSize.mobileL} {
    &:nth-child(1) {
      margin-right: 0vw;
    }
  }
`

const RetailerIcon = styled(StoreBaseIcon)`
  height: 35px;
  width: 35px;
  fill: ${UMEColors.rose.primary};
`

const StoreIcon = styled(StoreBaseIcon)`
  height: 35px;
  width: 35px;
  fill: ${UMEColors.orange.amber};
`

const FiltersContainer = styled.div`
  margin-top: 4vh;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`

const TagsContainer = styled.div`
  margin-left: 20px;
  min-height: 6vh;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  // Tags inside the container
  & > div {
    margin: 10px;
  }

  @media ${screenSize.mobileL} {
    margin-left: 0;
    margin-top: 2vh;
  }
`

const UMEFinancialCardsContainer = styled.div`
  margin-top: 4vh;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  @media ${screenSize.mobileL} {
    display: none;
    display: flex;
    justify-content: center;
  }
`

const VerticalLine = styled.div`
  box-sizing: border-box;
  height: 282px;
  width: 1px;
  border: 1px solid ${UMEColors.black.primary};
  opacity: 0.1;
  margin: 10px 39.5px 0px 39.5px;

  @media ${screenSize.mobileL} {
    display: none;
  }
`

const CabinetIcon = styled(CabinetBaseIcon)`
  height: 30px;
  width: 30px;
  // TODO - Not in Design Kit
  fill: #6e6e6e;
`
const TableSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 4vh;
`

const TableSectionTitle = styled.div`
  text-align: center;
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin-left: 18px;
`
const TableContainer = styled.div`
  ${(props: any) =>
    props.isLoading &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  margin-top: 2vh;

  min-height: 400px;
  max-height: 400px;
  border-radius: 8px;
  background-color: ${UMEColors.white.primary};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 33px;
  overflow-x: auto;
  overflow-y: auto;
`
