import * as React from 'react'
import { CardIcon, SectionCard, SectionContainer, Subtitle } from './style'
import { Link } from 'react-router-dom'

export const HomeCard = (props: { subtitle: string; icon: any; to: string }) => (
  <Link to={props.to}>
    <SectionContainer>
      <SectionCard>
        <CardIcon>{props.icon}</CardIcon>
      </SectionCard>
      <div style={{ marginTop: 10 }}>
        <Subtitle>{props.subtitle}</Subtitle>
      </div>
    </SectionContainer>
  </Link>
)
