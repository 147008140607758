import { Anchor } from '@bit/ume.design-kit.ui'
import { Box } from 'grommet'
import * as React from 'react'
import Lightbox from 'react-lightbox-component'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { FormCloseIconStyle, ImagesContainer, ModalStyle, TitleStyle } from './style'

export interface IBorrowerImagesModalProps {
  borrowerId: string
}

export interface IBorrowerImagesModalState {
  images: { src: string; title: string; description: string }[]
  isOpen: boolean
}

const initialState: IBorrowerImagesModalState = {
  images: [],
  isOpen: false,
}

export default class BorrowerImagesModal extends React.Component<IBorrowerImagesModalProps, IBorrowerImagesModalState> {
  constructor(props: IBorrowerImagesModalProps) {
    super(props)

    this.state = initialState
  }

  open = async () => {
    this.setState({ isOpen: true })
    const images = await bffBackofficeApiService.borrowers.getBorrowerPhotos(this.props.borrowerId)

    this.setState({
      images: images.map(img => ({
        description: (img as any).createdOn,
        src: !img.imagebase64.startsWith('data:') ? `data:image/jpeg;base64,${img.imagebase64}` : img.imagebase64,
        title: (img as any).createdOn,
      })),
    })
  }

  close = () => {
    this.setState(initialState)
  }

  public render() {
    const { isOpen, images } = this.state

    return (
      <Box>
        <Anchor alignSelf="end" margin="0 12px" fontSize="12px" onClick={this.open}>
          mais fotos
        </Anchor>

        {isOpen && (
          <ModalStyle responsive={false} modal={true} onEsc={() => {}} onClickOutside={this.close}>
            <Box gap="medium">
              <Box direction="row" justify="between" align="start">
                <TitleStyle>Todas as fotos registradas</TitleStyle>
                <FormCloseIconStyle color="black" onClick={this.close} />
              </Box>
              <ImagesContainer>
                {images.length > 1 ? (
                  <Lightbox
                    thumbnailMargin="8px"
                    images={images}
                    thumbnailWidth="68px"
                    thumbnailHeight="auto"
                    thumbnailBorder="4px"
                  />
                ) : (
                  <LoadingIcon />
                )}
              </ImagesContainer>
            </Box>
          </ModalStyle>
        )}
      </Box>
    )
  }
}
