import { Keyboard, MaskedInput } from 'grommet'
import { Search } from 'grommet-icons'
import React from 'react'
import { toaster } from '../../App'
import maskCpf from '../../common/assets/utils/cpfMask'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { IBorrowerResponse } from '../../services/bff-backoffice/borrowers/interfaces/borrower.interfaces'
import BorrowerProfilePage from '../CS-Profile/BorrowerProfilePage'
import BorrowerRenegotiation from './BorrowerRenegotiation'
import { BorrowersPageContainer, CPFFlexRowContainer, CPFTextInputContainer, Divider } from './styles'

interface IBorrowersPageProps {
  history?: any
  match?: any
  location?: any
}

interface IBorrowersPageState {
  cpf: string
  noPunctCpf: string
  loadingBorrower: boolean
  borrower?: IBorrowerResponse
  activeTab: 0
}

const CpfMaskInput = [
  {
    length: 3,
    regexp: /\d/,
    placeholder: '000',
  },
  { fixed: '.' },
  {
    length: 3,
    regexp: /\d/,
    placeholder: '000',
  },
  { fixed: '.' },
  {
    length: 3,
    regexp: /\d/,
    placeholder: '000',
  },
  { fixed: '-' },
  {
    length: 2,
    regexp: /\d/,
    placeholder: '00',
  },
]

export default class BorrowersPage extends React.Component<IBorrowersPageProps, IBorrowersPageState> {
  constructor(props: IBorrowersPageProps) {
    super(props)
    this.state = {
      cpf: '',
      noPunctCpf: '',
      loadingBorrower: false,
      activeTab: 0,
    }
  }

  componentDidMount() {
    const { borrowerId } = this.props.match.params

    if (borrowerId) {
      this.loadBorrowerById(borrowerId)
    }
  }

  loadBorrowerById = (borrowerId: string) => {
    this.setState({ loadingBorrower: true })

    bffBackofficeApiService.borrowers
      .getBorrowerById(borrowerId)
      .then((borrower: IBorrowerResponse) => {
        this.setState({ borrower, cpf: maskCpf(borrower.cpf), noPunctCpf: borrower.cpf, loadingBorrower: false })
        toaster.showSuccessToast('Tomador encontrado!')
      })
      .catch(() => {
        toaster.showErrorToast('Tomador não encontrado')
        this.setState({ loadingBorrower: false })
      })
  }

  loadBorrower = () => {
    let { cpf } = this.state

    const noPunctCpf = cpf
      .split('-')
      .join('')
      .split('.')
      .join('')

    this.setState({ loadingBorrower: true })

    bffBackofficeApiService.borrowers
      .getBorrowerByDocument(noPunctCpf)
      .then((borrower: IBorrowerResponse) => {
        this.props.history.push(`/borrowers/${borrower.id}/info`)
        this.setState({ borrower, cpf: maskCpf(borrower.cpf), noPunctCpf: borrower.cpf, loadingBorrower: false })
        toaster.showSuccessToast('Tomador encontrado!')
      })
      .catch(() => {
        toaster.showErrorToast('Tomador não encontrado')
        this.setState({ loadingBorrower: false })
      })
  }

  cpfOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ cpf: event.target.value, borrower: undefined })
  }

  render() {
    const { cpf, borrower } = this.state

    return (
      <BorrowersPageContainer>
        <br /> <br />
        <CPFFlexRowContainer>
          <CPFTextInputContainer>
            <Keyboard onEnter={this.loadBorrower}>
              <MaskedInput
                mask={CpfMaskInput}
                onInput={this.cpfOnChange}
                value={cpf}
                placeholder="Digite o CPF"
                reverse
                icon={<Search style={{ cursor: 'pointer', pointerEvents: 'visible' }} onClick={this.loadBorrower} />}
              />
            </Keyboard>
          </CPFTextInputContainer>
        </CPFFlexRowContainer>
        {borrower ? (
          <>
            <br /> <br />
            <Divider />
            {this.props.location.pathname.endsWith('renegotiation') ? (
              <BorrowerRenegotiation history={this.props.history} borrowerId={borrower.id} />
            ) : (
              <BorrowerProfilePage history={this.props.history} match={this.props.match} previousPage="Tomadores" />
            )}
          </>
        ) : (
          <>
            <br /> <br />
          </>
        )}
      </BorrowersPageContainer>
    )
  }
}
