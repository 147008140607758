import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { AxiosError } from 'axios'
import React from 'react'
import styled, { css } from 'styled-components'
import {IBillingResponse} from '../../services/bff-backoffice/billings/interfaces/billings.interfaces'
import { formatDate, formatNumberToBRL } from '../../utils'

// TODO - Set this on coordinator-client
export enum HttpBillingsErrors {
  NO_PAYMENTS_AVAILABLE = 'NO_PAYMENTS_AVAILABLE',
  BILLING_NOT_FOUND = 'BILLING_NOT_FOUND',
  INVOICE_STILL_VALID = 'INVOICE_STILL_VALID',
  DUPLICATE_BILLING_PAYMENT = 'DUPLICATE_BILLING_PAYMENT',
  INVALID_INVOICE_DUE_DATE = 'INVALID_INVOICE_DUE_DATE',
  DATE_RANGE_OVERLAP = 'DATE_RANGE_OVERLAP',
  INVALID_DATE_RANGE = 'INVALID_DATE_RANGE',
}

export const getErrorStringForBillingsHttpErrors = (errorCode: string, error: AxiosError) => {
  if (errorCode === HttpBillingsErrors.NO_PAYMENTS_AVAILABLE) {
    return `Nenhum pagamento disponível neste intervalo`
  } else if (errorCode === HttpBillingsErrors.BILLING_NOT_FOUND) {
    return `Cobrança não encontrada`
  } else if (errorCode === HttpBillingsErrors.INVOICE_STILL_VALID) {
    return `Cobrança ainda esta válida. Espere o último boleto expirar para criar um novo`
  } else if (errorCode === HttpBillingsErrors.INVALID_INVOICE_DUE_DATE) {
    return `Data de vencimento do boleto inválida`
  } else if (errorCode === HttpBillingsErrors.DATE_RANGE_OVERLAP) {
    return error.response ? error.response.data.message : `Já existe uma cobrança com essas datas`
  } else if (errorCode === HttpBillingsErrors.INVALID_DATE_RANGE) {
    return `Data de ínicio não pode estar depois da data de fim`
  } else {
    return `Erro não identificado. Entrar em contato com a engenharia`
  }
}

export const financialPendingPaymentsCard = {
  header: { title: 'A Pagar', subtitle: 'Boletos enviados' },
  card: {
    description: 'Boletos pendentes de pagamentos',
    quantityText: 'Número de pagamentos',
    priceText: 'Valor financeiro',
  },
}

export const financialOverduePaymentsCard = {
  header: { title: 'Vencido', subtitle: 'Em atraso' },
  card: {
    description: 'Boletos vencidos',
    quantityText: 'Número de pagamentos',
    priceText: 'Valor financeiro',
  },
}

export const financialIncomingPaymentsCard = {
  header: { title: 'Em Aberto', subtitle: 'Está sendo pago' },
  card: {
    description: 'Valor que está entrando',
    quantityText: 'Número de pagamentos',
    priceText: 'Valor financeiro',
  },
  download: `pagamentos em aberto.`,
}

export const UMEDataTableHeader = [
  {
    label: 'ID',
    attribute: 'id'
  },
  {
    label: 'Criado em:',
    attribute: 'createdOn',
    formatCell: (row: IBillingResponse) => {
      if (row.invoice)
        return (
          <InvoiceLink color="inherit" target="_blank" rel="noopener noreferrer" href={row.invoice.url}>
            {formatDate(row.createdOn)}
          </InvoiceLink>
        )
      else return formatDate(row.createdOn)
    },
  },
  {
    label: 'Referente a pagamentos de:',
    attribute: 'startDate',
    formatCell: (row: IBillingResponse) => `${formatDate(row.startDate)} - ${formatDate(row.endDate)}`,
  },
  { label: 'Varejo', attribute: 'retailerName' },
  { label: 'Loja', attribute: 'storesName' },
  {
    label: 'Valor do boleto',
    attribute: 'paymentsSum',
    formatCell: (row: IBillingResponse) => {
      if (row.invoice) return formatNumberToBRL(row.invoice.value)
      else return '---'
    },
  },
  {
    label: 'Status do email',
    attribute: 'emailStatus',
    formatCell: (row: any) => {
      let emailTimestamp = formatDate(row.lastEmailTimestamp)
      if (!row.lastEmailStatus || row.lastEmailStatus === 'ERROR') {
        return 'Não enviado'
      } else {
        switch (row.lastEmailStatus) {
          case 'SENT':
            return `Enviado -- ${emailTimestamp}`
          case 'ERROR':
            return `Não enviado -- ${emailTimestamp}`
          case 'WARNING':
            return `Enviado parcialmente -- ${emailTimestamp}`
        }
      }
    },
  },
  {
    label: 'Venc. Boleto',
    // TODO - When formatCell is given, attribute is not used
    attribute: 'invoice.dueDate',
    formatCell: (row: IBillingResponse) => {
      if (row.invoice) return formatDate(row.invoice.dueDate)
      else return '---'
    },
  },
  {
    label: 'Status',
    attribute: 'status',
    dropDownValues: [
      { label: 'A pagar', value: 'pending' },
      { label: 'Pago', value: 'paid' },
      { label: 'Vencido', value: 'expired' },
    ],
    formatCell: (row: any) => {
      // TODO - None of theses colors are in the design kit
      if (row.status === 're-issuance') {
        return <BillingStatusText backgroundColor="#FF8B00">Segunda Via</BillingStatusText>
      }
      switch (row.status) {
        case 'pending':
          return <BillingStatusText backgroundColor="#0048D6">A Pagar</BillingStatusText>
        case 'paid':
          return <BillingStatusText backgroundColor="#1F9901">Pago</BillingStatusText>
        case 'expired':
          return <BillingStatusText backgroundColor="#FF2601">Vencido</BillingStatusText>
        default:
          return row.status
      }
    },
  },
]

const BillingStatusText = styled.span`
  ${(props: any) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
  display: inline-block;
  height: 100%;
  width: 80%;
  text-align: center;
  border-radius: 2;
  color: ${UMEColors.white.primary};
`

const InvoiceLink = styled.a`
  color: inherit;
  text-decoration: underline;

  &:hover {
    opacity: 0.4;
    color: inherit;
  }
`
