import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { bffBackofficeApiService } from '../../../services/bff-backoffice'
import { IGetEmailResponse } from '../../../services/bff-backoffice/billings/interfaces/billings.interfaces'
import { IPlaceSearchResponse, IPostGooglePlaceSearch, IStoreResponse, PostStoreRequest } from '../../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'
import {
  FetchRetailerStoresResponse,
  FetchStoreResponse,
} from '../../../services/bff-backoffice/retailers/interfaces/stores.res'
import { EditGooglePlaceIdPayload, PutStoreLogoPayload, StoresSliceReducer } from './stores.reducer'

function* fetchRetailerStores(action: PayloadAction<string>) {
  try {
    const retailerId = action.payload
    const result: FetchRetailerStoresResponse = yield bffBackofficeApiService.retailers.getRetailerStores(retailerId)
    yield put(StoresSliceReducer.actions.fetchRetailerStoresSuccess(result.stores))
  } catch (error) {
    yield put(StoresSliceReducer.actions.fetchRetailerStoresError(error))
  }
}

function* fetchStore(action: PayloadAction<string>) {
  try {
    const storeId = action.payload
    const store: FetchStoreResponse = yield bffBackofficeApiService.retailers.getStoreById(storeId)
    yield put(StoresSliceReducer.actions.fetchStoreSuccess(store))
  } catch (error) {
    yield put(StoresSliceReducer.actions.fetchStoreError(error))
  }
}

function* fetchStoreEmails(action: PayloadAction<string[]>) {
  try {
    const storeIds = action.payload
    const result: IGetEmailResponse = yield bffBackofficeApiService.billings.getEmailsFromStores(storeIds)
    yield put(StoresSliceReducer.actions.fetchStoreEmailsSuccess(result.emails))
  } catch (error) {
    yield put(StoresSliceReducer.actions.fetchStoreEmailsError(error))
  }
}

function* putStoreLogo(action: PayloadAction<PutStoreLogoPayload>) {
  const storeId = action.payload.storeId
  const logo = action.payload.logo

  try {
    yield put(StoresSliceReducer.actions.putStoreLogoLoading())
    yield bffBackofficeApiService.retailers.putStoreLogo(storeId, logo )
    yield put(StoresSliceReducer.actions.putStoreLogoSuccess())
  } catch (error) {
    yield put(StoresSliceReducer.actions.putStoreLogoError(error))
  }
}

function* searchGooglePlace(action: PayloadAction<IPostGooglePlaceSearch>) {
  const body = action.payload
  try {
    yield put(StoresSliceReducer.actions.searchGooglePlaceLoading())
    const googlePlaceSearch: IPlaceSearchResponse = yield bffBackofficeApiService.retailers.searchGooglePlace(body)
    yield put(StoresSliceReducer.actions.searchGooglePlaceSuccess(googlePlaceSearch))
  } catch (error) {
    yield put(StoresSliceReducer.actions.searchGooglePlaceError(error))
  }
}

function* createStore(action: PayloadAction<PostStoreRequest>) {
  try {
    const body = action.payload
    yield put(StoresSliceReducer.actions.createStoreLoading())
    const store: IStoreResponse = yield bffBackofficeApiService.retailers.createStore(body)
    yield put(StoresSliceReducer.actions.createStoreSuccess(store))
    yield put(StoresSliceReducer.actions.fetchRetailerStores(store.retailerId))
  } catch (error) {
    yield put(StoresSliceReducer.actions.createStoreError(error))
  }
}

function* editGooglePlaceId(action: PayloadAction<EditGooglePlaceIdPayload>) {
  try {
    const { storeId, body } = action.payload
    yield put(StoresSliceReducer.actions.editGooglePlaceIdLoading())
    const store: FetchStoreResponse = yield bffBackofficeApiService.retailers.putGooglePlaceId(storeId, body)
    yield put(StoresSliceReducer.actions.fetchRetailerStores(store.retailerId))
    yield put(StoresSliceReducer.actions.editGooglePlaceIdSuccess(store))
    yield put(StoresSliceReducer.actions.editGooglePlaceIdModalClose())
  } catch (error) {
    yield put(StoresSliceReducer.actions.editGooglePlaceIdError(error))
  }
}

const storesSaga = [
  takeLatest(StoresSliceReducer.actions.fetchRetailerStores, fetchRetailerStores),
  takeLatest(StoresSliceReducer.actions.fetchStore, fetchStore),
  takeLatest(StoresSliceReducer.actions.fetchStoreEmails, fetchStoreEmails),
  takeLatest(StoresSliceReducer.actions.putStoreLogo, putStoreLogo),
  takeLatest(StoresSliceReducer.actions.searchGooglePlace, searchGooglePlace),
  takeLatest(StoresSliceReducer.actions.createStore, createStore),
  takeLatest(StoresSliceReducer.actions.editGooglePlaceId, editGooglePlaceId),
]

export default storesSaga
