import styled, { keyframes } from 'styled-components'

const motion1 = (props: any) => keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`

const motion2 = (props: any) => keyframes`
   0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`
const motion3 = (props: any) => keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const EllipsisSpinner = styled.div`
  position: relative;
  margin: auto;
  width: 64px;
  height: 64px;
  div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: blue;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 6px;
    animation: ${(p: any) => motion1(p)} 0.6s infinite;
  }
  div:nth-child(2) {
    left: 6px;
    animation: ${(p: any) => motion2(p)} 0.6s infinite;
  }
  div:nth-child(3) {
    left: 26px;
    animation: ${(p: any) => motion2(p)} 0.6s infinite;
  }
  div:nth-child(4) {
    left: 45px;
    animation: ${(p: any) => motion3(p)} 0.6s infinite;
  }
`

export { EllipsisSpinner }
