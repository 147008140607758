import { BorrowerSettings } from '../../../services/bff-backoffice/coordinator/interfaces/borrower.interfaces'
import { BorrowerSettingsProps } from '../RegistrationDataCard/RegistrationDataCard'

export const mapBorrowerSettingsProps = (borrowerInfo: {
  borrowerSettings?: BorrowerSettings
  isBorrowerEnabledToPayAnywhere?: boolean
}): BorrowerSettingsProps => {
  return {
    disablePurchase: !!borrowerInfo.borrowerSettings?.disablePurchase,
    allowedToPayAnywhere: borrowerInfo.isBorrowerEnabledToPayAnywhere,
  }
}
