const colors = {
  blue: {
    dark: '#0000ff',
    primary: '#8cafe6',
  },
  gray: {
    primary: '#808080',
    light: '#bebebe',
  },
  yellow: {
    primary: '#FFC960',
    light: '#FFFF99',
    dark: '#CCCC00',
  },
  green: {
    primary: '#8BC34A',
    light: '#7FFF00',
    dark: '#006400',
    seagreen: '#8FBC8F',
  },
  black: {
    primary: '#000000',
  },
  white: {
    primary: '#FFFFFF',
  },
  red: {
    primary: '#FF0000',
    dark: '#CC0000',
    light: 'FF4040',
    persian: '#E02020',
  },
}

export const { blue, gray, yellow, green, black, white, red } = colors
