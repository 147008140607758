import React, { ChangeEvent } from 'react'
import { MaskedInput, MaskedInputProps } from 'grommet'

type TextDatePickerProps = MaskedInputProps & {
    label?: string, 
    separator?: string, 
    defaultValue?: string, 
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void, 
    style?: React.CSSProperties,
    disabled?: boolean
}

export const TextDatePicker = (props: TextDatePickerProps) => {
    const { label, separator } = props

    return (
        <>
            {label ? <p>{label}</p> : <></>}
            <MaskedInput
                disabled={props.disabled}
                mask={[
                    {
                        length: 2,
                        // options: days,
                        regexp: /^0[1-9]$|^1[0-9]$|^2[0-9]$|^3[0-1]|^[0-3]$/,
                        placeholder: 'DD',
                    },
                    { fixed: separator || '/' },
                    {
                        length: 2,
                        // options: months,
                        regexp: /^0[1-9]$|^1[0-2]$|^[0-1]$/,
                        placeholder: 'MM',
                    },
                    { fixed: separator || '/' },
                    {
                        length: 4,
                        // TODO - Add Regex here
                        options: ['2019', '2020'],
                        placeholder: 'YYYY',
                    },
                ]}
                {...props}
            />
        </>
    )
}
