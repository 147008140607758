import { Card, Tag, Text } from '@bit/ume.design-kit.ui'
import { gray } from '@bit/ume.design-kit.ui/Palette'
import styled from 'styled-components'

export const CardStyle = styled(Card)`
  border-radius: 8px;
  height: 156px;
  width: 340px;
  padding-top: 22px;
`
export const GridHeaderTitleStyle = styled(Text)`
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
`
export const GridInfoTextStyle = styled(Text)`
  color: ${(props: any) => (props.color ? props.color : gray.spanish)};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 4px;
`
export const LimitTagStyle = styled(Tag)`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  width: 118px;
  justify-content: start;
`
