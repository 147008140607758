import styled from 'styled-components'
import { white } from '@bit/ume.design-kit.ui/utils/_colors'

const FiltersComponent = styled.div`
  display: inline;
  width: 360px;
  background-color: ${white.primary};
  box-shadow: 2px 2px 8px 0 rgba(152,152,152,0.5);
`

const Today = styled.div`
  display: flex;
  margin-right: auto;
`

export { FiltersComponent, Today }