import { Anchor, Box, Text } from 'grommet'
import React from 'react'
import styled from 'styled-components'
import { formatDateWithHour } from '../../utils'
import { Card } from '@bit/ume.design-kit.ui'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'

interface Props {
  id: string
  createdOn: string
  retailerId?: string
}

const ApplicationOption = ({ id, createdOn, retailerId }: Props) => (
  <LinkAnchor href={retailerId ? `/retailers-bench/${retailerId}/applications/${id}` : `/applications/${id}`}>
    <CardStyled>
      <Container>
        <StyledText><b>ID:</b> {id}</StyledText>
        <StyledText><b>Data e Hora:</b> {formatDateWithHour(createdOn)}</StyledText>
      </Container>
    </CardStyled>
  </LinkAnchor>
)

const CardStyled = styled(Card)`
  margin: 0 0 8px 0;
  padding: 16px;

  &:hover {
    cursor: pointer;
    box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4) !important;
    transition: 0.3s;
  }
`

const Container = styled(Box)`
  border-left-color: green;
  border-left-width: 3px;
  border-left-style: solid;
  text-align: left;
  padding: 0 8px;
  display: flex;
  justify-content: center;
`

const StyledText = styled(Text)`
    color: ${UMEColors.gray.eclipse};
    font-size: 16px;
    font-weight: 400;
`

const LinkAnchor = styled(Anchor)`
  text-decoration: none !important;
`

export default ApplicationOption
