import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import {
  ApplicationBatchesResponse,
  IApplicationResponse,
  IBatchApplicationOptionsResponse,
  IRetailerBenchApplicationOptionsResponse,
  IScoresResponse,
  RetailersBenchResponse,
} from './interfaces/application-engine.interfaces'

export default class ApplicationEngineService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async getApplicationsBatches(): Promise<ApplicationBatchesResponse[]> {
    const response = await this.axiosInstance.get<ApplicationBatchesResponse[]>(`/application-engine/batches`)
    return response.data
  }

  async getRetailersInEvaluationBench(): Promise<RetailersBenchResponse[]> {
    const response = await this.axiosInstance.get<RetailersBenchResponse[]>(`/application-engine/retailers-bench`)
    return response.data
  }

  async getApplicationById(id: string): Promise<IApplicationResponse> {
    const response = await this.axiosInstance.get<IApplicationResponse>(`/application-engine/applications/${id}`)
    return response.data
  }

  async getScoresFromApplication(id: string): Promise<IScoresResponse> {
    const response = await this.axiosInstance.get<IScoresResponse>(`/application-engine/applications/${id}/scores`)
    return response.data
  }

  async getAppicationsByBatchNumber(batchNumber: string): Promise<IBatchApplicationOptionsResponse[]> {
    const response = await this.axiosInstance.get<IBatchApplicationOptionsResponse[]>(
      `/application-engine/batches/${batchNumber}/applications`
    )
    return response.data
  }

  async getApplicationsInRetailerBench(id: string): Promise<IRetailerBenchApplicationOptionsResponse[]> {
    const response = await this.axiosInstance.get<IRetailerBenchApplicationOptionsResponse[]>(
      `/application-engine/retailers-bench/${id}/applications`
    )
    return response.data
  }

  async enableRetailerInRetailersBench(id: string): Promise<void> {
    const response = await this.axiosInstance.put<void>(`/application-engine/retailers-bench/${id}/enable`)
    return response.data
  }

  async disableRetailerInRetailersBench(id: string): Promise<void> {
    const response = await this.axiosInstance.put<void>(`/application-engine/retailers-bench/${id}/disable`)
    return response.data
  }
}
