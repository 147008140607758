import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Text } from 'grommet'
import styled, { css } from 'styled-components'
import { ReactComponent as BarcodeBaseIcon } from '../../common/assets/images/bx-barcode.svg'
import { ReactComponent as StoreBaseIcon } from '../../common/assets/images/bx-store.svg'
import { screenSize } from '../../config/screens.config'

export const StyledTabPane = styled(Text)`
  color: ${UMEColors.black.primary};
`

export const BarcodeIcon = styled(BarcodeBaseIcon)`
  height: 35px;
  width: 35px;
`

export const RetailerPageContainer = styled.div`
  background-color: white;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2);
  min-height: 95vh;
  background-color: ${UMEColors.gray.azure};

  padding: 24px 70px 24px 70px;

  @media ${screenSize.mobileL} {
    &:nth-child(even) {
      padding: 12px 20px 12px 20px;
    }
  }
`

export const UMETabsContainer = styled.div`
  margin-top: 4vh;
  margin-bottom: 25px;

  display: flex;
  flex-wrap: wrap;

  @media ${screenSize.mobileL} {
    margin-top: 1vh;
  }
`

export const RetailerIcon = styled(StoreBaseIcon)`
  height: 35px;
  width: 35px;
  fill: ${UMEColors.rose.primary};
`

export const StyledButton = styled.button`
  width: ${(props: any) => (props.width ? props.width : '200px')};
  background-color: ${(props: any) => (props.backgroundColor ? props.backgroundColor : '#0048d6')};
  border: none;
  border-radius: 5px;
  min-height: 40px;
  padding: ${(props: any) => (props.padding ? props.padding : '0')};

  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-align: center;

  transition: 0.3s;

  &:hover {
    transition: 0.1s;
    opacity: 0.3;
    cursor: pointer;
  }

  ${(props: any) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.2;
    `}
`

export const NotFoundMessage = styled.div`
  text-align: center;
  width: 100%;
  background-color: ${UMEColors.red.light};
  color: ${UMEColors.white.primary};
  padding: 7px;
`

export const NotFoundContainer = styled.div`
  background-color: white;
  min-height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  & > h1 {
    margin-bottom: 5vh;
  }
  & > svg {
    margin-bottom: 2vh;
    opacity: 0.7;
    width: 150px;
    height: 150px;
  }

  & > h3 {
    width: 300px;
    word-wrap: break-word;
    color: ${UMEColors.gray.aluminium};
  }
`
