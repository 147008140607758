import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Box, Text } from 'grommet'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { SmallLoadingIcon } from '../../organisms/RetailersPage/styles'
import { RetailersSliceReducer } from '../../redux/reducers/retailers/retailers.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { DebtFundingPackResponse } from '../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'
import { LoadingButton as Button } from '../LoadingButton/LoadingButton'
import Modal from './Modal'

interface Props {
  isModalOpen?: boolean
  onClose: () => void
  oldBlock?: DebtFundingPackResponse
  newBlock?: DebtFundingPackResponse
}

const EditRetailerBlockModal = ({ isModalOpen, onClose, oldBlock, newBlock }: Props) => {
  const dispatch = useDispatch()

  const { retailer, isEditRetailerBlockLoading } = useTypedSelector(state => state.retailers)

  const handleConfirmButton = () => {
    const retailerId = retailer?.id as string
    dispatch(
      RetailersSliceReducer.actions.editRetailerBlock({
        debtFundingPack: newBlock as DebtFundingPackResponse,
        retailerId,
      })
    )
  }

  return (
    <Modal modalTtitle="Confirmar alteração de bloco?" isOpen={!!isModalOpen} onClose={onClose} width={'500px'}>
      <Box overflow="auto" pad={{ top: '20px' }} margin={{ vertical: '10px' }}>
        <Box direction="row" justify="evenly">
          <Text size="18px" color={UMEColors.black.light}>
            De: <Text weight="bold">{oldBlock?.name?.toUpperCase()}</Text>
          </Text>
          <Text size="18px" color={UMEColors.black.light}>
            Para: <Text weight="bold">{newBlock?.name?.toUpperCase()}</Text>
          </Text>
        </Box>
        <Box width="100%" direction="row" gap="medium" justify="center" margin={{ top: '20px' }}>
          <CancelButton onClick={onClose} label={'Cancelar'} />
          <ConfirmButton
            onClick={handleConfirmButton}
            label={isEditRetailerBlockLoading ? <SmallLoadingIcon /> : 'Confirmar'}
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default EditRetailerBlockModal

const ConfirmButton = styled(Button)`
  border-radius: 6px;
  padding: 10px;
  width: 40%;
  background-color: ${UMEColors.green.laurel};
  border: solid 1px ${UMEColors.white.primary};
  box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4) !important;

  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  transition: 0.4s;

  &:hover {
    transition: 250ms;
    opacity: 0.8;
    cursor: pointer;
  }

  ${(props: any) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.2;
    `}
`

const CancelButton = styled(Button)`
  border-radius: 6px;
  padding: 10px;
  width: 40%;
  background-color: ${UMEColors.white.primary};
  border: solid 1px ${UMEColors.green.laurel};
  box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4) !important;

  color: ${UMEColors.green.laurel};
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  transition: 0.4s;

  &:hover {
    transition: 250ms;
    cursor: pointer;
    background-color: ${UMEColors.green.laurel};
    color: ${UMEColors.white.primary};
  }
`
