import { Clipboard, TextInput } from '@bit/ume.design-kit.ui'
import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Box } from 'grommet'
import { FormPreviousLink, FormTrash } from 'grommet-icons'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { toaster } from '../../App'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { emailIsValid, validate_celular, validate_cnpj } from '../../common/Validators'
import UMEDataTable from '../../molecules/DataTable/DataTable'
import DetailsCard, { IDetailsCardData } from '../../molecules/RetailersPage/DetailsCard'
import DropDownInput from '../../molecules/RetailersPage/DropDownInput'
import MaskedInput from '../../molecules/RetailersPage/MaskedInput'
import { CardStyle } from '../../molecules/RetailersPage/styles'
import { StyledButton } from '../../pages/Retailers/style'
import { RetailersSliceReducer } from '../../redux/reducers/retailers/retailers.reducer'
import { EditGooglePlaceIdPayload, StoresSliceReducer } from '../../redux/reducers/stores/stores.reducer'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { IEmailResponse } from '../../services/bff-backoffice/billings/interfaces/billings.interfaces'
import {
  BankAccountType,
  IPlaceSearchResponse,
  IPostGooglePlaceSearch,
  IPutStoreFinalBankAccount,
  IPutStoreIntermediaryBankAccount,
  IRetailerResponse,
} from '../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'
import { FetchStoreResponse, StoreStatus } from '../../services/bff-backoffice/retailers/interfaces/stores.res'
import { formatCep, formatCNPJ, formatDate, formatPhoneNumber } from '../../utils'
import { TableContainer } from '../ForcedApprovalModification/style'
import FormModal from './FormModal'
import {
  BoldTextStyled,
  DeleteTextStyled,
  GooglePlaceAddressTextStyled,
  GooglePlaceNameTextStyled,
  PageTitle,
  PreviousButtonStyle,
  SectionTitleStyle,
  SmallLoadingIcon,
  StyledTextRetailerName,
  SubSectionTitleStyle,
} from './styles'

interface IStoreDetailsProps extends RouteComponentProps {
  store: FetchStoreResponse
  emails: IEmailResponse[]
  retailer: IRetailerResponse
  fetchRetailer: (retailerid: string) => void
  fetchStore: (storeId: string) => {}
  fetchStoresEmails: (stores: string[]) => {}
  retailerLogo?: string
  isPutStoreLogoLoading: boolean
  putStoreLogo: (storeId: string, logo: File) => {}

  googlePlaceSearch: IPlaceSearchResponse
  isLoadingGooglePlaceSearch: boolean
  isEditGooglePlaceIdModalOpen: boolean
  isEditGooglePlaceIdLoading: boolean
  searchGooglePlace: (body: IPostGooglePlaceSearch) => {}
  editGooglePlaceIdModalOpen: () => {}
  editGooglePlaceIdModalClose: () => {}
  editGooglePlaceId: (payload: EditGooglePlaceIdPayload) => {}
}

interface IStoreDetailsState {
  intermediaryBankAccountBankCode: string
  intermediaryBankAccountAccountType: string
  intermediaryBankAccountAgencyCode: string
  intermediaryBankAccountAgencyDigit?: string
  intermediaryBankAccountCode: string
  intermediaryBankAccountDigit: string
  depositAccountOwnerDocument: string
  depositAccountOwnerName: string
  depositAccountOwnerPhoneNumber: string
  finalBankAccountBankCode: string
  finalBankAccountAccountType: string
  finalBankAccountAgencyCode: string
  finalBankAccountAgencyDigit: string
  finalBankAccountCode: string
  finalBankAccountDigit: string
  email: string
  intermediaryBankAccountBankCodeErrorIntent: boolean
  intermediaryBankAccountAgencyCodeErrorIntent: boolean
  intermediaryBankAccountCodeErrorIntent: boolean
  intermediaryBankAccountDigitErrorIntent: boolean
  depositAccountOwnerDocumentErrorIntent: boolean
  depositAccountOwnerNameErrorIntent: boolean
  depositAccountOwnerPhoneNumberErrorIntent: boolean
  finalBankAccountBankCodeErrorIntent: boolean
  finalBankAccountAgencyCodeErrorIntent: boolean
  finalBankAccountCodeErrorIntent: boolean
  finalBankAccountDigitErrorIntent: boolean
  isCreatingFinalBankAccount: boolean
  isCreatingIntermediaryBankAccount: boolean
  isLoadingSaveFinalBankAccountButton: boolean
  isLoadingSaveIntermediaryBankAccountButton: boolean
  storeLogo?: string
  retailerLogo?: string

  // Emails
  isCreatingEmail: boolean
  isLoadingSaveEmailButton: boolean
  emailErrorIntent: boolean
  isDeletingEmail: boolean
  isLoadingDeleteEmailButton: boolean
  emailToDelete?: IEmailResponse

  // Google Place Id
  searchText: string
  googlePlaceId: string
}

const initialState: IStoreDetailsState = {
  isCreatingIntermediaryBankAccount: false,
  isCreatingFinalBankAccount: false,
  intermediaryBankAccountBankCode: '',
  intermediaryBankAccountAccountType: 'Corrente',
  intermediaryBankAccountAgencyCode: '',
  intermediaryBankAccountAgencyDigit: '',
  intermediaryBankAccountCode: '',
  intermediaryBankAccountDigit: '',
  depositAccountOwnerDocument: '',
  depositAccountOwnerPhoneNumber: '',
  depositAccountOwnerName: '',
  finalBankAccountBankCode: '',
  finalBankAccountAccountType: 'Corrente',
  finalBankAccountAgencyCode: '',
  finalBankAccountAgencyDigit: '',
  finalBankAccountCode: '',
  finalBankAccountDigit: '',
  email: '',
  intermediaryBankAccountBankCodeErrorIntent: false,
  intermediaryBankAccountAgencyCodeErrorIntent: false,
  intermediaryBankAccountCodeErrorIntent: false,
  intermediaryBankAccountDigitErrorIntent: false,
  depositAccountOwnerDocumentErrorIntent: false,
  depositAccountOwnerNameErrorIntent: false,
  depositAccountOwnerPhoneNumberErrorIntent: false,
  finalBankAccountBankCodeErrorIntent: false,
  finalBankAccountAgencyCodeErrorIntent: false,
  finalBankAccountCodeErrorIntent: false,
  finalBankAccountDigitErrorIntent: false,
  isLoadingSaveFinalBankAccountButton: false,
  isLoadingSaveIntermediaryBankAccountButton: false,
  isCreatingEmail: false,
  isLoadingSaveEmailButton: false,
  emailErrorIntent: false,
  isDeletingEmail: false,
  isLoadingDeleteEmailButton: false,
  storeLogo: undefined,
  retailerLogo: undefined,
  searchText: '',
  googlePlaceId: '',
}

const ACCOUNT_TYPES: any = {
  '1': 'Poupança',
  '2': 'Corrente',
}

const CnpjMaskInput = [
  {
    length: 2,
    regexp: /\d/,
    placeholder: '00',
  },
  { fixed: '.' },
  {
    length: 3,
    regexp: /\d/,
    placeholder: '000',
  },
  { fixed: '.' },
  {
    length: 3,
    regexp: /\d/,
    placeholder: '000',
  },
  { fixed: '/' },
  {
    length: 4,
    regexp: /\d/,
    placeholder: '0000',
  },
  { fixed: '-' },
  {
    length: 2,
    regexp: /\d/,
    placeholder: '00',
  },
]

const CellphoneMaskInput = [
  { fixed: '(' },
  {
    length: 1,
    regexp: /[1-9]/,
    placeholder: '0',
  },
  {
    length: 1,
    regexp: /\d/,
    placeholder: '0',
  },
  { fixed: ') ' },
  {
    length: 1,
    regexp: /[5-9]/,
    placeholder: '0',
  },
  {
    length: 4,
    regexp: /\d/,
    placeholder: '0000',
  },
  { fixed: '-' },
  {
    length: 4,
    regexp: /\d/,
    placeholder: '0000',
  },
]

const DigitMaskInput = [
  {
    length: 1,
    regexp: /\d/,
    placeholder: '0',
  },
]

const AgencyMaskInput = [
  {
    length: 4,
    regexp: /\d/,
    placeholder: '0000',
  },
]

const NumberMaskInput = [
  {
    regexp: /\d/,
  },
]

class StoreDetails extends React.Component<IStoreDetailsProps, IStoreDetailsState> {
  constructor(props: IStoreDetailsProps) {
    super(props)
    this.state = initialState
  }

  componentDidUpdate = async (prevProps: IStoreDetailsProps) => {
    // If the retailer is updated, go to stores tab
    if (prevProps.retailer !== this.props.retailer && this.props.retailer) {
      this.props.history.push(`/${this.props.retailer.id}/stores`)
      this.loadLogos()
    }
  }

  loadLogos = async () => {
    const { store } = this.props
    if (store) {
      try {
        const storeLogo = await bffBackofficeApiService.retailers.getStoreLogoById(store.id)
        const retailerLogo = await bffBackofficeApiService.retailers.getRetailerLogoById(store.retailerId)
        this.setState({ storeLogo, retailerLogo })
      } catch (error) {
        this.setState({ storeLogo: '-', retailerLogo: '-' })
      }
    }
  }

  handleFile = (file: File) => {
    if (!file) {
      return
    }
    const storeId = this.props.store.id
    this.props.putStoreLogo(storeId, file)
  }

  onEmailCreateClick = () => {
    this.setState({ isCreatingEmail: true })
  }

  onIntermediaryBankAccountCreateClick = () => {
    this.setState({ isCreatingIntermediaryBankAccount: true })
  }

  onFinalBankAccountCreateClick = () => {
    this.setState({ isCreatingFinalBankAccount: true })
  }

  onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const state: any = this.state
    state[e.target.name] = e.target.value
    this.setState(state)
  }

  onChangeDropDownInput = (e: any) => {
    const state: any = this.state
    state[e.target.name] = e.value
    this.setState(state)
  }

  // Returns the form fields of the Create Intermediary Bank Account Modal
  createIntermediaryBankAccountFields = () => {
    return [
      <StyledTextRetailerName key={0}>{this.props.store.name}</StyledTextRetailerName>,
      <TextInput
        required
        label="Nome Titular"
        key={1}
        name={'depositAccountOwnerName'}
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.depositAccountOwnerName}
        errorIntent={this.state.depositAccountOwnerNameErrorIntent}
      />,
      <MaskedInput
        required
        label="Celular"
        key={2}
        width="100%"
        mask={CellphoneMaskInput}
        placeholder="(92) 99999-9999"
        name={'depositAccountOwnerPhoneNumber'}
        onChange={this.onChangeInput}
        value={this.state.depositAccountOwnerPhoneNumber}
        errorIntent={this.state.depositAccountOwnerPhoneNumberErrorIntent}
      />,
      <MaskedInput
        required
        label="CNPJ"
        mask={CnpjMaskInput}
        placeholder="00.000.000/0000-00"
        key={3}
        name={'depositAccountOwnerDocument'}
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.depositAccountOwnerDocument}
        errorIntent={this.state.depositAccountOwnerDocumentErrorIntent}
      />,
      <DropDownInput
        required
        style={{ border: 'none' }}
        options={['Corrente', 'Poupança']}
        label="Tipo de Conta"
        key={4}
        name={'intermediaryBankAccountAccountType'}
        width="100%"
        onChange={this.onChangeDropDownInput}
        value={this.state.intermediaryBankAccountAccountType}
      />,
      <MaskedInput
        required
        label="Número do Banco"
        key={5}
        name={'intermediaryBankAccountBankCode'}
        mask={NumberMaskInput}
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.intermediaryBankAccountBankCode}
        errorIntent={this.state.intermediaryBankAccountBankCodeErrorIntent}
      />,
      <Box gap="small" key={11} direction="row-responsive">
        <MaskedInput
          required
          label="Agência"
          mask={AgencyMaskInput}
          placeholder="0000"
          key={6}
          name={'intermediaryBankAccountAgencyCode'}
          width="100%"
          onChange={this.onChangeInput}
          value={this.state.intermediaryBankAccountAgencyCode}
          errorIntent={this.state.intermediaryBankAccountAgencyCodeErrorIntent}
        />
        <MaskedInput
          label="Dígito da Agência"
          mask={DigitMaskInput}
          key={7}
          name={'intermediaryBankAccountAgencyDigit'}
          placeholder="0"
          width="100%"
          onChange={this.onChangeInput}
          value={this.state.intermediaryBankAccountAgencyDigit}
        />
      </Box>,
      <Box gap="small" key={10} direction="row-responsive">
        <MaskedInput
          required
          label="Conta"
          mask={NumberMaskInput}
          key={8}
          name={'intermediaryBankAccountCode'}
          width="100%"
          onChange={this.onChangeInput}
          value={this.state.intermediaryBankAccountCode}
          errorIntent={this.state.intermediaryBankAccountCodeErrorIntent}
        />
        <MaskedInput
          required
          label="Dígito da Conta"
          mask={DigitMaskInput}
          key={9}
          name={'intermediaryBankAccountDigit'}
          placeholder="0"
          width="100%"
          onChange={this.onChangeInput}
          value={this.state.intermediaryBankAccountDigit}
          errorIntent={this.state.intermediaryBankAccountDigitErrorIntent}
        />
      </Box>,
    ]
  }

  // Returns the form fields of the Create Final Bank Account Modal
  createFinalBankAccountFields = () => {
    return [
      <StyledTextRetailerName key={0}>{this.props.store.name}</StyledTextRetailerName>,
      <DropDownInput
        required
        style={{ border: 'none' }}
        options={['Corrente', 'Poupança']}
        label="Tipo de Conta"
        key={1}
        name={'finalBankAccountAccountType'}
        width="100%"
        onChange={this.onChangeDropDownInput}
        value={this.state.finalBankAccountAccountType}
      />,
      <MaskedInput
        required
        label="Número do Banco"
        mask={NumberMaskInput}
        key={2}
        name={'finalBankAccountBankCode'}
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.finalBankAccountBankCode}
        errorIntent={this.state.finalBankAccountBankCodeErrorIntent}
      />,
      <Box gap="small" key={8} direction="row-responsive">
        <MaskedInput
          required
          label="Agência"
          mask={AgencyMaskInput}
          placeholder="0000"
          key={3}
          name={'finalBankAccountAgencyCode'}
          width="100%"
          onChange={this.onChangeInput}
          value={this.state.finalBankAccountAgencyCode}
          errorIntent={this.state.finalBankAccountAgencyCodeErrorIntent}
        />
        <MaskedInput
          label="Dígito da Agência"
          mask={DigitMaskInput}
          key={4}
          name={'finalBankAccountAgencyDigit'}
          placeholder="0"
          width="100%"
          onChange={this.onChangeInput}
          value={this.state.finalBankAccountAgencyDigit}
        />
      </Box>,
      <Box gap="small" key={7} direction="row-responsive">
        <MaskedInput
          required
          label="Conta"
          mask={NumberMaskInput}
          key={5}
          name={'finalBankAccountCode'}
          width="100%"
          onChange={this.onChangeInput}
          value={this.state.finalBankAccountCode}
          errorIntent={this.state.finalBankAccountCodeErrorIntent}
        />
        <MaskedInput
          required
          label="Dígito da Conta"
          mask={DigitMaskInput}
          key={6}
          name={'finalBankAccountDigit'}
          placeholder="0"
          width="100%"
          onChange={this.onChangeInput}
          value={this.state.finalBankAccountDigit}
          errorIntent={this.state.finalBankAccountDigitErrorIntent}
        />
      </Box>,
    ]
  }

  // Validates the create intermediary bank account fields and, if valid, submit to API Service
  onSaveIntermediaryBankAccount = () => {
    this.setState({ isLoadingSaveIntermediaryBankAccountButton: true })

    const {
      intermediaryBankAccountAgencyCode,
      intermediaryBankAccountBankCode,
      intermediaryBankAccountCode,
      intermediaryBankAccountDigit,
      depositAccountOwnerDocument,
      depositAccountOwnerName,
      depositAccountOwnerPhoneNumber,
      intermediaryBankAccountAgencyDigit,
      intermediaryBankAccountAccountType,
    } = this.state

    let error = false
    let intermediaryBankAccountAgencyCodeErrorIntent = false,
      intermediaryBankAccountBankCodeErrorIntent = false,
      intermediaryBankAccountCodeErrorIntent = false,
      intermediaryBankAccountDigitErrorIntent = false,
      depositAccountOwnerDocumentErrorIntent = false,
      depositAccountOwnerNameErrorIntent = false,
      depositAccountOwnerPhoneNumberErrorIntent = false

    if (depositAccountOwnerName.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Nome de titular deve conter pelo menos um caracter.')
      depositAccountOwnerNameErrorIntent = true
      error = true
    } else {
      depositAccountOwnerNameErrorIntent = false
    }

    if (!validate_celular(depositAccountOwnerPhoneNumber)) {
      toaster.showErrorToast('Telefone de titular inválido.')
      depositAccountOwnerPhoneNumberErrorIntent = true
      error = true
    } else {
      depositAccountOwnerPhoneNumberErrorIntent = false
    }

    if (!validate_cnpj(depositAccountOwnerDocument)) {
      toaster.showErrorToast('CNPJ de titular inválido.')
      depositAccountOwnerDocumentErrorIntent = true
      error = true
    } else {
      depositAccountOwnerDocumentErrorIntent = false
    }

    if (intermediaryBankAccountBankCode.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Número de Banco deve conter pelo menos um número.')
      intermediaryBankAccountBankCodeErrorIntent = true
      error = true
    } else {
      intermediaryBankAccountBankCodeErrorIntent = false
    }

    if (intermediaryBankAccountAgencyCode.replace(/ /g, '').length !== 4) {
      toaster.showErrorToast('Agência deve conter quatro números.')
      intermediaryBankAccountAgencyCodeErrorIntent = true
      error = true
    } else {
      intermediaryBankAccountAgencyCodeErrorIntent = false
    }

    if (intermediaryBankAccountCode.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Conta deve conter pelo menos um caracter.')
      intermediaryBankAccountCodeErrorIntent = true
      error = true
    } else {
      intermediaryBankAccountCodeErrorIntent = false
    }

    if (intermediaryBankAccountDigit.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Dígito de conta deve conter um número.')
      intermediaryBankAccountDigitErrorIntent = true
      error = true
    } else {
      intermediaryBankAccountDigitErrorIntent = false
    }

    this.setState({
      intermediaryBankAccountAgencyCodeErrorIntent,
      intermediaryBankAccountBankCodeErrorIntent,
      intermediaryBankAccountCodeErrorIntent,
      intermediaryBankAccountDigitErrorIntent,
      depositAccountOwnerDocumentErrorIntent,
      depositAccountOwnerNameErrorIntent,
      depositAccountOwnerPhoneNumberErrorIntent,
    })

    if (error) {
      this.setState({ isLoadingSaveIntermediaryBankAccountButton: false })
      return
    }

    const { store } = this.props

    const body: IPutStoreIntermediaryBankAccount = {
      depositAccountOwnerDocument: depositAccountOwnerDocument
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', ''),
      depositAccountOwnerPhoneNumber: depositAccountOwnerPhoneNumber
        .replace(' ', '')
        .replace('(', '')
        .replace(')', '')
        .replace('-', ''),
      depositAccountOwnerName,
      intermediaryBankAccountAccountType:
        intermediaryBankAccountAccountType === 'Corrente' ? BankAccountType.CORRENTE : BankAccountType.POUPANCA,
      intermediaryBankAccountAgencyCode,
      intermediaryBankAccountAgencyDigit,
      intermediaryBankAccountBankCode,
      intermediaryBankAccountCode,
      intermediaryBankAccountDigit,
    }

    // TODO - Put in Redux-Saga
    bffBackofficeApiService.retailers
      .putStoreIntermediaryBankAccount(store.id, body)
      .then(res => {
        this.props.fetchStore(store.id)
        toaster.showSuccessToast(`Conta Intermediária criada com sucesso.`)
        this.setState({ isLoadingSaveIntermediaryBankAccountButton: false, isCreatingIntermediaryBankAccount: false })
      })
      .catch(error => {
        toaster.showErrorToast(
          `Não foi possível criar conta intermediária. ${
            error.response?.data.statusCode ? `Erro ${error.response?.data.statusCode}` : ''
          }`
        )
        this.setState({ isLoadingSaveIntermediaryBankAccountButton: false })
      })
  }

  // Validates the create final bank account fields and, if valid, submit to API Service
  onSaveFinalBankAccount = () => {
    this.setState({ isLoadingSaveFinalBankAccountButton: true })

    const {
      finalBankAccountAgencyCode,
      finalBankAccountBankCode,
      finalBankAccountCode,
      finalBankAccountDigit,
      finalBankAccountAccountType,
      finalBankAccountAgencyDigit,
    } = this.state

    let error = false
    let finalBankAccountAgencyCodeErrorIntent = false,
      finalBankAccountBankCodeErrorIntent = false,
      finalBankAccountCodeErrorIntent = false,
      finalBankAccountDigitErrorIntent = false

    if (finalBankAccountBankCode.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Número de Banco deve conter pelo menos um número.')
      finalBankAccountBankCodeErrorIntent = true
      error = true
    } else {
      finalBankAccountBankCodeErrorIntent = false
    }

    if (finalBankAccountAgencyCode.replace(/ /g, '').length !== 4) {
      toaster.showErrorToast('Agência deve conter quatro números.')
      finalBankAccountAgencyCodeErrorIntent = true
      error = true
    } else {
      finalBankAccountAgencyCodeErrorIntent = false
    }

    if (finalBankAccountCode.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Conta deve conter pelo menos um número.')
      finalBankAccountCodeErrorIntent = true
      error = true
    } else {
      finalBankAccountCodeErrorIntent = false
    }

    if (finalBankAccountDigit.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Dígito de conta deve conter um número.')
      finalBankAccountDigitErrorIntent = true
      error = true
    } else {
      finalBankAccountDigitErrorIntent = false
    }

    this.setState({
      finalBankAccountAgencyCodeErrorIntent,
      finalBankAccountBankCodeErrorIntent,
      finalBankAccountCodeErrorIntent,
      finalBankAccountDigitErrorIntent,
    })

    if (error) {
      this.setState({ isLoadingSaveFinalBankAccountButton: false })
      return
    }

    const { store } = this.props

    const body: IPutStoreFinalBankAccount = {
      finalBankAccountAccountType:
        finalBankAccountAccountType === 'Corrente' ? BankAccountType.CORRENTE : BankAccountType.POUPANCA,
      finalBankAccountAgencyCode,
      finalBankAccountAgencyDigit,
      finalBankAccountBankCode,
      finalBankAccountCode,
      finalBankAccountDigit,
    }

    // TODO - Put in Redux-Saga
    bffBackofficeApiService.retailers
      .putStoreFinalBankAccount(store.id, body)
      .then(res => {
        this.props.fetchStore(store.id)
        toaster.showSuccessToast(`Conta Final criada com sucesso.`)
        this.setState({ isLoadingSaveFinalBankAccountButton: false, isCreatingFinalBankAccount: false })
      })
      .catch(error => {
        toaster.showErrorToast(
          `Não foi possível criar conta final. ${
            error.response?.data.statusCode ? `Erro ${error.response?.data.statusCode}` : ''
          }`
        )
        this.setState({ isLoadingSaveFinalBankAccountButton: false })
      })
  }

  // Validates the create email fields and, if valid, submit to API Service
  onSaveEmail = () => {
    this.setState({ isLoadingSaveEmailButton: true })

    const { email } = this.state

    let emailErrorIntent = false

    if (!emailIsValid(email)) {
      toaster.showErrorToast('E-mail inválido.')
      emailErrorIntent = true
    } else {
      emailErrorIntent = false
    }

    this.setState({
      emailErrorIntent,
    })

    if (emailErrorIntent) {
      this.setState({ isLoadingSaveEmailButton: false })
      return
    }

    const { store } = this.props

    // TODO - Put in Redux-Saga
    bffBackofficeApiService.billings
      .createEmail(store.id, [email])
      .then(res => {
        this.props.fetchStoresEmails([store.id])
        toaster.showSuccessToast(`E-mail criado com sucesso.`)
        this.setState({ isLoadingSaveEmailButton: false, isCreatingEmail: false })
      })
      .catch(error => {
        toaster.showErrorToast(
          `Não foi possível criar e-mail. ${
            error.response?.data.statusCode ? `Erro ${error.response?.data.statusCode}` : ''
          }`
        )
        this.setState({ isLoadingSaveEmailButton: false })
      })
  }

  // Goes back to the stores tab when the "Voltar" button is clicked
  onPreviousButtonClick = () => {
    let pathTokens = this.props.location.pathname.split('/')
    pathTokens[4] = ''
    this.props.history.push(pathTokens.join('/'))
  }

  // Returns the form fields of the Create Email Modal
  createEmailFields = () => {
    return [
      <StyledTextRetailerName key={0}>{this.props.store.name}</StyledTextRetailerName>,
      <TextInput
        required
        label="E-mail"
        type="email"
        placeholder="email@email.com"
        key={1}
        name={'email'}
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.email}
        errorIntent={this.state.emailErrorIntent}
      />,
    ]
  }

  onCheckBoxChange = (e: CheckboxChangeEvent) => {
    if (this.props.googlePlaceSearch) {
      this.setState({
        googlePlaceId: e.target.checked ? this.props.googlePlaceSearch.placeId : '',
      })
    }
  }

  handleSearchButtonClick = () => {
    const { searchText } = this.state
    const { store } = this.props
    this.props.searchGooglePlace({ name: searchText, zipcode: store.zipcode })
  }

  handleEditGooglePlaceIdButtonClick = () => {
    const { store, googlePlaceSearch } = this.props
    this.props.editGooglePlaceId({ storeId: store.id, body: { placeId: googlePlaceSearch.placeId } })
  }

  // Returns the form fields of the Create Email Modal
  editGooglePlaceIdFields = () => {
    return [
      <Box key={1} direction="row" align="center" pad={{ right: '10px' }}>
        <TextInput
          label="Localização Google"
          placeholder={''}
          key={2}
          name={'searchText'}
          width="100%"
          onChange={this.onChangeInput}
          value={this.state.searchText}
        />
        <StyledButton width={'30%'} key={3} disabled={!this.state.searchText} onClick={this.handleSearchButtonClick}>
          {this.props.isLoadingGooglePlaceSearch ? <SmallLoadingIcon /> : <> Pesquisar </>}
        </StyledButton>
      </Box>,
      <Box margin={{ left: '20px' }} key={4}>
        {this.props.googlePlaceSearch === null ? (
          <GooglePlaceNameTextStyled>Localização não encontrada</GooglePlaceNameTextStyled>
        ) : this.props.googlePlaceSearch === undefined ? (
          <GooglePlaceNameTextStyled>Digite o nome do local para pesquisar.</GooglePlaceNameTextStyled>
        ) : (
          <Checkbox
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}
            onChange={this.onCheckBoxChange}
          >
            <Box gap="xsmall">
              <GooglePlaceNameTextStyled>{this.props.googlePlaceSearch.name}</GooglePlaceNameTextStyled>
              <GooglePlaceAddressTextStyled>{this.props.googlePlaceSearch.address}</GooglePlaceAddressTextStyled>
            </Box>
          </Checkbox>
        )}
      </Box>,
    ]
  }

  // Returns the form fields of the Create Email Modal
  deleteEmailFields = () => {
    const { emailToDelete } = this.state

    return [
      <DeleteTextStyled key={0}>
        Deseja deletar o email <BoldTextStyled>{emailToDelete?.email}</BoldTextStyled>?
      </DeleteTextStyled>,
    ]
  }

  // Validates the create email fields and, if valid, submit to API Service
  onDeleteEmail = () => {
    this.setState({ isLoadingDeleteEmailButton: true })
    const { emailToDelete } = this.state
    const { store } = this.props

    if (!emailToDelete) {
      toaster.showErrorToast('E-mail inválido.')
      return
    }

    // TODO - Put in Redux-Saga
    bffBackofficeApiService.billings
      .deleteEmail(String(emailToDelete.id))
      .then(res => {
        this.props.fetchStoresEmails([store.id])
        toaster.showSuccessToast(`E-mail deletado.`)
        this.setState({ isLoadingDeleteEmailButton: false, isDeletingEmail: false })
      })
      .catch(error => {
        toaster.showErrorToast(
          `Não foi possível deletar e-mail. ${
            error.response?.data.statusCode ? `Erro ${error.response?.data.statusCode}` : ''
          }`
        )
        this.setState({ isLoadingDeleteEmailButton: false })
      })
  }

  render = () => {
    const { store, emails, isEditGooglePlaceIdModalOpen, isEditGooglePlaceIdLoading } = this.props
    const {
      isCreatingIntermediaryBankAccount,
      isCreatingFinalBankAccount,
      isLoadingSaveFinalBankAccountButton,
      isLoadingSaveIntermediaryBankAccountButton,
      isLoadingSaveEmailButton,
      isCreatingEmail,
      isDeletingEmail,
      isLoadingDeleteEmailButton,
      storeLogo,
      retailerLogo,
    } = this.state

    if (!store) {
      return (
        <Box width="100%" height="50vh">
          <LoadingIcon />
        </Box>
      )
    }

    const storeData: IDetailsCardData[] = [
      {
        label: 'Logo',
        value: this.props.isPutStoreLogoLoading ? undefined : storeLogo,
        fallback: retailerLogo,
        image: true,
        handleFile: this.handleFile,
      },
      {
        label: 'ID',
        value: store.id,
      },
      {
        label: 'Nome',
        value: store.name,
      },
      {
        label: 'CEP',
        value: store.zipcode ? formatCep(store.zipcode) : undefined,
      },
      {
        label: 'Endereço',
        value: store.address,
      },
      {
        label: 'Google Place Id',
        value: store.googlePlaceId,
        handleEditionClick: this.props.editGooglePlaceIdModalOpen,
      },
      {
        label: 'Status',
        value: store.status == StoreStatus.ACTIVE ? 'Ativa' : 'Inativa',
      },
    ]

    if (store.deactivatedOn) {
      storeData.push({
        label: 'Data de Inativação',
        value: formatDate(store.deactivatedOn),
      })
    }
    const intermediaryBankAccountData: IDetailsCardData[] = [
      {
        label: 'Tipo',
        value: store.intermediaryBankAccountAccountType
          ? ACCOUNT_TYPES[store.intermediaryBankAccountAccountType]
          : undefined,
      },
      {
        label: 'Código do Banco',
        value: store.intermediaryBankAccountBankCode,
      },
      {
        label: 'Conta',
        value: store.intermediaryBankAccountCode,
      },
      {
        label: 'Dígito da Conta',
        value: store.intermediaryBankAccountDigit,
      },
      {
        label: 'Agência',
        value: store.intermediaryBankAccountAgencyCode,
      },
      {
        label: 'Dígito da Agência',
        value: store.intermediaryBankAccountAgencyDigit,
      },
      {
        label: 'Titular',
        value: store.depositAccountOwnerName,
      },
      {
        label: 'CNPJ de Titular',
        value: store.depositAccountOwnerDocument ? formatCNPJ(store.depositAccountOwnerDocument) : undefined,
      },
      {
        label: 'Celular de Titular',
        value: store.depositAccountOwnerPhoneNumber
          ? formatPhoneNumber(store.depositAccountOwnerPhoneNumber)
          : undefined,
      },
    ]

    const finalBankAccountData: IDetailsCardData[] = [
      {
        label: 'Tipo',
        value: store.finalBankAccountAccountType ? ACCOUNT_TYPES[store.finalBankAccountAccountType] : undefined,
      },
      {
        label: 'Código do Banco',
        value: store.finalBankAccountBankCode,
      },
      {
        label: 'Conta',
        value: store.finalBankAccountCode,
      },
      {
        label: 'Dígito da Conta',
        value: store.finalBankAccountDigit,
      },
      {
        label: 'Agência',
        value: store.finalBankAccountAgencyCode,
      },
      {
        label: 'Dígito da Agência',
        value: store.finalBankAccountAgencyDigit,
      },
    ]

    return (
      <>
        <FormModal
          title="Criar Conta Intermediária"
          isModalOpen={isCreatingIntermediaryBankAccount}
          fields={this.createIntermediaryBankAccountFields()}
          onClose={() => {
            this.setState({ isCreatingIntermediaryBankAccount: false })
          }}
          onCreate={this.onSaveIntermediaryBankAccount}
          isLoadingButton={isLoadingSaveIntermediaryBankAccountButton}
        />

        <FormModal
          title="Criar Conta Final"
          isModalOpen={isCreatingFinalBankAccount}
          fields={this.createFinalBankAccountFields()}
          onClose={() => {
            this.setState({ isCreatingFinalBankAccount: false })
          }}
          onCreate={this.onSaveFinalBankAccount}
          isLoadingButton={isLoadingSaveFinalBankAccountButton}
        />

        <FormModal
          title="Criar E-mail"
          isModalOpen={isCreatingEmail}
          fields={this.createEmailFields()}
          onClose={() => {
            this.setState({ isCreatingEmail: false })
          }}
          onCreate={this.onSaveEmail}
          isLoadingButton={isLoadingSaveEmailButton}
        />

        <FormModal
          title="ATENÇÃO"
          isModalOpen={isDeletingEmail}
          fields={this.deleteEmailFields()}
          onClose={() => {
            this.setState({ isDeletingEmail: false })
          }}
          onCreate={this.onDeleteEmail}
          isLoadingButton={isLoadingDeleteEmailButton}
          deleteButton
        />

        <FormModal
          title="Editar Google Place Id"
          isModalOpen={isEditGooglePlaceIdModalOpen}
          fields={this.editGooglePlaceIdFields()}
          onClose={this.props.editGooglePlaceIdModalClose}
          onCreate={this.handleEditGooglePlaceIdButtonClick}
          isLoadingButton={isEditGooglePlaceIdLoading}
        />

        <Box style={{ maxWidth: '1000px' }} direction="column" gap="medium">
          <Box direction="row" align="center" gap="xsmall">
            <PreviousButtonStyle
              onClick={() => this.onPreviousButtonClick()}
              width="28px"
              height="28px"
              style={{ marginLeft: 0 }}
              icon={<FormPreviousLink color="white" />}
            />
            <PageTitle> Voltar </PageTitle>
          </Box>

          <Box width="100%" align="start" gap="medium">
            <Box width="100%" align="start">
              <SectionTitleStyle>Loja {store.name}</SectionTitleStyle>

              <Box width="100%">
                <DetailsCard data={storeData} />
              </Box>
            </Box>

            <Box width="100%" align="start">
              <Box width="100%" justify="between" direction="row" pad={{ right: '10px' }}>
                <SectionTitleStyle>E-mails</SectionTitleStyle>
                <StyledButton onClick={this.onEmailCreateClick}>Cadastrar Email</StyledButton>
              </Box>
              <Box width="100%">
                {!emails ? (
                  <CardStyle height="100%">
                    <Box style={{ minHeight: '100px' }} fill align="center" justify="center">
                      <LoadingIcon />
                    </Box>
                  </CardStyle>
                ) : emails.length > 0 ? (
                  <TableContainer style={{ maxHeight: '250px', margin: '8px' }}>
                    <UMEDataTable
                      header={[
                        {
                          label: 'Emails',
                          attribute: 'email',
                          formatCell: (row: IEmailResponse) => {
                            return (
                              <Box direction="row" justify="between">
                                <Box
                                  onClick={() => {
                                    toaster.showSuccessToast('E-mail copiado.')
                                  }}
                                >
                                  <Clipboard label={row.email} value={row.email} />
                                </Box>
                                <Box
                                  margin={{ right: '10px' }}
                                  onClick={() => {
                                    this.setState({ isDeletingEmail: true, emailToDelete: row })
                                  }}
                                >
                                  <FormTrash color={'red'} />
                                </Box>
                              </Box>
                            )
                          },
                        },
                      ]}
                      data={emails}
                    />
                  </TableContainer>
                ) : (
                  <CardStyle height="100%">
                    <Box style={{ minHeight: '100px' }} fill align="center" justify="center" gap="small">
                      Não há e-mails cadastrados.
                    </Box>
                  </CardStyle>
                )}
              </Box>
            </Box>

            <Box width="100%" align="start">
              <SectionTitleStyle>Dados da Conta</SectionTitleStyle>

              <Box width="100%" direction="row-responsive">
                <Box width="100%">
                  <SubSectionTitleStyle>Intermediária</SubSectionTitleStyle>
                  {store.intermediaryBankAccountCode ? (
                    <DetailsCard data={intermediaryBankAccountData} />
                  ) : (
                    <CardStyle height="100%">
                      <Box fill align="center" justify="center" gap="small">
                        Esta loja usa a conta intermediária do varejo.
                        <StyledButton onClick={this.onIntermediaryBankAccountCreateClick}>
                          Cadastrar Conta de Loja
                        </StyledButton>
                      </Box>
                    </CardStyle>
                  )}
                </Box>

                <Box width="100%">
                  <SubSectionTitleStyle>Final</SubSectionTitleStyle>
                  {store.finalBankAccountCode ? (
                    <DetailsCard data={finalBankAccountData} />
                  ) : (
                    <CardStyle height="100%">
                      <Box fill align="center" justify="center" gap="small">
                        Esta loja usa a conta final do varejo.
                        <StyledButton onClick={this.onFinalBankAccountCreateClick}>
                          Cadastrar Conta de Loja
                        </StyledButton>
                      </Box>
                    </CardStyle>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  store: state.stores.store,
  emails: state.stores.emails,
  retailer: state.retailers.retailer,
  retailerLogo: state.retailers.logo,
  isPutStoreLogoLoading: state.stores.isPutStoreLogoLoading,
  googlePlaceSearch: state.stores.googlePlaceSearchEdit,
  isLoadingGooglePlaceSearch: state.stores.isLoadingGooglePlaceSearchEdit,
  isEditGooglePlaceIdLoading: state.stores.isEditGooglePlaceIdLoading,
  isEditGooglePlaceIdModalOpen: state.stores.isEditGooglePlaceIdModalOpen,
})

const mapDispatchToProps = (dispatch: any) => ({
  fetchRetailer: (retailerId: string) => dispatch(RetailersSliceReducer.actions.fetchRetailer(retailerId)),
  fetchStore: (storeId: string) => dispatch(StoresSliceReducer.actions.fetchStore(storeId)),
  putStoreLogo: (storeId: string, logo: File) => dispatch(StoresSliceReducer.actions.putStoreLogo({ storeId, logo })),
  fetchStoresEmails: (stores: string[]) => dispatch(StoresSliceReducer.actions.fetchStoreEmails(stores)),
  searchGooglePlace: (body: IPostGooglePlaceSearch) => dispatch(StoresSliceReducer.actions.searchGooglePlace(body)),
  editGooglePlaceIdModalOpen: () => dispatch(StoresSliceReducer.actions.editGooglePlaceIdModalOpen()),
  editGooglePlaceIdModalClose: () => dispatch(StoresSliceReducer.actions.editGooglePlaceIdModalClose()),
  editGooglePlaceId: (payload: EditGooglePlaceIdPayload) =>
    dispatch(StoresSliceReducer.actions.editGooglePlaceId(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StoreDetails)
