const PENDING_TAGS = [
  'venceHoje', 'deHojeAte3Dias', 'de3ate7dias', 'de7ate14dias', 'de14ate30dias', 'maisDe30dias'
]

const OVERDUE_TAGS = [
  'ate3dias', 'de3ate15dias', 'de15ate30dias', 'de30ate60dias', 'de60ate120dias','maisDe120dias'
]

export {
  PENDING_TAGS,
  OVERDUE_TAGS
}