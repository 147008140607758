import React from 'react'
import { formatCPF, formatDate } from '../../../utils'
import { applicationStatusDictionary } from '../../../config/utils'
import { GreenText, RedText } from '../../../atoms/Texts/style'

const yesNoStyle = (el: boolean) => (el ? <GreenText>SIM</GreenText> : <RedText>NÃO</RedText>)

const applicationsHeader = [
  { property: 'id', label: 'ID' },
  { property: 'createdOn', label: 'Data', format: formatDate },
  { property: 'retailerName', label: 'Varejista' },
  { property: 'storeName', label: 'Loja' },
  { property: 'operatorName', label: 'Operador' },
  { property: 'borrowerName', label: 'Tomador' },
  { property: 'cpf', label: 'CPF', format: formatCPF },
  { property: 'phoneNumber', label: 'Telefone' },
  { property: 'status', label: 'Status', format: (el: string) => applicationStatusDictionary[el] },
  { property: 'lastCompletedStage', label: 'Último Estágio' },
  {
    property: 'deniedReason',
    label: 'Motivo',
    formatObject: (obj: any) => (obj.status === 'DENIED' ? obj.deniedReason : ''),
  },
  { property: 'converted', label: 'Convertido', format: yesNoStyle },
]

export const ApplicationsConfig = {
  label: 'Applications',
  route: '/applications',
  header: applicationsHeader,
}
