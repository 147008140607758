import { Card, Modal, Tag, Text } from '@bit/ume.design-kit.ui'
import { blue, gray } from '@bit/ume.design-kit.ui/Palette'
import { Box } from 'grommet'
import { Clone } from 'grommet-icons'
import styled from 'styled-components'

export const StyledModal = styled(Modal)`
  width: 100%;
  height: 100%;
`

export const CardStyle = styled(Card)`
  border-radius: 8px;
  height: 553px;
  padding: 0px 20px;
  margin-top: 12px;

  overflow-x: auto;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${gray.gainsboro};
    border-left: 2px solid white;
    border-right: 2px solid white;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${gray.eclipse};
    border-radius: 9px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${gray.nobel};
  }
`
export const SimulationsBoxStyle = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
`
export const TagStyle = styled(Tag)`
  &:nth-child(1) {
    height: 22px;
    width: 88px;
    border-radius: 4px;
    padding: 5px 8px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    justify-content: start;
  }
  &:nth-child(2) {
    margin-left: 12px;
  }
`

export const DifferenceTextStyle = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`

export const HelperTextStyle = styled(Text)`
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0;
  margin-left: 16px;
`

export const LinkAnchorStyle = styled.a`
  color: ${blue.bay};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-decoration: underline;

  &:hover {
    color: white;
  }
`

export const ClipboardIconStyle = styled(Clone)`
  &:hover {
    color: white;
  }
`
