import { Button, Heading } from '@bit/ume.design-kit.ui'
import { gray } from '@bit/ume.design-kit.ui/Palette'
import { Box } from 'grommet'
import styled from 'styled-components'

export const InstallmentDetailsBox = styled(Box)`
  padding: 16px 40px 136px 40px;
  background-color: ${gray.azure};
  text-align: start;
  scroll-behavior: smooth;
`

export const SectionTitleStyle = styled(Heading)`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin: 0;
`

export const PreviousButtonStyle = styled(Button)`
  padding: 0;
`
