import { Checkbox } from '@bit/ume.design-kit.ui'
import * as React from 'react'
import { gray, red, white } from '../../common/assets/_colors'
import { getPayableAdditions } from '../../common/installment-computed-values'
import { ISingleInstallment } from '../../services/bff-backoffice/coordinator/interfaces/installment.interfaces'
import { formatDate, formatNumberToBRL } from '../../utils'
import { StatusTagStyle } from '../RenegotiationDetails/style'

export const renegotiationInstallmentsTableConfig = (
  onInstallmentToggle: (installmentId: string) => void,
  checkedInstallmentsIds: (installmentsIds: string) => void
) => [
  {
    label: 'Selecione',
    attribute: 'id',
    formatCell: (i: ISingleInstallment) =>
      i.status !== 'PAID' ? (
        <Checkbox
          hoverBorder={white.primary}
          id={i.id}
          name="ids"
          checked={checkedInstallmentsIds(i.id)}
          onChange={(event: any) => onInstallmentToggle(i.id)}
        />
      ) : (
        <Checkbox hoverBorder={white.primary} id={i.id} disabled />
      ),
  },
  { attribute: 'id', label: 'identificador' },
  {
    attribute: 'dueDate',
    label: 'vencimento',
    formatCell: (installment: ISingleInstallment) => {
      return formatDate(installment.dueDate)
    },
  },
  {
    attribute: 'installmentValue',
    label: 'valor parcela',
    formatCell: (installment: ISingleInstallment) => {
      return formatNumberToBRL(installment.installmentValue)
    },
  },
  {
    attribute: 'acrescimos',
    label: 'acrescimos',
    formatCell: (installment: ISingleInstallment) => {
      return formatNumberToBRL(
        getPayableAdditions({
          paymentDue: installment.paymentDue,
          value: installment.installmentValue,
          paidValue: installment.paidValue,
          paymentTimestamp: installment.paymentTimestamp,
        })
      )
    },
  },
  {
    attribute: 'paidValue',
    label: 'valor pago',
    formatCell: (installment: ISingleInstallment) => {
      return formatNumberToBRL(installment.paidValue)
    },
  },
  {
    attribute: 'expectedValueOnDate',
    label: 'valor devido',
    formatCell: (i: ISingleInstallment) => {
      return !i.paymentTimestamp
        ? i.status === 'PAYMENT_OVERDUE'
          ? formatNumberToBRL(i.expectedValueOnDate - (i.paidValue ? i.paidValue : 0))
          : formatNumberToBRL(i.installmentValue - (i.paidValue ? i.paidValue : 0))
        : '---'
    },
  },
  {
    attribute: 'status',
    label: 'status',
    formatCell: (i: ISingleInstallment) => {
      switch (i.status) {
        case 'PENDING':
          return (
            <StatusTagStyle
              disable
              backgroundColor={gray.primary}
              label={'A vencer'}
              iconBackground="none"
              iconColor={white.primary}
            />
          )
        case 'PAYMENT_OVERDUE':
          return (
            <StatusTagStyle
              disable
              backgroundColor={red.primary}
              label={'Vencida'}
              iconBackground="none"
              iconColor={white.primary}
            />
          )
        default:
          return <></>
      }
    },
  },
]
