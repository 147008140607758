import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import {
  IBillingResponse,
  IBillingSimulation,
  IEmailResponse,
  IGetBillings,
  IGetEmailResponse,
  IGetPayments,
  IInvoiceResponse,
  IPaymentResponse,
  IPaymentsAggResponse,
  IPostCreateBilling,
  IPostSimulateBilling,
  IPutEmailResponse,
} from './interfaces/billings.interfaces'

export default class BillingsService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async getBillings(params: IGetBillings): Promise<IBillingResponse[]> {
    const response = await this.axiosInstance.get<IBillingResponse[]>(`/billings`, {
      params,
    })
    return response.data
  }

  async getPayments(params: IGetPayments): Promise<IPaymentResponse[]> {
    const response = await this.axiosInstance.get<IPaymentResponse[]>(`/billings/payments`, {
      params,
    })
    return response.data
  }

  async createBillingInvoice(id: string, invoiceDueDate: string): Promise<IInvoiceResponse> {
    const response = await this.axiosInstance.post<IInvoiceResponse>(`/billings/${id}/invoice`, { invoiceDueDate })
    return response.data
  }

  async getEmailsFromStores(stores: string[]): Promise<IGetEmailResponse> {
    const response = await this.axiosInstance.get<IGetEmailResponse>(`/billings/stores/emails`, {
      params: { stores },
    })
    return response.data
  }

  async sendBillingEmail(id: string, emails: string[]): Promise<string> {
    const response = await this.axiosInstance.post<string>(`/billings/${id}/email`, { emails })
    return response.data
  }

  async getBillingPayments(id: string): Promise<IPaymentResponse[]> {
    const response = await this.axiosInstance.get<IPaymentResponse[]>(`/billings/${id}/payments`)
    return response.data
  }

  async simulateBilling(body: IPostSimulateBilling): Promise<IBillingSimulation> {
    const response = await this.axiosInstance.post<IBillingSimulation>(`/billings/simulate`, body)
    return response.data
  }

  async createBilling(body: IPostCreateBilling): Promise<IBillingResponse> {
    const response = await this.axiosInstance.post<IBillingResponse>(`/billings`, body)
    return response.data
  }

  async getPaymentsAgg(params: IGetPayments): Promise<IPaymentsAggResponse> {
    const response = await this.axiosInstance.get<IPaymentsAggResponse>(`/billings/payments/agg`, {
      params,
    })
    return response.data
  }

  async createEmail(id: string, emails: string[]): Promise<IEmailResponse[]> {
    const response = await this.axiosInstance.post<IEmailResponse[]>(`/billings/stores/${id}/email`, { emails })
    return response.data
  }

  async deleteEmail(id: string): Promise<IPutEmailResponse> {
    const response = await this.axiosInstance.delete<IPutEmailResponse>(`/billings/stores/${id}`)
    return response.data
  }
}
