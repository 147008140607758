import { Card } from '@bit/ume.design-kit.ui'
import { gray } from '@bit/ume.design-kit.ui/Palette'
import styled from 'styled-components'
import { screenSize } from '../../config/screens.config'
import { LoadingButton } from '../../molecules/LoadingButton/LoadingButton'

export const Divider = styled.hr`
  width: 90%;
  border: 1px solid rgba(16, 22, 26, 0.15);
  margin: 5px;
  align-self: center;
  margin: auto;
  color: #182026;
`

export const BorrowersPageContainer = styled.div`
  background-color: #f5f6f6;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2);
`

export const CPFLabel = styled.span`
  font-size: 1rem;
  font-weight: bold;
  align-self: center;
  margin-right: 20px;
  font-size: 1.5rem;
  font-family: Roboto;
`

export const CPFFlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 5%;

  @media ${screenSize.mobileL} {
    margin: auto;
    justify-content: center;
    margin: 5px;
  }
`
export const CPFTextInputContainer = styled.div`
  @media ${screenSize.mobileL} {
    width: 100%;
  }
`

export const GreenLoadingButton = styled(LoadingButton)`
  background-color: #137cbd;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  color: white;
  border: green;
  outline: none;
  border-radius: 5px;
  align-self: center;
  margin-left: 10px;
  font-family: Roboto;
  padding-top: 7px;
  padding-bottom: 7px;

  position: relative;

  @media ${screenSize.mobileL} {
    margin-left: 0px;
    width: 100%;
    margin-top: 5px;
  }
`

export const ContractsRenegotiationPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 2em;
`

export const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1em;
  padding: 0;
  align-items: center;
`

export const TableBoxStyle = styled(Card)`
  border-radius: 8px;
  padding: 1em;
  margin-top: 12px;

  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${gray.gainsboro};
    border-left: 2px solid white;
    border-right: 2px solid white;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${gray.eclipse};
    border-radius: 9px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${gray.nobel};
  }
`

export const FormInputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1em;
  width: 100%;
`
export const Label = styled.label`
  font-weight: bold;
  font-size: 1.4em;
`

export const DatePickerContainer = styled.div`
  width: auto;
`

export const RenegotiationSummaryContainer = styled.div`
  display: flex;
  padding: 2em;
  flex-wrap: wrap;
  flex-direction: row-reverse;
`

export const ValueWithLabelContainer = styled.div`
  label {
    font-weight: bold;
    font-size: 1.4em;
  }
  p {
    font-size: 1.4em;
    margin-left: 1em;
  }
  display: flex;
  justify-content: space-between;
  margin: 1em;
`

export const RenegotiationChoicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
`
