import * as React from 'react'
import { Box } from 'grommet'
import { SectionTitleStyle, SectionTextStyle } from './style'

export default class HomeTitle extends React.Component {

  public render() {

    return (
      <Box>
        <SectionTitleStyle>Seja bem-vindo,</SectionTitleStyle>
        <SectionTextStyle bold size = "14px">Como você está hoje?</SectionTextStyle>
      </Box>
    )
  }
}
