import { base, Grommet, Layer, ResponsiveContext } from 'grommet'
import * as React from 'react'
import stripesSmall from '../../common/assets/images/elements/circle1-small.png'
import stripes from '../../common/assets/images/elements/circle1.png'
import circles from '../../common/assets/images/elements/circles3.png'
import dots from '../../common/assets/images/elements/dots.png'
import circle from '../../common/assets/images/elements/fill28-small.png'
import wavesSmall from '../../common/assets/images/elements/waves2-small.png'
import waves2 from '../../common/assets/images/elements/waves2.png'
import { ElementsBox } from './style'

export interface IBackgroundProps {}

export interface IBackgroundState {}

export default class Background extends React.Component<IBackgroundProps, IBackgroundState> {
  constructor(props: IBackgroundProps) {
    super(props)

    this.state = {}
  }

  public render() {
    return (
      <Grommet theme={base}>
        <ResponsiveContext.Consumer>
          {size => (
            <Layer animate={false} full>
              {size === 'small' ? (
                <ElementsBox fill background="white">
                  <img alt="ume" className="top-left mobile" src={wavesSmall} />
                  <img alt="ume" className="bottom-left mobile" src={stripesSmall} />
                  <img alt="ume" className="top-right mobile" src={circle} />
                  {this.props.children}
                </ElementsBox>
              ) : (
                <ElementsBox fill background="white">
                  <img alt="ume" className="top-left" src={stripes} />
                  <img alt="ume" className="bottom-left" src={waves2} />
                  <img alt="ume" className="top-right" src={circles} />
                  <img alt="ume" className="bottom-right" src={dots} />
                  {this.props.children}
                </ElementsBox>
              )}
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      </Grommet>
    )
  }
}
