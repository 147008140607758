import React, { useState, useEffect, useCallback } from 'react'
import { FiltersComponent, Today } from './styles'
import { gray } from '@bit/ume.design-kit.ui/utils/_colors'
import { Button, Heading, LoadingScreen } from '@bit/ume.design-kit.ui'
import moment from 'moment'
import queryString from 'query-string'
import { Box } from 'grommet'
import { toaster } from '../../App'
import { AttendanceCard, Breadcrumbs, InfoCard, Pagination } from '../../molecules/Prioritization'
import { Filters } from '../../organisms/Prioritization'
import { PENDING_TAGS, OVERDUE_TAGS } from '../../molecules/Prioritization/tags'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { IBorrowerProfile, IPrioritizationSummary } from '../../services/bff-backoffice/coordinator/interfaces/tag-system.interfaces'

interface IPrioritizationProps {
  history: any
  location: any
}

interface IPrioritizationState {
  today: string
  summary: IPrioritizationSummary
  borrowers: IBorrowerProfile[]
  allTags: string[]
  pendingTags: string[]
  overdueTags: string[]
  tags: string[]
  retailers: any
  retailerOptions: string[]
  retailerId: number | null
  retailerValue: string | undefined
  loading: boolean
  hideSummary: boolean
  borrowerName: string | null | undefined
  borrowerCpf: number | null | undefined
  pageSize: number
  currentPage: number
  stores: any
  storeId: number | null
  storeValue: any
  storeOptions: string[]
}

const initialState = {
  today:  moment(new Date()).format('LL'),
  summary: {
    totalBorrowers: 0,
    totalContracts: 0,
    totalInstallments: 0
  },
  borrowers: [],
  allTags: [],
  pendingTags: [],
  overdueTags: [],
  tags: [],
  retailers: [{}],
  retailerOptions: [],
  retailerId: null,
  retailerValue: undefined,
  loading: true,
  hideSummary: false,
  borrowerName: null,
  borrowerCpf: null,
  pageSize: 10,
  currentPage: 1,
  stores: [{}],
  storeValue: undefined,
  storeId: null,
  storeOptions: []
}

const Prioritization = (props: IPrioritizationProps) => {
  const isName = /^[A-Za-zÀ-ȕ\s]*$/
  const queryParams = queryString.parse(props.location.search)
  const url = window.location.origin + window.location.pathname
  const [state, setState] = useState<IPrioritizationState>(initialState)
  
  const getTags = useCallback(() => {
    bffBackofficeApiService.coordinator.getTags()
    .then((response) => {
      getFilteredTags(response.tags)
      initializeMultiTag(response.tags)
    })
    .catch((error) => {
      toaster.showErrorToast("Erro ao carregar as Tags")
      console.error('Prioritization#getTags -> ', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getTags()
    getRetailers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTags])

  const updateQueryParams = (page: number, pageSize: number) => {
    const newUrl = url + `?page=${page}&page_size=${pageSize}`
    window.history.pushState({path: newUrl}, '', newUrl)
  }

  const getPage = () => {
    if (queryParams.page) {
      setState(prevState => ({
        ...prevState,
        currentPage: Number(queryParams.page)
      }))
      return Number(queryParams.page)
    }
    return 1
  }

  const getPageSize = () => {
    if (queryParams['page_size']) {
      setState(prevState => ({
        ...prevState,
        pageSize: Number(queryParams['page_size'])
      }))
      return Number(queryParams['page_size'])
    }
    return 10
  }

  const initializeMultiTag = (tags: any) => {
    const keys: any = tags.keys()
    let initialKeys : any = [] 
    
    for (const key of keys) {
      initialKeys.push(key) 
    }

    setState(prevState => ({
      ...prevState,
      selected: initialKeys
    }))
  }

  const onRemoveTag = (selectedTag: any | any[]) => {
    const { tags } = state
    const updatedTags: any[] = []
    tags.forEach(tag => {
      if (tag !== selectedTag) {
        updatedTags.push(tag)
      }
    })

    setState(prevState => ({
      ...prevState,
      tags: updatedTags
    }))

  }

  const onPickTag = (options: any) => {
    const newTag = options.value
    const updatedTags = state.tags
    let isExist = false
    updatedTags.forEach((tag: string) => {
      if(tag === newTag) isExist = true
    })
    
    if(!isExist) {
      updatedTags.push(newTag)
      setState(prevState => ({
        ...prevState,
        tags: updatedTags
      }))
    }
  }
  
  const getRetailers = async() => {
    bffBackofficeApiService.retailers.getRetailers()
      .then(response => {
        setState(prevState => ({
          ...prevState,
          retailers: response.retailers,
        }))
        getFilteredRetailers(response.retailers)
      })
  }

  const getFilteredRetailers = (retailers: any[]) => {
    const retailerOptions: string[] = []

    retailers.map((retailer) => {
      return retailerOptions.push(retailer.fantasyName)
    })

    setState(prevState => ({
      ...prevState,
      retailerOptions
    }))

    return retailerOptions;
  }

  const getFilteredTags = (tags: any) => {
    const pending: any = []
    const overdue: any = []
    const allTags: any = []
    const page = getPage()
    const pageSize = getPageSize()

    tags.forEach((tag: { name: string }) => {
      allTags.push(tag.name)
      PENDING_TAGS.forEach((tagName: string) => {
        if (tagName === tag.name) {
          pending.push(tag.name)
        }
      })
      OVERDUE_TAGS.forEach((tagName: string) => {
        if (tagName === tag.name) {
          overdue.push(tag.name)
        }
      })
    })

    setState(prevState => ({
      ...prevState,
      tags: allTags,
      allTags: allTags,
      pendingTags: pending,
      overdueTags: overdue
    }))

    getSummary(allTags)
    getBorrowers(undefined, allTags, undefined, undefined, undefined, page, pageSize)
  }

  const getSummary = async(summary: string[], retailerId?: string, storeId?: string) => {
    await bffBackofficeApiService.coordinator.getTagsPrioritizationSummary({ tags: summary, retailerId, storeId })
      .then((response) => {
        setState(prevState => ({
          ...prevState,
          summary: response
        }))
      })
      .catch((error) => {
        console.error('Prioritization#getPendingSummary -> ', error);
      })
      
    }
    
  const getBorrowers = async(borrowerId?: any, tags?: string[], borrowerName?: string, retailerId?: string, storeId?: string, page?: number, pageSize?: number) => {
    setState(prevState => ({
      ...prevState,
      loading: true
    }))

    if (borrowerName) {
      setState(prevState => ({
        ...prevState,
        hideSummary: true
      }))
    } else {
        setState(prevState => ({
          ...prevState,
          hideSummary: false
        }))
    }

    await bffBackofficeApiService.coordinator
      .getBorrowersTagSystem({borrowerId, tags, borrowerName, retailerId, storeId, page, pageSize})
      .then(response => {
        setState(prevState => ({
          ...prevState,
          borrowers: response.borrowerProfiles,
          loading: false,
        }))
      })
      .catch(error => {
        toaster.showErrorToast('Não foi possível achar perfis com esses filtros')
        setState(prevState => ({
          ...prevState,
          borrowers: [],
          summary: { totalBorrowers: 0, totalContracts: 0, totalInstallments: 0 },
          loading: false,
        }))
        console.error('Prioritization#getPendingSummary -> ', error)
      })
  }


  const onPickRetailer = (options: any) => {
    const retailers = state.retailers
    let retailerId: any = null

    retailers.filter((retailer: any) => {
      return retailer.fantasyName === options.value ? retailerId = retailer.id : null
    });
    fetchStoresById(retailerId)
    setState(prevState => ({
      ...prevState,
      retailerId: retailerId,
      retailerValue: options.value
    }))
  }

  const fetchStoresById = async(id: any) => {
    const selectedStores: any = []
    let stores: any = []
    await bffBackofficeApiService.retailers.getStores()
      .then((response) => {
        stores = response.stores
        response.stores.forEach(store => {
          if (store.retailerId === id) {
            selectedStores.push(store.name)
          }
        })
      })
      .catch(() => {
        toaster.showErrorToast("Não foi possível encontrar as lojas")
      })
    setState(prevState => ({
      ...prevState,
      storeOptions: selectedStores,
      stores
    }))
  }
  const onPickStore = (options: any) => {
    const stores = state.stores
    const value = options.value
    let storeId: any = null

    stores.filter((store: any) => {
      return store.name === value ? storeId = store.id : null
    })

    setState(prevState => ({
      ...prevState,
      storeId: storeId,
      storeValue: value
    }))
  }

  const searchBorrower = () => {
    const page: number = state.currentPage
    const pageSize: number = state.pageSize
    const borrowerName: any = state.borrowerName;
    const borrowerCpf: any = state.borrowerCpf;

    if (borrowerName) {
      getBorrowers(undefined, undefined, borrowerName, undefined, undefined, page, pageSize)
      setState(prevState => ({
        ...prevState,
        borrowerCpf: null 
      }))
    } else if (borrowerCpf) {
      getBorrowerByCpf(borrowerCpf)
      setState(prevState => ({
        ...prevState,
        borrowerName: null
      }))
    } else {
      toaster.showErrorToast("Não foi encontrado nenhum Nome ou CPF")
    }
  }

  const getBorrowerByCpf = async(cpf: string) => {
    const cpfValid = cpf.replace(/[^\w\s]/gi, '')
    
    await bffBackofficeApiService.borrowers.getBorrowerByDocument(cpfValid)
      .then(({ id }) => {
        props.history.push(`/profiles/${id}`)
      })
      .catch((error) => {
        toaster.showErrorToast("Não foi possível buscar esse CPF")
        console.error('Prioritization#getBorrowerByCpf -> ', error);
      })
  }

  const updatePage = (page: any) => {
    setState(prevState => ({
      ...prevState,
      currentPage: page
    }))

    const currentTags: any = state.tags
    const retailerId: any = state.retailerId
    const borrowerName: any = state.borrowerName
    const pageSize: number = state.pageSize
    const storeId: any = state.storeId

    updateQueryParams(page, pageSize)
    getSummary(currentTags, retailerId)
    getBorrowers(undefined, currentTags, borrowerName, retailerId, storeId, page, pageSize)
    
  }

  const clearAllTags = () => {
    setState(prevState => ({
      ...prevState,
      tags: []
    }))
  }
  
  const filter = () => {
    setState(prevState => ({
      ...prevState,
      currentPage: 1
    }))
    const currentTags: any = state.tags
    const retailerId: any = state.retailerId
    const pageSize: number = state.pageSize
    const storeId: any = state.storeId

    updateQueryParams(1, pageSize)
    getSummary(currentTags, retailerId)
    getBorrowers(undefined, currentTags, undefined, retailerId, storeId, 1, pageSize)

  }

  const selectPageSize = (options: any) => {
    const tags: any = state.tags
    const retailerId: any = state.retailerId
    const borrowerName: any = state.borrowerName
    const storeId: any = state.storeId
    
    setState(prevState => ({
      ...prevState,
      pageSize: options.value
    }))
    updateQueryParams(state.currentPage, options.value)
    getBorrowers(undefined, tags, borrowerName, retailerId, storeId, 1, options.value)
  }

  const onChangeNameOrCpf = (event: any) => {
    const value = event.target.value
    
    if (value.match(isName)) {
      setState(prevState => ({
        ...prevState,
        borrowerName: value,
        borrowerCpf: null
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        borrowerCpf: value,
        borrowerName: null
      }))
    }
  }

  return(
    <div style={{ display: 'flex', backgroundColor: gray.azure, minHeight: '94vh' }}>
      {
        state.loading ?
          <LoadingScreen /> :
        <>
          <FiltersComponent>
            <Filters
              // BorrowerSearch
              borrowerName={state.borrowerName}
              getValue={searchBorrower}
              onChange={onChangeNameOrCpf}
              // RetailerPicker
              retailersOptions={state.retailerOptions}
              retailers={state.retailers}
              value={state.retailerValue}
              onPick={onPickRetailer}
              // StorePicker
              storeOptions={state.storeOptions}
              onPickStore={onPickStore}
              storeValue={state.storeValue}
              // MultiTag
              onPickTag={onPickTag}
              onRemoveTag={onRemoveTag}
              tags={state.tags}
              tagOptions={state.allTags}
              onClearTags={clearAllTags}
            />

            <Button onClick={filter} label="Filtrar" width="-webkit-fill-available" margin="0 32px" />

          </FiltersComponent>
          <div style={{ padding: "16px 64px", width: "100%"}}>
            <div style={{ textAlign: "initial"}}>
              <Breadcrumbs />
            </div>
            <Today>
              <Heading level={3}>
                {state.today}
              </Heading>
            </Today>
            <Box animation="zoomIn">
            {
              state.hideSummary ?
                null :
                <div style={{ display: "flex", flexWrap: "wrap"}}>
                  <InfoCard name="Usuários" value={state.summary.totalBorrowers} />
                  <InfoCard name="Contratos" value={state.summary.totalContracts} />
                  <InfoCard name="Parcelas" value={state.summary.totalInstallments} />
                </div>
            }
              <div style={{ display: "flex", flexWrap: "wrap", paddingRight: "16px"}}>
                {
                  Array.isArray(state.borrowers) &&
                  state.borrowers.map((borrower) => (
                    borrower.tags.length === 0 ?
                    <AttendanceCard
                      key={borrower.id}
                      name={borrower.name}
                      contracts={0}
                      totalDebt={0}
                      tag={"semParcelasAVencer"}
                      history={props.history}
                      borrowerId={borrower.id}
                    />
                   :
                   borrower.tags.map((info: any) => (
                    <AttendanceCard
                      key={borrower.id}
                      name={borrower.name}
                      contracts={info.contracts}
                      totalDebt={info.totalDebt}
                      tag={info.tag}
                      history={props.history}
                      borrowerId={borrower.id}
                    />
                  ))
                  ))
                }
              </div>
              <>
                {
                  state.borrowers.length === 0 ?
                  <Box animation={"zoomOut"}>
                    <Heading level={2}>
                      {"Não foi possível encontrar nenhum resultado! :("}
                    </Heading>
                  </Box>
                  :
                  <Pagination
                    currentPage={state.currentPage}
                    pageSize={state.pageSize}
                    totalData={state.summary.totalBorrowers}
                    onNextPage={() => updatePage(state.currentPage+1)}
                    onPreviousPage={() => updatePage(state.currentPage-1)}
                    onSelectPageSize={selectPageSize}
                  />
                }
              </>
            </Box>
          </div>
        </>
      }
    </div>
  )
}

export default Prioritization