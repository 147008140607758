import { TextInput } from '@bit/ume.design-kit.ui'
import { Box } from 'grommet'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { toaster } from '../../App'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { emailIsValid, validate_celular, validate_cpf } from '../../common/Validators'
import UMEDataTable from '../../molecules/DataTable/DataTable'
import DropDownInput from '../../molecules/RetailersPage/DropDownInput'
import MaskedInput from '../../molecules/RetailersPage/MaskedInput'
import { StyledButton } from '../../pages/Retailers/style'
import { OperatorsSliceReducer } from '../../redux/reducers/operators/operators.reducer'
import { RetailersSliceReducer } from '../../redux/reducers/retailers/retailers.reducer'
import { StoresSliceReducer } from '../../redux/reducers/stores/stores.reducer'
import { IOperator, IOperatorPostReq } from '../../services/bff-backoffice/coordinator/interfaces/retailers.interfaces'
import {
  IRetailerResponse,
  IStoreResponse,
} from '../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'
import { TableContainer } from '../ForcedApprovalModification/style'
import FormModal from './FormModal'
import { SectionTitleStyle, StyledTextRetailerName } from './styles'

interface IOperatorsTabProps extends RouteComponentProps {
  retailer: IRetailerResponse
  operators?: IOperator[]
  stores: IStoreResponse[]
  storesNames: string[]
  isCreateOperatorModalOpen: boolean
  fetchRetailerStores: (retailerId: string) => {}
  fetchRetailerOperators: (retailerId: string) => {}
  resetRetailerOriginationParameters: () => {}
  createOperator: (body: IOperatorPostReq) => {}
  createOperatorModalOpen: () => {}
  createOperatorModalClose: () => {}
}

interface IOperatorsTabState {
  operatorsFiltered?: IOperator[]
  storeNames?: any
  name: string
  cpf: string
  email: string
  phoneNumber: string
  operatorStore: string
  operatorRole: string
  password: string
  confirmPassword: string
  nameErrorIntent: boolean
  cpfErrorIntent: boolean
  emailErrorIntent: boolean
  phoneNumberErrorIntent: boolean
  passwordErrorIntent: boolean
  isLoadingSaveOperatorButton: boolean
}

const initialState: IOperatorsTabState = {
  name: '',
  cpf: '',
  email: '',
  phoneNumber: '',
  operatorStore: '',
  operatorRole: 'Representante',
  password: '',
  confirmPassword: '',
  nameErrorIntent: false,
  cpfErrorIntent: false,
  emailErrorIntent: false,
  phoneNumberErrorIntent: false,
  passwordErrorIntent: false,
  isLoadingSaveOperatorButton: false,
}

const roles: any = {
  manager: 'Gerente',
  attendant: 'Atendente',
  representative: 'Representante',
  analyst: 'Analista',
  'retailer-analyst': 'Analista de Varejo',
  cashier: 'Caixa',
  'risk-analyst': 'Analista de Risco',
}

const inputRoles: any = {
  Gerente: 'manager',
  Atendente: 'attendant',
  Representante: 'representative',
  Caixa: 'cashier',
  'Analista de Varejo': 'retailer-analyst',
}

const CpfMaskInput = [
  {
    length: 3,
    regexp: /\d/,
    placeholder: '000',
  },
  { fixed: '.' },
  {
    length: 3,
    regexp: /\d/,
    placeholder: '000',
  },
  { fixed: '.' },
  {
    length: 3,
    regexp: /\d/,
    placeholder: '000',
  },
  { fixed: '-' },
  {
    length: 2,
    regexp: /\d/,
    placeholder: '00',
  },
]

const CellphoneMaskInput = [
  { fixed: '(' },
  {
    length: 1,
    regexp: /[1-9]/,
    placeholder: '0',
  },
  {
    length: 1,
    regexp: /\d/,
    placeholder: '0',
  },
  { fixed: ') ' },
  {
    length: 1,
    regexp: /[5-9]/,
    placeholder: '0',
  },
  {
    length: 4,
    regexp: /\d/,
    placeholder: '0000',
  },
  { fixed: '-' },
  {
    length: 4,
    regexp: /\d/,
    placeholder: '0000',
  },
]

const regexNumber = /(?=.*?[0-9])/

class OperatorsTab extends React.Component<IOperatorsTabProps, IOperatorsTabState> {
  constructor(props: IOperatorsTabProps) {
    super(props)
    this.state = initialState
  }

  componentDidMount = () => {
    // TODO: use retailerId to fetch the operators
    if (this.props.retailer) {
      if (!this.props.operators) {
        this.props.fetchRetailerOperators(this.props.retailer.id)
      } else {
        this.setState({ operatorsFiltered: this.props.operators })
      }

      if (!this.props.stores) {
        this.props.fetchRetailerStores(this.props.retailer.id)
        return
      }

      const storeNames: any = {}
      this.props.stores.forEach(store => {
        storeNames[store.id] = store.name
      })

      if (this.props.stores[0]) {
        this.setState({ storeNames, operatorStore: this.props.stores[0].name })
      } else {
        this.setState({ storeNames })
      }
    }
  }

  componentDidUpdate = async (prevProps: any) => {
    // Sets the dropdown index when a retailerId is given and the retailers are loaded
    if (prevProps.retailer !== this.props.retailer && this.props.retailer) {
      this.props.fetchRetailerStores(this.props.retailer.id)
      this.props.fetchRetailerOperators(this.props.retailer.id)
      this.props.resetRetailerOriginationParameters()
    }

    if (prevProps.stores !== this.props.stores && this.props.stores) {
      const storeNames: any = {}
      this.props.stores.forEach(store => {
        storeNames[store.id] = store.name
      })
      this.setState({ storeNames, operatorStore: this.props.storesNames[0] })
    }

    if (prevProps.operators !== this.props.operators && this.props.operators) {
      this.setState({ operatorsFiltered: this.props.operators })
    }
  }

  onFilterSelected = (attribute: string, valueChangedTo: string) => {
    if (valueChangedTo === 'Todos') {
      let data = Object.assign([], this.props.operators)
      this.setState({ operatorsFiltered: data })
      return
    }

    if (!this.props.operators) return

    let data = Object.assign(
      [],
      this.props.operators.map(operator => ({
        ...operator,
        storeName: this.state.storeNames[operator.storeId],
      }))
    )
    data = data.filter((el: any) => el['storeId'] === valueChangedTo)

    this.setState({ operatorsFiltered: data })
  }

  onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const state: any = this.state
    state[e.target.name] = e.target.value
    this.setState(state)
  }

  onChangeDropDownInput = (e: any) => {
    const state: any = this.state
    state[e.target.name] = e.value
    this.setState(state)
  }

  public passwordIsValid(password: string) {
    return password.length > 5 && regexNumber.test(password)
  }

  onSaveOperator = () => {
    this.setState({ isLoadingSaveOperatorButton: true })

    const { name, cpf, email, phoneNumber, password, confirmPassword, operatorStore, operatorRole } = this.state

    let error = false
    let nameErrorIntent = false
    let cpfErrorIntent = false
    let emailErrorIntent = false
    let phoneNumberErrorIntent = false
    let passwordErrorIntent = false

    if (name.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Nome deve conter pelo menos um caracter.')
      nameErrorIntent = true
      error = true
    } else {
      nameErrorIntent = false
    }

    if (!validate_cpf(cpf)) {
      toaster.showErrorToast('CPF inválido.')
      cpfErrorIntent = true
      error = true
    } else {
      cpfErrorIntent = false
    }

    if (!emailIsValid(email)) {
      toaster.showErrorToast('E-mail inválido.')
      emailErrorIntent = true
      error = true
    } else {
      emailErrorIntent = false
    }

    if (!validate_celular(phoneNumber)) {
      toaster.showErrorToast('Telefone inválido.')
      phoneNumberErrorIntent = true
      error = true
    } else {
      phoneNumberErrorIntent = false
    }

    if (!this.passwordIsValid(password)) {
      toaster.showErrorToast('Senha deve ter tamanho mínimo 6 e conter pelo menos um número.')
      passwordErrorIntent = true
      error = true
    } else if (confirmPassword !== password) {
      toaster.showErrorToast('Senhas não conferem.')
      passwordErrorIntent = true
      error = true
    } else {
      passwordErrorIntent = false
    }

    const { retailer, stores } = this.props

    let storeId: any = stores.find(store => store.name === operatorStore)
    if (storeId === undefined) {
      toaster.showErrorToast('Loja inválida.')
      error = true
    } else {
      storeId = storeId.id
    }

    this.setState({
      nameErrorIntent,
      cpfErrorIntent,
      emailErrorIntent,
      phoneNumberErrorIntent,
      passwordErrorIntent,
    })

    if (error) {
      this.setState({ isLoadingSaveOperatorButton: false })
      return
    }

    const body: IOperatorPostReq = {
      name,
      active: true,
      cpf: cpf
        .replace('.', '')
        .replace('.', '')
        .replace('-', ''),
      email,
      // TODO - Add country code in form
      phoneNumber:
        '+55' +
        phoneNumber
          .replace(' ', '')
          .replace('(', '')
          .replace(')', '')
          .replace('-', ''),
      retailerId: retailer.id,
      role: inputRoles[operatorRole],
      password,
      storeId,
    }

    this.props.createOperator(body)
  }

  createOperatorFields = () => {
    return [
      <StyledTextRetailerName key={0}>{this.props.retailer.fantasyName}</StyledTextRetailerName>,
      <TextInput
        required
        label="Nome"
        key={1}
        name={'name'}
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.name}
        errorIntent={this.state.nameErrorIntent}
      />,
      <MaskedInput
        required
        label="CPF"
        mask={CpfMaskInput}
        placeholder="000.000.000-00"
        key={2}
        name={'cpf'}
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.cpf}
        errorIntent={this.state.cpfErrorIntent}
      />,
      <TextInput
        required
        label="E-mail"
        type="email"
        placeholder="email@email.com"
        key={3}
        name={'email'}
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.email}
        errorIntent={this.state.emailErrorIntent}
      />,
      <MaskedInput
        required
        label="Telefone"
        key={4}
        width="100%"
        name={'phoneNumber'}
        mask={CellphoneMaskInput}
        placeholder="(92) 99999-9999"
        onChange={this.onChangeInput}
        value={this.state.phoneNumber}
        errorIntent={this.state.phoneNumberErrorIntent}
      />,
      <DropDownInput
        required
        style={{ border: 'none' }}
        options={this.props.storesNames}
        label="Loja"
        key={5}
        name={'operatorStore'}
        width="100%"
        onChange={this.onChangeDropDownInput}
        value={this.state.operatorStore}
      />,
      <DropDownInput
        required
        style={{ border: 'none' }}
        options={['Representante', 'Caixa', 'Gerente', 'Atendente', 'Analista de Varejo']}
        label="Função"
        key={6}
        name={'operatorRole'}
        width="100%"
        onChange={this.onChangeDropDownInput}
        value={this.state.operatorRole}
      />,
      <TextInput
        required
        label="Senha"
        type="password"
        key={7}
        name={'password'}
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.password}
        errorIntent={this.state.passwordErrorIntent}
      />,
      <TextInput
        required
        label="Confirme a Senha"
        type="password"
        key={8}
        name="confirmPassword"
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.confirmPassword}
        errorIntent={this.state.passwordErrorIntent}
      />,
    ]
  }

  render() {
    const { operatorsFiltered, storeNames, isLoadingSaveOperatorButton } = this.state
    const { isCreateOperatorModalOpen } = this.props
    const { stores } = this.props

    if (!operatorsFiltered || !stores || !storeNames) {
      return (
        <Box width="100%" height="50vh">
          <LoadingIcon />
        </Box>
      )
    }

    return (
      <>
        {isCreateOperatorModalOpen ? (
          <FormModal
            title="Criar Operador"
            isModalOpen={isCreateOperatorModalOpen}
            fields={this.createOperatorFields()}
            onClose={this.props.createOperatorModalClose}
            onCreate={this.onSaveOperator}
            isLoadingButton={isLoadingSaveOperatorButton}
          />
        ) : (
          <></>
        )}

        <Box style={{ maxWidth: '1000px' }} direction="column">
          <Box width="100%" direction="row-responsive" align="center" justify="between" margin={{ vertical: '0' }}>
            <SectionTitleStyle style={{ marginBottom: 0 }}>Lista de Operadores</SectionTitleStyle>

            <StyledButton onClick={this.props.createOperatorModalOpen}>Cadastrar Operador</StyledButton>
          </Box>
          <TableContainer style={{ maxHeight: '600px' }}>
            {operatorsFiltered.length > 0 ? (
              <UMEDataTable
                onFilterSelected={this.onFilterSelected}
                header={[
                  {
                    label: 'Nome',
                    attribute: 'name',
                  },
                  {
                    label: 'CPF',
                    attribute: 'cpf',
                  },
                  {
                    label: 'E-mail',
                    attribute: 'email',
                  },
                  {
                    label: 'Telefone',
                    attribute: 'phoneNumber',
                  },
                  {
                    label: 'Função',
                    attribute: 'role',
                    formatCell: (row: any) => {
                      if (roles[row.role]) return roles[row.role]
                      else return row.role
                    },
                  },
                  {
                    label: 'Loja',
                    attribute: 'storeName',
                    dropDownValues: stores.map(store => ({
                      label: store.name,
                      value: store.id,
                    })),
                  },
                ]}
                data={operatorsFiltered.map(operator => ({
                  ...operator,
                  storeName: storeNames[operator.storeId],
                }))}
              />
            ) : (
              <Box pad={{ top: '33px' }} align="center" justify="center" margin={{ vertical: '50px' }}>
                Não há operadores cadastrados.
              </Box>
            )}
          </TableContainer>
        </Box>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  retailer: state.retailers.retailer,
  stores: state.stores.stores,
  operators: state.operators.operators,
  storesNames: state.stores.storesNames,
  isCreateOperatorModalOpen: state.operators.isCreateOperatorModalOpen,
})

const mapDispatchToProps = (dispatch: any) => ({
  fetchRetailerStores: (retailerId: string) => dispatch(StoresSliceReducer.actions.fetchRetailerStores(retailerId)),
  fetchRetailerOperators: (retailerId: string) =>
    dispatch(OperatorsSliceReducer.actions.fetchRetailerOperators(retailerId)),
  resetRetailerOriginationParameters: () => dispatch(RetailersSliceReducer.actions.originationsParametersReset()),
  createOperator: (body: IOperatorPostReq) => dispatch(OperatorsSliceReducer.actions.createOperator(body)),
  createOperatorModalOpen: () => dispatch(OperatorsSliceReducer.actions.createOperatorModalOpen()),
  createOperatorModalClose: () => dispatch(OperatorsSliceReducer.actions.createOperatorModalClose()),
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatorsTab)
