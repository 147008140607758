import { Clipboard, DataTable, Loading, Text } from '@bit/ume.design-kit.ui'
import { gray, green, red, yellow } from '@bit/ume.design-kit.ui/Palette'
import { Box } from 'grommet'
import React, { useState } from 'react'
import { toaster } from '../../App'
import { IInvoice } from '../../services/bff-backoffice/coordinator/interfaces/invoice.interfaces'
import { formatDate, formatDateTreatingError, formatNumberToBRL } from '../../utils'
import { InvoiceInstallmentsModal } from './InvoiceInstallmentsModal'
import { LinkAnchorStyle, StatusTagStyle, TableBoxStyle } from './style'
import { mapInvoicesTableRows } from './utils/helpers'

interface IInvoicesTableProps {
  history?: any
  invoices?: IInvoice[] | Error
}

export interface InvoicesTableRow extends IInvoice {
  operator: string
}

export const InvoicesTable = (props: IInvoicesTableProps) => {
  const { invoices } = props

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice>()

  const columnsDropDown = [
    {
      label: 'ID do boleto',
      attribute: 'id',
    },
    {
      attribute: 'installments',
      label: 'Parcelas',
      formatCell: (i: InvoicesTableRow) => {
        if (!i.installments || i.installments.length === 0) {
          return '-'
        }

        if (i.installments.length === 1) {
          return <LinkAnchorStyle href={`/installment/${i.installments[0].id}`}>{i.installments[0].id}</LinkAnchorStyle>
        }

        return (
          <LinkAnchorStyle
            onClick={() => {
              setIsModalOpen(true)
              setSelectedInvoice(i)
            }}
          >
            Ver todas
          </LinkAnchorStyle>
        )
      },
    },
    { attribute: 'createdOn', label: 'Gerado em', formatCell: (i: InvoicesTableRow) => formatDate(i.createdOn) },
    {
      attribute: 'expectedValue',
      label: 'Valor do boleto',
      formatCell: (i: InvoicesTableRow) => formatNumberToBRL(i.value),
    },
    { attribute: 'dueDate', label: 'Vencimento', formatCell: (i: InvoicesTableRow) => formatDate(i.dueDate) },
    {
      attribute: 'barcode',
      label: 'Link do boleto',
      formatCell: (i: InvoicesTableRow) => (
        <Box direction="row" align="center" gap="medium">
          <LinkAnchorStyle target="_blank" href={i.url + '.pdf'}>
            {i?.barcode?.slice(0, 5) ? i?.barcode?.slice(0, 5) + '...' : '---------'}
          </LinkAnchorStyle>
          <div
            title="Copiar link do boleto"
            onClick={() => toaster.showSuccessToast('Copiado para área de transferência')}
          >
            <Clipboard value={i.url + '.pdf'} />
          </div>
        </Box>
      ),
    },
    {
      attribute: 'operator',
      label: 'Operador',
    },
    {
      attribute: 'modifiedOn',
      label: 'Última atualização',
      formatCell: (i: InvoicesTableRow) => formatDate(i.modifiedOn),
    },
    {
      attribute: 'paidValue',
      label: 'Valor pago',
      formatCell: (i: InvoicesTableRow) => formatNumberToBRL(i.paidValue),
    },
    {
      label: 'Status',
      attribute: 'status',
      formatCell: (i: InvoicesTableRow) => {
        switch (i.status) {
          case 'pending':
            return <StatusTagStyle disable backgroundColor={gray.primary} label={'A pagar'} />
          case 'paid':
            return <StatusTagStyle disable backgroundColor={green.laurel} label={'Pago'} />
          case 'overdue':
            return <StatusTagStyle disable backgroundColor={red.primary} label={'Atrasado'} />
          case 'expired':
            return <StatusTagStyle disable backgroundColor={red.primary} label={'Vencido'} />
          case 'canceled':
            return <StatusTagStyle disable backgroundColor={yellow.citrine} label={'Cancelado'} />
          default:
            return 'no status'
        }
      },
    },

    {
      attribute: 'paymentDate',
      label: 'Data do pagamento',
      formatCell: (i: InvoicesTableRow) => (i.paymentDate ? formatDateTreatingError(i.paymentDate) : '--'),
    },
    {
      attribute: 'liquidationDate',
      label: 'Data de compensação',
      formatCell: (i: InvoicesTableRow) => (i.liquidationDate ? formatDateTreatingError(i.liquidationDate) : '--'),
    },
    {
      label: 'ID Iugu',
      attribute: 'partnerInvoiceId',
    },
  ]

  if (!invoices) {
    return (
      <TableBoxStyle>
        <Box fill align="center" justify="center">
          <Loading />
        </Box>
      </TableBoxStyle>
    )
  }

  if (invoices instanceof Error) {
    return (
      <TableBoxStyle>
        <Box margin={{ top: '20px' }}>
          <Text color="red" light>
            Não foi possível carregar os dados para este cliente.
          </Text>
        </Box>
      </TableBoxStyle>
    )
  }

  const invoicesTableRows = mapInvoicesTableRows(invoices)

  return (
    <>
      <InvoiceInstallmentsModal
        invoice={selectedInvoice}
        isModalOpen={isModalOpen}
        onCancel={async () => setIsModalOpen(false)}
        history={props.history}
      />
      <DataTable header={columnsDropDown} data={invoicesTableRows} fontSize="14px" />
    </>
  )
}
