import React from 'react'
import { Link } from 'react-router-dom'
import { BreadcrumbsBox, BreadcrumbsItem } from '../RetailersPage/styles'

interface Props {
  fantasyName: string
}

const RetailerBenchApplicationsBreadcrumbs = ({ fantasyName }: Props) => {
  return (
    <BreadcrumbsBox>
      <Link to="/">
        <BreadcrumbsItem>Início</BreadcrumbsItem>
      </Link>
      <BreadcrumbsItem>/</BreadcrumbsItem>
      <Link to="/retailers-bench">
        <BreadcrumbsItem>Banca de Varejistas</BreadcrumbsItem>
      </Link>
      <BreadcrumbsItem>/</BreadcrumbsItem>
      <BreadcrumbsItem active>{fantasyName}</BreadcrumbsItem>
    </BreadcrumbsBox>
  )
}

export default RetailerBenchApplicationsBreadcrumbs
