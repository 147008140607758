import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { FormNextLink } from 'grommet-icons'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { IBatchResponse } from '../../services/bff-backoffice/liquidation-originations/interfaces/liquidation-originations.interfaces'
import { BatchCard } from './BatchCard'

interface IBatchCanbanColumnProps {
  from: string
  to: string
  stepOrder: string
  stepDescription: string
  isLoading: boolean
  batches?: IBatchResponse[]
  isError: boolean
}

export const BatchCanbanColumn = (props: IBatchCanbanColumnProps & React.HTMLAttributes<IBatchCanbanColumnProps>) => {
  const { from, stepDescription, stepOrder, to, isError } = props

  const renderCards = () => {
    const { isLoading, batches } = props

    if (isLoading) {
      return <LoadingIcon />
    }

    if (batches && !batches.length) {
      return <NoBatchFound> Nenhuma remessa com esse estado. </NoBatchFound>
    }

    if (batches) {
      return batches.map((batch, index) => (
        <Link to={`/financial/originations/batch/${batch.id}`}>
          <BatchCard
            style={{ marginBottom: '40px' }}
            date={batch.batchDate}
            price={batch.principalSum}
            quantity={batch.originationsCount}
            key={index}
          />
        </Link>
      ))
    }
  }

  return (
    <StatusColumn style={props.style}>
      <StatusHeader>
        <StepOrder>{stepOrder}</StepOrder>
        <StepTitle>
          {from}
          <FormNextLink
            style={{
              display: 'inline-block',
              fill: UMEColors.black.primary,
              stroke: UMEColors.black.primary,
              height: 35,
              width: 35,
              margin: '0px 16px 0px 16px',
            }}
          />
          {to}
        </StepTitle>
        <StepDescription> {stepDescription} </StepDescription>
      </StatusHeader>

      {/* TODO - Improve this error message */}
      <CardsContainer>{isError ? <h3> Erro ao carregar remessas. </h3> : renderCards()}</CardsContainer>
    </StatusColumn>
  )
}

const NoBatchFound = styled.h3`
  margin-bottom: 3vh;
`

const StatusColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  padding: 0px 15px 0px 15px;
`

const StatusHeader = styled.div`
  display: flex;
  flex-direction: column;
  height: 90px;

  text-align: left;
`

const StepOrder = styled.p`
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;

  margin-bottom: 5px;
`

const StepTitle = styled.div`
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 5px;
`
const StepDescription = styled.p`
  width: 291px;
  // TODO - Not in Design Kit
  color: #8c8c8c;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;

  // text-wrap: wrap;
`
const CardsContainer = styled.div`
  margin-top: 3vh;
`
