import { DataTable, Loading, Text } from '@bit/ume.design-kit.ui'
import { gray, green, red, white } from '@bit/ume.design-kit.ui/Palette'
import { yellow } from '@bit/ume.design-kit.ui/utils/_colors'
import { Box } from 'grommet'
import * as React from 'react'
import { IRenegotiationResponse } from '../../../services/bff-backoffice/coordinator/interfaces/renegotiation.interfaces'
import { formatDate, formatNumberToBRL } from '../../../utils'
import { CardStyle, TagStyle } from './style'
const moment = require('moment')

export interface IRenegotiationsCardProps {
  renegotiations?: IRenegotiationResponse[]
  history: any
  onMounted?: () => void
}

export default class RenegotiationsCard extends React.PureComponent<IRenegotiationsCardProps> {
  componentDidMount(): void {
    if (this.props.onMounted) this.props.onMounted()
  }

  public columnsDropDown = [
    {
      label: 'Id',
      attribute: 'id',
    },
    {
      label: 'Dívida',
      formatCell: (r: IRenegotiationResponse) => formatNumberToBRL(r.totalDebt),
    },
    {
      label: 'Desconto',
      formatCell: (r: IRenegotiationResponse) => formatNumberToBRL(r.monetaryDiscount),
    },
    {
      label: 'Valor Acordo',
      formatCell: (r: IRenegotiationResponse) => formatNumberToBRL(r.renegotiationDueValue),
    },
    {
      label: 'Qtd. de Pagamentos',
      formatCell: (r: IRenegotiationResponse) => `${r.upfrontPayment ? '1' : '0'} + ${r.numberOfPayments}x`,
    },
    {
      label: 'Data do acordo',
      formatCell: (r: IRenegotiationResponse) => (r.createdOn ? formatDate(r.createdOn) : '--'),
    },
    {
      label: 'Data do pgmto',
      formatCell: (r: IRenegotiationResponse) => (r.paymentTimestamp ? formatDate(r.paymentTimestamp) : '--'),
    },
    {
      label: 'Status',
      attribute: 'status',
      dropDownValues: [
        { label: 'Todos', value: 'all' },
        { label: 'Em aberto', value: 'DUE' },
        { label: 'Quebrado', value: 'DEFAULTED' },
        { label: 'Pago', value: 'PAID' },
      ],
      formatCell: (r: IRenegotiationResponse) => {
        const status =
          r.status !== 'DUE'
            ? r.status
            : r.payments.filter(p => !p.paymentTimestamp && p.dueDate < moment().format('YYYY-MM-DD')).length > 0
            ? 'OVERDUE'
            : 'DUE'
        switch (status) {
          case 'DUE':
            return (
              <TagStyle
                disable
                backgroundColor={gray.primary}
                label={'Aberto'}
                iconBackground="none"
                iconColor={white.primary}
                onClick={() => this.redirectOnClick(r)}
              ></TagStyle>
            )
          case 'OVERDUE':
            return (
              <TagStyle
                disable
                backgroundColor={red.primary}
                label={'Atrasado'}
                iconBackground="none"
                iconColor={white.primary}
                onClick={() => this.redirectOnClick(r)}
              ></TagStyle>
            )
          case 'PAID':
            return (
              <TagStyle
                disable
                backgroundColor={green.laurel}
                label={'Pago'}
                iconBackground="none"
                iconColor={white.primary}
                onClick={() => this.redirectOnClick(r)}
              />
            )
          case 'DEFAULTED':
            return (
              <TagStyle
                disable
                backgroundColor={yellow.primary}
                label={'Quebrado'}
                iconBackground="none"
                iconColor={white.primary}
                onClick={() => this.redirectOnClick(r)}
              />
            )
        }
      },
    },
  ]

  redirectOnClick = (r: IRenegotiationResponse) => {
    this.props.history.push({ pathname: `/renegotiations/${r.id}`, state: { renegotiation: r } })
  }

  public render() {
    const { renegotiations } = this.props

    const errorCard = (
      <Box margin={{ top: '20px' }}>
        <Text color="red" light>
          Não foi possível carregar os dados para este cliente.
        </Text>
      </Box>
    )

    const RenegotiationsCard = (renegotiations: IRenegotiationResponse[]) => (
      <DataTable header={this.columnsDropDown} data={renegotiations} fontSize="14px" />
    )

    return (
      <CardStyle>
        {!renegotiations ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : renegotiations instanceof Error ? (
          errorCard
        ) : (
          RenegotiationsCard(renegotiations)
        )}
      </CardStyle>
    )
  }
}
