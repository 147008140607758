import { ZipcodeSearchErrors } from './ZipcodeSearchErrors'

const ViaCep = require('node-viacep').default
const viacep = new ViaCep()

export interface ZipCodeAddress {
  cep: string
  logradouro: string
  complemento?: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  gia: string
}

export default class ZipCodeSearch {
  static searchAddressByZipCode = (zipcode: string) => {
    return viacep.address
      .getJson(zipcode)
      .then((data: any) => data.json())
      .then((data: ZipCodeAddress) => {
        if (!data.logradouro && !data.localidade && !data.uf) {
          return ZipcodeSearchErrors.ZIPCODE_NOT_FOUND
        }

        return data
      })
      .catch((error: any) => {
        return ZipcodeSearchErrors.INVALID_ZIPCODE
      })
  }
}
