import { Button, Heading, Tab, Text } from '@bit/ume.design-kit.ui'
import { black, gray, red } from '@bit/ume.design-kit.ui/Palette'
import { Box } from 'grommet'
import styled from 'styled-components'
import { ReactComponent as BarcodeBaseIcon } from '../../common/assets/images/bx-barcode.svg'
import { ReactComponent as CabinetBaseIcon } from '../../common/assets/images/bx-cabinet.svg'
import { screenSize } from '../../config/screens.config'

export const BarcodeIconStyle = styled(BarcodeBaseIcon)`
  height: 30px;
  width: 30px;
  fill: ${red.primary};
`

export const CabinetBaseIconIcon = styled(CabinetBaseIcon)`
  height: 28px;
  width: 28px;
  fill: ${red.primary};
`

export const BorrowerProfileBox = styled(Box)`
  padding: 16px 36px 136px 36px;
  background-color: ${gray.azure};
  text-align: start;
`
export const SectionTitleStyle = styled(Heading)`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin: 0;
`
export const PreviousButtonStyle = styled(Button)`
  padding: 0;
`
export const UnderlinedText = styled(Text)`
  color: ${black.primary};
  text-decoration: underline;
  font-size: 16px;
`

export const TabStyle = styled(Tab)`
  opacity: ${(props: any) => (props.active ? 1 : 0.3)};

  &.installments {
    width: 165px;
  }

  &.renegotiations {
    width: 165px;
  }

  &.contracts {
    width: 220px;
  }

  &.invoices {
    width: 165px;
  }

  @media ${screenSize.tablet} {
    &.installments {
      width: 45%;
    }

    &.contracts {
      width: 45%;
    }
  }

  & button,
  div,
  span {
    text-align: start;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: -0.69px;
    line-height: 25px;
    margin-left: 1px;
    margin-bottom: 1px;
    color: ${black.primary};
  }

  &:nth-child(n) {
    > div {
      border-bottom: solid 2px ${black.amber};
    }
  }
`
