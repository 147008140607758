import { DataTable } from '@bit/ume.design-kit.ui'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Button } from 'antd'
import { Box } from 'grommet'
import React, { useState } from 'react'
import styled from 'styled-components'
import { toaster } from '../../../App'
import { numberToCurrency } from '../../../common/assets/utils/currencyMask'
import { IBatchStatus } from '../../../molecules/OriginationsBatch/BatchAccordion'
import { BigNumberCard } from '../../../molecules/OriginationsBatch/BigNumberCard'
import { getErrorStringForBackofficeOriginationsHttpErrors } from '../../../pages/OriginationsBatchPage/config'
import { bffBackofficeApiService } from '../../../services/bff-backoffice'
import { IBatchResponse } from '../../../services/bff-backoffice/liquidation-originations/interfaces/liquidation-originations.interfaces'
import { IRetailerResponse } from '../../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'
import { findIdInAttributeOfArray } from '../../../utils'

interface ITransitoryToRetailerStepProps {
  batch: IBatchResponse
  status: IBatchStatus
  onStepEnded?: (stepName: string) => void
  retailers: IRetailerResponse[]
}

const RETAILERS_BATCH_HEADER = [
  { label: 'Varejo', attribute: 'retailerName' },
  {
    label: 'Valores',
    attribute: 'principalMinusUmeFee',
    formatCell: (batch: any) => numberToCurrency(batch.principalMinusUmeFee, true),
  },
]

export const TransitoryToRetailerStep = (props: ITransitoryToRetailerStepProps) => {
  const { batch, onStepEnded, status, retailers } = props
  const principalMinusUmeFee = batch.principalSum - batch.umeFeeSum

  const [loadingEndStep, setLoadingEndStep] = useState(false)

  const tableData = batch.retailersBatch.map(retailerBatch => {
    let retailer = findIdInAttributeOfArray(retailerBatch.retailerId, 'id', retailers)
    let retailerName = retailer ? retailer.fantasyName : retailerBatch.retailerId
    let principalMinusUmeFee = retailerBatch.principalSum - retailerBatch.umeFeeSum

    return { retailerName, principalMinusUmeFee }
  })
  // Add UME row at the end
  tableData.push({ retailerName: 'UME', principalMinusUmeFee: batch.umeFeeSum })

  const endStep = () => {
    setLoadingEndStep(true)
    bffBackofficeApiService.liquidationOriginations
      .endStepForBatch(String(batch.id), 'TRANSITORY_TO_RETAILER')
      .then(() => {
        setLoadingEndStep(false)
        if (onStepEnded) {
          onStepEnded('TRANSITORY_TO_RETAILER')
        }
        toaster.showSuccessToast(`Etapa finalizada!`)
      })
      .catch(error => {
        setLoadingEndStep(false)

        const errorString = getErrorStringForBackofficeOriginationsHttpErrors(error)
        toaster.showErrorToast(errorString)
      })
  }

  return (
    <Container>
      {/* FIRST SECTION */}
      <FirstSection>
        <Box direction="row" justify="start">
          <BigNumberCard title="Valor Financeiro" value={principalMinusUmeFee} valueFontSize="32px" isMoney />
          <ResumeInformation>
            <p>Resumo: </p>
            <p>Principal - Taxa UME</p>
            <p>
              R$ {numberToCurrency(batch.principalSum)} - R$ {numberToCurrency(batch.umeFeeSum)}
            </p>
          </ResumeInformation>
        </Box>

        <Box direction="row" justify="start" align="center" pad="medium" gap="medium" wrap>
          <StepDescription>
            Confirme o envio da Conta Transitória para a Conta Final dos Varejistas para concluir essa etapa.
          </StepDescription>
        </Box>
      </FirstSection>

      {/* SECOND SECTION */}
      <SecondSection>
        <TableContainer>
          <p>
            Valores a <strong>transferir</strong> por varejista:
          </p>
          <DataTable header={RETAILERS_BATCH_HEADER} data={tableData} />
        </TableContainer>
      </SecondSection>

      {/* THRID SECTION */}
      <ThirdSection>
        <p>Confirmar as trasnferências irá finalizar o processo.</p>
        <LoadingButton
          disabled={status !== 'PENDING'}
          loading={loadingEndStep}
          type="primary"
          onClick={() => endStep()}
        >
          Confirmar transferência
        </LoadingButton>
      </ThirdSection>
    </Container>
  )
}

const LoadingButton = styled(Button)`
  background-color: ${UMEColors.blue.dark};
  color: white;

  &:hover {
    opacity: 0.5;
  }
`

const TableContainer = styled.div`
  max-width: 270px;
  margin: auto;
`

const Container = styled.div`
  min-height: 300px;

  width: 100%;
  border-radius: 8px;
  background-color: ${UMEColors.white.primary};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);

  padding: 1vh 1vw 1vh 1vw;

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
`

const FirstSection = styled.div`
  flex: 1;
  margin: 10px;
`
const SecondSection = styled.div`
  flex: 1;
  margin: 10px;
  border-right: 1px solid rgba(58, 58, 58, 0.3);
  border-left: 1px solid rgba(58, 58, 58, 0.3);
`
const ThirdSection = styled.div`
  flex: 1;
  margin: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > p {
    color: ${UMEColors.black.primary};
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }
`

// FIRST SECTION
const ResumeInformation = styled.div`
  margin: 1vh 0 1vh 2vw;

  & > p:nth-child(1) {
    // TODO - Not in design kit
    color: #030202;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
  }
  & > p:nth-child(2) {
    margin-top: 20px;
    color: ${UMEColors.gray.primary};
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
  }
  & > p:nth-child(3) {
    color: ${UMEColors.gray.primary};
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
  }
`

const StepDescription = styled.p`
  // TODO - Not in design kit
  color: #030202;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;

  margin: 0;
  text-align: left;
  text-wrap: wrap;
`
