import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Box, Text } from 'grommet'
import { CircleAlert, StatusGood } from 'grommet-icons'
import React, { useState } from 'react'
import styled from 'styled-components'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import ApproveApplicationModal from '../../molecules/Applications/ApproveApplicationModal'
import DenialReasonsModal from '../../molecules/Applications/DenialReasonsModal'
import { LoadingButton } from '../../molecules/LoadingButton/LoadingButton'
import { useTypedSelector } from '../../redux/reducers/selectors'
import {
  ApplicationStatus,
  ApplicationType,
} from '../../services/bff-backoffice/application-engine/interfaces/application-engine.interfaces'
import { ProcessStatus } from '../../services/bff-backoffice/datalake-acesso/interfaces/datalake-acesso.interfaces'

const EvaluationButtons = () => {
  const { application, biometryStatus, isLoadingBiometryStatus, fetchBiometryStatusError } = useTypedSelector(
    state => ({
      application: state.applications.application,
      biometryStatus: state.applications.biometryStatus,
      isLoadingBiometryStatus: state.applications.isLoadingBiometryStatus,
      fetchBiometryStatusError: state.applications.fetchBiometryStatusError,
    })
  )

  const [isDenyModalOpen, setIsDenyModalOpen] = useState<boolean>(false)
  const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false)

  if (!application) return <></>

  if (application.status !== ApplicationStatus.WAITING_MANUAL_APPROVAL) {
    return (
      <WarningText>
        * Applications com status <b>{application.status}</b> não podem ser avaliados.
      </WarningText>
    )
  }

  if (application.applicationType !== ApplicationType.APPLICATION) {
    return <WarningText>* Não é possível avaliar reavaliações.</WarningText>
  }

  if (isLoadingBiometryStatus) {
    return <LoadingIcon />
  }

  if (fetchBiometryStatusError || !biometryStatus) {
    return <WarningText>* Erro ao carregar status da biometria. Por favor, atualize a página.</WarningText>
  }

  if (biometryStatus?.status !== ProcessStatus.COMPLETED) {
    return (
      <WarningText>* A biometria deve estar com status "Completa" para que o application seja avaliado.</WarningText>
    )
  }

  return (
    <React.Fragment>
      <ApproveApplicationModal isModalOpen={isApproveModalOpen} onClose={() => setIsApproveModalOpen(false)} />
      <DenialReasonsModal isModalOpen={isDenyModalOpen} onClose={() => setIsDenyModalOpen(false)} />
      <ButtonsContainer>
        <DenyButton
          onClick={() => setIsDenyModalOpen(true)}
          label="Negar"
          icon={<CircleAlert color={UMEColors.white.primary} width="12px" />}
        />
        <ApproveButton
          onClick={() => setIsApproveModalOpen(true)}
          label="Aprovar"
          icon={<StatusGood color={UMEColors.white.primary} width="12px" />}
        />
      </ButtonsContainer>
    </React.Fragment>
  )
}

export default EvaluationButtons

const ButtonsContainer = styled(Box)`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`

const WarningText = styled(Text)`
  text-align: start;
  margin: 20px;
  font-style: italic;
  color: ${UMEColors.red.persian};
`

const DenyButton = styled(LoadingButton)`
  border-radius: 6px;
  padding: 10px;
  width: 150px;
  background-color: ${UMEColors.red.persian};
  border: none;
  box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4) !important;
  margin: 0 1vw;

  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  transition: 0.4s;

  &:hover {
    transition: 250ms;
    opacity: 0.8;
    cursor: pointer;
  }
`

const ApproveButton = styled(LoadingButton)`
  border-radius: 6px;
  padding: 10px;
  width: 150px;
  background-color: ${UMEColors.green.laurel};
  border: none;
  box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4) !important;
  margin: 0 1vw;

  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  transition: 0.4s;

  &:hover {
    transition: 250ms;
    opacity: 0.8;
    cursor: pointer;
  }
`
