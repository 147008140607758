import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import {
  IApplications,
  IApplicationsByStatusByStoresRecent,
  IApplicationsStatus,
  IGetApplications,
  IGetApplicationsRecent,
  IGetByDateInterval,
  IGetOriginationsAnalysis,
  IGetOriginationsSummary,
  IInstallmentsForCollections,
  IInstallmentsForOperations,
  IOperators,
  IOriginations,
  IOriginationSummary,
  IOriginationsViewOperations,
  IOverdueInstallments,
  IPayments,
  IStores,
  PayAnywhereAccessControlResponse,
} from './interfaces/backoffice.interfaces'

export default class BackofficeApiService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async isBorrowerEnabledToPayAnywhere(borrowerId: string): Promise<boolean> {
    const response = await this.axiosInstance.get<PayAnywhereAccessControlResponse>(
      `/backoffice/pay-anywhere/access-control/${borrowerId}`
    )
    return response.data.accessControl
  }

  async getOriginationsSummary(params: IGetOriginationsSummary): Promise<IOriginationSummary> {
    const response = await this.axiosInstance.get<IOriginationSummary>(`/backoffice/originations/summary`, { params })
    return response.data
  }

  async getOriginationsAnalysis(params: IGetOriginationsSummary): Promise<IGetOriginationsAnalysis> {
    const response = await this.axiosInstance.get<IGetOriginationsAnalysis>(`/backoffice/originations/analysis`, {
      params,
    })
    return response.data
  }

  async getRecentApplicationsByStatus(params: IGetApplicationsRecent): Promise<IApplicationsByStatusByStoresRecent[]> {
    const response = await this.axiosInstance.get<IApplicationsByStatusByStoresRecent[]>(
      `/backoffice/applications/status/stores/recent`,
      {
        params,
      }
    )
    return response.data
  }

  async getApplicationsByStatusByStores(params: IGetApplications): Promise<IApplicationsByStatusByStoresRecent[]> {
    const response = await this.axiosInstance.get<IApplicationsByStatusByStoresRecent[]>(
      `/backoffice/applications/status/stores`,
      {
        params,
      }
    )
    return response.data
  }

  async getOverdueInstallments(params: IInstallmentsForCollections): Promise<IOverdueInstallments[]> {
    const response = await this.axiosInstance.get<IOverdueInstallments[]>(`/backoffice/installments/collections`, {
      params,
    })
    return response.data
  }

  async getPayments(params: IGetByDateInterval): Promise<IPayments[]> {
    const response = await this.axiosInstance.get<IPayments[]>(`/backoffice/payments`, {
      params,
    })
    return response.data
  }

  async getOriginationsViewOperations(params: IGetByDateInterval): Promise<IOriginations[]> {
    const response = await this.axiosInstance.get<IOriginations[]>(`/backoffice/originations`, {
      params,
    })
    return response.data
  }

  async getOriginationsViewForOperations(params: IGetOriginationsSummary): Promise<IOriginationsViewOperations[]> {
    const response = await this.axiosInstance.get<IOriginationsViewOperations[]>(
      `/backoffice/originations/operations`,
      {
        params,
      }
    )
    return response.data
  }

  async getApplications(params: IGetApplications): Promise<IApplications[]> {
    const response = await this.axiosInstance.get<IApplications[]>(`/backoffice/applications`, {
      params,
    })
    return response.data
  }

  async getOperators(params: IGetOriginationsSummary): Promise<IOperators[]> {
    const response = await this.axiosInstance.get<IOperators[]>(`/backoffice/operators`, {
      params,
    })
    return response.data
  }

  async getStores(params: IGetOriginationsSummary): Promise<IStores[]> {
    const response = await this.axiosInstance.get<IStores[]>(`/backoffice/stores`, {
      params,
    })
    return response.data
  }

  async getInstallments(): Promise<IInstallmentsForOperations[]> {
    const response = await this.axiosInstance.get<IInstallmentsForOperations[]>(`/backoffice/installments/operations`)
    return response.data
  }

  async getApplicationStatus(params: IGetApplications): Promise<IApplicationsStatus> {
    const response = await this.axiosInstance.get<IApplicationsStatus>(`/backoffice/applications/status`, {
      params,
    })
    return response.data
  }
}
