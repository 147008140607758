const sizes = {
  micro: '1px',
  xsmall: '2px',
  small: '4px',
  medium: '8px',
  large: '16px',
  xlarge: '32px',
  mega: '64px',
  tera: '128px',
}

export const { micro, xsmall, small, medium, large, xlarge, mega, tera } = sizes
