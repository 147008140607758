import { Card } from '@bit/ume.design-kit.ui'
import { Box } from 'grommet'
import styled from 'styled-components'
import { mobile } from '../../../../common/assets/_breakpoints'

export const LoginBox = styled(Box)`
  margin-top: 130px;
`
export const LoginCard = styled(Card)`
  @media (max-width: ${mobile}) {
    border: none;
  }
`
