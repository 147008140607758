import React, { Component } from 'react'
import imgHero from '../../../common/assets/images/security-concept-illustration.jpg'
import { gray, green, red } from '../../../common/assets/_colors'
import { bffBackofficeApiService } from '../../../services/bff-backoffice'
import {
  Button,
  ImgHero,
  InputPassword,
  ListItem,
  ParagraphTitle,
  PasswordCard,
  PasswordComponent,
  PasswordContainer,
  TitleContainer,
  UnorderedList,
} from './style'

export interface IPasswordProps {
  history?: any
}
export interface IPasswordState {
  password: string
  passwordConfirm: string
  passwordLengthColor: string
  passwordCapitalLetterColor: string
  passwordLowerLetterColor: string
  passwordNumberColor: string
  passwordSpecialCaracterColor: string
  passwordRepeatCorrectlyColor: string
  isPasswordAllowedtoChange: boolean
}

export default class PasswordFirstAccess extends Component<IPasswordProps, IPasswordState> {
  constructor(props: IPasswordProps) {
    super(props)
    this.state = {
      password: '',
      passwordConfirm: '',
      passwordLengthColor: gray.primary,
      passwordCapitalLetterColor: gray.primary,
      passwordLowerLetterColor: gray.primary,
      passwordNumberColor: gray.primary,
      passwordSpecialCaracterColor: gray.primary,
      passwordRepeatCorrectlyColor: gray.primary,
      isPasswordAllowedtoChange: false,
    }
  }

  handlePassword = (event: any) => {
    event.preventDefault()
    const regexCapitalLetter = /(?:[A-Z])/g
    const regexLowerLetter = /(?:[a-z])/g
    const regexNumber = /(?:[0-9])/g
    const regexSpecialCaracter = /[!@#$%^&*(),.?":{}|<>]/g

    this.setState({ password: event.target.value })

    if (event.target.value.length > 5) {
      this.setState({ passwordLengthColor: green.dark })
    } else {
      this.setState({ passwordLengthColor: red.primary })
    }

    if (regexCapitalLetter.test(event.target.value)) {
      this.setState({ passwordCapitalLetterColor: green.dark })
    } else {
      this.setState({ passwordCapitalLetterColor: red.primary })
    }

    if (regexLowerLetter.test(event.target.value)) {
      this.setState({ passwordLowerLetterColor: green.dark })
    } else {
      this.setState({ passwordLowerLetterColor: red.primary })
    }

    if (regexNumber.test(event.target.value)) {
      this.setState({ passwordNumberColor: green.dark })
    } else {
      this.setState({ passwordNumberColor: red.primary })
    }

    if (regexSpecialCaracter.test(event.target.value)) {
      this.setState({ passwordSpecialCaracterColor: green.dark })
    } else {
      this.setState({ passwordSpecialCaracterColor: red.primary })
    }
  }

  handleRepeatPassword = (event: any) => {
    event.preventDefault()
    if (event.target.value === this.state.password) {
      this.setState({
        passwordConfirm: event.target.value,
        isPasswordAllowedtoChange: true,
        passwordRepeatCorrectlyColor: green.dark,
      })
    } else {
      this.setState({
        passwordConfirm: event.target.value,
        isPasswordAllowedtoChange: false,
        passwordRepeatCorrectlyColor: red.primary,
      })
    }
  }

  handleSubmit = () => {
    const { password, passwordConfirm } = this.state
    const user = bffBackofficeApiService.auth.getUser()

    if (user !== undefined && password === passwordConfirm) {
      alert(
        'Tente novamente, se persistir o problema fale com um de nossos analistas via Whatsapp clicando no ícone abaixo :)'
      )
        
    } else {
      alert(
        'Tente novamente, se persistir o problema fale com um de nossos analistas via Whatsapp clicando no ícone abaixo :)'
      )
    }
  }

  render() {
    const {
      passwordLengthColor,
      passwordCapitalLetterColor,
      passwordLowerLetterColor,
      passwordNumberColor,
      passwordSpecialCaracterColor,
      passwordRepeatCorrectlyColor,
      isPasswordAllowedtoChange,
    } = this.state

    const newUser = bffBackofficeApiService.auth.getUser()
    if (newUser && !newUser.passwordReset) {
      this.props.history.push('/compras')
    }

    return (
      <PasswordContainer>
        <ImgHero src={imgHero} alt={'security-banner-illustration'} loading="lazy" />
        <PasswordCard>
          <TitleContainer>
            <h3>Precisando de uma senha nova?</h3>
            <ParagraphTitle>Para sua segurança, sua senha deve conter no mínimo 6 digitos</ParagraphTitle>
          </TitleContainer>
          <PasswordComponent>
            <InputPassword onChange={this.handlePassword} placeholder="Nova Senha" type="password" />
            <InputPassword onChange={this.handleRepeatPassword} placeholder="Repita a Senha" type="password" />

            {isPasswordAllowedtoChange && <Button onClick={this.handleSubmit}>Confirmar</Button>}

            <UnorderedList>
              <ListItem className={'lengthColor'} passwordLengthColor={passwordLengthColor}>
                6 digitos
              </ListItem>
              <ListItem className={'capitalLetterColor'} passwordCapitalLetterColor={passwordCapitalLetterColor}>
                Pelo menos uma letra maiuscula
              </ListItem>
              <ListItem className={'lowerCaseColor'} passwordLowerLetterColor={passwordLowerLetterColor}>
                Pelo menos uma letra minuscula
              </ListItem>
              <ListItem className={'numberColor'} passwordNumberColor={passwordNumberColor}>
                Pelo menos um número
              </ListItem>
              <ListItem className={'specialCaracterColor'} passwordSpecialCaracterColor={passwordSpecialCaracterColor}>
                Pelo menos um caracter especial (!@#$%)
              </ListItem>
              <ListItem className={'repeatPasswordColor'} passwordRepeatCorrectlyColor={passwordRepeatCorrectlyColor}>
                Repetiu a senha corretamente
              </ListItem>
            </UnorderedList>
          </PasswordComponent>
        </PasswordCard>
      </PasswordContainer>
    )
  }
}
