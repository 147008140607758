import styled from 'styled-components'
import { black, gray, white } from '@bit/ume.design-kit.ui/utils/_colors'
import { Card } from '@bit/ume.design-kit.ui'
import { FormClose } from 'grommet-icons'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Box, Layer, MaskedInput, Select } from 'grommet'

export const BreadcrumbsBox = styled.div`
  display: block;
  font-size: 10px;
  text-align: left;
` 
  
export const BreadcrumbsItem = styled.span`
  margin: 0 6px;  
  color: ${props => props.active ? black.primary : gray.nobel};
`

export const DataBoxStyle = styled(Box)`
  margin: 2px 8px 12px 8px;
`

export const CardStyle = styled(Card)`
  border-radius: 8px;
`

export const ModalLayer = styled(Layer)`
  border-radius: 10px;
  background-color: ${white};
`
export const ModalContainer = styled(Box)`
  color: ${black.primary};
  padding: 20px;
  font-family: Roboto;
`
export const ModalHeader = styled(Box)`
  border-radius: 10px;
`
export const Title = styled.h1`
  border-radius: 10px;
  background-color: ${white};
  margin: 5px 0 0 0;
  font-size: 28px;
`
export const CloseButton = styled.button`
  border: none;
  float: right;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  background: white;
  align-items: center;
`

export const CloseIcon = styled(FormClose)`
  height: 1em;
  width: 1em;
  border: 2px solid ${gray.eclipse};
  border-radius: 100%;
  stroke: ${black.primary};
`
export const CloseLabel = styled.span`
  font-weight: bold;
  font-family: Roboto;
  background: ${white};
  color: ${gray.eclipse};
  font-size: 14px;
  margin-right: 15px;
  height: 100%;
  font-family: Roboto;
`

export const StyledMaskedInput = styled(MaskedInput)`
  width: ${props => (props.width ? props.width : '280px')};
  height: ${props => (props.height ? props.height : '58px')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '4px')};
  border: ${props =>
    props.border ? props.border : `solid 1px ${props.errorIntent ? `${UMEColors.red.persian}` : `${UMEColors.gray.gainsboro}`}`};
  box-sizing: border-box;
  // margin-top: 4px;

  // text-style
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-size: 16px;
  letter-spacing: 0;
  text-indent: 8px;
  line-height: 19px;
  color: ${UMEColors.gray.eclipse};

  &:nth-child(n) {
    box-shadow: none;
  }

  ::placeholder {
    color: ${UMEColors.gray.aluminium};
    font-size: 18px;
    line-height: 21px;
    text-indent: 10px;
    transition: all 0.3s;
  }
  :focus {
    border: ${props =>
      props.border ? props.border : `2px solid ${props.errorIntent ? `${UMEColors.red.persian}` : `${UMEColors.blue.navy}`}`};
  }
  :focus::placeholder {
    color: transparent;
    transition: all 0.3s;
  }
`

export const StyledDropDownInput = styled(Select)`
  width: ${props => (props.width ? props.width : '280px')};
  height: ${props => (props.height ? props.height : '58px')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '4px')};
  border: ${props =>
    props.border ? props.border : `solid 1px ${props.errorIntent ? `${UMEColors.red.persian}` : `${UMEColors.gray.gainsboro}`}`};
  box-sizing: border-box;
  // margin-top: 4px;

  // text-style
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-size: 16px;
  letter-spacing: 0;
  text-indent: 8px;
  line-height: 19px;
  color: ${UMEColors.gray.eclipse};

  &:nth-child(n) {
    box-shadow: none;
  }

  :focus {
    border: ${props =>
      props.border ? props.border : `2px solid ${props.errorIntent ? `${UMEColors.red.persian}` : `${UMEColors.blue.navy}`}`};
  }
  :focus::placeholder {
    color: transparent;
    transition: all 0.3s;
  }
`

export const StyledLabel = styled.label`
  background-color: ${props => props.backgroundColor ? props.backgroundColor : UMEColors.white.primary};

  margin-left: 16px;
  padding: 0px 8px 0px 8px;
  position: absolute;
  transform: translateY(-6px);

  // text-style
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;

  &:after {
    content: ${props => (props.required ? `" *"` : ``)};
    color: red;
    display: inline;
  }
`

export const StyledBoxInput = styled(Box)`
  font-family: Roboto;
  margin: 4px 8px;
  position: relative;

  ${StyledMaskedInput}:placeholder-shown {
    & + ${StyledLabel} {
      display: none;
    }
  }

  ${StyledLabel} {
    color: ${props => (props.errorIntent ? `${UMEColors.red.persian}` : `${UMEColors.gray.aluminium}`)};
  }

  &:focus-within {
    > ${StyledMaskedInput} {
      border: ${props =>
        props.border ? props.border : `2px solid ${props.errorIntent ? `${UMEColors.red.persian}` : `${UMEColors.blue.navy}`}`};
    }
    > ${StyledLabel} {
      color: ${props => (props.errorIntent ? `${UMEColors.red.persian}` : `${UMEColors.blue.navy}`)};
      transition: all 0.3s;
      transform: translateY(-6px);
      visibility: visible;
    }
  }
`