import React, { Component, CSSProperties } from 'react'
import { StyledBoxInput, StyledLabel, StyledDropDownInput } from './styles'
import { SelectProps as GrommetDropDownInputProps } from 'grommet'

interface DropDownInputProps {
  label?: string
  errorIntent?: boolean
  containerStyle?: CSSProperties;
}

export default class DropDownInput extends Component<
  DropDownInputProps & GrommetDropDownInputProps & Omit<JSX.IntrinsicElements['input'], 'onSelect' | 'size'>
> {
  render() {
    return (
      <StyledBoxInput errorIntent={this.props.errorIntent} style={this.props.containerStyle}>
        <StyledDropDownInput {...this.props}>{this.props.children}</StyledDropDownInput>
        {this.props.label && <StyledLabel required={this.props.required}>{this.props.label}</StyledLabel>}
      </StyledBoxInput>
    )
  }
}
