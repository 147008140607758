import * as React from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import { bffBackofficeApiService } from '../services/bff-backoffice'

export enum Role {
  REPRESENTATIVE = 'representative',
  CASHIER = 'cashier',
  MANAGER = 'manager',
  BORROWER = 'BORROWER',
  ANALYST = 'analyst',
  RISK_ANALYST = 'risk-analyst',
  EXTERNAL_COLLECTOR = 'external-collector',
}

export const ALL_ROLES = Object.values(Role)

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  authorizedRoles: Role[]
}

type RenderComponent = (props: RouteComponentProps<any>) => React.ReactNode

export class PrivateRoute extends Route<PrivateRouteProps> {
  public render() {
    const { component: Component, authorizedRoles, ...rest }: PrivateRouteProps = this.props

    const renderComponent: RenderComponent = props => {
      const logged = bffBackofficeApiService.auth.isLogged()
      const authorized = bffBackofficeApiService.auth.isAuthorized(authorizedRoles)

      if (logged && authorized) {
        return <Component {...props} />
      } else if (logged && !authorized) {
        return <Redirect to={{ pathname: '/' }} />
      } else {
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      }
    }

    return <Route {...rest} render={renderComponent} />
  }
}
