import React from 'react'
import { FormSearch, Home } from 'grommet-icons'
import { Dropdown, Heading, Paragraph } from '@bit/ume.design-kit.ui'
import { rose, gray } from '@bit/ume.design-kit.ui/utils/_colors'
import { Component, Container } from './styles'

export interface IRetailerPickerProps {
  onPick?: (options: any) => void
  retailers: any[] | any
  retailersOptions?: any[] | any
  value?: string | undefined
}

const RetailerPicker = (props: IRetailerPickerProps) => {
  return(
    <Container>
      <Component>
        <Home color={rose.primary} />
        <Heading level={3} size="small" margin="0 8px">
          Escolha o Varejo
        </Heading>
      </Component>
      <Paragraph style={{ fontSize: 12 }} size="small" margin="12px 8px" color={gray.dim}>
        Selecione uma loja específica para fazer o filtro
      </Paragraph>
      <Dropdown
        id="select-retailer"
        name="select-retailer"
        placeholder="Selecione o Parceiro"
        open={false}
        value={props.value}
        options={props.retailersOptions ? props.retailersOptions : ['Selecione']}
        icon={<FormSearch />}
        onChange={props.onPick}
        style={{ width: 220 }}
      />
    </Container>
  )
}

export default RetailerPicker