import { Button, Text } from '@bit/ume.design-kit.ui'
import { gray } from '@bit/ume.design-kit.ui/utils/_colors'
import { Box } from 'grommet'
import * as React from 'react'
import ErrorString from '../../../../atoms/ErrorString/ErrorString'
import umeLogo from '../../../../common/assets/images/ume-logo.jpeg'
import { ButtonBox, LoginHeading, LogoImg } from './style'

export interface IKeycloackLoginProps {
  handleLoginButtonPress: () => Promise<any>
  error?: string
}

export default class KeycloackLogin extends React.Component<IKeycloackLoginProps> {
  constructor(props: IKeycloackLoginProps) {
    super(props)
  }

  public render() {
    return (
      <Box margin="medium">
        <Box align="center" justify="center">
          <LogoImg src={umeLogo} />

          <Box justify="center" align="center" margin="medium">
            <LoginHeading size="small" level="3">
              Login
            </LoginHeading>
            <Text color={gray.aluminium} size="small">
              Ir para plataforma Ume
            </Text>
          </Box>
        </Box>

        <Box>{this.props.error && <ErrorString text={this.props.error} />}</Box>

        <ButtonBox>
          <Button
            style={{ width: '100%' }}
            onClick={() => {
              this.props.handleLoginButtonPress()
            }}
          >
            Login via Google
          </Button>
        </ButtonBox>
      </Box>
    )
  }
}
