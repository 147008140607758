import React, { Component } from 'react'
import NotFoundHero from '../../common/assets/images/404-hero.png'
import { Container, InfoComponent, ImgHero } from './style'

export interface INotFoundProps {
  history?: any
}

export interface INotFoundState {
}

export default class NotFound extends Component<INotFoundProps, INotFoundState> {
  handleHomePageRedirect = () => {
    this.props.history.push('/')
  }

  render() {
    return (
        <Container>
          <InfoComponent>
            <h1>Ooops!</h1>
            <h3>Página não encontrada...</h3>
            <button onClick={this.handleHomePageRedirect}>Página inicial</button>
          </InfoComponent>
          <ImgHero src={NotFoundHero} />
        </Container>
    )
  }
}
