import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Checkbox } from 'antd'
import { Box, Text } from 'grommet'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { denialReasonsText } from '../../common/assets/utils/denial-reasons'
import Modal from '../../molecules/RetailersPage/Modal'
import { ApplicationsSliceReducer } from '../../redux/reducers/applications/applications.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import {
  ApplicationStatus,
  DenialReasons,
} from '../../services/bff-backoffice/application-engine/interfaces/application-engine.interfaces'
import { LoadingButton } from '../LoadingButton/LoadingButton'

interface Props {
  isModalOpen: boolean
  onClose: () => void
}

const DenialReasonsModal = ({ isModalOpen, onClose }: Props) => {
  const dispatch = useDispatch()

  const { application, isLoadingButton } = useTypedSelector(state => ({
    application: state.applications.application,
    isLoadingButton: state.applications.isLoadingApplicationOnlineEvaluation,
  }))

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const [denialReasons, setDenialReasons] = useState<DenialReasons[]>([])

  if (!application) return <></>

  const handleConfirmButton = () => {
    dispatch(
      ApplicationsSliceReducer.actions.evaluateApplicationOnline({
        applicationId: application.id,
        body: {
          evaluation: ApplicationStatus.DENIED,
          denialReasons,
        },
      })
    )
  }

  const handleCloseModal = () => {
    setDenialReasons([])
    setIsButtonDisabled(true)
    onClose()
  }

  const handleCheckItem = (checkedValues: DenialReasons[]) => {
    setDenialReasons(checkedValues)
    if (checkedValues.length === 0) {
      setIsButtonDisabled(true)
    } else if (isButtonDisabled) {
      setIsButtonDisabled(false)
    }
  }

  const options = [
    { label: denialReasonsText[DenialReasons.ILLEGIBLE_DOCUMENT], value: DenialReasons.ILLEGIBLE_DOCUMENT },
    { label: denialReasonsText[DenialReasons.INVALID_FACE_MATCHING], value: DenialReasons.INVALID_FACE_MATCHING },
    { label: denialReasonsText[DenialReasons.SPOOF_FACE_IMAGE], value: DenialReasons.SPOOF_FACE_IMAGE },
    { label: denialReasonsText[DenialReasons.INVALID_OCR], value: DenialReasons.INVALID_OCR },
    { label: denialReasonsText[DenialReasons.IVALID_DOCUMENT], value: DenialReasons.IVALID_DOCUMENT },
    { label: denialReasonsText[DenialReasons.SPOOF_DOCUMENT_IMAGE], value: DenialReasons.SPOOF_DOCUMENT_IMAGE },
    { label: denialReasonsText[DenialReasons.TAMPERED_DOCUMENT], value: DenialReasons.TAMPERED_DOCUMENT },
    { label: denialReasonsText[DenialReasons.NOT_IN_AMAZON], value: DenialReasons.NOT_IN_AMAZON },
    { label: denialReasonsText[DenialReasons.UNABLE_TO_VALIDATE_PHONE], value: DenialReasons.UNABLE_TO_VALIDATE_PHONE },
    {
      label: denialReasonsText[DenialReasons.FAILED_OPERATOR_VALIDATION],
      value: DenialReasons.FAILED_OPERATOR_VALIDATION,
    },
  ]

  return (
    <Modal modalTtitle="Confirmar Negação" isOpen={isModalOpen} onClose={handleCloseModal} width={'500px'}>
      <Box overflow="auto" pad={{ top: '20px' }} margin={{ top: '10px', bottom: '20px' }}>
        <Text size="18px" color={UMEColors.black.light}>
          Informe um ou mais motivos para a negação:
        </Text>
        <Box margin={{ horizontal: '20px', top: '10px' }} direction="column" gap="medium">
          <ColumnCheckbox onChange={handleCheckItem} options={options} />
          <Box gap="small" direction="row" justify="center" align="center">
            <CancelButton onClick={handleCloseModal} label={'Cancelar'} />
            <ConfirmButton
              onClick={handleConfirmButton}
              label={isLoadingButton ? <SmallLoadingIcon /> : 'Negar Cliente'}
              disabled={isButtonDisabled || isLoadingButton}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default DenialReasonsModal

const ColumnCheckbox = styled(Checkbox.Group)`
  .ant-checkbox-group-item {
    margin: 5px 0;
    font-size: 16px;
    color: ${UMEColors.black.light};
  }
`

const SmallLoadingIcon = styled(LoadingIcon)`
  height: 14px;
  width: 14px;
`
const CancelButton = styled(LoadingButton)`
  border-radius: 6px;
  padding: 10px;
  width: 45%;
  background-color: ${UMEColors.red.persian};
  border: solid 1px ${UMEColors.white.primary};
  box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4) !important;
  height: 50px;

  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  transition: 0.4s;

  &:hover {
    transition: 250ms;
    opacity: 0.8;
    cursor: pointer;
  }

  ${(props: any) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.2;
    `}
`
const ConfirmButton = styled(LoadingButton)`
  border-radius: 6px;
  padding: 10px;
  width: 45%;
  background-color: ${UMEColors.white.primary};
  border: solid 1px ${UMEColors.red.persian};
  box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4) !important;
  height: 50px;

  color: ${UMEColors.red.persian};
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  transition: 0.4s;

  &:hover {
    transition: 250ms;
    cursor: pointer;
    background-color: ${UMEColors.red.persian};
    color: ${UMEColors.white.primary};
  }

  ${(props: any) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.2;
    `}
`
