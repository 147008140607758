import { Breadcrumbs, Button, Heading, LoadingScreen, RichTabTitle, Tabs } from '@bit/ume.design-kit.ui'
import { Modal } from 'antd'
import { Box } from 'grommet'
import { FormPreviousLink } from 'grommet-icons'
import * as React from 'react'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { IRenegotiationResponse } from '../../services/bff-backoffice/coordinator/interfaces/renegotiation.interfaces'
import { formatNumberToBRL } from '../../utils'
import { resolveRenegotiationReceiptRoute } from '../Receipts/Utils'
import RenegotiationDetails from './RenegotiationDetails'
import RenegotiationPayments from './RenegotiationPayments'
import RenegotiationStatus from './RenegotiationStatus'
import { BarcodeIconStyle, PreviousButtonStyle, RenegotiationDetailsBox, SectionTitleStyle, TabStyle } from './style'

export interface IRenegotiationDetailsPageProps {
  history?: any
  match?: any
  location?: any
}

export interface IRenegotiationDetailsPageState {
  renegotiationId?: string
  renegotiation?: IRenegotiationResponse | Error
  showConfirmationDialog: boolean
  defaultingRenegotiation: boolean
}

const initialState: IRenegotiationDetailsPageState = {
  showConfirmationDialog: false,
  defaultingRenegotiation: false,
}

export default class RenegotiationDetailsPage extends React.Component<
  IRenegotiationDetailsPageProps,
  IRenegotiationDetailsPageState
> {
  constructor(props: IRenegotiationDetailsPageProps) {
    super(props)

    this.state = initialState
  }

  componentDidMount() {
    const { renegotiationId } = this.props.match.params
    document.body.scrollTop = 0

    this.setState({ renegotiationId })
    this.loadRenegotiation(renegotiationId)
  }

  loadRenegotiation = (renegotiationId: string) => {
    bffBackofficeApiService.coordinator
      .getRenegotiationById(renegotiationId)
      .then(data => {
        this.setState({
          renegotiation: { ...data, payments: data.payments.sort((p1, p2) => p1.dueDate.localeCompare(p2.dueDate)) },
        })
      })
      .catch(() => {
        this.setState({ renegotiation: new Error() })
        return this.props.history.push('/*')
      })
  }

  askForConfirmation = () => {
    this.setState({
      showConfirmationDialog: true,
    })
  }

  onConfirm = async () => {
    const { renegotiation } = this.state
    if (renegotiation && !(renegotiation instanceof Error) && renegotiation.status === 'DUE' && renegotiation.id) {
      await this.defaultRenegotiation()
    }
  }

  onCancel = () => {
    this.setState({
      showConfirmationDialog: false,
      defaultingRenegotiation: false,
    })
  }

  defaultRenegotiation = async () => {
    if (!this.state.renegotiation || this.state.renegotiation instanceof Error || !this.state.renegotiation.id) {
      return
    }

    this.setState({
      defaultingRenegotiation: true,
    })

    await bffBackofficeApiService.coordinator.defaultRenegotiation(this.state.renegotiation.id)

    this.setState({
      defaultingRenegotiation: false,
    })

    this.props.history.push(`/borrowers/${this.state.renegotiation.borrowerId}/info`)
  }

  onPreviousLinkClick = () => {
    this.props.history.go(-1)
  }

  public render() {
    const { renegotiation, renegotiationId, showConfirmationDialog, defaultingRenegotiation } = this.state

    if (!renegotiation) {
      return <LoadingScreen />
    }

    return (
      <RenegotiationDetailsBox fill>
        <Box margin={{ horizontal: 'medium', top: 'small' }}>
          <Breadcrumbs anchors={['Home', 'Priorização', 'Acordos', 'ID:' + renegotiationId]} />
        </Box>

        <Box margin={{ horizontal: 'small', vertical: 'medium' }} direction="row" align="center" gap="xsmall">
          <PreviousButtonStyle
            onClick={this.onPreviousLinkClick}
            width="32px"
            height="32px"
            icon={<FormPreviousLink color="white" />}
          />
          <Heading size="12px">Voltar</Heading>
        </Box>

        <Box gap="medium">
          <Box gap="xsmall">
            <Box margin={{ horizontal: 'medium' }} direction="row" align="center" gap="medium">
              <SectionTitleStyle>Detalhes do acordo</SectionTitleStyle>
              <RenegotiationStatus renegotiation={renegotiation} />
              {!(renegotiation instanceof Error) && renegotiation.status === 'DUE' && (
                <Button
                  backgroundColor={'red'}
                  style={{ margin: '10 25px 0 0', width: '150px' }}
                  onClick={() => this.askForConfirmation()}
                >
                  CANCELAR ACORDO
                </Button>
              )}
              {!(renegotiation instanceof Error) && renegotiation.id && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={resolveRenegotiationReceiptRoute(renegotiation.id)}
                >
                  COMPROVANTE
                </a>
              )}
            </Box>
            <RenegotiationDetails renegotiation={renegotiation} />
          </Box>
        </Box>

        {!(renegotiation instanceof Error) && renegotiation.payments && (
          <Box overflow="auto" gap="medium">
            <Tabs justify="start">
              <TabStyle
                margin={{ left: 'medium' }}
                className="payments"
                title={<RichTabTitle icon={<BarcodeIconStyle />} label={'Parcelas'} />}
              >
                <RenegotiationPayments
                  history={this.props.history}
                  payments={renegotiation.payments}
                  renegotiationId={renegotiation.id!}
                />
              </TabStyle>
            </Tabs>
          </Box>
        )}

        {!(renegotiation instanceof Error) && renegotiation.payments && (
          <Modal
            title="VOCÊ TEM CERTEZA?"
            width="80vw"
            visible={showConfirmationDialog}
            onOk={this.onConfirm}
            confirmLoading={defaultingRenegotiation}
            onCancel={this.onCancel}
          >
            <h2>DESEJA CANCELAR O ACORDO {renegotiation.id} ?</h2>
            <h3>
              O acordo possui{' '}
              {formatNumberToBRL(
                renegotiation.payments
                  .filter(p => p.paymentTimestamp)
                  .map(p => (p.paidValue ? p.paidValue : 0))
                  .reduce((p1, p2) => p1 + p2, 0)
              )}{' '}
              em {renegotiation.payments.filter(p => p.paymentTimestamp).length} parcelas pagas e{' '}
              {formatNumberToBRL(
                renegotiation.payments
                  .filter(p => !p.paymentTimestamp)
                  .map(p => (p.paymentDue ? p.paymentDue : 0))
                  .reduce((p1, p2) => p1 + p2, 0)
              )}{' '}
              (valor hoje) em {renegotiation.payments.filter(p => !p.paymentTimestamp).length} parcelas pendentes.
            </h3>
          </Modal>
        )}
      </RenegotiationDetailsBox>
    )
  }
}
