import styled from 'styled-components'
import { Box } from 'grommet'
import { gray } from '@bit/ume.design-kit.ui/utils/_colors'
import { mobile } from '../../common/assets/_breakpoints'

export const HomeContainerStyle = styled(Box)`
  background-color: ${gray.azure};
  box-shadow: 2px 0px 8px 2px rgba(152, 152, 152, 0.5);
  padding-left: 10%;
  height: 100vh;

  @media (max-width: ${mobile}) {
    padding: 0;
    height: 100%;
    display:flex;
    align-items: center;
  }
`
export const MainBoxStyle = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: ${mobile}) {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto;
  }
`