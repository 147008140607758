import { gray, green, red, white } from '@bit/ume.design-kit.ui/Palette'
import * as React from 'react'
import { yellow } from '../../common/assets/_colors'
import { RenegotiationPaymentResponse } from '../../services/bff-backoffice/coordinator/interfaces/renegotiation.interfaces'
import { TagStyle } from './style'
const moment = require('moment')

export interface IRenegotiationPaymentStatusProps {
  payment?: RenegotiationPaymentResponse | Error
}

export default class RenegotiationPaymentStatus extends React.PureComponent<IRenegotiationPaymentStatusProps> {
  public render() {
    const { payment } = this.props

    const statusTag = (p: RenegotiationPaymentResponse) => {
      switch (p.status) {
        case 'DUE':
          return p.dueDate < moment().format('YYYY-MM-DD') ? (
            <TagStyle
              title="Clique para ver mais detalhes"
              backgroundColor={red.primary}
              label={'Atrasada'}
              iconBackground="none"
              iconColor={white.primary}
            />
          ) : (
            <TagStyle
              title="Clique para ver mais detalhes"
              backgroundColor={gray.primary}
              label={'Aberta'}
              iconBackground="none"
              iconColor={white.primary}
            />
          )
        case 'PAID':
          return (
            <TagStyle
              title="Clique para ver mais detalhes"
              backgroundColor={green.laurel}
              label={'Paga'}
              iconBackground="none"
              iconColor={white.primary}
            />
          )
        case 'DEFAULTED':
          return (
            <TagStyle
              disable
              backgroundColor={red.primary}
              label={'Quebrada'}
              iconBackground="none"
              iconColor={yellow.primary}
            />
          )
        default:
          return 'no status'
      }
    }
    return payment === undefined ? <></> : payment instanceof Error ? <></> : statusTag(payment)
  }
}
