import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { bffBackofficeApiService } from '../../../services/bff-backoffice'
import {
  IRetailerBenchApplicationOptionsResponse,
  RetailersBenchResponse,
} from '../../../services/bff-backoffice/application-engine/interfaces/application-engine.interfaces'
import { RetailersBenchSliceReducer } from './retailers-bench.reducer'

function* fetchRetailersBench(action: PayloadAction<undefined>) {
  try {
    yield put(RetailersBenchSliceReducer.actions.fetchRetailersBenchLoading())
    const result: RetailersBenchResponse = yield bffBackofficeApiService.applicationEngine.getRetailersInEvaluationBench()
    yield put(RetailersBenchSliceReducer.actions.fetchRetailersBenchSuccess(result))
  } catch (error) {
    yield put(RetailersBenchSliceReducer.actions.fetchRetailersBenchError(error))
  }
}

function* fetchRetailerBenchApplications(action: PayloadAction<string>) {
  try {
    const retailerId = action.payload
    yield put(RetailersBenchSliceReducer.actions.fetchRetailerBenchApplicationsLoading())
    const result: IRetailerBenchApplicationOptionsResponse[] = yield bffBackofficeApiService.applicationEngine.getApplicationsInRetailerBench(
      retailerId
    )
    yield put(RetailersBenchSliceReducer.actions.fetchRetailerBenchApplicationsSuccess(result))
  } catch (error) {
    yield put(RetailersBenchSliceReducer.actions.fetchRetailerBenchApplicationsError(error))
  }
}

function* enableRetailer(action: PayloadAction<string>) {
  try {
    const retailerId = action.payload
    yield put(RetailersBenchSliceReducer.actions.enableRetailerLoading())
    yield bffBackofficeApiService.applicationEngine.enableRetailerInRetailersBench(retailerId)
    yield put(RetailersBenchSliceReducer.actions.enableRetailerSuccess())
    yield put(RetailersBenchSliceReducer.actions.fetchRetailersBench())
  } catch (error) {
    yield put(RetailersBenchSliceReducer.actions.enableRetailerError(error))
  }
}

function* disableRetailer(action: PayloadAction<string>) {
  try {
    const retailerId = action.payload
    yield put(RetailersBenchSliceReducer.actions.disableRetailerLoading())
    yield bffBackofficeApiService.applicationEngine.disableRetailerInRetailersBench(retailerId)
    yield put(RetailersBenchSliceReducer.actions.disableRetailerSuccess())
    yield put(RetailersBenchSliceReducer.actions.fetchRetailersBench())
  } catch (error) {
    yield put(RetailersBenchSliceReducer.actions.disableRetailerError(error))
  }
}

const retailersBenchSaga = [
  takeLatest(RetailersBenchSliceReducer.actions.fetchRetailersBench, fetchRetailersBench),
  takeLatest(RetailersBenchSliceReducer.actions.fetchRetailerBenchApplications, fetchRetailerBenchApplications),
  takeLatest(RetailersBenchSliceReducer.actions.enableRetailer, enableRetailer),
  takeLatest(RetailersBenchSliceReducer.actions.disableRetailer, disableRetailer),
]

export default retailersBenchSaga
