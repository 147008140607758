import { booleanToSimOrNao, formatDate, formatNumberToBRL } from '../../../utils'

const paymentsHeader = [
  { property: 'createdOn', label: 'Data', format: formatDate },
  { property: 'installmentId', label: 'ID Parcela' },
  { property: 'contractId', label: 'ID Contrato' },
  { property: 'principal', label: 'Principal', format: formatNumberToBRL },
  {
    property: 'financedValue',
    label: 'Valor Financiado',
    format: formatNumberToBRL,
  },
  { property: 'contractTimestamp', label: 'Data Compra', format: formatDate },
  { property: 'installmentDueDate', label: 'Data Vencimento', format: formatDate },
  { property: 'installmentOrder', label: 'Número Parcela' },
  { property: 'numberOfInstallments', label: 'Total Parcelas' },
  { property: 'paymentTimestamp', label: 'Data Pagamento', format: formatDate },
  { property: 'installmentValue', label: 'Valor Parcela', format: formatNumberToBRL },
  { property: 'paidValue', label: 'Valor Pago', format: formatNumberToBRL },
  { property: 'earlyPaymentDiscount', label: 'Desconto Pagamento Antecipado', format: formatNumberToBRL },
  {
    property: 'principalOutstandingBalanceAfterPayment',
    label: 'Saldo Devedor Após Pagamento',
    format: formatNumberToBRL,
  },
  { property: 'amortization', label: 'Amortização', format: formatNumberToBRL },
  { property: 'defaultInterest', label: 'Juros de Mora', format: formatNumberToBRL },
  { property: 'defaultFine', label: 'Multa de Mora', format: formatNumberToBRL },
  { property: 'monetaryCorrection', label: 'Correção Monetária', format: formatNumberToBRL },
  { property: 'contractAlignmentFeeDue', label: 'Alinhamento Devido', format: formatNumberToBRL },
  {
    property: 'isUpfrontPayment',
    label: 'Pagamento de entrada?',
    format: booleanToSimOrNao,
  },
  { property: 'method', label: 'Método de pagamento' },
  { property: 'paymentRetailerName', label: 'Varejista' },
  { property: 'paymentStoreName', label: 'Loja' },
  { property: 'paymentOperatorName', label: 'Operador' },
]

export const PaymentsConfig = {
  label: 'Pagamentos',
  route: '/payments',
  header: paymentsHeader,
}
