import { DataTable } from '@bit/ume.design-kit.ui'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import Modal from 'antd/lib/modal/Modal'
import { Box } from 'grommet'
import React, { ReactNode, useState } from 'react'
import { getArrayOfObjectsKeys } from '../../config/utils'
import { IPostApplicationForcedApprovalAnalysisResponse } from '../../services/bff-backoffice/coordinator/interfaces/applications.interfaces'
import { formatNumberToBRL } from '../../utils'
import { TableContainer, TypeLabel } from './style'

interface IForcedApprovalTableData {
  type: string
  reason: string
  count: number
}

const VALIDATION_TABLE_HEADER = [
  {
    label: 'Tipo',
    attribute: 'type',
    textAlign: 'center',
    formatCell: (row: IForcedApprovalTableData) => {
      if (row.type === 'error') {
        return <TypeLabel backgroundColor="#FF2601">ERRO</TypeLabel>
      } else if (row.type === 'warning') {
        return <TypeLabel backgroundColor="#FF8B00">WARNING</TypeLabel>
      } else return <span>{row.type}</span>
    },
  },
  { attribute: 'reason', label: 'Descrição', textAlign: 'center' },
  {
    attribute: 'count',
    label: 'Quantidade',
    textAlign: 'center',
  },
]

interface IForcedApprovalsWarningsProps {
  forcedApprovalErrors?: IPostApplicationForcedApprovalAnalysisResponse
}

interface IForcedApprovalsWarningsState {
  isModalVisible: boolean
  modalData: any[]
  modalHeader: any[]
  modalTitle: ReactNode
}

const initialState = {
  isModalVisible: false,
  modalData: [],
  modalHeader: [],
  modalTitle: <></>,
}

export const ForcedApprovalsWarnings = (props: IForcedApprovalsWarningsProps) => {
  const [state, setState] = useState<IForcedApprovalsWarningsState>(initialState)

  // On table click:
  //  -- Show modal with all errors of the clicked element
  const handleTableClick = (element: IForcedApprovalTableData) => {
    if (!props.forcedApprovalErrors) {
      return
    }

    // Filter data for the specific error reason
    let modalData: any[] = []
    let modalTitle: any
    if (element.type === 'error') {
      modalData = props.forcedApprovalErrors.errors.filter(error => error.reason === element.reason)
      modalTitle = (
        <>
          <TypeLabel backgroundColor="#FF2601" style={{ width: 'unset', padding: 5, marginRight: 10 }}>
            ERRO
          </TypeLabel>
          {element.reason}
        </>
      )
    } else {
      modalData = props.forcedApprovalErrors.errors.filter(warning => warning.reason === element.reason)
      modalTitle = (
        <>
          <TypeLabel backgroundColor="#FF8B00" style={{ width: 'unset', padding: 5, marginRight: 10 }}>
            WARNING
          </TypeLabel>
          {element.reason}
        </>
      )
    }

    // Get a generic table header
    //  -- each error/reason has a different object
    const objectsKeys = getArrayOfObjectsKeys(modalData)
    const modalHeader = objectsKeys.map((key: string) => ({
      label: key,
      attribute: key,
      formatCell: (row: any) => {
        // Damn this if...
        if (isNaN(row[key]) || key === 'borrowerId') {
          return row[key]
        } else {
          return formatNumberToBRL(row[key])
        }
      },
    }))

    setState({ isModalVisible: true, modalData, modalHeader, modalTitle })
  }

  const { forcedApprovalErrors } = props
  const { isModalVisible, modalData, modalTitle, modalHeader } = state

  // If there's no errors, then no need to show any table
  if (!forcedApprovalErrors) return <></>

  // ------------------ DATA TRANSFORMATION ------------------
  const { errors } = forcedApprovalErrors

  // Aggregating the errors into a js object
  //    - counts the number of times each error happened
  const errorsObject: any = {}
  for (const error of errors) {
    if (!errorsObject[error.reason]) {
      errorsObject[error.reason] = 1
    } else {
      errorsObject[error.reason] += 1
    }
  }

  // Transforms the object into an array
  const errorsArray: IForcedApprovalTableData[] = Object.keys(errorsObject).map((key: string) => ({
    type: 'error',
    reason: key,
    count: errorsObject[key],
  }))

  return (
    <Box style={{ minHeight: 400 }}>
      <h1>Validação da planilha:</h1>
      {errorsArray.length === 0 ? (
        <span>
          <strong style={{ color: UMEColors.green.dark }}>Nenhum erro encontrado.</strong>
        </span>
      ) : (
        <></>
      )}
      <TableContainer>
        <DataTable
          onRowElementClick={handleTableClick}
          header={VALIDATION_TABLE_HEADER}
          data={errorsArray}
          fontSize="14px"
        />
      </TableContainer>

      <Modal
        visible={isModalVisible}
        title={modalTitle}
        width="80%"
        onOk={() => setState({ ...state, isModalVisible: false })}
        onCancel={() => setState({ ...state, isModalVisible: false })}
        footer={null}
      >
        <TableContainer>
          <DataTable header={modalHeader} data={modalData} fontSize="14px" />
        </TableContainer>
      </Modal>
    </Box>
  )
}
