import { Loading, Text } from '@bit/ume.design-kit.ui'
import { Box } from 'grommet'
import * as React from 'react'
import { ResponsiveGrid } from '../../atoms/ResponsiveGrid/ResponsiveGrid'
import { InfoTextStyle, LabelStyle } from '../../pages/CS-Profile/ContractsSummaryCard/style'
import { ISingleInstallment } from '../../services/bff-backoffice/coordinator/interfaces/installment.interfaces'
import { formatNumberToBRL } from '../../utils'
import { CardStyle, DataBoxStyle } from './style'

export interface IInstallmentValuesProps {
  installment?: ISingleInstallment | Error
}

export default class InstallmentValues extends React.PureComponent<IInstallmentValuesProps> {
  public render() {
    const { installment } = this.props

    const errorCard = (
      <Text color="red" light>
        Não foi possível carregar os dados para esta parcela.
      </Text>
    )

    const InstallmentValuesCard = (installment: ISingleInstallment) => (
      <Box>
        <ResponsiveGrid columns="150px" gap="small">
          <DataBoxStyle>
            <LabelStyle>Amortização</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(installment.amortization)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Valor da parcela</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(installment.installmentValue)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Juros</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(installment.interest)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Multa de mora</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(installment.defaultFine)}</InfoTextStyle>
          </DataBoxStyle>
        </ResponsiveGrid>
      </Box>
    )
    return (
      <CardStyle height="376px" width="376px">
        {installment === undefined ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : installment instanceof Error ? (
          errorCard
        ) : (
          InstallmentValuesCard(installment)
        )}
      </CardStyle>
    )
  }
}
