import { Card, Loading, Text } from '@bit/ume.design-kit.ui'
import * as React from 'react'
import styled from 'styled-components'
import { numberToCurrency } from '../../../common/assets/utils/currencyMask'
import {IOriginationSummary} from '../../../services/bff-backoffice/coordinator/interfaces/contracts.interfaces'
import { ContractSummaryDataBoxStyle, InfoTextStyle, LabelStyle } from './style'

export interface IContractsSummaryCardProps {
  contractsSummary?: IOriginationSummary | Error
}

export default class ContractsSummaryCard extends React.PureComponent<IContractsSummaryCardProps> {
  public render() {
    const { contractsSummary } = this.props

    const errorCard = (
      <Text color="red" light>
        Não foi possível carregar os dados para este cliente.
      </Text>
    )

    const ContractsSummaryCard = (contracts: IOriginationSummary) => (
      <ContractsContainer>
        <ContractSummaryDataBoxStyle>
          <LabelStyle>Contratos:</LabelStyle>
          <InfoTextStyle>{contracts.totalContracts + ' contratos'}</InfoTextStyle>
        </ContractSummaryDataBoxStyle>
        <ContractSummaryDataBoxStyle>
          <LabelStyle>Valor total:</LabelStyle>
          <InfoTextStyle>{numberToCurrency(contracts.sumContractsValues, true)}</InfoTextStyle>
        </ContractSummaryDataBoxStyle>
        <ContractSummaryDataBoxStyle>
          <LabelStyle>Valor devido:</LabelStyle>
          <InfoTextStyle>{numberToCurrency(contracts.sumPendingInstallmentsValue, true)}</InfoTextStyle>
        </ContractSummaryDataBoxStyle>
        <ContractSummaryDataBoxStyle>
          <LabelStyle>Valor vencido:</LabelStyle>
          <InfoTextStyle>{numberToCurrency(contracts.sumOverdueInstallmentsValue, true)}</InfoTextStyle>
        </ContractSummaryDataBoxStyle>
        <ContractSummaryDataBoxStyle>
          <LabelStyle>À vencer:</LabelStyle>
          <InfoTextStyle>{numberToCurrency(contracts.sumPendingDueInstallmentsValue, true)}</InfoTextStyle>
        </ContractSummaryDataBoxStyle>
        <ContractSummaryDataBoxStyle>
          <LabelStyle>Valor pago:</LabelStyle>
          <InfoTextStyle>{numberToCurrency(contracts.sumPaidInstallmentsValue, true)}</InfoTextStyle>
        </ContractSummaryDataBoxStyle>
      </ContractsContainer>
    )

    if (!contractsSummary) {
      return (
        <CardStyle style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Loading />
        </CardStyle>
      )
    }

    return (
      <CardStyle style={{ height: undefined, width: undefined }}>
        {contractsSummary instanceof Error ? errorCard : ContractsSummaryCard(contractsSummary)}
      </CardStyle>
    )
  }
}

const CardStyle = styled(Card)`
  border-radius: 8px;
  padding: 24px 32px;
  height: 100%;
  width: 100%:
`

const ContractsContainer = styled.div`
  height: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
`
