import { Anchor, Loading, Text } from '@bit/ume.design-kit.ui'
import { Image } from 'antd'
import { Box } from 'grommet'
import * as React from 'react'
import { FileUploader } from '../../atoms/FileUploader/FileUploader'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { ResponsiveGrid } from '../../atoms/ResponsiveGrid/ResponsiveGrid'
import NotFoundImage from '../../common/assets/images/not-found-icon.png'
import { InfoTextStyle, LabelStyle } from '../../pages/CS-Profile/ContractsSummaryCard/style'
import { CardStyle, DataBoxStyle } from './styles'
import styled from 'styled-components'

export interface IDetailsCardData {
  label: string
  value?: JSX.Element | string | number
  image?: boolean
  fallback?: string
  handleFile?: (file: File) => void
  handleEditionClick?: () => void
  editionText?: string
}

export interface IDetailsCardProps {
  data: IDetailsCardData[] | Error
}

export default class DetailsCard extends React.Component<IDetailsCardProps> {
  public render() {
    const errorCard = (
      <Text color="red" light>
        Não foi possível carregar os dados.
      </Text>
    )

    const DetailsCard = () =>
      this.props.data instanceof Error ? (
        errorCard
      ) : (
        <Box direction="row" gap="medium">
          <Box width="100%">
            <ResponsiveGrid columns="200px" gap="small">
              {this.props.data.map((data, index) => {
                return data.image ? (
                  <DataBoxStyle key={index} align="start">
                    {data.handleFile ? (
                      <LabelStyle>
                        {data.label} (<FileUploader textAnchor="Editar" handleFile={data.handleFile} />)
                      </LabelStyle>
                    ) : (
                      <LabelStyle>{data.label}</LabelStyle>
                    )}
                    {!data.value ? (
                      <Box height="10vh" width="10vh" alignContent="center" justify="center">
                        <LoadingIcon />
                      </Box>
                    ) : (
                      <Image
                        src={data.value as string}
                        // fit="contain"
                        style={{ width: '10vh', height: '10vh', marginBottom: '1vh' }}
                        fallback={data.fallback ? data.fallback : NotFoundImage}
                      />
                    )}
                  </DataBoxStyle>
                ) : (
                  <DataBoxStyle key={index} align="start">
                    <LabelStyle>
                      {data.label}
                      {data.handleEditionClick ? (
                        <>
                          {' '}
                          (<Anchor onClick={data.handleEditionClick}>{data.editionText ? data.editionText : 'Editar'}</Anchor>)
                        </>
                      ) : (
                        ''
                      )}
                    </LabelStyle>
                    <Value>{data.value ? data.value : '-'}</Value>
                  </DataBoxStyle>
                )
              }
              )}
            </ResponsiveGrid>
          </Box>
        </Box>
      )

    return (
      <CardStyle height="100%">
        {this.props.data === undefined ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : (
          DetailsCard()
        )}
      </CardStyle>
    )
  }
}

const Value = styled(InfoTextStyle)`
  width: 100%;
`