import { MaskedInputProps as GrommetMaskedInputProps } from 'grommet'
import React, { Component } from 'react'
import { StyledBoxInput, StyledLabel, StyledMaskedInput } from './styles'

interface MaskedInputProps {
  label?: string
  labelBackgroundColor?: string
  errorIntent?: boolean
}

export default class MaskedInput extends Component<
  MaskedInputProps & GrommetMaskedInputProps & Omit<JSX.IntrinsicElements['input'], 'onSelect' | 'size'>
> {
  render() {
    return (
      <StyledBoxInput errorIntent={this.props.errorIntent}>
        <StyledMaskedInput {...this.props}>{this.props.children}</StyledMaskedInput>
        {this.props.label && (
          <StyledLabel backgroundColor={this.props.labelBackgroundColor} required={this.props.required}>
            {this.props.label}
          </StyledLabel>
        )}
      </StyledBoxInput>
    )
  }
}
