import React from 'react'
import { FormPrevious, FormNext } from 'grommet-icons'
import { Card, Dropdown, Paragraph } from '@bit/ume.design-kit.ui'
import { PageBox } from './styles'

interface IPaginationProps {
  currentPage: number
  pageSize: number
  onSelectPageSize?: (options?: any) => void
  onPreviousPage?: () => void
  onNextPage?: () => void
  totalData: number
}

const Pagination = (props: IPaginationProps) => {
  const getOptions = () => {
    let options = [10, 20, 30, 40, 50]
    let isAllowedToPush = true
    options.forEach((item: number) => {
      if (item === props.pageSize) {
        isAllowedToPush = false
      }
    })

    if (isAllowedToPush) {
      options.push(props.pageSize)
    }
    return options
  }
  
  return(
    <div style={{ display: "flex"}}>
      <div style={{ margin: "auto", display: "flex"}}>
        {
          props.currentPage > 1 &&
          <PageBox onClick={props.onPreviousPage}>
            <FormPrevious />
          </PageBox>
        }
        <Card>
          {props.currentPage}
        </Card>
        {
          props.totalData / props.pageSize > props.currentPage &&
          <PageBox onClick={props.onNextPage}>
            <FormNext />
          </PageBox>
        }
      </div>
      <Card width="200px">
        <Paragraph fill size="small">Resultados por página</Paragraph>
        <Dropdown
          placeholder="Selecione"
          open={false}
          value={props.pageSize}
          options={getOptions()}
          onChange={props.onSelectPageSize}
        /> 
      </Card>
    </div>
  )
}

export default Pagination