import { Loading, Text } from '@bit/ume.design-kit.ui'
import { Box } from 'grommet'
import * as React from 'react'
import { ResponsiveGrid } from '../../atoms/ResponsiveGrid/ResponsiveGrid'
import { InfoTextStyle, LabelStyle } from '../../pages/CS-Profile/ContractsSummaryCard/style'
import { RenegotiationPaymentResponse } from '../../services/bff-backoffice/coordinator/interfaces/renegotiation.interfaces'
import { formatNumberToBRL } from '../../utils'
import { CardStyle, DataBoxStyle } from './style'

export interface IRenegotiationPaymentValuesProps {
  renegotiationPayment?: RenegotiationPaymentResponse | Error
}

export default class RenegotiationPaymentValues extends React.PureComponent<IRenegotiationPaymentValuesProps> {
  public render() {
    const { renegotiationPayment } = this.props

    const errorCard = (
      <Text color="red" light>
        Não foi possível carregar os dados para este pagamento.
      </Text>
    )

    const RenegotiationPaymentValuesCard = (renegotiationPayment: RenegotiationPaymentResponse) => (
      <Box>
        <ResponsiveGrid columns="150px" gap="small">
          <DataBoxStyle>
            <LabelStyle>Amortização</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(0)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Valor da parcela</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(renegotiationPayment.value)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Juros</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(renegotiationPayment.interest)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Multa de mora</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(renegotiationPayment.defaultFine)}</InfoTextStyle>
          </DataBoxStyle>
        </ResponsiveGrid>
      </Box>
    )
    return (
      <CardStyle width="376px">
        {renegotiationPayment === undefined ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : renegotiationPayment instanceof Error ? (
          errorCard
        ) : (
          RenegotiationPaymentValuesCard(renegotiationPayment)
        )}
      </CardStyle>
    )
  }
}
