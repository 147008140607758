import React from 'react'
import{
  RetailerPicker,
  BorrowerSearch,
  MultiTag,
  StorePicker
} from '../../molecules/Prioritization'

const Filters = (props: any) => {
  return(
    <>
      <BorrowerSearch
        borrowerName={props.borrowerName}
        borrowerCpf={props.borrowerCpf}
        getValue={props.getValue}
        onFocus={props.onFocus}
        onChange={props.onChange}
      />
      <RetailerPicker
        onPick={props.onPick}
        retailers={props.retailers}
        retailersOptions={props.retailersOptions}
        value={props.value}
      />
      <StorePicker
        onPickStore={props.onPickStore}
        stores={props.store}
        storesOptions={props.storeOptions}
        storeValue={props.storeValue}
      />
      <MultiTag
        onPickTag={props.onPickTag}
        onRemoveTag={props.onRemoveTag}
        tags={props.tags}
        tagOptions={props.tagOptions}
        onClearTags={props.onClearTags}
        />
    </>
  )
}

export default Filters