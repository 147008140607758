export interface ApplicationBatchesResponse {
  numberOfApplications: number
  batchNumber: number
}

export interface RetailersBenchResponse {
  retailers: {
    [id: string]: {
      fantasyName: string
      logo: string
      active: boolean
    }
  }
}

export interface IApplicationResponse {
  id: string
  status: ApplicationStatus
  borrowerZipcode?: string
  borrowerId: string
  retailerId?: string
  storeId: string
  professionId: string
  operatorId?: string
  policyId: string
  requestedValue?: number
  stages?: IApplicationStage[]
  generatedAt?: ApplicationGeneratedAt
  batchNumber?: number | null
  denialReasons?: DenialReasons[]
  evaluatedByOperatorId?: string
  applicationType?: ApplicationType
  createdOn: string
  reevaluationId?: string
  evaluatedTimestamp?: string
  version?: string
}

export interface IApplicationStage {
  id: string
  name: StageName
  startedOnTimestamp: string
  finishedOnTimestamp: string
  inputAttributesValues: any
  evaluationResult: ApplicationStageEvaluationResult
  modelPredictions: IModelPredictionResponse[]
}

export interface ApplicationStageEvaluationResult {
  approved: boolean
  reason?: ReasonOfRefusal
  extra?: any
}

export interface IModelPredictionResponse {
  id: string
  modelId: string
  createdOn: string
  modifiedOn: string
  applicationStageId: string
  contractId: string
  limitId: string
  inputAttributesValues: any
  outputAttributesValues: any
}

export interface IScoresResponse {
  scudraScore?: number
  bemolBehaviorScore?: number
}

export interface IBatchApplicationOptionsResponse {
  id: string
  batchNumber?: number | null
  createdOn: string
  borrowerId: string
  retailerId: string
  storeId: string
}

export interface IRetailerBenchApplicationOptionsResponse {
  id: string
  createdOn: string
  borrowerId: string
  storeId: string
}

export interface IGetBorrowerApplicationStatus {
  status: ApplicationStatus
  biometryStatus?: BiometryStatus
}

export enum ApplicationStatus {
  PRE_APPROVED = 'PRE_APPROVED',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  FAILURE = 'FAILURE',
  UNDER_ANALYSIS = 'UNDER_ANALYSIS',
  WAITING_MANUAL_APPROVAL = 'WAITING_MANUAL_APPROVAL',
}

export enum PreApplicationStatus {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  UNDER_ANALYSIS = 'UNDER_ANALYSIS',
  WAITING_MANUAL_APPROVAL = 'WAITING_MANUAL_APPROVAL',
  PRE_APPLICATION_APPROVED = 'PRE_APPLICATION_APPROVED',
  PRE_APPLICATION_DENIED = 'PRE_APPLICATION_DENIED',
  PRE_APPLICATION_CONTINUE = 'PRE_APPLICATION_CONTINUE',
}

export enum BiometryStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  CONFLICT = 'CONFLICT',
  CANCELED = 'CANCELED',
  SPOOF = 'SPOOF',
}

export enum ApplicationType {
  APPLICATION = 'APPLICATION',
  REEVALUATION = 'REEVALUATION',
}

export enum ApplicationGeneratedAt {
  MOBILE_APP = 'mobile-app',
  CLIENT_BROWSER = 'client-browser',
}

export enum DenialReasons {
  SPOOF_FACE_IMAGE = 'SPOOF_FACE_IMAGE',
  SPOOF_DOCUMENT_IMAGE = 'SPOOF_DOCUMENT_IMAGE',
  IVALID_DOCUMENT = 'IVALID_DOCUMENT',
  INVALID_FACE_MATCHING = 'INVALID_FACE_MATCHING',
  TAMPERED_DOCUMENT = 'TAMPERED_DOCUMENT',
  ILLEGIBLE_DOCUMENT = 'ILLEGIBLE_DOCUMENT',
  INVALID_OCR = 'INVALID_OCR',
  NOT_IN_AMAZON = 'NOT_IN_AMAZON',
  UNABLE_TO_VALIDATE_PHONE = 'UNABLE_TO_VALIDATE_PHONE',
  FAILED_OPERATOR_VALIDATION = 'FAILED_OPERATOR_VALIDATION',
}

export type StageName =
  | 'BORROWER_FETCH'
  | 'BIOMETRY'
  | 'PHONE_VERIFICATION'
  | 'BEMOL'
  | 'FIRST_LIMIT'
  | 'SCUDRA_SCORE'
  | 'BVS_INFO'
  | 'SPC_INFO'
  | 'FRONTEND'
  | 'NEOWAY'
  | 'PROFESSION'
  | 'BVS_POSITIVE_EVALUATION'

export type ReasonOfRefusal =
  | 'BIOMETRY_PROCESS_FAILURE'
  | 'BIOMETRY_SCORE_BELOW_THRESHOLD'
  | 'BORROWER_NOT_FOUND'
  | 'CREDIT_API_BAD_GATEWAY'
  | 'CREDIT_SCORE_BELOW_THRESHOLD'
  | 'NO_COMPLETED_BIOMETRY_PROCESS_FOUND'
  | 'NO_COMPLETED_PHONE_VERIFICATION_FOUND'
  | 'PHONE_VERIFICATION_NOT_FOUND'
  | 'BEMOL_NOT_FOUND'
  | 'BEMOL_DEFAULT'
  | 'BEMOL_BEHAVIOR_SCORE_BELOW_THRESHOLD'
  | 'BEMOL_APPLICATION_SCORE_BELOW_THRESHOLD'
  | 'FRONTEND_TIMEOUT'
  | 'NEOWAY_NOT_FOUND'
  | 'PROFESSION_GROUP_NOT_FOUND'
  | 'SPC_NOT_FOUND'
  | 'CPF_HAS_SPC_RESTRICTION'
  | 'BEMOL_TAKEN_LIMIT_ABOVE_THRESHOLD'
  | 'BEMOL_OVERDUE_DAYS_ABOVE_THRESHOLD'
