import React from 'react'
import { Link } from 'react-router-dom'
import { BreadcrumbsBox, BreadcrumbsItem } from '../RetailersPage/styles'

interface IRiskBreadcrumbsProps {
  screenName: string
  retailerId: string
}

// TODO - Use fantasyName, not retailerId
const BenchApplicationBreadcrumbs = ({ retailerId, screenName }: IRiskBreadcrumbsProps) => {
  return (
    <BreadcrumbsBox>
      <Link to="/">
        <BreadcrumbsItem>Início</BreadcrumbsItem>
      </Link>
      <BreadcrumbsItem>/</BreadcrumbsItem>
      <Link to={`/retailers-bench`}>
        <BreadcrumbsItem>Banca de Varejistas</BreadcrumbsItem>
      </Link>
      <BreadcrumbsItem>/</BreadcrumbsItem>
      <Link to={`/retailers-bench/${retailerId}/applications`}>
        <BreadcrumbsItem>{retailerId}</BreadcrumbsItem>
      </Link>
      <BreadcrumbsItem>/</BreadcrumbsItem>
      <BreadcrumbsItem active>{screenName}</BreadcrumbsItem>
    </BreadcrumbsBox>
  )
}

export default BenchApplicationBreadcrumbs