import React, { useState } from 'react'
import { RiskBreadcrumbs } from '../../molecules/LimitsModificationPage/RiskBreadcrumbs'
import { Heading, Box } from 'grommet'
import { Button, Tooltip, Modal } from 'antd'
import XLSX from 'xlsx'
import { toaster } from '../../App'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { get_header_row } from '../../config/utils'
import { ConfirmButton, Container, DownloadTableTemplateButton, FileUpload, Title, UploadText } from './style'
import { ForcedApprovalsWarnings } from '../../organisms/ForcedApprovalModification/ForcedApprovalWarnings'
import {bffBackofficeApiService} from '../../services/bff-backoffice'
import { IForcedApprovalBody, IPostApplicationForcedApprovalAnalysisResponse } from '../../services/bff-backoffice/coordinator/interfaces/applications.interfaces'

interface IForcedApprovalPageProps {
  history: any
  location: any
}

interface IForcedApprovalPageState {
  isLoadingErrors: boolean
  // TODO - Usar a Interface do coordinator-aclient
  errors?: IPostApplicationForcedApprovalAnalysisResponse
  isForcingApproval: boolean
  forcedApprovalBorrowers?: IForcedApprovalBody
}

const initialState: IForcedApprovalPageState = {
  isLoadingErrors: false,
  isForcingApproval: false,
}

export const ForcedApprovalPage = (props: IForcedApprovalPageProps) => {
  const [state, setState] = useState<IForcedApprovalPageState>(initialState)

  // Gets the inputted file from the user
  // - Validates it's format (header rows)
  // - Creates a json array
  // - Sends to server
  const handleSelectedFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, errors: undefined, isLoadingErrors: true })

    const files = e.target.files

    if (!files || files.length === 0) {
      setState({ ...state, isLoadingErrors: false })
      return toaster.showErrorToast(`Selecione um arquivo`)
    }
    const file = files[0]

    const bufferData = await file.arrayBuffer()

    // Read the .xlsx file
    const data = XLSX.read(bufferData, { type: 'buffer' })

    // Get all sheet's names
    const sheetNames = data.SheetNames

    // No sheet or multiple sheets error
    if (sheetNames.length !== 1) {
      setState({ ...state, isLoadingErrors: false })
      return toaster.showErrorToast(`Planilha deve ter uma Sheet!`)
    }

    // Get the worksheet
    const worksheet = data.Sheets[sheetNames[0]]

    // Get the header row
    const headerRow = get_header_row(worksheet)

    // Check if the header row is as expected
    const expectedHeadersArray = ['borrowerId', 'recurringPurchasesLimit', 'bigPurchasesLimit']
    for (const expectedHeader of expectedHeadersArray) {
      if (!headerRow.find(element => element === expectedHeader)) {
        setState({ ...state, isLoadingErrors: false })
        return toaster.showErrorToast(`Coluna ${expectedHeader} faltando na tabela.`)
      }
    }

    let jsonFromTable: any[] = XLSX.utils.sheet_to_json(data.Sheets[sheetNames[0]])
    const jsonBody: any[] = []

    // Treat json from table into expected json for API
    for (let element of jsonFromTable) {
      const borrowerId = String(element.borrowerId)
      const recurringPurchasesLimit = element.recurringPurchasesLimit !== undefined ? Number(element.recurringPurchasesLimit) : undefined
      const bigPurchasesLimit = element.bigPurchasesLimit !== undefined  ? Number(element.bigPurchasesLimit) : undefined

      jsonBody.push({
        borrowerId,
        recurringPurchasesLimit,
        bigPurchasesLimit,
      })
    }

    const forcedApprovalBorrowers = {
      approvals: jsonBody
    }

    bffBackofficeApiService.coordinator.validateApplicationApprovalAnalysis({ approvals: jsonBody })
      .then(result => {
        toaster.showSuccessToast(`Planilha carregada com sucesso.`)
        setState({ ...state, errors: result, isLoadingErrors: false, forcedApprovalBorrowers })
      })
      .catch(error => {
        if (error && error.response && error.response.data) {
          Modal.error({
            title: 'Erro ao submeter planilha',
            content: JSON.stringify(error.response.data),
          })
        } else {
          toaster.showErrorToast(`Erro no servidor`)
        }
        setState({ ...state, isLoadingErrors: false })
      })
  }

  // Changes the event value to default, so the onChange event is triggered even if
  //   the same file is uploaded.
  const handleClickInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = ""
  }

  // Creates a template table and sends to user
  const handleDownloadTableTemplate = () => {
    const template = [
      { borrowerId: 1234, recurringPurchasesLimit: 100, bigPurchasesLimit: 200 },
      { borrowerId: 1235, recurringPurchasesLimit: null, bigPurchasesLimit: null },
    ]

    const sheet = XLSX.utils.json_to_sheet(template)
    XLSX.utils.sheet_add_json(sheet, [], { origin: 'A3' })

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, sheet)
    XLSX.writeFile(wb, 'forced_approval_template.xlsx')
  }

  // Clear screen
  const clearScreen = () => {
    setState({ ...state, errors: undefined, isLoadingErrors: false, isForcingApproval: false })
  }

  // Approve borrowers by force
  const handleConfirmButton = () => {
    setState({ ...state, isForcingApproval: true })

    if (!state.forcedApprovalBorrowers) {
      return
    }

    bffBackofficeApiService.coordinator
      .forceApplicationApprove(state.forcedApprovalBorrowers)
      .then(() => {
        Modal.success({
          title: 'Clientes aprovados com sucesso!',
          maskClosable: true,
          centered: true,
        })
        clearScreen()
      })
      .catch(error => {
        if (error && error.response && error.response.data) {
          Modal.error({
            title: 'Erro ao submeter planilha',
            content: JSON.stringify(error.response.data),
          })
        } else {
          toaster.showErrorToast(`Erro no servidor`)
        }
      })
  }

  const { isLoadingErrors, errors, isForcingApproval } = state
  const hasErrors = errors && errors.errors.length > 0 ? true : false

  return (
    <Container>
      <div style={{ marginTop: '3vh' }}>
        <RiskBreadcrumbs screenName='Aprovação Forçada'/>
      </div>

      <Title>
        <Heading level={2}> Aprovação Forçada </Heading>
        <DownloadTableTemplateButton type="primary" size="middle" onClick={handleDownloadTableTemplate}>
          Download template da planilha
        </DownloadTableTemplateButton>
      </Title>

      <Box direction="row" margin={{ top: '5vh' }}>
        <UploadText>
          Selecione uma planilha no formato <strong>.xlsx</strong> para validação
        </UploadText>

        <FileUpload type="file" accept=".xlsx" onChange={handleSelectedFile} onClick={handleClickInputFile} />
      </Box>

      <Box direction="row" margin={{ top: '5vh' }}>
        {isLoadingErrors ? (
          <Box flex margin={{ top: '8vh' }} align="center" justify="center">
            <LoadingIcon />
          </Box>
        ) : (
          <ForcedApprovalsWarnings forcedApprovalErrors={errors} />
        )}
      </Box>

      {errors ? (
        <Box direction="row" margin={{ top: '3vh' }} align="center" justify="end" gap="medium">
          <Button onClick={clearScreen}> Cancelar </Button>
          <Tooltip title={hasErrors ? 'Corrija os erros notificados para efetivar as aprovações.' : ''}>
            <ConfirmButton
              onClick={handleConfirmButton}
              loading={isForcingApproval}
              type="primary"
              disabled={hasErrors}
            >
              Efetivar aprovações
            </ConfirmButton>
          </Tooltip>
        </Box>
      ) : (
        <></>
      )}
    </Container>
  )
}