import React from 'react'
import { Box, Heading } from 'grommet'
import styled from 'styled-components'
import IsActiveButton from '../../molecules/RetailersBench/IsActiveButton'

interface Props {
  title: string
  icon: JSX.Element
  isRetailerActive: boolean
  retailerId?: string
}

const RetailerBenchApplicationsTitle = ({ title, icon, isRetailerActive, retailerId }: Props) => (
  <Box margin={{ horizontal: 'medium', top: 'medium' }} direction="row" align="center" gap="small">
    {icon}
    <Title>{title}</Title>
    {retailerId ? <IsActiveButton isActive={isRetailerActive} retailerId={retailerId} /> : <></>}
  </Box>
)

const Title = styled(Heading)`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin: 0 1px;
  text-align: initial;
`

export default RetailerBenchApplicationsTitle
