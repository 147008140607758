import { Tag } from '@bit/ume.design-kit.ui'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Text } from 'grommet'
import { Gallery } from 'grommet-icons'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { IMAGE_LABEL_TEXT } from '../../common/assets/utils/image-label-text'
import ApplicationsTitle from '../../molecules/Applications/ApplicationsTitle'
import BorrowerImagesModal from '../../molecules/BorrowerImagesModal/BorrowerImagesModal'
import DetailsCard, { IDetailsCardData } from '../../molecules/RetailersPage/DetailsCard'
import { CardStyle } from '../../molecules/RetailersPage/styles'
import { ApplicationsSliceReducer } from '../../redux/reducers/applications/applications.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { ProcessStatus } from '../../services/bff-backoffice/datalake-acesso/interfaces/datalake-acesso.interfaces'

const ImagesCard = () => {
  const dispatch = useDispatch()
  const {
    application,
    borrowerFaceImage,
    isLoadingBorrowerFaceImage,
    fetchBorrowerFaceImageError,
    borrowerDocumentImages,
    isLoadingBorrowerDocumentImages,
    fetchBorrowerDocumentImagesError,
    biometryStatus,
    isLoadingBiometryStatus,
    fetchBiometryStatusError,
  } = useTypedSelector(state => ({
    application: state.applications.application,
    borrowerFaceImage: state.applications.borrowerFaceImage,
    isLoadingBorrowerFaceImage: state.applications.isLoadingBorrowerFaceImage,
    fetchBorrowerFaceImageError: state.applications.fetchBorrowerFaceImageError,
    borrowerDocumentImages: state.applications.borrowerDocumentImages,
    isLoadingBorrowerDocumentImages: state.applications.isLoadingBorrowerDocumentImages,
    fetchBorrowerDocumentImagesError: state.applications.fetchBorrowerDocumentImagesError,
    biometryStatus: state.applications.biometryStatus,
    isLoadingBiometryStatus: state.applications.isLoadingBiometryStatus,
    fetchBiometryStatusError: state.applications.fetchBiometryStatusError,
  }))

  useEffect(() => {
    if (application) {
      dispatch(ApplicationsSliceReducer.actions.fetchBorrowerFaceImage(application.borrowerId))
      dispatch(ApplicationsSliceReducer.actions.fetchBorrowerDocumentImages(application.borrowerId))
      dispatch(ApplicationsSliceReducer.actions.fetchBiometryStatus(application.borrowerId))
    }
  }, [application, dispatch])

  // Unlikely to occur
  if (!application) return <></>

  const Content = () => {
    if (isLoadingBorrowerFaceImage || isLoadingBorrowerDocumentImages) {
      return (
        <CardStyle>
          <LoadingIcon />
        </CardStyle>
      )
    }

    if (
      fetchBorrowerFaceImageError ||
      !borrowerFaceImage ||
      fetchBorrowerDocumentImagesError ||
      !borrowerDocumentImages
    ) {
      return (
        <>
          <CardStyle>
            <Text color="red">Não foi possível carregar as imagens do cliente.</Text>
          </CardStyle>
        </>
      )
    }

    const formatBiometryStatus = () => {
      if (isLoadingBiometryStatus) {
        return <LoadingIcon />
      }

      if (fetchBiometryStatusError || !biometryStatus) {
        return (
          <Text size="16px" color={UMEColors.red.persian}>
            Erro ao carregar status
          </Text>
        )
      }

      switch (biometryStatus.status) {
        case ProcessStatus.COMPLETED:
          return <StatusTag disable backgroundColor={UMEColors.green.dark} label={'Completa'} />
        case ProcessStatus.CONFLICT:
          return <StatusTag disable backgroundColor={UMEColors.orange.amber} label={'Em conflito'} />
        case ProcessStatus.SPOOF:
          return <StatusTag disable backgroundColor={UMEColors.orange.amber} label={'Foto de foto'} />
        case ProcessStatus.CANCELED:
          return <StatusTag disable backgroundColor={UMEColors.red.persian} label={'Cancelada'} />
        case ProcessStatus.IN_PROGRESS:
          return <StatusTag disable backgroundColor={UMEColors.yellow.citrine} label={'Em progresso'} />
        default:
          return <StatusTag disable backgroundColor={UMEColors.red.persian} label={'Erro'} />
      }
    }

    const formatBiometryScore = () => {
      if (isLoadingBiometryStatus) {
        return <LoadingIcon />
      }

      if (fetchBiometryStatusError || !biometryStatus) {
        return (
          <Text size="16px" color={UMEColors.red.persian}>
            Erro ao carregar score
          </Text>
        )
      }

      return biometryStatus.score ? biometryStatus.score : '-'
    }

    const formatHadConflict = () => {
      if (isLoadingBiometryStatus) {
        return <LoadingIcon />
      }

      if (fetchBiometryStatusError || !biometryStatus) {
        return (
          <Text size="16px" color={UMEColors.red.persian}>
            Erro ao carregar dados sobre conflito
          </Text>
        )
      }

      return biometryStatus.hadConflict ? (
        <Text size="16px" color={UMEColors.red.persian}>
          Sim
        </Text>
      ) : (
        <Text size="16px" color={UMEColors.green.dark}>
          Não
        </Text>
      )
    }

    const data: IDetailsCardData[] = [
      {
        label: IMAGE_LABEL_TEXT[borrowerFaceImage.label],
        value: `data:image/jpeg;base64,${borrowerFaceImage.imagebase64}`,
        image: true,
      },
      ...borrowerDocumentImages.images.map(image => ({
        label: IMAGE_LABEL_TEXT[image.label],
        value: `data:image/jpeg;base64,${image.imagebase64}`,
        image: true,
      })),
      {
        label: 'Status',
        value: formatBiometryStatus(),
        handleEditionClick: () =>
          dispatch(ApplicationsSliceReducer.actions.fetchBiometryStatus(application.borrowerId)),
        editionText: 'atualizar',
      },
      {
        label: 'Score',
        value: formatBiometryScore(),
      },
      {
        label: 'Houve conflito?',
        value: formatHadConflict(),
      },
    ]

    return <DetailsCard data={data} />
  }

  return (
    <React.Fragment>
      <ApplicationsTitle
        title="Biometria"
        icon={<Gallery size="25px" color={UMEColors.purple.primary} />}
        modal={<BorrowerImagesModal borrowerId={application.borrowerId} />}
      />
      <Content />
    </React.Fragment>
  )
}

export const StatusTag = styled(Tag)`
  padding: 4px 6px;
  height: 22px;
  font-weight: 500;
  line-height: 14px;
`

export default ImagesCard
