import { Anchor, Breadcrumbs, Button, Clipboard, Heading, RichTabTitle, Tabs } from '@bit/ume.design-kit.ui'
import { orange, purple, violet } from '@bit/ume.design-kit.ui/Palette'
import { blue } from '@bit/ume.design-kit.ui/utils/_colors'
import { Box } from 'grommet'
import { Columns, ContactInfo, FormPreviousLink, Note, PowerReset } from 'grommet-icons'
import * as React from 'react'
import { toaster } from '../../App'
import { isEditBorrowerAllowed } from '../../config/config'
import BorrowerEditionModal from '../../molecules/BorrowerEditionModal/BorrowerEditionModal'
import BorrowerImagesModal from '../../molecules/BorrowerImagesModal/BorrowerImagesModal'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { IPhoneResponse } from '../../services/bff-backoffice/borrowers/interfaces/borrower.interfaces'
import ContractsService from '../../services/bff-backoffice/contracts-service/contracts.service'
import {
  ICSContract,
  ICSContracts,
} from '../../services/bff-backoffice/contracts-service/interfaces/contracts.res.types'
import { IBorrowerLimit } from '../../services/bff-backoffice/coordinator/interfaces/borrower.interfaces'
import { IOriginationSummary } from '../../services/bff-backoffice/coordinator/interfaces/contracts.interfaces'
import {
  ChargeFeeInstallment,
  InstallmentTypeEnum,
  INSTALLMENT_STATUS,
  ISingleInstallment,
} from '../../services/bff-backoffice/coordinator/interfaces/installment.interfaces'
import { IInvoice } from '../../services/bff-backoffice/coordinator/interfaces/invoice.interfaces'
import { IRenegotiationResponse } from '../../services/bff-backoffice/coordinator/interfaces/renegotiation.interfaces'
import {
  IRetailerResponse,
  IStoreResponse,
} from '../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'
import { resolvePurchaseReceiptRoute } from '../Receipts/Utils'
import ContractsCard from './ContractsCard/ContractsCard'
import ContractsSummaryCard from './ContractsSummaryCard/ContractsSummaryCard'
import { CardStyle } from './ContractsSummaryCard/style'
import { summaryTextToCopy } from './ContractsSummaryCard/summaryText'
import InstallmentsCard from './InstallmentsCard/InstallmentsCard'
import { HelperTextStyle } from './InstallmentsCard/style'
import { InvoicesCard } from './InvoicesCard/InvoicesCard'
import LimitsCard from './LimitsCard/LimitsCard'
import RegistrationDataCard, { BorrowerSettingsProps } from './RegistrationDataCard/RegistrationDataCard'
import RenegotiationsCard from './RenegotiationsCard/RenegotiationsCard'
import {
  BarcodeIconStyle,
  BorrowerProfileBox,
  CabinetBaseIconIcon,
  PreviousButtonStyle,
  SectionTitleStyle,
  TabStyle,
  UnderlinedText,
} from './style'
import { mapBorrowerSettingsProps } from './utils/mappers'

const moment = require('moment')

export type CONTRACT_STATUS = 'PENDING' | 'OVERDUE' | 'PAID' | 'RENEGOTIATED' | 'CANCELED'

export interface IBorrowerSummaryProfile {
  borrowerId: string
  createdOn: string
  cpf: string
  name: string
  email?: string
  birthDate: string
  age: number
  phoneNumber?: string
  portraitBase64: string
  enableHighRecurrence: boolean
  phones?: IPhoneResponse[]
  borrowerSettings: BorrowerSettingsProps
}

export interface IBorrowerProfilePageProps {
  history?: any
  match?: any
  location?: any
  previousPage?: string
}

export interface IBorrowerProfilePageState {
  borrowerId?: string
  stores: IStoreResponse[]
  renegotiations?: IRenegotiationResponse[]
  retailers: IRetailerResponse[]
  selectedRetailerId?: string
  installmentsStatus?: INSTALLMENT_STATUS
  contractsStatus?: CONTRACT_STATUS
  limits?: IBorrowerLimit[] | Error
  contractsSummary?: IOriginationSummary | Error
  showContractsSummary: boolean
  borrowerSummary?: IBorrowerSummaryProfile | Error
  installments?: ISingleInstallment[] | Error
  feeInstallments?: ChargeFeeInstallment[] | Error
  feesPaymentDueTotalValue?: number
  contracts?: IContractUI[] | Error
  invoices?: IInvoice[] | Error
}

export interface IContractUI extends ICSContract {
  storeName: string
  retailerName: string
  purchaseReceiptRenderUri: string
}

const initialState: IBorrowerProfilePageState = {
  retailers: [],
  stores: [],
  installmentsStatus: undefined,
  contractsStatus: undefined,
  showContractsSummary: false,
}

export default class BorrowerProfilePage extends React.Component<IBorrowerProfilePageProps, IBorrowerProfilePageState> {
  private readonly borrowerId: string

  constructor(props: IBorrowerProfilePageProps) {
    super(props)

    this.state = { ...initialState }
    this.borrowerId = this.props.match.params.borrowerId
  }

  componentDidMount() {
    const borrowerId = this.borrowerId
    this.setState({ borrowerId })

    this.fetchBorrowerSummaryProfile(borrowerId)
    this.fetchAllBorrowerLimits(borrowerId)
  }

  componentDidUpdate(prevProps: IBorrowerProfilePageProps, prevState: IBorrowerProfilePageState) {
    if (this.state.installmentsStatus !== prevState.installmentsStatus) {
      this.setState({ installments: initialState.installments })

      const { borrowerId, installmentsStatus } = this.state
      if (borrowerId) {
        this.loadInstallments(borrowerId, installmentsStatus)
      }
    }
  }

  loadInstallments = (borrowerId: string, status?: INSTALLMENT_STATUS) => {
    const params = { borrowerId, status }

    bffBackofficeApiService.coordinator
      .getContractsInstallments(params)
      .then(data => {
        this.setState({ installments: data.installments })
      })
      .catch(() => {
        this.setState({ installments: new Error() })
      })
  }

  loadFees = (borrowerId: string) => {
    bffBackofficeApiService.coordinator
      .getInstallmentsFees(borrowerId)
      .then(data => {
        const feeInstallments = data
        const feesPaymentDueTotalValue =
          feeInstallments?.reduce((totalValue, feeInstallment) => totalValue + feeInstallment.paymentDue, 0) ?? 0
        this.setState({ feeInstallments, feesPaymentDueTotalValue })
      })
      .catch(() => {
        this.setState({ feeInstallments: new Error() })
      })
  }

  loadInstallmentsTabContent = () => {
    this.loadInstallments(this.borrowerId, this.state.installmentsStatus)
    this.loadFees(this.borrowerId)
  }

  loadInvoices = (borrowerId: string) => {
    bffBackofficeApiService.coordinator
      .getInvoices({ borrowerId })
      .then(invoices => {
        this.setState({ invoices })
      })
      .catch(() => {
        this.setState({ invoices: new Error() })
      })
  }

  loadContracts = async (borrowerId: string, status?: CONTRACT_STATUS, retailerId?: string) => {
    const contracts: ICSContracts = await ContractsService.getCsContracts({
      status,
      borrowerId,
      retailerId,
      installmentType: InstallmentTypeEnum.ORIGINATION,
    })

    let retailers: IRetailerResponse[] = this.state.retailers
    let stores: IStoreResponse[] = this.state.stores

    if (this.state.retailers.length == 0) retailers = (await bffBackofficeApiService.retailers.getRetailers()).retailers
    if (this.state.stores.length == 0) stores = (await bffBackofficeApiService.retailers.getStores()).stores

    let contractsUi: IContractUI[] = []

    for (const contract of contracts.contracts) {
      const store = stores.filter(s => s.id === contract.storeId)[0]
      const retailer = retailers.filter(r => r.id === store.retailerId)[0]

      contractsUi = [
        ...contractsUi,
        {
          ...contract,
          storeName: store?.name || contract.storeId,
          retailerName: retailer?.name || ' - ',
          purchaseReceiptRenderUri: resolvePurchaseReceiptRoute(contract.id),
        },
      ]
    }

    this.setState({ retailers: retailers, stores: stores, contracts: contractsUi })
  }

  loadRenegotiations = async (borrowerId: string) => {
    const renegotiations = await bffBackofficeApiService.coordinator.getRenegotiations(borrowerId)

    this.setState({
      renegotiations: renegotiations.renegotiations.sort((r1, r2) => r1.createdOn.localeCompare(r2.createdOn)),
    })
  }

  fetchAllBorrowerLimits = (borrowerId: string) => {
    bffBackofficeApiService.coordinator
      .getAllBorrowerLimits(borrowerId)
      .then(data => this.setState({ limits: data.limits }))
      .catch(() => this.setState({ limits: new Error() }))
  }

  fetchBorrowerSummaryProfile = async (borrowerId: string) => {
    let portrait
    let borrower

    try {
      ;[borrower, portrait] = await Promise.all([
        bffBackofficeApiService.coordinator.findBorrowerById(borrowerId),
        bffBackofficeApiService.borrowers.getBorrowerPortraitPhoto(borrowerId),
      ])

      if (borrower && borrower.name && borrower.birthDate) {
        const isBorrowerEnabledToPayAnywhere = await this.isBorrowerEnabledToPayAnywhere(borrowerId)
        const age = moment().diff(borrower.birthDate, 'years')
        this.setState({
          borrowerSummary: {
            createdOn: borrower.createdOn,
            cpf: borrower.cpf,
            name: borrower.name,
            email: borrower.email,
            birthDate: borrower.birthDate,
            phoneNumber: borrower.phoneNumber,
            borrowerId,
            portraitBase64: portrait.imagebase64,
            age,
            enableHighRecurrence: borrower.enableHighRecurrence,
            phones: borrower.phones,
            borrowerSettings: mapBorrowerSettingsProps({
              borrowerSettings: borrower.borrowerSettings,
              isBorrowerEnabledToPayAnywhere,
            }),
          },
        })
      } else {
        throw new Error('Incomplete borrower info')
      }
    } catch (err) {
      this.setState({ borrowerSummary: new Error() })
    }
  }

  handleShowContractsSummary = () => {
    const { contracts, installments } = this.state

    if (!contracts || contracts instanceof Error) {
      this.loadContracts(this.borrowerId, this.state.contractsStatus)
    }

    if (!installments || installments instanceof Error) {
      this.loadInstallmentsTabContent()
    }

    this.fetchOriginationsSummary(this.borrowerId)
  }

  fetchOriginationsSummary = (borrowerId: string) => {
    this.setState({ showContractsSummary: true })

    bffBackofficeApiService.coordinator
      .getContractsOriginationsSummary(borrowerId)
      .then(data => this.setState({ contractsSummary: data }))
      .catch(() => this.setState({ contractsSummary: new Error() }))
  }

  onInstallmentFilterSelected = (property: string, value: any) => {
    if (property === 'status') {
      if (value === 'Todos') {
        this.setState({ installmentsStatus: undefined })
      } else {
        const status = value as INSTALLMENT_STATUS
        this.setState({ installmentsStatus: status })
      }
    }
  }

  onContractFilterSelected = (property: string, value: any) => {
    const { borrowerId, contractsStatus, selectedRetailerId } = this.state

    if (property === 'status') {
      if (value === 'all') {
        borrowerId && this.loadContracts(borrowerId, undefined, selectedRetailerId)
        this.setState({ contractsStatus: undefined })
      } else {
        const status = value as CONTRACT_STATUS
        borrowerId && this.loadContracts(borrowerId, status, selectedRetailerId)
        this.setState({ contractsStatus: status })
      }
    }

    if (property === 'retailerName') {
      if (value === 'all') {
        borrowerId && this.loadContracts(borrowerId, contractsStatus, undefined)
        this.setState({ selectedRetailerId: undefined })
      } else {
        borrowerId && this.loadContracts(borrowerId, contractsStatus, value)
        this.setState({ selectedRetailerId: value })
      }
    }
  }

  onNovoAcordoClick = () => {
    this.props.history.push(`/borrowers/${this.state.borrowerId}/renegotiation`)
  }

  onBorrowerEditionSubmit = (body: { name?: string; email?: string; birthDate?: string }) => {
    const { borrowerId } = this.state

    if (borrowerId) {
      bffBackofficeApiService.coordinator
        .putBorrower(borrowerId, body)
        .then(() => {
          this.fetchBorrowerSummaryProfile(borrowerId)
          toaster.showSuccessToast('Cadastro atualizado com sucesso')
        })
        .catch(() => {
          toaster.showErrorToast('Não foi possível atualizar o cadastro')
        })
    }
  }

  onBorrowerPhoneEditionSubmit = (body: { number: string }) => {
    const { borrowerId } = this.state
    if (borrowerId) {
      bffBackofficeApiService.borrowers
        .createBorrowerPhoneNumber(borrowerId, body.number)
        .then(() => {
          this.fetchBorrowerSummaryProfile(borrowerId)
          toaster.showSuccessToast('Cadastro atualizado com sucesso')
        })
        .catch(() => {
          toaster.showErrorToast('Não foi possível atualizar o cadastro')
        })
    }
  }

  onPreviousLinkClick = () => {
    this.props.history.go(-1)
  }

  isBorrowerEnabledToPayAnywhere = async (borrowerId: string): Promise<boolean | undefined> => {
    try {
      return await bffBackofficeApiService.backoffice.isBorrowerEnabledToPayAnywhere(borrowerId)
    } catch (error) {
      toaster.showErrorToast('Não foi possível determinar se o cliente tem acesso ao Pix')
      return
    }
  }

  public render() {
    const {
      borrowerSummary,
      limits,
      contractsSummary,
      retailers,
      stores,
      installments,
      feeInstallments,
      contracts,
      renegotiations,
      invoices,
      feesPaymentDueTotalValue,
      showContractsSummary,
    } = this.state

    const { previousPage } = this.props

    const clipboardBox = borrowerSummary &&
      !(borrowerSummary instanceof Error) &&
      installments &&
      !(installments instanceof Error) &&
      contracts &&
      !(contracts instanceof Error) &&
      limits &&
      !(limits instanceof Error) &&
      contractsSummary &&
      !(contractsSummary instanceof Error) && (
        <div title="Copiar resumo" onClick={() => toaster.showSuccessToast('Copiado para área de transferência')}>
          <Clipboard
            label={<span style={{ color: blue.navy }}>Copiar resumo</span>}
            value={summaryTextToCopy(borrowerSummary, installments, contracts, limits, contractsSummary)}
          />
        </div>
      )

    const hasOpenRenegotiations =
      installments &&
      !(installments instanceof Error) &&
      installments.filter(i => i.inRenegotiation && !i.paymentTimestamp).length > 0

    const hasOverdueInstallments =
      installments &&
      !(installments instanceof Error) &&
      installments.filter(i => i.status == 'PAYMENT_OVERDUE').length > 0

    const borrowerEditionModal = borrowerSummary && !(borrowerSummary instanceof Error) && (
      <BorrowerEditionModal
        borrowerSummary={borrowerSummary}
        onBorrowerEditionSubmit={this.onBorrowerEditionSubmit}
        onBorrowerPhoneEditionSubmit={this.onBorrowerPhoneEditionSubmit}
      />
    )

    return (
      <BorrowerProfileBox fill>
        <Box margin={{ horizontal: 'medium', top: 'small' }}>
          <Breadcrumbs anchors={['Home', previousPage ? previousPage : 'Priorização', borrowerSummary?.name]} />
        </Box>

        <Box margin={{ horizontal: 'small', vertical: 'medium' }} direction="row" align="center" gap="xsmall">
          <PreviousButtonStyle
            onClick={this.onPreviousLinkClick}
            width="32px"
            height="32px"
            icon={<FormPreviousLink color="white" />}
          />
          <Heading size="12px">Voltar</Heading>
        </Box>

        <Box gap="medium">
          <Box direction="row-responsive" gap="medium" style={{ flexWrap: 'wrap' }}>
            <Box style={{ width: '100%' }}>
              <Box margin={{ horizontal: 'medium' }} direction="row" align="center" gap="small">
                <ContactInfo size="25px" color={orange.amber} />
                <SectionTitleStyle>Perfil cadastral</SectionTitleStyle>
                {isEditBorrowerAllowed && borrowerEditionModal}
                {borrowerSummary && !(borrowerSummary instanceof Error) && borrowerSummary.borrowerId && (
                  <BorrowerImagesModal borrowerId={borrowerSummary.borrowerId} />
                )}
              </Box>
              <RegistrationDataCard borrowerSummary={borrowerSummary} />
            </Box>
          </Box>

          <Box direction="row-responsive" gap="medium">
            <Box style={{ maxWidth: '680px', flex: 1 }}>
              <Box margin={{ horizontal: 'medium' }} direction="row" align="center" gap="small">
                <Note size="20px" color={purple.primary} />
                <SectionTitleStyle>Resumo</SectionTitleStyle>
                {clipboardBox}
              </Box>
              {showContractsSummary ? (
                <ContractsSummaryCard contractsSummary={contractsSummary} />
              ) : (
                <CardStyle>
                  <Box direction="row" align="center" justify="center" height="100%">
                    <Anchor onClick={this.handleShowContractsSummary}>
                      <Box direction="row" gap="xsmall" align="center">
                        <UnderlinedText>Carregar dados</UnderlinedText> <PowerReset />
                      </Box>
                    </Anchor>
                  </Box>
                </CardStyle>
              )}
            </Box>
            <Box>
              <Box
                margin={{ horizontal: 'medium' }}
                direction="row"
                align="center"
                gap="small"
                style={{ width: '100%' }}
              >
                <Columns size="20px" color={violet.primary} />
                <SectionTitleStyle>
                  Limite disponível {(hasOpenRenegotiations || hasOverdueInstallments) && '*'}
                </SectionTitleStyle>
              </Box>
              <LimitsCard limits={limits} />
              {hasOpenRenegotiations && (
                <HelperTextStyle style={{ color: 'red', fontWeight: 'bold' }}>
                  * EXISTEM RENEGOCIAÇÕES EM ABERTO.
                </HelperTextStyle>
              )}
              {hasOverdueInstallments && (
                <HelperTextStyle style={{ color: 'red', fontWeight: 'bold' }}>* HÁ PARCELAS COM ATRASO</HelperTextStyle>
              )}
            </Box>
          </Box>

          <Box>
            <Tabs justify="start">
              <TabStyle
                margin={{ left: 'medium' }}
                className="installments"
                title={<RichTabTitle icon={<BarcodeIconStyle />} label={'Parcelas'} />}
              >
                <InstallmentsCard
                  history={this.props.history}
                  onFilterSelected={this.onInstallmentFilterSelected}
                  installments={installments}
                  feeInstallments={feeInstallments}
                  feesTotalPaymentDue={feesPaymentDueTotalValue}
                  onMounted={() => {
                    if (this.state.installments !== undefined) return
                    this.loadInstallmentsTabContent()
                  }}
                />
              </TabStyle>
              <TabStyle
                className="contracts"
                title={<RichTabTitle icon={<CabinetBaseIconIcon />} label={'Contratos'} />}
              >
                <ContractsCard
                  retailers={retailers}
                  stores={stores}
                  onFilterSelected={this.onContractFilterSelected}
                  contracts={contracts}
                  onMounted={() => {
                    if (this.state.contracts !== undefined) return
                    this.loadContracts(this.borrowerId, this.state.contractsStatus)
                  }}
                />
              </TabStyle>
              <TabStyle
                className="renegotiations"
                title={<RichTabTitle icon={<CabinetBaseIconIcon />} label={'Acordos'} />}
              >
                <RenegotiationsCard
                  history={this.props.history}
                  renegotiations={renegotiations}
                  onMounted={() => {
                    if (this.state.renegotiations !== undefined) return
                    this.loadRenegotiations(this.borrowerId)
                  }}
                />
                <Box direction="row" align="baseline" justify="between">
                  <Button style={{ margin: '10 25px 0 0', width: '150px' }} onClick={() => this.onNovoAcordoClick()}>
                    NOVO ACORDO
                  </Button>
                </Box>
              </TabStyle>
              <TabStyle className="invoices" title={<RichTabTitle icon={<BarcodeIconStyle />} label={'Boletos'} />}>
                <InvoicesCard
                  history={this.props.history}
                  match={this.props.match}
                  location={this.props.location}
                  invoices={invoices}
                  onMounted={() => {
                    if (this.state.invoices !== undefined) return
                    this.loadInvoices(this.borrowerId)
                  }}
                />
              </TabStyle>
            </Tabs>
          </Box>
        </Box>
      </BorrowerProfileBox>
    )
  }
}
