import { Button, DataTable } from '@bit/ume.design-kit.ui'
import { Modal } from 'antd'
import moment from 'moment'
import * as React from 'react'
import { toaster } from '../../App'
import MaskedInput from '../../molecules/RetailersPage/MaskedInput'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { ISingleInstallment } from '../../services/bff-backoffice/coordinator/interfaces/installment.interfaces'
import {
  IDebtRenegotiationRequest,
  IRenegotiationResponse,
} from '../../services/bff-backoffice/coordinator/interfaces/renegotiation.interfaces'
import { formatNumberToBRL } from '../../utils'
import RenegotiationDetails from '../RenegotiationDetails/RenegotiationDetails'
import { translateErrors } from './errors'
import FormError from './FormError'
import { DateMaskInput, FloatMaskInput, NumberOfInstallmentsMask } from './inputmasks'
import { renegotiationInstallmentsTableConfig } from './installments-table-config'
import { ContractsRenegotiationPageContainer, InputContainer, TableBoxStyle } from './styles'

interface IBorrowerRenegotiationProps {
  history?: any
  match?: any
  location?: any
  borrowerId: string
}

interface IBorrowerRenegotiationState {
  totalDebt: number // sum of all installments present values, used for presentation only
  upfrontPaymentDueDate: string // date for upfront payment
  firstInstallmentDueDate: string // date for first installment
  renegotiationSimulation?: IRenegotiationResponse // the new renegotiation contract. until a "accept" transactions in one of its proposals, it has no installments. that means that getContractById with its id will fail
  upfrontPayment?: string // form upfront payment value
  interestRate?: string // interestRate
  numberOfInstallments: string // numberOfPayments
  discount?: string // form discount value
  installments?: ISingleInstallment[]
  borrowerId: string
  error?: { error: string; message?: string }
  selectedInstallmentIds: string[]
  showConfirmationDialog: boolean
  executingRenegotiation: boolean
}

export default class BorrowerRenegotiation extends React.Component<
  IBorrowerRenegotiationProps,
  IBorrowerRenegotiationState
> {
  public readonly state: IBorrowerRenegotiationState = {
    totalDebt: 0,
    firstInstallmentDueDate: '',
    upfrontPaymentDueDate: '',
    interestRate: '',
    numberOfInstallments: '',
    upfrontPayment: '',
    discount: '',
    borrowerId: '',
    selectedInstallmentIds: [],
    showConfirmationDialog: false,
    executingRenegotiation: false,
  }

  componentDidMount() {
    this.setState({ borrowerId: this.props.borrowerId })
    this.fetchContractsByBorrowerId(this.props.borrowerId)
  }

  onInstallmentToggle = (installmentId: string) => {
    // removing
    if (this.state.selectedInstallmentIds.includes(installmentId)) {
      this.setState({
        selectedInstallmentIds: this.state.selectedInstallmentIds.filter(id => id !== installmentId),
        renegotiationSimulation: undefined,
      })
    } else {
      // adding
      this.setState({
        selectedInstallmentIds: [...this.state.selectedInstallmentIds, ...[installmentId]],
        renegotiationSimulation: undefined,
      })
    }
  }

  isCheckedInstallment = (installmentId: string): boolean => {
    return !!this.state.selectedInstallmentIds.filter(i => i === installmentId).length
  }

  onUpfrontValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || !isNaN(parseFloat(e.target.value))) {
      this.setState({
        upfrontPayment: e.target.value,
        renegotiationSimulation: undefined,
      })
    }
  }

  onInterestRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || !isNaN(parseFloat(e.target.value))) {
      this.setState({
        interestRate: e.target.value,
        renegotiationSimulation: undefined,
      })
    }
  }

  onNumberOfInstallmentsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || !isNaN(parseInt(e.target.value))) {
      this.setState({
        numberOfInstallments: e.target.value,
        renegotiationSimulation: undefined,
      })
    }
  }

  onDiscountValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || !isNaN(parseFloat(e.target.value))) {
      this.setState({
        discount: e.target.value,
        renegotiationSimulation: undefined,
      })
    }
  }

  onUpfrontDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      upfrontPaymentDueDate: e.currentTarget.value,
      renegotiationSimulation: undefined,
    })
  }

  onErrorTimeout = () => {
    this.setState({
      error: undefined,
      renegotiationSimulation: undefined,
    })
  }

  simulate = async () => {
    const {
      upfrontPaymentDueDate,
      upfrontPayment,
      firstInstallmentDueDate,
      discount,
      selectedInstallmentIds,
      interestRate,
      numberOfInstallments,
    } = this.state

    this.setState({
      renegotiationSimulation: undefined,
    })

    const request: IDebtRenegotiationRequest = {
      borrowerId: this.state.borrowerId,
      installmentIds: selectedInstallmentIds,
      firstPaymentDueDate: firstInstallmentDueDate
        ? moment(firstInstallmentDueDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : undefined,
      upfrontPaymentDueDate: upfrontPaymentDueDate
        ? moment(upfrontPaymentDueDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : undefined,
      upfrontPayment: upfrontPayment ? parseFloat(upfrontPayment) : undefined,
      monetaryDiscount: discount ? parseFloat(discount) : undefined,
      numberOfPayments: numberOfInstallments ? parseInt(numberOfInstallments) : undefined,
      interestRate: interestRate ? Number((parseFloat(interestRate) / 100).toFixed(4)) : undefined,
    }

    try {
      const simulation = await bffBackofficeApiService.coordinator.simulateRenegotiation(request)

      this.setState({
        renegotiationSimulation: simulation,
      })
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        this.setState({
          error: {
            error: translateErrors(err.response.data.error),
            message: err.response.data.message ? err.response.data.message : JSON.stringify(err.response.data),
          },
        })
      } else {
        if (!err.status) {
          this.setState({
            error: {
              error: 'Cheque sua conexao com a internet.',
            },
          })
        }
        throw err
      }
    }
  }

  execute = async () => {
    const {
      upfrontPaymentDueDate,
      upfrontPayment,
      firstInstallmentDueDate,
      discount,
      selectedInstallmentIds,
      interestRate,
      numberOfInstallments,
    } = this.state

    this.setState({
      executingRenegotiation: true,
    })

    const request: IDebtRenegotiationRequest = {
      borrowerId: this.state.borrowerId,
      installmentIds: selectedInstallmentIds,
      firstPaymentDueDate: firstInstallmentDueDate
        ? moment(firstInstallmentDueDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : undefined,
      upfrontPaymentDueDate: upfrontPaymentDueDate
        ? moment(upfrontPaymentDueDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : undefined,
      upfrontPayment: upfrontPayment ? parseFloat(upfrontPayment) : undefined,
      monetaryDiscount: discount ? parseFloat(discount) : undefined,
      numberOfPayments: numberOfInstallments ? parseInt(numberOfInstallments) : undefined,
      interestRate: interestRate ? Number((parseFloat(interestRate) / 100).toFixed(4)) : undefined,
    }

    try {
      const renegotiation = await bffBackofficeApiService.coordinator.renegotiate(request)

      this.props.history.push(`/renegotiations/${renegotiation.id}`)
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        this.setState({
          error: {
            error: translateErrors(err.response.data.error),
            message: err.response.data.message ? err.response.data.message : JSON.stringify(err.response.data),
          },
        })
      } else {
        if (!err.status) {
          this.setState({
            error: {
              error: 'Cheque sua conexao com a internet.',
            },
          })
        }
        throw err
      }
    }
  }

  askForConfirmation = () => {
    this.setState({
      showConfirmationDialog: true,
    })
  }

  onConfirm = async () => {
    if (this.state.renegotiationSimulation) {
      await this.execute()
    }
  }

  onCancel = () => {
    this.setState({
      renegotiationSimulation: undefined,
      error: undefined,
      showConfirmationDialog: false,
    })
  }

  onFirstInstallmentDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      firstInstallmentDueDate: e.currentTarget.value,
      renegotiationSimulation: undefined,
    })
  }

  fetchContractsByBorrowerId = async (borrowerId: string) => {
    try {
      const contracts = await bffBackofficeApiService.coordinator.getContracts({
        borrowerId: borrowerId,
      })

      let installments = contracts.contracts
        .map(c => c.installments)
        .reduce((i1, i2) => [...i1, ...i2], [])
        .filter(i => i.status === 'PENDING' || i.status === 'PAYMENT_OVERDUE')

      // all paid first, ordered by due date
      // all pending last, ordered by due date
      installments = installments.sort(
        (i1, i2) => i1.dueDate.localeCompare(i2.dueDate) || i1.createdOn.localeCompare(i2.createdOn)
      )

      // computes the sum of present values for all unpaid installments
      const currentDebt = installments
        .map(i => {
          if (i.paymentTimestamp) {
            return 0
          } else {
            return i.status === 'PAYMENT_OVERDUE'
              ? i.expectedValueOnDate - (i.paidValue ? i.paidValue : 0)
              : i.installmentValue - (i.paidValue ? i.paidValue : 0)
          }
        })
        .reduce((i1, i2) => i1 + i2, 0)

      const selectedInstallmentIds: string[] = []
      installments.map(i => (i.status !== 'PAID' ? selectedInstallmentIds.push(i.id) : null))

      this.setState({
        installments,
        totalDebt: currentDebt,
        selectedInstallmentIds,
      })
    } catch (err) {
      toaster.showErrorToast('Pessoa não encontrada')
    }
  }

  public render() {
    const {
      totalDebt: currentDebt,
      upfrontPayment,
      firstInstallmentDueDate,
      upfrontPaymentDueDate: upfrontPaymentDate,
      discount,
      installments,
      renegotiationSimulation,
      error,
      selectedInstallmentIds,
      numberOfInstallments,
      interestRate,
      executingRenegotiation,
      showConfirmationDialog,
    } = this.state

    if (!installments) {
      return (
        <ContractsRenegotiationPageContainer>
          <h3>Carregando</h3>
        </ContractsRenegotiationPageContainer>
      )
    }

    const debtInRenegotiation = installments
      ?.filter(i => selectedInstallmentIds.includes(i.id))
      .map(i => {
        if (i.paymentTimestamp) {
          return 0
        } else {
          return i.status === 'PAYMENT_OVERDUE'
            ? i.expectedValueOnDate - (i.paidValue ? i.paidValue : 0)
            : i.installmentValue - (i.paidValue ? i.paidValue : 0)
        }
      })
      .reduce((i1, i2) => i1 + i2, 0)

    return (
      <ContractsRenegotiationPageContainer>
        <TableBoxStyle>
          <DataTable
            fontSize="14px"
            data={installments}
            header={renegotiationInstallmentsTableConfig(this.onInstallmentToggle, this.isCheckedInstallment)}
          />
        </TableBoxStyle>
        <h2 style={{ textAlign: 'right', marginRight: '1em' }}>Total da dívida: {formatNumberToBRL(currentDebt)}</h2>
        <h2 style={{ textAlign: 'right', marginRight: '1em' }}>
          Renegociando: {formatNumberToBRL(debtInRenegotiation ? debtInRenegotiation : 0)}
        </h2>

        <h2 style={{ textAlign: 'left', marginLeft: '1em' }}>Renegocie:</h2>

        <InputContainer>
          <MaskedInput
            labelBackgroundColor="#f5f6f6"
            label="Desconto"
            key={1}
            mask={FloatMaskInput}
            name={'formDiscount'}
            placeholder={'0.00'}
            onChange={this.onDiscountValueChange}
            value={discount}
          />
          <MaskedInput
            labelBackgroundColor="#f5f6f6"
            label="Entrada"
            key={2}
            mask={FloatMaskInput}
            name={'formUpfrontValue'}
            placeholder={'0.00'}
            onChange={this.onUpfrontValueChange}
            value={upfrontPayment}
          />
          <MaskedInput
            labelBackgroundColor="#f5f6f6"
            label="Data entrada"
            key={3}
            mask={DateMaskInput}
            name={'formUpfrontDate'}
            placeholder={'DD/MM/YYYY'}
            onChange={this.onUpfrontDateChange}
            value={upfrontPaymentDate}
          />
          <MaskedInput
            labelBackgroundColor="#f5f6f6"
            label="Vcto parcela"
            key={4}
            mask={DateMaskInput}
            name={'formFirstInstallmentDueDate'}
            placeholder={'DD/MM/YYYY'}
            onChange={this.onFirstInstallmentDateChange}
            value={firstInstallmentDueDate}
          />
          <MaskedInput
            labelBackgroundColor="#f5f6f6"
            label="N parcelas"
            key={5}
            mask={NumberOfInstallmentsMask}
            name={'formNumberOfInstallments'}
            placeholder={'1'}
            onChange={this.onNumberOfInstallmentsChange}
            value={numberOfInstallments}
          />
          <MaskedInput
            labelBackgroundColor="#f5f6f6"
            label="Tx de juros"
            key={6}
            mask={FloatMaskInput}
            name={'formInterestRate'}
            placeholder={'0.00'}
            onChange={this.onInterestRateChange}
            value={interestRate}
          />
          <Button style={{ margin: '10 25px 0 0', width: '150px' }} onClick={this.simulate}>
            SIMULAR
          </Button>
        </InputContainer>
        {error && (
          <FormError onErrorTimeout={this.onErrorTimeout} timeout={8000} error={error.error} details={error.message} />
        )}
        {renegotiationSimulation && <h2 style={{ textAlign: 'left', marginLeft: '1em' }}>Simulação:</h2>}
        {renegotiationSimulation && [
          <RenegotiationDetails renegotiation={renegotiationSimulation} />,
          <Button style={{ margin: '10 25px 0 0', width: '150px' }} onClick={this.askForConfirmation}>
            EFETIVAR
          </Button>,
        ]}
        <Modal
          title="VOCÊ TEM CERTEZA?"
          width="80vw"
          visible={showConfirmationDialog}
          onOk={this.onConfirm}
          confirmLoading={executingRenegotiation}
          onCancel={this.onCancel}
        >
          <RenegotiationDetails renegotiation={renegotiationSimulation} />
        </Modal>
      </ContractsRenegotiationPageContainer>
    )
  }
}
