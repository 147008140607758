import { Card, Text } from '@bit/ume.design-kit.ui'
import { gray } from '@bit/ume.design-kit.ui/Palette'
import { Box } from 'grommet'
import styled from 'styled-components'

export const CardStyle = styled(Card)`
  border-radius: 8px;
  height: 156px;
  width: 680px;
  padding: 24px 32px;
`

export const LabelStyle = styled(Text)`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  color: ${gray.nobel};
  margin-bottom: 2px;
  margin-top: 0;
`

export const ContractSummaryDataBoxStyle = styled(Box)`
  margin: 0 8px 8px 8px;
`

export const InfoTextStyle = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: start;
`
