import { Card, Text } from '@bit/ume.design-kit.ui'
import { gray } from '@bit/ume.design-kit.ui/Palette'
import { Box } from 'grommet'
import styled from 'styled-components'

export const CardStyle = styled(Card)`
  border-radius: 8px;
  height: 188px;
  width: 680px;
  padding: 4px 10px;
`

export const LabelStyle = styled(Text)`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  color: ${gray.nobel};
  margin-bottom: 4px;
  margin-top: 0;
`
export const BorrowerDataBoxStyle = styled(Box)`
  margin-bottom: 20px;
`

type InfoTextStyleProps = { color?: string }
export const InfoTextStyle = styled(Text)<InfoTextStyleProps>`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  color: ${(p: InfoTextStyleProps) => (p.color ? p.color : 'inherit')};
`

export const PictureCardStyle = styled(Card)`
  height: 80px;
  width: 80px;
  border-radius: 4px;
  background-color: ${gray.gainsboro};
`

export const PictureLabelStyle = styled(Text)`
  color: ${gray.primary};
  font-size: 8px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 9px;
`
