import { Tab, Tabs } from 'grommet'
import moment from 'moment'
import React from 'react'
import { toaster } from '../../App'
import { FlexColumnContainer } from '../../atoms/Containers/style'
import { SubHeader } from '../../atoms/Texts/style'
import {
  ERROR_LOADING_APPLICATIONS_BY_STORE,
  ERROR_LOADING_APPLICATION_STATUS,
  ERROR_LOADING_ORIGINATIONS_ANALYSIS,
  ERROR_LOADING_ORIGINATIONS_SUMMARIES,
  ERROR_LOADING_RECENT_APPLICATIONS_BY_STORE,
} from '../../config/messages/errors'
import { GenericTable } from '../../molecules'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import {
  IApplicationsByStatusByStoresRecent,
  IApplicationsStatus,
  IGetOriginationsAnalysis,
  IOriginationSummary,
} from '../../services/bff-backoffice/backoffice-api/interfaces/backoffice.interfaces'
import { BACKEND_DATE_FORMAT } from '../../utils'
import {
  applicationsHeader,
  droppedContractsHeader,
  originationsAnalysisHeader,
  originationsSummaryHeader,
  recentApplicationsByStatusByStoreHeader,
} from './configs'
import { IBackofficeState } from './interfaces'
import { BackofficeContainer } from './styles'

const today = moment().format(BACKEND_DATE_FORMAT)

export default class AnalysisPage extends React.Component<any, IBackofficeState> {
  public readonly state: IBackofficeState

  constructor(props: any) {
    super(props)
    this.state = {
      applicationsStatus: null,
      originationsSummary: null,
      originationsAnalysis: null,
      droppedContracts: null,
      recentApplicationsByStore: null,
      applicationsByStore: null,
      activeTab: 0,
    }
  }

  refreshScreen = async () => {
    bffBackofficeApiService.backoffice
      .getApplicationStatus({ startDate: today })
      .then((data: IApplicationsStatus) => {
        data['TOTAL'] = Object.values(data).reduce((a: any, b: any) => a + b, 0)
        this.setState({ applicationsStatus: [data] })
      })
      .catch(() => toaster.showErrorToast(ERROR_LOADING_APPLICATION_STATUS))

    bffBackofficeApiService.backoffice
      .getOriginationsSummary({ startDate: today })
      .then((data: IOriginationSummary) => {
        this.setState({ originationsSummary: [data] })
      })
      .catch(() => toaster.showErrorToast(ERROR_LOADING_ORIGINATIONS_SUMMARIES))

    bffBackofficeApiService.backoffice
      .getOriginationsAnalysis({ startDate: today })
      .then((data: IGetOriginationsAnalysis) => {
        let array = [
          {
            type: 'Conversões',
            count: data.conversionsAgg.count,
            volume: data.conversionsAgg.volume,
          },
          {
            type: 'Primeira Compra',
            count: data.firstLimitsAgg.count,
            volume: data.firstLimitsAgg.volume,
          },
          {
            type: 'Recorrências',
            count: data.recurrencesAgg.count,
            volume: data.recurrencesAgg.volume,
          },
        ]
        this.setState({ originationsAnalysis: array })
      })
      .catch(() => toaster.showErrorToast(ERROR_LOADING_ORIGINATIONS_ANALYSIS))

    // Recent Applications By Status
    bffBackofficeApiService.backoffice
      .getRecentApplicationsByStatus({})
      .then((data: IApplicationsByStatusByStoresRecent[]) => {
        this.setState({ recentApplicationsByStore: data })
      })
      .catch(() => toaster.showErrorToast(ERROR_LOADING_RECENT_APPLICATIONS_BY_STORE))

    // Applications by Status by Stores
    bffBackofficeApiService.backoffice
      .getApplicationsByStatusByStores({ startDate: today })
      .then((data: IApplicationsByStatusByStoresRecent[]) => {
        this.setState({ applicationsByStore: data })
      })
      .catch(() => toaster.showErrorToast(ERROR_LOADING_APPLICATIONS_BY_STORE))
  }

  // TODO - See why component mounts twice
  componentDidMount = async () => {
    let activeTab = Number(localStorage.getItem('backoffice-tab'))

    this.setState({ activeTab })

    this.refreshScreen()
  }

  // TODO - Create a Tab Molecule with tab change saving on local storage
  onTabChange = (tabIndex: number) => {
    localStorage.setItem('backoffice-tab', String(tabIndex))
    this.setState({ activeTab: tabIndex })
  }

  render() {
    let {
      originationsSummary,
      originationsAnalysis,
      droppedContracts,
      applicationsStatus,
      recentApplicationsByStore,
      applicationsByStore,
      activeTab,
    } = this.state

    return (
      <>
        <h1> Backoffice - Análises </h1>

        <BackofficeContainer>
          <Tabs onActive={this.onTabChange} activeIndex={activeTab}>
            <Tab title="Applications - Hoje">
              <FlexColumnContainer>
                <h1> Dia: {moment(today).format('DD/MM/YYYY')} </h1>

                <SubHeader> Resumo dos Applications </SubHeader>
                <GenericTable data={applicationsStatus} header={applicationsHeader} />
                <hr />
                <SubHeader> Applications por loja </SubHeader>
                <GenericTable data={applicationsByStore} header={recentApplicationsByStatusByStoreHeader} />
                <hr />
                <SubHeader> Últimos 45 minutos </SubHeader>
                <GenericTable data={recentApplicationsByStore} header={recentApplicationsByStatusByStoreHeader} />
              </FlexColumnContainer>
            </Tab>
            <Tab title="Originações - Hoje">
              <h1> Dia: {moment(today).format('DD/MM/YYYY')} </h1>

              <FlexColumnContainer>
                <SubHeader> Resumo das Originações </SubHeader>
                <GenericTable data={originationsSummary} header={originationsSummaryHeader} />
                <br />

                {!!droppedContracts && (
                  <>
                    <SubHeader> Carrinhos Abandonados </SubHeader>
                    <GenericTable data={droppedContracts} header={droppedContractsHeader} />
                  </>
                )}

                <br />
                <SubHeader> Análises das Originações </SubHeader>
                <GenericTable data={originationsAnalysis} header={originationsAnalysisHeader} />
              </FlexColumnContainer>
            </Tab>
            {/* <Tab title="Relatórios">
              <Reports />
            </Tab> */}
          </Tabs>
        </BackofficeContainer>
      </>
    )
  }
}
