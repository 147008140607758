export enum SourceProduct {
  LOW_RECURRENCE = 'LOW_RECURRENCE', // Grandes compras
  MEDIUM_RECURRENCE = 'MEDIUM_RECURRENCE', // Crediarinho
  HIGH_RECURRENCE = 'HIGH_RECURRENCE', // Alta recorrência
  PAY_ANYWHERE = 'PAY_ANYWHERE', // Pix Rede aberta,
  PERSONAL_LOAN = 'PERSONAL_LOAN', // Empréstimo pessoal
  TOP_UP = 'TOP_UP', // Gift Card e Recarga
  WORKING_CAPITAL = 'WORKING_CAPITAL', // Crédito Semanal
}

export type SourceProductType =
  | SourceProduct.LOW_RECURRENCE
  | SourceProduct.MEDIUM_RECURRENCE
  | SourceProduct.HIGH_RECURRENCE
  | SourceProduct.PAY_ANYWHERE
  | SourceProduct.PERSONAL_LOAN
  | SourceProduct.WORKING_CAPITAL
