import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { numberToCurrency } from '../../common/assets/utils/currencyMask'

interface IBigNumberCardProps {
  title: string
  value: number
  isMoney?: boolean
  valueFontSize?: string
  valueColor?: string
}

export const BigNumberCard = (props: IBigNumberCardProps & HTMLAttributes<IBigNumberCardProps>) => {
  const { title, value, isMoney, valueFontSize, valueColor } = props

  return (
    <Card {...props}>
      <ContentHeader>{title}</ContentHeader>

      <Content valueColor={valueColor}>
        {isMoney ? (
          <>
            <MoneyUnit>R$</MoneyUnit>
            <MoneyValue fontSize={valueFontSize}>{numberToCurrency(value)}</MoneyValue>
          </>
        ) : (
          <Value fontSize={valueFontSize}>{value}</Value>
        )}
      </Content>
    </Card>
  )
}

const Content = styled.div`
  color: ${(props: any) => (props.valueColor ? props.valueColor : UMEColors.black.primary)};
`

const Value = styled.div`
  font-family: Roboto;
  font-size: ${(props: any) => (props.fontSize ? props.fontSize : '56px')};
  font-weight: bold;
  letter-spacing: -1.31px;
  line-height: 66px;
`

const MoneyValue = styled.span`
  font-family: Roboto;
  font-size: ${(props: any) => (props.fontSize ? props.fontSize : '56px')};
  font-weight: bold;
  letter-spacing: 0;
  line-height: 66px;
`

const Card = styled.section`
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: ${UMEColors.white.primary};
  height: 184px;
  padding: 16px;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-width: 164px;
`

const ContentHeader = styled.header`
  height: 22px;
  width: 107px;

  color: ${UMEColors.gray.spanish};
  font-size: 20px;
  font-family: Roboto;
  font-weight: 500;
  letter-spacing: -0.5px;
`

const MoneyUnit = styled.p`
  margin: 0px;

  font-family: Roboto;
  font-size: 23px;
  font-weight: bold;
  letter-spacing: -0.54px;

  margin-bottom: -8px;
`
