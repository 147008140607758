import React from 'react'
import { Button } from 'grommet'
import { Spinner } from '../../atoms/Spinner/Spinner'

export interface ILoadingButtonState {}

export interface ILoadingButtonProps {
  isLoading: boolean
  label: string
  style?: any
  icon?: any
  onClick?: Function
}

export class LoadingButton extends React.Component<ILoadingButtonProps, ILoadingButtonState> {
  render() {
    return (
      <Button
        {...this.props}
        icon={this.props.isLoading ? <Spinner /> : this.props.icon ? this.props.icon : <></>}
        label={this.props.label}
        onClick={event => (this.props.onClick ? this.props.onClick(event) : undefined)}
      />
    )
  }
}


// import React, { HTMLAttributes } from 'react'
// import styled from 'styled-components'
// import { Button } from 'antd'

// export const LoadingButton = (props: HTMLAttributes<any>) => (
//   <StyledLoadingButton {...props} type='primary'>
//     {props.children}
//   </StyledLoadingButton>
// )

// export const StyledLoadingButton = styled(Button)`
//   min-height: ${(props: any) => (props.height ? props.height : 'inherit')};
//   min-width: ${(props: any) => (props.width ? props.width : 'inherit')};
//   border-radius: 2px;

//   background-color: red;
//   font-family: Roboto;
//   font-size: 12px;
//   font-weight: 500;
//   letter-spacing: 0;
//   line-height: 14px;
//   text-align: center;

//   &:active {
//     background-color: inherit;
//     color: inherit;
//   }

//   &:focus {
//     background-color: inherit;
//     color: inherit;
//   }

//   &:hover {
//     cursor: pointer;
//     transition: 0.3s;
//     box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
//     // background-color: ${(props: any) => (props.backgroundColor ? props.backgroundColor : 'undefined')};
//     // color: ${(props: any) => (props.color ? props.color : 'inherit')};

//     // transform: translateY(-0.25em);
//   }
// `
