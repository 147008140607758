import { Box } from 'grommet'
import React from 'react'
import styled from 'styled-components'
import { tablet } from '../../common/assets/_breakpoints'

const TabletMessage = (props: any) => {
  return(
    <TabletMessageStyled height="100%" align="center" justify="center">
        Tela indisponível em tablets e celulares.
    </TabletMessageStyled>
  )
}

const TabletMessageStyled = styled(Box)`
  @media (min-width: ${tablet}) {
    display: none;
  }
`

export { TabletMessage }