import React from 'react'
import { Box } from 'grommet'
import { StyledButton } from '../../pages/Retailers/style'
import Modal from '../../molecules/RetailersPage/Modal'
import { SmallLoadingIcon } from './styles'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'

interface IFormModalProps {
  isModalOpen: boolean
  isLoadingButton: boolean
  title: string
  fields: any[]
  deleteButton?: boolean
  onCreate: () => void
  onClose: () => void
  hideFooter?: boolean
  wrapFieldsWithScroll?: boolean;
}

interface IFormModalState {
  isDownloadingPayments: boolean
}

const initialState: IFormModalState = {
  isDownloadingPayments: false,
}

export default class FormModal extends React.Component<IFormModalProps, IFormModalState> {
  constructor(props: IFormModalProps) {
    super(props)
    this.state = initialState
  }

  render() {
    const {
      title,
      isModalOpen,
      isLoadingButton,
      onClose,
      fields,
      onCreate,
      deleteButton,
      hideFooter,
      wrapFieldsWithScroll
    } = this.props

    return (
      <Modal modalTtitle={title} isOpen={isModalOpen} onClose={onClose} width={'450px'}>
        <Box overflow="auto">
          <Box margin={'25px'} direction="column" gap={wrapFieldsWithScroll ? "small" : "medium"}>
            {wrapFieldsWithScroll ? <Box flex={{ grow: true ? undefined : 1 }} gap={"small"}>
              {fields.map(Field => {
                return Field
              })}
            </Box> :
              fields.map(Field => {
                return Field
              })
            }
            {hideFooter ? (
              <></>
            ) : (
              <StyledButton
                backgroundColor={deleteButton ? UMEColors.red.light : undefined}
                style={{ width: '100%' }}
                onClick={onCreate}
              >
                {isLoadingButton ? <SmallLoadingIcon /> : deleteButton ? 'Deletar' : 'Salvar'}
              </StyledButton>
            )}
          </Box>
        </Box>
      </Modal>
    )
  }
}
