import { ApplicationStatus, DenialReasons } from '../../application-engine/interfaces/application-engine.interfaces'

export interface IForcedApproval {
  borrowerId: string
  recurringPurchasesLimit: number
  bigPurchasesLimit: number
}

export interface IForcedApprovalBody {
  approvals: IForcedApproval[]
}

export interface IPostApplicationForcedApprovalAnalysisResponse {
  errors: IForcedApprovalNotification[]
}

export interface IForcedApprovalNotification {
  borrowerId: string
  reason: ApprovalErrorsType
}

export interface IEvaluateOnlineApplicationBody {
  evaluation: ApplicationStatus.APPROVED | ApplicationStatus.DENIED
  denialReasons?: DenialReasons[]
}

export interface IEvaluateOnlineApplicationResponse {
  id: string
  status: string
  evaluatedByOperatorId: string
  denialReasons?: DenialReasons[]
  borrowerLimit?: IPostBorrowerLimitResponse
}
export interface IPostBorrowerLimitResponse {
  limitType: string
  attributedFirstLimit: number
  suggestedFirstLimit: number
  scoreBin: number
  scoreUsed: string
  policyId: string
  id: string
}

export enum ApprovalErrorsType {
  BORROWER_APPLICATION_NOT_FOUND = 'BORROWER_APPLICATION_NOT_FOUND',
  BORROWER_ALREADY_APPROVED = 'BORROWER_ALREADY_APPROVED',
  PHONE_VERIFICATION_NOT_FOUND = 'PHONE_VERIFICATION_NOT_FOUND',
  PHONE_VERIFICATION_NOT_APPROVED = 'PHONE_VERIFICATION_NOT_APPROVED',
  ERROR_ON_APPROVAL_DATA = 'ERROR_ON_APPROVAL_DATA',
  NEGATIVE_LIMIT = 'NEGATIVE_LIMIT',
  LIMIT_IS_ZERO = 'LIMIT_IS_ZERO',
  BIG_PURCHASE_SMALLER_THAN_RECURRING_PURCHASE = 'BIG_PURCHASE_SMALLER_THAN_RECURRING_PURCHASE',
}

export enum ApplicationOnlineErrorType {
  APPLICATION_NOT_FOUND = 'APPLICATION_NOT_FOUND',
  APPLICATION_MUST_BE_WAITING_MANUAL_APPROVAL = 'APPLICATION_MUST_BE_WAITING_MANUAL_APPROVAL',
  NOT_AN_ONLINE_APPLICATION = 'NOT_AN_ONLINE_APPLICATION',
  LIMIT_NOT_CREATED = 'LIMIT_NOT_CREATED',
  REQUIRED_DENIAL_REASONS = 'REQUIRED_DENIAL_REASONS',
}
