import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'

export const DownloadSpecificContractsButton = (props: any) => {
  const [loading, setLoading] = useState(false)

  return (
    <LoadingButton
      loading={loading}
      type="primary"
      onClick={async () => {
        setLoading(true)
        await props.onDownloadContracts(props.c)
        setLoading(false)
      }}
    >
      Baixar contratos
    </LoadingButton>
  )
}

const LoadingButton = styled(Button)`
  background-color: ${UMEColors.blue.dark};
  color: white;
  width: 150px;

  &:hover {
    opacity: 0.5;
  }
`
