import React from 'react'
import styled from 'styled-components'
// import { screenSize } from '../../../../config/screens.config'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { formatNumberToBRL } from '../../utils'
import moment from 'moment'

interface IBatchCardProps {
  date: string
  quantity: number
  price: number
}

export const BatchCard = (props: IBatchCardProps & React.HTMLAttributes<IBatchCardProps>) => {
  const { date, quantity, price } = props

  const formattedDate = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')

  return (
    <Card {...props}>
      <ContentHeader>
        Pacote do dia: <Date>{formattedDate}</Date>
      </ContentHeader>
      <Content>
        <ContentItem>
          <Quantity> {quantity} </Quantity>
          <span> Originações </span>
        </ContentItem>

        <ContentItem>
          <Price>
            <PriceUnit> R$ </PriceUnit>
            <span>{formatNumberToBRL(price).substring(3)}</span>
          </Price>
          <span> Valor financeiro </span>
        </ContentItem>
      </Content>
    </Card>
  )
}

const Date = styled.span`
  // TODO - Not in Design Kit
  color: #5b5b5b;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;

  margin-left: 10px;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const Card = styled.section`
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: ${UMEColors.white.primary};
  height: 126px;
  width: 291px;
  padding: 16px;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  transition: .2s;

  &:hover {
    box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4);
    transition: .3s;
    cursor: pointer;
  }
`

const ContentHeader = styled.header`
  // TODO - Not in Design Kit
  color: #5b5b5b;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
`

const ContentItem = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  // TODO - Not in Design Kit
  color: #5b5b5b;
`

const Quantity = styled.div`
  color: ${UMEColors.black.primary};
  font-size: 32px;
  font-weight: bold;
  height: 38px;
`

const Price = styled.div`
  // TODO - Not in design kit
  color: #00b748;
  font-size: 24px;
  font-weight: 700;
  height: 38px;

  display: flex;
  align-items: flex-end;

  & > span:first-child {
    margin-bottom: 4.5px;
  }
`

const PriceUnit = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
`
