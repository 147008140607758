interface Payable {
  paidValue?: number
  paymentTimestamp?: string
  value: number
  paymentDue: number
}

/**
 * Computes the discount value for a given Payable entity
 *
 * If was paid and less than the expected value, return the difference
 * If not paid and debt is less than the expected value, return the difference
 * Returns 0.0 otherwise.
 *
 * @param {Payable} payable
 * @return {*}  {number}
 */
export const getPayableDiscounts = (payable: Payable): number => {
  if (payable.paymentTimestamp && payable.paidValue && payable.paidValue < payable.value) {
    return payable.value - payable.paidValue
  }

  if (!payable.paymentTimestamp && payable.paymentDue < payable.value) {
    return payable.value - payable.paymentDue
  }

  return 0.0
}

/**
 * Computes the additions value for a given Payable entity
 *
 * If was paid and more than the expected value, return the difference.
 * If not paid and debt is more than the expected value, return the difference.
 *
 * Returns 0.0 otherwise.
 *
 * @param {Payable} payable
 * @return {*}  {number}
 */
export const getPayableAdditions = (payable: Payable): number => {
  if (payable.paymentTimestamp && payable.paidValue && payable.paidValue > payable.value) {
    return payable.paidValue - payable.value
  }

  if (!payable.paymentTimestamp && payable.paymentDue > payable.value) {
    return payable.paymentDue - payable.value
  }

  return 0.0
}

/**
 * If paid, returns the ammount paid vs expected.
 * If not paid, returns the ammount due vs expected.
 *
 * Discounts are negative, additions are positive.
 *
 * @param {Payable} payable
 * @return {*}  {number}
 */
export const getPayableDifference = (payable: Payable): number => {
  let value = 0.0

  if ((value = getPayableAdditions(payable))) {
    return value
  }

  if ((value = getPayableDiscounts(payable))) {
    return -value
  }

  return 0.0
}
