import styled from 'styled-components'
import { black, gray } from '@bit/ume.design-kit.ui/utils/_colors'
import Dragger from 'antd/lib/upload/Dragger'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 32px 32px 8px;

  > div {
    width: -webkit-fill-available;
  }

  > div > div > button {
    width: -webkit-fill-available;
  }
`
const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 4px 12px;
`
const StyledDragger = styled(Dragger)`
  maxWidth: 600px;
  maxHeight: 250px;
  padding: 10px;
`
const BreadcrumbsBox = styled.div`
  display: flex;
  margin-top: 24px;
  font-size: 10px;
` 
  
const BreadcrumbsItem = styled.span`
  margin: 0 6px;  
  color: ${props => props.active ? black.primary : gray.nobel};
`

export {
  BreadcrumbsBox,
  BreadcrumbsItem,
  Container,
  Component,
  StyledDragger,
  UploadContainer,
}