import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { LoadingButton } from '../LoadingButton/LoadingButton'
import { RetailersBenchSliceReducer } from '../../redux/reducers/retailers-bench/retailers-bench.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'

interface Props {
  isActive: boolean
  retailerId: string
}

const IsActiveButton = ({ isActive, retailerId }: Props) => {
  const dispatch = useDispatch()

  const { isLoadingDisableRetailer, isLoadingEnableRetailer } = useTypedSelector(state => ({
    isLoadingDisableRetailer: state.retailersBench.isLoadingDisableRetailer,
    isLoadingEnableRetailer: state.retailersBench.isLoadingEnableRetailer,
  }))

  const handleDisableButtonClick = () => {
    dispatch(RetailersBenchSliceReducer.actions.disableRetailer(retailerId))
  }

  const handleEnableButtonClick = () => {
    dispatch(RetailersBenchSliceReducer.actions.enableRetailer(retailerId))
  }

  if (isActive) {
    return <DisableButton onClick={handleDisableButtonClick} label="Desativar" isLoading={isLoadingDisableRetailer} />
  }

  return <EnableButton onClick={handleEnableButtonClick} label="Ativar" isLoading={isLoadingEnableRetailer} />
}

const DisableButton = styled(LoadingButton)`
  border-radius: 6px;
  padding: 5px 0;
  width: 100px !important;
  background-color: ${UMEColors.red.persian};
  border: solid 0px ${UMEColors.white.primary};
  box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4) !important;

  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  transition: 0.4s;

  &:hover {
    transition: 250ms;
    opacity: 0.6;
    cursor: pointer;
  }
`
const EnableButton = styled(LoadingButton)`
  border-radius: 6px;
  padding: 5px 0;
  width: 100px !important;
  background-color: ${UMEColors.green.laurel};
  border: solid 0px ${UMEColors.green.laurel};
  box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4) !important;

  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  transition: 0.4s;

  &:hover {
    transition: 250ms;
    cursor: pointer;
    opacity: 0.6;
  }
`

export default IsActiveButton
