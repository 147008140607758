export interface IGetBatchesQueryParams {
  startDate?: string
  endDate?: string
  originationsStartDate?: string
  originationsEndDate?: string
  batchId?: string
  status?: IBatchStatus[]
  retailerId?: string
  storeId?: string
}

export interface IPostEndStepParams {
  id: string
  stepName: IBatchStepName
}

export interface IGetOriginationsForBatchQuery {
  storeId?: string
  retailerId?: string
}

export interface IPostBankerEmail {
  emails: string[]
  base64File?: string
}

export interface IPutVoucher {
  base64File: string
}

export interface IPutRetailerMdrRatesBody {
  mdrRates: IMdrRateReq[]
  mdrLiquidationType?: MdrLiquidationTypeEnum
}

export interface IMdrRateReq {
  rate: number
  upperBound?: number
}

export interface IGetBatchResponse {
  data: IBatchResponse[]
}

export interface IBatchResponse {
  id: number
  createdOn: string
  modifiedOn: string
  batchDate: string
  status: IBatchStatus
  originationsCount: number
  principalSum: number
  umeFeeSum: number
  iofSum: number
  discountSum: number
  originationsStartDate: string
  originationsEndDate: string
  retailersBatch: IRetailerBatchResponse[]
  storesBatch: IStoreBatchResponse[]
  steps: IBatchStepResponse[]
  originations: IOriginationResponse[]
}

export interface IRetailerBatchResponse {
  id: number
  createdOn: string
  modifiedOn: string
  retailerId: string
  principalSum: number
  umeFeeSum: number
  iofSum: number
  discountSum: number
  originationsCount: number
}

export interface IStoreBatchResponse {
  id: number
  createdOn: string
  modifiedOn: string
  retailerId: string
  storeId: string
  principalSum: number
  umeFeeSum: number
  iofSum: number
  discountSum: number
  originationsCount: number
}

export interface IBatchStepResponse {
  id: number
  createdOn: string
  modifiedOn: string
  name: IBatchStepName
  order: number
  endTimestamp: string
  operatorId: string
}

export interface IOriginationResponse {
  id: number
  timestamp: string
  borrowerId: string
  mdr: number
  mdrRate: number
  iof: number
  interest: number
  interestRate: number
  principal: number
  principalInStore: number
  financedValue: number
  financialInstitutionId: string
  financialInstitutionName: string
  retailerId: string
  storeId: string
  operatorId: string
  storeName: string
  retailerName: string
  contractId: string
  partnerProposalId: string
  numberOfInstallments: number
  retailerCNPJ: string
  installmentValue: number
  wasRenegotiated: boolean
  renegotiationDiscount: number
  upfrontPayment: number
  firstInstallmentDueDate: string
  upfrontPaymentDueDate: string
  isRenegotiation: boolean
  couponPrincipalDiscount: number
  cancellationTimestamp: string | null
  debtFundingPackId: string
  debtFundingPackName: string
}

export interface IPostBankerEmailResponse {
  accepted: string[]
  rejected: string[]
  envelopeTime: number
  messageTime: number
  messageSize: number
  response: string
  envelope: {
    from: string
    to: string
  }
  messageId: string
}

export interface IGetRetailerMdrRatesResponse {
  id: string
  retailerId: string
  mdrRates: IMdrRateResponse[]
  isActive: boolean
  mdrLiquidationType?: MdrLiquidationType
  createdOn: string
  modifiedOn: string
  mdrType: MdrType
}

export interface IMdrRateResponse {
  rate: number
  upperBound?: number
}

export type IBatchStepName = 'UME_TO_BANKER' | 'BANKER_TO_TRANSITORY' | 'TRANSITORY_TO_RETAILER'
export type MdrLiquidationType = keyof typeof MdrLiquidationTypeEnum

export enum MdrType {
  SIMPLE = 'SIMPLE',
  REGRESSIVE = 'REGRESSIVE',
}

export type IBatchStatus = 'AT_UME' | 'AT_BANKER' | 'AT_TRANSITORY_ACCOUNT' | 'AT_RETAILER'

export enum MdrLiquidationTypeEnum {
  AUTOMATIC_SPLIT = 'AUTOMATIC_SPLIT',
}

export enum HttpOriginationsBatchErrors {
  DATE_IN_THE_FUTURE = "DATE_IN_THE_FUTURE",
  NOT_A_BUSINESS_DAY = "NOT_A_BUSINESS_DAY",
  BATCH_NOT_FOUND = "BATCH_NOT_FOUND",
  ALREADY_EXISTING_BATCH_ON_DATE = "ALREADY_EXISTING_BATCH_ON_DATE",
  NO_ORIGINATIONS_ON_DATE = "NO_ORIGINATIONS_ON_DATE",
  BATCH_ALREADY_FINISHED = "BATCH_ALREADY_FINISHED",
  NOT_FIRST_PENDING_STEP = "NOT_FIRST_PENDING_STEP",
  VOUCHER_NOT_FOUND = "VOUCHER_NOT_FOUND",
  EMAIL_NOT_SENT = "EMAIL_NOT_SENT",
  STEP_NOT_FOUND = "STEP_NOT_FOUND",
  STEP_ALREADY_FINISHED = "STEP_ALREADY_FINISHED"
}

export enum ORIGINATIONS_BATCH_STATUS {
  AT_UME = "AT_UME",
  AT_BANKER = "AT_BANKER",
  AT_TRANSITORY_ACCOUNT = "AT_TRANSITORY_ACCOUNT",
  AT_RETAILER = "AT_RETAILER"
}