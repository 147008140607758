import { DataTable } from '@bit/ume.design-kit.ui'
import { gray } from '@bit/ume.design-kit.ui/Palette'
import React from 'react'
import styled from 'styled-components'
import { LinkAnchorStyle } from '../../pages/RenegotiationDetails/style'
import { ISingleInstallment } from '../../services/bff-backoffice/coordinator/interfaces/installment.interfaces'
import { IInvoice } from '../../services/bff-backoffice/coordinator/interfaces/invoice.interfaces'
import { formatDate, formatNumberToBRL } from '../../utils'
import Modal from '../RetailersPage/Modal'

export interface InvoiceInstallmentsModalProps {
  history?: any
  invoice?: IInvoice
  isModalOpen: boolean
  onCancel: () => {}
}

export const InvoiceInstallmentsModal = (props: InvoiceInstallmentsModalProps) => {
  const { invoice, isModalOpen, onCancel } = props

  if (!invoice) {
    return <></>
  }

  const updateInstallmentsValues = (invoice: IInvoice) =>
    invoice.installments.map(inst => ({
      ...inst,
      installmentValue:
        invoice.installmentsValues.find(i => String(i.installmentId) === String(inst.id))?.simulatedValue ||
        inst.installmentValue,
    }))

  const columnsDropDown = [
    {
      label: 'ID',
      attribute: 'id',
      textAlign: 'center',
      formatCell: (i: ISingleInstallment) => {
        return <LinkAnchorStyle href={`/installment/${i.id}`}>{i.id}</LinkAnchorStyle>
      },
    },
    {
      label: 'Valor',
      attribute: 'installmentValue',
      textAlign: 'center',
      formatCell: (i: ISingleInstallment) => {
        return formatNumberToBRL(i.installmentValue)
      },
    },
    {
      label: 'Ordem',
      attribute: 'installmentOrder',
      textAlign: 'center',
    },
    {
      label: 'Vencimento',
      attribute: 'dueDate',
      textAlign: 'center',
      formatCell: (i: ISingleInstallment) => {
        return formatDate(i.dueDate)
      },
    },
  ]

  return (
    <Modal
      isOpen={isModalOpen}
      modalTtitle={`Parcelas do boleto ${invoice.id}`}
      width="100%"
      onClose={() => onCancel()}
    >
      <TableBoxStyle>
        <DataTable header={columnsDropDown} data={updateInstallmentsValues(invoice)} fontSize="14px" />
      </TableBoxStyle>
    </Modal>
  )
}

const TableBoxStyle = styled.div`
  max-height: 60vh;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${gray.gainsboro};
    border: 2px solid ${gray.azure};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${gray.eclipse};
    border-radius: 9px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${gray.nobel};
  }
`
