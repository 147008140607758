import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { toaster } from '../../../App'
import { IOriginationsParametersResponse, IPutOriginationsParameters } from '../../../services/bff-backoffice/coordinator/interfaces/parameters.interfaces'
import { IGetRetailerMdrRatesResponse } from '../../../services/bff-backoffice/liquidation-originations/interfaces/liquidation-originations.interfaces'
import { DebtFundingPackResponse, IRetailerCategoryReponse, IRetailerResponse, PostRetailerRequest } from '../../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'
import { RetailerReducerErrors } from './retailers.errors'

export type IRetailersState = {
  retailersNames: string[]
  retailers?: IRetailerResponse[]
  retailer?: IRetailerResponse
  error: any
  errorType?: RetailerReducerErrors
  categories?: IRetailerCategoryReponse[]
  blocks: DebtFundingPackResponse[]

  // For a specific retailer
  originationsParameters?: IOriginationsParametersResponse
  mdr: any
  isPuttingOriginationsParameters: boolean
  isUpdatingRetailerStatus: boolean
  isPutRetailerLogoLoading?: boolean
  isCreateRetailerModalOpen: boolean
  isCreateRetailerLoading: boolean
  isEditRetailerCategoriesLoading: boolean
  isEditRetailerCategoriesModalOpen: boolean
  isFetchingRetailer: boolean
  isEditRetailerBlockLoading?: boolean
  closeEditRetailerBlockModal?: boolean
}

const initialState: IRetailersState = {
  retailersNames: [],
  retailers: undefined,
  retailer: undefined,
  error: undefined,
  errorType: undefined,
  blocks: [],

  // For a specific retailer
  originationsParameters: undefined,
  mdr: undefined,
  isPuttingOriginationsParameters: false,
  isUpdatingRetailerStatus: false,
  isPutRetailerLogoLoading: false,
  isCreateRetailerLoading: false,
  isCreateRetailerModalOpen: false,
  isEditRetailerCategoriesLoading: false,
  isEditRetailerCategoriesModalOpen: false,
  isFetchingRetailer: false,
}

export type PutRetailerLogo = {
  retailerId: string
  logo: File
}

export type EditRetailerCategoriesPayload = {
  retailerId: string
  categories: string[]
}

export type EditRetailerBlockPayload = {
  retailerId: string
  debtFundingPack: DebtFundingPackResponse
}

export const RetailersSliceReducer = createSlice({
  name: 'retailers',
  initialState,
  reducers: {
    resetState: (state, action: PayloadAction<undefined>) => {
      return { ...initialState }
    },

    /*********** CREATE RETAILER ***********/
    createRetailer: (state, action: PayloadAction<PostRetailerRequest>) => {
      // Used in Redux-Saga
      return state
    },
    createRetailerSuccess: (state, action: PayloadAction<IRetailerResponse>) => {
      toaster.showSuccessToast(`Varejo criado com sucesso.`)
      return { ...state, isCreateRetailerLoading: false, isCreateRetailerModalOpen: false }
    },
    createRetailerError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      toaster.showErrorToast(
        `Não foi possível criar varejista.` + error.response?.data.statusCode
          ? `Erro ${error.response?.data.statusCode}`
          : ''
      )
      return { ...state, isCreateRetailerLoading: false }
    },
    createRetailerLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isCreateRetailerLoading: true }
    },
    createRetailerModalOpen: (state, action: PayloadAction<undefined>) => {
      return { ...state, isCreateRetailerModalOpen: true }
    },
    createRetailerModalClose: (state, action: PayloadAction<undefined>) => {
      return { ...state, isCreateRetailerModalOpen: false }
    },

    /*********** FETCH RETAILERS ***********/
    fetchRetailers: (state, action: PayloadAction<undefined>) => {
      return { ...state, retailers: undefined }
    },
    fetchRetailersSuccess: (state, action: PayloadAction<IRetailerResponse[]>) => {
      const retailers = action.payload
      const retailersNames = retailers.map(retailer => retailer.fantasyName)
      return { ...state, retailersNames, retailers, error: undefined }
    },
    fetchRetailersError: (state, action: PayloadAction<AxiosError>) => {
      toaster.showErrorToast(`Erro ao carregar varejistas`)
      return { ...state, error: action.payload, retailers: [] }
    },

    /*********** FETCH RETAILERS CATEGORIES ***********/
    fetchCategories: (state, action: PayloadAction<undefined>) => {
      return { ...state, categories: [] }
    },
    fetchCategoriesSuccess: (state, action: PayloadAction<IRetailerCategoryReponse[]>) => {
      const categories = action.payload
      return { ...state, categories }
    },
    fetchCategoriesError: (state, action: PayloadAction<AxiosError>) => {
      toaster.showErrorToast(`Erro ao carregar categorias.`)
      return state
    },

    /*********** FETCH RETAILERS BLOCKS ***********/
    fetchBlocks: (state, action: PayloadAction<undefined>) => {
      return { ...state, blocks: [] }
    },
    fetchBlocksSuccess: (state, action: PayloadAction<DebtFundingPackResponse[]>) => {
      const blocks = action.payload
      return { ...state, blocks }
    },
    fetchBlocksError: (state, action: PayloadAction<AxiosError>) => {
      toaster.showErrorToast(`Erro ao carregar os blocos.`)
      return state
    },

    /*********** FETCH RETAILER ***********/
    fetchRetailer: (state, action: PayloadAction<string>) => {
      return { ...state, retailer: undefined, isFetchingRetailer: true }
    },
    fetchRetailerSuccess: (state, action: PayloadAction<IRetailerResponse>) => {
      const retailer = action.payload
      return { ...state, retailer, error: undefined, isFetchingRetailer: false }
    },
    fetchRetailerError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      if (error.response?.status === 404) {
        return {
          ...state,
          error: action.payload,
          errorType: RetailerReducerErrors.RETAILER_NOT_FOUND,
          isFetchingRetailer: false,
        }
      }

      toaster.showErrorToast(`Erro ao carregar varejo.`)
      return { ...state, error: action.payload, isFetchingRetailer: false }
    },

    /*********** PUT RETAILER LOGO ***********/
    putRetailerLogo: (state, action: PayloadAction<PutRetailerLogo>) => {
      // Used in Redux-Saga
      return state
    },
    putRetailerLogoLoading: (state, action: PayloadAction<undefined>) => {
      // Used in Redux-Saga
      return { ...state, isPutRetailerLogoLoading: true }
    },
    putRetailerLogoSuccess: (state, action: PayloadAction<undefined>) => {
      return { ...state, isPutRetailerLogoLoading: false }
    },
    putRetailerLogoError: (state, action: PayloadAction<AxiosError>) => {
      toaster.showErrorToast(`Erro ao atualizar logo do varejo.`)
      return { ...state, isPutRetailerLogoLoading: false }
    },

    /*********** FETCH MDR RATES ***********/
    fetchRetailerMdr: (state, action: PayloadAction<string>) => {
      return { ...state, mdr: undefined }
    },
    fetchRetailerMdrSuccess: (state, action: PayloadAction<IGetRetailerMdrRatesResponse>) => {
      const mdr = action.payload
      return { ...state, mdr }
    },
    fetchRetailerMdrError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload

      if (error.response?.status !== 404) {
        toaster.showErrorToast(`Erro ao carregar Taxas de MDR para o varejista.`)
        return { ...state, mdr: null }
      }

      return { ...state, mdr: {} }
    },

    /*********** UPDATE RETAILER STATUS ***********/
    updateRetailerStatus: (state, action: PayloadAction<string>) => {
      // Used on Redux-Saga
      return state
    },
    updateRetailerStatusLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isUpdatingRetailerStatus: true }
    },
    updateRetailerStatusSuccess: (state, action: PayloadAction<IRetailerResponse>) => {
      const retailer = action.payload
      return { ...state, isUpdatingRetailerStatus: false, retailer }
    },
    updateRetailerStatusError: (state, action: PayloadAction<AxiosError>) => {
      toaster.showErrorToast(`Erro ao atualizar o status do varejo.`)
      return { ...state, isUpdatingRetailerStatus: false }
    },

    /*********** PUT ORIGINATIONS PARAMETER ***********/
    putOriginationsParameters: (state, action: PayloadAction<IPutOriginationsParameters>) => {
      // Used on Redux-Saga
      return state
    },
    putOriginationsParametersLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isPuttingOriginationsParameters: true }
    },
    putOriginationsParametersSuccess: (state, action: PayloadAction<IOriginationsParametersResponse>) => {
      const originationsParameters = action.payload
      return { ...state, isPuttingOriginationsParameters: false, originationsParameters }
    },
    putOriginationsParametersError: (state, action: PayloadAction<AxiosError>) => {
      toaster.showErrorToast(`Erro ao atualizar os parâmetros de originação`)
      return { ...state, isPuttingOriginationsParameters: false, originationsParameters: undefined }
    },

    /*********** FETCH ORIGINATIONS PARAMETER ***********/
    fetchOriginationsParameters: (state, action: PayloadAction<string>) => {
      return { ...state, originationsParameters: undefined }
    },
    fetchOriginationsParametersSuccess: (state, action: PayloadAction<IOriginationsParametersResponse>) => {
      const originationsParameters = action.payload
      return { ...state, originationsParameters }
    },
    fetchOriginationsParametersError: (state, action: PayloadAction<AxiosError>) => {
      toaster.showErrorToast(`Erro ao carregar os parâmetros de originação`)
      return state
    },
    originationsParametersReset: (state, action: PayloadAction<undefined>) => {
      return { ...state, originationsParameters: undefined }
    },

    /*********** EDIT RETAILER CATEGORIES ***********/
    editRetailerCategories: (state, action: PayloadAction<EditRetailerCategoriesPayload>) => {
      // Used in Redux-Saga
      return state
    },
    editRetailerCategoriesLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isEditRetailerCategoriesLoading: true }
    },
    editRetailerCategoriesSuccess: (state, action: PayloadAction<IRetailerResponse>) => {
      const retailer = action.payload
      return { ...state, retailer, isEditRetailerCategoriesLoading: false }
    },
    editRetailerCategoriesError: (state, action: PayloadAction<AxiosError>) => {
      toaster.showErrorToast(`Erro ao atualizar categorias`)
      return { ...state, isEditRetailerCategoriesLoading: false }
    },
    editRetailerCategoriesModalOpen: (state, action: PayloadAction<undefined>) => {
      return { ...state, isEditRetailerCategoriesModalOpen: true }
    },
    editRetailerCategoriesModalClose: (state, action: PayloadAction<undefined>) => {
      return { ...state, isEditRetailerCategoriesModalOpen: false }
    },

    /*********** EDIT RETAILER BLOCK ***********/
    editRetailerBlock: (state, action: PayloadAction<EditRetailerBlockPayload>) => {
      // Used in Redux-Saga
      return {
        ...state,
        isEditRetailerBlockLoading: true,
        closeEditRetailerBlockModal: undefined,
      }
    },
    editRetailerBlockSuccess: (state, action: PayloadAction<IRetailerResponse>) => {
      const retailer = action.payload
      toaster.showSuccessToast(`Bloco atualizado com sucesso`)
      return {
        ...state,
        retailer,
        isEditRetailerBlockLoading: false,
        closeEditRetailerBlockModal: true,
      }
    },
    editRetailerBlockError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      if (
        error?.response?.data?.message ===
        RetailerReducerErrors.REQUIRED_INTERMEDIARY_ACCOUNT_TO_CHANGE_DEBT_FUNDING_PACK
      ) {
        toaster.showErrorToast(`Cadastre uma conta intermediária para alterar o bloco.`)
      } else {
        toaster.showErrorToast(`Erro ao atualizar bloco`)
      }

      return {
        ...state,
        isEditRetailerBlockLoading: false,
        closeEditRetailerBlockModal: true,
      }
    },
  },
})
