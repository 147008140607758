import { Heading } from '@bit/ume.design-kit.ui'
import { Box } from 'grommet'
import styled from 'styled-components'

export const LogoImg = styled.img`
  alt: ume-logo;
  height: 58px;
  border-radius: 8px;
`
export const LoginHeading = styled(Heading)`
  margin-top: 0;
  margin-bottom: 6px;
`
export const ButtonBox = styled(Box)`
  margin-top: 10px;
  align-items: center;
`
