import { bffBackofficeApiService } from '..'
import { IContract, IContractFetchQueryParams, IContracts } from '../coordinator/interfaces/contracts.interfaces'
import { InstallmentStatusEnum, ISingleInstallment } from '../coordinator/interfaces/installment.interfaces'
import { ContractStatus, ContractStatusType, ICSContract, ICSContracts } from './interfaces/contracts.res.types'

export default class ContractsService {
  static async getCsContracts(params: IContractFetchQueryParams): Promise<ICSContracts> {
    const contracts = await bffBackofficeApiService.coordinator.getContracts(params)

    return this.transformIContractsIntoICSContracts(contracts)
  }

  private static sumPendingInstallmentsForContract(installments: ISingleInstallment[]): number {
    const pendingInstallments = installments.filter(installment => !installment.paymentTimestamp)

    return pendingInstallments.reduce((installmentsSum, installment) => {
      const value = installment.status === 'PAYMENT_OVERDUE' ? installment.paymentDue : installment.installmentValue
      return value + installmentsSum
    }, 0)
  }

  private static determineContractStatus(contract: IContract): ContractStatusType {
    const installments = contract.installments
    if (contract.canceledOn) {
      return ContractStatus.CANCELED
    }

    if (installments.some(installment => installment.status === InstallmentStatusEnum.PAYMENT_OVERDUE)) {
      return ContractStatus.OVERDUE
    }

    if (installments.some(installment => installment.status === InstallmentStatusEnum.PENDING)) {
      return ContractStatus.PENDING
    }

    return ContractStatus.PAID
  }

  private static transformIContractsIntoICSContracts(contracts: IContracts): ICSContracts {
    const csContracts = contracts.contracts.map<ICSContract>(contract => {
      return {
        ...contract,
        sumPendingInstallments: this.sumPendingInstallmentsForContract(contract.installments),
        status: this.determineContractStatus(contract),
      }
    })

    return {
      contracts: csContracts,
    }
  }
}
