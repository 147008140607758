import * as React from 'react'
import { black } from '../../../utils/colors'

export interface TextSummaryProps {
  isTextLabel: boolean
  hasSelectedRows: boolean
  text: string
}

export const TextSummary = ({ isTextLabel, text, hasSelectedRows }: TextSummaryProps) => {
  return (
    <div
      style={{
        color: isTextLabel && !hasSelectedRows ? `${black.primary}` : 'inherit',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '0.9rem',
        lineHeight: '1.2rem',
      }}
    >
      {text}
    </div>
  )
}
