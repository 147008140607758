
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Button } from 'antd'
import styled from 'styled-components'

export const UploadText = styled.span`
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 19px;
`

export const FileUpload = styled.input`
  outline: none;
  margin-left: 3vw;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${UMEColors.gray.azure};
  min-height: 94vh;
  padding: 16px 64px;
  width: 100%;

  text-align: initial;
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  margin-top: 3vh;
`
export const DownloadTableTemplateButton = styled(Button)`
  display: inline-block;

  border: none;

  // TEXT
  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;

  // BUTTON
  border-radius: 2px;
  background-color: ${UMEColors.gray.primary};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
    transition: 0.3s;
    background-color: ${UMEColors.gray.primary};
    opacity: 0.5;
  }

  &:focus {
    background-color: ${UMEColors.gray.primary};
  }

  &:active {
    background-color: ${UMEColors.gray.primary};
  }
`

export const ConfirmButton = styled(Button)`
  display: inline-block;

  border: none;

  // TEXT
  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;

  // BUTTON
  border-radius: 2px;
  background-color: ${UMEColors.green.dark};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
    transition: 0.3s;
    background-color: ${UMEColors.green.dark};
    opacity: 0.5;
  }

  &:focus {
    background-color: ${UMEColors.green.dark};
  }

  &:active {
    background-color: ${UMEColors.green.dark};
  }
`