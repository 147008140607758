import React from 'react'
import { formatCPF, formatDate, formatNumberToBRL } from '../../../utils'
import { TextRedBackground, TextGreenBackground, TextOrangeBackground } from '../../../atoms/Texts/style'

const installmentsHeader = [
  { property: 'id', label: 'ID' },
  { property: 'cpf', label: 'CPF', format: formatCPF },
  { property: 'name', label: 'Nome' },
  { property: 'phoneNumber', label: 'Telefone' },
  { property: 'contractId', label: 'ID Contrato' },
  { property: 'dueDate', label: 'Vencimento', format: formatDate },
  { property: 'installmentOrder', label: 'Ordem da Parcela' },
  { property: 'numberOfInstallments', label: 'Qntd de Parcelas' },
  { property: 'installmentValue', label: 'Valor da Parcela', format: formatNumberToBRL },
  { property: 'retailerName', label: 'Varejista' },
  {
    property: 'status',
    label: 'Status',
    format: (status: string) => {
      switch (status) {
        case 'PAYMENT_OVERDUE':
          return <TextRedBackground> VENCIDO </TextRedBackground>
        case 'PAID':
          return <TextGreenBackground> PAGO </TextGreenBackground>
        case 'PENDING':
          return <TextOrangeBackground> PENDENTE </TextOrangeBackground>
        default:
          return status
      }
    },
  },
]

export const InstallmentsOperationsConfig = {
  label: 'Vencimentos',
  route: '/installments/operations',
  header: installmentsHeader,
}
