import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Button } from 'antd'
import { AxiosError } from 'axios'
import { Box, TextInput } from 'grommet'
import moment from 'moment'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import React, { useState } from 'react'
import styled from 'styled-components'
import XLSX from 'xlsx'
import { toaster } from '../../../App'
import { numberToCurrency } from '../../../common/assets/utils/currencyMask'
import { UploadItem } from '../../../molecules/FinancialOriginations/UploadItem'
import { IBatchStatus } from '../../../molecules/OriginationsBatch/BatchAccordion'
import { BigNumberCard } from '../../../molecules/OriginationsBatch/BigNumberCard'
import { getErrorStringForBackofficeOriginationsHttpErrors } from '../../../pages/OriginationsBatchPage/config'
import { bffBackofficeApiService } from '../../../services/bff-backoffice'
import { IBatchResponse } from '../../../services/bff-backoffice/liquidation-originations/interfaces/liquidation-originations.interfaces'

interface IUmeToBankerStepProps {
  batch: IBatchResponse
  status: IBatchStatus
  onStepEnded?: (stepName: string) => void
}

const MAX_NUMBER_OF_EMAILS = 2

export const UmeToBankerStep = (props: IUmeToBankerStepProps) => {
  const { batch, onStepEnded, status } = props

  const [loadingOriginations, setLoadingOriginations] = useState(false)

  const [base64File, setBase64File] = useState('')
  const [emails, setEmail] = useState(new Array(MAX_NUMBER_OF_EMAILS))
  const [loadingForm, setLoadingForm] = useState(false)

  const [loadingEndStep, setLoadingEndStep] = useState(false)

  const downloadOriginations = () => {
    setLoadingOriginations(true)

    bffBackofficeApiService.liquidationOriginations
      .getBatchOriginations(String(batch.id))
      .then(originations => {
        setLoadingOriginations(false)
        toaster.showSuccessToast(`Originações baixadas com sucesso!`)

        let fileName = `originações_${moment(batch.batchDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}.xlsx`
        const ws = XLSX.utils.json_to_sheet(originations)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, `Originações`)
        XLSX.writeFile(wb, fileName)
      })
      .catch(error => {
        setLoadingOriginations(false)

        const errorString = getErrorStringForBackofficeOriginationsHttpErrors(error)
        toaster.showErrorToast(errorString)
      })
  }

  // On email changed
  const onEmailChange = (email: string, index: number) => {
    if (index > MAX_NUMBER_OF_EMAILS) {
      return
    }

    let newEmails = Object.assign([], emails)
    newEmails[index] = email
    setEmail(newEmails)
  }

  const submitForm = () => {
    const hasAtLeastOneEmail = emails.find(email => email && email.length > 0)
    if (!hasAtLeastOneEmail) {
      return toaster.showErrorToast(`Preencha pelo menos um email`)
    }

    setLoadingForm(true)
    const base64 = base64File.length > 100 ? base64File : undefined

    bffBackofficeApiService.liquidationOriginations
      .sendBankerEmail(String(batch.id), { emails, base64File: base64 })
      .then(() => {
        setLoadingForm(false)
        toaster.showSuccessToast(`Email enviado!`)
      })
      .catch((error: AxiosError) => {
        setLoadingForm(false)

        const errorString = getErrorStringForBackofficeOriginationsHttpErrors(error)
        toaster.showErrorToast(errorString)
      })
  }

  const uploadFile = async (options: UploadRequestOption) => {
    if (options.file) {
      const file = options.file as File
      let abc = await file.arrayBuffer()
      const rawBase64 = btoa(new Uint8Array(abc).reduce((options, byte) => options + String.fromCharCode(byte), ''))
      const base64 = `data:${file.type};base64,${rawBase64}`

      setBase64File(base64)

      bffBackofficeApiService.liquidationOriginations
        .putBankerVoucher(String(batch.id), { base64File: base64 })
        .then(() => {
          toaster.showSuccessToast('Comprovante anexado')
        })
        .catch(error => {
          const errorString = getErrorStringForBackofficeOriginationsHttpErrors(error)
          toaster.showErrorToast(errorString)
        })
    }
  }

  const endStep = () => {
    setLoadingEndStep(true)
    bffBackofficeApiService.liquidationOriginations
      .endStepForBatch(String(batch.id), 'UME_TO_BANKER')
      .then(() => {
        setLoadingEndStep(false)
        if (onStepEnded) {
          onStepEnded('UME_TO_BMP')
        }
        toaster.showSuccessToast(`Etapa finalizada!`)
      })
      .catch(error => {
        setLoadingEndStep(false)

        const errorString = getErrorStringForBackofficeOriginationsHttpErrors(error)
        toaster.showErrorToast(errorString)
      })
  }

  return (
    <Container>
      {/* FIRST SECTION */}
      <FirstSection>
        <Box direction="row" justify="start">
          <BigNumberCard
            title="Valor Financeiro"
            value={batch.principalSum + batch.iofSum}
            valueFontSize="32px"
            isMoney
          />
          <ResumeInformation>
            <p>Resumo: </p>
            <p>Principal + IoF</p>
            <p>
              R$ {numberToCurrency(batch.principalSum)} + R$ {numberToCurrency(batch.iofSum)}
            </p>
          </ResumeInformation>
        </Box>

        <Box direction="row" justify="start" align="center" pad="medium" gap="medium" wrap>
          <StepDescription>Baixe um relatório com os IDs das CCBs dos contratos: </StepDescription>

          <LoadingButton loading={loadingOriginations} onClick={() => downloadOriginations()} type="primary">
            Baixar
          </LoadingButton>
        </Box>
      </FirstSection>

      {/* SECOND SECTION */}
      <SecondSection>
        <FormContainer>
          <FormDescription>
            <p>Preencha as informações:</p>
            <p>Será enviado as CCBS e o comprovante</p>
          </FormDescription>

          <EmailTextInput placeholder="Email" onChange={(event: any) => onEmailChange(event.target.value, 0)} />
          <EmailTextInput placeholder="Email" onChange={(event: any) => onEmailChange(event.target.value, 1)} />

          <UploadContainer>
            <UploadItem customRequest={options => uploadFile(options)} multiple={false} />
          </UploadContainer>

          <LoadingButton
            loading={loadingForm}
            type="primary"
            style={{ alignSelf: 'flex-start' }}
            onClick={() => submitForm()}
          >
            Enviar
          </LoadingButton>
        </FormContainer>
      </SecondSection>

      {/* THRID SECTION */}
      <ThirdSection>
        <p>Deseja confirmar a transferência do valor para a conta transitória?</p>
        <LoadingButton
          disabled={status !== 'PENDING'}
          loading={loadingEndStep}
          type="primary"
          onClick={() => endStep()}
        >
          Confirmar transferência
        </LoadingButton>
      </ThirdSection>
    </Container>
  )
}

const LoadingButton = styled(Button)`
  background-color: ${UMEColors.blue.dark};
  color: white;

  &:hover {
    opacity: 0.5;
  }
`

const Container = styled.div`
  min-height: 300px;

  width: 100%;
  border-radius: 8px;
  background-color: ${UMEColors.white.primary};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);

  padding: 1vh 1vw 1vh 1vw;

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
`

const FirstSection = styled.div`
  flex: 1;
  margin: 10px;
`
const SecondSection = styled.div`
  flex: 1;
  margin: 10px;
  border-right: 1px solid rgba(58, 58, 58, 0.3);
  border-left: 1px solid rgba(58, 58, 58, 0.3);
`
const ThirdSection = styled.div`
  flex: 1;
  margin: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > p {
    color: ${UMEColors.black.primary};
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }
`

// FIRST SECTION
const ResumeInformation = styled.div`
  margin: 1vh 0 1vh 2vw;

  & > p:nth-child(1) {
    // TODO - Not in Design Kit
    color: #030202;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
  }
  & > p:nth-child(2) {
    margin-top: 20px;
    color: ${UMEColors.black.primary};
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
  }
  & > p:nth-child(3) {
    color: ${UMEColors.black.primary};
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
  }
`

const StepDescription = styled.p`
  // TODO - Not in design kit
  color: #030202;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;

  margin: 0;
  text-align: left;
  text-wrap: wrap;
`

const UploadContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
`

const FormContainer = styled.div`
  width: 220px;
  min-height: 247px;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`

const FormDescription = styled.div`
  text-align: left;
  width: 100%;

  & > p:nth-child(1) {
    color: ${UMEColors.black.primary};
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    margin: 0px;
  }

  & > p:nth-child(2) {
    color: ${UMEColors.gray.spanish};
    font-family: Roboto;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 11px;
  }
`
const EmailTextInput = styled(TextInput)`
  margin-top: 4px;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid ${UMEColors.gray.light};
  border-radius: 4px;
  background-color: ${UMEColors.white.primary};

  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.47px;
`
