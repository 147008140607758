import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import {
  IDebtFundingPackResponse,
  IPlaceSearchResponse,
  IPostGooglePlaceSearch,
  IPutRetailerCategories,
  IPutRetailerFinalBankAccount,
  IPutRetailerIntermediaryBankAccount,
  IPutStoreFinalBankAccount,
  IPutStoreIntermediaryBankAccount,
  IPutStorePlace,
  IRetailerCategoriesResponse,
  IRetailerResponse,
  IRetailersResponse,
  IStoreResponse,
  IStoresResponse,
  PostRetailerRequest,
  PostStoreRequest,
  PostStoreResponse,
} from './interfaces/retailers.interfaces'
import { FetchRetailerStoresResponse, FetchStoreResponse } from './interfaces/stores.res'

export default class RetailersService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async getRetailers(retailerIds?: string[]): Promise<IRetailersResponse> {
    const response = await this.axiosInstance.get<IRetailersResponse>(`/retailers`, {
      params: retailerIds,
    })
    return response.data
  }

  async getStores(storeIds?: string[], status?: string): Promise<IStoresResponse> {
    const response = await this.axiosInstance.get<IStoresResponse>(`/retailers/stores`, {
      params: { storeIds, status },
    })
    return response.data
  }

  async getCategories(): Promise<IRetailerCategoriesResponse> {
    const response = await this.axiosInstance.get<IRetailerCategoriesResponse>(`/retailers/categories`)
    return response.data
  }

  async getDebtFundingPacks(): Promise<IDebtFundingPackResponse> {
    const response = await this.axiosInstance.get<IDebtFundingPackResponse>(`/retailers/debt-funding-packs`)
    return response.data
  }

  async getStoreById(storeId: string): Promise<FetchStoreResponse> {
    const response = await this.axiosInstance.get<FetchStoreResponse>(`/retailers/stores/${storeId}`)
    return response.data
  }

  async getStoreLogoById(storeId: string) {
    const response = await this.axiosInstance.get(`/retailers/stores/${storeId}/logo`)
    return response.data
  }

  async createStore(body: PostStoreRequest): Promise<PostStoreResponse> {
    const response = await this.axiosInstance.post<PostStoreResponse>(`/retailers/stores`, body)
    return response.data
  }

  async putStoreLogo(storeId: string, body: File) {
    const response = await this.axiosInstance.put(`/retailers/stores/${storeId}/logo`, body)
    return response.data
  }

  async putStoreIntermediaryBankAccount(
    storeId: string,
    body: IPutStoreIntermediaryBankAccount
  ): Promise<IStoreResponse> {
    const response = await this.axiosInstance.put<IStoreResponse>(
      `/retailers/stores/${storeId}/bank-account/intermediary`,
      body
    )
    return response.data
  }

  async putStoreFinalBankAccount(storeId: string, body: IPutStoreFinalBankAccount): Promise<IStoreResponse> {
    const response = await this.axiosInstance.put<IStoreResponse>(
      `/retailers/stores/${storeId}/bank-account/final`,
      body
    )
    return response.data
  }

  async searchGooglePlace(body: IPostGooglePlaceSearch): Promise<IPlaceSearchResponse> {
    const response = await this.axiosInstance.post<IPlaceSearchResponse>(`/retailers/stores/google-place/search`, body)
    return response.data
  }

  async putGooglePlaceId(id: string, body: IPutStorePlace): Promise<IStoreResponse> {
    const response = await this.axiosInstance.put<IStoreResponse>(`/retailers/stores/${id}/google-place/id`, body)
    return response.data
  }

  async getRetailerStores(retailerId: string): Promise<FetchRetailerStoresResponse> {
    const response = await this.axiosInstance.get<FetchRetailerStoresResponse>(`/retailers/${retailerId}/stores`)
    return response.data
  }

  async getRetailerLogoById(retailerId: string): Promise<string> {
    const response = await this.axiosInstance.get<string>(`/retailers/${retailerId}/logo`)
    return response.data
  }

  async createRetailer(body: PostRetailerRequest): Promise<IRetailerResponse> {
    const response = await this.axiosInstance.post<IRetailerResponse>(`/retailers`, body)
    return response.data
  }

  async putRetailerLogo(retailerId: string, body: any) {
    const response = await this.axiosInstance.put(`/retailers/${retailerId}/logo`, body)
    return response.data
  }

  async updateRetailerStatus(retailerId: string): Promise<IRetailerResponse> {
    const response = await this.axiosInstance.post<IRetailerResponse>(`/retailers/${retailerId}/status`)
    return response.data
  }

  async putRetailerIntermediaryBankAccount(
    retailerId: string,
    body: IPutRetailerIntermediaryBankAccount
  ): Promise<IRetailerResponse> {
    const response = await this.axiosInstance.put<IRetailerResponse>(
      `/retailers/${retailerId}/bank-account/intermediary`,
      body
    )
    return response.data
  }

  async putRetailerFinalBankAccount(
    retailerId: string,
    body: IPutRetailerFinalBankAccount
  ): Promise<IRetailerResponse> {
    const response = await this.axiosInstance.put<IRetailerResponse>(
      `/retailers/${retailerId}/bank-account/final`,
      body
    )
    return response.data
  }

  async putRetailerCategories(retailerId: string, body: IPutRetailerCategories): Promise<IRetailerResponse> {
    const response = await this.axiosInstance.put<IRetailerResponse>(`/retailers/${retailerId}/categories`, body)
    return response.data
  }

  async updateRetailerDebtFundingPackId(retailerId: string, debtFundingPackId: string): Promise<void> {
    const response = await this.axiosInstance.put<void>(
      `/retailers/${retailerId}/debt-funding-packs/${debtFundingPackId}`
    )
    return response.data
  }

  async getRetailerById(retailerId: string): Promise<IRetailerResponse> {
    const response = await this.axiosInstance.get<IRetailerResponse>(`/retailers/${retailerId}`)
    return response.data
  }
}
