export interface IImageResponse {
  id: string
  imagebase64: string
  providerResponse?: any
  status: ImageStatus
  label: ImageLabel
  fraudBiometryProcessId?: string
  borrowerId?: string
  livenessCheck?: ILivenessCheck
  livenessResponse?: IWebDataDomeLivenessResponse
  generatedAt?: BiometryGeneratedAt
}

export interface IWebDataDomeLivenessResponse {
  isLikelyRealPerson?: boolean
  success: boolean
  callData?: {
    date: string
    path: string
    requestMethod: string
    epochSecond: number
    tid: string
  }
  additionalSessionData?: {
    isAdditionalDataPartiallyIncomplete: boolean
  }
  liveness2DStatusMessage?: string
  liveness2DStatusEnumInt?: number
  id: string
  error: boolean
  errorMessage?: string
  wasProcessed: boolean
}

export interface IDocumentImageResponse {
  missingSide?: DocumentLabel
  images: IImageResponse[]
}

export interface IProcessStatusResponse {
  id: string
  status: ProcessStatus
  score?: number
  hadConflict: boolean
}

export enum ImageStatus {
  SUCCESS = 'SUCCESS',
  UNSUPORTED_BASE64 = 'UNSUPORTED_BASE64',
  FACE_NEEDS_CENTERING = 'FACE_NEEDS_CENTERING',
  FACE_TOO_FAR = 'FACE_TOO_FAR',
  FACE_TOO_CLOSE = 'FACE_TOO_CLOSE',
  TOO_DARK = 'TOO_DARK',
  OUT_OF_FOCUS = 'OUT_OF_FOCUS',
  FACE_TILTED = 'FACE_TILTED',
  FACE_WITH_GLASSES = 'FACE_WITH_GLASSES',
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  INVALID_DOCUMENT = 'INVALID_DOCUMENT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export enum ImageLabel {
  PORTRAIT = 'portrait',
  CNH_FRONT = 'cnh_frente',
  CNH_BACK = 'cnh_verso',
  RG_FRONT = 'rg_frente',
  RG_BACK = 'rg_verso',
  MILITARY_RG_FRONT = 'identidade_militar_frente',
  MILITARY_RG_BACK = 'identidade_militar_verso',
  PASSPORT_FRONT = 'passaporte',
  PASSPORT_BACK = 'passaporte_verso',
  CTPS_FRONT = 'carteira_de_trabalho',
  CTPS_BACK = 'carteira_de_trabalho_verso',
  FOREING_ID_FRONT = 'rg_estrangeiro',
  FOREING_ID_BACK = 'rg_estrangeiro_verso',
  NEW_CNH_FRONT = 'nova_cnh_frente',
  NEW_CNH_BACK = 'nova_cnh_verso',
  DIGITAL_RG_FRONT = 'rg_digital_frente',
  DIGITAL_RG_BACK = 'rg_digital_verso',
  DIGITAL_CNH_FRONT = 'cnh_digital_frente',
  DIGITAL_CNH_BACK = 'cnh_digital_verso',
}

export enum DocumentLabel {
  CNH_FRONT = 'cnh_frente',
  CNH_BACK = 'cnh_verso',
  RG_FRONT = 'rg_frente',
  RG_BACK = 'rg_verso',
  MILITARY_RG_FRONT = 'identidade_militar_frente',
  MILITARY_RG_BACK = 'identidade_militar_verso',
  PASSPORT_FRONT = 'passaporte',
  PASSPORT_BACK = 'passaporte_verso',
  CTPS_FRONT = 'carteira_de_trabalho',
  CTPS_BACK = 'carteira_de_trabalho_verso',
  FOREING_ID_FRONT = 'rg_estrangeiro',
  FOREING_ID_BACK = 'rg_estrangeiro_verso',
  NEW_CNH_FRONT = 'nova_cnh_frente',
  NEW_CNH_BACK = 'nova_cnh_verso',
  DIGITAL_RG_FRONT = 'rg_digital_frente',
  DIGITAL_RG_BACK = 'rg_digital_verso',
  DIGITAL_CNH_FRONT = 'cnh_digital_frente',
  DIGITAL_CNH_BACK = 'cnh_digital_verso',
}

export enum ILivenessCheck {
  LIVE = 'live',
  SPOOF = 'spoof',
  ERROR = 'error',
}

export enum BiometryGeneratedAt {
  MOBILE_APP = 'mobile-app',
  CLIENT_BROWSER = 'client-browser',
}

export enum ProcessStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  CONFLICT = 'CONFLICT',
  CANCELED = 'CANCELED',
  SPOOF = 'SPOOF',
}
