import * as React from 'react'
import { green } from '../../../utils/colors'

export interface ValueSummaryProps {
  hasSelectedRows: boolean
  hasFees: boolean
  text: string
}

export const ValueSummary = ({ text, hasSelectedRows, hasFees }: ValueSummaryProps) => {
  return (
    <div
      style={{
        color: hasFees && !hasSelectedRows ? `${green.primary900}` : 'inherit',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '1.5rem',
        lineHeight: '1.2rem',
      }}
    >
      {text}
    </div>
  )
}
