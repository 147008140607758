import { ReactComponent as LoadingBaseIcon } from '../../common/assets/images/bx-loader-alt.svg'
import styled from 'styled-components'

export const LoadingIcon = styled(LoadingBaseIcon)`
    align-self: center;
    height: 100%;
    animation: load infinite 1s linear;

    @keyframes load {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`
