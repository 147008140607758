import { IInstallmentsValues, ISingleInstallment } from './installment.interfaces'

export type PaymentMethodType = 'STORE' | 'INVOICE' | 'RENEGOTIATION'

export type InvoiceStatusType = 'paid' | 'pending' | 'expired' | 'canceled'

export interface IInvoice {
  id: string
  createdOn: string
  modifiedOn: string
  partnerInvoiceId: string
  operatorId: string
  status: string
  dueDate: string
  partnerUpdates: any
  paymentDate: string
  liquidationDate: string
  barcode: string
  url: string
  paidValue: number
  expectedValue: number
  value: number
  installmentsValues: IInstallmentsValues[]
  installments: ISingleInstallment[]
  numberOfInstallments: number
  pixCode?: string
  pixQrCodeUrl?: string
  debtFundingPacksSplit?: IDebtFundingPacksSplit[]
  generatedAt?: InvoiceGeneratedAt
}

interface IDebtFundingPacksSplit {
  debtFundingPackId: string
  installmentValues?: IInstallmentsValues[]
  splitTotalValue: number
  splitTotalValueInCents: number
}

export enum InvoiceSource {
  SINGLE_INSTALLMENT = 'SINGLE_INSTALLMENT',
  MULTIPLE_INSTALLMENTS = 'MULTIPLE_INSTALLMENTS',
  DETACHED = 'DETACHED',
  RENEGOTIATION = 'RENEGOTIATION',
}

export type InvoiceSourceType =
  | InvoiceSource.SINGLE_INSTALLMENT
  | InvoiceSource.MULTIPLE_INSTALLMENTS
  | InvoiceSource.DETACHED
  | InvoiceSource.RENEGOTIATION

export enum InvoiceGeneratedAt {
  MOBILE_APP = 'mobile-app',
  CHATBOT = 'chatbot',
  BACKOFFICE = 'backoffice',
}

export type InvoiceGeneratedAtType =
  | InvoiceGeneratedAt.MOBILE_APP
  | InvoiceGeneratedAt.CHATBOT
  | InvoiceGeneratedAt.BACKOFFICE

export interface IGetInvoicesQueryParams {
  installmentsIds?: string[]
  borrowerId?: string
  partnerInvoiceId?: string
}
