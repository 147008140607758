import { getAuthRootUrl, getBffBackofficeRootUrl, getKeycloackRootUrl } from '../../config/config'
import KeycloakService from '../keycloak/Keycloak.service'
import ApplicationEngineService from './application-engine/application-engine.service'
import { AuthService } from './auth/auth.service'
import BackofficeApiService from './backoffice-api/backoffice.service'
import BillingsService from './billings/billings.service'
import BorrowersService from './borrowers/borrowers.service'
import CoordinatorService from './coordinator/coordinator.service'
import DatalakeAcessoService from './datalake-acesso/datalake-acesso.service'
import LiquidationOriginationsService from './liquidation-originations/liquidation-originations.service'
import RetailersService from './retailers/retailers.service'

type ApiService = {
  auth: AuthService
  borrowers: BorrowersService
  coordinator: CoordinatorService
  retailers: RetailersService
  backoffice: BackofficeApiService
  datalakeAcesso: DatalakeAcessoService
  billings: BillingsService
  applicationEngine: ApplicationEngineService
  liquidationOriginations: LiquidationOriginationsService
}

export const bffBackofficeApiService: ApiService = {
  auth: new AuthService(),
  borrowers: new BorrowersService(),
  coordinator: new CoordinatorService(),
  retailers: new RetailersService(),
  backoffice: new BackofficeApiService(),
  datalakeAcesso: new DatalakeAcessoService(),
  billings: new BillingsService(),
  applicationEngine: new ApplicationEngineService(),
  liquidationOriginations: new LiquidationOriginationsService(),
}

const startBffBackofficeApiService = (): void => {
  const authToken = localStorage.getItem('id_token')

  bffBackofficeApiService.auth._init(getBffBackofficeRootUrl(), authToken)
  bffBackofficeApiService.borrowers._init(getBffBackofficeRootUrl(), authToken)
  bffBackofficeApiService.coordinator._init(getBffBackofficeRootUrl(), authToken)
  bffBackofficeApiService.retailers._init(getBffBackofficeRootUrl(), authToken)
  bffBackofficeApiService.backoffice._init(getBffBackofficeRootUrl(), authToken)
  bffBackofficeApiService.billings._init(getBffBackofficeRootUrl(), authToken)
  bffBackofficeApiService.applicationEngine._init(getBffBackofficeRootUrl(), authToken)
  bffBackofficeApiService.datalakeAcesso._init(getBffBackofficeRootUrl(), authToken)
  bffBackofficeApiService.liquidationOriginations._init(getBffBackofficeRootUrl(), authToken)
}

export const startSSOAuthService = async (token: string): Promise<void> => {
  const url = getBffBackofficeRootUrl()

  if (token) {
    bffBackofficeApiService.auth._init(url, null)
    await bffBackofficeApiService.auth.exchangeTokens(token)
  }
}

export const startApiServices = (): void => {
  startBffBackofficeApiService()
}

export const authService = new AuthService()
authService._init(getAuthRootUrl(), null)

export const keyCloackService = new KeycloakService()
keyCloackService.init(getKeycloackRootUrl())
