import React from 'react'
import { Box, Heading } from 'grommet'
import styled from 'styled-components'

interface Props {
  title: string
  icon: JSX.Element
}

const RetailersBenchTitle = ({ title, icon }: Props) => (
  <Box margin={{ top: 'medium' }} direction="row" align="center" gap="small">
    {icon}
    <Title>{title}</Title>
  </Box>
)

const Title = styled(Heading)`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin: 0 1px;
  text-align: initial;
`

export default RetailersBenchTitle
