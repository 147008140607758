import { Loading, Text } from '@bit/ume.design-kit.ui'
import { Box } from 'grommet'
import * as React from 'react'
import { ResponsiveGrid } from '../../atoms/ResponsiveGrid/ResponsiveGrid'
import { InfoTextStyle, LabelStyle } from '../../pages/CS-Profile/ContractsSummaryCard/style'
import { formatDate, formatNumberToBRL } from '../../utils'
import { CardStyle, DataBoxStyle } from './style'

export interface IBillingDetailsProps {
  billing?: any | Error
  downloadingButton: any
}

export default class BillingDetails extends React.PureComponent<IBillingDetailsProps> {
  public render() {
    const { billing } = this.props

    const errorCard = (
      <Text color="red" light>
        Não foi possível carregar os dados para esta cobrança.
      </Text>
    )

    const statusEmail = (row: any) => {
      let emailTimestamp = formatDate(row.lastEmailTimestamp)
      if (!row.lastEmailStatus || row.lastEmailStatus === 'ERROR') {
        return 'Não enviado'
      } else {
        switch (row.lastEmailStatus) {
          case 'SENT':
            return `Enviado -- ${emailTimestamp}`
          case 'ERROR':
            return `Não enviado -- ${emailTimestamp}`
          case 'WARNING':
            return `Enviado parcialmente -- ${emailTimestamp}`
        }
      }
    }

    const BillingDetailsCard = (billing: any) => (
      <Box>
        <ResponsiveGrid columns="200px" gap="small">
          <DataBoxStyle>
            <LabelStyle>ID:</LabelStyle>
            <InfoTextStyle>{billing.id}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Criado em:</LabelStyle>
            <InfoTextStyle>{formatDate(billing.createdOn)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Referente a pagamentos de:</LabelStyle>
            <InfoTextStyle>{`${formatDate(billing.startDate)} - ${formatDate(billing.endDate)}`}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Venc. Boleto</LabelStyle>
            <InfoTextStyle>{billing.invoice? formatDate(billing.invoice.dueDate) : '---'}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Valor do Boleto</LabelStyle>
            <InfoTextStyle>{billing.invoice? formatNumberToBRL(billing.invoice.value) : '---'}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Status do Email</LabelStyle>
            <InfoTextStyle>{statusEmail(billing)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Varejo</LabelStyle>
            <InfoTextStyle>{billing.retailerName}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Loja</LabelStyle>
            <InfoTextStyle>{billing.storesName}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
          <LabelStyle>Pagamentos</LabelStyle>
          <InfoTextStyle>{this.props.downloadingButton}</InfoTextStyle>
          </DataBoxStyle>

        </ResponsiveGrid>
      </Box>
    )
    return (
      <CardStyle>
        {billing === undefined ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : billing instanceof Error ? (
          errorCard
        ) : (
          BillingDetailsCard(billing)
        )}
      </CardStyle>
    )
  }
}
