import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { AccordionPanel, Box } from 'grommet'
import { Alert, FormNextLink, StatusDisabled, StatusGood } from 'grommet-icons'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { IBatchStepResponse } from '../../services/bff-backoffice/liquidation-originations/interfaces/liquidation-originations.interfaces'

export type IBatchStatus = 'PENDING' | 'FINISHED' | 'WAITING'

interface IBatchAccordionProps {
  stepOrder: string
  from: string
  to: string
  status: IBatchStatus
  children?: any
  step: IBatchStepResponse
}

// TODO  Fix size
const iconStyle = {
  display: 'inline-block',
  fill: UMEColors.black.primary,
  stroke: UMEColors.black.primary,
  height: 35,
  width: 35,
  margin: '0px 16px 0px 16px',
}

export const BatchAccordion = (props: IBatchAccordionProps) => {
  const { from, to, stepOrder, status, step } = props
  const endDate = step.endTimestamp ? moment(new Date(step.endTimestamp)).format('HH:mm [-] L') : ''

  let backgroundColor: string
  let statusText: string
  let StatusIcon: JSX.Element

  switch (status) {
    case 'PENDING': {
      backgroundColor = '#f7b500'
      statusText = 'Pendente'
      StatusIcon = <Alert style={iconStyle} />
      break
    }
    case 'FINISHED': {
      backgroundColor = '#28C264'
      statusText = 'Concluído'
      StatusIcon = <StatusGood style={iconStyle} />
      break
    }
    case 'WAITING': {
      backgroundColor = '#DADADA'
      statusText = 'Aguardando'
      StatusIcon = <StatusDisabled style={iconStyle} />
      break
    }
  }

  const renderAccordionTitle = () => {
    return (
      <StepHeader>
        <StepTitleContainer>
          <StepOrderText> {stepOrder} </StepOrderText>
          <StepTitle>
            {from}
            <FormNextLink style={{ ...iconStyle, stroke: UMEColors.white.primary }} />
            {to}
          </StepTitle>
        </StepTitleContainer>

        <StepStatus>
          <span>Status:</span>
          {StatusIcon}
          <Status>{statusText}</Status>
        </StepStatus>
      </StepHeader>
    )
  }

  return (
    <>
      <Box justify="end" align="center" direction="row">
        <span>
          <strong>Operador:</strong>
        </span>
        <OperatorName>{step.operatorId}</OperatorName>
        <ConclusionDate>
          <strong>Data/Hora:</strong>
        </ConclusionDate>
        <EndDate>{endDate}</EndDate>
      </Box>
      <StyledAcoordionPanel backgroundColor={backgroundColor} focusIndicator={false} label={renderAccordionTitle()}>
        {props.children}
      </StyledAcoordionPanel>
    </>
  )
}

const OperatorName = styled.span`
  margin-left: 10px;
`

const ConclusionDate = styled.span`
  margin-left: 5vw;
`

const EndDate = styled.span`
  margin-right: 6vw;
  margin-left: 10px;
`

const StepOrderText = styled.span`
  width: 170px;
`

const Status = styled.span`
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500 !important;
  letter-spacing: 0;
  line-height: 28px;

  width: 140px;
`

const StepStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > span {
    color: ${UMEColors.black.primary};
    font-family: Roboto;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 28px;
  }
`

const StepTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  & > span {
    color: ${UMEColors.black.primary};
    font-family: Roboto;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 28px;
  }
`

// TODO - Remove border on click
const StyledAcoordionPanel = styled(AccordionPanel)`
  border-radius: 8px 8px 0 0;
  background-color: ${(props: any) => (props.backgroundColor ? props.backgroundColor : UMEColors.white.primary)};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);

  border: none;
`

const StepTitle = styled.div`
  margin-left: 48px;

  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;

  display: flex;
  flex-direction: row;
  align-items: center;
`

const StepHeader = styled.div`
  min-height: 60px;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  padding: 0px 3vw 0px 3vw;
`
