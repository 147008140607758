import styled from "styled-components";

export const ContractViewPageContainer = styled.div`
    background-color: white;
    display: flex;
    flex-flow: column;
    height: 100%;
    margin: 0;
    padding: 2em;
`

export const KeyValueContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: flex-start;
    margin: 0;
    padding: 0;
`

export const FormInputGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 1em;
    width: 100%;
`
export const Label = styled.label`
    font-weight: bold;
    font-size: 1.4em;
`

export const DatePickerContainer = styled.div`
    width: auto;
`

export const RenegotiationSummaryContainer = styled.div`
    display: flex;
    padding: 2em;
    flex-wrap: wrap;
    flex-direction: row-reverse;
`

export const ValueWithLabelContainer = styled.div`
    label {
        font-weight: bold;
        font-size: 1.4em;
    }
    p {
        font-size: 1.4em;
        margin-left: 1em;
    }
    display: flex;
    justify-content: space-between;
    margin: 1em;
`

export const RenegotiationChoicesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0;
`
