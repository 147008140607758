import React, { Component } from 'react'
import { TextDatePicker } from '../TextDatePicker/TextDatePicker'
import moment from 'moment'
import { validateFullDate } from '../../utils'
import { Menu } from 'grommet'
import { DateRangeContainer, ParagraphLeft } from './style'
import { RegularButton } from '../../atoms/Buttons/style'

const REGULAR_DATE_FORMAT = 'DD/MM/YYYY'
const today = moment(new Date()).format(REGULAR_DATE_FORMAT)

export default class DateRange extends Component<any, any> {
  constructor(props: any) {
    super(props)
    let { options } = this.props
    let defaultOption = options && Array.isArray(options) && options.length >= 1 ? options[0].label : 'Menu'
    this.state = {
      startDate: today,
      endDate: today,
      option: defaultOption,
    }
  }

  startDateChanged = (event: any) => {
    this.setState({ startDate: event.target.value })
  }

  endDateChanged = (event: any) => {
    this.setState({ endDate: event.target.value })
  }

  handleSearch = () => {
    let { startDate, endDate, option } = this.state
    let sDate,
      eDate = null

    if (startDate) {
      let array = startDate.split('/')
      if (array && array.length === 3) sDate = validateFullDate(array[0], array[1], array[2])
    }
    if (endDate) {
      let array = endDate.split('/')
      if (array && array.length === 3) eDate = validateFullDate(array[0], array[1], array[2])
    }

    this.props.onSearch(sDate, eDate, option)
  }

  render() {
    return (
      <DateRangeContainer>
        <div style={{alignSelf: 'center'}}>
          <ParagraphLeft> Data inicial: </ParagraphLeft>
          <TextDatePicker
            separator={'/'}
            defaultValue={today}
            onChange={this.startDateChanged}
            style={{ textAlign: 'center' }}
          />
        </div>

        <div style={{alignSelf: 'center'}}>
          <ParagraphLeft> Data final: </ParagraphLeft>
          <TextDatePicker
            defaultValue={today}
            separator={'/'}
            onChange={this.endDateChanged}
            style={{ textAlign: 'center' }}
          />
        </div>

        <Menu
          style={{ marginLeft: '2%' }}
          label={this.state.option}
          items={this.props.options.map((el: any) => {
            return {
              label: el.label,
              onClick: () => {
                this.setState({ option: el.label })
              },
            }
          })}
        />
        <RegularButton onClick={this.handleSearch}> Buscar </RegularButton>
      </DateRangeContainer>
    )
  }
}
