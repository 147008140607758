import * as React from 'react'
import { TextGreenBackground, TextOrangeBackground, TextRedBackground } from '../../../atoms/Texts/style'
import { ISingleInstallment } from '../../../services/bff-backoffice/coordinator/interfaces/installment.interfaces'
import { booleanToSimOrNao, formatDate, formatDateTreatingError, formatNumberToBRL } from '../../../utils'

export const renegotiationInstallmentsTableConfig = [
  { property: 'installmentOrder', label: '#' },
  { property: 'id', label: 'identificador' },
  { property: 'dueDate', label: 'vencimento', format: formatDate },
  { property: 'installmentValue', label: 'valor parcela', format: formatNumberToBRL },
  { property: 'interest', label: 'juros', format: formatNumberToBRL },
  { property: 'defaultFine', label: 'multa mora', format: formatNumberToBRL },
  { property: 'defaultInterest', label: 'juros mora', format: formatNumberToBRL },
  { property: 'monetaryCorrection', label: 'correção', format: formatNumberToBRL },
  { property: 'earlyPaymentDiscount', label: 'desc pag adiantado', format: formatNumberToBRL },
  { property: 'paymentTimestamp', label: 'dt pagamento', format: formatDateTreatingError },
  { property: 'paidValue', label: 'valor pago', format: formatNumberToBRL },
  { property: 'paymentDue', label: 'valor devido', format: formatNumberToBRL },
  {
    property: 'status',
    label: 'status',
    format: (status: string) => {
      switch (status) {
        case 'PAID':
          return <TextGreenBackground> PAGO </TextGreenBackground>
        case 'PENDING':
          return <TextOrangeBackground> PENDENTE </TextOrangeBackground>
        case 'PAYMENT_OVERDUE':
          return <TextRedBackground> ATRASADO </TextRedBackground>
      }
    },
  },
  { property: 'isRenegotiation', label: 'parcela renegociada?', format: booleanToSimOrNao },
  { property: 'renegotiatedValue', label: 'valor renegociado', format: formatNumberToBRL },
  {
    property: 'method',
    label: 'forma de pag',
    formatObject: (i: ISingleInstallment) => {
      if (!i.paymentTimestamp) {
        return '--'
      }
      switch (i.method) {
        case 'STORE':
          return 'loja'
        case 'INVOICE':
          return 'boleto'
        case 'RENEGOTIATION':
          return 'renegociação'
        default:
          return '--'
      }
    },
  },
  { property: 'discount', label: 'desconto', format: formatNumberToBRL },
  { property: 'isFromRenegotiationContract', label: 'é de renegociação?', format: booleanToSimOrNao },
  { property: 'expectedValueOnDate', label: 'valor presente', format: formatNumberToBRL },
]
