import { ZipCodeInfoResponse } from '../../borrowers/interfaces/borrower.interfaces'
import { LimitType } from '../../retailers/interfaces/retailers.interfaces'

export interface IBorrowerResponse {
  id: string
  createdOn: string
  cpf: string
  name?: string
  email?: string
  cep?: string
  fcmToken?: string
  birthDate?: string
  phoneNumber?: string
  passwordReset?: boolean
  externalCollection?: BorrowerExternalCollectionStatus
  debtFundingPackId?: string
  billDueDay?: number
  enableHighRecurrence: boolean
  phones?: IPhone[]
  zipCodeInfo?: ZipCodeInfoResponse
  addressNumber?: string
  addressComplement?: string
  borrowerSettings?: BorrowerSettings
  limits?: ILimitRecord[]
}

export interface BorrowerSettings {
  disablePurchase: boolean
}
export interface Borrower extends IBorrowerResponse {
  enableHighRecurrence: boolean
  borrowerSettings?: BorrowerSettings
}

export interface IBorrowerLimit {
  approvedLimit: number
  availableLimit: number
  limitType: string
  isBlocked: boolean
}

export interface IBorrowerLimits {
  limits: IBorrowerLimit[]
}

export interface IBorrowerEdition {
  name?: string
  email?: string
  birthDate?: string
}

export interface IPhone {
  id: string
  createdOn: string
  modifiedOn: string
  ownerId: string
  phoneNumber: string
  phoneType: PHONE_TYPE
}

export interface BorrowerSettingsRecord {
  id: string
  createdOn: string
  modifiedOn: string
  borrowerId: string
  disablePurchase: boolean
}

export interface ILimitRecord {
  id: string
  borrowerId: string
  createdOn: string
  limit: number
  isBlockedBy?: string
  limitType: LimitType
  policyId?: string
}

export enum ImageLabel {
  PORTRAIT = 'portrait',
  CNH_FRONT = 'cnh_frente',
  CNH_BACK = 'cnh_verso',
  RG_FRONT = 'rg_frente',
  RG_BACK = 'rg_verso',
  MILITARY_RG_FRONT = 'identidade_militar_frente',
  MILITARY_RG_BACK = 'identidade_militar_verso',
  PASSPORT_FRONT = 'passaporte',
  PASSPORT_BACK = 'passaporte_verso',
  CTPS_FRONT = 'carteira_de_trabalho',
  CTPS_BACK = 'carteira_de_trabalho_verso',
  FOREING_ID_FRONT = 'rg_estrangeiro',
  FOREING_ID_BACK = 'rg_estrangeiro_verso',
  NEW_CNH_FRONT = 'nova_cnh_frente',
  NEW_CNH_BACK = 'nova_cnh_verso',
}

export enum PHONE_TYPE {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export type BorrowerExternalCollectionStatus = 'BLOCKED' | 'COMAX_SENT' | 'COMAX_RETURN' | 'ALLOWED'
