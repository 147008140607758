import axios, { AxiosInstance } from 'axios'
import { keyCloackService } from '..'
import { getAxiosInstance } from '../utils'
import { IExchangeTokenResponse, ILoginProfileResponse } from './dto/Auth.dto'

export class AuthService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async exchangeTokens(authorizationToken: string): Promise<IExchangeTokenResponse> {
    const response = await this.axiosInstance.get<IExchangeTokenResponse>(`/auth/exchange-tokens`, {
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
      },
    })

    this.setToken(response.data.accessToken)
    return response.data
  }

  async decodeJwt(): Promise<ILoginProfileResponse> {
    const response = await this.axiosInstance.get<ILoginProfileResponse>(`/auth`)

    this.setUser(response.data)
    return response.data
  }

  isAuthorized(authorizedRoles: string[]): boolean {
    const user = this.getUser()

    if (user && authorizedRoles.indexOf(user.role) !== -1) {
      return true
    } else {
      return false
    }
  }

  isLogged(): boolean {
    const token = this.getToken()
    const user = this.getUser()
    return !!token && !!user
  }

  async logout() {
    localStorage.removeItem('id_token')
    localStorage.removeItem('user')
    await keyCloackService.logout()
  }

  setUser(user: ILoginProfileResponse) {
    localStorage.setItem('user', JSON.stringify(user))
  }

  getUser(): ILoginProfileResponse | undefined {
    const user = localStorage.getItem('user')
    const userParsed = JSON.parse(user || '[]')
    if (userParsed.length === 0) {
      return undefined
    } else if (userParsed.exp < Date.now() / 1000) {
      return undefined
    } else {
      return userParsed
    }
  }

  setToken(token: string) {
    localStorage.setItem('id_token', token)
  }

  getToken(): string | null {
    return localStorage.getItem('id_token')
  }
}
