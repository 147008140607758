import React from 'react'
import { FormSearch, Basket } from 'grommet-icons'
import { Dropdown, Heading } from '@bit/ume.design-kit.ui'
import { violet } from '@bit/ume.design-kit.ui/utils/_colors'
import { Component, Container } from './styles'
import { Box } from 'grommet'

export interface IStorePickerProps {
  onPickStore?: (options: any) => void
  stores: any[] | any
  storesOptions?: any[] | any
  storeValue?: string | undefined
}

const StorePicker = (props: IStorePickerProps) => {
  return(
    <>
      {
        props.storesOptions.length === 0 ?
        null :
      <Box animation="zoomIn" margin="none">
        <Container>
          <Component>
            <Basket color={violet.primary} />
            <Heading level={3} size="small" margin="0 8px">
              Escolha a Loja
            </Heading>
          </Component>
          <Dropdown
            id="select-store"
            name="select-store"
            placeholder="Selecione a Loja"
            open={false}
            value={props.storeValue}
            options={props.storesOptions}
            icon={<FormSearch />}
            onChange={props.onPickStore}
            style={{ width: 220 }}
          />
        </Container>
      </Box>
      }
    </>
  )
}

export default StorePicker