import { Button, Clipboard, Loading } from '@bit/ume.design-kit.ui'
import { blue, green, red, white } from '@bit/ume.design-kit.ui/utils/_colors'
import { Box } from 'grommet'
import moment from 'moment'
import * as React from 'react'
import { toaster } from '../../App'
import { TextDatePicker } from '../../molecules'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { IInvoice, InvoiceGeneratedAt } from '../../services/bff-backoffice/coordinator/interfaces/invoice.interfaces'
import { RenegotiationPaymentResponse } from '../../services/bff-backoffice/coordinator/interfaces/renegotiation.interfaces'
import { formatNumberToBRL } from '../../utils'
import {
  ButtonStyle,
  DifferenceTextStyle,
  Divider,
  InvoiceSimulationData,
  InvoiceSimulationLabel,
  LinkAnchor,
  LoadingBox,
  PaymentInvoiceBox,
} from './style'

export interface IRenegotiationInvoiceSimulationModalProps {
  payments: RenegotiationPaymentResponse[]
  numberOfPayments: number
  renegotiationId: string
}

export interface IRenegotiationInvoiceSimulationModalState {
  simulationLoading: boolean
  creatingInvoice: boolean
  simulations: RenegotiationPaymentResponse[] | null
  date: string
  invoiceValue?: number
  renegotiationValue?: string
  createdInvoice?: IInvoice
  paymentIds: string[]
}

export default class RenegotiationInvoiceSimulationModal extends React.PureComponent<
  IRenegotiationInvoiceSimulationModalProps,
  IRenegotiationInvoiceSimulationModalState
> {
  private isCreatingInvoice = false

  constructor(props: IRenegotiationInvoiceSimulationModalProps) {
    super(props)

    this.state = {
      date: '',
      simulationLoading: false,
      creatingInvoice: false,
      paymentIds: this.props.payments.map(payment => payment.id!),
      simulations: null,
    }

    this.isCreatingInvoice = false
  }

  calculateInvoiceValue(payments: RenegotiationPaymentResponse[]) {
    const sum = payments.reduce((total, payment) => {
      return total + (payment.paymentDue ? payment.paymentDue : 0)
    }, 0)
    return sum
  }
  calculateRenegotiationPaymentsInstallmentValue(payments: RenegotiationPaymentResponse[]) {
    const sum = payments.reduce((total, payment) => {
      return total + (payment.value ? Math.round(payment.value * 100) / 100 : 0)
    }, 0)
    return sum
  }
  createInvoice = () => {
    const { paymentIds } = this.state
    const { renegotiationId } = this.props

    if (!paymentIds || !paymentIds.length || !renegotiationId) {
      return
    }

    if (!this.state.date || moment(this.state.date, 'DD/MM/YYYY') < moment(moment().format('YYYY-MM-DD'))) {
      return toaster.showErrorToast('Data inválida.')
    }
    const dueDate = moment(this.state.date, 'DD/MM/YYYY').format('YYYY-MM-DD')

    if (this.isCreatingInvoice) return

    this.isCreatingInvoice = true
    this.setState({ creatingInvoice: true })
    bffBackofficeApiService.coordinator
      .createInvoiceForMultiplePayments(renegotiationId, paymentIds, dueDate, InvoiceGeneratedAt.BACKOFFICE)
      .then((invoice: IInvoice) => {
        this.isCreatingInvoice = false

        this.setState({
          createdInvoice: invoice,
          creatingInvoice: false,
        })
        toaster.showSuccessToast('Boleto emitido com sucesso!')
      })
      .catch((error: any) => {
        this.isCreatingInvoice = false

        this.setState({ creatingInvoice: false })
        toaster.showErrorToast(error.response.data.message)
      })
  }

  onSimulate = () => {
    if (!this.state.date) {
      this.setState({ simulations: null, invoiceValue: undefined, createdInvoice: undefined })
      return toaster.showErrorToast('Data inválida.')
    }
    const dateMoment = moment(this.state.date, 'DD/MM/YYYY')
    if (moment(moment().format('YYYY-MM-DD')) > dateMoment) {
      this.setState({ simulations: null, invoiceValue: undefined, createdInvoice: undefined })
      return toaster.showErrorToast('Data inválida.')
    }
    const date = dateMoment.format('YYYY-MM-DD')
    if (date === 'Invalid date') return toaster.showErrorToast('Data inválida.')
    this.setState({
      createdInvoice: undefined,
    })
    this.setState({ simulationLoading: true })
    bffBackofficeApiService.coordinator
      .getRenegotiationsPaymentsByIds({ ids: this.state.paymentIds, paymentDate: date })
      .then(simulations => {
        this.setState({
          simulations: simulations.payments,
          simulationLoading: false,
          invoiceValue: this.calculateInvoiceValue(simulations.payments),
        })
      })
      .catch(() => {
        toaster.showErrorToast('Erro ao simular os valores da parcela')
        this.setState({ simulationLoading: false })
      })
  }

  onInvoiceDClick = (d: number) => {
    // days

    const targetDate = moment()
      .add(d, 'd')
      .format('DD/MM/YYYY')

    this.setState({
      date: targetDate,
    })
  }

  public render() {
    const { payments } = this.props

    if (!payments || !payments.length) return <h3>Carregando</h3>

    const discount = (diff: number) =>
      diff > 0 ? (
        <DifferenceTextStyle style={{ color: red.primary }}>{'+' + formatNumberToBRL(diff)}</DifferenceTextStyle>
      ) : (
        <DifferenceTextStyle style={{ color: green.darkPastel }}>{formatNumberToBRL(diff)}</DifferenceTextStyle>
      )

    const simulationCard = (payments: RenegotiationPaymentResponse[]) => (
      <Box gap="xsmall">
        <InvoiceSimulationLabel>
          Valor {this.props.numberOfPayments > 1 ? 'das parcelas' : 'da parcela'}
        </InvoiceSimulationLabel>
        <InvoiceSimulationData>
          {formatNumberToBRL(this.calculateRenegotiationPaymentsInstallmentValue(payments))}
        </InvoiceSimulationData>

        <InvoiceSimulationLabel>Valor atual</InvoiceSimulationLabel>
        <InvoiceSimulationData>{formatNumberToBRL(this.calculateInvoiceValue(payments))}</InvoiceSimulationData>

        <Box style={{ margin: '0 0 15px 0' }}>
          <Divider />
        </Box>

        <Box direction="row" justify="between" pad={{ horizontal: '0', vertical: '16px' }}>
          <Button
            onClick={this.onInvoiceDClick.bind(this, 1)}
            color={blue.navy}
            backgroundColor={white.primary}
            style={{ padding: 0 }}
            label="+1"
            margin="0"
            width="3em"
          />
          <Button
            onClick={this.onInvoiceDClick.bind(this, 2)}
            color={blue.navy}
            backgroundColor={white.primary}
            style={{ padding: 0 }}
            label="+2"
            margin="0"
            width="3em"
          />
          <Button
            onClick={this.onInvoiceDClick.bind(this, 3)}
            color={blue.navy}
            backgroundColor={white.primary}
            style={{ padding: 0 }}
            label="+3"
            margin="0"
            width="3em"
          />
          <Button
            onClick={this.onInvoiceDClick.bind(this, 4)}
            color={blue.navy}
            backgroundColor={white.primary}
            style={{ padding: 0 }}
            label="+4"
            margin="0"
            width="3em"
          />
          <Button
            onClick={this.onInvoiceDClick.bind(this, 5)}
            color={blue.navy}
            backgroundColor={white.primary}
            style={{ padding: 0 }}
            label="+5"
            margin="0"
            width="3em"
          />
        </Box>

        <TextDatePicker
          value={this.state.date}
          onChange={event => {
            this.setState({ date: event.target.value })
          }}
          style={{ textAlign: 'center' }}
        />
        <ButtonStyle onClick={() => this.onSimulate()}>Simular</ButtonStyle>

        {this.state.simulationLoading ? (
          <LoadingBox>
            <Loading />
          </LoadingBox>
        ) : (
          <Box>
            <InvoiceSimulationLabel>Valor do boleto</InvoiceSimulationLabel>
            <InvoiceSimulationData>
              {this.state.invoiceValue ? formatNumberToBRL(this.state.invoiceValue) : '-'}
            </InvoiceSimulationData>

            <InvoiceSimulationLabel>Descontos/Acréscimos</InvoiceSimulationLabel>
            <InvoiceSimulationData>
              {this.state.simulations
                ? discount(
                    this.calculateInvoiceValue(this.state.simulations) -
                      this.calculateRenegotiationPaymentsInstallmentValue(payments)
                  )
                : '-'}
            </InvoiceSimulationData>
          </Box>
        )}
        <Box style={{ margin: '15px 0 0 0' }}>
          <Divider />
        </Box>

        <ButtonStyle
          onClick={() => this.createInvoice()}
          disabled={this.state.simulations && this.state.createdInvoice}
          border="none"
          color={white.primary}
          backgroundColor={green.laurel}
        >
          Gerar Boleto
        </ButtonStyle>
        {this.state.creatingInvoice ? (
          <LoadingBox>
            <Loading />
          </LoadingBox>
        ) : (
          <Box>
            {this.state.createdInvoice ? <InvoiceSimulationLabel>Boleto</InvoiceSimulationLabel> : <></>}
            <InvoiceSimulationData onClick={() => toaster.showSuccessToast('Copiado para área de transferência')}>
              {this.state.createdInvoice ? (
                <Clipboard
                  width="175px"
                  label={
                    <LinkAnchor target="_blank" href={this.state.createdInvoice.url + '.pdf'}>
                      Link do boleto
                    </LinkAnchor>
                  }
                  value={this.state.createdInvoice.url + '.pdf'}
                />
              ) : (
                ''
              )}
            </InvoiceSimulationData>

            <InvoiceSimulationData onClick={() => toaster.showSuccessToast('Copiado para área de transferência')}>
              {this.state.createdInvoice ? (
                <Clipboard
                  width="175px"
                  label={<InvoiceSimulationLabel>Código do Boleto</InvoiceSimulationLabel>}
                  value={this.state.createdInvoice.barcode}
                />
              ) : (
                ''
              )}
            </InvoiceSimulationData>
          </Box>
        )}
      </Box>
    )

    return (
      <PaymentInvoiceBox>
        {!payments ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : payments instanceof Error ? (
          <></>
        ) : (
          simulationCard(payments)
        )}
      </PaymentInvoiceBox>
    )
  }
}
