import styled from 'styled-components'

const ErrorStripe = styled.div`
  background: #be3b3b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${(props: { isHidden: boolean }) => (props.isHidden ? '0' : '48px')};
  width: 100%;
  transition: height 400ms cubic-bezier(0.4, 0, 0.23, 1);
  border-radius: 4px;

  h3,
  p {
    color: #f1f1f1;
    margin: 0;
    padding: 0;
    opacity: ${(props: { isHidden: boolean }) => (props.isHidden ? 0 : 1)};
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.23, 1);
    letter-spacing: 0.03em; // will help reading on the high-contrast
  }

  h3 {
    font-weight: bold;
  }
`

export default ErrorStripe
