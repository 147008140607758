import { IContract } from '../../coordinator/interfaces/contracts.interfaces'

export enum ContractStatus {
  CANCELED = 'CANCELED',
  OVERDUE = 'OVERDUE',
  PENDING = 'PENDING',
  PAID = 'PAID',
}

export type ContractStatusType =
  | ContractStatus.CANCELED
  | ContractStatus.OVERDUE
  | ContractStatus.PAID
  | ContractStatus.PENDING

export interface ICSContract extends IContract {
  sumPendingInstallments: number
  status: ContractStatusType
}

export interface ICSContracts {
  contracts: ICSContract[]
}
