import { Card } from '@bit/ume.design-kit.ui'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Image } from 'grommet'
import { History } from 'history'
import React from 'react'
import styled from 'styled-components'

interface Props {
  history: History
  retailerId: string
  logo: string
  fantasyName: string
}

const RetailerBenchCard = ({ fantasyName, logo, retailerId, history }: Props) => {
  const handleClick = () => {
    history.push(`retailers-bench/${retailerId}/applications`)
  }

  return (
    <CardStyle onClick={handleClick}>
      <Title>
        {fantasyName}
      </Title>
      <Image width='100%' src={logo} />
    </CardStyle>
  )
}

const CardStyle = styled(Card)`
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;

  &:hover {
    cursor: pointer;
    box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4) !important;
    transition: 0.3s;
  }
`

const Title = styled.div`
  font-size: 22px;
  color: ${UMEColors.black.primary};
  font-weight: bold;
`

export default RetailerBenchCard
