import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Box, Text } from 'grommet'
import { Clock } from 'grommet-icons'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { ResponsiveGrid } from '../../atoms/ResponsiveGrid/ResponsiveGrid'
import { laptop } from '../../common/assets/_breakpoints'
import RetailerBenchCard from '../../molecules/RetailersBench/RetailerBenchCard'
import RetailersBenchPageBreadcrumbs from '../../molecules/RetailersBench/RetailersBenchPageBreadcrumbs'
import RetailersBenchTitle from '../../molecules/RetailersBench/RetailersBenchTitle'
import UnknownError from '../../organisms/Applications/UnknownError'
import { RetailersBenchSliceReducer } from '../../redux/reducers/retailers-bench/retailers-bench.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'

interface MatchParams {
  id: string
}

interface RetailersBenchPageProps extends RouteComponentProps<MatchParams> {}

export const RetailersBenchPage = ({ history }: RetailersBenchPageProps) => {
  const dispatch = useDispatch()
  const { retailersBench, isLoadingRetailersBench, fetchRetailersBenchError } = useTypedSelector(state => ({
    retailersBench: state.retailersBench.retailersBench,
    isLoadingRetailersBench: state.retailersBench.isLoadingRetailersBench,
    fetchRetailersBenchError: state.retailersBench.fetchRetailersBenchError,
  }))

  useEffect(() => {
    dispatch(RetailersBenchSliceReducer.actions.fetchRetailersBench())
  }, [dispatch])

  if (!retailersBench) {
    return <></>
  }

  if (isLoadingRetailersBench) {
    return (
      <Box width="100%" height="90vh">
        <LoadingIcon />
      </Box>
    )
  }

  if (fetchRetailersBenchError || !retailersBench) {
    if (fetchRetailersBenchError?.status !== 404) {
      return <UnknownError />
    }
  }

  const Content = () => {
    if (fetchRetailersBenchError?.status === 404) {
      return <Legend isError>Não há varejista algum na banca de avaliação.</Legend>
    }

    return (
      <>
        <Legend>Selecione um dos varejos para ver seus applications esperando avaliação:</Legend>
        <ResponsiveGrid margin={'24px'} columns="175px" gap="small">
          {Object.entries(retailersBench.retailers).map(retailer => (
            <RetailerBenchCard
              history={history}
              key={retailer[0]}
              fantasyName={retailer[1].fantasyName}
              logo={retailer[1].logo}
              retailerId={retailer[0]}
            />
          ))}
        </ResponsiveGrid>
      </>
    )
  }

  return (
    <Container>
      <RetailersBenchPageBreadcrumbs />
      <RetailersBenchTitle
        title="Banca de Varejistas"
        icon={<Clock size="25px" color={UMEColors.green.darkPastel} />}
      />
      <Content />
    </Container>
  )
}

const Container = styled(Box)`
  margin-top: 2vh;
  margin-bottom: 5vh;
  margin-left: 3vw;
  width: 60%;

  @media (max-width: ${laptop}) {
    width: 95%;
  }
`
const Legend = styled(Text)`
  text-align: left;
  margin-top: 16px;
  margin-left: 32px;
  color: ${ (props: any) => props.isError ? UMEColors.red.persian : '#5b5b5b'};
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
`
