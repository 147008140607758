const deployMode = (process.env.REACT_APP_NODE_ENV as 'prod' | 'staging' | 'cloud-dev' | 'dev') || 'dev'

// coordinator base url from each env
export const getCoordinatorRootUrl = () => {
  const urlsEnv: { [env: string]: string } = {
    dev: 'http://localhost:3001/v1',
    // dev: 'https://dev-coordinator.ume.com.br/v1',
    'cloud-dev': 'https://dev-coordinator.ume.com.br/v1',
    staging: 'https://staging-coordinator.ume.com.br/v1',
    prod: 'https://coordinator.ume.com.br/v1',
  }
  return urlsEnv[deployMode]
}

export const getAuthRootUrl = () => {
  const urlsEnv: { [env: string]: string } = {
    // dev: 'http://localhost:4000/v1/auth',
    dev: 'https://dev-auth.ume.com.br/v1/auth',
    'cloud-dev': 'https://dev-auth.ume.com.br/v1/auth',
    staging: 'https://staging-auth.ume.com.br/v1/auth',
    prod: 'https://auth.ume.com.br/v1/auth',
  }
  return urlsEnv[deployMode]
}

//TODO: update keycloack enviroments configs
export const getKeycloackRootUrl = () => {
  const urlsEnv: { [env: string]: string } = {
    dev: 'https://dev-api.ume.com.br/keycloak',
    'cloud-dev': 'https://dev-api.ume.com.br/keycloak',
    staging: 'https://api.ume.com.br/keycloak',
    prod: 'https://api.ume.com.br/keycloak',
  }
  const realm: { [env: string]: string } = {
    dev: 'analysts',
    'cloud-dev': 'analysts',
    staging: 'analysts',
    prod: 'analysts',
  }
  const clientId: { [env: string]: string } = {
    dev: 'backoffice',
    'cloud-dev': 'backoffice',
    staging: 'backoffice',
    prod: 'backoffice',
  }

  return { url: urlsEnv[deployMode], clientId: clientId[deployMode], realm: realm[deployMode] }
}

export const getBffBackofficeRootUrl = () => {
  const urlsEnv: { [env: string]: string } = {
    dev: 'http://localhost:3003/v1',
    'cloud-dev': 'https://dev-api.ume.com.br/operations/bff-backoffice/v1',
    staging: 'https://stage-api.ume.com.br/operations/bff-backoffice/v1',
    prod: 'https://api.ume.com.br/operations/bff-backoffice/v1',
  }
  return urlsEnv[deployMode]
}

const featureFlags = {
  dev: {
    invoices: true,
    editBorrower: true,
    shouldAllowStoreAddressEdit: true,
  },
  'cloud-dev': {
    invoices: true,
    editBorrower: true,
    shouldAllowStoreAddressEdit: true,
  },
  staging: {
    invoices: true,
    editBorrower: true,
    shouldAllowStoreAddressEdit: true,
  },
  prod: {
    invoices: true,
    editBorrower: true,
    shouldAllowStoreAddressEdit: true,
  },
}
export const invoicesFeatureFlag = featureFlags[deployMode].invoices
export const isEditBorrowerAllowed = featureFlags[deployMode].editBorrower
export const shouldAllowStoreAddressEdit = featureFlags[deployMode].shouldAllowStoreAddressEdit
