import { LoadingScreen } from '@bit/ume.design-kit.ui'
import * as React from 'react'
import { Redirect } from 'react-router'
import { PagesEnum } from '../../../../pages/common/enums/pages.enum'
import {
  bffBackofficeApiService,
  keyCloackService,
  startApiServices,
  startSSOAuthService,
} from '../../../../services/bff-backoffice'
import { ILoginProfileResponse } from '../../../../services/bff-backoffice/auth/dto/Auth.dto'
import KeycloackLogin from '../Keycloack/KeycloackLogin'
import { LoginBox, LoginCard } from './style'

export interface ILoginFormProps {
  handleUserLogged: (user?: ILoginProfileResponse) => void
}

export interface ILoginFormState {
  loading: boolean
  error: string
}

export default class LoginForm extends React.Component<ILoginFormProps, ILoginFormState> {
  constructor(props: ILoginFormProps) {
    super(props)

    this.state = {
      error: '',
      loading: false,
    }
  }

  public handleLogin = async () => {
    this.setState({ loading: true })

    try {
      const keycloakLoginToken = await keyCloackService.getLoginToken()

      if (!keycloakLoginToken) {
        return this.setState({
          loading: false,
          error: 'Erro ao autenticar, tente novamente.',
        })
      }

      await startSSOAuthService(keycloakLoginToken)

      startApiServices()

      const user = await bffBackofficeApiService.auth.decodeJwt()

      this.props.handleUserLogged(user)
      this.setState({
        loading: false,
      })
    } catch (error) {
      this.setState({
        loading: false,
        error: error.message,
      })

      if (error.message === 'UNAUTHORIZED') {
        this.setState({
          error: 'Usuário sem autorização',
        })
      }

      this.props.handleUserLogged()
    }
  }

  public render() {
    const user = bffBackofficeApiService.auth.getUser()

    if (bffBackofficeApiService.auth.isLogged() && user) {
      return <Redirect to={PagesEnum.HOME_PAGE} />
    }

    return (
      <LoginBox align="center" justify="center">
        <LoginCard boxShadow={false}>
          {<KeycloackLogin handleLoginButtonPress={this.handleLogin} error={this.state.error} />}
        </LoginCard>
        {this.state.loading && <LoadingScreen />}
      </LoginBox>
    )
  }
}
