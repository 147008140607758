import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Box, Text } from 'grommet'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { ApplicationsSliceReducer } from '../../redux/reducers/applications/applications.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { ApplicationStatus } from '../../services/bff-backoffice/application-engine/interfaces/application-engine.interfaces'
import { LoadingButton } from '../LoadingButton/LoadingButton'
import Modal from '../RetailersPage/Modal'

interface Props {
  isModalOpen: boolean
  onClose: () => void
}

const ApproveApplicationModal = ({ isModalOpen, onClose }: Props) => {
  const dispatch = useDispatch()

  const { application, isLoadingButton } = useTypedSelector(state => ({
    application: state.applications.application,
    isLoadingButton: state.applications.isLoadingApplicationOnlineEvaluation,
  }))

  if (!application) return <></>

  const handleConfirmButton = () => {
    dispatch(
      ApplicationsSliceReducer.actions.evaluateApplicationOnline({
        applicationId: application.id,
        body: {
          evaluation: ApplicationStatus.APPROVED,
        },
      })
    )
  }

  return (
    <Modal modalTtitle="Confirmar Aprovação" isOpen={isModalOpen} onClose={onClose} width={'500px'}>
      <Box overflow="auto" pad={{ top: '20px' }} margin={{ vertical: '10px' }}>
        <Text size="18px" color={UMEColors.black.light}>
          Deseja aprovar cliente?
        </Text>
        <Box width="100%" direction="row" gap="medium" justify="center" margin={{ top: '20px' }}>
          <CancelButton onClick={onClose} label={'Cancelar'} />
          <ConfirmButton
            onClick={handleConfirmButton}
            label={isLoadingButton ? <SmallLoadingIcon /> : 'Aprovar Cliente'}
            disabled={isLoadingButton}
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default ApproveApplicationModal

const SmallLoadingIcon = styled(LoadingIcon)`
  height: 14px;
  width: 14px;
  fill: ${UMEColors.white.primary};
`
const ConfirmButton = styled(LoadingButton)`
  border-radius: 6px;
  padding: 10px;
  width: 40%;
  background-color: ${UMEColors.green.laurel};
  border: solid 1px ${UMEColors.white.primary};
  box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4) !important;

  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  transition: 0.4s;

  &:hover {
    transition: 250ms;
    opacity: 0.8;
    cursor: pointer;
  }

  ${(props: any) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.2;
    `}
`

const CancelButton = styled(LoadingButton)`
  border-radius: 6px;
  padding: 10px;
  width: 40%;
  background-color: ${UMEColors.white.primary};
  border: solid 1px ${UMEColors.green.laurel};
  box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4) !important;

  color: ${UMEColors.green.laurel};
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  transition: 0.4s;

  &:hover {
    transition: 250ms;
    cursor: pointer;
    background-color: ${UMEColors.green.laurel};
    color: ${UMEColors.white.primary};
  }
`
