import { Card } from '@bit/ume.design-kit.ui'
import { black } from '@bit/ume.design-kit.ui/utils/_colors'
import styled from 'styled-components'
import { mobile } from '../../../common/assets/_breakpoints'

export const SectionContainer = styled.div`
  padding: 16px 10px 40px 0;
  display: flex;
  flex-direction: column;
  max-width: 200px;

  :hover {
    cursor: pointer;
  }

  @media (max-width: ${mobile}) {
    padding: 0;
  }
`
export const SectionCard = styled(Card)`
  padding: 35px;

  :hover {
    box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4);
  }

  @media (max-width: ${mobile}) {
    padding: 20px;
  }
`
export const Subtitle = styled.span`
  font-size: 20px;
  line-height: 0;
  text-align: center;
  font-weight: bold;
  color: ${black.primary};
`
export const CardIcon = styled.span`
  > svg {
    width: 100px;
    height: 100px;
    fill: ${black.primary}
    stroke: ${black.primary}

    @media (max-width: ${mobile}) {
      width: 75px;
      height: 75px;
    }
  }
`
