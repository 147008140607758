import { Box } from 'grommet'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import XLSX from 'xlsx'
import { toaster } from '../../App'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { get_header_row } from '../../config/utils'
import UMEDataTable from '../../molecules/DataTable/DataTable'
import DetailsCard from '../../molecules/RetailersPage/DetailsCard'
import DropDownInput from '../../molecules/RetailersPage/DropDownInput'
import MaskedInput from '../../molecules/RetailersPage/MaskedInput'
import { CardStyle } from '../../molecules/RetailersPage/styles'
import { StyledButton } from '../../pages/Retailers/style'
import { RetailersSliceReducer } from '../../redux/reducers/retailers/retailers.reducer'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { MdrLiquidationTypeEnum } from '../../services/bff-backoffice/coordinator/enums/mdr-liquidation-type.enum'
import {IOriginationsParametersResponse, IPutOriginationsParameters} from '../../services/bff-backoffice/coordinator/interfaces/parameters.interfaces'

import {
  IGetRetailerMdrRatesResponse,
  IMdrRateReq,
} from '../../services/bff-backoffice/liquidation-originations/interfaces/liquidation-originations.interfaces'
import {IRetailerResponse} from '../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'
import { formatNumberToBRL } from '../../utils'
import { TableContainer } from '../ForcedApprovalModification/style'
import FormModal from './FormModal'
import { FileUpload, SectionTitleStyle, StyledTextRetailerName, SubSectionTitleStyle } from './styles'

const NumberMaskInput = [
  {
    regexp: /^[\d]*$/,
  },
]

const FloatMaskInput = [
  {
    regexp: /^[0-9]{1,3}$/,
  },
  {
    regexp: /^\.$/,
  },
  { regexp: /^[0-9]{1,3}$/ },
]

enum MdrLiquidationTypeOptionsEnum {
  AUTOMATIC_SPLIT = 'Split Automático',
  DEFAULT = 'Padrão',
}

const mdrLiquidationTypeOptions = [MdrLiquidationTypeOptionsEnum.AUTOMATIC_SPLIT, MdrLiquidationTypeOptionsEnum.DEFAULT]

interface MatchParams {
  retailerId: string
}

interface IFinancialTabProps extends RouteComponentProps<MatchParams> {
  retailer: IRetailerResponse
  mdr: IGetRetailerMdrRatesResponse
  originationsParameters: IOriginationsParametersResponse
  fetchOriginationsParameters: (retailerId: string) => void
  putRetailerOriginationsParameter: (parameters: IPutOriginationsParameters) => void
  fetchRetailerMdr: (retailerId: string) => void
  isPuttingOriginationsParameters: boolean
}

interface IFinancialTabState {
  isLoadingSelectedFile: boolean
  isLoadingDownloadButton: boolean
  isUpdatingOriginationsParameters: boolean

  mdrRate?: number
  minimumValue?: number
  firstPurchaseMinimumValue?: number

  formMdrRate?: number
  formMinimumValue?: number
  formFirstPurchaseMinimumValue?: number

  formMdrRateError: boolean
  formMinimumValueError: boolean
  formFirstPurchaseMinimumValueError: boolean

  formMdrLiquidationType?: string
  formMdrLiquidationTypeError: boolean
}

const initialState: IFinancialTabState = {
  isLoadingSelectedFile: false,
  isLoadingDownloadButton: false,
  isUpdatingOriginationsParameters: false,

  mdrRate: undefined,
  minimumValue: undefined,
  firstPurchaseMinimumValue: undefined,

  formMdrRate: undefined,
  formMinimumValue: undefined,
  formFirstPurchaseMinimumValue: undefined,

  formMdrRateError: false,
  formMinimumValueError: false,
  formFirstPurchaseMinimumValueError: false,

  formMdrLiquidationType: undefined,
  formMdrLiquidationTypeError: false,
}

class FinancialTab extends React.Component<IFinancialTabProps, IFinancialTabState> {
  private retailerId: string

  constructor(props: IFinancialTabProps) {
    super(props)
    this.state = initialState

    this.retailerId = this.props.match.params.retailerId
  }

  componentDidMount = () => {
    if (!this.props.originationsParameters) {
      this.props.fetchOriginationsParameters(this.retailerId)
      this.props.fetchRetailerMdr(this.retailerId)
    }
  }

  componentDidUpdate = async (prevProps: any) => {
    // If a new retailer is selected, load its parameters
    if (prevProps.retailer !== this.props.retailer && this.props.retailer) {
      this.props.fetchOriginationsParameters(this.retailerId)
      this.props.fetchRetailerMdr(this.retailerId)
    }

    if (prevProps.originationsParameters !== this.props.originationsParameters) {
      this.setState({ isUpdatingOriginationsParameters: false })
    }
  }

  handleDownloadTableTemplate = () => {
    this.setState({ isLoadingDownloadButton: true })
    let mdrRates
    let fileName = `regressive_mdr_retailer_${this.retailerId}.xlsx`

    if (!(this.props.mdr?.mdrRates?.length > 0)) {
      mdrRates = [
        { maximoVolumeMensal: 50000, investimento: 4.45 },
        { maximoVolumeMensal: undefined, investimento: 3 },
      ]
      fileName = `regressive_mdr_template.xlsx`
    } else {
      mdrRates = this.props.mdr.mdrRates.map((mdrRate: any) => ({
        maximoVolumeMensal: mdrRate.upperBound,
        investimento: mdrRate.rate * 100,
      }))
    }

    const sheet = XLSX.utils.json_to_sheet(mdrRates)
    XLSX.utils.sheet_add_json(sheet, [], { origin: 'A3' })

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, sheet)
    XLSX.writeFile(wb, fileName)
    this.setState({ isLoadingDownloadButton: false })
  }

  handleClickInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = ''
  }

  handleSelectedFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isLoadingSelectedFile: true })

    const files = e.target.files

    if (!files || files.length === 0) {
      this.setState({ isLoadingSelectedFile: false })
      return toaster.showErrorToast(`Selecione um arquivo`)
    }
    const file = files[0]

    const bufferData = await file.arrayBuffer()

    // Read the .xlsx file
    const data = XLSX.read(bufferData, { type: 'buffer' })

    // Get all sheet's names
    const sheetNames = data.SheetNames

    // No sheet or multiple sheets error
    if (sheetNames.length !== 1) {
      this.setState({ isLoadingSelectedFile: false })
      return toaster.showErrorToast(`Planilha deve ter uma Sheet!`)
    }

    // Get the worksheet
    const worksheet = data.Sheets[sheetNames[0]]

    // Get the header row
    const headerRow = get_header_row(worksheet)

    // Check if the header row is as expected
    const expectedHeadersArray = ['maximoVolumeMensal', 'investimento']
    for (const expectedHeader of expectedHeadersArray) {
      if (!headerRow.find(element => element === expectedHeader)) {
        this.setState({ isLoadingSelectedFile: false })
        return toaster.showErrorToast(`Coluna ${expectedHeader} faltando na tabela.`)
      }
    }

    let jsonFromTable: any[] = XLSX.utils.sheet_to_json(data.Sheets[sheetNames[0]])
    const mdrRates: IMdrRateReq[] = []
    let lastUpperBound

    // Treat json from table into expected json for API
    for (let i = 0; i < jsonFromTable.length; i++) {
      let rate: any = Number(jsonFromTable[i].investimento)
      let upperBound: any = jsonFromTable[i].maximoVolumeMensal

      if (!upperBound && i < jsonFromTable.length - 1) {
        this.setState({ isLoadingSelectedFile: false })
        return toaster.showErrorToast(`Prencha todos os valores mensais, sendo o último opcional. Revise a planilha.`)
      }
      upperBound = Number(upperBound)

      if (!rate || rate < 0 || rate > 1) {
        this.setState({ isLoadingSelectedFile: false })
        return toaster.showErrorToast(`Todos os investimentos devem estar entre 0 e 1. Revise a planilha.`)
      }

      if (upperBound < 0) {
        this.setState({ isLoadingSelectedFile: false })
        return toaster.showErrorToast(`Máximo volume mensal ${upperBound} inválido. Revise a planilha.`)
      }

      if (lastUpperBound && lastUpperBound > upperBound) {
        this.setState({ isLoadingSelectedFile: false })
        return toaster.showErrorToast(`Os valores de volume mensal não são crescentes. Revise a planilha.`)
      }
      lastUpperBound = upperBound

      mdrRates.push({
        rate,
        upperBound,
      })
    }

    bffBackofficeApiService.liquidationOriginations
      .putRetailerMdrRates(this.retailerId, { mdrRates })
      .then((result: IGetRetailerMdrRatesResponse) => {
        toaster.showSuccessToast('MDR atualizado')
        this.props.fetchRetailerMdr(this.retailerId)
        this.setState({ isLoadingSelectedFile: false })
      })
      .catch((err: any) => {
        toaster.showErrorToast('Não foi possível atualizar o MDR. Revise a planilha ou contate a engenharia.')
        this.setState({ isLoadingSelectedFile: false })
      })
  }

  onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const state: any = this.state
    state[e.target.name] = e.target.value
    this.setState(state)
  }

  onChangeDropDownInput = (e: any) => {
    const state: any = this.state
    state[e.target.name] = e.value
    this.setState(state)
  }

  openOriginationsParametersModal = async () => {
    const { originationsParameters } = this.props
    const visualFormMdrRate = Number((originationsParameters.mdrRate * 100).toFixed(3))

    await this.setState({
      isUpdatingOriginationsParameters: true,
      formFirstPurchaseMinimumValue: originationsParameters
        ? originationsParameters.firstPurchaseMinimumValue
        : undefined,
      formMdrRate: originationsParameters ? (isNaN(visualFormMdrRate) ? undefined : visualFormMdrRate) : undefined,
      formMinimumValue: originationsParameters ? originationsParameters.minimumValue : undefined,

      formMdrRateError: false,
      formFirstPurchaseMinimumValueError: false,
      formMinimumValueError: false,

      formMdrLiquidationType: originationsParameters.mdrLiquidationType
        ? MdrLiquidationTypeOptionsEnum.AUTOMATIC_SPLIT
        : MdrLiquidationTypeOptionsEnum.DEFAULT,
    })
  }

  // Returns the form fields of the Create Intermediary Bank Account Modal
  updateOriginationsParametersFields = () => {
    return [
      <StyledTextRetailerName key={0}>{this.props.retailer.fantasyName}</StyledTextRetailerName>,
      <MaskedInput
        required
        label="MDR"
        key={1}
        mask={FloatMaskInput}
        width="100%"
        name={'formMdrRate'}
        placeholder={'4.45'}
        onChange={this.onChangeInput}
        value={this.state.formMdrRate}
        errorIntent={this.state.formMdrRateError}
      />,
      <MaskedInput
        required
        label="Valor mínimo de compra"
        key={2}
        width="100%"
        mask={NumberMaskInput}
        name={'formMinimumValue'}
        placeholder={'10'}
        onChange={this.onChangeInput}
        value={this.state.formMinimumValue}
        errorIntent={this.state.formMinimumValueError}
      />,
      <MaskedInput
        required
        label="Valor mínimo de primeira compra"
        key={3}
        width="100%"
        mask={NumberMaskInput}
        name={'formFirstPurchaseMinimumValue'}
        placeholder={'10'}
        onChange={this.onChangeInput}
        value={this.state.formFirstPurchaseMinimumValue}
        errorIntent={this.state.formFirstPurchaseMinimumValueError}
      />,
      <DropDownInput
        required
        name="formMdrLiquidationType"
        label="Tipo de MDR"
        width="100%"
        placeholder="Selecione o tipo de MDR"
        style={{ border: 'none' }}
        key={4}
        options={mdrLiquidationTypeOptions}
        onChange={this.onChangeDropDownInput}
        value={this.state.formMdrLiquidationType}
        errorIntent={this.state.formMdrLiquidationTypeError}
      />,
    ]
  }

  onUpdateOriginationsParameters = () => {
    const { formMdrRate, formMinimumValue, formFirstPurchaseMinimumValue, formMdrLiquidationType } = this.state
    let formMdrRateError = false,
      formMinimumValueError = false,
      formFirstPurchaseMinimumValueError = false,
      formMdrLiquidationTypeError = false

    if (formMdrRate === undefined || formMdrRate === null) {
      formMdrRateError = true
      toaster.showErrorToast(`MDR é obrigatório`)
    }
    if (formMinimumValue === undefined || formMinimumValue === null) {
      formMinimumValueError = true
      toaster.showErrorToast(`Valor minimo é obrigatório`)
    }
    if (formFirstPurchaseMinimumValue === undefined || formFirstPurchaseMinimumValue === null) {
      formFirstPurchaseMinimumValueError = true
      toaster.showErrorToast(`Valor mínimo de primeira compra é obrigatório`)
    }
    if (!formMdrLiquidationType) {
      formMdrLiquidationTypeError = true
      toaster.showErrorToast(`O tipo do MDR é obrigatório`)
    }

    if (
      formMdrRateError ||
      formMinimumValueError ||
      formFirstPurchaseMinimumValueError ||
      formMdrLiquidationTypeError
    ) {
      return this.setState({
        formMdrRateError,
        formMinimumValueError,
        formFirstPurchaseMinimumValueError,
        formMdrLiquidationTypeError,
      })
    }
    const body = {
      mdr: Number(formMdrRate) / 100,
      firstPurchaseMinimumValue: Number(formFirstPurchaseMinimumValue),
      minimumValue: Number(formMinimumValue),
      retailerId: this.retailerId,
      mdrLiquidationType:
        formMdrLiquidationType === MdrLiquidationTypeOptionsEnum.AUTOMATIC_SPLIT
          ? MdrLiquidationTypeEnum.AUTOMATIC_SPLIT
          : undefined,
    } as IPutOriginationsParameters

    this.props.putRetailerOriginationsParameter(body)
  }

  render() {
    const { isUpdatingOriginationsParameters, isLoadingSelectedFile } = this.state
    const { mdr, retailer, originationsParameters, isPuttingOriginationsParameters } = this.props

    // Waits until the retailer is loaded to show cards
    if (!retailer) {
      return (
        <Box width="100%" height="50vh">
          <LoadingIcon />
        </Box>
      )
    }

    const retailerOriginationsParametersData = [
      {
        label: 'MDR',
        value:
          originationsParameters && originationsParameters.mdrRate !== undefined
            ? `${Number((originationsParameters.mdrRate * 100).toFixed(3))}%`
            : 'Não cadastrado',
      },
      {
        label: 'Valor minimo de compra',
        value:
          originationsParameters && originationsParameters.minimumValue !== undefined
            ? formatNumberToBRL(originationsParameters.minimumValue)
            : 'Não cadastrado',
      },
      {
        label: 'Mínimo primeira compra',
        value:
          originationsParameters && originationsParameters.firstPurchaseMinimumValue !== undefined
            ? formatNumberToBRL(originationsParameters.firstPurchaseMinimumValue)
            : 'Não cadastrado',
      },
      {
        label: 'Tipo de MDR',
        value: originationsParameters?.mdrLiquidationType
          ? MdrLiquidationTypeOptionsEnum.AUTOMATIC_SPLIT
          : MdrLiquidationTypeOptionsEnum.DEFAULT,
      },
    ]

    return (
      <>
        {isUpdatingOriginationsParameters ? (
          <FormModal
            title="Atualizar parâmetros de originação"
            isModalOpen={isUpdatingOriginationsParameters}
            fields={this.updateOriginationsParametersFields()}
            onClose={() => {
              this.setState({ isUpdatingOriginationsParameters: false })
            }}
            onCreate={this.onUpdateOriginationsParameters}
            isLoadingButton={isPuttingOriginationsParameters}
          />
        ) : (
          <></>
        )}
        <Box style={{ maxWidth: '1000px' }} direction="column" gap="medium">
          <Box width="80%" align="start" gap="medium">
            <SectionTitleStyle style={{ marginBottom: 0 }}>Financeiro</SectionTitleStyle>

            <Box width="100%">
              <Box width="100%" justify="between" direction="row" pad={{ right: '10px' }}>
                <SubSectionTitleStyle>Parâmetros de originação</SubSectionTitleStyle>
                <StyledButton width="125px" onClick={this.openOriginationsParametersModal}>
                  Editar
                </StyledButton>
              </Box>
              {!originationsParameters ? (
                <CardStyle height="100%">
                  <Box style={{ minHeight: '50px' }} fill align="center" justify="center">
                    <LoadingIcon />
                  </Box>
                </CardStyle>
              ) : (
                <DetailsCard data={retailerOriginationsParametersData} />
              )}
            </Box>

            <Box width="100%" align="start">
              <Box width="100%" justify="between" direction="row" pad={{ right: '10px' }}>
                <SubSectionTitleStyle>MDR Regressivo</SubSectionTitleStyle>
                <StyledButton width="125px" onClick={this.handleDownloadTableTemplate}>
                  Download
                </StyledButton>
              </Box>

              <Box width="100%">
                {mdr === undefined || isLoadingSelectedFile ? (
                  <CardStyle height="100%">
                    <Box style={{ minHeight: '100px' }} fill align="center" justify="center">
                      <LoadingIcon />
                    </Box>
                  </CardStyle>
                ) : !mdr ? (
                  <CardStyle height="100%">
                    <Box style={{ minHeight: '100px' }} fill align="center" justify="center" gap="small">
                      Erro ao carregar Taxas de MDR.
                    </Box>
                  </CardStyle>
                ) : mdr.mdrRates?.length > 0 ? (
                  <TableContainer style={{ maxHeight: '600px', margin: '8px' }}>
                    <UMEDataTable
                      header={[
                        {
                          label: 'Volume mensal até',
                          attribute: 'upperBound',
                          formatCell: (row: any) => {
                            return row.upperBound ? formatNumberToBRL(row.upperBound) : '-'
                          },
                          textAlign: 'center',
                        },
                        {
                          label: 'Investimento',
                          attribute: 'rate',
                          formatCell: (row: any) => {
                            return `${row.rate * 100}%`
                          },
                          textAlign: 'center',
                        },
                      ]}
                      data={mdr.mdrRates}
                    />
                    <Box margin={{ top: '20px' }} justify="center">
                      <FileUpload
                        type="file"
                        accept=".xlsx"
                        onChange={this.handleSelectedFile}
                        onClick={this.handleClickInputFile}
                      />
                    </Box>
                  </TableContainer>
                ) : (
                  <CardStyle height="100%">
                    <Box style={{ minHeight: '100px' }} fill align="center" justify="center" gap="small">
                      Não há faixas de MDR cadastradas.
                    </Box>
                    <Box margin={{ top: '20px' }} justify="center">
                      <FileUpload
                        type="file"
                        accept=".xlsx"
                        onChange={this.handleSelectedFile}
                        onClick={this.handleClickInputFile}
                      />
                    </Box>
                  </CardStyle>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  retailer: state.retailers.retailer,
  mdr: state.retailers.mdr,
  originationsParameters: state.retailers.originationsParameters,
  isPuttingOriginationsParameters: state.retailers.isPuttingOriginationsParameters,
})

const mapDispatchToProps = (dispatch: any) => ({
  fetchOriginationsParameters: (retailerId: string) =>
    dispatch(RetailersSliceReducer.actions.fetchOriginationsParameters(retailerId)),

  putRetailerOriginationsParameter: (parameters: IPutOriginationsParameters) =>
    dispatch(RetailersSliceReducer.actions.putOriginationsParameters(parameters)),

  fetchRetailerMdr: (retailerId: string) => dispatch(RetailersSliceReducer.actions.fetchRetailerMdr(retailerId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FinancialTab)
