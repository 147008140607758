import React from 'react'
import { Card, Heading } from '@bit/ume.design-kit.ui'
import { black, gray } from '@bit/ume.design-kit.ui/utils/_colors'
import styled from 'styled-components'

const InfoCard = (props: any) => {
  return(
    <Card height="184px" width="160px">
      <CustomHeading
        level={4}
        color={gray.spanish}
        margin="none"
        textAlign="start"
      >
        {props.name}
      </CustomHeading>
      <Heading
        level={1}
        color={black.primary}
        textAlign="start"
      >
        {props.value}
      </Heading>
    </Card>
  )
}

const CustomHeading = styled(Heading)`
  height: 33%;
`

export { InfoCard }