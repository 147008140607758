import { Anchor } from '@bit/ume.design-kit.ui'
import React, { useRef } from 'react'

interface FileUploaderProps {
  textAnchor?: string
  handleFile: (file: File) => void
}

export const FileUploader = (props: FileUploaderProps) => {
  const hiddenFileInput = useRef<any>(null)
  const { handleFile, textAnchor } = props

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0]
    handleFile(fileUploaded)
  }

  return (
    <>
      <Anchor onClick={handleClick} style={{ textAlign: 'start' }}>
        {textAnchor ? textAnchor : 'Nova foto'}
      </Anchor>
      <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />
    </>
  )
}
