import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { InvoicesTable } from '../../../molecules/InstallmentDetails/InvoicesTable'
import { IInvoice } from '../../../services/bff-backoffice/coordinator/interfaces/invoice.interfaces'
import { CardStyle } from './style'

export interface IInvoicesCardProps extends RouteComponentProps {
  invoices?: IInvoice[] | Error
  onMounted?: () => void
}

export class InvoicesCard extends React.PureComponent<IInvoicesCardProps> {
  componentDidMount(): void {
    if (this.props.onMounted) this.props.onMounted()
  }

  render() {
    const { invoices, history } = this.props

    return (
      <CardStyle>
        <InvoicesTable invoices={invoices} history={history} />
      </CardStyle>
    )
  }
}
