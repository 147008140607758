import { Anchor, Button, Loading } from '@bit/ume.design-kit.ui'
import { green, red } from '@bit/ume.design-kit.ui/Palette'
import { Box } from 'grommet'
import { StatusCritical, StatusGood } from 'grommet-icons'
import moment from 'moment'
import * as React from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import maskPhone from '../../common/assets/utils/phoneMask'
import { emailIsValid, validateBr } from '../../common/Validators'
import { IBorrowerSummaryProfile } from '../../pages/CS-Profile/BorrowerProfilePage'
import { formatDate } from '../../utils'
import {
  DisabledTextInputStyle,
  DropdownStyle,
  FormCloseIconStyle,
  ModalStyle,
  TextInputStyle,
  TitleStyle,
  WarningTextStyle,
} from './style'

export type EditionOptionsType = 'name' | 'email' | 'birthDate' | 'phoneNumber'
const EDITION_ATTRIBUTES: { label: string; type: EditionOptionsType }[] = [
  { label: 'Nome', type: 'name' },
  { label: 'E-mail', type: 'email' },
  { label: 'Data de nascimento', type: 'birthDate' },
  // { label: 'Celular', type: 'phoneNumber' },
]

export interface IBorrowerEditionModalProps {
  borrowerSummary: IBorrowerSummaryProfile
  onBorrowerEditionSubmit: (body: { name?: string; email?: string; birthDate?: string }) => void
  onBorrowerPhoneEditionSubmit: (body: { number: string }) => void
}

export interface IBorrowerEditionModalState {
  attributeSelected?: { label: string; type: EditionOptionsType }
  newValue: string
  oldValue?: string
  loading: boolean
  borrowerEditionModalIsOpen: boolean
}

const initialState: IBorrowerEditionModalState = {
  newValue: '',
  loading: false,
  borrowerEditionModalIsOpen: false,
  attributeSelected: undefined,
  oldValue: undefined,
}

export default class BorrowerEditionModal extends React.Component<
  IBorrowerEditionModalProps,
  IBorrowerEditionModalState
> {
  constructor(props: IBorrowerEditionModalProps) {
    super(props)

    this.state = initialState
  }

  componentDidUpdate(prevProps: IBorrowerEditionModalProps, prevState: IBorrowerEditionModalState) {
    if (this.props.borrowerSummary !== prevProps.borrowerSummary) {
      this.setState(initialState)
    }
  }

  onSelect = (attribute: { value: { label: string; type: EditionOptionsType } }) => {
    this.setState({
      attributeSelected: attribute.value,
      oldValue: this.props.borrowerSummary[attribute.value.type],
      newValue: '',
    })
  }

  onNewValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ newValue: e.target.value })
  }

  onNewValueNumberChange = (values: NumberFormatValues) => {
    this.setState({ newValue: values.value })
  }

  onSubmit = () => {
    const { attributeSelected, newValue } = this.state
    this.setState({ loading: true })

    switch (attributeSelected?.type) {
      case 'name':
        return this.props.onBorrowerEditionSubmit({ name: newValue.toUpperCase() })
      case 'email':
        return this.props.onBorrowerEditionSubmit({ email: newValue.toLowerCase() })
      case 'birthDate':
        return this.props.onBorrowerEditionSubmit({ birthDate: moment(newValue, 'DDMMYYYY').format('YYYY-MM-DD') })
      case 'phoneNumber':
        return this.props.onBorrowerPhoneEditionSubmit({ number: '+55' + newValue })
      default:
        return null
    }
  }

  isNewValueValid = (): boolean => {
    const { attributeSelected, newValue, oldValue } = this.state

    if (!newValue) {
      return false
    } else {
      switch (attributeSelected?.type) {
        case 'email':
          return newValue !== oldValue && emailIsValid(newValue)
        case 'name':
          return newValue.toUpperCase() !== oldValue && newValue.includes(' ')
        case 'birthDate':
          const newBirthDate = moment(newValue, 'DDMMYYYY').format('YYYY-MM-DD')
          return newBirthDate !== oldValue && newValue.length === 8 && moment(newValue, 'DDMMYYYY').isValid()
        case 'phoneNumber':
          return oldValue !== '+55' + newValue && validateBr.celular(newValue)
      }
      return true
    }
  }

  openBorrowerEditionModal = () => {
    this.setState({ borrowerEditionModalIsOpen: true })
  }

  closeBorrowerEditionModal = () => {
    this.setState(initialState)
  }

  public render() {
    const { attributeSelected, newValue, oldValue, borrowerEditionModalIsOpen, loading } = this.state

    const newValueInput = () => {
      if (attributeSelected?.type === 'phoneNumber') {
        return (
          <NumberFormat
            format="(##) #####-####"
            value={newValue}
            type="tel"
            isNumericString={true}
            placeholder="(99) 99999-9999"
            onValueChange={this.onNewValueNumberChange}
            tabIndex={0}
            label="Novo valor"
            customInput={TextInputStyle}
          />
        )
      } else if (attributeSelected?.type === 'birthDate') {
        return (
          <NumberFormat
            format="##/##/####"
            value={newValue}
            type="tel"
            isNumericString={true}
            placeholder="DD/MM/AAAA"
            onValueChange={this.onNewValueNumberChange}
            tabIndex={0}
            label="Novo valor"
            customInput={TextInputStyle}
          />
        )
      } else if (attributeSelected?.type === 'email') {
        return (
          <TextInputStyle
            value={newValue}
            onChange={this.onNewValueChange}
            label="Novo valor"
            tabIndex={0}
            placeholder="exemplo@email.com"
            style={{ textTransform: 'lowercase' }}
          />
        )
      } else {
        return (
          <TextInputStyle
            value={newValue}
            onChange={this.onNewValueChange}
            label="Novo valor"
            tabIndex={0}
            style={{ textTransform: 'uppercase' }}
          />
        )
      }
    }

    const formatedValue = () => {
      switch (attributeSelected?.type) {
        case 'name':
          return oldValue
        case 'birthDate':
          return oldValue ? formatDate(oldValue) : 'Sem data de nascimento cadastrada'
        case 'email':
          return oldValue ? oldValue : 'Sem e-mail cadastrado'
        case 'phoneNumber':
          return oldValue ? maskPhone(oldValue) : 'Sem telefone cadastrado'
        default:
          return ''
      }
    }

    return (
      <Box>
        <Anchor alignSelf="end" margin="0 12px" fontSize="12px" onClick={this.openBorrowerEditionModal}>
          editar
        </Anchor>

        {borrowerEditionModalIsOpen && (
          <ModalStyle onEsc={this.closeBorrowerEditionModal} onClickOutside={this.closeBorrowerEditionModal}>
            <Box gap="medium">
              <Box direction="row" justify="between" align="start">
                <TitleStyle>Editar dados cadastrais</TitleStyle>
                <FormCloseIconStyle color="black" onClick={this.closeBorrowerEditionModal} />
              </Box>
              <Box width="275px">
                <WarningTextStyle>
                  *Ao alterar os dados, será enviado um SMS ao cliente o informando sobre esta alteração.
                </WarningTextStyle>
              </Box>
              <Box width="260px">
                <DropdownStyle
                  id="select-attributes"
                  name="select-attributes"
                  placeholder="Selecione o campo a alterar"
                  open={false}
                  labelKey="label"
                  valueKey="type"
                  value={attributeSelected}
                  options={EDITION_ATTRIBUTES}
                  onChange={this.onSelect}
                />
              </Box>
              <Box style={{ opacity: attributeSelected ? 1 : 0.3 }} margin={{ top: 'small' }}>
                <Box gap="medium">
                  <Box>
                    <DisabledTextInputStyle disabled value={formatedValue()} label="Antigo valor" />
                  </Box>

                  <Box>
                    <Box direction="row" align="center" gap="small">
                      {newValueInput()}
                      {this.isNewValueValid() ? (
                        <StatusGood color={green.darkPastel} />
                      ) : (
                        <StatusCritical color={red.light} />
                      )}
                    </Box>
                  </Box>

                  <Box direction="row-reverse" margin={{ top: 'large' }}>
                    {loading ? (
                      <Loading />
                    ) : (
                      <Button tabIndex={0} disabled={!this.isNewValueValid()} onClick={this.onSubmit}>
                        Alterar
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </ModalStyle>
        )}
      </Box>
    )
  }
}
