import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { bffBackofficeApiService } from '../../../services/bff-backoffice'
import { IGetRetailerOperators, IOperator, IOperatorPostReq } from '../../../services/bff-backoffice/coordinator/interfaces/retailers.interfaces'
import { OperatorsSliceReducer } from './operators.reducer'

function* fetchRetailerOperators(action: PayloadAction<string>) {
  try {
    const retailerId = action.payload
    const result: IGetRetailerOperators = yield bffBackofficeApiService.coordinator.getRetailerOperators(retailerId)
    yield put(OperatorsSliceReducer.actions.fetchRetailerOperatorsSuccess(result.data))
  } catch (error) {
    yield put(OperatorsSliceReducer.actions.fetchRetailerOperatorsError(error))
  }
}

function* createOperator(action: PayloadAction<IOperatorPostReq>) {
  try {
    const body = action.payload
    yield put(OperatorsSliceReducer.actions.createOperatorLoading())
    const operator: IOperator = yield bffBackofficeApiService.coordinator.createOperator(body)
    yield put(OperatorsSliceReducer.actions.createOperatorSuccess(operator))
    yield put(OperatorsSliceReducer.actions.fetchRetailerOperators(operator.retailerId))
  } catch (error) {
    yield put(OperatorsSliceReducer.actions.createOperatorError(error))
  }
}

const operatorsSaga = [
  takeLatest(OperatorsSliceReducer.actions.fetchRetailerOperators, fetchRetailerOperators),
  takeLatest(OperatorsSliceReducer.actions.createOperator, createOperator),
]

export default operatorsSaga
