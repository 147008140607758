import React from 'react'
import { ReactComponent as BarcodeBaseIcon } from '../../common/assets/images/bx-barcode.svg'
import styled from 'styled-components'
import { FinancialTab } from '../../molecules/FinancialOriginations/FinancialTab'
import { screenSize } from '../../config/screens.config'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import FinancialPaymentsPage from '../../organisms/FinancialPayments/FinancialPaymentsPage'
import FinancialOriginationsPage from '../../organisms/FinancialOriginations/FinancialOriginationsPage'
import { Switch, Router, Route } from 'react-router'

interface IFinancialPageProps {
  history: any
  match?: any
  location?: any
}

interface IFinancialPageState {
  selectedTabIndex: number
}

const TO_PAY_TAB_INDEX = 0
const TO_RECEIVE_TAB_INDEX = 1

export default class FinancialPage extends React.Component<IFinancialPageProps, IFinancialPageState> {
  constructor(props: IFinancialPageProps) {
    super(props)
    this.state = {
      selectedTabIndex: TO_PAY_TAB_INDEX,
    }
  }

  // On mounting the page, check which tab is selected by looking at the route name
  componentDidMount = () => {
    if (this.props.location.pathname === '/financial/payments') {
      this.setState({ selectedTabIndex: TO_RECEIVE_TAB_INDEX })
    } else if (this.props.location.pathName === '/financial/originations') {
      this.setState({ selectedTabIndex: TO_PAY_TAB_INDEX })
    }
  }

  // When a tab is clicked, the route name should change, as well as the selected tab
  onTabClicked = (tabIndex: number) => {
    if (tabIndex === TO_PAY_TAB_INDEX) {
      this.props.history.push(`${this.props.match.path}/originations`)
    } else {
      this.props.history.push(`${this.props.match.path}/payments`)
    }

    this.setState({ selectedTabIndex: tabIndex })
  }

  render() {
    const { selectedTabIndex } = this.state

    return (
      <FinancialPageContainer>
        {/* BREADCUMB */}
        <FinancialBreadcumb id="financial-breadcumb">
          <span> Home </span>
          <span> / </span>
          <span> Financeiro </span>
          <span> / </span>
          <span> {selectedTabIndex === TO_PAY_TAB_INDEX ? 'A Pagar' : 'A Receber'}</span>
        </FinancialBreadcumb>

        {/* TABS */}
        <UMETabsContainer>
          <FinancialTab
            Icon={BarcodeIcon}
            onClick={() => this.onTabClicked(TO_PAY_TAB_INDEX)}
            title={'A pagar'}
            active={selectedTabIndex !== TO_PAY_TAB_INDEX}
          />
          <FinancialTab
            Icon={BarcodeIcon}
            onClick={() => this.onTabClicked(TO_RECEIVE_TAB_INDEX)}
            title={'A receber'}
            active={selectedTabIndex !== TO_RECEIVE_TAB_INDEX}
          />
        </UMETabsContainer>

        <Router history={this.props.history}>
          <Switch>
            <Route
              path={`${this.props.match.path}/payments`}
              component={() => <FinancialPaymentsPage history={this.props.history} />}
            />

            <Route
              path={`${this.props.match.path}/originations`}
              component={() => <FinancialOriginationsPage history={this.props.history} />}
            />
          </Switch>
        </Router>
      </FinancialPageContainer>
    )
  }
}

const FinancialPageContainer = styled.div`
  background-color: white;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2);
  min-height: 95vh;
  background-color: ${UMEColors.gray.azure};

  padding: 24px 70px 24px 70px;

  @media ${screenSize.mobileL} {
    &:nth-child(even) {
      padding: 12px 20px 12px 20px;
    }
  }
`

const FinancialBreadcumb = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 200px;

  // TODO - Not in design kit
  color: #959595;
  font-family: Roboto;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 11px;
`

const BarcodeIcon = styled(BarcodeBaseIcon)`
  height: 35px;
  width: 35px;
`

const UMETabsContainer = styled.div`
  margin-top: 4vh;

  display: flex;
  flex-wrap: wrap;

  @media ${screenSize.mobileL} {
    margin-top: 1vh;
  }
`
