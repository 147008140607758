import { Modal } from 'antd'
import { MaskedInput } from 'antd-mask-input'
import { AxiosError } from 'axios'
import React, { SyntheticEvent, useState } from 'react'
import { toaster } from '../../../App'
import Loading from '../../../atoms/Loading/Loading'
import { bffBackofficeApiService } from '../../../services/bff-backoffice'
import { BorrowersServiceErrors } from '../../../services/bff-backoffice/borrowers/borrowers-service.enum'

// TODO(lucas.citolin): Create global KeysEnum
const ENTER_KEY = 'Enter'
// TODO(lucas.citolin): Create PhoneUtils class to check if string is a valid phone
const PHONE_LENGTH_WITH_DDD = 11

type Props = {
  borrowerId: string
  isModalVisible: boolean
  onCloseModal: () => void
}

const UpdateUnverifiedPhoneModal = ({ borrowerId, onCloseModal, isModalVisible }: Props) => {
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined)
  const [isUpdatingPhone, setIsUpdatingPhone] = useState<boolean>(false)

  const handleCloseModal = () => {
    setPhoneNumber(undefined)
    setIsUpdatingPhone(false)
    onCloseModal()
  }

  const handleSubmit = () => {
    if (!phoneNumber) {
      return handleCloseModal()
    }

    if (phoneNumber?.length !== PHONE_LENGTH_WITH_DDD) {
      return toaster.showErrorToast('Digite um telefone válido')
    }

    const phoneNumberWithCountryCode = `+55${phoneNumber}`
    changeUnverifiedPhone(phoneNumberWithCountryCode)
  }

  const changeUnverifiedPhone = async (formattedPhoneNumber: string) => {
    try {
      setIsUpdatingPhone(true)

      await bffBackofficeApiService.borrowers.createBorrowerPhoneNumber(borrowerId, formattedPhoneNumber)

      toaster.showSuccessToast('Telefone atualizado')
      handleCloseModal()
    } catch (error) {
      // TODO(lucas.citolin): Create a specific error class for BffServices, such as the one used on client-mobile-app
      const errorCode = (error as AxiosError).response?.data.message
      let errorMessage: string
      if (errorCode === BorrowersServiceErrors.PHONE_ALREADY_ACTIVE_FOR_ANOTHER_BORROWER) {
        errorMessage = 'Outro cliente já verificou este número'
      } else {
        errorMessage = errorCode
      }
      toaster.showErrorToast(errorMessage)
    }

    setIsUpdatingPhone(false)
  }

  const handleInputChange = (event: SyntheticEvent & { maskedValue: string; unmaskedValue: string }) => {
    setPhoneNumber(event.unmaskedValue)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER_KEY) {
      handleSubmit()
    }
  }

  let isPhoneInputRed = phoneNumber !== undefined && phoneNumber?.length !== PHONE_LENGTH_WITH_DDD

  return (
    <Modal
      title="Atualizar telefone não verificado"
      visible={isModalVisible}
      onOk={handleSubmit}
      onCancel={handleCloseModal}
    >
      {isUpdatingPhone ? (
        <Loading />
      ) : (
        <>
          <p>O cliente ainda não verificou o número de telefone</p>
          <p>
            Caso o promotor tenha cadastrado um número incorreto, preencha o telefone correto abaixo para que o cliente
            possa entrar novamente no fluxo de verificação
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Importante:</span> O cliente ainda vai precisar verificar o telefone
            através do promotor em: parceiros.ume.com.br > Aquisição 2.0
          </p>
          <MaskedInput
            mask="(00) 00000-0000"
            placeholder="(92) 98223-3038"
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            value={phoneNumber}
            style={{ borderColor: isPhoneInputRed ? 'red' : 'inherit' }}
          />
        </>
      )}
    </Modal>
  )
}

export default UpdateUnverifiedPhoneModal
