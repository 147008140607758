const errorsTranslations: { [key: string]: any } = {
  INSTALLMENT_ALREADY_PAID: 'Parcela já paga. Erro no sistema. Contate o suporte.',
  EMPTY_INSTALLMENTS: 'Selecione ao menos uma parcela',
  INSTALLMENT_IN_OPEN_RENEGOTIATION: 'Marque o acordo anterior como quebrado.',
  INSTALLMENT_SKIPPED: 'Você se esqueceu de uma parcela a vencer.',
  MISSING_OVERDUE_INSTALLMENT: 'Você se esqueceu de uma parcela vencida.',
  INSTALLMENT_NOT_FOUND: 'Parcela nao encontrada. Erro no sistema. Contate o suporte.',
  UNDEFINED_RENEGOTIATION_PARAMS: 'Sistema de renegociaçoes desconfigurado. Contate o suporte.',
  ABOVE_MAXIMUM_INTEREST_RATE: 'Acima da taxa máxima de juros.',
  BELOW_MINIMUM_INTEREST_RATE: 'Abaixo da taxa mínima de juros.',
  ABOVE_MAXIMUM_NUMBER_OF_PAYMENTS: 'Acima do máximo de parcelas.',
  PAST_FIRST_PAYMENT: 'Primeira parcela no passado.',
  PAST_UPFRONT_PAYMENT: 'Entrada no passado.',
  UPFRONT_AFTER_FIRST_PAYMENT: 'Primeira parcela antes da entrada.',
  UPFRONT_VALUE_UNDEFINED: 'Insira um valor de entrada.',
  UPFRONT_DATE_UNDEFINED: 'Insira uma data para a entrada.',
  ABOVE_MAXIMUM_DISCOUNT: 'Acima do desconto máximo.',
  BOTH_PERCENTAGE_AND_MONETARY_DISCOUNTS_DEFINED: 'Defina desconto em % ou em R$',
  MONETARY_DISCOUNT_ABOVE_DEBT: 'Desconto acima da dívida.',
  PAYMENT_BELOW_MINIMUM_VALUE: 'Parcelas com o valor abaixo do mínimo.',
  UPFRONT_PAYMENT_BELOW_MINIMUM_VALUE: 'Entrada abaixo do valor mínimo.',
  MISSING_FIRST_PAYMENT_DUE_DATE: 'Insira uma data para o primeiro pagamento.',
  MISSING_NUMBER_OF_PAYMENTS: 'Insira um parcelamento.',
  MISSING_INTEREST_RATE: 'Insira a taxa de juros.',
  INTERNAL_SERVER_ERROR: 'Erro no servidor. Contate o suporte.',
  BAD_REQUEST: 'Erro de formataçao. Se certifique de preencher corretamente o formulário.',
}

export const translateErrors = (error: string): string => {
  if (errorsTranslations[error]) {
    return errorsTranslations[error]
  } else {
    return 'Erro desconhecido. Contate o suporte.'
  }
}
