import moment from 'moment-timezone'

export const REGULAR_DATE_FORMAT = 'DD/MM/YYYY'
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD'
export const REGULAR_DATE_HOUR_FORMAT = 'DD/MM/YYYY HH:mm'

export const amountFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
})

export const formatNumberToBRL = (el?: number): string => {
  if (el === null || el === undefined) {
    return '--'
  }
  return amountFormatter.format(el)
}

export const formatDateTreatingError = (el?: string | null): string => {
  if (!el) return '---'

  let date = moment(el)
    .utc()
    .format(REGULAR_DATE_FORMAT)
  if (date === 'Invalid date') date = '---'
  return date
}

export const formatDate = (el: string): string => {
  return moment(new Date(el))
    .utc()
    .format(REGULAR_DATE_FORMAT)
}

export const formatDateToBackend = (date: string, format: string = 'DD/MM/YY'): string => {
  return moment(date, format).format(BACKEND_DATE_FORMAT)
}

export const validateDate = (date: string, format: string = 'DD/MM/YY'): boolean => {
  return moment(date, format, true).isValid()
}

export const formatCPF = (cpf: string): string => {
  cpf = cpf.replace(/[^\d]/g, '')

  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const formatCNPJ = (cpf: string): string => {
  cpf = cpf.replace(/[^\d]/g, '')

  return cpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

export const formatCep = (cpf: string): string => {
  cpf = cpf.replace(/[^\d]/g, '')

  return cpf.replace(/(\d{5})(\d{3})/, '$1-$2')
}

export const formatPhoneNumber = (cpf: string): string => {
  cpf = cpf.replace(/[^\d]/g, '')

  return cpf.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
}

export const formatDateWithHour = (date: string) => {
  return moment(date).format(REGULAR_DATE_HOUR_FORMAT)
}

export const validateFullDate = (day: string, month: string, year: string): string | null => {
  let d = Number(day)
  let m = Number(month)
  let y = Number(year)

  if (!d || d <= 0 || d >= 32) return null
  else if (!m || m <= 0 || m >= 13) return null
  else if (!y || y <= 2000 || y >= 3000) return null

  return `${day}/${month}/${year}`
}

export const booleanToSimOrNao = (bool: boolean): string => {
  return bool ? 'sim' : 'não'
}

export const findIdInAttributeOfArray = <T extends unknown>(id: string, attribute: string, array: T[]) => {
  if (!array || !Array.isArray(array) || array.length === 0) return undefined
  else {
    let foundObject = array.find((object: any) => object[attribute] === id)
    if (!foundObject) return undefined
    else return foundObject
  }
}

export const getRandomColor = () => {
  // const randomRedColors = [
  //     { backgroundColor: '#FFEBEE', textColor: '#000' },
  //     { backgroundColor: '#FFCDD2', textColor: '#000' },
  //     { backgroundColor: '#EF9A9A', textColor: '#FFF' },
  //     { backgroundColor: '#E57373', textColor: '#FFF' },
  //     { backgroundColor: '#EF5350', textColor: '#FFF' },
  //     { backgroundColor: '#F44336', textColor: '#FFF' },
  //     { backgroundColor: '#E53935', textColor: '#FFF' },
  //     { backgroundColor: '#D32F2F', textColor: '#FFF' },
  //     { backgroundColor: '#C62828', textColor: '#FFF' },
  //     { backgroundColor: '#B71C1C', textColor: '#FFF' },
  //     { backgroundColor: '#FF8A80', textColor: '#FFF' },
  //     { backgroundColor: '#FF5252', textColor: '#FFF' },
  //     { backgroundColor: '#FF1744', textColor: '#FFF' },
  //     { backgroundColor: '#D50000', textColor: '#FFF' },
  // ]

  const randomColors = [
    { backgroundColor: '#E53935', textColor: '#FFF' },
    { backgroundColor: '#9C27B0', textColor: '#FFF' },
    { backgroundColor: '#4527A0', textColor: '#FFF' },
    { backgroundColor: '#536DFE', textColor: '#FFF' },
    { backgroundColor: '#1976D2', textColor: '#FFF' },
    { backgroundColor: '#039BE5', textColor: '#FFF' },
    { backgroundColor: '#006064', textColor: '#FFF' },
    { backgroundColor: '#00695C', textColor: '#FFF' },
    { backgroundColor: '#1B5E20', textColor: '#FFF' },
    { backgroundColor: '#388E3C', textColor: '#FFF' },
    { backgroundColor: '#7CB342', textColor: '#FFF' },
    { backgroundColor: '#AFB42B', textColor: '#FFF' },
    { backgroundColor: '#F9A825', textColor: '#FFF' },
    { backgroundColor: '#FDD835', textColor: '#FFF' },
    { backgroundColor: '#EF6C00', textColor: '#FFF' },
    { backgroundColor: '#F4511E', textColor: '#FFF' },
    { backgroundColor: '#6D4C41', textColor: '#FFF' },
    { backgroundColor: '#455A64', textColor: '#FFF' },
  ]

  return randomColors[Math.floor(Math.random() * randomColors.length)]
}
