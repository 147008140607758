import { Breadcrumbs, DataTable, Heading, RichTabTitle, Tab, Tabs } from '@bit/ume.design-kit.ui'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Button } from 'antd'
import { Accordion, Box, Grommet } from 'grommet'
import { FormPreviousLink } from 'grommet-icons'
import moment from 'moment'
import React from 'react'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'
import XLSX from 'xlsx'
import { toaster } from '../../App'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { ReactComponent as Aliens } from '../../common/assets/images/aliens.svg'
import { roundNumber } from '../../common/NumberUtils'
import { screenSize } from '../../config/screens.config'
import { BatchAccordion, IBatchStatus } from '../../molecules/OriginationsBatch/BatchAccordion'
import { BigNumberCard } from '../../molecules/OriginationsBatch/BigNumberCard'
import { DownloadSpecificContractsButton } from '../../molecules/OriginationsBatch/ContractsButton'
import { BankerToTransitoryStep } from '../../organisms/FinancialOriginations/BatchStepsContent/BankerToTransitoryStep'
import { TransitoryToRetailerStep } from '../../organisms/FinancialOriginations/BatchStepsContent/TransitoryToRetailerStep'
import { UmeToBankerStep } from '../../organisms/FinancialOriginations/BatchStepsContent/UmeToBankerStep'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import {
  IBatchResponse,
  IStoreBatchResponse,
} from '../../services/bff-backoffice/liquidation-originations/interfaces/liquidation-originations.interfaces'
import {
  IRetailerResponse,
  IRetailersResponse,
  IStoreResponse,
  IStoresResponse,
} from '../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'
import { findIdInAttributeOfArray } from '../../utils'
import { PreviousButtonStyle } from '../CS-Profile/style'
import {
  getErrorStringForBackofficeOriginationsHttpErrors,
  renderBatchStatus,
  renderOriginationsDate,
  RETAILERS_BATCH_TABLE_HEADER,
  STORES_BATCH_TABLE_HEADER,
} from './config'
import { IOriginationsRetailerBatchResponse } from './types'

interface IOriginationsBatchPageProps {
  history: any
  match?: any
}

interface IOriginationsBatchPageState {
  batch?: IBatchResponse
  loadingContracts: boolean
  retailers: IRetailerResponse[]
  stores: IStoreResponse[]
  batchNotFound: boolean
}

export default class OriginationsBatchPage extends React.Component<
  IOriginationsBatchPageProps,
  IOriginationsBatchPageState
> {
  constructor(props: IOriginationsBatchPageProps) {
    super(props)

    this.state = {
      loadingContracts: false,
      retailers: [],
      stores: [],
      batchNotFound: false,
    }
  }

  componentDidMount = () => {
    this.fetchBatchFromAPI()
    this.loadRetailersFromAPI()
    this.loadStoresFromAPI()
  }

  // Loads Retailers from API in order to replace the IDs coming from Billings API
  loadRetailersFromAPI = () => {
    bffBackofficeApiService.retailers
      .getRetailers()
      .then((result: IRetailersResponse) => {
        this.setState({ retailers: result.retailers })
      })
      .catch(() => {
        toaster.showErrorToast(`Erro ao carregar varejistas`)
      })
  }

  // Loads Stores from API in order to replace the IDs coming from Billings API
  loadStoresFromAPI = () => {
    bffBackofficeApiService.retailers
      .getStores()
      .then((result: IStoresResponse) => {
        this.setState({ stores: result.stores })
      })
      .catch(() => {
        toaster.showErrorToast(`Erro ao carregar lojas`)
      })
  }

  fetchBatchFromAPI = () => {
    const { batchId } = this.props.match.params

    bffBackofficeApiService.liquidationOriginations
      .getBatchById(batchId)
      .then(batch => {
        this.setState({ batch })
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.statusCode === 404) {
          this.setState({ batchNotFound: true })
        }
        const errorString = getErrorStringForBackofficeOriginationsHttpErrors(error)
        toaster.showErrorToast(errorString)
      })
  }

  downloadContracts = () => {
    const { batch } = this.state
    if (!batch) {
      return toaster.showWarningToast(`Remessa sendo carregada`)
    }

    this.setState({ loadingContracts: true })

    bffBackofficeApiService.liquidationOriginations
      .getBatchOriginations(String(batch.id))
      .then(originations => {
        this.setState({ loadingContracts: false })
        toaster.showSuccessToast(`Originações baixadas com sucesso!`)

        let fileName = `originações_${moment(batch.batchDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}.xlsx`
        const ws = XLSX.utils.json_to_sheet(originations)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, `Originações`)
        XLSX.writeFile(wb, fileName)
      })
      .catch(error => {
        this.setState({ loadingContracts: false })

        const errorString = getErrorStringForBackofficeOriginationsHttpErrors(error)
        toaster.showErrorToast(errorString)
      })
  }

  onDownloadContractsByStoreClicked = async (storeBatch: IStoreBatchResponse) => {
    const { batch } = this.state
    if (!batch) {
      return toaster.showWarningToast(`Remessa sendo carregada`)
    }

    return bffBackofficeApiService.liquidationOriginations
      .getBatchOriginations(String(batch.id), storeBatch.storeId)
      .then(originations => {
        toaster.showSuccessToast(`Originações baixadas com sucesso!`)

        let fileName = `originações_loja_${storeBatch.storeId}_${moment(batch.batchDate, 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        )}.xlsx`
        const ws = XLSX.utils.json_to_sheet(originations)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, `Originações`)
        XLSX.writeFile(wb, fileName)
      })
      .catch(error => {
        const errorString = getErrorStringForBackofficeOriginationsHttpErrors(error)
        toaster.showErrorToast(errorString)
      })
  }

  onDownloadContractsByRetailerClicked = async (retailerBatch: IOriginationsRetailerBatchResponse): Promise<any> => {
    const { batch } = this.state
    if (!batch) {
      return toaster.showWarningToast(`Remessa sendo carregada`)
    }

    return bffBackofficeApiService.liquidationOriginations
      .getBatchOriginations(String(batch.id), undefined, retailerBatch.retailerId)
      .then(originations => {
        toaster.showSuccessToast(`Originações baixadas com sucesso!`)

        let fileName = `originações_varejista_${retailerBatch.retailerId}_${moment(
          batch.batchDate,
          'YYYY-MM-DD'
        ).format('DD/MM/YYYY')}.xlsx`
        const ws = XLSX.utils.json_to_sheet(originations)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, `Originações`)
        XLSX.writeFile(wb, fileName)
      })
      .catch(error => {
        const errorString = getErrorStringForBackofficeOriginationsHttpErrors(error)
        toaster.showErrorToast(errorString)
      })
  }

  onStepEnded = () => {
    this.fetchBatchFromAPI()
  }

  render() {
    const { batchId } = this.props.match.params
    let { batch, loadingContracts, retailers, batchNotFound } = this.state

    if (!batch && batchNotFound) {
      return (
        <>
          <NotFoundMessage>
            <strong>REMESSA DE ID {batchId} NÃO EXISTE.</strong>
          </NotFoundMessage>

          <NotFoundContainer>
            <Aliens />
            <h3>Ou talvez Aliens tenham invadido a Terra e destruído os servidores.</h3>
          </NotFoundContainer>
        </>
      )
    }

    if (!batch) {
      return (
        <LoadingContainer>
          <LoadingIcon />
        </LoadingContainer>
      )
    }

    let formattedBatchDate = moment(batch.batchDate, 'YYYY-MM-DD').format('dddd, D [de] MMMM [de] YYYY')
    formattedBatchDate = formattedBatchDate.charAt(0).toUpperCase() + formattedBatchDate.slice(1)

    let RETAILERS_HEADER = Object.assign([], RETAILERS_BATCH_TABLE_HEADER)
    const retailersData = batch.retailersBatch.map(retailerBatch => {
      let retailer = findIdInAttributeOfArray(retailerBatch.retailerId, 'id', this.state.retailers)
      let retailerName = retailer ? retailer.fantasyName : retailerBatch.retailerId
      return {
        ...retailerBatch,
        retailerName,
        netSum: roundNumber(retailerBatch.principalSum - retailerBatch.umeFeeSum, 2),
        grossSum: roundNumber(retailerBatch.principalSum + retailerBatch.discountSum, 2),
      }
    })
    // Need to do this so the callback can access the class scope
    RETAILERS_HEADER.push({
      label: 'Contratos',
      attribute: 'originations',
      formatCell: (retailerBatch: IOriginationsRetailerBatchResponse) => {
        return (
          <DownloadSpecificContractsButton
            onDownloadContracts={async () => {
              return this.onDownloadContractsByRetailerClicked(retailerBatch)
            }}
          />
        )
      },
    })

    const RETAILERS_REPORT_HEADER = RETAILERS_BATCH_TABLE_HEADER.map((d: { label: any; attribute: any }) => {
      return { label: d.label, key: d.attribute }
    })

    let STORES_HEADER = Object.assign([], STORES_BATCH_TABLE_HEADER)
    const storesData = batch.storesBatch.map(storeBatch => {
      let store = findIdInAttributeOfArray(storeBatch.storeId, 'id', this.state.stores)
      let storeName = store ? store.name : storeBatch.storeId

      let retailer = findIdInAttributeOfArray(storeBatch.retailerId, 'id', this.state.retailers)
      let retailerName = retailer ? retailer.fantasyName : storeBatch.retailerId
      const netSum = roundNumber(storeBatch.principalSum - storeBatch.umeFeeSum, 2)
      const grossSum = roundNumber(storeBatch.principalSum + storeBatch.discountSum, 2)
      return { ...storeBatch, storeName, retailerName, netSum, grossSum }
    })

    // Need to do this so the callback can access the class scope
    STORES_HEADER.push({
      label: 'Contratos',
      attribute: 'originations',
      formatCell: (storeBatch: IStoreBatchResponse) => (
        <DownloadSpecificContractsButton
          onDownloadContracts={async () => {
            return this.onDownloadContractsByStoreClicked(storeBatch)
          }}
        />
      ),
    })

    const STORES_REPORT_HEADER = STORES_BATCH_TABLE_HEADER.map((d: { label: any; attribute: any }) => {
      return { label: d.label, key: d.attribute }
    })

    const firstPendingStep = batch.steps.find(step => !step.endTimestamp)
    let umeToBankerStepStatus: IBatchStatus
    let bankerToTransitoryStepStatus: IBatchStatus
    let transitoryToRetailerStepStatus: IBatchStatus

    if (!firstPendingStep) {
      umeToBankerStepStatus = 'FINISHED'
      bankerToTransitoryStepStatus = 'FINISHED'
      transitoryToRetailerStepStatus = 'FINISHED'
    } else {
      umeToBankerStepStatus = batch.steps[0].endTimestamp
        ? 'FINISHED'
        : firstPendingStep.order === 0
        ? 'PENDING'
        : 'WAITING'
      bankerToTransitoryStepStatus = batch.steps[1].endTimestamp
        ? 'FINISHED'
        : firstPendingStep.order === 1
        ? 'PENDING'
        : 'WAITING'
      transitoryToRetailerStepStatus = batch.steps[2].endTimestamp
        ? 'FINISHED'
        : firstPendingStep.order === 2
        ? 'PENDING'
        : 'WAITING'
    }

    return (
      <Container>
        <Box margin={{ top: 'small' }}>
          <Breadcrumbs anchors={['Home', 'Remessas de Originações', batchId]} />
        </Box>

        <Box margin={{ vertical: 'medium' }} direction="row" align="center" gap="xsmall">
          <PreviousButtonStyle
            onClick={() => this.props.history.go(-1)}
            width="32px"
            height="32px"
            style={{ marginLeft: 0 }}
            icon={<FormPreviousLink color="white" />}
          />
          <Heading size="12px">Voltar</Heading>
        </Box>

        <Header>
          <TodayDate> {formattedBatchDate} </TodayDate>

          <DownloadAllContractsButton
            type="primary"
            size="middle"
            loading={loadingContracts}
            onClick={() => this.downloadContracts()}
          >
            Baixar contratos
          </DownloadAllContractsButton>
        </Header>
        <ScreenSubTitle> {renderBatchStatus(batch.status)} </ScreenSubTitle>

        <Box direction="row" wrap margin={{ top: '5vh' }}>
          <BigNumberCard title={'Contratos'} value={batch.originationsCount} style={{ margin: 10 }} />
          <BigNumberCard
            isMoney
            title={'Principal'}
            value={batch.principalSum}
            style={{ margin: 10 }}
            valueColor={UMEColors.green.primary}
          />
          <BigNumberCard isMoney title={'IoF'} value={batch.iofSum} valueFontSize={'40px'} style={{ margin: 10 }} />
          <BigNumberCard
            isMoney
            title={'Taxa UME'}
            value={batch.umeFeeSum}
            valueFontSize={'40px'}
            style={{ margin: 10 }}
          />
        </Box>
        <OriginationsDates>
          {renderOriginationsDate(batch.originationsStartDate, batch.originationsEndDate)}
        </OriginationsDates>

        <Tabs justify="start" margin={{ top: '5vh', bottom: '5vh' }}>
          <TabStyle margin={{ left: 'medium' }} title={<RichTabTitle label={'Etapas'} />}>
            <Grommet theme={{ accordion: { border: undefined } }}>
              <Accordion gap="8vh" margin={{ top: '5vh' }}>
                <BatchAccordion
                  stepOrder="Primeira etapa"
                  from="UME"
                  to="Banker"
                  status={umeToBankerStepStatus}
                  step={batch.steps[0]}
                >
                  <UmeToBankerStep
                    batch={batch}
                    onStepEnded={() => this.onStepEnded()}
                    status={umeToBankerStepStatus}
                  />
                </BatchAccordion>

                <BatchAccordion
                  stepOrder="Segunda etapa"
                  from="Banker"
                  to="UME"
                  status={bankerToTransitoryStepStatus}
                  step={batch.steps[1]}
                >
                  <BankerToTransitoryStep
                    batch={batch}
                    onStepEnded={() => this.onStepEnded()}
                    status={bankerToTransitoryStepStatus}
                    retailers={retailers}
                  />
                </BatchAccordion>

                <BatchAccordion
                  stepOrder="Terceira etapa"
                  from="UME"
                  to="Varejista"
                  status={transitoryToRetailerStepStatus}
                  step={batch.steps[2]}
                >
                  <TransitoryToRetailerStep
                    batch={batch}
                    onStepEnded={() => this.onStepEnded()}
                    status={transitoryToRetailerStepStatus}
                    retailers={retailers}
                  />
                </BatchAccordion>
              </Accordion>
            </Grommet>
          </TabStyle>

          <TabStyle title={<RichTabTitle label={'Varejistas'} />}>
            <Box margin="10px">
              <TableSectionTitle> Varejistas nessa remessa ({batch.retailersBatch.length}) </TableSectionTitle>
              {!!retailersData && retailersData.length && (
                <DownloadButton type="primary">
                  <CSVLink
                    data={retailersData}
                    headers={RETAILERS_REPORT_HEADER}
                    filename={`remessa_varejos_${batch.batchDate}`}
                  >
                    Baixar csv
                  </CSVLink>
                </DownloadButton>
              )}
              <TableContainer>
                <DataTable header={RETAILERS_HEADER} data={retailersData} fontSize="14px" />
              </TableContainer>
            </Box>
          </TabStyle>

          <TabStyle title={<RichTabTitle label={'Loja'} />}>
            <Box margin="10px">
              <TableSectionTitle> Lojas nessa remessa ({batch.storesBatch.length}) </TableSectionTitle>
              {!!storesData && storesData.length && (
                <DownloadButton type="primary">
                  <CSVLink
                    data={storesData}
                    headers={STORES_REPORT_HEADER}
                    filename={`remessa_lojas_${batch.batchDate}`}
                  >
                    Baixar csv
                  </CSVLink>
                </DownloadButton>
              )}
              <TableContainer>
                <DataTable header={STORES_HEADER} data={storesData} fontSize="14px" />
              </TableContainer>
            </Box>
          </TabStyle>
        </Tabs>
      </Container>
    )
  }
}

const DownloadButton = styled(Button)`
  background-color: ${UMEColors.blue.dark};
  color: white;
  width: 150px;
  &:hover {
    opacity: 0.5;
  }
`

const TableSectionTitle = styled.div`
  margin-top: 3vh;
  margin-bottom: 1vh;

  text-align: left;
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
`

const TableContainer = styled.div`
  margin-top: 2vh;

  min-height: 400px;
  max-height: 400px;
  border-radius: 8px;
  background-color: ${UMEColors.white.primary};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 33px;
  overflow-x: auto;
  overflow-y: auto;
`

const TabStyle = styled(Tab)`
  opacity: ${(props: any) => (props.active ? 1 : 0.3)};
  min-width: 105px;

  & button,
  div,
  span {
    text-align: start;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: -0.69px;
    line-height: 25px;
    margin-left: 1px;
    margin-bottom: 1px;
    color: ${UMEColors.black.primary};
  }

  margin: 10px;

  &:nth-child(n) {
    > div {
      border-bottom: solid 2px ${UMEColors.black.primary};
    }
  }
`

const NotFoundMessage = styled.div`
  text-align: center;
  width: 100%;
  background-color: ${UMEColors.red.light};
  color: ${UMEColors.white.primary};
  padding: 7px;
`

const NotFoundContainer = styled.div`
  background-color: white;
  min-height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  & > h1 {
    margin-bottom: 5vh;
  }
  & > svg {
    margin-bottom: 2vh;
    opacity: 0.7;
    width: 150px;
    height: 150px;
  }

  & > h3 {
    width: 300px;
    word-wrap: break-word;
    color: ${UMEColors.gray.aluminium};
  }
`

const LoadingContainer = styled.div`
  background-color: white;
  min-height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  background-color: white;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2);
  min-height: 95vh;
  background-color: ${UMEColors.gray.azure};
  text-align: left;

  padding: 24px 70px 24px 70px;

  @media ${screenSize.mobileL} {
    &:nth-child(even) {
      padding: 12px 20px 12px 20px;
    }
  }
`

const Header = styled.div`
  margin-top: 2vh;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const TodayDate = styled.span`
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: left;
  // line-height: 40px;
`

const ScreenSubTitle = styled.p`
  text-align: left;

  color: ${UMEColors.gray.primary};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 19px;
`

const DownloadAllContractsButton = styled(Button)`
  display: inline-block;

  border: none;

  // TEXT
  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;

  // BUTTON
  border-radius: 2px;
  background-color: ${UMEColors.gray.primary};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
    transition: 0.3s;
    background-color: ${UMEColors.gray.primary};
    opacity: 0.5;
  }

  &:focus {
    background-color: ${UMEColors.gray.primary};
  }

  &:active {
    background-color: ${UMEColors.gray.primary};
  }
`
const OriginationsDates = styled.p`
  text-align: left;

  margin-left: 10px;
  color: ${UMEColors.gray.primary};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 19px;
`
