import { Button } from '@bit/ume.design-kit.ui'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Box, Text } from 'grommet'
import { FormPreviousLink } from 'grommet-icons'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { laptop } from '../../common/assets/_breakpoints'
import ApplicationOption from '../../molecules/Applications/ApplicationOption'
import RetailerBenchApplicationsBreadcrumbs from '../../molecules/RetailersBench/RetailerBenchApplicationsBreadcrumbs'
import UnknownError from '../../organisms/Applications/UnknownError'
import { RetailersBenchSliceReducer } from '../../redux/reducers/retailers-bench/retailers-bench.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import RetailerBenchApplicationsTitle from './RetailerBenchApplicationsTitle'

interface MatchParams {
  retailerId?: string
}

interface Props extends RouteComponentProps<MatchParams> {}

const RetailerBenchApplicationsPage = ({ match, history }: Props) => {
  const dispatch = useDispatch()
  const retailerId = match.params.retailerId as string

  const {
    retailerBenchApplications,
    fetchRetailerBenchApplicationsError,
    isLoadingRetailerBenchApplications,
    retailersBench,
    isLoadingRetailersBench,
    fetchRetailersBenchError,
  } = useTypedSelector(state => ({
    retailerBenchApplications: state.retailersBench.retailerBenchApplications,
    isLoadingRetailerBenchApplications: state.retailersBench.isLoadingRetailerBenchApplications,
    fetchRetailerBenchApplicationsError: state.retailersBench.fetchRetailerBenchApplicationsError,

    retailersBench: state.retailersBench.retailersBench,
    isLoadingRetailersBench: state.retailersBench.isLoadingRetailersBench,
    fetchRetailersBenchError: state.retailersBench.fetchRetailersBenchError,
  }))

  useEffect(() => {
    if (retailerId) {
      dispatch(RetailersBenchSliceReducer.actions.fetchRetailerBenchApplications(retailerId))
      if (!retailersBench) {
        dispatch(RetailersBenchSliceReducer.actions.fetchRetailersBench())
      }
    }
  }, [retailerId, dispatch, retailersBench])

  if (isLoadingRetailerBenchApplications || isLoadingRetailersBench) {
    return (
      <Box width="100%" height="90vh">
        <LoadingIcon />
      </Box>
    )
  }

  const handlePreviousButtonClick = () => {
    history.push(`/retailers-bench`)
  }

  if (
    fetchRetailersBenchError ||
    fetchRetailerBenchApplicationsError ||
    !retailerBenchApplications ||
    !retailersBench
  ) {
    if (!fetchRetailersBenchError && !retailersBench?.retailers[retailerId]) {
      return (
        <Container>
          <RetailerBenchApplicationsBreadcrumbs fantasyName="Varejo não encontrado" />
          <RetailerBenchApplicationsTitle
            title={`Varejo não encontrado`}
            icon={
              <PreviousButton
                onClick={handlePreviousButtonClick}
                width="32px"
                height="32px"
                style={{ marginLeft: 0 }}
                icon={<FormPreviousLink color="white" />}
              />
            }
            isRetailerActive={false}
          />
          <Legend isError>Este varejo não está na banca de avaliação.</Legend>
        </Container>
      )
    }

    if (fetchRetailerBenchApplicationsError?.status !== 404) {
      return <UnknownError />
    }
  }

  const Content = () => {
    if (fetchRetailerBenchApplicationsError?.status === 404) {
      return <Legend isError>Este varejo não possui applications a serem avaliados.</Legend>
    }

    return (
      <>
        <Legend>Selecione um dos applications abaixo apra avaliar.</Legend>
        <ApplicationsContainer>
          {retailerBenchApplications?.map(application => (
            <ApplicationOption createdOn={application.createdOn} id={application.id} key={application.id} retailerId={retailerId}/>
          ))}
        </ApplicationsContainer>
      </>
    )
  }

  const fantasyName = retailersBench?.retailers[retailerId].fantasyName as string

  return (
    <Container>
      <RetailerBenchApplicationsBreadcrumbs fantasyName={retailersBench ? fantasyName : ''} />
      <RetailerBenchApplicationsTitle
        title={`${fantasyName}`}
        icon={
          <PreviousButton
            onClick={handlePreviousButtonClick}
            width="32px"
            height="32px"
            style={{ marginLeft: 0 }}
            icon={<FormPreviousLink color="white" />}
          />
        }
        retailerId={retailerId}
        isRetailerActive={retailersBench?.retailers[retailerId].active as boolean}
      />
      <Content />
    </Container>
  )
}

const Container = styled(Box)`
  margin-top: 2vh;
  margin-bottom: 5vh;
  margin-left: 2vw;
  width: 60%;

  @media (max-width: ${laptop}) {
    width: 95%;
  }
`

const ApplicationsContainer = styled(Box)`
  margin-top: 2vh;
  margin-left: 2vw;
  width: 60%;

  @media (max-width: ${laptop}) {
    width: 95%;
  }
`

const PreviousButton = styled(Button)`
  padding: 0;
`

const Legend = styled(Text)`
  text-align: left;
  margin-top: 16px;
  margin-left: 32px;
  color: ${(props: any) => (props.isError ? UMEColors.red.persian : '#5b5b5b')};
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
`

export default RetailerBenchApplicationsPage
