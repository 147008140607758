import { Box } from 'grommet'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { laptop } from '../../common/assets/_breakpoints'
import ApplicationsBreadcrumbs from '../../molecules/Applications/ApplicationsBreadcrumbs'
import BenchApplicationBreadcrumbs from '../../molecules/RetailersBench/BenchApplicationBreadcrumbs'
import ApplicationDetails from '../../organisms/Applications/ApplicationDetails'
import BorrowerDetails from '../../organisms/Applications/BorrowerDetails'
import EvaluationButtons from '../../organisms/Applications/EvaluationButtons'
import ImagesCard from '../../organisms/Applications/ImagesCard'
import NotFoundError from '../../organisms/Applications/NotFoundError'
import UnknownError from '../../organisms/Applications/UnknownError'
import { ApplicationsSliceReducer } from '../../redux/reducers/applications/applications.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'

interface MatchParams {
  applicationId: string
  retailerId: string
}

interface IApplicationsPageProps extends RouteComponentProps<MatchParams> {}

const ApplicationsPage = ({ match, history }: IApplicationsPageProps) => {
  const dispatch = useDispatch()
  const { applicationId, retailerId } = match.params
  const { application, isLoadingApplication, fetchApplicationError } = useTypedSelector(state => ({
    application: state.applications.application,
    isLoadingApplication: state.applications.isLoadingApplication,
    fetchApplicationError: state.applications.fetchApplicationError,
  }))

  useEffect(() => {
    dispatch(ApplicationsSliceReducer.actions.fetchApplication(applicationId))
  }, [applicationId, dispatch])

  if (isLoadingApplication) {
    return (
      <Box width="100%" height="90vh">
        <LoadingIcon />
      </Box>
    )
  }

  if (fetchApplicationError || !application) {
    if (fetchApplicationError?.status === 404) {
      return <NotFoundError message={`APPLICATION DE ID ${applicationId} NÃO ENCONTRADO.`} />
    }

    return <UnknownError />
  }

  return (
    <Container>
      { retailerId ? <BenchApplicationBreadcrumbs retailerId={retailerId} screenName={application.id} /> : <ApplicationsBreadcrumbs screenName={application.id} /> }
      <ApplicationDetails history={history} />
      <BorrowerDetails />
      <ImagesCard />
      <EvaluationButtons />
    </Container>
  )
}

export default ApplicationsPage

const Container = styled(Box)`
  margin-top: 2vh;
  margin-bottom: 5vh;
  margin-left: 2vw;
  width: 60%;

  @media (max-width: ${laptop}) {
    width: 95%;
  }
`
