import axios from "axios";

import { useQuery } from 'react-query';
import { bffBackofficeApiService } from "../../services/bff-backoffice";

export const useGetReceipt = (url: string) => {
  return useQuery<any, Error>({
    retry: false,
    queryKey: ['getReceipt', url],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return axios
        .get(
            url,
            {
                responseType: "blob",
                headers: {
                    'Authorization': bffBackofficeApiService.auth.getToken(),
                }
            }
        ).then((res) => res.data)
        .catch(() => {
          throw new Error("Error getting file")
        })
    },
  })
}