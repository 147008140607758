import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { Reducers, rootSaga } from '../reducers'

const sagaMiddleware = createSagaMiddleware()

// Note: this API requires redux@>=3.1.0
const Store = createStore(Reducers, applyMiddleware(sagaMiddleware))

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof Reducers>
export default Store
