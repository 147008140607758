import { Loading, Text } from '@bit/ume.design-kit.ui'
import { Box } from 'grommet'
import * as React from 'react'
import { ResponsiveGrid } from '../../atoms/ResponsiveGrid/ResponsiveGrid'
import { getPayableAdditions, getPayableDiscounts } from '../../common/installment-computed-values'
import { InfoTextStyle, LabelStyle } from '../../pages/CS-Profile/ContractsSummaryCard/style'
import { ISingleInstallment } from '../../services/bff-backoffice/coordinator/interfaces/installment.interfaces'
import { formatDateTreatingError, formatNumberToBRL } from '../../utils'
import { CardStyle, DataBoxStyle } from './style'

export interface IInstallmentPaymentsProps {
  installment?: ISingleInstallment | Error
}

export default class InstallmentPayments extends React.PureComponent<IInstallmentPaymentsProps> {
  public render() {
    const { installment } = this.props

    const errorCard = (
      <Text color="red" light>
        Não foi possível carregar os dados para esta parcela.
      </Text>
    )

    const InstallmentPaymentsCard = (installment: ISingleInstallment) => (
      <Box>
        <ResponsiveGrid columns="150px" gap="small">
          <DataBoxStyle>
            <LabelStyle>Valor devido</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(installment.paymentDue)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Data do pgto</LabelStyle>
            <InfoTextStyle>
              {installment.paymentTimestamp ? formatDateTreatingError(installment.paymentTimestamp) : '-'}
            </InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle> Adições</LabelStyle>
            <InfoTextStyle>
              {formatNumberToBRL(
                getPayableAdditions({
                  paymentDue: installment.paymentDue,
                  value: installment.installmentValue,
                  paidValue: installment.paidValue,
                  paymentTimestamp: installment.paymentTimestamp,
                })
              )}
            </InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Descontos</LabelStyle>
            <InfoTextStyle>
              {formatNumberToBRL(
                getPayableDiscounts({
                  paymentDue: installment.paymentDue,
                  value: installment.installmentValue,
                  paidValue: installment.paidValue,
                  paymentTimestamp: installment.paymentTimestamp,
                })
              )}
            </InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Valor pago</LabelStyle>
            <InfoTextStyle>{installment.paidValue ? formatNumberToBRL(installment.paidValue) : '-'}</InfoTextStyle>
          </DataBoxStyle>
        </ResponsiveGrid>
      </Box>
    )
    return (
      <CardStyle width="552px">
        {installment === undefined ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : installment instanceof Error ? (
          errorCard
        ) : (
          InstallmentPaymentsCard(installment)
        )}
      </CardStyle>
    )
  }
}
