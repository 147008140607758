import styled from 'styled-components'
import { Text, Heading } from 'grommet'

export const SectionTitleStyle = styled(Heading)`
  margin-bottom: 0;
  margin-top: 32px;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 47px;
  width: fit-content;
`
export const SectionTextStyle = styled(Text)`
  font-size: ${(props: any) => (props.size ? props.size : '16px')};
  font-weight: ${(props: any) => (props.bold ? '500' : '300')};
  letter-spacing: 0;
  line-height: 16px;
  margin-right: 28px;
  text-align: start;
`