import {
  SourceProduct,
  SourceProductType,
} from '../../../services/bff-backoffice/coordinator/enums/source-product.enum'

const sourceProductMap = {
  [SourceProduct.HIGH_RECURRENCE]: 'Ume Leve',
  [SourceProduct.PAY_ANYWHERE]: 'Pix',
  [SourceProduct.LOW_RECURRENCE]: 'Ume Convencional',
  [SourceProduct.MEDIUM_RECURRENCE]: 'Ume Convencional',
  [SourceProduct.PERSONAL_LOAN]: 'Empréstimo Pessoal',
  [SourceProduct.TOP_UP]: 'Gift Card e Recarga',
  [SourceProduct.WORKING_CAPITAL]: 'Crédito Semanal',
}

export const translateSourceProduct = (sourceProduct?: SourceProductType): string => {
  if (!sourceProduct) return 'Ume Convencional'

  return sourceProductMap[sourceProduct] ?? '-'
}
