import {
  InvoiceGeneratedAt,
  InvoiceGeneratedAtType,
} from '../../../services/bff-backoffice/coordinator/interfaces/invoice.interfaces'

export const getOperator = (
  invoiceGeneratedAt: InvoiceGeneratedAtType | undefined,
  operatorId: string | undefined
): string => {
  if (invoiceGeneratedAt === InvoiceGeneratedAt.CHATBOT) return 'Chatbot'

  return operatorId ?? 'Cliente'
}
