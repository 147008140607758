import React, { useState, ReactNode } from 'react'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Box } from 'grommet'
import { DataTable } from '@bit/ume.design-kit.ui'
import styled from 'styled-components'
import { css } from 'styled-components'
import Modal from 'antd/lib/modal/Modal'
import { getArrayOfObjectsKeys } from '../../config/utils'
import { formatNumberToBRL } from '../../utils'
import { IPostLimitsValidateResponse } from '../../services/bff-backoffice/coordinator/interfaces/limits.interfaces'

interface ILimitTableData {
  type: string
  reason: string
  count: number
}

const VALIDATION_TABLE_HEADER = [
  {
    label: 'Tipo',
    attribute: 'type',
    textAlign: 'center',
    formatCell: (row: ILimitTableData) => {
      if (row.type === 'error') {
        return <TypeLabel backgroundColor="#FF2601">ERRO</TypeLabel>
      } else if (row.type === 'warning') {
        return <TypeLabel backgroundColor="#FF8B00">WARNING</TypeLabel>
      } else return <span>{row.type}</span>
    },
  },
  { attribute: 'reason', label: 'Descrição', textAlign: 'center' },
  {
    attribute: 'count',
    label: 'Quantidade',
    textAlign: 'center',
  },
]

interface ILimitsWarningsProps {
  limitsWarnings?: IPostLimitsValidateResponse
}

interface ILimitsWarningsState {
  isModalVisible: boolean
  modalData: any[]
  modalHeader: any[]
  modalTitle: ReactNode
}

const initialState = {
  isModalVisible: false,
  modalData: [],
  modalHeader: [],
  modalTitle: <></>,
}

export const LimitsWarnings = (props: ILimitsWarningsProps) => {
  const [state, setState] = useState<ILimitsWarningsState>(initialState)

  // On table click:
  //  -- Show modal with all errors/warnings of the clicked element
  const handleTableClick = (element: ILimitTableData) => {
    if (!props.limitsWarnings) {
      return
    }

    // Filter data for the specific error/warning reason
    let modalData: any[] = []
    let modalTitle: any
    if (element.type === 'error') {
      modalData = props.limitsWarnings.errors.filter(error => error.reason === element.reason)
      modalTitle = (
        <>
          <TypeLabel backgroundColor="#FF2601" style={{ width: 'unset', padding: 5, marginRight: 10 }}>
            ERRO
          </TypeLabel>
          {element.reason}
        </>
      )
    } else {
      modalData = props.limitsWarnings.warnings.filter(warning => warning.reason === element.reason)
      modalTitle = (
        <>
          <TypeLabel backgroundColor="#FF8B00" style={{ width: 'unset', padding: 5, marginRight: 10 }}>
            WARNING
          </TypeLabel>
          {element.reason}
        </>
      )
    }

    // Get a generic table header
    //  -- each error/reason has a different object
    const objectsKeys = getArrayOfObjectsKeys(modalData)
    const modalHeader = objectsKeys.map((key: string) => ({
      label: key,
      attribute: key,
      formatCell: (row: any) => {
        // Damn this if...
        if (isNaN(row[key]) || key === 'borrowerId') {
          return row[key]
        } else {
          return formatNumberToBRL(row[key])
        }
      },
    }))

    setState({ isModalVisible: true, modalData, modalHeader, modalTitle })
  }

  const { limitsWarnings } = props
  const { isModalVisible, modalData, modalTitle, modalHeader } = state

  // If there's no warnings, then no need to show any table
  if (!limitsWarnings) return <></>

  // ------------------ DATA TRANSFORMATION ------------------
  const { errors, warnings } = limitsWarnings

  // Aggregating the errors into a js object
  //    - counts the number of times each error happened
  const errorsObject: any = {}
  for (const error of errors) {
    if (!errorsObject[error.reason]) {
      errorsObject[error.reason] = 1
    } else {
      errorsObject[error.reason] += 1
    }
  }

  // Aggregating the warnings into a js object
  //    - counts the number of times each warning happened
  const warningsObject = warnings.reduce((object: any, limitWarning) => {
    if (!object[limitWarning.reason]) {
      object[limitWarning.reason] = 1
    } else {
      object[limitWarning.reason] += 1
    }

    return object
  }, {})

  // Transforms the object into an array
  const errorsArray: ILimitTableData[] = Object.keys(errorsObject).map((key: string) => ({
    type: 'error',
    reason: key,
    count: errorsObject[key],
  }))
  const warningsArray: ILimitTableData[] = Object.keys(warningsObject).map((key: string) => ({
    type: 'warning',
    reason: key,
    count: warningsObject[key],
  }))

  // The data shown in table is:
  //    -- errors and warnings
  const tableData = errorsArray.concat(warningsArray)

  return (
    <Box style={{ minHeight: 400 }}>
      <h1>Validação da planilha:</h1>
      {tableData.length === 0 ? (
        <span>
          <strong style={{ color: UMEColors.green.dark }}>Nenhum erro ou warning encontrado.</strong>
        </span>
      ) : (
        <></>
      )}
      <TableContainer>
        <DataTable
          onRowElementClick={handleTableClick}
          header={VALIDATION_TABLE_HEADER}
          data={tableData}
          fontSize="14px"
        />
      </TableContainer>

      <Modal
        visible={isModalVisible}
        title={modalTitle}
        width="80%"
        onOk={() => setState({ ...state, isModalVisible: false })}
        onCancel={() => setState({ ...state, isModalVisible: false })}
        footer={null}
      >
        <TableContainer>
          <DataTable header={modalHeader} data={modalData} fontSize="14px" />
        </TableContainer>
      </Modal>
    </Box>
  )
}

const TypeLabel = styled.span`
  ${(props: any) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
  display: inline-block;
  height: 100%;
  width: 80%;
  text-align: center;
  border-radius: 2;
  color: ${UMEColors.white.primary};
`

const TableContainer = styled.div`
  ${(props: any) =>
    props.isLoading &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  margin-top: 2vh;

  max-height: 400px;
  border-radius: 8px;
  background-color: ${UMEColors.white.primary};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 33px;
  overflow-x: auto;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${UMEColors.gray.gainsboro};
    border-left: 2px solid white;
    border-right: 2px solid white;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${UMEColors.gray.eclipse};
    border-radius: 9px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${UMEColors.gray.nobel};
  }
`
