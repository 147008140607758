import { Card, Tag, Text } from '@bit/ume.design-kit.ui'
import { blue, gray } from '@bit/ume.design-kit.ui/Palette'
import { Box, TextInput } from 'grommet'
import styled from 'styled-components'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { LoadingButton } from '../LoadingButton/LoadingButton'
import { css } from 'styled-components'
import GrommetDatePicker from '../DatePicker/GrommetDatePicker'

export const StatusTagStyle = styled(Tag)`
  &:nth-child(1) {
    padding: 4px 6px;
    height: 22px;
    width: 88px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    justify-content: start;
  }
  &:nth-child(2) {
    margin-left: 12px;
  }
`

export const TableBoxStyle = styled(Card)`
  max-width: 1210px;
  box-shadow: none;
  border: none;
  padding: 12px 2px;
  background-color: transparent;

  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${gray.gainsboro};
    border: 2px solid ${gray.azure};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${gray.eclipse};
    border-radius: 9px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${gray.nobel};
  }
`

export const LinkAnchorStyle = styled.a`
  color: ${blue.navy};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-decoration: underline;

  &:hover {
    color: white;
  }
`

export const LabelStyle = styled(Text)`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  color: ${gray.nobel};
  margin-bottom: 4px;
  margin-top: 0;
`
export const InfoTextStyle = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
`
export const DifferenceTextStyle = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
`

export const CardStyle = styled(Card)`
  border-radius: 8px;
  padding: 24px;
`

export const ConfirmationMessageStyle = styled(Text)`
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  color: ${UMEColors.green.laurel};
`

export const BarCodeStyled = styled(Text)`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  color: ${gray.nobel};
  margin-bottom: 2px;
  margin-top: 0;
  margin-right: 28px;
`

export const DataBoxStyle = styled(Box)`
  margin: 2px 8px 12px 8px;
`
export const GenerateInvoiceLoadingButton = styled(LoadingButton)`
  border-radius: 6px;
  padding: 10px;
  width: 200px;
  // Not in
  background-color: ${UMEColors.blue.navy};
  border: none;
  box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4);
  margin-top: 10px;

  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  transition: 0.4s;

  &:hover {
    transition: 250ms;
    opacity: 0.4;
    cursor: pointer;
  }

  ${(props: any) =>
    props.disabled &&
    css`
      &:hover {
        opacity: 0.2;
        cursor: not-allowed;
        transition: 0.4s;
      }
      opacity: 0.2;
      cursor: not-allowed;
      transition: 0.4s;
    `}
`
export const SendBillingEmailLoadingButton = styled(LoadingButton)`
  border-radius: 6px;
  padding: 10px;
  margin-top: 12px;
  width: 150px;
  // Not in
  background-color: ${UMEColors.blue.navy};
  border: none;
  box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4);

  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  transition: 0.4s;

  &:hover {
    transition: 250ms;
    opacity: 0.4;
    cursor: pointer;
  }

  ${(props: any) =>
    props.disabled &&
    css`
      &:hover {
        opacity: 0.2;
        cursor: not-allowed;
        transition: 0.4s;
      }
      opacity: 0.2;
      cursor: not-allowed;
      transition: 0.4s;
    `}
`
export const EmailTextInput = styled(TextInput)`
  margin-top: 12px;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid ${UMEColors.gray.light};
  border-radius: 4px;
  background-color: ${UMEColors.white.primary};

  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.47px;

  ${(props: any) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`
export const DefaultEmailsContainer = styled.div`
    min-height: 160px;
    max-height: 160px;
    margin: 0 10px 0 10px
    overflow-y: auto;
    ::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: ${UMEColors.gray.gainsboro};
      border-left: 2px solid white;
      border-right: 2px solid white;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${UMEColors.gray.eclipse};
        border-radius: 9px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: ${UMEColors.gray.nobel};
    }
`

export const DueDatePicker = styled(GrommetDatePicker)`
  margin-top: 4px;
  box-sizing: border-box;
  height: 36px;
  width: 40%;
  border: 1px solid ${UMEColors.gray.light};
  border-radius: 4px;
  background-color: ${UMEColors.white.primary};

  // TODO - Not in Design Kit
  color: #585858;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.47px;
`