import { getCoordinatorRootUrl } from "../../config/config"

export const resolvePurchaseReceiptRoute = (id: string): string => {
    return `/render/${id}/originations`
}

export const resolveRenegotiationReceiptRoute = (id: string): string => {
    return `/render/${id}/renegotiations`
}

export const resolveExternalReceiptUrl = (type: string, ids: string): string => {
    const baseUrl = getCoordinatorRootUrl()
    return baseUrl + resolveExternalReceiptRoute(type, ids)
}

const resolveExternalReceiptRoute = (type: string, ids: string) => {
    switch (type) {
        case 'originations': return `/contracts/${ids}/render`
        case 'payments': return `/contracts/installments/payments/render?ids=${ids}`
        case 'renegotiations-payments': return `/renegotiations/payments/render?ids=${ids}`
        case 'renegotiations': return `/renegotiations/${ids}/render`
        default:
            return `/contracts/${ids}/render`
    }
}