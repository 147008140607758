import { Button, Tag } from '@bit/ume.design-kit.ui'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Box } from 'grommet'
import { FormPreviousLink } from 'grommet-icons'
import { History } from 'history'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { denialReasonsText } from '../../common/assets/utils/denial-reasons'
import ApplicationsTitle from '../../molecules/Applications/ApplicationsTitle'
import DetailsCard, { IDetailsCardData } from '../../molecules/RetailersPage/DetailsCard'
import { ApplicationsSliceReducer } from '../../redux/reducers/applications/applications.reducer'
import { RetailersSliceReducer } from '../../redux/reducers/retailers/retailers.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { StoresSliceReducer } from '../../redux/reducers/stores/stores.reducer'
import {
  ApplicationStatus,
  DenialReasons,
} from '../../services/bff-backoffice/application-engine/interfaces/application-engine.interfaces'
import { formatDateWithHour } from '../../utils'

interface Props {
  history: History
}

const ApplicationDetails = ({ history }: Props) => {
  const dispatch = useDispatch()
  const {
    application,
    applicationScores,
    isLoadingApplicationScores,
    retailer,
    isLoadingRetailer,
    store,
    isLoadingStore,
  } = useTypedSelector(state => ({
    application: state.applications.application,
    applicationScores: state.applications.applicationScores,
    isLoadingApplicationScores: state.applications.isLoadingApplicationScores,
    retailer: state.retailers.retailer,
    isLoadingRetailer: state.retailers.isFetchingRetailer,
    store: state.stores.store,
    isLoadingStore: state.stores.isFetchingStore,
  }))

  useEffect(() => {
    if (application) {
      dispatch(StoresSliceReducer.actions.fetchStore(application.storeId))
      dispatch(ApplicationsSliceReducer.actions.fetchApplicationScores(application.id))
      if (application.retailerId) {
        dispatch(RetailersSliceReducer.actions.fetchRetailer(application.retailerId))
      }
    }
  }, [application, dispatch])

  // Unlikely to occur
  if (!application) return <></>

  const handlePreviousButtonClick = () => {
    history.goBack()
  }

  const formatStatus = (status: ApplicationStatus) => {
    switch (status) {
      case ApplicationStatus.APPROVED:
        return <StatusTag disable backgroundColor={UMEColors.green.dark} label={'Aprovado'} />
      case ApplicationStatus.DENIED:
        return <StatusTag disable backgroundColor={UMEColors.red.persian} label={'Negado'} />
      case ApplicationStatus.WAITING_MANUAL_APPROVAL:
        return <StatusTag disable backgroundColor={UMEColors.yellow.dark} label={'Esperando avaliação'} />
      case ApplicationStatus.FAILURE:
        return <StatusTag disable backgroundColor={UMEColors.red.persian} label={'Falhou'} />
      default:
        return <StatusTag disable backgroundColor={UMEColors.yellow.citrine} label={'Analisando'} />
    }
  }

  const formatStore = (storeId: string) => {
    if (isLoadingStore) {
      return <LoadingIcon />
    }

    if (store) {
      return store.name
    }

    return storeId
  }

  const formatRetailer = (retailerId?: string) => {
    if (isLoadingRetailer) {
      return <LoadingIcon />
    }

    if (retailer) {
      return retailer.fantasyName
    }

    return retailerId ? retailerId : '-'
  }

  const formatBemolBehaviorScore = () => {
    if (isLoadingApplicationScores) {
      return <LoadingIcon />
    }

    console.log(applicationScores)

    return applicationScores && applicationScores.bemolBehaviorScore
      ? applicationScores.bemolBehaviorScore.toFixed(2)
      : '-'
  }

  const formatScudraScore = () => {
    if (isLoadingApplicationScores) {
      return <LoadingIcon />
    }

    return applicationScores && applicationScores.scudraScore ? applicationScores.scudraScore.toFixed(2) : '-'
  }

  const formatDenialReasons = (denialReasons: DenialReasons[]) => {
    const denialReasonsString = denialReasons.reduce(
      (prev, actual: DenialReasons) => `${prev} ${denialReasonsText[actual]}, `,
      ''
    )
    return denialReasonsString.substr(0, denialReasonsString.length - 2)
  }

  const applicationData: IDetailsCardData[] = [
    {
      label: 'ID',
      value: application.id,
    },
    {
      label: 'Data e Hora',
      value: formatDateWithHour(application.createdOn),
    },
    {
      label: 'Tipo',
      value: application.applicationType ? application.applicationType : '-',
    },
    {
      label: 'Origem',
      value: application.generatedAt === 'mobile-app' ? 'Aplicativo UME' : 'Application em Loja',
    },
    {
      label: 'Status',
      value: formatStatus(application.status),
    },
    {
      label: 'Motivos de Negação (AO)',
      value: application.denialReasons ? formatDenialReasons(application.denialReasons) : '-',
    },
    {
      label: 'Operador que Avaliou (AO)',
      value: application.evaluatedByOperatorId ? application.evaluatedByOperatorId : '-',
    },
    {
      label: 'Varejo',
      value: formatRetailer(application.retailerId),
    },
    {
      label: 'Loja',
      value: formatStore(application.storeId),
    },
  ]

  const scoresData: IDetailsCardData[] = [
    {
      label: 'Scudra Score',
      value: formatScudraScore(),
    },
    {
      label: 'Bemol Behavior Score',
      value: formatBemolBehaviorScore(),
    },
  ]

  return (
    <React.Fragment>
      <ApplicationsTitle
        title="Dados do Application"
        icon={
          <PreviousButton
            onClick={handlePreviousButtonClick}
            width="32px"
            height="32px"
            style={{ marginLeft: 0 }}
            icon={<FormPreviousLink color="white" />}
          />
        }
      />
      <Box direction="row-responsive">
        <Box width="100%">
          <DetailsCard data={applicationData} />
        </Box>
        <Box width="100%">
          <DetailsCard data={scoresData} />
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default ApplicationDetails

export const StatusTag = styled(Tag)`
  padding: 4px 6px;
  height: 22px;
  font-weight: 500;
  line-height: 14px;
`

const PreviousButton = styled(Button)`
  padding: 0;
`
