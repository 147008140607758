import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { toaster } from '../../App'
import { BatchCanbanColumn } from '../../molecules/FinancialOriginations/BatchCanbanColumn'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { IBatchResponse } from '../../services/bff-backoffice/liquidation-originations/interfaces/liquidation-originations.interfaces'
import { OriginationsBatchSearchPage } from './OriginationsBatchSearchPage'

interface IFinancialOriginationsPageProps {
  history: any
}

interface IFinancialOriginationsPageState {
  umeToBankerBatches?: IBatchResponse[]
  bankerToTransitoryBatches?: IBatchResponse[]
  umeToRetailersBatches?: IBatchResponse[]
  isLoadingBatches: boolean
  isBatchError: boolean
  onSearchPage: boolean
}

export default class FinancialOriginationsPage extends React.Component<
  IFinancialOriginationsPageProps,
  IFinancialOriginationsPageState
> {
  constructor(props: IFinancialOriginationsPageProps) {
    super(props)

    this.state = {
      umeToBankerBatches: undefined,
      bankerToTransitoryBatches: undefined,
      umeToRetailersBatches: undefined,
      isLoadingBatches: true,
      isBatchError: false,
      onSearchPage: false,
    }

    this.fetchBatchesFromAPI()
  }

  fetchBatchesFromAPI = () => {
    bffBackofficeApiService.liquidationOriginations
      .getBatches({ status: ['AT_UME', 'AT_BANKER', 'AT_TRANSITORY_ACCOUNT'] })
      .then(response => {
        const pendingBatches = response.data
        toaster.showSuccessToast(`Remessas carregadas com sucesso.`)

        let umeToBankerBatches = []
        let bankerToTransitoryBatches = []
        let umeToRetailersBatches = []
        for (const batch of pendingBatches) {
          if (batch.status === 'AT_UME') {
            umeToBankerBatches.push(batch)
          } else if (batch.status === 'AT_BANKER') {
            bankerToTransitoryBatches.push(batch)
          } else if (batch.status === 'AT_TRANSITORY_ACCOUNT') {
            umeToRetailersBatches.push(batch)
          }
        }

        this.setState({
          isLoadingBatches: false,
          isBatchError: false,
          umeToRetailersBatches,
          umeToBankerBatches,
          bankerToTransitoryBatches,
        })
      })
      .catch(error => {
        console.error(error)
        this.setState({
          isLoadingBatches: false,
          umeToBankerBatches: undefined,
          bankerToTransitoryBatches: undefined,
          umeToRetailersBatches: undefined,
          isBatchError: true,
        })
        toaster.showErrorToast(`Erro ao buscar as remessas.`)
      })
  }

  onSearchButtonClicked = () => {
    this.setState({ onSearchPage: true })
  }

  render() {
    const {
      isBatchError,
      isLoadingBatches,
      bankerToTransitoryBatches,
      umeToBankerBatches,
      umeToRetailersBatches,
      onSearchPage,
    } = this.state

    let formattedTodayDate = moment().format('dddd, D [de] MMMM [de] YYYY')
    formattedTodayDate = formattedTodayDate.charAt(0).toUpperCase() + formattedTodayDate.slice(1)

    if (onSearchPage) {
      return <OriginationsBatchSearchPage onClose={() => this.setState({ onSearchPage: false })} />
    }

    return (
      <>
        <Header>
          <DateContainer>
            <TodayDate> {formattedTodayDate} </TodayDate>
          </DateContainer>

          <SearchContainer>
            <span> Buscar remessas: </span>
            <SearchButton onClick={this.onSearchButtonClicked}> Buscar </SearchButton>
          </SearchContainer>
        </Header>
        <ScreenSubTitle> Liquidação </ScreenSubTitle>

        <StatusContainer>
          <BatchCanbanColumn
            from="UME"
            to="BMP"
            stepDescription="Aguardando transferência para o Bancarizador"
            stepOrder="Primeira etapa"
            style={{
              borderRight: '2px solid rgba(198,198,198,.5)',
              flex: 1,
              minWidth: 320,
            }}
            isLoading={isLoadingBatches}
            batches={umeToBankerBatches}
            isError={isBatchError}
          />

          <BatchCanbanColumn
            from="BMP"
            to="Conta inter."
            stepDescription="Aguardando recebimento na Conta Intermediária dos Varejistas"
            stepOrder="Segunda etapa"
            style={{
              borderRight: '2px solid rgba(198,198,198,.5)',
              flex: 1,
              minWidth: 320,
            }}
            isLoading={isLoadingBatches}
            batches={bankerToTransitoryBatches}
            isError={isBatchError}
          />

          <BatchCanbanColumn
            from="UME"
            to="Varejista"
            stepDescription="Aguardando transferência para o Varejista Final"
            stepOrder="Terceira etapa"
            style={{ flex: 1, minWidth: 320 }}
            isLoading={isLoadingBatches}
            batches={umeToRetailersBatches}
            isError={isBatchError}
          />
        </StatusContainer>
      </>
    )
  }
}

const Header = styled.div`
  margin-top: 6vh;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const DateContainer = styled.div`
  text-align: left;
`

const TodayDate = styled.span`
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 0;
  // line-height: 40px;
`

const ScreenSubTitle = styled.p`
  text-align: left;

  color: ${UMEColors.gray.primary};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 19px;
`

const SearchContainer = styled.div`
  & > span {
    color: ${UMEColors.black.primary};
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    display: inline-block;
  }

  align-items: center;
  justify-content: center;
`

const SearchButton = styled.button`
  display: inline-block;

  margin-left: 16px;

  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;

  height: 32px;
  width: 64px;
  border-radius: 2px;
  background-color: ${UMEColors.black.primary};
  outline: none;
  border: none;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);

  transition: 0.3s;

  &:hover {
    cursor: pointer;
    transition: 0.3s;
    opacity: 0.5;
  }
`

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  min-height: 80vh;

  margin-top: 5vh;
`
