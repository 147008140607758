import { Clipboard, DataTable, Loading, Text } from '@bit/ume.design-kit.ui'
import { gray, green, red, yellow } from '@bit/ume.design-kit.ui/Palette'
import { Box } from 'grommet'
import * as React from 'react'
import { toaster } from '../../App'
import { IInvoice } from '../../services/bff-backoffice/coordinator/interfaces/invoice.interfaces'
import { formatDate, formatDateTreatingError, formatNumberToBRL } from '../../utils'
import { LinkAnchorStyle, StatusTagStyle, TableBoxStyle } from './style'
import { mapInvoicesHistoryTableRows } from './utils/helpers'

const columnsDropDown = [
  {
    label: 'ID do boleto',
    attribute: 'id',
  },
  { attribute: 'createdOn', label: 'Gerado em', formatCell: (i: InvoicesHistoryRow) => formatDate(i.createdOn) },
  {
    attribute: 'expectedValue',
    label: 'Valor do boleto',
    formatCell: (i: InvoicesHistoryRow) => formatNumberToBRL(i.value),
  },
  { attribute: 'dueDate', label: 'Vencimento', formatCell: (i: InvoicesHistoryRow) => formatDate(i.dueDate) },
  {
    attribute: 'barcode',
    label: 'Link do boleto',
    formatCell: (i: InvoicesHistoryRow) => (
      <Box direction="row" align="center" gap="medium">
        <LinkAnchorStyle target="_blank" href={i.url + '.pdf'}>
          {i?.barcode?.slice(0, 5) ? i?.barcode?.slice(0, 5) + '...' : '---------'}
        </LinkAnchorStyle>
        <div
          title="Copiar link do boleto"
          onClick={() => toaster.showSuccessToast('Copiado para área de transferência')}
        >
          <Clipboard value={i.url + '.pdf'} />
        </div>
      </Box>
    ),
  },
  {
    attribute: 'operator',
    label: 'Operador',
  },
  {
    attribute: 'modifiedOn',
    label: 'Última atualização',
    formatCell: (i: InvoicesHistoryRow) => formatDate(i.modifiedOn),
  },
  {
    attribute: 'paidValue',
    label: 'Valor pago',
    formatCell: (i: InvoicesHistoryRow) => formatNumberToBRL(i.paidValue),
  },
  {
    label: 'Status',
    attribute: 'status',
    formatCell: (i: InvoicesHistoryRow) => {
      switch (i.status) {
        case 'pending':
          return <StatusTagStyle disable backgroundColor={gray.primary} label={'A pagar'} />
        case 'paid':
          return <StatusTagStyle disable backgroundColor={green.laurel} label={'Pago'} />
        case 'overdue':
          return <StatusTagStyle disable backgroundColor={red.primary} label={'Atrasado'} />
        case 'expired':
          return <StatusTagStyle disable backgroundColor={red.primary} label={'Vencido'} />
        case 'canceled':
          return <StatusTagStyle disable backgroundColor={yellow.citrine} label={'Cancelado'} />
        default:
          return 'no status'
      }
    },
  },

  {
    attribute: 'paymentDate',
    label: 'Data do pagamento',
    formatCell: (i: InvoicesHistoryRow) => (i.paymentDate ? formatDateTreatingError(i.paymentDate) : '--'),
  },
  {
    attribute: 'liquidationDate',
    label: 'Data de compensação',
    formatCell: (i: InvoicesHistoryRow) => (i.liquidationDate ? formatDateTreatingError(i.liquidationDate) : '--'),
  },
  {
    label: 'ID Iugu',
    formatCell: (i: InvoicesHistoryRow) => i.partnerInvoiceId ?? '-',
  },
]

export interface InvoicesHistoryRow {
  id: string
  createdOn: string
  value: number
  dueDate: string
  barcode: string
  url: string
  operator: string
  modifiedOn: string
  paidValue: number
  status: string
  paymentDate: string
  liquidationDate: string
  partnerInvoiceId: string
}

export interface IInvoicesHistoryProps {
  invoices?: IInvoice[] | Error
}

export default class InvoicesHistory extends React.PureComponent<IInvoicesHistoryProps> {
  public render() {
    const { invoices } = this.props

    const errorCard = (
      <Text color="red" light>
        Não foi possível carregar os dados de boletos para esta parcela.
      </Text>
    )

    const InvoicesHistoryTable = (invoices: IInvoice[]) => {
      const invoicesTableRows = mapInvoicesHistoryTableRows(invoices)

      return <DataTable header={columnsDropDown} data={invoicesTableRows} fontSize="14px" />
    }

    return (
      <TableBoxStyle>
        {invoices === undefined ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : invoices instanceof Error ? (
          errorCard
        ) : (
          InvoicesHistoryTable(invoices || [])
        )}
      </TableBoxStyle>
    )
  }
}
