import {TextInput} from '@bit/ume.design-kit.ui'
import {Box} from 'grommet'
import React from 'react'
import {connect} from 'react-redux'
import {Route,RouteComponentProps,Router,Switch} from 'react-router'
import {toaster} from '../../App'
import {LoadingIcon} from '../../atoms/Loading-icon/LoadingIcon'
import {ReactComponent as Aliens} from '../../common/assets/images/aliens.svg'
import {FinancingOptionsTypeResolver} from '../../common/financing-options-resolver'
import {emailIsValid,validate_celular,validate_cnpj} from '../../common/Validators'
import DropDownInput from '../../molecules/RetailersPage/DropDownInput'
import MaskedInput from '../../molecules/RetailersPage/MaskedInput'
import {RetailerPicker} from '../../molecules/RetailersPage/RetailerPicker'
import {RetailersTabsContainer} from '../../molecules/RetailersPage/RetailersTabsContainer'
import FinancialTab from '../../organisms/RetailersPage/FinancialTab'
import FormModal from '../../organisms/RetailersPage/FormModal'
import OperatorsTab from '../../organisms/RetailersPage/OperatorsTab'
import RetailerTab from '../../organisms/RetailersPage/RetailerTab'
import StoresTab from '../../organisms/RetailersPage/StoresTab'
import {RetailerReducerErrors} from '../../redux/reducers/retailers/retailers.errors'
import {RetailersSliceReducer} from '../../redux/reducers/retailers/retailers.reducer'
import {StoresReducerErrors} from '../../redux/reducers/stores/stores.errors'
import {StoresSliceReducer} from '../../redux/reducers/stores/stores.reducer'
import { 
  DebtFundingPackResponse, 
  FinancingOptionsType,
  IRetailerCategoryReponse,
  IRetailerResponse,
  PostRetailerRequest
} from '../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'
import {LinkAnchor} from '../CS-Profile/SimulationCard/style'
import {NotFoundContainer,NotFoundMessage,RetailerIcon,RetailerPageContainer,StyledButton} from './style'

const RETAILER_TAB_INDEX = 0
const STORE_TAB_INDEX = 1
const OPERATOR_TAB_INDEX = 2
const FINANCIAL_TAB_INDEX = 3

interface IRetailersPageProps {
  history: any
  match?: any
  location?: any
  fetchRetailers: () => void
  fetchRetailer: (retailerid: string) => void
  fetchStore: (storeId: string) => void
  resetRetailers: () => {}
  fetchCategories: () => {}
  fetchBlocks: () => {}
  createRetailer: (body: PostRetailerRequest) => {}
  createRetailerModalOpen: () => {}
  createRetailerModalClose: () => {}
  isCreateRetailerModalOpen: boolean
  isCreateRetailerLoading: boolean
  retailersNames: string[]
  retailers: IRetailerResponse[]
  retailerErrorType: any
  storeErrorType: any
  categories: IRetailerCategoryReponse[]
  blocks: DebtFundingPackResponse[]
}

interface IRetailersPageState {
  selectedTabIndex: number
  selectedRetailerId?: string
  selectedStoreId?: string
  selectedRetailersIndex?: number
  name: string
  fantasyName: string
  cnpj: string
  businessName: string
  phoneNumber: string
  email: string
  selectedCategories: string[]
  selectedBlock?: DebtFundingPackResponse
  nameErrorIntent: boolean
  fantasyNameErrorIntent: boolean
  cnpjErrorIntent: boolean
  phoneNumberErrorIntent: boolean
  emailErrorIntent: boolean
  businessNameErrorIntent: boolean
  selectedFinancingOptionsType?: FinancingOptionsType
}

const initialState: IRetailersPageState = {
  selectedTabIndex: RETAILER_TAB_INDEX,
  cnpj: '',
  email: '',
  fantasyName: '',
  name: '',
  phoneNumber: '',
  businessName: '',
  selectedCategories: [],
  nameErrorIntent: false,
  fantasyNameErrorIntent: false,
  cnpjErrorIntent: false,
  phoneNumberErrorIntent: false,
  emailErrorIntent: false,
  businessNameErrorIntent: false,
}

const CnpjMaskInput = [
  {
    length: 2,
    regexp: /\d/,
    placeholder: '00',
  },
  { fixed: '.' },
  {
    length: 3,
    regexp: /\d/,
    placeholder: '000',
  },
  { fixed: '.' },
  {
    length: 3,
    regexp: /\d/,
    placeholder: '000',
  },
  { fixed: '/' },
  {
    length: 4,
    regexp: /\d/,
    placeholder: '0000',
  },
  { fixed: '-' },
  {
    length: 2,
    regexp: /\d/,
    placeholder: '00',
  },
]

const CellphoneMaskInput = [
  { fixed: '(' },
  {
    length: 1,
    regexp: /[1-9]/,
    placeholder: '0',
  },
  {
    length: 1,
    regexp: /\d/,
    placeholder: '0',
  },
  { fixed: ') ' },
  {
    length: 1,
    regexp: /[5-9]/,
    placeholder: '0',
  },
  {
    length: 4,
    regexp: /\d/,
    placeholder: '0000',
  },
  { fixed: '-' },
  {
    length: 4,
    regexp: /\d/,
    placeholder: '0000',
  },
]

class RetailersPage extends React.Component<IRetailersPageProps, IRetailersPageState> {
  constructor(props: IRetailersPageProps) {
    super(props)
    this.state = initialState
  }

  // On mounting the page, check which tab is selected by looking at the route name
  componentDidMount = () => {
    // If an error has occurred and the page is reloaded, reset the reducer
    if (this.props.retailerErrorType) {
      this.props.resetRetailers()
    }

    // If there is no retailer list, fetches it
    if (!this.props.retailers) {
      this.props.fetchRetailers()
      this.props.fetchCategories()
      this.props.fetchBlocks()
    }

    const pathTokens = this.props.location.pathname.split('/')
    const subContext = pathTokens[3]
    let { retailerId, storeId, operatorId } = this.props.match.params

    if (!retailerId) {
      retailerId = pathTokens[2]
    }

    if (!storeId) {
      storeId = pathTokens[4]
    }

    if (!operatorId) {
      operatorId = pathTokens[6]
    }

    // If a valid retailer Id is given, check the subContexts to set the right tab
    if (retailerId && Number(retailerId)) {
      this.setState({ selectedRetailerId: retailerId, selectedStoreId: storeId })
      this.props.fetchRetailer(retailerId)

      if (subContext === `operators`) {
        this.setState({ selectedTabIndex: OPERATOR_TAB_INDEX })
        return
      }

      if (subContext === 'stores') {
        this.setState({ selectedTabIndex: STORE_TAB_INDEX })
        return
      }

      if (subContext === 'financial') {
        this.setState({ selectedTabIndex: FINANCIAL_TAB_INDEX })
        return
      }

      this.setState({ selectedTabIndex: RETAILER_TAB_INDEX })
      return
    }

    this.setState({ selectedTabIndex: RETAILER_TAB_INDEX })
  }

  componentDidUpdate = (prevProps: IRetailersPageProps) => {
    // Sets the dropdown index when a retailerId is given and the retailers are loaded
    if (prevProps.retailers !== this.props.retailers && this.props.retailers) {
      this.setState({
        selectedRetailersIndex: this.props.retailers.findIndex(
          retailer => retailer.id === this.state.selectedRetailerId
        ),
      })
      return
    }

    // Reset the selectedRetailerId if an error occurs
    if (prevProps.retailerErrorType !== this.props.retailerErrorType && prevProps.retailerErrorType) {
      this.setState({ selectedRetailerId: undefined })
    }
  }

  onChangeDropDownInput = (e: any) => {
    const state: any = this.state
    state[e.target.name] = e.value
    this.setState(state)
  }

  // When a tab is clicked, the route name should change, as well as the selected tab
  onTabClicked = (tabIndex: number) => {
    const uri = `/retailers/${this.state.selectedRetailerId}`

    if (tabIndex === RETAILER_TAB_INDEX) {
      this.props.history.push(`${uri}`)
    } else if (tabIndex === STORE_TAB_INDEX) {
      this.props.history.push(`${uri}/stores`)
    } else if (tabIndex === FINANCIAL_TAB_INDEX) {
      this.props.history.push(`${uri}/financial`)
    } else {
      this.props.history.push(`${uri}/operators`)
    }

    this.setState({ selectedTabIndex: tabIndex })
  }

  onRetailerSelect = (index?: number) => {
    // The default value was selected
    if (index === undefined) {
      this.setState({
        selectedRetailersIndex: undefined,
        selectedRetailerId: undefined,
      })

      this.props.history.push('/retailers')
      return
    }

    const selectedRetailerId = this.props.retailers[index].id
    this.setState({
      selectedRetailersIndex: index,
      selectedRetailerId,
    })
    this.props.fetchRetailer(selectedRetailerId)

    let pathTokens = this.props.location.pathname.split('/')
    pathTokens[2] = selectedRetailerId
    this.props.history.push(pathTokens.join('/'))
  }

  onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const state: any = this.state
    state[e.target.name] = e.target.value
    this.setState(state)
  }

  // Validates the create retailer fields and, if valid, submit to API Service
  onSaveRetailer = () => {
    const {
      cnpj,
      email,
      fantasyName,
      name,
      phoneNumber,
      businessName,
      selectedCategories,
      selectedBlock,
      selectedFinancingOptionsType,
    } = this.state

    let error = false
    let nameErrorIntent = false
    let cnpjErrorIntent = false
    let fantasyNameErrorIntent = false
    let phoneNumberErrorIntent = false
    let emailErrorIntent = false
    let businessNameErrorIntent = false

    if (name.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Apelido deve conter pelo menos um caracter.')
      nameErrorIntent = true
      error = true
    } else {
      nameErrorIntent = false
    }

    if (!validate_cnpj(cnpj)) {
      toaster.showErrorToast('CNPJ inválido.')
      cnpjErrorIntent = true
      error = true
    } else {
      cnpjErrorIntent = false
    }

    if (fantasyName.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Nome Fantasia deve conter pelo menos um caracter.')
      fantasyNameErrorIntent = true
      error = true
    } else {
      fantasyNameErrorIntent = false
    }

    if (businessName.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Razão Social deve conter pelo menos um caracter.')
      businessNameErrorIntent = true
      error = true
    } else {
      businessNameErrorIntent = false
    }

    if (!validate_celular(phoneNumber)) {
      toaster.showErrorToast('Telefone inválido.')
      phoneNumberErrorIntent = true
      error = true
    } else {
      phoneNumberErrorIntent = false
    }

    if (!emailIsValid(email)) {
      toaster.showErrorToast('E-mail inválido.')
      emailErrorIntent = true
      error = true
    } else {
      emailErrorIntent = false
    }

    this.setState({
      nameErrorIntent,
      businessNameErrorIntent,
      cnpjErrorIntent,
      emailErrorIntent,
      fantasyNameErrorIntent,
      phoneNumberErrorIntent,
    })

    if (error) {
      return
    }

    // TODO - Create a new retailer and update redux retailers
    const body: PostRetailerRequest = {
      cnpj: cnpj
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', ''),
      businessName,
      email,
      fantasyName,
      categoriesId: selectedCategories,
      name,
      phoneNumber: phoneNumber
        .replace(' ', '')
        .replace('(', '')
        .replace(')', '')
        .replace('-', ''),
      debtFundingPackName: selectedBlock?.name,
      financingOptionsType: selectedFinancingOptionsType?.financingOptionsType,
    }

    this.props.createRetailer(body)
  }

  // Returns the form fields of the Create Retailer Modal
  createRetailerFields = () => {
    return [
      <TextInput
        required
        label="Nome"
        placeholder="Letras minúsculas sem espaços"
        key={1}
        name="name"
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.name}
        errorIntent={this.state.nameErrorIntent}
      />,
      <MaskedInput
        required
        label="CNPJ"
        mask={CnpjMaskInput}
        placeholder="00.000.000/0000-00"
        key={2}
        width="100%"
        name="cnpj"
        onChange={this.onChangeInput}
        value={this.state.cnpj}
        errorIntent={this.state.cnpjErrorIntent}
      />,
      <TextInput
        required
        label="Apelido"
        key={3}
        name="fantasyName"
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.fantasyName}
        errorIntent={this.state.fantasyNameErrorIntent}
      />,
      <TextInput
        required
        label="Razão Social"
        key={4}
        name="businessName"
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.businessName}
        errorIntent={this.state.businessNameErrorIntent}
      />,
      <MaskedInput
        required
        label="Telefone"
        key={5}
        width="100%"
        name="phoneNumber"
        mask={CellphoneMaskInput}
        placeholder="(92) 99999-9999"
        onChange={this.onChangeInput}
        value={this.state.phoneNumber}
        errorIntent={this.state.phoneNumberErrorIntent}
      />,
      <TextInput
        required
        label="E-mail"
        placeholder="email@email.com"
        key={6}
        name="email"
        width="100%"
        onChange={this.onChangeInput}
        value={this.state.email}
        errorIntent={this.state.emailErrorIntent}
      />,
      <DropDownInput
        required
        name="selectedCategories"
        label="Categoria"
        multiple={true}
        width="100%"
        placeholder="Selecione a categoria deste varejo"
        style={{ border: 'none' }}
        key={7}
        options={this.props.categories}
        valueKey={'id'}
        labelKey={'name'}
        onChange={this.onChangeDropDownInput}
        value={this.state.selectedCategories}
      />,
      <DropDownInput
        required
        name="selectedBlock"
        label="Bloco"
        width="100%"
        placeholder="Selecione o bloco deste varejo"
        style={{ border: 'none' }}
        key={8}
        options={this.props.blocks}
        valueKey={'id'}
        labelKey={'name'}
        onChange={this.onChangeDropDownInput}
      />,
      <DropDownInput
        name="selectedFinancingOptionsType"
        label="Opções de financiamento"
        width="100%"
        placeholder="Selecione as opções de financiamento do varejo"
        style={{ border: 'none' }}
        key={9}
        options={FinancingOptionsTypeResolver.getFinancingOptionsType()}
        valueKey={'name'}
        labelKey={'name'}
        onChange={this.onChangeDropDownInput}
      />,
    ]
  }

  render() {
    const { selectedTabIndex, selectedRetailerId, selectedStoreId } = this.state
    const {
      retailersNames,
      retailers,
      retailerErrorType,
      storeErrorType,
      isCreateRetailerModalOpen,
      isCreateRetailerLoading,
    } = this.props

    // Show message if the retailer was not found
    if (retailerErrorType === RetailerReducerErrors.RETAILER_NOT_FOUND) {
      return (
        <>
          <NotFoundMessage>
            <strong>RETAILER DE ID {selectedRetailerId} NÃO EXISTE.</strong>
          </NotFoundMessage>

          <NotFoundContainer>
            <Aliens />
            <h3>
              Ou talvez Aliens tenham invadido a Terra e destruído os servidores. Em ambos os casos, clique{' '}
              <LinkAnchor href="/retailers">aqui</LinkAnchor>.
            </h3>
          </NotFoundContainer>
        </>
      )
    }

    // Show message if the store was not found
    if (storeErrorType === StoresReducerErrors.STORE_NOT_FOUND) {
      return (
        <>
          <NotFoundMessage>
            <strong>STORE DE ID {selectedStoreId} NÃO EXISTE.</strong>
          </NotFoundMessage>

          <NotFoundContainer>
            <Aliens />
            <h3>
              Ou talvez Aliens tenham invadido a Terra e destruído os servidores. Em ambos os casos, clique{' '}
              <LinkAnchor href="/retailers">aqui</LinkAnchor>.
            </h3>
          </NotFoundContainer>
        </>
      )
    }

    if (!retailers) {
      return (
        <RetailerPageContainer>
          <Box width="100%" height="90vh">
            <LoadingIcon />
          </Box>
        </RetailerPageContainer>
      )
    }

    return (
      <RetailerPageContainer>
        <FormModal
          title="Criar Varejo"
          isModalOpen={isCreateRetailerModalOpen}
          fields={this.createRetailerFields()}
          onClose={this.props.createRetailerModalClose}
          onCreate={this.onSaveRetailer}
          isLoadingButton={isCreateRetailerLoading}
          wrapFieldsWithScroll
        />

        <Box direction="row" align="center" justify="between">
          {/* Retailer Picker  */}
          <RetailerPicker
            Icon={RetailerIcon}
            default="Selecione"
            label={'Escolha o varejo'}
            select={retailersNames}
            onDropDownSelect={this.onRetailerSelect}
            selectedIndex={this.state.selectedRetailersIndex}
          />

          {/* Register a nre retailer  */}
          <StyledButton onClick={this.props.createRetailerModalOpen}>Cadastrar Varejista</StyledButton>
        </Box>

        {selectedRetailerId ? (
          <RetailersTabsContainer selectedTabIndex={selectedTabIndex} onTabClicked={this.onTabClicked} />
        ) : (
          <></>
        )}

        <Router history={this.props.history}>
          <Switch>
            <Route
              path={`${this.props.match.path}/:retailerId/stores`}
              component={(props: RouteComponentProps) => <StoresTab {...props} />}
            />

            <Route
              path={`${this.props.match.path}/:retailerId/operators`}
              component={(props: RouteComponentProps) => <OperatorsTab {...props} />}
            />

            <Route
              path={`${this.props.match.path}/:retailerId/financial`}
              component={(
                props: RouteComponentProps<{
                  retailerId: string
                }>
              ) => <FinancialTab {...props} />}
            />

            <Route
              path={`${this.props.match.path}/:retailerId`}
              component={(props: RouteComponentProps) => <RetailerTab {...props} />}
            />
          </Switch>
        </Router>
      </RetailerPageContainer>
    )
  }
}

const mapStateToProps = (state: any) => ({
  retailersNames: state.retailers.retailersNames,
  retailers: state.retailers.retailers,
  retailerErrorType: state.retailers.errorType,
  storeErrorType: state.stores.errorType,
  categories: state.retailers.categories,
  blocks: state.retailers.blocks,
  isCreateRetailerModalOpen: state.retailers.isCreateRetailerModalOpen,
  isCreateRetailerLoading: state.retailers.isCreateRetailerLoading,
})

const mapDispatchToProps = (dispatch: any) => ({
  fetchRetailers: () => dispatch(RetailersSliceReducer.actions.fetchRetailers()),
  fetchRetailer: (retailerId: string) => dispatch(RetailersSliceReducer.actions.fetchRetailer(retailerId)),
  resetRetailers: () => dispatch(RetailersSliceReducer.actions.resetState()),
  fetchCategories: () => dispatch(RetailersSliceReducer.actions.fetchCategories()),
  fetchBlocks: () => dispatch(RetailersSliceReducer.actions.fetchBlocks()),
  createRetailer: (body: PostRetailerRequest) => dispatch(RetailersSliceReducer.actions.createRetailer(body)),
  createRetailerModalOpen: () => dispatch(RetailersSliceReducer.actions.createRetailerModalOpen()),
  createRetailerModalClose: () => dispatch(RetailersSliceReducer.actions.createRetailerModalClose()),
  resetStores: () => dispatch(StoresSliceReducer.actions.resetState()),
  fetchStore: (storeId: string) => dispatch(StoresSliceReducer.actions.fetchStore(storeId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RetailersPage)
