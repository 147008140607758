import { createBrowserHistory } from 'history'
import React from 'react'
import ReactGA from 'react-ga'
import { pdfjs } from 'react-pdf'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { Route, RouteProps, Router, Switch } from 'react-router-dom'
import 'typeface-roboto'
import './App.css'
import { AppToaster } from './common/AppToaster'
import { ALL_ROLES, PrivateRoute, Role } from './common/PrivateRoute'
import AnalysisPage from './pages/Analysis'
import ApplicationsBatchesPage from './pages/Applications/ApplicationsBatchesPage'
import ApplicationsPage from './pages/Applications/ApplicationsPage'
import BatchApplicationsPage from './pages/Applications/BatchApplicationsPage'
import BorrowersPage from './pages/Borrowers/BorrowersPage'
import ContractViewPage from './pages/Contracts/contracts-renegotiation/ContractViewPage'
import BorrowerProfilePage from './pages/CS-Profile/BorrowerProfilePage'
import FinancialPage from './pages/Financial/FinancialPage'
import { FinancingOptionsModificationsPage } from './pages/FinancingOptionsModificationsPage/FinancingOptionsModificationsPage'
import { ForcedApprovalPage } from './pages/ForcedApprovalPage/ForcedApprovalPage'
import Home from './pages/Home/Home'
import InstallmentDetailsPage from './pages/InstallmentDetails/InstallmentDetailsPage'
import { LimitsModificationsPage } from './pages/LimitsModificationsPage/LimitsModificationsPage'
import LoginBorrower from './pages/Login/Login'
import NotFound from './pages/NotFound/NotFound'
import OriginationsBatchPage from './pages/OriginationsBatchPage/OriginationsBatchPage'
import { PasswordFirstAccess, PasswordForgot, PasswordReset } from './pages/Password'
import { Prioritization } from './pages/Prioritization'
import Receipt from './pages/Receipts/Receipt'
import RenegotiationDetailsPage from './pages/RenegotiationDetails/RenegotiationDetailsPage'
import RenegotiationPaymentDetails from './pages/RenegotiationDetails/RenegotiationPaymentDetails'
import Reports from './pages/Reports'
import RetailersPage from './pages/Retailers/RetailersPage'
import RetailerBenchApplicationsPage from './pages/RetailersBench/RetailerBenchApplicationsPage'
import { RetailersBenchPage } from './pages/RetailersBench/RetailersBenchPage'
import RiskHome from './pages/RiskHome/RiskHome'
import Store from './redux/store'
import { bffBackofficeApiService, startApiServices } from './services/bff-backoffice'
import { ILoginProfileResponse } from './services/bff-backoffice/auth/dto/Auth.dto'
import { UMEHeader } from './UMEHeader/UMEHeader'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

require('dotenv').config()

startApiServices()

export const toaster = new AppToaster()

const history = createBrowserHistory()
history.listen((location: { pathname: any; search: any }) => {
  ReactGA.pageview(location.pathname + location.search)
})

interface IAppState {
  currentUser?: ILoginProfileResponse
}

class App extends React.Component<{}, IAppState> {
  state = {
    currentUser: undefined,
  }

  async componentDidMount() {
    // TODO - This setState makes the component load twice
    // this.setState({ currentUser: apiService.getUser() })
  }

  public handleUserLogged = (currentUser?: ILoginProfileResponse) => {
    // TODO - This setState makes the component load twice ONLY on login
    // -- By commenting this line the user only appear in the top right corner after the first refresh after login
    // this.setState({ currentUser })
  }

  render() {
    const user = bffBackofficeApiService.auth.getUser()
    const queryClient = new QueryClient()

    return (
      <Provider store={Store}>
        <QueryClientProvider client={queryClient}>
          <Router history={history}>
            <div className="App">
              <UMEHeader user={user} history={history} handleUserLogged={this.handleUserLogged} />

              <Switch>
                <PrivateRoute
                  authorizedRoles={[
                    Role.REPRESENTATIVE,
                    Role.CASHIER,
                    Role.ANALYST,
                    Role.MANAGER,
                    Role.BORROWER,
                    Role.RISK_ANALYST,
                    Role.EXTERNAL_COLLECTOR,
                  ]}
                  path="/"
                  exact={true}
                  component={(props: RouteProps) => <Home {...props} handleUserLogged={this.handleUserLogged}></Home>}
                />

                <Route
                  path="/login"
                  component={(props: RouteProps) => (
                    <LoginBorrower {...props} handleUserLogged={this.handleUserLogged}></LoginBorrower>
                  )}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.EXTERNAL_COLLECTOR]}
                  path="/borrowers/:borrowerId?"
                  component={(props: RouteProps) => <BorrowersPage history={history} {...props} />}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.EXTERNAL_COLLECTOR]}
                  path="/installment/:installmentId"
                  component={(props: RouteProps) => <InstallmentDetailsPage history={history} {...props} />}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.EXTERNAL_COLLECTOR]}
                  path="/payments/:renegotiationPaymentId"
                  component={(props: RouteProps) => <RenegotiationPaymentDetails history={history} {...props} />}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.EXTERNAL_COLLECTOR]}
                  path="/renegotiations/:renegotiationId"
                  component={(props: RouteProps) => <RenegotiationDetailsPage history={history} {...props} />}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST]}
                  path="/financial/originations/batch/:batchId"
                  exact={false}
                  component={(props: RouteProps) => <OriginationsBatchPage history={history} {...props} />}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST]}
                  path="/financial"
                  exact={false}
                  component={(props: RouteProps) => <FinancialPage history={history} {...props} />}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST]}
                  path="/prioritization"
                  component={(props: RouteProps) => (
                    <Prioritization history={history} location={props.location} {...props} />
                  )}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.EXTERNAL_COLLECTOR]}
                  path="/profiles/:borrowerId"
                  component={(props: RouteProps) => <BorrowerProfilePage history={history} {...props} />}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.EXTERNAL_COLLECTOR]}
                  path="/contracts/:id"
                  exact={true}
                  component={(props: RouteProps) => <ContractViewPage history={history} {...props} />}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST]}
                  path="/analysis"
                  component={(props: RouteProps) => <AnalysisPage {...props} />}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST]}
                  path="/reports"
                  component={(props: RouteProps) => <Reports />}
                />

                <PrivateRoute
                  authorizedRoles={[Role.BORROWER]}
                  path="/altera-senha-primeiro-acesso"
                  component={(props: RouteProps) => <PasswordFirstAccess {...props}></PasswordFirstAccess>}
                />

                <Route
                  authorizedRoles={[Role.BORROWER]}
                  path="/alterar-senha"
                  component={(props: RouteProps) => <PasswordReset {...props}></PasswordReset>}
                />

                <Route
                  authorizedRoles={[Role.BORROWER]}
                  path="/esqueci-minha-senha"
                  component={(props: RouteProps) => <PasswordForgot {...props}></PasswordForgot>}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.RISK_ANALYST]}
                  path="/risk/limits"
                  component={(props: RouteProps) => (
                    <LimitsModificationsPage history={history} location={props.location} {...props} />
                  )}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.RISK_ANALYST]}
                  path="/risk/financing-options"
                  component={(props: RouteProps) => (
                    <FinancingOptionsModificationsPage history={history} location={props.location} {...props} />
                  )}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.RISK_ANALYST]}
                  path="/risk/forced-approval"
                  component={(props: RouteProps) => (
                    <ForcedApprovalPage history={history} location={props.location} {...props} />
                  )}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.RISK_ANALYST]}
                  path="/risk"
                  component={(props: RouteProps) => <RiskHome history={history} location={props.location} {...props} />}
                />

                {/* <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.RISK_ANALYST]}
                  path="/retailers/:retailerId"
                  component={(props: RouteProps) => (
                    <RetailersPage history={history} location={props.location} {...props} />
                  )}
                /> */}

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.RISK_ANALYST]}
                  path="/retailers"
                  component={(props: RouteProps) => (
                    <RetailersPage history={history} location={props.location} {...props} />
                  )}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.RISK_ANALYST]}
                  path="/applications/batches/:batchNumber"
                  component={BatchApplicationsPage}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.RISK_ANALYST]}
                  path="/applications/batches"
                  component={ApplicationsBatchesPage}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.RISK_ANALYST]}
                  path="/applications/:applicationId"
                  component={ApplicationsPage}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.RISK_ANALYST]}
                  path="/retailers-bench/:retailerId/applications/:applicationId"
                  component={ApplicationsPage}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.RISK_ANALYST]}
                  path="/retailers-bench/:retailerId/applications"
                  component={RetailerBenchApplicationsPage}
                />

                <PrivateRoute
                  authorizedRoles={[Role.ANALYST, Role.RISK_ANALYST]}
                  path="/retailers-bench"
                  component={RetailersBenchPage}
                />

                <PrivateRoute
                  authorizedRoles={ALL_ROLES}
                  path="/render/:ids/:type"
                  component={(props: any) => <Receipt {...props} />}
                />

                <Route path="*" component={(props: RouteProps) => <NotFound {...props}></NotFound>} />
              </Switch>
            </div>
          </Router>
        </QueryClientProvider>
      </Provider>
    )
  }
}

export default App
