import { Breadcrumbs, Heading, LoadingScreen } from '@bit/ume.design-kit.ui'
import { gray } from '@bit/ume.design-kit.ui/utils/_colors'
import { Box } from 'grommet'
import { FormPreviousLink } from 'grommet-icons'
import * as React from 'react'
import styled from 'styled-components'
import { InvoicesHistory } from '../../molecules/InstallmentDetails'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { RenegotiationPaymentResponse } from '../../services/bff-backoffice/coordinator/interfaces/renegotiation.interfaces'
import RenegotiationPaymentDetailsContent from './RenegotiationPaymentDetailsContent'
import RenegotiationPaymentPaidValues from './RenegotiationPaymentsPaidValues'
import RenegotiationPaymentStatus from './RenegotiationPaymentStatus'
import RenegotiationPaymentValues from './RenegotiationPaymentValues'
import { PreviousButtonStyle, SectionTitleStyle } from './style'

export const RenegotiationPaymentDetailsBox = styled(Box)`
  padding: 16px 40px 136px 40px;
  background-color: ${gray.azure};
  text-align: start;
  scroll-behavior: smooth;
`

export interface IRenegotiationPaymentDetailsPageProps {
  history?: any
  match?: any
  location?: any
}

export interface IRenegotiationPaymentDetailsPageState {
  renegotiationPaymentId?: string
  renegotiationPayment: RenegotiationPaymentResponse | null
}

const initialState: IRenegotiationPaymentDetailsPageState = {
  renegotiationPayment: null,
}

export default class RenegotiationPaymentDetails extends React.Component<
  IRenegotiationPaymentDetailsPageProps,
  IRenegotiationPaymentDetailsPageState
> {
  constructor(props: IRenegotiationPaymentDetailsPageProps) {
    super(props)

    this.state = initialState
  }

  componentDidMount() {
    const { renegotiationPaymentId } = this.props.match.params
    const { renegotiationPayment } = this.props.history.location.state || {}

    document.body.scrollTop = 0

    this.setState({ renegotiationPaymentId, renegotiationPayment })

    if (!renegotiationPayment) {
      this.loadPayment(renegotiationPaymentId)
    }
  }

  loadPayment = (installment: string) => {
    bffBackofficeApiService.coordinator
      .getOneRenegotiationPaymentById(installment)
      .then(data => {
        this.setState({ renegotiationPayment: data })
      })
      .catch(() => {
        this.setState({ renegotiationPayment: null })
        return this.props.history.push('/*')
      })
  }

  onPreviousLinkClick = () => {
    this.props.history.go(-1)
  }

  public render() {
    const { renegotiationPayment, renegotiationPaymentId: installmentId } = this.state

    if (!renegotiationPayment) {
      return <LoadingScreen />
    }

    return (
      <RenegotiationPaymentDetailsBox fill>
        <Box margin={{ horizontal: 'medium', top: 'small' }}>
          <Breadcrumbs anchors={['Home', 'Priorização', 'Parcelas', 'ID:' + installmentId]} />
        </Box>

        <Box margin={{ horizontal: 'small', vertical: 'medium' }} direction="row" align="center" gap="xsmall">
          <PreviousButtonStyle
            onClick={this.onPreviousLinkClick}
            width="32px"
            height="32px"
            icon={<FormPreviousLink color="white" />}
          />
          <Heading size="12px">Voltar</Heading>
        </Box>

        <Box gap="medium">
          <Box gap="xsmall">
            <Box margin={{ horizontal: 'medium' }} direction="row" align="center" gap="medium">
              <SectionTitleStyle>Detalhes da parcela</SectionTitleStyle>
              <RenegotiationPaymentStatus payment={renegotiationPayment} />
            </Box>
            <RenegotiationPaymentDetailsContent renegotiationPayment={renegotiationPayment} />
          </Box>

          <Box direction="row-responsive" gap="medium">
            <Box>
              <Box margin={{ horizontal: 'medium' }}>
                <SectionTitleStyle>Valores</SectionTitleStyle>
              </Box>
              <RenegotiationPaymentValues renegotiationPayment={renegotiationPayment} />
            </Box>
            <Box>
              <Box margin={{ horizontal: 'medium' }}>
                <SectionTitleStyle>Pagamentos</SectionTitleStyle>
              </Box>
              <RenegotiationPaymentPaidValues renegotiationPayment={renegotiationPayment} />
            </Box>
          </Box>

          <Box gap="medium">
            <Box>
              <Box margin={{ horizontal: 'medium' }}>
                <SectionTitleStyle>Histórico de boletos dessa parcela</SectionTitleStyle>
              </Box>
              <InvoicesHistory
                invoices={renegotiationPayment instanceof Error ? renegotiationPayment : renegotiationPayment.invoices}
              />
            </Box>
          </Box>
        </Box>
      </RenegotiationPaymentDetailsBox>
    )
  }
}
