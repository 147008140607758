import styled from 'styled-components'
import { laptop } from '../../../common/assets/_breakpoints'
import { blue, gray, white, black, red } from '../../../common/assets/_colors'
import { large, medium, small, xlarge, xsmall } from '../../../common/assets/_sizes'

const PasswordCard = styled.div`
  width: auto
  height: 500px
  background-color: hsla(120, 60%, 100%, 0.7);
  padding: ${xlarge} ${xlarge} 0 ${xlarge};

  @media (min-width: ${laptop}) { 
    width: 60%;
    float: right;
    font-size: initial
  }
`

const PasswordContainer = styled.div`
  @media (min-width: ${laptop}) {
    background-color: hsla(120, 60%, 100%, 0.7);
  }
`

const TitleContainer = styled.div``

const CpfInvalid = styled.p`
  color: ${red.primary};
  font-size: small;
`

const ParagraphTitle = styled.p`
  color: ${gray.primary};
`

const InputPassword = styled.input`
  margin-bottom: ${small};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
}
`

const Button = styled.button`
  background-color: ${blue.dark};
  border: none;
  color: ${white.primary};
  padding: ${medium} ${large};
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: ${large};
  margin: ${small} ${xsmall};
  cursor: pointer;

  &:disabled {
    border: 1px solid ${gray.light};
    background-color: ${gray.light};
    color: ${black.primary};
  }
}
`

const UnorderedList = styled.ul`
  text-align: left;

  @media (min-width: ${laptop}) {
    margin-top: ${xlarge};
  }
`

const PasswordComponent = styled.div`
  display: inline-grid;

  @media (min-width: ${laptop}) {
    display: flex;
    flex-direction: column;
    place-items: center;
  }
`

const ListItem = styled.li`
  font-size: smaller;
  font-style: italic;
`

const ImgHeroDesktop = styled.img`
  display: none;
  @media (min-width: ${laptop}) {
    display: inline;
    height: 500px;
  }
`

const ImgLockerMobile = styled.img`
  display: inline;
  height: 25%;
  @media (min-width: ${laptop}) {
    display: none;
  }
`

const TextResendCode = styled.button`
  cursor: pointer;
  text-decoration: underline;
  color: ${blue.dark}
  border: none;
  background-color: unset;
`

export {
  CpfInvalid,
  Button,
  InputPassword,
  ParagraphTitle,
  PasswordCard,
  PasswordComponent,
  PasswordContainer,
  TitleContainer,
  UnorderedList,
  ListItem,
  ImgHeroDesktop,
  ImgLockerMobile,
  TextResendCode,
}
