import styled from 'styled-components'
import { TableRow } from 'grommet'

// style={{ backgroundColor: index % 2 === 1 ? EVEN_ROW_COLOR : undefined }}

const EVEN_ROW_COLOR = '#F0F0F0'

export const GenericTableRow = styled(TableRow)`
  background-color: ${(props: any) => (props.index % 2 === 1 ? EVEN_ROW_COLOR : 'white')};

  ${(props: any) =>
    props.disableHover
      ? ''
      : `
  &:hover {
      background-color: blue;
      color: white;
      transition: 0.3s;
      cursor: pointer;
  }
  `}
`
export const NotFoundText = styled.h2`
  color: grey;
  text-align: center;
  padding-bottom: 1%;
`