import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Aliens } from '../../common/assets/images/aliens.svg'

interface INotFoundError {
  message: string
}

const NotFoundError = ({ message }: INotFoundError) => (
  <>
    <NotFoundMessage>
      <strong>{message}</strong>
    </NotFoundMessage>

    <NotFoundContainer>
      <Aliens />
      <h3>
        Ou talvez Aliens tenham invadido a Terra e destruído os servidores. <br />
      </h3>
      <h3>
        Clique <LinkAnchor href="/">aqui</LinkAnchor> para voltar à tela inicial.
      </h3>
    </NotFoundContainer>
  </>
)

export default NotFoundError

const NotFoundMessage = styled.div`
  text-align: center;
  width: 100%;
  background-color: ${UMEColors.red.light};
  color: ${UMEColors.white.primary};
  padding: 7px;
`

const NotFoundContainer = styled.div`
  background-color: white;
  min-height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  & > h1 {
    margin-bottom: 5vh;
  }
  & > svg {
    margin-bottom: 2vh;
    opacity: 0.7;
    width: 150px;
    height: 150px;
  }

  & > h3 {
    width: 300px;
    word-wrap: break-word;
    color: ${UMEColors.gray.aluminium};
  }
`

const LinkAnchor = styled.a`
  color: ${UMEColors.blue.bay};
  font-weight: bold;
  width: 150px;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0;
`
