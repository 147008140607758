import { FinancingOptionsTypeEnum } from '../services/bff-backoffice/retailers/interfaces/retailers.interfaces'

export class FinancingOptionsTypeResolver {
  static getFinancingOptionsType() {
    return [
      {
        financingOptionsType: FinancingOptionsTypeEnum.DEFAULT,
        name: 'Ume Convencional',
      },
      {
        financingOptionsType: FinancingOptionsTypeEnum.HIGH_RECURRENCE,
        name: 'Ume Leve',
      },
    ]
  }
}
