import styled from 'styled-components'

export const ParagraphLeft = styled.div`
  text-align: left;
  margin: 4;
`

export const DateRangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 1.5%;
  margin-bottom: 1.0%;
  flex-wrap: wrap;
  justify-content: space-around;
`
