import { DataTable, Loading, Text } from '@bit/ume.design-kit.ui'
import { gray, green, red, white, yellow } from '@bit/ume.design-kit.ui/Palette'
import { Box } from 'grommet'
import * as React from 'react'
import { translateSourceProduct } from '../../../common/assets/utils/translationUtils'
import {IRetailerResponse, IStoreResponse} from '../../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'
import { formatDate, formatNumberToBRL } from '../../../utils'
import { IContractUI } from '../BorrowerProfilePage'
import { CardStyle, LinkAnchor, TagStyle } from './style'

export interface IContractsCardProps {
  retailers: IRetailerResponse[]
  stores: IStoreResponse[]
  contracts?: IContractUI[] | Error
  onFilterSelected: (property: string, value: any) => void
  onMounted?: () => void
}

export default class ContractsCard extends React.PureComponent<IContractsCardProps> {
  componentDidMount(): void {
    if (this.props.onMounted) this.props.onMounted()
  }

  private loadHeaders = () => {
    return [
      {
        attribute: 'sourceProduct',
        label: 'Produto',
        formatCell: (c: IContractUI) => translateSourceProduct(c.sourceProduct),
      },
      {
        label: 'ID do contrato',
        attribute: 'id',
      },
      {
        attribute: 'retailerName',
        label: 'Varejo/Loja',
        dropDownValues: [{ label: 'Todos', value: 'all' }].concat(
          this.props.retailers.map(r => ({ label: r.fantasyName, value: r.id }))
        ),
        formatCell: (c: IContractUI) => c.retailerName + '/' + c.storeName,
      },
      {
        attribute: 'contractValue',
        label: 'Valor do Contrato',
        formatCell: (c: IContractUI) => formatNumberToBRL(c.contractValue || 0),
      },
      {
        attribute: 'installments',
        label: 'Qtd. de Parcelas',
        formatCell: (c: IContractUI) => c.installments.length + 'x',
      },
      {
        attribute: 'createdOn',
        label: 'Data da Originação',
        formatCell: (c: IContractUI) => formatDate(c.createdOn),
      },
      {
        attribute: 'installments',
        label: 'Valor das parcelas em aberto',
        formatCell: (c: IContractUI) => formatNumberToBRL(c.sumPendingInstallments),
      },
      {
        attribute: 'receipt',
        label: 'Comprovante',
        formatCell: (c: IContractUI) => (
          <LinkAnchor target="_blank" href={c.purchaseReceiptRenderUri}>
            Visualizar
          </LinkAnchor>
        ),
      },
      {
        label: 'Status',
        attribute: 'status',
        dropDownValues: [
          { label: 'Todos', value: 'all' },
          { label: 'Em aberto', value: 'PENDING' },
          { label: 'Vencida', value: 'OVERDUE' },
          { label: 'Pago', value: 'PAID' },
          { label: 'Renegociado', value: 'RENEGOTIATED' },
          { label: 'Cancelado', value: 'CANCELED' },
        ],
        formatCell: (c: IContractUI) => {
          switch (c.status) {
            case 'PENDING':
              return (
                <TagStyle
                  disable
                  backgroundColor={gray.primary}
                  label={'A vencer'}
                  iconBackground="none"
                  iconColor={white.primary}
                ></TagStyle>
              )
            case 'PAID':
              return (
                <TagStyle
                  disable
                  backgroundColor={green.laurel}
                  label={'Pago'}
                  iconBackground="none"
                  iconColor={white.primary}
                />
              )
            case 'OVERDUE':
              return (
                <TagStyle
                  disable
                  backgroundColor={red.primary}
                  label={'Vencida'}
                  iconBackground="none"
                  iconColor={white.primary}
                />
              )
            case 'CANCELED':
              return (
                <TagStyle
                  disable
                  backgroundColor={red.primary}
                  label={'Cancelado'}
                  iconBackground="none"
                  iconColor={white.primary}
                />
              )
            default:
              return <TagStyle disable backgroundColor={yellow.citrine} label={'Renegociado'} />
          }
        },
      },
    ]
  }

  public render() {
    const { contracts, onFilterSelected } = this.props

    const headers = this.loadHeaders()

    const errorCard = (
      <Box margin={{ top: '20px' }}>
        <Text color="red" light>
          Não foi possível carregar os dados para este cliente.
        </Text>
      </Box>
    )

    const ContractsCard = (contracts: IContractUI[]) => (
      <DataTable header={headers} data={contracts} onFilterSelected={onFilterSelected} fontSize="14px" />
    )

    return (
      <CardStyle>
        {!contracts ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : contracts instanceof Error ? (
          errorCard
        ) : (
          ContractsCard(contracts)
        )}
      </CardStyle>
    )
  }
}
