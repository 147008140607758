import { Box } from 'grommet'
import { Clock, Currency, Money, Optimize, Organization, PhoneVertical, User } from 'grommet-icons'
import * as React from 'react'
import { Role } from '../../common/PrivateRoute'
import { HomeCard } from '../../molecules/Home/HomeCard/HomeCard'
import HomeTitle from '../../molecules/Home/Title/HomeTitle'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { ILoginProfileResponse } from '../../services/bff-backoffice/auth/dto/Auth.dto'
import { HomeContainerStyle, MainBoxStyle } from './style'

export interface HomeProps {
  history?: any
  handleUserLogged: (user?: ILoginProfileResponse) => void
}

export interface HomeState {}

export default class Home extends React.Component<HomeProps, HomeState> {
  constructor(props: HomeProps) {
    super(props)
    this.state = {}
  }

  public render() {
    const user = bffBackofficeApiService.auth.getUser()
    if (user && !user.passwordReset && user.role === Role.BORROWER) {
      this.props.history.push('/compras')
    }

    return (
      <HomeContainerStyle height="xlarge" gap="small">
        <Box gap="small">
          <HomeTitle />
          <MainBoxStyle>
            <HomeCard subtitle={'Análises'} icon={<Optimize />} to={'/analysis'} />
            <HomeCard subtitle={'Tomadores'} icon={<User />} to={'/borrowers'} />
            <HomeCard subtitle={'Financeiro'} icon={<Money />} to={'/financial/originations'} />
            <HomeCard subtitle={'Risco'} icon={<Currency />} to={'/risk'} />
            <HomeCard subtitle={'Varejistas'} icon={<Organization />} to={'/retailers'} />
            <HomeCard subtitle={'Application Online'} icon={<PhoneVertical />} to={'/applications/batches'} />
            <HomeCard subtitle={'Banca de Varejistas'} icon={<Clock />} to={'/retailers-bench'} />
          </MainBoxStyle>
        </Box>
      </HomeContainerStyle>
    )
  }
}
