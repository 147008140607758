import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import {
  IBatchResponse,
  IGetBatchesQueryParams,
  IGetBatchResponse,
  IGetRetailerMdrRatesResponse,
  IOriginationResponse,
  IPostBankerEmail,
  IPutRetailerMdrRatesBody,
  IPutVoucher,
} from './interfaces/liquidation-originations.interfaces'

export default class LiquidationOriginationsService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async getBatches(params: IGetBatchesQueryParams): Promise<IGetBatchResponse> {
    const response = await this.axiosInstance.get<IGetBatchResponse>(`/liquidation-originations/batches`, {
      params,
    })
    return response.data
  }

  async endStepForBatch(id: string, stepName: string): Promise<void> {
    const response = await this.axiosInstance.post<void>(`/liquidation-originations/batches/${id}/step/${stepName}/end`)
    return response.data
  }

  async getBatchOriginations(batchId: string, storeId?: string, retailerId?: string): Promise<IOriginationResponse[]> {
    const params = { storeId, retailerId }
    const response = await this.axiosInstance.get<IOriginationResponse[]>(
      `/liquidation-originations/batches/${batchId}/originations`,
      {
        params,
      }
    )
    return response.data
  }

  async sendBankerEmail(id: string, body: IPostBankerEmail): Promise<void> {
    const response = await this.axiosInstance.post<void>(`/liquidation-originations/batches/${id}/banker/email`, body)
    return response.data
  }

  async putBankerVoucher(id: string, body: IPutVoucher): Promise<void> {
    const response = await this.axiosInstance.put<void>(`/liquidation-originations/batches/${id}/banker/voucher`, body)
    return response.data
  }

  async putRetailerMdrRates(id: string, body: IPutRetailerMdrRatesBody): Promise<IGetRetailerMdrRatesResponse> {
    const response = await this.axiosInstance.put<IGetRetailerMdrRatesResponse>(
      `/liquidation-originations/retailers/${id}/mdr-rates`,
      body
    )
    return response.data
  }

  async getBatchById(id: string): Promise<IBatchResponse> {
    const response = await this.axiosInstance.get<IBatchResponse>(`/liquidation-originations/batches/${id}`)
    return response.data
  }

  async getRetailerMdrRates(id: string): Promise<IGetRetailerMdrRatesResponse> {
    const response = await this.axiosInstance.get<IGetRetailerMdrRatesResponse>(
      `/liquidation-originations/retailers/${id}/mdr-rates`
    )
    return response.data
  }
}
