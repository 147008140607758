import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { toaster } from '../../../App'
import {
  ApplicationBatchesResponse,
  IApplicationResponse,
  IBatchApplicationOptionsResponse,
  IScoresResponse,
  PreApplicationStatus,
} from '../../../services/bff-backoffice/application-engine/interfaces/application-engine.interfaces'
import {
  IEvaluateOnlineApplicationBody,
  IEvaluateOnlineApplicationResponse,
} from '../../../services/bff-backoffice/coordinator/interfaces/applications.interfaces'
import { IBorrowerResponse } from '../../../services/bff-backoffice/coordinator/interfaces/borrower.interfaces'
import {
  IDocumentImageResponse,
  IImageResponse,
  IProcessStatusResponse,
} from '../../../services/bff-backoffice/datalake-acesso/interfaces/datalake-acesso.interfaces'
import { transformHttpApplicationsErrorsToString } from './applications.errors'

export type IApplicationsState = {
  /************* FETCH APPLICATION *************/
  application?: IApplicationResponse
  isLoadingApplication: boolean
  fetchApplicationError?: AxiosResponse

  /************* FETCH APPLICATIONS SCORES *************/
  applicationScores?: IScoresResponse
  isLoadingApplicationScores: boolean
  fetchApplicationScoresError?: AxiosResponse

  /************* FETCH BORROWER *************/
  borrower?: IBorrowerResponse
  isLoadingBorrower: boolean
  fetchBorrowerError?: AxiosResponse

  /************* FETCH BORROWER FACE IMAGE *************/
  borrowerFaceImage?: IImageResponse
  isLoadingBorrowerFaceImage: boolean
  fetchBorrowerFaceImageError?: AxiosResponse

  /************* FETCH BORROWER DOCUMENT IMAGES *************/
  borrowerDocumentImages?: IDocumentImageResponse
  isLoadingBorrowerDocumentImages: boolean
  fetchBorrowerDocumentImagesError?: AxiosResponse

  /************* EVALUATE ONLINE APPLICATION *************/
  applicationOnlineEvaluation?: IEvaluateOnlineApplicationResponse
  isLoadingApplicationOnlineEvaluation: boolean
  ApplicationOnlineEvaluationError?: AxiosResponse

  /************* FETCH APPLICATION BATCHES *************/
  batches?: ApplicationBatchesResponse[]
  isLoadingBatches: boolean
  fetchBatchesError?: AxiosResponse

  /************* FETCH APPLICATION BATCHES *************/
  batchApplications?: IBatchApplicationOptionsResponse[]
  isLoadingBatchApplications: boolean
  fetchBatchApplicationsError?: AxiosResponse

  /************* FETCH APPLICATION BATCHES *************/
  biometryStatus?: IProcessStatusResponse
  isLoadingBiometryStatus: boolean
  fetchBiometryStatusError?: AxiosResponse
}

const initialState: IApplicationsState = {
  isLoadingApplication: false,
  isLoadingApplicationScores: false,
  isLoadingBorrower: false,
  isLoadingBorrowerFaceImage: false,
  isLoadingBorrowerDocumentImages: false,
  isLoadingApplicationOnlineEvaluation: false,
  isLoadingBatches: false,
  isLoadingBatchApplications: false,
  isLoadingBiometryStatus: false,
}

export interface EvaluateApplicationOnlineAction {
  applicationId: string
  body: IEvaluateOnlineApplicationBody
}

export const ApplicationsSliceReducer = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    /************* FETCH APPLICATION *************/
    fetchApplication: (state, action: PayloadAction<string>) => {
      // Used in Redux Saga
      return state
    },
    fetchApplicationLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingApplication: true }
    },
    fetchApplicationSuccess: (state, action: PayloadAction<IApplicationResponse>) => {
      const application = action.payload
      return { ...state, application, isLoadingApplication: false, fetchApplicationError: undefined }
    },
    fetchApplicationError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      const message = transformHttpApplicationsErrorsToString(error)
      toaster.showErrorToast(message)
      return {
        ...state,
        application: undefined,
        fetchApplicationError: error.response,
        isLoadingApplication: false,
      }
    },

    /************* FETCH APPLICATIONS SCORES *************/
    fetchApplicationScores: (state, action: PayloadAction<string>) => {
      // Used in Redux Saga
      return state
    },
    fetchApplicationScoresLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingApplicationScores: true }
    },
    fetchApplicationScoresSuccess: (state, action: PayloadAction<IScoresResponse>) => {
      const applicationScores = action.payload
      return { ...state, applicationScores, isLoadingApplicationScores: false, fetchApplicationScoresError: undefined }
    },
    fetchApplicationScoresError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      if (error.response?.status === 404) {
        toaster.showErrorToast('Erro ao carregar scores do application.')
      } else {
        const message = transformHttpApplicationsErrorsToString(error)
        toaster.showErrorToast(message)
      }
      return {
        ...state,
        applicationScores: undefined,
        fetchApplicationScoresError: error.response,
        isLoadingApplicationScores: false,
      }
    },

    /************* FETCH BORROWER *************/
    fetchBorrower: (state, action: PayloadAction<string>) => {
      // Used in Redux Saga
      return state
    },
    fetchBorrowerLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingBorrower: true }
    },
    fetchBorrowerSuccess: (state, action: PayloadAction<IBorrowerResponse>) => {
      const borrower = action.payload
      return { ...state, borrower, isLoadingBorrower: false, fetchBorrowerError: undefined }
    },
    fetchBorrowerError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      const message = transformHttpApplicationsErrorsToString(error)
      toaster.showErrorToast(message)
      return {
        ...state,
        borrower: undefined,
        fetchBorrowerError: error.response,
        isLoadingBorrower: false,
      }
    },

    /************* FETCH BORROWER FACE IMAGE *************/
    fetchBorrowerFaceImage: (state, action: PayloadAction<string>) => {
      // Used in Redux Saga
      return state
    },
    fetchBorrowerFaceImageLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingBorrowerFaceImage: true }
    },
    fetchBorrowerFaceImageSuccess: (state, action: PayloadAction<IImageResponse>) => {
      const borrowerFaceImage = action.payload
      return { ...state, borrowerFaceImage, isLoadingBorrowerFaceImage: false, fetchBorrowerFaceImageError: undefined }
    },
    fetchBorrowerFaceImageError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      if (error.response?.status === 404) {
        toaster.showErrorToast('Erro ao carregar foto de rosto.')
      } else {
        const message = transformHttpApplicationsErrorsToString(error)
        toaster.showErrorToast(message)
      }
      return {
        ...state,
        borrowerFaceImage: undefined,
        fetchBorrowerFaceImageError: error.response,
        isLoadingBorrowerFaceImage: false,
      }
    },

    /************* FETCH BORROWER DOCUMENT IMAGES *************/
    fetchBorrowerDocumentImages: (state, action: PayloadAction<string>) => {
      // Used in Redux Saga
      return state
    },
    fetchBorrowerDocumentImagesLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingBorrowerDocumentImages: true }
    },
    fetchBorrowerDocumentImagesSuccess: (state, action: PayloadAction<IDocumentImageResponse>) => {
      const borrowerDocumentImages = action.payload
      return {
        ...state,
        borrowerDocumentImages,
        isLoadingBorrowerDocumentImages: false,
        fetchBorrowerDocumentImagesError: undefined,
      }
    },
    fetchBorrowerDocumentImagesError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      if (error.response?.status === 404) {
        toaster.showErrorToast('Erro ao carregar foto de documento.')
      } else {
        const message = transformHttpApplicationsErrorsToString(error)
        toaster.showErrorToast(message)
      }
      return {
        ...state,
        borrowerDocumentImages: undefined,
        fetchBorrowerDocumentImagesError: error.response,
        isLoadingBorrowerDocumentImages: false,
      }
    },

    /************* EVALUATE APPLICATION ONLINE *************/
    evaluateApplicationOnline: (state, action: PayloadAction<EvaluateApplicationOnlineAction>) => {
      // Used in Redux Saga
      return state
    },
    evaluateApplicationOnlineLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingApplicationOnlineEvaluation: true }
    },
    evaluateApplicationOnlineSuccess: (state, action: PayloadAction<IEvaluateOnlineApplicationResponse>) => {
      const applicationOnlineEvaluation = action.payload

      const status = applicationOnlineEvaluation.status === PreApplicationStatus.APPROVED ? 'aprovado' : 'negado'
      toaster.showSuccessToast(`Application ${status} com sucesso`)

      return {
        ...state,
        applicationOnlineEvaluation,
        isLoadingApplicationOnlineEvaluation: false,
        ApplicationOnlineEvaluationError: undefined,
      }
    },
    evaluateApplicationOnlineError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      const message = transformHttpApplicationsErrorsToString(error)
      toaster.showErrorToast(message)
      return {
        ...state,
        applicationOnlineEvaluation: undefined,
        ApplicationOnlineEvaluationError: error.response,
        isLoadingApplicationOnlineEvaluation: false,
      }
    },

    /************* FETCH BATCHES *************/
    fetchBatches: (state, action: PayloadAction<undefined>) => {
      // Used in Redux Saga
      return state
    },
    fetchBatchesLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingBatches: true }
    },
    fetchBatchesSuccess: (state, action: PayloadAction<ApplicationBatchesResponse[]>) => {
      const batches = action.payload
      return { ...state, batches, isLoadingBatches: false, fetchBatchesError: undefined }
    },
    fetchBatchesError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      const message = transformHttpApplicationsErrorsToString(error)
      toaster.showErrorToast(message)
      return {
        ...state,
        batches: undefined,
        fetchBatchesError: error.response,
        isLoadingBatches: false,
      }
    },

    /************* FETCH BATCHES APPLICATIONS *************/
    fetchBatchApplications: (state, action: PayloadAction<string>) => {
      // Used in Redux Saga
      return state
    },
    fetchBatchApplicationsLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingBatchApplications: true }
    },
    fetchBatchApplicationsSuccess: (state, action: PayloadAction<IBatchApplicationOptionsResponse[]>) => {
      const batchApplications = action.payload
      return { ...state, batchApplications, isLoadingBatchApplications: false, fetchBatchApplicationsError: undefined }
    },
    fetchBatchApplicationsError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      if (error.response?.status !== 404) {
        const message = transformHttpApplicationsErrorsToString(error)
        toaster.showErrorToast(message)
      }
      return {
        ...state,
        batchApplications: undefined,
        fetchBatchApplicationsError: error.response,
        isLoadingBatchApplications: false,
      }
    },

    /************* FETCH BIOMETRY STATUS *************/
    fetchBiometryStatus: (state, action: PayloadAction<string>) => {
      // Used in Redux Saga
      return state
    },
    fetchBiometryStatusLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingBiometryStatus: true }
    },
    fetchBiometryStatusSuccess: (state, action: PayloadAction<IProcessStatusResponse>) => {
      const biometryStatus = action.payload
      return { ...state, biometryStatus, isLoadingBiometryStatus: false, fetchBiometryStatusError: undefined }
    },
    fetchBiometryStatusError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      if (error.response?.status === 404) {
        toaster.showErrorToast('Erro ao carregar status da biometria.')
      }
      return {
        ...state,
        biometryStatus: undefined,
        fetchBiometryStatusError: error.response,
        isLoadingBiometryStatus: false,
      }
    },
  },
})
