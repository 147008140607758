import { Button, Heading, Modal } from '@bit/ume.design-kit.ui'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Text } from 'grommet'
import styled from 'styled-components'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { tablet } from '../../common/assets/_breakpoints'

export const SectionTitleStyle = styled(Heading)`
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin-left: 10px;
  text-align: start;
`

export const SubSectionTitleStyle = styled(Heading)`
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin-left: 10px;
  align-self: self-start;
  margin-bottom: 0;
`

export const PreviousButtonStyle = styled(Button)`
  padding: 0;
`

export const PageTitle = styled.span`
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: left;
`

export const ModalView = styled.div`
  @media (max-width: ${tablet}) {
    width: 100%;
  }
`

export const StyledModal = styled(Modal)`
  width: 100%;
  height: 100%;
`

export const StyledTextRetailerName = styled.label`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 14px;
  color: ${UMEColors.gray.eclipse};
`

export const GooglePlaceAddressTextStyled = styled(Text)`
  font-size: 14px;
  line-height: 14px;
  color: ${UMEColors.gray.eclipse};
`
export const GooglePlaceNameTextStyled = styled(Text)`
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: ${UMEColors.gray.eclipse};
`

export const SmallLoadingIcon = styled(LoadingIcon)`
  height: 14px;
  width: 14px;
  fill: ${UMEColors.white.primary};
`

export const DeleteTextStyled = styled(Text)`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 38px;
`

export const BoldTextStyled = styled(Text)`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 38px;
  font-weight: bold;
`

export const FileUpload = styled.input`
  outline: none;
  margin-left: 25px;
`

export const GreenStatus = styled.span`
  padding: 5px;
  background-color: green;
  color: white;
  min-width: 100px;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export const RedStatus = styled.span`
  padding: 5px;
  background-color: red;
  color: white;
  transition: 0.3s;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
    transition: 0.3s;
  }
`
