import React from 'react'
import { Link } from 'react-router-dom'
import { BreadcrumbsBox, BreadcrumbsItem } from '../RetailersPage/styles'

const RetailersBenchPageBreadcrumbs = () => {
  return (
    <BreadcrumbsBox>
      <Link to="/">
        <BreadcrumbsItem>Início</BreadcrumbsItem>
      </Link>
      <BreadcrumbsItem>/</BreadcrumbsItem>
      <BreadcrumbsItem active>Banca de Varejistas</BreadcrumbsItem>
    </BreadcrumbsBox>
  )
}

export default RetailersBenchPageBreadcrumbs