import { Button, Clipboard, Loading } from '@bit/ume.design-kit.ui'
import { blue, green, red, white } from '@bit/ume.design-kit.ui/utils/_colors'
import { Box } from 'grommet'
import moment from 'moment'
import * as React from 'react'
import { toaster } from '../../../App'
import { TextDatePicker } from '../../../molecules'
import { bffBackofficeApiService } from '../../../services/bff-backoffice'
import {
  ChargeFeeInstallment,
  InstallmentSimulation,
  ISingleInstallment,
} from '../../../services/bff-backoffice/coordinator/interfaces/installment.interfaces'
import {
  IInvoice,
  InvoiceGeneratedAt,
} from '../../../services/bff-backoffice/coordinator/interfaces/invoice.interfaces'
import { formatNumberToBRL } from '../../../utils'
import {
  ButtonStyle,
  CardStyle,
  DataStyle,
  DifferenceTextStyle,
  Divider,
  LabelStyle,
  LinkAnchor,
  LoadingBox,
} from './style'

interface Props {
  installments: ISingleInstallment[]
  feeInstallments: ChargeFeeInstallment[] | undefined | Error
  numberOfInstallments: number
}

interface State {
  isSimulationLoading: boolean
  isCreatingInvoice: boolean
  simulations: InstallmentSimulation[] | null
  date: string
  installmentsIds: string[]
  invoiceValue?: number
  renegotiationValue?: string
  createdInvoice?: IInvoice | null
}

export default class MultipleInstallmentsSimulationCard extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      date: '',
      isSimulationLoading: false,
      isCreatingInvoice: false,
      simulations: null,
      installmentsIds: this.props.installments.map(installment => installment.id),
    }
  }

  createInvoice = () => {
    let dueDate = moment(this.state.date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    if (this.state.isCreatingInvoice) return

    this.setState({ isCreatingInvoice: true })

    bffBackofficeApiService.coordinator
      .createInvoiceForInstallments(this.state.installmentsIds, dueDate, InvoiceGeneratedAt.BACKOFFICE)
      .then((invoice: IInvoice) => {
        this.setState({
          createdInvoice: invoice,
          isCreatingInvoice: false,
        })

        toaster.showSuccessToast('Boleto emitido com sucesso!')
      })
      .catch((error: any) => {
        this.setState({ isCreatingInvoice: false })

        toaster.showErrorToast(error.response?.data?.message)
      })
  }

  onSimulate = () => {
    const dateMoment = moment(this.state.date, 'DD/MM/YYYY')
    if (moment(moment().format('YYYY-MM-DD')) > dateMoment) {
      this.setState({ simulations: null, invoiceValue: undefined, createdInvoice: null })
      return toaster.showErrorToast('Data invalida.')
    }
    const date = dateMoment.format('YYYY-MM-DD')
    if (date === 'Invalid date') return toaster.showErrorToast('Data invalida.')

    this.setState({
      createdInvoice: null,
      isSimulationLoading: true,
    })

    bffBackofficeApiService.coordinator
      .simulateInstallmentsByDate(this.state.installmentsIds, date)
      .then((simulations: InstallmentSimulation[]) => {
        const simulationsTotalValue = simulations.reduce(
          (totalValue, simulation) => totalValue + simulation.simulation.paymentDue,
          0
        )
        this.setState({ simulations, isSimulationLoading: false, invoiceValue: simulationsTotalValue })
      })
      .catch(() => {
        toaster.showErrorToast('Erro ao simular o valor do boleto')
        this.setState({ isSimulationLoading: false })
      })
  }

  onInvoiceDClick = (d: number) => {
    // days

    const targetDate = moment()
      .add(d, 'd')
      .format('DD/MM/YYYY')

    this.setState({
      date: targetDate,
    })
  }

  public render() {
    const { installments, numberOfInstallments, feeInstallments } = this.props

    const user = bffBackofficeApiService.auth.getUser()

    if (!user) {
      return
    }

    const installmentsValueSum =
      installments?.reduce((totalValue, instalment) => totalValue + instalment.installmentValue, 0) ?? 0

    const feeInstallmentsValueSum = () => {
      if (feeInstallments instanceof Error) {
        return 0
      }
      return feeInstallments?.reduce((totalValue, fee) => totalValue + fee.installmentValue, 0) ?? 0
    }

    const discount = (diff: number) =>
      diff > 0 ? (
        <DifferenceTextStyle style={{ color: red.primary }}>{'+' + formatNumberToBRL(diff)}</DifferenceTextStyle>
      ) : (
        <DifferenceTextStyle style={{ color: green.darkPastel }}>{formatNumberToBRL(diff)}</DifferenceTextStyle>
      )
    const installmentsTotalValueDisplayText = `Valor da${numberOfInstallments > 1 ? 's' : ''} parcela${
      numberOfInstallments > 1 ? 's' : ''
    }`
    const feeInstallmentsTotalValue = feeInstallmentsValueSum()

    const simulationCard = () => (
      <Box>
        {feeInstallmentsTotalValue > 0 && (
          <>
            <LabelStyle>Taxa de utilização</LabelStyle>
            <DataStyle>{formatNumberToBRL(feeInstallmentsTotalValue)}</DataStyle>
          </>
        )}
        <LabelStyle>{installmentsTotalValueDisplayText}</LabelStyle>
        <DataStyle>{formatNumberToBRL(installmentsValueSum)}</DataStyle>

        <Box style={{ margin: '0 0 15px 0' }}>
          <Divider />
        </Box>

        <Box direction="row" justify="between" pad={{ horizontal: '0', vertical: '16px' }}>
          <Button
            onClick={this.onInvoiceDClick.bind(this, 1)}
            color={blue.navy}
            backgroundColor={white.primary}
            style={{ padding: 0 }}
            label="+1"
            margin="0"
            width="3em"
          />
          <Button
            onClick={this.onInvoiceDClick.bind(this, 2)}
            color={blue.navy}
            backgroundColor={white.primary}
            style={{ padding: 0 }}
            label="+2"
            margin="0"
            width="3em"
          />
          <Button
            onClick={this.onInvoiceDClick.bind(this, 3)}
            color={blue.navy}
            backgroundColor={white.primary}
            style={{ padding: 0 }}
            label="+3"
            margin="0"
            width="3em"
          />
          <Button
            onClick={this.onInvoiceDClick.bind(this, 4)}
            color={blue.navy}
            backgroundColor={white.primary}
            style={{ padding: 0 }}
            label="+4"
            margin="0"
            width="3em"
          />
          <Button
            onClick={this.onInvoiceDClick.bind(this, 5)}
            color={blue.navy}
            backgroundColor={white.primary}
            style={{ padding: 0 }}
            label="+5"
            margin="0"
            width="3em"
          />
        </Box>

        <TextDatePicker
          value={this.state.date}
          onChange={event => {
            this.setState({ date: event.target.value })
          }}
          style={{ textAlign: 'center' }}
        />
        <ButtonStyle onClick={() => this.onSimulate()}>Simular</ButtonStyle>

        {this.state.isSimulationLoading ? (
          <LoadingBox>
            <Loading />
          </LoadingBox>
        ) : (
          <Box>
            <LabelStyle>Valor do boleto</LabelStyle>
            <DataStyle>{this.state.invoiceValue ? formatNumberToBRL(this.state.invoiceValue) : '-'}</DataStyle>

            <LabelStyle>Descontos/Acréscimos</LabelStyle>
            <DataStyle>
              {this.state.simulations
                ? discount((this.state.invoiceValue ?? 0) - (installmentsValueSum + feeInstallmentsTotalValue))
                : '-'}
            </DataStyle>
          </Box>
        )}
        <Box style={{ margin: '15px 0 0 0' }}>
          <Divider />
        </Box>

        <ButtonStyle
          onClick={() => this.createInvoice()}
          disabled={this.state.simulations && this.state.createdInvoice}
          border="none"
          color={white.primary}
          backgroundColor={green.laurel}
        >
          Gerar Boleto
        </ButtonStyle>
        {this.state.isCreatingInvoice ? (
          <LoadingBox>
            <Loading />
          </LoadingBox>
        ) : (
          <Box>
            {this.state.createdInvoice ? <LabelStyle>Boleto</LabelStyle> : <></>}
            <DataStyle onClick={() => toaster.showSuccessToast('Copiado para área de transferência')}>
              {this.state.createdInvoice ? (
                <Clipboard
                  width="175px"
                  label={
                    <LinkAnchor target="_blank" href={this.state.createdInvoice.url + '.pdf'}>
                      Link do boleto
                    </LinkAnchor>
                  }
                  value={this.state.createdInvoice.url + '.pdf'}
                />
              ) : (
                ''
              )}
            </DataStyle>

            <DataStyle onClick={() => toaster.showSuccessToast('Copiado para área de transferência')}>
              {this.state.createdInvoice ? (
                <Clipboard
                  width="175px"
                  label={<LabelStyle>Código do Boleto</LabelStyle>}
                  value={this.state.createdInvoice.barcode}
                />
              ) : (
                ''
              )}
            </DataStyle>
          </Box>
        )}
      </Box>
    )

    return (
      <CardStyle>
        {installments === undefined ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : installments instanceof Error ? (
          <></>
        ) : (
          simulationCard()
        )}
      </CardStyle>
    )
  }
}
