import * as React from 'react'
import { toaster } from '../../../App'
import Loading from '../../../atoms/Loading/Loading'
import { GenericTable } from '../../../molecules'
import { bffBackofficeApiService } from '../../../services/bff-backoffice'
import { IContract } from '../../../services/bff-backoffice/coordinator/interfaces/contracts.interfaces'
import { formatNumberToBRL } from '../../../utils'
import { renegotiationInstallmentsTableConfig } from './old-installments-table-config'
import { ContractViewPageContainer } from './styles'

interface IContractViewProps {
  history?: any
  match?: any
  location?: any
}

interface IContractViewState {
  isLoadingContract: boolean // are we fetching the original contract from our services ? used for the page "loading" state
  contractId?: string // comes from the URL, used for the "createRenegotiationProposals" transaction
  contract?: IContract // fetched from our services, used to compute the contract summary
  currentDebt: number
}

export default class ContractView extends React.Component<IContractViewProps, IContractViewState> {
  public readonly state: IContractViewState = {
    isLoadingContract: true,
    currentDebt: 0,
  }

  componentDidMount() {
    const { id: contractId } = this.props.match.params
    this.setState({ contractId })

    this.fetchContractById(contractId)
  }

  fetchContractById = async (contractId: string) => {
    try {
      const contract = await bffBackofficeApiService.coordinator.getContractById(contractId)

      contract.installments = contract.installments.sort((a, b) => a.installmentOrder - b.installmentOrder)

      // computes the sum of present values for all unpaid installments
      const currentDebt = contract.installments
        .map(x => (x.paymentTimestamp ? 0 : x.expectedValueOnDate))
        .reduce((x, y) => x + y, 0)

      this.setState({
        contract,
        isLoadingContract: false,
        currentDebt,
      })
    } catch (err) {
      toaster.showErrorToast('Contrato não encontrado')
      this.setState({
        isLoadingContract: false,
      })
    }
  }

  public render() {
    const { isLoadingContract, contract, contractId, currentDebt } = this.state

    if (isLoadingContract) {
      return <Loading />
    }

    if (!contract) {
      // went to API and contract is not defined
      return 'ERRO'
    }

    return (
      <ContractViewPageContainer>
        <h2>Contrato {`#${contractId}`}</h2>
        <GenericTable disableHover data={contract.installments} header={renegotiationInstallmentsTableConfig} />
        <h2 style={{ textAlign: 'right', marginRight: '1em' }}>Total da dívida: {formatNumberToBRL(currentDebt)}</h2>
      </ContractViewPageContainer>
    )
  }
}
