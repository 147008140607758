import { toaster } from '../../../App'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { IOperator, IOperatorPostReq } from '../../../services/bff-backoffice/coordinator/interfaces/retailers.interfaces'

export type IOperatorsState = {
  operators?: IOperator[]
  isCreateOperatorModalOpen: boolean
  isCreateOperatorLoading: boolean
}

const initialState: IOperatorsState = {
  operators: undefined,
  isCreateOperatorModalOpen: false,
  isCreateOperatorLoading: false
}

export const OperatorsSliceReducer = createSlice({
  name: 'operators',
  initialState,
  reducers: {
    resetState: (state, action: PayloadAction<undefined>) => {
      return { ...initialState }
    },

    /*********** FETCH RETAILER OPERATORS ***********/
    fetchRetailerOperators: (state, action: PayloadAction<string>) => {
      return { ...state, operators: undefined }
    },
    fetchRetailerOperatorsSuccess: (state, action: PayloadAction<IOperator[]>) => {
      const operators = action.payload
      return { ...state, operators }
    },
    fetchRetailerOperatorsError: (state, action: PayloadAction<AxiosError>) => {
      toaster.showErrorToast(`Erro ao carregar operadores.`)
      return { ...state, operators: [] }
    },

    /*********** CREATE OPERATOR ***********/
    createOperator: (state, action: PayloadAction<IOperatorPostReq>) => {
      // Used in Redux-Saga
      return state
    },
    createOperatorSuccess: (state, action: PayloadAction<IOperator>) => {
      toaster.showSuccessToast(`Operador criado com sucesso.`)
      return { ...state, isCreateOperatorLoading: false, isCreateOperatorModalOpen: false }
    },
    createOperatorError: (state, action: PayloadAction<AxiosError>) => {
      toaster.showErrorToast(`Erro ao criar operador.`)
      return { ...state, isCreateOperatorLoading: false }
    },
    createOperatorLoading: (state, action: PayloadAction<undefined>) => {
      // Used in Redux-Saga
      return { ...state, isCreateOperatorLoading: true }
    },
    createOperatorModalOpen: (state, action: PayloadAction<undefined>) => {
      // Used in Redux-Saga
      return { ...state, isCreateOperatorModalOpen: true }
    },
    createOperatorModalClose: (state, action: PayloadAction<undefined>) => {
      // Used in Redux-Saga
      return { ...state, isCreateOperatorModalOpen: false }
    },
  }
})
