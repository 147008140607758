import styled from 'styled-components'
import { black, gray } from '@bit/ume.design-kit.ui/utils/_colors'

export const BreadcrumbsBox = styled.div`
  display: block;
  font-size: 10px;
` 
  
export const BreadcrumbsItem = styled.span`
  margin: 0 6px;  
  color: ${props => props.active ? black.primary : gray.nobel};
`

