import { Button, Checkbox, Clipboard, DataTable, Loading, Text } from '@bit/ume.design-kit.ui'
import { blue, gray, green, red, white } from '@bit/ume.design-kit.ui/Palette'
import { Box } from 'grommet'
import { Sync } from 'grommet-icons'
import * as React from 'react'
import { toaster } from '../../../App'
import { translateSourceProduct } from '../../../common/assets/utils/translationUtils'
import {
  ChargeFeeInstallment,
  ISingleInstallment,
} from '../../../services/bff-backoffice/coordinator/interfaces/installment.interfaces'
import { formatDate, formatDateTreatingError, formatNumberToBRL } from '../../../utils'
import MultipleInstallmentsSimulationCard from '../SimulationCard/MultipleInstallmentsSimulationCard'
import {
  CardStyle,
  DifferenceTextStyle,
  HelperTextStyle,
  LinkAnchorStyle,
  SimulationsBoxStyle,
  StyledModal,
  TagStyle,
} from './style'
import { SummaryBox } from './SummaryBox'

export interface IInstallmentsCardProps {
  history?: any
  installments?: ISingleInstallment[] | Error
  feeInstallments?: ChargeFeeInstallment[] | Error
  feesTotalPaymentDue?: number
  onFilterSelected: (property: string, value: any) => void
  onMounted?: () => void
}

export interface IInstallmentsCardState {
  layer: boolean
  date: string
  simulationLoading: boolean
  installmentsToSimulate: ISingleInstallment[]
  numberOfSelectedInstallments: number
  totalValue: number
}

const initialState: IInstallmentsCardState = {
  layer: false,
  date: '',
  simulationLoading: false,
  installmentsToSimulate: [],
  numberOfSelectedInstallments: 0,
  totalValue: 0,
}

export default class InstallmentsCard extends React.Component<IInstallmentsCardProps, IInstallmentsCardState> {
  constructor(props: IInstallmentsCardProps) {
    super(props)
    this.state = { ...initialState }
  }

  componentDidMount(): void {
    if (this.props.onMounted) this.props.onMounted()
  }

  componentDidUpdate(prevProps: IInstallmentsCardProps) {
    if (this.props.feesTotalPaymentDue !== prevProps.feesTotalPaymentDue) {
      this.setState({ totalValue: this.props?.feesTotalPaymentDue ?? 0 })
    }
  }

  updateSummary(checked: boolean, value: number) {
    if (checked) {
      this.setState({
        totalValue: this.state.totalValue + value + 0,
        numberOfSelectedInstallments: this.state.numberOfSelectedInstallments + 1,
      })
    } else {
      this.setState({
        totalValue: this.state.totalValue - value + 0,
        numberOfSelectedInstallments: this.state.numberOfSelectedInstallments - 1,
      })
    }
  }

  columnsDropDown = [
    {
      label: 'Selecione',
      attribute: 'id',
      formatCell: (i: ISingleInstallment) =>
        i.status !== 'PAID' ? (
          <Checkbox
            hoverBorder={white.primary}
            id={i.id}
            name="ids"
            onChange={(event: any) => this.updateSummary(event.target.checked, i.paymentDue)}
          />
        ) : (
          <Checkbox hoverBorder={white.primary} id={i.id} disabled />
        ),
    },
    {
      attribute: 'sourceProduct',
      label: 'Produto',
      formatCell: (i: ISingleInstallment) => translateSourceProduct(i.sourceProduct),
    },
    {
      label: 'ID do contrato',
      attribute: 'contractId',
    },
    {
      label: 'ID da parcela',
      attribute: 'id',
    },
    {
      attribute: 'dueDate',
      label: 'Vencimento',
      formatCell: (i: ISingleInstallment) => formatDate(i.dueDate),
    },
    {
      attribute: 'installmentValue',
      label: 'Valor da Parcela',
      formatCell: (i: ISingleInstallment) => formatNumberToBRL(i.installmentValue),
    },
    {
      attribute: 'interest',
      label: <Box width="100px">Descontos/ Acréscimos</Box>,
      formatCell: (i: ISingleInstallment) => {
        let difference = 0
        if (i.paidValue) {
          difference = i.paidValue - i.installmentValue
        } else {
          difference = i.paymentDue - i.installmentValue
        }
        if (difference > 0) {
          return <DifferenceTextStyle color={red.primary}>{'+' + formatNumberToBRL(difference)}</DifferenceTextStyle>
        } else {
          return <DifferenceTextStyle color={green.darkPastel}>{formatNumberToBRL(difference)}</DifferenceTextStyle>
        }
      },
    },
    {
      attribute: 'paymentDue',
      label: 'Valor atual',
      formatCell: (i: ISingleInstallment) => formatNumberToBRL(i.paymentDue),
    },
    {
      attribute: 'invoiceUrl',
      label: 'Link do boleto',
      formatCell: (i: ISingleInstallment) => {
        if (!i.invoices || i.invoices.length === 0) {
          return '-'
        }
        const latestInvoice = i.invoices.sort((a, b) => Number(b.id) - Number(a.id))[0]
        const latestInvoiceUrl = latestInvoice.url + '.pdf'
        const latestInvoiceBarCode = latestInvoice?.barcode?.slice(0, 5)
          ? latestInvoice?.barcode?.slice(0, 5) + '...'
          : '---------'

        return (
          <Box direction="row" align="center" gap="small">
            <LinkAnchorStyle target="_blank" href={latestInvoiceUrl}>
              {latestInvoiceBarCode}
            </LinkAnchorStyle>
            <div
              title="Copiar link do boleto"
              onClick={() => toaster.showSuccessToast('Copiado para área de transferência')}
            >
              <Clipboard value={latestInvoiceUrl} color={blue.bay} />
            </div>
          </Box>
        )
      },
    },
    {
      attribute: 'installmentOrder',
      label: <Box width="100px">Ordem da Parcela</Box>,
      formatCell: (i: ISingleInstallment) => i.installmentOrder,
    },
    {
      attribute: 'createdOn',
      label: 'Originação',
      formatCell: (i: ISingleInstallment) => formatDate(i.createdOn),
    },
    {
      label: 'Status',
      attribute: 'status',
      dropDownValues: [
        { label: 'Todos', value: 'all' },
        { label: 'Pendente', value: 'PENDING' },
        { label: 'Vencida', value: 'PAYMENT_OVERDUE' },
        { label: 'Pago', value: 'PAID' },
      ],
      formatCell: (i: ISingleInstallment) => {
        switch (i.status) {
          case 'PENDING':
            return (
              <TagStyle
                title="Clique para ver mais detalhes"
                onClick={() => this.redirectOnClick(i)}
                backgroundColor={gray.primary}
                label={'A vencer'}
                iconBackground="none"
                iconColor={white.primary}
                icon={i.isRenegotiation ? <Sync /> : undefined}
              />
            )
          case 'PAID':
            return (
              <TagStyle
                title="Clique para ver mais detalhes"
                onClick={() => this.redirectOnClick(i)}
                backgroundColor={green.laurel}
                label={'Pago'}
                iconBackground="none"
                iconColor={white.primary}
                icon={i.isRenegotiation ? <Sync /> : undefined}
              />
            )
          case 'PAYMENT_OVERDUE':
            return (
              <TagStyle
                title="Clique para ver mais detalhes"
                onClick={() => this.redirectOnClick(i)}
                backgroundColor={red.primary}
                label={'Vencida'}
                iconBackground="none"
                iconColor={white.primary}
                icon={i.isRenegotiation ? <Sync /> : undefined}
              />
            )
          default:
            return 'no status'
        }
      },
    },
    {
      attribute: 'method',
      label: 'Forma de Pgto',
      formatCell: (i: ISingleInstallment) => {
        if (!i.paymentTimestamp) {
          return '-'
        }
        switch (i.method) {
          case 'STORE':
            return 'Loja'
          case 'INVOICE':
            return 'Boleto'
          case 'RENEGOTIATION':
            return 'Renegociação'
          default:
            return '-'
        }
      },
    },
    {
      attribute: 'paymentTimestamp',
      label: 'Data do Pgto',
      formatCell: (i: ISingleInstallment) => (i.paymentTimestamp ? formatDateTreatingError(i.paymentTimestamp) : '-'),
    },
  ]

  getIdsToSimulate = () => {
    const elements = document.getElementsByName('ids')
    let installmentsToSimulate: string[] = []
    elements.forEach(element => {
      const inputElement: HTMLInputElement = element as HTMLInputElement
      if (inputElement.checked) {
        installmentsToSimulate.push(element.id)
      }
    })
    return installmentsToSimulate
  }

  simulateInvoices() {
    const idsToSimulate = this.getIdsToSimulate()
    if (this.props.installments && !(this.props.installments instanceof Error)) {
      if (this.props.installments.length === 0) {
        toaster.showErrorToast('Não há parcelas para simular.')
      } else if (idsToSimulate.length === 0) {
        toaster.showErrorToast('Escolha alguma parcela para simular.')
      } else {
        const installmentsToSimulate = this.props.installments.filter(inst => idsToSimulate.indexOf(inst.id) > -1)
        this.setState({ installmentsToSimulate })
        this.setState({ layer: true })
      }
    }
  }

  redirectOnClick = (installment: ISingleInstallment) => {
    this.props.history.push({ pathname: `/installment/${installment.id}`, state: { installment } })
  }

  public render() {
    const { installments, onFilterSelected, feeInstallments, feesTotalPaymentDue } = this.props
    const { installmentsToSimulate, numberOfSelectedInstallments, totalValue } = this.state
    const errorCard = (
      <Box margin={{ top: '20px' }}>
        <Text color="red" light>
          Não foi possível carregar os dados para este cliente.
        </Text>
      </Box>
    )

    const InstallmentsCard = (installments: ISingleInstallment[]) => (
      <DataTable
        header={this.columnsDropDown}
        data={installments}
        onFilterSelected={onFilterSelected}
        fontSize="14px"
      />
    )

    return (
      <Box>
        {this.state.layer ? (
          <StyledModal
            modalTtitle="Gerar boleto"
            height="large"
            width="xxlarge"
            round="large"
            isOpen={true}
            onClose={() => {
              this.setState({ layer: false })
            }}
          >
            <SimulationsBoxStyle overflow="auto">
              <MultipleInstallmentsSimulationCard
                feeInstallments={feeInstallments}
                installments={installmentsToSimulate}
                numberOfInstallments={numberOfSelectedInstallments}
              />
            </SimulationsBoxStyle>
          </StyledModal>
        ) : (
          <></>
        )}

        <Box>
          <CardStyle>
            {!installments ? (
              <Box fill align="center" justify="center">
                <Loading />
              </Box>
            ) : installments instanceof Error ? (
              errorCard
            ) : (
              InstallmentsCard(installments)
            )}
          </CardStyle>
          <Box direction="row" align="center" justify="around" width="90%" wrap={true}>
            <Box width="25%">
              <HelperTextStyle>*Clique nas tags para ver mais detalhes de uma parcela</HelperTextStyle>
            </Box>
            <SummaryBox
              valueToPay={totalValue}
              hasSelectedRows={numberOfSelectedInstallments > 0}
              hasFees={Boolean(feesTotalPaymentDue)}
            />
            <Button style={{ width: '150px' }} onClick={() => this.simulateInvoices()}>
              GERAR BOLETO
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }
}
