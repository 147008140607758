import React, { useState } from 'react'
import styled from 'styled-components'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { RiskBreadcrumbs } from '../../molecules/LimitsModificationPage/RiskBreadcrumbs'
import { Heading, Box } from 'grommet'
import { Button, Tooltip, Modal } from 'antd'
import XLSX from 'xlsx'
import { toaster } from '../../App'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { LimitsWarnings } from '../../organisms/LimitsModification/LimitsWarnings'
import { get_header_row } from '../../config/utils'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { IPostLimitsValidateResponse, IUpdateLimit } from '../../services/bff-backoffice/coordinator/interfaces/limits.interfaces'

interface ILimitsModificationsPageProps {
  history: any
  location: any
}

interface ILimitsModificationsPageState {
  isLoadingWarnings: boolean
  warnings?: IPostLimitsValidateResponse
  isPuttingNewLimits: boolean
  newLimitsJson?: IUpdateLimit[]
}

const initialState: ILimitsModificationsPageState = {
  isLoadingWarnings: false,
  isPuttingNewLimits: false,
}

export const LimitsModificationsPage = (props: ILimitsModificationsPageProps) => {
  const [state, setState] = useState<ILimitsModificationsPageState>(initialState)

  // Gets the inputted file from the user
  // - Validates it's format (header rows)
  // - Creates a json array
  // - Sends to server
  const handleSelectedFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, warnings: undefined, isLoadingWarnings: true })

    const files = e.target.files

    if (!files || files.length === 0) {
      setState({ ...state, isLoadingWarnings: false })
      return toaster.showErrorToast(`Selecione um arquivo`)
    }
    const file = files[0]

    const bufferData = await file.arrayBuffer()

    // Read the .xlsx file
    const data = XLSX.read(bufferData, { type: 'buffer' })

    // Get all sheet's names
    const sheetNames = data.SheetNames

    // No sheet or multiple sheets error
    if (sheetNames.length !== 1) {
      setState({ ...state, isLoadingWarnings: false })
      return toaster.showErrorToast(`Planilha deve ter uma Sheet!`)
    }

    // Get the worksheet
    const worksheet = data.Sheets[sheetNames[0]]

    // Get the header row
    const headerRow = get_header_row(worksheet)

    // Check if the header row is as expected
    const expectedHeadersArray = ['borrowerId', 'limitType', 'limitValue']
    for (const expectedHeader of expectedHeadersArray) {
      if (!headerRow.find(element => element === expectedHeader)) {
        setState({ ...state, isLoadingWarnings: false })
        return toaster.showErrorToast(`Coluna ${expectedHeader} faltando na tabela.`)
      }
    }

    let jsonFromTable: any[] = XLSX.utils.sheet_to_json(data.Sheets[sheetNames[0]])
    const jsonBody: any[] = []

    // Treat json from table into expected json for API
    for (let element of jsonFromTable) {
      const borrowerId = String(element.borrowerId)
      const limitType = element.limitType
      const limitValue = Number(element.limitValue)

      // NaN cases
      if (limitValue !== 0 && !limitValue) {
        setState({ ...state, isLoadingWarnings: false })
        return toaster.showErrorToast(`Um dos limites não é um número.`)
      }

      jsonBody.push({
        borrowerId,
        limitType,
        limitValue,
      })
    }

    bffBackofficeApiService.coordinator
      .validateLimits({ newLimits: jsonBody })
      .then(result => {
        toaster.showSuccessToast(`Planilha carregada com sucesso.`)
        setState({ ...state, warnings: result, isLoadingWarnings: false, newLimitsJson: jsonBody })
      })
      .catch(error => {
        if (error && error.response && error.response.data) {
          Modal.error({
            title: 'Erro ao submeter planilha',
            content: JSON.stringify(error.response.data),
          })
        } else {
          toaster.showErrorToast(`Erro no servidor`)
        }
        setState({ ...state, isLoadingWarnings: false })
      })
  }

  // Changes the event value to default, so the onChange event is triggered even if
  //   the same file is uploaded.
  const handleClickInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = ""
  }

  // Creates a template table and sends to user
  const handleDownloadTableTemplate = () => {
    const template = [
      { borrowerId: 1234, limitType: 'RECURRING_PURCHASE', limitValue: 800 },
      { borrowerId: 1234, limitType: 'BIG_PURCHASE', limitValue: 1500 },
    ]

    const sheet = XLSX.utils.json_to_sheet(template)
    XLSX.utils.sheet_add_json(sheet, [], { origin: 'A3' })

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, sheet)
    XLSX.writeFile(wb, 'limits_template.xlsx')
  }

  // Clear screen
  const clearScreen = () => {
    setState({ ...state, warnings: undefined, isLoadingWarnings: false, isPuttingNewLimits: false })
  }

  // Updates limits on the server!
  const handleConfirmButton = () => {
    setState({ ...state, isPuttingNewLimits: true })

    if (!state.newLimitsJson) {
      return
    }

    bffBackofficeApiService.coordinator
      .updateLimits({ newLimits: state.newLimitsJson })
      .then(() => {
        Modal.success({
          title: 'Limites alterados com sucesso!',
          maskClosable: true,
          centered: true,
        })
        clearScreen()
      })
      .catch(error => {
        if (error && error.response && error.response.data) {
          Modal.error({
            title: 'Erro ao submeter planilha',
            content: JSON.stringify(error.response.data),
          })
        } else {
          toaster.showErrorToast(`Erro no servidor`)
        }
      })
  }

  const { isLoadingWarnings, warnings, isPuttingNewLimits } = state
  const hasErrors = warnings && warnings.errors.length > 0 ? true : false

  return (
    <Container>
      <div style={{ marginTop: '3vh' }}>
        <RiskBreadcrumbs screenName='Modificação de Limites'/>
      </div>

      <Title>
        <Heading level={2}> Modificação de Limites </Heading>
        <DownloadTableTemplateButton type="primary" size="middle" onClick={handleDownloadTableTemplate}>
          Download template da planilha
        </DownloadTableTemplateButton>
      </Title>

      <Box direction="row" margin={{ top: '5vh' }}>
        <UploadText>
          Selecione uma planilha no formato <strong>.xlsx</strong> para validação
        </UploadText>

        <FileUpload type="file" accept=".xlsx" onChange={handleSelectedFile} onClick={handleClickInputFile} />
      </Box>

      <Box direction="row" margin={{ top: '5vh' }}>
        {isLoadingWarnings ? (
          <Box flex margin={{ top: '8vh' }} align="center" justify="center">
            <LoadingIcon />
          </Box>
        ) : (
          <LimitsWarnings limitsWarnings={warnings} />
        )}
      </Box>

      {warnings ? (
        <Box direction="row" margin={{ top: '3vh' }} align="center" justify="end" gap="medium">
          <Button onClick={clearScreen}> Cancelar </Button>
          <Tooltip title={hasErrors ? 'Corrija os erros notificados para efetivar as alterações' : ''}>
            <ConfirmButton
              onClick={handleConfirmButton}
              loading={isPuttingNewLimits}
              type="primary"
              disabled={hasErrors}
            >
              Efetivar alterações
            </ConfirmButton>
          </Tooltip>
        </Box>
      ) : (
        <></>
      )}
    </Container>
  )
}

const UploadText = styled.span`
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 19px;
`

const FileUpload = styled.input`
  outline: none;
  margin-left: 3vw;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${UMEColors.gray.azure};
  min-height: 94vh;
  padding: 16px 64px;
  width: 100%;

  text-align: initial;
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  margin-top: 3vh;
`
const DownloadTableTemplateButton = styled(Button)`
  display: inline-block;

  border: none;

  // TEXT
  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;

  // BUTTON
  border-radius: 2px;
  background-color: ${UMEColors.gray.primary};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
    transition: 0.3s;
    background-color: ${UMEColors.gray.primary};
    opacity: 0.5;
  }

  &:focus {
    background-color: ${UMEColors.gray.primary};
  }

  &:active {
    background-color: ${UMEColors.gray.primary};
  }
`

const ConfirmButton = styled(Button)`
  display: inline-block;

  border: none;

  // TEXT
  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;

  // BUTTON
  border-radius: 2px;
  background-color: ${UMEColors.green.dark};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
    transition: 0.3s;
    background-color: ${UMEColors.green.dark};
    opacity: 0.5;
  }

  &:focus {
    background-color: ${UMEColors.green.dark};
  }

  &:active {
    background-color: ${UMEColors.green.dark};
  }
`
