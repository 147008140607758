export const FloatMaskInput = [
  {
    regexp: /^[0-9]*$/,
  },
  {
    regexp: /^\.$/,
  },
  { regexp: /^[0-9]{1,2}$/ },
]

export const NumberOfInstallmentsMask = [
  {
    regexp: /^0+$/,
    options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18'],
  },
]

export const DateMaskInput = [
  {
    length: 2,
    // options: days,
    regexp: /^0[1-9]$|^1[0-9]$|^2[0-9]$|^3[0-1]|^[0-3]$/,
    placeholder: 'DD',
  },
  { fixed: '/' },
  {
    length: 2,
    // options: months,
    regexp: /^0[1-9]$|^1[0-2]$|^[0-1]$/,
    placeholder: 'MM',
  },
  { fixed: '/' },
  {
    length: 4,
    // TODO - Add Regex here
    options: ['2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026'],
    placeholder: 'YYYY',
  },
]
