import { Loading, Text, Clipboard } from '@bit/ume.design-kit.ui'
import { Box } from 'grommet'
import * as React from 'react'
import { CardStyle, GenerateInvoiceLoadingButton, BarCodeStyled, ConfirmationMessageStyle, DueDatePicker } from './style'
import moment from 'moment-timezone'
import { toaster } from '../../App'
import { getErrorStringForBillingsHttpErrors } from '../../organisms/FinancialPayments/config'
import { LabelStyle } from '../../pages/CS-Profile/ContractsSummaryCard/style'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Validate } from 'grommet-icons'
import { DataStyle, LinkAnchor } from '../../pages/CS-Profile/SimulationCard/style'
import {bffBackofficeApiService} from '../../services/bff-backoffice'
import { IInvoiceResponse } from '../../services/bff-backoffice/billings/interfaces/billings.interfaces'

export interface IGenerateInvoiceProps {
  billing?: any | Error
  emails?: string[]
}

export interface IGenerateInvoiceState {
  dueDate: string
  isLoadingInvoiceCreation: boolean
  disabled: boolean
  invoiceCreated?: IInvoiceResponse
}

const initialState : IGenerateInvoiceState = {
  dueDate: "",
  isLoadingInvoiceCreation: false,
  disabled: true,
}

export default class GenerateInvoice extends React.PureComponent<IGenerateInvoiceProps, IGenerateInvoiceState> {
  private isCreatingInvoice = false

  constructor(props: IGenerateInvoiceProps) {
    super(props)
    this.state = initialState
    this.isCreatingInvoice = false
  }

  componentDidMount() {
    // If billing is not expired you can't generate an invoice
    this.setState({ disabled: this.props.billing.status !== 'expired' })
  }

  // Creates a Billing from API
  createBillingFromAPI = () => {
    if (!this.props.billing) {
      toaster.showErrorToast("Não é possível gerar boleto para esta cobrança.")
      return
    }

    if (this.state.dueDate === "") {
      toaster.showErrorToast("Informe uma data de vencimento.")
      return
    }

    let startDate = this.props.billing.startDate
      ? moment(this.props.billing.startDate[0][0])
          .format('YYYY-MM-DD')
      : undefined
    let endDate = this.props.billing.endDate
      ? moment(this.props.billing.endDate[0][1])
          .format('YYYY-MM-DD')
      : undefined
    
    let invoiceDueDate = this.state.dueDate
      ? moment(this.state.dueDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : ''

    if (!startDate || !endDate) return

    this.setState({ isLoadingInvoiceCreation: true })

    if (this.isCreatingInvoice)
      return

    this.isCreatingInvoice = true

    bffBackofficeApiService.billings
      .createBillingInvoice(this.props.billing.id, invoiceDueDate)
      .then((createdBilling: IInvoiceResponse) => {
        //   Reset Screen
        this.setState({
          isLoadingInvoiceCreation: false,
          dueDate: "",
          invoiceCreated: createdBilling
        })

        this.isCreatingInvoice = false 

        toaster.showSuccessToast(`Cobrança criada com sucesso`)
      })
      .catch((error: any) => {
        this.setState({
          isLoadingInvoiceCreation: false,
        })

        this.isCreatingInvoice = false

        if (error && error.response && error.response.data) {
          let errorString = getErrorStringForBillingsHttpErrors(error.response.data.error, error)
          toaster.showErrorToast(errorString)
        } else {
          toaster.showErrorToast(`Erro ao criar a cobrança!`)
          console.error(error)
        }
      })
  }

  onDateSelected = (date: string) => {
    this.setState({ dueDate: date })
  }
  
  public render() {
    const { billing } = this.props
    let tomorrow = moment()
      .add(2, 'day')
      .format('YYYY-MM-DD')
    let oneMonthFromTomorrow = moment()
      .add(2, 'day')
      .add(1, 'month')
      .format('YYYY-MM-DD')

    const errorCard = (
      <Text color="red" light>
        Não é possível gerar boletos para esta cobrança.
      </Text>
    )

    const GenerateInvoiceCard = (billing: any) => (
      <>
        {
          this.state.invoiceCreated ?
            <>
            <Box direction="column" align="center" gap="small" justify="center" height="100%">
              <Box direction="row" align="center" gap="small">
                <Validate size="large" color={UMEColors.green.laurel} />
                <ConfirmationMessageStyle>Boleto Gerado</ConfirmationMessageStyle>
              </Box>
              <Box margin={{top: "20px"}} direction="column" gap="small">
                <DataStyle onClick={() => toaster.showSuccessToast("Copiado para área de transferência")}>
                  <Clipboard
                    width="175px" 
                    label={
                      <LinkAnchor target='_blank' href={this.state.invoiceCreated?.url + '.pdf'}>
                        Link do boleto
                      </LinkAnchor>
                    } 
                    value={this.state.invoiceCreated?.url + '.pdf'} 
                  />
                </DataStyle>

                <DataStyle onClick={() => toaster.showSuccessToast("Copiado para área de transferência")}>
                  <Clipboard 
                    width="175px" 
                    label ={
                      <BarCodeStyled>
                        Código do Boleto
                      </BarCodeStyled>
                    } 
                    value={this.state.invoiceCreated?.barcode} />
                </DataStyle>
              </Box>
            </Box>

            </>
          :
            this.state.disabled ? 
              <>
                <Box direction="column" align="center" justify="center" height="100%">
                  O último boleto não está vencido.
                </Box>
              </>
            :
              <>
                <Box direction="column">
                  <LabelStyle>Data de vencimento:</LabelStyle>
                  <DueDatePicker dueDateId={2} onDateSelect={this.onDateSelected} bounds={[tomorrow, oneMonthFromTomorrow]} />
                  <GenerateInvoiceLoadingButton 
                    label="Gerar boleto"
                    onClick={this.createBillingFromAPI}
                    isLoading={this.state.isLoadingInvoiceCreation}
                    disabled={this.state.disabled}
                  />
                </Box>
              </>
        }
      </>
    )
    return (
      <CardStyle height="100%">
        {billing === undefined ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : billing instanceof Error ? (
          errorCard
        ) : (
          GenerateInvoiceCard(billing)
        )}
      </CardStyle>
    )
  }
}
