import React, { Component } from 'react'
import imgHero from '../../../common/assets/images/security-concept-illustration.jpg'
import { gray, green, red } from '../../../common/assets/_colors'
import {bffBackofficeApiService} from '../../../services/bff-backoffice'
import {
  Button,
  ImgHero,
  InputPassword,
  ListItem,
  ParagraphTitle,
  PasswordCard,
  PasswordComponent,
  PasswordContainer,
  TitleContainer,
  UnorderedList,
} from './style'

export interface IPasswordProps {
  history?: any
}
export interface IPasswordState {
  password: string
  passwordConfirm: string
  passwordLengthColor: string
  passwordRepeatCorrectlyColor: string,
  isPasswordAllowedtoChange: boolean
}

export default class PasswordFirstAccess extends Component<IPasswordProps, IPasswordState> {
  constructor(props: IPasswordProps) {
    super(props)
    this.state = {
      password: '',
      passwordConfirm: '',
      passwordLengthColor: gray.primary,
      passwordRepeatCorrectlyColor: gray.primary,
      isPasswordAllowedtoChange: false
    }
  }

  handlePassword = (event: any) => {
    event.preventDefault()

    this.setState({ password: event.target.value })

    if (event.target.value.length > 3) {
      this.setState({ passwordLengthColor: green.dark})
      if (this.state.passwordConfirm === this.state.password){
        this.setState({ isPasswordAllowedtoChange: true })
      }
    } else {
      this.setState({ passwordLengthColor: red.primary, isPasswordAllowedtoChange: false })
    }
  }

  handleRepeatPassword = (event: any) => {
    event.preventDefault()
    if (event.target.value === this.state.password) {
      this.setState({
        passwordConfirm: event.target.value,
        passwordRepeatCorrectlyColor: green.dark,
      })
      if (this.state.password.length > 3){
        this.setState({ isPasswordAllowedtoChange: true })
      }
    } else {
      this.setState({
        passwordConfirm: event.target.value,
        passwordRepeatCorrectlyColor: red.primary,
      })
    }
  }

  handleSubmit = () => {
    bffBackofficeApiService.coordinator.resetBorrowerPassword()
      .then(() => {
        this.props.history.push('/login')
      })
      .catch(() => {
        alert(
          'Tente novamente, se persistir o problema fale com um de nossos analistas via Whatsapp clicando no ícone abaixo :)'
        )
      })
  }

  render() {
    const {
      passwordLengthColor,
      passwordRepeatCorrectlyColor,
      isPasswordAllowedtoChange,
    } = this.state

    return (
      <PasswordContainer>
        <ImgHero src={imgHero} alt={'security-banner-illustration'} loading="lazy" />
        <PasswordCard>
          <TitleContainer>
            <h2>Precisando de uma senha nova?</h2>
            <ParagraphTitle>Para sua segurança, escolha uma senha única e de fácil memorização.</ParagraphTitle>
          </TitleContainer>
          <PasswordComponent>
            <InputPassword onChange={this.handlePassword} placeholder="Nova Senha" type="password" />
            <InputPassword onChange={this.handleRepeatPassword} placeholder="Repita a Senha" type="password" />

            {isPasswordAllowedtoChange && <Button onClick={this.handleSubmit}>Confirmar</Button>}

            <UnorderedList>
              <ListItem className={'lengthColor'} passwordLengthColor={passwordLengthColor}>
                4 digitos
              </ListItem>
              <ListItem className={'repeatPasswordColor'} passwordRepeatCorrectlyColor={passwordRepeatCorrectlyColor}>
                Repetiu a senha corretamente
              </ListItem>
            </UnorderedList>
          </PasswordComponent>
        </PasswordCard>
      </PasswordContainer>
    )
  }
}
