import styled from 'styled-components'

export const TextGreenBackground = styled.p`
  background-color: green;
  color: white;
  margin: 0;
  padding: 0;
`

export const TextOrangeBackground = styled.p`
  background-color: orange;
  color: white;
  margin: 0;
  padding: 0;
`

export const TextRedBackground = styled.p`
  background-color: red;
  color: white;
  margin: 0;
  padding: 0;
`

export const SubHeader = styled.h2`
  background-color: #f0f0f0;
`
export const GreyText = styled.h2`
  color: grey;
`
export const RedText = styled.p`
  color: red;
`
export const GreenText = styled.p`
  color: green;
`