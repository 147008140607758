import { Loading, Text } from '@bit/ume.design-kit.ui'
import { Box } from 'grommet'
import * as React from 'react'
import { CardStyle, EmailTextInput, SendBillingEmailLoadingButton, DefaultEmailsContainer, ConfirmationMessageStyle } from './style'
import { LabelStyle } from '../../pages/CS-Profile/ContractsSummaryCard/style'
import { toaster } from '../../App'
import { emailIsValid } from '../../common/Validators'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Validate } from 'grommet-icons'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { IEmailResponse, IGetEmailResponse } from '../../services/bff-backoffice/billings/interfaces/billings.interfaces'

export interface ISendBillingEmailsProps {
  stores?: string[] | Error
  billing: any
}

export interface ISendBillingEmailsState {
  defaultEmails?: IEmailResponse[]
  optionalEmails: string[]
  isSendingEmails: boolean
  isEmailsSent: boolean
}

const MAX_NUMBER_OF_EMAILS = 3

const initialState : ISendBillingEmailsState = {
  optionalEmails: new Array(MAX_NUMBER_OF_EMAILS),
  defaultEmails: undefined,
  isSendingEmails: false,
  isEmailsSent: false
}

export default class SendBillingEmails extends React.PureComponent<ISendBillingEmailsProps, ISendBillingEmailsState> {
  constructor(props: ISendBillingEmailsProps) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    this.loadDefaultEmailsFromAPI()
  }

  // Loads the default emails of each store
  loadDefaultEmailsFromAPI = () => {
    if (this.props.stores === undefined || this.props.stores instanceof Error ) {
      this.setState({defaultEmails: []})
    } else {
      bffBackofficeApiService.billings
      .getEmailsFromStores(this.props.stores)
      .then((result: IGetEmailResponse) => {
        const emailStrings = result.emails.map(email => email.email)
        // Eliminate duplicates
        const defaultEmailsSet = result.emails.filter((email, i) => emailStrings.indexOf(email.email) === i)
        // While the number of emails default is less than MAX_NUMBER_OF_EMAILS, fill with '-'
        while (defaultEmailsSet.length < MAX_NUMBER_OF_EMAILS) {
          defaultEmailsSet.push({
            email:"-",
            id: -1, 
            storeId: -1
          })
        }
        this.setState({ defaultEmails: defaultEmailsSet })

      })
      .catch((error: any) => {
        this.setState({ defaultEmails: [] })
        toaster.showErrorToast(`Erro ao carregar emails de lojas`)
      })
    }
  }

  // On email changed
  onOptionalEmailChange = (email: string, index: number) => {
    if (index > MAX_NUMBER_OF_EMAILS) {
      return
    }
    const optionalEmails = this.state.optionalEmails
    optionalEmails[index] = email
    this.setState({optionalEmails})
  }

  // On email changed
  sendBillingEmails = () => {
    this.setState({ isEmailsSent: false, isSendingEmails: true })
    const emails : string[] = []
    let invalidEmail = undefined
    // Pushes all loaded default emails
    this.state.defaultEmails?.forEach(email => {
      if (email.email === "-") {
          return
      }
      emails.push(email.email)
    })

    // Push the aditional emails
    this.state.optionalEmails.forEach(email => {
      if (email) {
        if (emailIsValid(email)) {
          emails.push(email)
        } else {
          invalidEmail = email
          return
        }
      }
    })

    // If one of the aditional emails is invalid, show an error
    if (invalidEmail !== undefined) {
      toaster.showErrorToast(`Email ${invalidEmail} inválido.`)
      this.setState({ isEmailsSent: false, isSendingEmails: false })
      return
    }

    // If there is ono email, ask for one at least
    if (emails.length === 0) {
      toaster.showErrorToast('Digite pelo menos um email para enviar cobrança.')
      this.setState({ isEmailsSent: false, isSendingEmails: false })
      return
    }

    bffBackofficeApiService.billings
      .sendBillingEmail(this.props.billing.id, emails)
      .then((result) => {
        this.setState({ isEmailsSent: true, isSendingEmails: false })
        // After 3s return to de default card state
        setTimeout(() => {
          this.setState({ isEmailsSent: false })
        }, 3000)
      })
      .catch((error: any) => {
        this.setState({ isEmailsSent: false, isSendingEmails: false })
        toaster.showErrorToast(`Erro ao enviar cobrança.`)
      })
  }
  
  public render() {
    const { stores } = this.props

    const errorCard = (
      <Text color="red" light>
        Não é possível enviar esta cobrança.
      </Text>
    )

    const SendBillingEmailsCard = (stores: any) => (
      <>
        {
          this.state.isEmailsSent ?
            <>
              <Box direction="row" align="center" gap="small" justify="center" height="100%">
                <Validate size="large" color={UMEColors.green.laurel} />
                <ConfirmationMessageStyle>Cobrança Enviada</ConfirmationMessageStyle>
              </Box>
            </>
          :
            <>
              {
                this.state.defaultEmails ?
                  <>
                    <Box direction="row-responsive"  align="start" justify="start" gap="small">
                      <DefaultEmailsContainer>
                        <LabelStyle>Emails default</LabelStyle>
                        {
                          this.state.defaultEmails.length > 0 ? 
                            this.state.defaultEmails.map((email, i) => {
                              return <EmailTextInput 
                                key={i}
                                type="email" 
                                disabled
                                value = {email.email}
                              />
                            })
                          : 
                            <></>
                        }
                      </DefaultEmailsContainer>
                      <DefaultEmailsContainer>
                        <LabelStyle>Emails adicionais</LabelStyle>
                        {
                          this.state.optionalEmails.length > 0 ? 
                            this.state.defaultEmails.map((email, i) => {
                              return <EmailTextInput 
                                key={i}
                                type="email" 
                                placeholder="Digite um email"
                                onChange={(event: any) => this.onOptionalEmailChange(event.target.value, 0)}
                              />
                            })
                          : 
                            <></>
                        }
                      </DefaultEmailsContainer>
                    </Box>
                    <SendBillingEmailLoadingButton
                      label="Enviar"
                      onClick={this.sendBillingEmails}
                      isLoading={this.state.isSendingEmails}
                    />
                  </>
                :
                  <>
                    <Box direction="row" align="center" gap="small" justify="center" height = "150px">
                      <Loading />
                    </Box>
                  </>
              }
              
            </>
        }
        
      </>
    )

    return (
      <CardStyle height="100%">
        {stores === undefined ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : stores instanceof Error ? (
          errorCard
        ) : (
          SendBillingEmailsCard(stores)
        )}
      </CardStyle>
    )
  }
}
