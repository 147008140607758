import { message } from 'antd'
import './AppToaster.css'

export class AppToaster {
  showSuccessToast = (stringMessage: string) => {
    message.success({ content: stringMessage, className: 'toaster-div success-toaster', duration: 2 })
  }

  showErrorToast = (stringMessage: string) => {
    message.error({ content: stringMessage, className: 'toaster-div error-toaster', duration: 2 })
  }

  showWarningToast = (stringMessage: string) => {
    message.warning({ content: stringMessage, className: 'toaster-div warning-toaster', duration: 2 })
  }
}
