import styled from 'styled-components'
import { laptop } from '../../../common/assets/_breakpoints'
import { blue, gray, white } from '../../../common/assets/_colors'
import { large, medium, small, xlarge, xsmall } from '../../../common/assets/_sizes'

const PasswordCard = styled.div`
  width: auto
  height: 500px
  background-color: hsla(120, 60%, 100%, 0.7);

  @media (min-width: ${laptop}) { 
    width: 60%;
    float: right;
    font-size: initial
  }
`

const PasswordContainer = styled.div`
  @media (min-width: ${laptop}) {
    background-color: hsla(120, 60%, 100%, 0.7);
  }
`

const TitleContainer = styled.div`
  padding: ${xlarge};
`

const ParagraphTitle = styled.p`
  color: ${gray.primary};
  font-size: smaller;
`

const InputPassword = styled.input`
  margin-bottom: ${small};
`

const Button = styled.button`
  background-color: ${blue.dark};
  border: none;
  color: ${white.primary};
  padding: ${medium} ${large};
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: ${large};
  margin: ${small} ${xsmall};
  cursor: pointer;
`

const UnorderedList = styled.ul`
  text-align: left;

  @media (min-width: ${laptop}) {
    margin-top: ${xlarge};
  }
`

const PasswordComponent = styled.div`
  display: inline-grid;

  @media (min-width: ${laptop}) {
    display: flex;
    flex-direction: column;
    place-items: center;
  }
`

const ListItem = styled.li`
  font-size: smaller;
  font-style: italic;

  &.lengthColor {
    color: ${(props: { passwordLengthColor: any }) => props.passwordLengthColor};
  }

  &.capitalLetterColor {
    color: ${(props: { passwordCapitalLetterColor: any }) => props.passwordCapitalLetterColor};
  }

  &.lowerCaseColor {
    color: ${(props: { passwordLowerLetterColor: any }) => props.passwordLowerLetterColor};
  }

  &.numberColor {
    color: ${(props: { passwordNumberColor: any }) => props.passwordNumberColor};
  }

  &.specialCaracterColor {
    color: ${(props: { passwordSpecialCaracterColor: any }) => props.passwordSpecialCaracterColor};
  }

  &.repeatPasswordColor {
    color: ${(props: { passwordRepeatCorrectlyColor: any }) => props.passwordRepeatCorrectlyColor};
  }
`

const ImgHero = styled.img`
  display: none;
  @media (min-width: ${laptop}) {
    display: inline;
    height: 500px;
  }
`

export {
  Button,
  InputPassword,
  ParagraphTitle,
  PasswordCard,
  PasswordComponent,
  PasswordContainer,
  TitleContainer,
  UnorderedList,
  ListItem,
  ImgHero,
}
