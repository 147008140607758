import styled from 'styled-components'

export const WhiteContainer = styled.div`
  background-color: white;
`

export const OverflowXContainer = styled.div`
  overflow-x: auto;
  width: 100%;
`

export const GenericTableContainer = styled.div`
  overflow-x: auto;
  width: 100%;

  span {
    font-size: 18px;
    line-height: 24px;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
    width: inherit;
    margin: auto;
  }

  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }

  thead tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  tr:nth-child(even){
    background-color: #F0F0F0;
  }

  ${(props: any) =>
    !Boolean(props.disableHover) &&
    `
    tr:hover {
        background-color: blue;
        color: white;
        transition: 0.3s;
        cursor: pointer;
    }
  `}

  th {
    margin: 0;
    padding: 0;
    font-weight: inherit;
    text-align: inherit;
    height: 100%;
    text-align: center;
    border-bottom: solid 1px rgba(0, 0, 0, 0.33);
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  td {
    margin: 0;
    padding: 0;
    font-weight: inherit;
    text-align: inherit;
    height: 100%;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
`

export const FlexCenterContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`
