import { OriginationsConfig } from './originations.config'
import { PaymentsConfig } from './payments.config'
import { OriginationsOperationsConfig } from './originations-operations.config'
import { RankingOperatorsConfig } from './ranking-operators.config'
import { ApplicationsConfig } from './applications.config'
import { RankingStoresConfig } from './ranking-stores.config'
import { InstallmentsOperationsConfig } from './installments-operations.config'

export const Configs : any = {
    'Originações': OriginationsConfig,
    'Pagamentos': PaymentsConfig,
    'Originações (operações)': OriginationsOperationsConfig,
    'Ranking Operadores': RankingOperatorsConfig,
    'Applications': ApplicationsConfig,
    'Ranking Lojas': RankingStoresConfig,
    'Vencimentos': InstallmentsOperationsConfig
}
