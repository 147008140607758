import React from 'react'
import { formatNumberToBRL } from '../../../utils'
import { green, white } from '../../../utils/colors'
import { TextSummary } from './TextSummary'
import { ValueSummary } from './ValueSummary'

interface SummaryBoxProps {
  valueToPay: number
  hasSelectedRows: boolean
  hasFees: boolean
}

const setSummaryBoxStyle = (hasSelectedRow: boolean, hasFees: boolean) => {
  const cardStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    flexWrap: `wrap`,
    justifyContent: 'space-around',
    width: '30%',
    height: '5rem',
    gap: '0.5rem',
  }

  if (!hasFees) return cardStyle

  if (hasSelectedRow) {
    return {
      ...cardStyle,
      width: '45%',
      backgroundColor: green.primary300,
      border: `1px solid ${green.primary300}`,
      color: white.primary,
    }
  }

  return {
    ...cardStyle,
    width: '45%',
    backgroundColor: green.lightMint,
    border: `1px solid ${green.primary300}`,
    color: green.primary600,
  }
}

enum SummaryCardTexts {
  AVAILABLE_FEE = 'Taxa de utilização está disponível para pagamento',
  FEE_INCLUDED_IN_PAYMENT = 'Taxa de utilização inclusa*',
  NUMBER_OF_GENERATED_INVOICE_LABEL = 'Será gerado:',
  TOTAL_VALUE_LABEL = 'Total de:',
  NUMBER_OF_GENERATED_INVOICE = '1 Boleto',
}

export const SummaryBox = ({ hasFees, valueToPay, hasSelectedRows }: SummaryBoxProps) => {
  return (
    <div style={setSummaryBoxStyle(hasSelectedRows, hasFees)}>
      {hasFees && !hasSelectedRows && (
        <div
          style={{
            display: 'flex',
            width: '35%',
            alignItems: 'center',
          }}
        >
          <TextSummary isTextLabel={false} text={SummaryCardTexts.AVAILABLE_FEE} hasSelectedRows={hasSelectedRows} />
        </div>
      )}
      {hasFees && hasSelectedRows && (
        <TextSummary
          isTextLabel={false}
          text={SummaryCardTexts.FEE_INCLUDED_IN_PAYMENT}
          hasSelectedRows={hasSelectedRows}
        />
      )}
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          columnGap: '0.5rem',
        }}
      >
        <TextSummary
          isTextLabel={true}
          text={SummaryCardTexts.NUMBER_OF_GENERATED_INVOICE_LABEL}
          hasSelectedRows={hasSelectedRows}
        />
        <ValueSummary
          hasFees={hasFees}
          hasSelectedRows={hasSelectedRows}
          text={SummaryCardTexts.NUMBER_OF_GENERATED_INVOICE}
        />
      </div>
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          columnGap: '0.5rem',
        }}
      >
        <TextSummary isTextLabel={true} text={SummaryCardTexts.TOTAL_VALUE_LABEL} hasSelectedRows={hasSelectedRows} />
        <ValueSummary hasFees={hasFees} hasSelectedRows={hasSelectedRows} text={formatNumberToBRL(valueToPay)} />
      </div>
    </div>
  )
}
