import { formatCPF } from '../../../utils'

const rankingOperatorsHeader = [
  { property: 'name', label: 'Operador' },
  { property: 'storeName', label: 'Loja' },
  { property: 'retailerName', label: 'Varejista' },
  { property: 'email', label: 'Email' },
  { property: 'phoneNumber', label: 'Telefone' },
  { property: 'role', label: 'Role' },
  { property: 'cpf', label: 'CPF', format: formatCPF },
  { property: 'applications', label: 'Applications' },
]

export const RankingOperatorsConfig = {
  label: 'Ranking Operadores',
  route: '/operators',
  header: rankingOperatorsHeader,
}
