import { Card, Tag, Text } from '@bit/ume.design-kit.ui'
import { blue, gray } from '@bit/ume.design-kit.ui/Palette'
import { Box } from 'grommet'
import styled from 'styled-components'

export const StatusTagStyle = styled(Tag)`
  &:nth-child(1) {
    padding: 4px 6px;
    height: 22px;
    width: 88px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    justify-content: start;
  }
  &:nth-child(2) {
    margin-left: 12px;
  }
`

export const TableBoxStyle = styled(Card)`
  box-shadow: none;
  border: none;
  padding: 12px 2px;
  background-color: transparent;

  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${gray.gainsboro};
    border: 2px solid ${gray.azure};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${gray.eclipse};
    border-radius: 9px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${gray.nobel};
  }
`

export const LinkAnchorStyle = styled.a`
  color: ${blue.navy};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-decoration: underline;

  &:hover {
    color: white;
  }
`

export const LabelStyle = styled(Text)`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  color: ${gray.nobel};
  margin-bottom: 4px;
  margin-top: 0;
`
export const InfoTextStyle = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
`
export const DifferenceTextStyle = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
`

export const CardStyle = styled(Card)`
  border-radius: 8px;
  padding: 24px;
  height: 168px;
`

export const DataBoxStyle = styled(Box)`
  margin: 2px 8px 12px 8px;
`
