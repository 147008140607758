const rankingStoresHeader = [
  { property: 'storeId', label: 'Id' },
  { property: 'retailerName', label: 'Varejista' },
  { property: 'name', label: 'Loja' },
  { property: 'zipCode', label: 'CEP' },
  { property: 'applications', label: 'Applications' },
]

export const RankingStoresConfig = {
  label: 'Ranking Stores',
  route: '/stores',
  header: rankingStoresHeader,
}
