import React from "react";
import { FormClose, Filter } from "grommet-icons";
import { Box } from "grommet";
import { StatusTag, Component, Container } from '../Prioritization/styles'
import { Heading, Dropdown } from "@bit/ume.design-kit.ui";
import { orange, blue, red, white } from "@bit/ume.design-kit.ui/utils/_colors";
import { PENDING_TAGS, OVERDUE_TAGS } from './tags'
import { Button } from '@bit/ume.design-kit.ui'

interface IMultiTagProps {
  tags: string[]
  onPickTag?: (options: any) => void
  onClearTags?: () => void
  onRemoveTag?: any | any[]
  value?: any | any[]
  tagOptions: string[]
}

const MultiTag = (props: IMultiTagProps) => {
  const renderTags = (tag: any | any[]) => {
    let color = null
    PENDING_TAGS.forEach((tagName: string) => {
      if (tagName === tag) {
        color = blue.bay
      }
    })
    OVERDUE_TAGS.forEach((tagName: string) => {
      if (tagName === tag) {
        color = red.persian
      }
    })

    return <StatusTag
      onClick={(event: { preventDefault: () => void; stopPropagation: () => void; }) => {
        event.preventDefault()
        event.stopPropagation()
        props.onRemoveTag(tag)
      }}
      index={`season_tag_${tag}`}
      key={`tag_tag_${tag}`}
      label={`#${tag}`}
      icon={<FormClose size="small" />}
      backgroundColor={color}
    />
  }

  const renderOption = (option: any | any[], state: any | any[]) => (
    <Box pad="small" background={state.active ? "active" : undefined}>
      {option}
    </Box>
  )

  return (
    <Container>
      <Component>
        <div style={{display: 'flex', justifyContent: 'space-between', width: '-webkit-fill-available'}}>
          <div  style={{display: 'flex'}}>
            <Filter color={orange.amber} />
            <Heading level={3} size="small" margin="0 8px">
              Tags
            </Heading>
          </div>
          <div>
            <Button onClick={props.onClearTags} color={blue.navy} backgroundColor={white.primary} label="Limpar" border="none" margin="0" />
          </div>
        </div>
      </Component>
      <Component>
      </Component>
      <Dropdown
        value={
          <Box wrap direction="row">
            {props.tags && props.tags.length ? (
              props.tags.map((tag: any) => {
                  return renderTags(tag)
              })
            ) : (
              <Box
                pad={{ vertical: "xsmall", horizontal: "small" }}
                margin="xsmall"
                align="start"
              >
                Selecione Tags
              </Box>
            )}
          </Box>
        }
        options={props.tagOptions}
        onChange={props.onPickTag}
      >
        {renderOption}
      </Dropdown>
    </Container>
  );
};

export default MultiTag