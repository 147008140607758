import { IBorrowerLimit } from '../../../services/bff-backoffice/coordinator/interfaces/borrower.interfaces'
import { IOriginationSummary } from '../../../services/bff-backoffice/coordinator/interfaces/contracts.interfaces'
import { ISingleInstallment } from '../../../services/bff-backoffice/coordinator/interfaces/installment.interfaces'
import { formatDate, formatNumberToBRL } from '../../../utils'
import { IBorrowerSummaryProfile, IContractUI } from '../BorrowerProfilePage'

export const summaryTextToCopy = (
  borrower: IBorrowerSummaryProfile,
  installments: ISingleInstallment[],
  contracts: IContractUI[],
  limits: IBorrowerLimit[],
  contractsSummary: IOriginationSummary
) => {
  const pendingDueInstallmentsCount = installments.filter(i => i.status === 'PENDING').length
  const overdueInstallmentsCount = installments.filter(i => i.status === 'PAYMENT_OVERDUE').length
  const overdueInstallmentsSum = contractsSummary.sumOverdueInstallmentsValue

  const introText = `\nO seu saldo devedor atualizado hoje é de ${formatNumberToBRL(
    contractsSummary.sumPendingInstallmentsValue
  )}. Você tem ${pendingDueInstallmentsCount} parcela${pendingDueInstallmentsCount === 1 ? '' : 's'} à vencer${
    overdueInstallmentsCount > 0
      ? ` e ${overdueInstallmentsCount} ${
          overdueInstallmentsCount === 1 ? 'parcela vencida' : 'parcelas vencidas'
        } no valor total de ${formatNumberToBRL(overdueInstallmentsSum)} se pago hoje.`
      : '.'
  }\n`

  let contractsText = '\n'

  for (const contract of contracts) {
    const pendingInstallmentsByContract = installments
      .filter(i => i.contractId === contract.id)
      .filter(i => i.status !== 'PAID')
    const pendingInstallmentsByContractCount = pendingInstallmentsByContract.length
    const pendingInstallmentsByContractSum = pendingInstallmentsByContract.reduce((a, b) => {
      const value = b.status === 'PAYMENT_OVERDUE' ? b.paymentDue : b.installmentValue
      return a + value
    }, 0)

    let installmentsText = '\n'

    for (const installment of pendingInstallmentsByContract) {
      installmentsText = installmentsText.concat(
        installment.status === 'PAYMENT_OVERDUE'
          ? `Parcela ${installment.installmentOrder} venceu em ${formatDate(
              installment.dueDate
            )} e está com o valor atual de ${formatNumberToBRL(installment.paymentDue)}.\n`
          : `Parcela ${installment.installmentOrder} à vencer em ${formatDate(
              installment.dueDate
            )} no valor de ${formatNumberToBRL(installment.installmentValue)}.\n`
      )
    }

    contractsText = contractsText.concat(
      `Da sua compra feita dia ${formatDate(contract.createdOn)} no varejista parceiro ${
        contract.retailerName
      }, você tem em aberto ${
        pendingInstallmentsByContractCount === 1 ? 'a seguinte parcela' : 'as seguintes parcelas'
      }:\n` +
        installmentsText +
        `${
          pendingInstallmentsByContractCount === 1
            ? '\n'
            : `\nValor total destas ${pendingInstallmentsByContractCount} parcelas é de ${formatNumberToBRL(
                pendingInstallmentsByContractSum
              )}.\n\n`
        }`
    )
  }

  const bigPurchaseLimit = limits.find(l => l.limitType === 'BIG_PURCHASE')
  const recurringLimit = limits.find(l => l.limitType === 'RECURRING_PURCHASE')

  const limitsText = `RECORRENTE: ${
    recurringLimit
      ? `Você tem ${formatNumberToBRL(recurringLimit?.approvedLimit || 0)} de limite aprovado e ${formatNumberToBRL(
          recurringLimit?.availableLimit || 0
        )} de limite ainda disponível para utilizar.`
      : 'Não há limite aprovado.'
  }\nGRANDES COMPRAS: ${
    bigPurchaseLimit
      ? `Você tem ${formatNumberToBRL(bigPurchaseLimit?.approvedLimit || 0)} de limite aprovado e ${formatNumberToBRL(
          bigPurchaseLimit?.availableLimit || 0
        )} de limite ainda disponível para utilizar.`
      : `Não há limite aprovado.`
  }`

  return `Sr.(a) ${borrower.name.trim()}, segue um resumo do seu crediário.\n` + introText + contractsText + limitsText
}
