import { Breadcrumbs, Heading, LoadingScreen } from '@bit/ume.design-kit.ui'
import { Box } from 'grommet'
import { FormPreviousLink } from 'grommet-icons'
import * as React from 'react'
import {
  InstallmentDetails,
  InstallmentPayments,
  InstallmentStatus,
  InstallmentValues,
  InvoicesHistory,
} from '../../molecules/InstallmentDetails'
import { InvoicesTable } from '../../molecules/InstallmentDetails/InvoicesTable'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { ISingleInstallment } from '../../services/bff-backoffice/coordinator/interfaces/installment.interfaces'
import { IInvoice } from '../../services/bff-backoffice/coordinator/interfaces/invoice.interfaces'
import { InstallmentDetailsBox, PreviousButtonStyle, SectionTitleStyle } from './style'

export interface IInstallmentDetailsPageProps {
  history?: any
  match?: any
  location?: any
}

export interface IInstallmentDetailsPageState {
  installmentId?: string
  installment?: ISingleInstallment | Error
  multipleInvoices?: IInvoice[] | Error
  uniqueInvoices?: IInvoice[] | Error
}

const initialState: IInstallmentDetailsPageState = {}

export default class InstallmentDetailsPage extends React.Component<
  IInstallmentDetailsPageProps,
  IInstallmentDetailsPageState
> {
  constructor(props: IInstallmentDetailsPageProps) {
    super(props)

    this.state = initialState
  }

  componentDidMount() {
    const { installmentId } = this.props.match.params
    const { installment } = this.props.history.location.state || {}

    document.body.scrollTop = 0

    this.setState({ installmentId, installment })
    !installment && this.loadInstallment(installmentId)
    this.loadInstallmentInvoices(installmentId)
  }

  loadInstallmentInvoices = (installmentId: string) => {
    bffBackofficeApiService.coordinator
      .getInvoices({ installmentsIds: [installmentId] })
      .then(invoices => {
        const multipleInvoices: IInvoice[] = [],
          uniqueInvoices: IInvoice[] = []
        invoices.forEach(invoice => {
          if (invoice.installmentsValues.length === 1) {
            uniqueInvoices.push(invoice)
          } else {
            multipleInvoices.push(invoice)
          }
        })
        this.setState({ uniqueInvoices, multipleInvoices })
      })
      .catch(() => {
        this.setState({ uniqueInvoices: new Error(), multipleInvoices: new Error() })
      })
  }

  loadInstallment = (installment: string) => {
    bffBackofficeApiService.coordinator
      .getContractInstallmentById(installment)
      .then(data => {
        this.setState({ installment: data })
      })
      .catch(() => {
        this.setState({ installment: new Error() })
        return this.props.history.push('/*')
      })
  }

  onPreviousLinkClick = () => {
    this.props.history.go(-1)
  }

  public render() {
    const { installment, installmentId, uniqueInvoices, multipleInvoices } = this.state

    if (!installment) {
      return <LoadingScreen />
    }

    return (
      <InstallmentDetailsBox fill>
        <Box margin={{ horizontal: 'medium', top: 'small' }}>
          <Breadcrumbs anchors={['Home', 'Priorização', 'Parcelas', 'ID:' + installmentId]} />
        </Box>

        <Box margin={{ horizontal: 'small', vertical: 'medium' }} direction="row" align="center" gap="xsmall">
          <PreviousButtonStyle
            onClick={this.onPreviousLinkClick}
            width="32px"
            height="32px"
            icon={<FormPreviousLink color="white" />}
          />
          <Heading size="12px">Voltar</Heading>
        </Box>

        <Box gap="medium">
          <Box gap="xsmall">
            <Box margin={{ horizontal: 'medium' }} direction="row" align="center" gap="medium">
              <SectionTitleStyle>Detalhes da parcela</SectionTitleStyle>
              <InstallmentStatus installment={installment} />
            </Box>
            <InstallmentDetails installment={installment} />
          </Box>

          <Box direction="row-responsive" gap="medium">
            <Box>
              <Box margin={{ horizontal: 'medium' }}>
                <SectionTitleStyle>Valores</SectionTitleStyle>
              </Box>
              <InstallmentValues installment={installment} />
            </Box>
            <Box>
              <Box margin={{ horizontal: 'medium' }}>
                <SectionTitleStyle>Pagamentos</SectionTitleStyle>
              </Box>
              <InstallmentPayments installment={installment} />
            </Box>
          </Box>

          <Box gap="medium">
            <Box>
              <Box margin={{ horizontal: 'medium' }}>
                <SectionTitleStyle>Histórico de boletos únicos desta parcela</SectionTitleStyle>
              </Box>
              <InvoicesHistory invoices={uniqueInvoices} />
            </Box>
          </Box>

          <Box gap="medium">
            <Box>
              <Box margin={{ horizontal: 'medium' }}>
                <SectionTitleStyle>Histórico de boletos múltiplos desta parcela</SectionTitleStyle>
              </Box>
              <InvoicesTable invoices={multipleInvoices} />
            </Box>
          </Box>
        </Box>
      </InstallmentDetailsBox>
    )
  }
}
