import React from 'react'
import { BreadcrumbsBox, BreadcrumbsItem } from './styles'

const Breadcrumbs = (props: any) => {
  return(
    <BreadcrumbsBox>
      <BreadcrumbsItem>Ínício</BreadcrumbsItem>
      <BreadcrumbsItem>/</BreadcrumbsItem>
      <BreadcrumbsItem active>Priorização</BreadcrumbsItem>
    </BreadcrumbsBox>
  )
}

export { Breadcrumbs }