import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import {
  IRetailerBenchApplicationOptionsResponse,
  RetailersBenchResponse,
} from '../../../services/bff-backoffice/application-engine/interfaces/application-engine.interfaces'

export type IRetailersBenchState = {
  /************* FETCH RETAILERS BENCH *************/
  retailersBench?: RetailersBenchResponse
  isLoadingRetailersBench: boolean
  fetchRetailersBenchError?: AxiosResponse

  /************* FETCH RETAILER BENCH APPLICATIONS *************/
  retailerBenchApplications?: IRetailerBenchApplicationOptionsResponse[]
  isLoadingRetailerBenchApplications: boolean
  fetchRetailerBenchApplicationsError?: AxiosResponse

  /************* ENABLE RETAILER *************/
  isLoadingEnableRetailer: boolean
  fetchEnableRetailerError?: AxiosResponse

  /************* DISABLE RETAILER *************/
  isLoadingDisableRetailer: boolean
  fetchDisableRetailerError?: AxiosResponse
}

const initialState: IRetailersBenchState = {
  isLoadingRetailersBench: false,
  isLoadingRetailerBenchApplications: false,
  isLoadingDisableRetailer: false,
  isLoadingEnableRetailer: false,
}

export const RetailersBenchSliceReducer = createSlice({
  name: 'retailers-bench',
  initialState,
  reducers: {
    /************* FETCH RETAILERS BENCH *************/
    fetchRetailersBench: (state, action: PayloadAction<undefined>) => {
      // Used in Redux Saga
      return state
    },
    fetchRetailersBenchLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingRetailersBench: true }
    },
    fetchRetailersBenchSuccess: (state, action: PayloadAction<RetailersBenchResponse>) => {
      const retailersBench = action.payload
      return { ...state, retailersBench, isLoadingRetailersBench: false, fetchRetailersBenchError: undefined }
    },
    fetchRetailersBenchError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      //   const message = transformHttpApplicationsErrorsToString(error)
      //   toaster.showErrorToast(message)
      return {
        ...state,
        retailersBench: undefined,
        fetchRetailersBenchError: error.response,
        isLoadingRetailersBench: false,
      }
    },

    /************* FETCH RETAILER BENCH APPLICATIONS *************/
    fetchRetailerBenchApplications: (state, action: PayloadAction<string>) => {
      // Used in Redux Saga
      return state
    },
    fetchRetailerBenchApplicationsLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingRetailerBenchApplications: true }
    },
    fetchRetailerBenchApplicationsSuccess: (
      state,
      action: PayloadAction<IRetailerBenchApplicationOptionsResponse[]>
    ) => {
      const retailerBenchApplications = action.payload
      return {
        ...state,
        retailerBenchApplications,
        isLoadingRetailerBenchApplications: false,
        fetchRetailerBenchApplicationsError: undefined,
      }
    },
    fetchRetailerBenchApplicationsError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      //   const message = transformHttpApplicationsErrorsToString(error)
      //   toaster.showErrorToast(message)
      return {
        ...state,
        retailerBenchApplications: undefined,
        fetchRetailerBenchApplicationsError: error.response,
        isLoadingRetailerBenchApplications: false,
      }
    },

    /************* ENABLE RETAILER *************/
    enableRetailer: (state, action: PayloadAction<string>) => {
      // Used in Redux Saga
      return state
    },
    enableRetailerLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingEnableRetailer: true }
    },
    enableRetailerSuccess: (state, action: PayloadAction<void>) => {
      return {
        ...state,
        isLoadingEnableRetailer: false,
        enableRetailerError: undefined,
      }
    },
    enableRetailerError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      return {
        ...state,
        enableRetailerError: error.response,
        isLoadingEnableRetailer: false,
      }
    },

    /************* DISABLE RETAILER *************/
    disableRetailer: (state, action: PayloadAction<string>) => {
      // Used in Redux Saga
      return state
    },
    disableRetailerLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingDisableRetailer: true }
    },
    disableRetailerSuccess: (state, action: PayloadAction<void>) => {
      return {
        ...state,
        isLoadingDisableRetailer: false,
        disableRetailerError: undefined,
      }
    },
    disableRetailerError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      return {
        ...state,
        disableRetailerError: error.response,
        isLoadingDisableRetailer: false,
      }
    },
  },
})
