import { formatNumberToBRL, formatDate, formatCPF, booleanToSimOrNao } from '../../../utils'

const paymentsHeader = [
  { property: 'createdOn', label: 'Data', format: formatDate },
  { property: 'contractId', label: 'ID Contrato' },
  { property: 'borrowerId', label: 'ID do Tomador' },
  { property: 'phoneNumber', label: 'Telefone' },
  { property: 'cpf', label: 'CPF', format: formatCPF },
  { property: 'name', label: 'Nome Tomador' },
  { property: 'originationTimestamp', label: 'Data Originação', format: formatDate },
  { property: 'principal', label: 'Principal', format: formatNumberToBRL },
  { property: 'iof', label: 'IOF', format: formatNumberToBRL },
  { property: 'financedValue', label: 'Valor Financiado', format: formatNumberToBRL },
  { property: 'numberOfInstallments', label: 'Número de Parcelas' },
  { property: 'installmentValue', label: 'Valor Parcela', format: formatNumberToBRL },
  { property: 'retailerInstallment', label: 'Parcela Varejista', format: formatNumberToBRL },
  { property: 'mdr', label: 'MDR', format: formatNumberToBRL },
  { property: 'revolvingAdvancementFee', label: 'Aditivo Rotativo', format: formatNumberToBRL },
  { property: 'installmentAdvancementFee', label: 'Aditivo Parcelas', format: formatNumberToBRL },
  { property: 'fixedAdvancementFee', label: 'Aditivio Fixo', format: formatNumberToBRL },
  { property: 'contractAlignmentFeeRetained', label: 'Alinhamento Retido', format: formatNumberToBRL },
  { property: 'depositDueToInternalRetailerAccount', label: 'Deposito conta Onete', format: formatNumberToBRL },
  { property: 'depositDueToExternalRetailerAccount', label: 'Deposito Varejista', format: formatNumberToBRL },
  { property: 'retailerName', label: 'Varejista' },
  { property: 'financialInstitutionFee', label: 'Fee Instituição Financeira', format: formatNumberToBRL },
  { property: 'partnerProposalId', label: 'Proposta BMP' },
  { property: 'interest', label: 'Juros', format: formatNumberToBRL },
  { property: 'lastBMPStatus', label: 'Último Status BMP' },
  { property: 'lastBMPNotificationTs', label: 'Última Notificação BMP', format: formatDate },
  { property: 'isRenegotiation', label: 'Renegociação', format: booleanToSimOrNao },
  { property: 'renegotiationDiscount', label: 'Desconto de Renegociação', format: formatNumberToBRL },
  { property: 'upfrontPayment', label: 'Valor da entrada', format: formatNumberToBRL },
]

export const OriginationsConfig = {
  label: 'Originações',
  route: '/originations',
  header: paymentsHeader,
}
