import React from 'react'
import XLSX from 'xlsx'
import styled from 'styled-components'
import { ReactComponent as CabinetBaseIcon } from '../../common/assets/images/bx-cabinet.svg'
import { ReactComponent as StoreBaseIcon } from '../../common/assets/images/bx-store.svg'
import { FinancialFilter } from '../../molecules/FinancialPayments/FinancialFilter'
import { Calendar, Text, TextInput } from 'grommet'
import moment from 'moment-timezone'
import { toaster } from '../../App'
import { screenSize } from '../../config/screens.config'
import { Tag } from '../../molecules/FinancialPayments/Tag'
import { css } from 'styled-components'
import { UMEResumeCard } from '../../molecules/FinancialPayments/BillingResumeCard'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import GrommetDatePicker from '../../molecules/DatePicker/GrommetDatePicker'
import { getRandomColor } from '../../utils'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { AxiosError } from 'axios'
import { getErrorStringForBillingsHttpErrors } from './config'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { IBillingResponse, IBillingSimulation, IEmailResponse } from '../../services/bff-backoffice/billings/interfaces/billings.interfaces'
import { IRetailerResponse, IRetailersResponse, IStoreResponse, IStoresResponse } from '../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'

const RETAILER_STEP = 0
const DATES_STEP = 1
const RESUME_STEP = 2
const FINAL_STEP = 3
const MAX_NUMBER_OF_EMAILS = 3

const ResumeCard = {
  description: 'Intervalo selecionado',
  quantityText: 'Número de parcelas',
  priceText: 'Valor financeiro',
}

interface ICreateBillingStoryLineState {
  isRetailerSelected: boolean
  storesName: string[]
  selectedStores: {
    name: string
    backgroundColor: string
    textColor: string
  }[]
  retailersName: string[]
  currentStep: number
  selectedDates?: string[][]
  simulatedBillingCard: {
    quantity: number
    price: number
  }
  loadingSimulation: boolean
  invoiceDueDate?: string
  creatingBilling: boolean
  defaultEmails: IEmailResponse[]
}

interface ICreateBillingStoryLineProps {
  onBillingCreated?: (billing: any) => void
}

export default class CreateBillingStoryLine extends React.Component<
  ICreateBillingStoryLineProps,
  ICreateBillingStoryLineState
> {
  private retailers?: IRetailerResponse[]
  private stores?: IStoreResponse[]
  private selectedRetailerId?: string
  private selectedStoresId: string[]
  private simulatedBilling?: IBillingSimulation
  private emails: string[]

  private isCreatingInvoice = false

  constructor(props: ICreateBillingStoryLineProps) {
    super(props)
    this.state = {
      storesName: [],
      retailersName: [],

      selectedStores: [],
      isRetailerSelected: false,
      selectedDates: undefined,

      simulatedBillingCard: { price: 0, quantity: 0 },
      loadingSimulation: false,
      currentStep: 0,
      creatingBilling: false,
      defaultEmails: [{
        email: '-',
        id: -1,
        storeId: -1
      },{
        email: '-',
        id: -1,
        storeId: -1
      },{
        email: '-',
        id: -1,
        storeId: -1
      }]
    }

    this.selectedStoresId = []
    this.emails = new Array(MAX_NUMBER_OF_EMAILS)

    this.loadRetailersFromAPI()

    this.isCreatingInvoice = false
  }

  // Loads Retailers from API in order to replace the IDs coming from Billings API
  loadRetailersFromAPI = () => {
    bffBackofficeApiService.retailers
      .getRetailers()
      .then((result: IRetailersResponse) => {
        this.retailers = result.retailers
        // Get an array of retailers name for dropdown
        let retailersName = this.retailers.map((retailer: IRetailerResponse) => retailer.fantasyName)
        this.setState({ retailersName })
      })
      .catch((error: any) => {
        // TODO - Toaster with error
        toaster.showErrorToast(`Erro ao carregar varejistas`)
      })
  }

  // Loads Stores from API in order to replace the IDs coming from Billings API
  loadStoresFromAPI = (retailerId: string) => {
    bffBackofficeApiService.retailers
      .getStores()
      .then((result: IStoresResponse) => {
        // TODO - Create an endpoint for this
        // 1. Filter only the retailer's store
        this.stores = result.stores.filter((store: IStoreResponse) => store.retailerId === retailerId)

        // 2. Get only the store's name
        let storesName = this.stores.map((store: IStoreResponse) => store.name)
        this.setState({ storesName })
      })
      .catch((error: any) => {
        // TODO - Toaster with error
        toaster.showErrorToast(`Erro ao carregar lojas`)
      })
  }

  // Simulates a Billing from Backend
  simulateBillingFromAPI = () => {
    // TODO - Don't fetch dates from state: it might not have updated yet
    let startDate = this.state.selectedDates
      ? moment(this.state.selectedDates[0][0])
          .format('YYYY-MM-DD')
      : undefined
    let endDate = this.state.selectedDates
      ? moment(this.state.selectedDates[0][1])
          .format('YYYY-MM-DD')
      : undefined

    if (!startDate || !endDate || !this.selectedRetailerId || !this.selectedStoresId) return

    let body = {
      startDate,
      endDate,
      retailerId: this.selectedRetailerId,
      storeId: this.selectedStoresId,
    }
    this.setState({ loadingSimulation: true })

    bffBackofficeApiService.billings
      .simulateBilling(body)
      .then((billingSimulationResponse: IBillingSimulation) => {
        this.simulatedBilling = billingSimulationResponse
        let simulatedBillingCard = {
          quantity: this.simulatedBilling.paymentsCount,
          price: this.simulatedBilling.paymentsSum,
        }
        const emailStrings = billingSimulationResponse.emails.map(email => email.email)
        // Eliminate duplicates
        const defaultEmailsSet = billingSimulationResponse.emails.filter((email, i) => emailStrings.indexOf(email.email) === i)
        // While the number of emails default is less than MAX_NUMBER_OF_EMAILS, fill with '-'
        while (defaultEmailsSet.length < MAX_NUMBER_OF_EMAILS) {
          defaultEmailsSet.push({
            email:"-",
            id: -1, 
            storeId: -1
          })
        }
        this.setState({ defaultEmails: defaultEmailsSet })

        this.setState({
          simulatedBillingCard,
          loadingSimulation: false,
          currentStep: this.state.currentStep + 1,
          defaultEmails: defaultEmailsSet
        })
        toaster.showSuccessToast(`Pagamentos disponíveis!`)
      })
      .catch((error: AxiosError) => {
        this.setState({
          loadingSimulation: false,
        })

        if (error && error.response && error.response.data) {
          let errorString = getErrorStringForBillingsHttpErrors(error.response.data.error, error)
          toaster.showErrorToast(errorString)
        } else {
          console.error(error)
          toaster.showErrorToast(`Erro ao simular a cobrança!`)
        }
      })
  }

  // Creates a Billing from API
  //      It's the endpoint for the storyline, after this action is perfomed: reset the whole component
  createBillingFromAPI = () => {
    let startDate = this.state.selectedDates
      ? moment(this.state.selectedDates[0][0])
          .format('YYYY-MM-DD')
      : undefined
    let endDate = this.state.selectedDates
      ? moment(this.state.selectedDates[0][1])
          .format('YYYY-MM-DD')
      : undefined
    let invoiceDueDate = this.state.invoiceDueDate
      ? moment(this.state.invoiceDueDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : ''
    if (!startDate || !endDate || !this.selectedRetailerId || !this.selectedStoresId) return

    let body = {
      startDate,
      endDate,
      retailerId: this.selectedRetailerId,
      storeId: this.selectedStoresId,
      invoiceDueDate,
      emails: this.emails.filter(email => email).concat(this.state.defaultEmails.map(email => email.email)),
    }

    if (this.isCreatingInvoice)
      return

    this.isCreatingInvoice = true

    this.setState({ creatingBilling: true })

    bffBackofficeApiService.billings
      .createBilling(body)
      .then((createdBilling: IBillingResponse) => {
        //   Reset Screen
        this.setState({
          creatingBilling: false,
          currentStep: 0,
          loadingSimulation: false,
          selectedStores: [],
          simulatedBillingCard: { quantity: 0, price: 0 },
          invoiceDueDate: undefined,
          selectedDates: undefined,
        })
        this.selectedStoresId = []
        this.simulatedBilling = undefined
        this.emails = []

        this.isCreatingInvoice = false

        if (this.props.onBillingCreated) {
          this.props.onBillingCreated(createdBilling)
        }

        toaster.showSuccessToast(`Cobrança criada com sucesso`)
      })
      .catch((error: any) => {
        this.setState({
          creatingBilling: false,
        })

        this.isCreatingInvoice = false 

        if (error && error.response && error.response.data) {
          let errorString = getErrorStringForBillingsHttpErrors(error.response.data.error, error)
          toaster.showErrorToast(errorString)
        } else {
          toaster.showErrorToast(`Erro ao criar a cobrança!`)
          console.error(error)
        }
      })
  }

  // When Retailer is selected from Dropdown: it gives the dropdown index
  onRetailerSelect = (index?: number) => {
    // The default element was selected
    if (!index) {
      this.selectedRetailerId = undefined
      this.selectedStoresId = []

      // this.fetchAPIBillings()
      this.setState({ isRetailerSelected: false, storesName: [], selectedStores: [] })
      return
    }
    if (!this.retailers) return

    this.selectedRetailerId = this.retailers[index].id

    this.loadStoresFromAPI(this.selectedRetailerId)

    this.setState({ isRetailerSelected: true, storesName: [], selectedStores: [] })
  }

  // When Store is selected from Dropdown: it gives the dropdown index
  onStoreSelect = (index?: number) => {
    if (!index) {
      this.selectedStoresId = []
      this.setState({ selectedStores: [] })
      // this.fetchAPIBillings()
      return
    }
    if (!this.stores) return

    let selectedStore = this.stores[index]

    // TODO - Remove from state
    let selectedStores = Object.assign([], this.state.selectedStores)

    // If store was already selected
    if (this.state.selectedStores.find(store => store.name === selectedStore.name)) {
      return toaster.showWarningToast(`Loja ${selectedStore.name} já selecionada!`)
    }

    let color = getRandomColor()
    this.selectedStoresId.push(selectedStore.id)

    selectedStores.push({
      name: selectedStore.name,
      backgroundColor: color.backgroundColor,
      textColor: color.textColor,
    })

    // this.fetchAPIBillings()

    this.setState({ selectedStores })
  }

  // On tag clicked: remove store
  onTagClicked = (label: string, index: number) => {
    if (!this.state.selectedStores) return
    let selectedStores = Object.assign([], this.state.selectedStores)
    selectedStores.splice(index, 1)
    this.selectedStoresId.splice(index, 1)

    // this.fetchAPIBillings()

    this.setState({ selectedStores })
  }

  // When the date range of the Billing is selected
  onBillingDateRangeSelect = (selectedDates: any) => {
    if (Array.isArray(selectedDates)) {
      this.setState({ selectedDates })
    } else if (!selectedDates) {
      return
    } else {
      this.setState({ selectedDates: [[selectedDates, selectedDates]] })
    }
  }

  // When Next Step button is clicked
  onNextStep = () => {
    if (this.state.currentStep === DATES_STEP) {
      // If it's the end of the DATES_STEP, then it's the time for RESUME_STEP:
      //      Simulates Billing in order to load simulated billing card
      this.simulateBillingFromAPI()
      return
    }

    this.setState({ currentStep: this.state.currentStep + 1 })
  }

  // When previous step is clicked
  onPreviousStep = () => {
    if (this.state.currentStep === DATES_STEP) {
      this.setState({ currentStep: this.state.currentStep - 1, selectedDates: undefined })
    } else if (this.state.currentStep === RESUME_STEP) {
      this.setState({ currentStep: this.state.currentStep - 1, simulatedBillingCard: { quantity: 0, price: 0 } })
      this.simulatedBilling = undefined
    } else {
      this.setState({ currentStep: this.state.currentStep - 1 })
    }
  }

  // If user doesn't trust the system and needs to download the payments
  onDownloadSimulationPayments = async () => {
    // TODO - Improve this file name
    let fileName = 'pagamentos_simulado'
    if (this.simulatedBilling) {
      fileName += `_INICIO_${this.simulatedBilling.parameters.startDate}_FIM_${this.simulatedBilling.parameters.endDate}.xlsx`
      const ws = XLSX.utils.json_to_sheet(this.simulatedBilling.payments)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, `Pagamentos`)
      XLSX.writeFile(wb, fileName)
      toaster.showSuccessToast(`Parcelas baixadas!`)
    } else {
      toaster.showErrorToast(`Erro ao baixar pagamentos!`)
    }
  }

  // On Invoice due date selected
  onDueDateSelect = (date: string) => {
    this.setState({ invoiceDueDate: date })
  }

  // On email changed
  onEmailChange = (email: string, index: number) => {
    if (index > MAX_NUMBER_OF_EMAILS) {
      return
    }

    this.emails[index] = email
  }

  render() {
    let today = moment().format('YYYY-MM-DD')
    let tomorrow = moment()
      .add(2, 'day')
      .format('YYYY-MM-DD')
    let oneMonthFromTomorrow = moment()
      .add(2, 'day')
      .add(1, 'month')
      .format('YYYY-MM-DD')

    let {
      isRetailerSelected,
      storesName,
      retailersName,
      selectedStores,
      selectedDates,
      currentStep,
      simulatedBillingCard,
      loadingSimulation,
      invoiceDueDate,
      creatingBilling,
    } = this.state
    let startDate = selectedDates
      ? moment(selectedDates[0][0])
          .format('DD/MM/YYYY')
      : '-- / -- / -- --'
    let endDate = selectedDates
      ? moment(selectedDates[0][1])
          .format('DD/MM/YYYY')
      : '-- / -- / -- --'

    return (
      <Container>
        <HorizontalLine />
        
        <SectionContainer>
          <CabinetIcon />
          <SectionTitle> Gerar Boleto </SectionTitle>
        </SectionContainer>

        <StepsContainer>
          <Step disabled={!(currentStep === RETAILER_STEP)}>
            <StepHeader>
              <StepIndicator> Passo 1 de 4 </StepIndicator>
              <StepTitle> Para quem é o boleto? </StepTitle>
              <StepSubTitle> Selecione um varejo e uma ou mais lojas se for necessário </StepSubTitle>
            </StepHeader>

            <StepContent>
              <BillingFilter
                Icon={RetailerIcon}
                default={'Todos'}
                label={'Escolha o varejo'}
                select={retailersName}
                onDropDownSelect={this.onRetailerSelect}
              />

              <BillingFilter
                disabled={!isRetailerSelected}
                Icon={StoreIcon}
                default={'Todas as lojas'}
                label={'Escolha as lojas'}
                select={storesName}
                onDropDownSelect={this.onStoreSelect}
              />

              <TagsContainer>
                {selectedStores &&
                  selectedStores.map((store, index: number) => (
                    <Tag
                      index={index}
                      key={index}
                      label={store.name}
                      backgroundColor={store.backgroundColor}
                      onClick={this.onTagClicked}
                    />
                  ))}
              </TagsContainer>
            </StepContent>

            <div style={{ height: '6vh' }}></div>

            <StepFooter>
              <NextButton disabled={!isRetailerSelected} onClick={this.onNextStep}>
                Continuar
              </NextButton>
            </StepFooter>
          </Step>

          <Step disabled={!(currentStep === DATES_STEP)}>
            <StepHeader>
              <StepIndicator> Passo 2 de 4 </StepIndicator>
              <StepTitle> Qual o intervalo de tempo? </StepTitle>
              <StepSubTitle>
                Selecione uma data inicial e uma data final para gerar o intervalo de cobrança
              </StepSubTitle>
            </StepHeader>

            <StepContent>
              <Calendar
                daysOfWeek={true}
                size="small"
                dates={selectedDates}
                onSelect={(date: any) => this.onBillingDateRangeSelect(date)}
                bounds={['2019-12-01', today]}
                range
              />
              <SelectedDate>
                <span> De: </span> <span> {startDate} </span>
              </SelectedDate>
              <SelectedDate>
                <span> Até: </span> <span> {endDate} </span>
              </SelectedDate>
            </StepContent>

            <StepFooter>
              <BackButton onClick={this.onPreviousStep}> Voltar </BackButton>
              <NextButton disabled={!selectedDates} onClick={this.onNextStep}>
                {loadingSimulation ? <SmallLoadingIcon /> : 'Continuar'}
              </NextButton>
            </StepFooter>
          </Step>

          <Step disabled={!(currentStep === RESUME_STEP)}>
            <StepHeader>
              <StepIndicator> Passo 3 de 4 </StepIndicator>
              <StepTitle> Resumo </StepTitle>
              <StepSubTitle>
                O número de parcelas e o valor financeiro irá aparecer conforme o intervalo selecionado{' '}
              </StepSubTitle>
            </StepHeader>

            <StepContent>
              <UMEResumeCard
                startDate={currentStep < 2 ? '-- / -- / -- --' : startDate}
                endDate={currentStep < 2 ? '-- / -- / -- --' : endDate}
                card={ResumeCard}
                data={simulatedBillingCard}
              />
              <DownloadPayments>
                <span onClick={this.onDownloadSimulationPayments}>Baixar</span> parcelas do intervalo selecionado
              </DownloadPayments>
            </StepContent>

            <StepFooter>
              <BackButton onClick={this.onPreviousStep}> Voltar </BackButton>
              <NextButton onClick={this.onNextStep}>Continuar</NextButton>
            </StepFooter>
          </Step>

          <Step disabled={!(currentStep === FINAL_STEP)}>
            <StepHeader>
              <StepIndicator> Passo 4 de 4 </StepIndicator>
              <StepTitle> Vencimento e disparo </StepTitle>
              <StepSubTitle>
                Defina a data do vencimento do boleto e adicione até 3 e-mails de quem irá receber o boleto
              </StepSubTitle>
            </StepHeader>

            <DueDateContainer>
              <p> Data de vencimento: </p>
              <DueDatePicker dueDateId={1} onDateSelect={this.onDueDateSelect} bounds={[tomorrow, oneMonthFromTomorrow]} />
            </DueDateContainer>

            <EmailsContainer>
              <p> Quem deve receber: </p>
              <DefaultEmailsContainer>
                <EmailTextInput
                  placeholder="Digite o email"
                  onChange={(event: any) => this.onEmailChange(event.target.value, 2)}
                />
                {
                  this.state.defaultEmails.length > 0 ? 
                    this.state.defaultEmails.map((email, i) => (
                      <EmailTextInput 
                        key={i}
                        type="email" 
                        disabled
                        value = {email.email}
                      />
                    ))
                  : 
                    <></>
                }
              </DefaultEmailsContainer>
            </EmailsContainer>

            <StepFooter>
              <BackButton onClick={this.onPreviousStep}> Voltar </BackButton>
              <CreateBillingButton disabled={!invoiceDueDate} onClick={this.createBillingFromAPI}>
                {creatingBilling ? <SmallLoadingIcon /> : 'Gerar cobrança'}
              </CreateBillingButton>
            </StepFooter>
          </Step>
        </StepsContainer>
      </Container>
    )
  }
}

export const LabelStyle = styled(Text)`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  color: ${UMEColors.gray.nobel};
  margin-bottom: 2px;
  margin-top: 0;
`

export const DefaultEmailsContainer = styled.div`
    min-height: 160px;
    max-height: 160px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: ${UMEColors.gray.gainsboro};
      border-left: 2px solid white;
      border-right: 2px solid white;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${UMEColors.gray.eclipse};
        border-radius: 9px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: ${UMEColors.gray.nobel};
    }
`

const CreateBillingButton = styled.button`
  width: 120px;

  // TODO - Not in Design Kit
  background-color: #05aa3e;
  border: none;
  padding: 7px 12px 7px 12px;
  border-radius: 5px;

  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-align: center;

  transition: 0.3s;

  &:hover {
    transition: 0.1s;
    opacity: 0.3;
    cursor: pointer;
  }

  ${(props: any) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.2;
    `}
`

const DueDatePicker = styled(GrommetDatePicker)`
  margin-top: 4px;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid ${UMEColors.gray.light};
  border-radius: 4px;
  background-color: ${UMEColors.white.primary};

  // TODO - Not in Design Kit
  color: #585858;
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.47px;
`

const EmailTextInput = styled(TextInput)`
  margin-top: 4px;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid ${UMEColors.gray.light};
  border-radius: 4px;
  background-color: ${UMEColors.white.primary};

  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.47px;

  ${(props: any) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

const EmailsContainer = styled.div`
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`

const DueDateContainer = styled.div`
  text-align: left;
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: -0.44px;
`

const SmallLoadingIcon = styled(LoadingIcon)`
  height: 14px;
  width: 14px;
  fill: ${UMEColors.white.primary};
`

const SelectedDate = styled.div`
  & :nth-child(1) {
    margin-top: 10px;
  }

  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: left;

  & > span:nth-child(1) {
    display: inline-block;
    width: 33px;
  }

  & > span:nth-child(2) {
    color: ${UMEColors.black.primary};

    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
  }
`

const DownloadPayments = styled.p`
  margin-top: 13px;
  text-align: left;
  width: 170px;
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  align-self: center;

  & > span {
    // TODO - Not in Design Kit
    color: #0037c8;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
`

const TagsContainer = styled.div`
  min-height: 3vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  // Tags inside the container
  & > div {
    margin: 10px;
  }

  @media ${screenSize.mobileL} {
    margin-left: 0;
    margin-top: 2vh;
  }
`

const NextButton = styled.button`
  width: 88px;

  // TODO - Not in Design Kit
  background-color: #0048d6;
  border: none;
  padding: 7px 12px 7px 12px;
  border-radius: 5px;

  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-align: center;

  transition: 0.3s;

  &:hover {
    transition: 0.1s;
    opacity: 0.3;
    cursor: pointer;
  }

  ${(props: any) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.2;
    `}
`

const BackButton = styled.button`
  width: 88px;

  padding: 8px 10px 8px 10px;
  border: none;
  // TODO - Not in design kit
  background-color: rgba(255, 255, 255, 0);
  border-radius: 5px;

  color: #0048d6;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-align: center;

  &:hover {
    transition: 0.1s;
    opacity: 0.3;
    cursor: pointer;
  }
`

const StepFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StepContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const BillingFilter = styled(FinancialFilter)`
  width: 70%;
  align-self: center;

  & span {
    font-weight: 400;
  }

  &:nth-child(2) {
    margin-top: 20px;
  }
`

const RetailerIcon = styled(StoreBaseIcon)`
  height: 28px;
  width: 28px;
  fill: ${UMEColors.rose.primary};
`

const StoreIcon = styled(StoreBaseIcon)`
  height: 28px;
  width: 28px;
  fill: ${UMEColors.orange.amber};
`

const StepSubTitle = styled.p`
  width: 100%;
  text-align: left;
  text-wrap: wrap;

  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
`

const StepTitle = styled.p`
  width: 100%;
  text-align: left;

  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
`

const StepIndicator = styled.p`
  width: 100%;
  text-align: left;

  // TODO - Not in Design Kit
  color: #7b7b7b;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: -0.43px;
`

const StepHeader = styled.header`
  display: flex;
  flex-direction: column;
`

const Step = styled.div`
  flex: 1 1 250px;
  max-width: 350px;
  margin: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  min-height: 500px;

  padding: 0px 37px 0px 37px;

  ${(props: any) =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.2;
    `}
`

const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-top: 4vh;
`

const Container = styled.section`
  margin-top: 5vh;
  margin-bottom: 5vh;
`

const HorizontalLine = styled.hr`
  width: 100%;
  background-color: ${UMEColors.black.primary};
  opacity: 0.2;
  opacity: 0.4;
  margin-top: 6vh;
`
const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 4vh;
`

const SectionTitle = styled.div`
  text-align: center;
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin-left: 18px;
`
const CabinetIcon = styled(CabinetBaseIcon)`
  height: 30px;
  width: 30px;
  // TODO - Not in Design Kit
  fill: #6e6e6e;
`
