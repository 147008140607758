import React from 'react'
import styled from 'styled-components'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { formatNumberToBRL } from '../../utils'

interface IUMEResumeCardProps {
  card: {
    description: string
    quantityText: string
    priceText: string
  }
  data?: {
    quantity: number
    price: number
  }
  startDate: string
  endDate: string
}

export const UMEResumeCard = (props: IUMEResumeCardProps) => {
  let { data, card, startDate, endDate } = props
  let { description, quantityText, priceText } = card

  // If no data is given, show loading card
  if (!data) {
    return (
      <Card>
        <LoadingIcon />
      </Card>
    )
  }

  return (
    <Card>
      <ContentHeader>
        <span>{description}</span>

        <ResumeDate>
          <span> De: </span> <span> {startDate} </span>
        </ResumeDate>
        <ResumeDate>
          <span> Até: </span> <span> {endDate} </span>
        </ResumeDate>
      </ContentHeader>

      <ContentItem>
        <Quantity> {data.quantity} </Quantity>
        <span> {quantityText} </span>
      </ContentItem>
      <ContentItem>
        <Price>
          <PriceUnit> R$ </PriceUnit>
          {formatNumberToBRL(data.price).substring(3)}
        </Price>
        <span> {priceText} </span>
      </ContentItem>
    </Card>
  )
}

const Card = styled.section`
  box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  background-color: ${UMEColors.white.primary};
  height: 250px;
  width: 208px;
  padding: 16px;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  align-self:

    & > :nth-child(2) {
  }
`

const ResumeDate = styled.div`
  & > span:nth-child(1) {
    display: inline-block;
    width: 33px;
  }

  & > span:nth-child(2) {
    color: ${UMEColors.black.primary};

    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
  }
`

const ContentHeader = styled.header`
  // TODO - Not in Design Kit
  color: #a1a1a1;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;

  & :nth-child(2) {
    margin-top: 16px;
  }
`

const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  // TODO - Not in Design Kit
  color: #5b5b5b;
`

const Quantity = styled.span`
  color: ${UMEColors.black.primary};
  font-size: 32px;
  font-weight: bold;
`

const Price = styled.span`
  // TODO - Not in Design Kit
  color: #00b748;
  font-size: 24px;
  font-weight: 700;
`

const PriceUnit = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
`
