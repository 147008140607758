import React from 'react'
import styled from 'styled-components'
import { css } from 'styled-components'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { ReactComponent as DownloadBaseIcon } from '../../common/assets/images/bxs-download.svg'
import { Box, Heading } from 'grommet'
import { Modal } from '@bit/ume.design-kit.ui'
import { LoadingButton } from '../../molecules/LoadingButton/LoadingButton'
import XLSX from 'xlsx'
import { toaster } from '../../App'
import BillingDetails from '../../molecules/BillingModal/BillingDetails'
import GenerateInvoice from '../../molecules/BillingModal/GenerateInvoice'
import SendBillingEmails from '../../molecules/BillingModal/SendBillingEmails'
import { tablet } from '../../common/assets/_breakpoints'
import { TabletMessage } from '../../molecules/BillingModal/TabletMessage'
import {bffBackofficeApiService} from '../../services/bff-backoffice'
import {IBillingResponse, IPaymentResponse} from '../../services/bff-backoffice/billings/interfaces/billings.interfaces'

interface IBillingModalProps {
    height: string
    width: string
    round: string
    isModalOpen: boolean
    selectedBilling?: IBillingResponse
    billing: any | Error
    onClose: () => void
  }
  
  interface IBillingModalState {
    isDownloadingPayments: boolean
  }

  const initialState : IBillingModalState = {
    isDownloadingPayments: false
  }
  
  export default class BillingModal extends React.Component<IBillingModalProps,IBillingModalState> {

    constructor(props: IBillingModalProps) {
        super(props)
        this.state = initialState
      }

      // When clicked on Download Payments
      onDownloadPayments = async () => {
        this.setState({ isDownloadingPayments: true })
    
        if (this.props.selectedBilling) {
          return bffBackofficeApiService.billings
            .getBillingPayments(String(this.props.selectedBilling.id))
            .then((data: IPaymentResponse[]) => {
              // TODO - Improve this file name
              let fileName = 'pagamentos'
              if (this.props.selectedBilling) {
                fileName += `_INICIO_${this.props.selectedBilling.startDate}_FIM_${this.props.selectedBilling.startDate}.xlsx`
              } else {
                fileName += `.xlsx`
              }
              this.setState({ isDownloadingPayments: false })
              const ws = XLSX.utils.json_to_sheet(data)
              const wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, ws, `Pagamentos`)
              XLSX.writeFile(wb, fileName)
              toaster.showSuccessToast(`Pagamentos baixados!`)
            })
            .catch((error: any) => {
              toaster.showErrorToast(`Erro ao baixar pagamentos`)
              this.setState({ isDownloadingPayments: false })
    
              if (error && error.response && error.response.data) {
                console.error(error.response.data)
              } else {
                console.error(error)
              }
            })
        }
      }

    billingStatus = (row: any) => {
      if (!row) {
        return <></>
      }
  
      if (row.status === 're-issuance') {
        return <BillingStatusText backgroundColor="#FF8B00">Segunda Via</BillingStatusText>
      }
      switch (row.status) {
        case 'pending':
          return <BillingStatusText backgroundColor="#0048D6">A Pagar</BillingStatusText>
        case 'paid':
          return <BillingStatusText backgroundColor="#1F9901">Pago</BillingStatusText>
        case 'expired':
          return <BillingStatusText backgroundColor="#FF2601">Vencido</BillingStatusText>
        default:
          return row.status
      }
    }
    render() {
        return (
            <StyledModal
                height={this.props.height}
                width={this.props.width}
                round={this.props.round}
                isOpen={this.props.isModalOpen}
                onClose={this.props.onClose}
            >
              <Box>
                <TabletMessage />
                <ModalView>
                  <Box margin={{ horizontal: 'medium' }} direction="row-responsive" align="center" gap="medium">
                      <SectionTitleStyle>Detalhes da Cobrança</SectionTitleStyle>
                      {this.billingStatus(this.props.selectedBilling)}
                  </Box>
                  <BillingDetails
                      billing={this.props.billing} 
                      downloadingButton={
                          <DownloadLoadingButton
                              icon={<DownloadIcon />}
                              label="Baixar Pagamentos"
                              onClick={this.onDownloadPayments}
                              isLoading={this.state.isDownloadingPayments}
                          />
                      }
                  />
                  <Box margin={{ top: '25px' }} direction="row" gap="medium" height="320px">
                    <Box width="50%">
                        <Box margin={{ horizontal: 'medium' }} gap="large">
                            <SectionTitleStyle>Gerar boleto</SectionTitleStyle>
                        </Box>
                        <GenerateInvoice 
                            billing={this.props.billing} 
                            emails={[]}
                        />
                    </Box>
                    <Box width="50%" direction="column">
                        <Box margin={{ horizontal: 'medium' }} gap="medium">
                            <SectionTitleStyle>Cobrança</SectionTitleStyle>
                        </Box>
                        <SendBillingEmails 
                            stores={this.props.billing ? this.props.billing.stores : undefined} 
                            billing={this.props.billing}
                        />
                    </Box>
                  </Box>
                </ModalView>
              </Box>
            </StyledModal>
        )
    }
  }

const ModalView = styled.div`
  @media (max-width: ${tablet}) {
    display: none;
  }
`

  const BillingStatusText = styled.span`
  ${(props: any) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
  display: inline-block;
  width: 120px;
  text-align: center;
  padding: 5px;
  border-radius: 5;
  color: ${UMEColors.white.primary};
`

export const SectionTitleStyle = styled(Heading)`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin: 0;
`

export const StyledModal = styled(Modal)`
  width: 100%;
  height: 100%;
`
const DownloadLoadingButton = styled(LoadingButton)`
  border-radius: 6px;
  padding: 5px;
  width: 200px;
  // Not in
  background-color: ${UMEColors.green.dark};
  border: none;
  box-shadow: 2px 4px 20px -5px rgba(0, 0, 0, 0.4);


  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  transition: 0.4s;

  &:hover {
    transition: 250ms;
    opacity: 0.4;
    cursor: pointer;
  }

  ${(props: any) =>
    props.disabled &&
    css`
      &:hover {
        opacity: 0.2;
        cursor: not-allowed;
        transition: 0.4s;
      }
      opacity: 0.2;
      cursor: not-allowed;
      transition: 0.4s;
    `}
`
const DownloadIcon = styled(DownloadBaseIcon)`
  height: 24px;
  width: 24px;
  fill: ${UMEColors.white.primary};
`