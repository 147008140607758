import React, { Component } from 'react'
import LoginForm from '../../organisms/login/components/LoginForm/LoginForm'
import { ILoginProfileResponse } from '../../services/bff-backoffice/auth/dto/Auth.dto'
import Background from '../Background/Background'
import { LoginContainerStyle } from '../Login'

export interface HomeRouterProps {
  title?: string
  history?: any
  location?: any
  handleUserLogged: (user?: ILoginProfileResponse) => void
}

export default class LoginBorrower extends Component<HomeRouterProps> {
  public render() {
    return (
      <LoginContainerStyle>
        <Background>
          <LoginForm {...this.props} />
        </Background>
      </LoginContainerStyle>
    )
  }
}
