import { Dropdown, Heading, Text, TextInput } from '@bit/ume.design-kit.ui'
import { gray, red } from '@bit/ume.design-kit.ui/Palette'
import { Layer } from 'grommet'
import { FormClose } from 'grommet-icons'
import styled from 'styled-components'

export const ModalStyle = styled(Layer)`
  border-radius: 8px;
  height: 530px;
  width: 510px;
  padding: 32px 40px;
  margin-top: 12px;
`

export const WarningTextStyle = styled(Text)`
  color: ${red.light};
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0;
`
export const TitleStyle = styled(Heading)`
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin: 0;
`

export const FormCloseIconStyle = styled(FormClose)`
  cursor: pointer;
`

export const DisabledTextInputStyle = styled(TextInput)`
  opacity: 0.5;
  margin: 0 -8px;
  width: 390px;
`

export const TextInputStyle = styled(TextInput)`
  margin: 0 -8px;
  width: 390px;
`

export const DropdownStyle = styled(Dropdown)`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.54px;
  color: ${gray.eclipse};
  line-height: 16px;
`
