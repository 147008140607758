import { Button, Card, Heading, Modal, Tab, Tag, Text } from '@bit/ume.design-kit.ui'
import { black, blue, gray, red } from '@bit/ume.design-kit.ui/Palette'
import { Box } from 'grommet'
import styled from 'styled-components'
import { ReactComponent as BarcodeBaseIcon } from '../../common/assets/images/bx-barcode.svg'
import { screenSize } from '../../config/screens.config'

export const RenegotiationDetailsBox = styled(Box)`
  padding: 16px 40px 136px 40px;
  background-color: ${gray.azure};
  text-align: start;
  scroll-behavior: smooth;
`

export const DataStyle = styled(Text)`
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0;
`

export const ButtonStyle = styled(Button)`
  width: 100%;
  margin: 15px 0 15px 0;
  padding: 10px 0 10px 0;
  font-family: Roboto;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: 300;
`

export const Divider = styled.hr`
  border-top: 2px solid ${gray.alto};
  width: 100%;
`

export const LoadingBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 195px;
  width: 1000px;
`

export const LinkAnchor = styled.a`
  color: ${blue.bay};
  font-weight: bold;
  width: 150px;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0;
`

export const SectionTitleStyle = styled(Heading)`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin: 0;
`

export const PreviousButtonStyle = styled(Button)`
  padding: 0;
`

export const StatusTagStyle = styled(Tag)`
  &:nth-child(1) {
    padding: 4px 6px;
    height: 22px;
    width: 88px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    justify-content: start;
  }
  &:nth-child(2) {
    margin-left: 12px;
  }
`

export const TableBoxStyle = styled(Card)`
  box-shadow: none;
  border: none;
  padding: 12px 2px;
  background-color: transparent;

  overflow: auto;

  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${gray.gainsboro};
    border: 2px solid ${gray.azure};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${gray.eclipse};
    border-radius: 9px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${gray.nobel};
  }
`

export const LinkAnchorStyle = styled.a`
  color: ${blue.navy};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-decoration: underline;

  &:hover {
    color: white;
  }
`

export const InvoiceSimulationLabel = styled.label`
  margin-bottom: 0;
  color: ${gray.eclipse};
  width: 150px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0;
`
export const InvoiceSimulationData = styled(Text)`
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0;
`

export const LabelStyle = styled(Text)`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  color: ${gray.nobel};
  margin-bottom: 4px;
  margin-top: 0;
`

export const InfoTextStyle = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
`
export const DifferenceTextStyle = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
`

export const CardStyle = styled(Card)`
  border-radius: 8px;
  padding: 24px;
`

export const PaymentInvoiceBox = styled(Box)`
  border-radius: 8px;
  max-width: 300px;
  min-width: 250px;
  height: 850px;
  padding-top: 22px;
  border: dashed 2px ${gray.alto};
  padding: 25px;
  margin: 25px 0 0 50px;
`

export const DataBoxStyle = styled(Box)`
  margin: 2px 8px 12px 8px;
`

export const HelperTextStyle = styled(Text)`
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0;
  margin-left: 16px;
`

export const SimulationsBoxStyle = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
`

export const StyledModal = styled(Modal)`
  width: 100%;
  height: 100%;
`

export const SummaryBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  margin: 20px 0 0 0;
  padding-right: 25px;
`

export const TagStyle = styled(Tag)`
  &:nth-child(1) {
    height: 22px;
    width: 88px;
    border-radius: 4px;
    padding: 5px 8px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    justify-content: start;
  }
  &:nth-child(2) {
    margin-left: 12px;
  }
`

export const TextSummary = styled(Text)`
  font-weight: 500;
  margin: 0 75px 0 0;
  letter-spacing: 0;
  line-height: 22px;
  font-size: 16px;
`

export const ValueSummary = styled(Text)`
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  font-size: 2em;
`

export const TabStyle = styled(Tab)`
  opacity: ${(props: any) => (props.active ? 1 : 0.3)};

  &.payments {
    width: 165px;
  }

  @media ${screenSize.tablet} {
    &.installments {
      width: 45%;
    }

    &.contracts {
      width: 45%;
    }
  }

  & button,
  div,
  span {
    text-align: start;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: -0.69px;
    line-height: 25px;
    margin-left: 1px;
    margin-bottom: 1px;
    color: ${black.primary};
  }

  &:nth-child(n) {
    > div {
      border-bottom: solid 2px ${black.amber};
    }
  }
`
export const BarcodeIconStyle = styled(BarcodeBaseIcon)`
  height: 30px;
  width: 30px;
  fill: ${red.primary};
`
