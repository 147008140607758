import { DenialReasons } from '../../../services/bff-backoffice/application-engine/interfaces/application-engine.interfaces'

export const denialReasonsText = {
  [DenialReasons.ILLEGIBLE_DOCUMENT]: 'Documento Ilegível',
  [DenialReasons.INVALID_FACE_MATCHING]: 'Rosto não confere com rosto do documento',
  [DenialReasons.SPOOF_FACE_IMAGE]: 'Foto de rosto não é ao vivo',
  [DenialReasons.INVALID_OCR]: 'Dados não conferem o documento',
  [DenialReasons.IVALID_DOCUMENT]: 'Documento inválido',
  [DenialReasons.SPOOF_DOCUMENT_IMAGE]: 'Foto de documento não é ao vivo',
  [DenialReasons.TAMPERED_DOCUMENT]: 'Imagem de documento borrada',
  [DenialReasons.NOT_IN_AMAZON]: 'Application fora do estado do Amazonas',
  [DenialReasons.UNABLE_TO_VALIDATE_PHONE]: 'Número de telefone não validado',
  [DenialReasons.FAILED_OPERATOR_VALIDATION]: 'Cliente não validou dados pelo telefone',
}
