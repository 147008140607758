import { gray, green, red, white } from '@bit/ume.design-kit.ui/Palette'
import { Sync } from 'grommet-icons'
import * as React from 'react'
import {
  INSTALLMENT_STATUS,
  ISingleInstallment,
} from '../../services/bff-backoffice/coordinator/interfaces/installment.interfaces'
import { StatusTagStyle } from './style'

export interface IInstallmentStatusProps {
  installment?: ISingleInstallment | Error
}

export default class InstallmentStatus extends React.PureComponent<IInstallmentStatusProps> {
  public render() {
    const { installment } = this.props

    const statusTag = (status: INSTALLMENT_STATUS, isRenegotiation: boolean) => {
      switch (status) {
        case 'PENDING':
          return (
            <StatusTagStyle
              disable
              backgroundColor={gray.primary}
              label={'A vencer'}
              iconBackground="none"
              iconColor={white.primary}
              icon={isRenegotiation ? <Sync /> : undefined}
            />
          )
        case 'PAID':
          return (
            <StatusTagStyle
              disable
              backgroundColor={green.laurel}
              label={'Pago'}
              iconBackground="none"
              iconColor={white.primary}
              icon={isRenegotiation ? <Sync /> : undefined}
            />
          )
        case 'PAYMENT_OVERDUE':
          return (
            <StatusTagStyle
              disable
              backgroundColor={red.primary}
              label={'Vencida'}
              iconBackground="none"
              iconColor={white.primary}
              icon={isRenegotiation ? <Sync /> : undefined}
            />
          )
        default:
          return <></>
      }
    }
    return installment === undefined ? (
      <></>
    ) : installment instanceof Error ? (
      <></>
    ) : (
      statusTag(installment.status, installment.isRenegotiation)
    )
  }
}
