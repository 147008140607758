export const numberToCurrency = (value: number, withCurrencySymbol = false) => {
  if (withCurrencySymbol) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  } else {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
}
