import React from 'react'
import styled from 'styled-components'
import { screenSize } from '../../config/screens.config'
import { css } from 'styled-components'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'

export const RetailersTab = (props: any) => {
  let { active, title } = props

  return (
    <Tab active={active} {...props}>
      <Label>{title}</Label>
    </Tab>
  )
}

const Tab = styled.div`
  display: flex;
  flex: 1 1 100px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 2px solid black;
  margin: 10px 35px 0px 0px;
  padding-bottom: 3px;
  max-width: 225px;
  height: 34px;

  color: ${UMEColors.black.primary};

  &:hover {
    cursor: pointer;
    border-width: 4px;
    transition: 250ms;
    opacity: 1;
  }

  ${(props: any) =>
    props.active &&
    css`
      opacity: 0.2;
      cursor: pointer;
    `}

  &:nth-child(4) {
    margin-right: 0px;
  }

  @media ${screenSize.mobileL} {
    margin-right: 10px;


    &:nth-child(3) {
      margin-right: 0px;
    }
  }
`

const Label = styled.span`
  margin-left: 16px;
  font-family: 'Roboto';
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.69px;
  line-height: 25px;
  text-align: center;
`
