import { formatDate, formatNumberToBRL } from '../../../utils'
import { applicationStatusDictionary } from '../../../config/utils'

export const applicationsHeader = [
  { property: 'DATE', label: 'Data', format: formatDate },
  { property: 'TOTAL', label: 'Total' },
  { property: 'APPROVED', label: 'Aprovados' },
  { property: 'DENIED', label: 'Negados' },
  { property: 'FAILURE', label: 'Falhas' },
  { property: 'UNDER_ANALYSIS', label: 'Em análise' },
]

export const originationsAnalysisHeader = [
  { property: 'type', label: 'Tipo' },
  { property: 'count', label: 'Quantidade' },
  { property: 'volume', label: 'Volume', format: formatNumberToBRL },
]

export const recentApplicationsByStatusByStoreHeader = [
  { property: 'retailerName', label: 'Varejista' },
  { property: 'storeName', label: 'Loja' },
  { property: 'status', label: 'Status', format: (el: string) => applicationStatusDictionary[el] },
  { property: 'applications', label: 'Applications' },
]

export const originationsSummaryHeader = [
  { property: 'total', label: 'Quantidade de Originações' },
  { property: 'principal', label: 'Valor Originado', format: formatNumberToBRL},
  { property: 'average', label: 'Ticket médio', format: formatNumberToBRL },
]

export const droppedContractsHeader = [
  { property: 'count', label: 'Quantidade de carrinhos abandonados' },
  { property: 'volume', label: 'Volume de carrinhos abandonados', format: formatNumberToBRL },
]
