import React from 'react'
import { Link } from 'react-router-dom'
import { BreadcrumbsBox, BreadcrumbsItem } from '../RetailersPage/styles'

interface Props {
  batchNumber: string
}

const BatchApplicationsBreadcrumbs = ({ batchNumber }: Props) => {
  return (
    <BreadcrumbsBox>
      <Link to="/">
        <BreadcrumbsItem>Início</BreadcrumbsItem>
      </Link>
      <BreadcrumbsItem>/</BreadcrumbsItem>
      <Link to="/applications/batches">
        <BreadcrumbsItem>Application Online</BreadcrumbsItem>
      </Link>
      <BreadcrumbsItem>/</BreadcrumbsItem>
      <BreadcrumbsItem active>Caixa {batchNumber}</BreadcrumbsItem>
    </BreadcrumbsBox>
  )
}

export default BatchApplicationsBreadcrumbs
