import { Clipboard, Loading, Text } from '@bit/ume.design-kit.ui'
import { green, red } from '@bit/ume.design-kit.ui/Palette'
import { Box } from 'grommet'
import * as React from 'react'
import { toaster } from '../../App'
import { ResponsiveGrid } from '../../atoms/ResponsiveGrid/ResponsiveGrid'
import { InfoTextStyle, LabelStyle } from '../../pages/CS-Profile/ContractsSummaryCard/style'
import { RenegotiationPaymentResponse } from '../../services/bff-backoffice/coordinator/interfaces/renegotiation.interfaces'
import { formatDate, formatDateTreatingError, formatNumberToBRL } from '../../utils'
import { CardStyle, DataBoxStyle, DifferenceTextStyle, LinkAnchorStyle } from './style'

export interface RenegotiationPaymentDetailsContentProps {
  renegotiationPayment?: RenegotiationPaymentResponse | Error
}

export default class RenegotiationPaymentDetailsContent extends React.PureComponent<
  RenegotiationPaymentDetailsContentProps
> {
  public render() {
    const { renegotiationPayment: installment } = this.props

    const errorCard = (
      <Text color="red" light>
        Não foi possível carregar os dados para esta parcela.
      </Text>
    )

    const differenceValue = (installmentValue: number, paidValue?: number, paymentDue?: number) => {
      let difference = 0

      if (paidValue) {
        difference = paidValue - installmentValue
      } else {
        if (paymentDue) {
          difference = paymentDue - installmentValue
        }
      }

      if (difference > 0) {
        return <DifferenceTextStyle color={red.primary}>{'+' + formatNumberToBRL(difference)}</DifferenceTextStyle>
      } else {
        return <DifferenceTextStyle color={green.darkPastel}>{formatNumberToBRL(difference)}</DifferenceTextStyle>
      }
    }

    const paymentMethodString = (method?: string, paymentTimestamp?: string) => {
      if (!paymentTimestamp) {
        return '-'
      }
      switch (method) {
        case 'STORE':
          return 'Loja'
        case 'INVOICE':
          return 'Boleto'
        case 'RENEGOTIATION':
          return 'Renegociação'
        default:
          return '-'
      }
    }

    const RenegotiationPaymentDetailsCard = (installment: RenegotiationPaymentResponse) => {
      const sortedInvoices = installment?.invoices?.sort((a, b) => Number(b.id) - Number(a.id))
      return (
        <Box>
          <ResponsiveGrid columns="150px" gap="small">
            <DataBoxStyle>
              <LabelStyle>ID da parcela</LabelStyle>
              <InfoTextStyle>{installment.id}</InfoTextStyle>
            </DataBoxStyle>

            <DataBoxStyle>
              <LabelStyle>Valor da parcela</LabelStyle>
              <InfoTextStyle>{formatNumberToBRL(installment.value)}</InfoTextStyle>
            </DataBoxStyle>

            <DataBoxStyle>
              <LabelStyle>Ordem da parcela</LabelStyle>
              <InfoTextStyle>{installment.order}</InfoTextStyle>
            </DataBoxStyle>

            <DataBoxStyle>
              <LabelStyle>Vencimento</LabelStyle>
              <InfoTextStyle>{formatDate(installment.dueDate)}</InfoTextStyle>
            </DataBoxStyle>

            <DataBoxStyle>
              <LabelStyle>Desconto/Acréscimo</LabelStyle>
              {differenceValue(installment.value, installment.paidValue, installment.paymentDue)}
            </DataBoxStyle>

            <DataBoxStyle>
              <LabelStyle>Valor atual</LabelStyle>
              <InfoTextStyle>{formatNumberToBRL(installment.paymentDue)}</InfoTextStyle>
            </DataBoxStyle>

            <DataBoxStyle>
              <LabelStyle>Data da orginação</LabelStyle>
              <InfoTextStyle>{formatDateTreatingError(installment.createdOn)}</InfoTextStyle>
            </DataBoxStyle>

            <DataBoxStyle>
              <LabelStyle>Forma de pgto</LabelStyle>
              <InfoTextStyle>
                {paymentMethodString(installment.paymentMethod, installment.paymentTimestamp)}
              </InfoTextStyle>
            </DataBoxStyle>

            <DataBoxStyle>
              <LabelStyle>Data do pgto</LabelStyle>
              <InfoTextStyle>
                {installment.paymentTimestamp ? formatDateTreatingError(installment.paymentTimestamp) : '-'}
              </InfoTextStyle>
            </DataBoxStyle>

            <DataBoxStyle>
              <LabelStyle>Link do boleto</LabelStyle>
              <InfoTextStyle>
                {sortedInvoices && sortedInvoices.length > 0 ? (
                  <Box direction="row" align="center" gap="medium">
                    <LinkAnchorStyle target="_blank" href={sortedInvoices[0].url + '.pdf'}>
                      {sortedInvoices[0]?.barcode?.slice(0, 5)
                        ? sortedInvoices[0]?.barcode?.slice(0, 5) + '...'
                        : '---------'}
                    </LinkAnchorStyle>
                    <div
                      title="Copiar código de barras"
                      onClick={() => toaster.showSuccessToast('Copiado para área de transferência')}
                    >
                      <Clipboard value={sortedInvoices[0].url + '.pdf'} />
                    </div>
                  </Box>
                ) : (
                  '-'
                )}
              </InfoTextStyle>
            </DataBoxStyle>
          </ResponsiveGrid>
        </Box>
      )
    }
    return (
      <CardStyle width="968px">
        {installment === undefined ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : installment instanceof Error ? (
          errorCard
        ) : (
          RenegotiationPaymentDetailsCard(installment)
        )}
      </CardStyle>
    )
  }
}
