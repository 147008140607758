import { ImageLabel } from '../../../services/bff-backoffice/coordinator/interfaces/borrower.interfaces'

export const IMAGE_LABEL_TEXT: { [label: string]: string } = {
  [ImageLabel.CNH_BACK]: 'CNH (Verso)',
  [ImageLabel.CNH_FRONT]: 'CNH (Frente)',
  [ImageLabel.RG_BACK]: 'RG (Verso)',
  [ImageLabel.RG_FRONT]: 'RG (Frente)',
  [ImageLabel.CTPS_BACK]: 'CTPS (Verso)',
  [ImageLabel.CTPS_FRONT]: 'CTPS (Frente)',
  [ImageLabel.FOREING_ID_BACK]: 'RG Estrangeiro (Verso)',
  [ImageLabel.FOREING_ID_FRONT]: 'RG Estrangeiro (Frente)',
  [ImageLabel.MILITARY_RG_BACK]: 'RG Militar (Verso)',
  [ImageLabel.MILITARY_RG_FRONT]: 'RG Militar (Frente)',
  [ImageLabel.PASSPORT_BACK]: 'Passaporte (Verso)',
  [ImageLabel.PASSPORT_FRONT]: 'Passaporte (Frente)',
  [ImageLabel.PORTRAIT]: 'Foto de Rosto',
  [ImageLabel.NEW_CNH_FRONT]: 'Nova CNH (Frente)',
  [ImageLabel.NEW_CNH_BACK]: 'Nova CNH (Verso)',
}
