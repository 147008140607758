import { combineReducers } from 'redux'
import { OperatorsSliceReducer } from './operators/operators.reducer'
import { RetailersSliceReducer } from './retailers/retailers.reducer'
import { StoresSliceReducer } from './stores/stores.reducer'
import { all } from 'redux-saga/effects'
import retailersSaga from './retailers/retailers.saga'
import storesSaga from './stores/stores.saga'
import operatorsSaga from './operators/operators.saga'
import applicationsSaga from './applications/applications.saga'
import retailersBenchSaga from './retailers-bench/retailers-bench.saga'
import { ApplicationsSliceReducer } from './applications/applications.reducer'
import { RetailersBenchSliceReducer } from './retailers-bench/retailers-bench.reducer'

export const Reducers = combineReducers({
  retailers: RetailersSliceReducer.reducer,
  stores: StoresSliceReducer.reducer,
  operators: OperatorsSliceReducer.reducer,
  applications: ApplicationsSliceReducer.reducer,
  retailersBench: RetailersBenchSliceReducer.reducer
})

export function* rootSaga() {
  yield all([...retailersSaga, ...storesSaga, ...operatorsSaga, ...applicationsSaga, ...retailersBenchSaga])
}

export type ReduxState = ReturnType<typeof Reducers>