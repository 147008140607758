import { AxiosError } from 'axios'
import moment from 'moment'
import { numberToCurrency } from '../../common/assets/utils/currencyMask'
import { HttpOriginationsBatchErrors, ORIGINATIONS_BATCH_STATUS } from '../../services/bff-backoffice/liquidation-originations/interfaces/liquidation-originations.interfaces'
import { IOriginationsRetailerBatchResponse } from './types'

const BAD_REQUEST = 400
const NOT_FOUND = 404

export const getErrorStringForBackofficeOriginationsHttpErrors = (error: AxiosError) => {
  if (error.response && error.response.data) {
    const httpErrors = HttpOriginationsBatchErrors
    const statusCode = error.response.data.statusCode
    if (statusCode === BAD_REQUEST) {
      return `Requisição incorreta. Contate a engenharia`
    } else if (statusCode === NOT_FOUND) {
      return `Remessa não encontrada!`
    }

    const errorCode = error.response.data.error

    if (errorCode === httpErrors.ALREADY_EXISTING_BATCH_ON_DATE) {
      return `Remessa para este dia já existe`
    } else if (errorCode === httpErrors.BATCH_ALREADY_FINISHED) {
      return `Remessa já finalizada`
    } else if (errorCode === httpErrors.BATCH_NOT_FOUND) {
      return `Remessa não encontrada`
    } else if (errorCode === httpErrors.EMAIL_NOT_SENT) {
      return `Email não pode ser enviado`
    } else if (errorCode === httpErrors.NOT_FIRST_PENDING_STEP) {
      return `Não é a primeira etapa pendente`
    } else if (errorCode === httpErrors.NO_ORIGINATIONS_ON_DATE) {
      return `Nenhuma originação no dia`
    } else if (errorCode === httpErrors.STEP_ALREADY_FINISHED) {
      return `Etapa já finalizada`
    } else if (errorCode === httpErrors.STEP_NOT_FOUND) {
      return `Etapa não encontrada`
    } else if (errorCode === httpErrors.VOUCHER_NOT_FOUND) {
      return `Comprovante não encontrado`
    } else {
      return `Erro não identificado. Entrar em contato com a engenharia`
    }
  } else {
    return `Erro não identificado. Entrar em contato com a engenharia`
  }
}

export const renderOriginationsDate = (originationsStartDate: string, originationsEndDate: string) => {
  if (originationsStartDate === originationsEndDate) {
    return `Contratos originados no dia ${moment(originationsStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}`
  } else {
    return `Contratos originados entre o dia ${moment(originationsStartDate, 'YYYY-MM-DD').format(
      'DD/MM/YYYY'
    )} e o dia ${moment(originationsEndDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}`
  }
}

export const renderBatchStatus = (batchStatus: string) => {
  const BATCH_STATUS = ORIGINATIONS_BATCH_STATUS
  switch (batchStatus) {
    case BATCH_STATUS.AT_UME:
      return `Aguardando transferência para o bancarizador.`
    case BATCH_STATUS.AT_BANKER:
      return `Aguardando transferência para a conta intermediária.`
    case BATCH_STATUS.AT_TRANSITORY_ACCOUNT:
      return `Aguardando transferência para a conta final do varejista.`
    case BATCH_STATUS.AT_RETAILER:
      return `Dinheiro transferido a varejista. Remessa concluída!`
    default:
      return `Status desconhecido.`
  }
}

export const RETAILERS_BATCH_TABLE_HEADER = [
  {
    label: 'Varejista',
    attribute: 'retailerName',
    textAlign: 'center',
  },
  {
    label: 'Principal',
    attribute: 'principalSum',
    formatCell: (c: IOriginationsRetailerBatchResponse) => numberToCurrency(c.principalSum, true),
    textAlign: 'center',
  },
  {
    label: 'Descontos',
    attribute: 'discountSum',
    formatCell: (c: IOriginationsRetailerBatchResponse) => numberToCurrency(c.discountSum, true),
    textAlign: 'center',
  },
  {
    label: 'Taxa UME',
    attribute: 'umeFeeSum',
    formatCell: (c: IOriginationsRetailerBatchResponse) => numberToCurrency(c.umeFeeSum, true),
    textAlign: 'center',
  },
  {
    label: 'IoF',
    attribute: 'iofSum',
    formatCell: (c: IOriginationsRetailerBatchResponse) => numberToCurrency(c.iofSum, true),
    textAlign: 'center',
  },
  {
    label: 'Depósito Varejista',
    attribute: 'netSum',
    formatCell: (c: IOriginationsRetailerBatchResponse) => numberToCurrency(c.netSum, true),
    textAlign: 'center',
  },
  {
    label: 'Valor bruto',
    attribute: 'grossSum',
    formatCell: (c: IOriginationsRetailerBatchResponse) => numberToCurrency(c.grossSum, true),
    textAlign: 'center',
  },
]

export const STORES_BATCH_TABLE_HEADER = [
  {
    label: 'Varejista',
    attribute: 'retailerName',
    textAlign: 'center',
  },
  {
    label: 'Lojas',
    attribute: 'storeName',
    textAlign: 'center',
  },
  {
    label: 'Principal',
    attribute: 'principalSum',
    formatCell: (c: IOriginationsRetailerBatchResponse) => numberToCurrency(c.principalSum, true),
    textAlign: 'center',
  },
  {
    label: 'Descontos',
    attribute: 'discountSum',
    formatCell: (c: IOriginationsRetailerBatchResponse) => numberToCurrency(c.discountSum, true),
    textAlign: 'center',
  },
  {
    label: 'Taxa UME',
    attribute: 'umeFeeSum',
    formatCell: (c: IOriginationsRetailerBatchResponse) => numberToCurrency(c.umeFeeSum, true),
    textAlign: 'center',
  },
  {
    label: 'IoF',
    attribute: 'iofSum',
    formatCell: (c: IOriginationsRetailerBatchResponse) => numberToCurrency(c.iofSum, true),
    textAlign: 'center',
  },
  {
    label: 'Depósito Loja',
    attribute: 'netSum',
    formatCell: (c: IOriginationsRetailerBatchResponse) => numberToCurrency(c.netSum, true),
    textAlign: 'center',
  },
  {
    label: 'Valor bruto',
    attribute: 'grossSum',
    formatCell: (c: IOriginationsRetailerBatchResponse) => numberToCurrency(c.grossSum, true),
    textAlign: 'center',
  },
]
