import { TextInput } from '@bit/ume.design-kit.ui'
import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Box } from 'grommet'
import React from 'react'
import { connect } from 'react-redux'
import { Route, RouteComponentProps, Router, Switch } from 'react-router'
import { toaster } from '../../App'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { valida_cep } from '../../common/Validators'
import { shouldAllowStoreAddressEdit } from '../../config/config'
import UMEDataTable from '../../molecules/DataTable/DataTable'
import MaskedInput from '../../molecules/RetailersPage/MaskedInput'
import { StyledButton } from '../../pages/Retailers/style'
import { ReduxState } from '../../redux/reducers'
import { OperatorsSliceReducer } from '../../redux/reducers/operators/operators.reducer'
import { RetailersSliceReducer } from '../../redux/reducers/retailers/retailers.reducer'
import { StoresSliceReducer } from '../../redux/reducers/stores/stores.reducer'
import {
  FullAddress,
  IPlaceSearchResponse,
  IPostGooglePlaceSearch,
  IRetailerResponse,
  PostStoreRequest,
} from '../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'
import { FetchStoreResponse, StoreStatus } from '../../services/bff-backoffice/retailers/interfaces/stores.res'
import ZipCodeSearch from '../../utils/zipcode/ZipcodeSearch'
import { ZipcodeSearchErrors } from '../../utils/zipcode/ZipcodeSearchErrors'
import { TableContainer } from '../ForcedApprovalModification/style'
import FormModal from './FormModal'
import StoreDetails from './StoreDetails'
import {
  GooglePlaceAddressTextStyled,
  GooglePlaceNameTextStyled,
  SectionTitleStyle,
  SmallLoadingIcon,
  StyledTextRetailerName,
} from './styles'

interface IStoreAddress {
  streetName: string
  district: string
  city: string
  uf: string
  ibge: number
}

interface IStoresTabProps extends RouteComponentProps {
  stores?: FetchStoreResponse[]
  store?: FetchStoreResponse
  retailer?: IRetailerResponse
  googlePlaceSearch?: IPlaceSearchResponse
  isLoadingGooglePlaceSearch: boolean
  isCreateStoreModalOpen: boolean
  isCreateStoreLoading: boolean
  zipcode: string
  streetName: string
  streetNumber: string
  complement?: string
  district: string
  uf: string
  city: string
  ibge?: number
  name: string
  searchText: string
  fetchRetailerStores: (retailerId: string) => {}
  fetchStore: (storeId: string) => {}
  resetRetailerOriginationParameters: () => {}
  resetOperators: () => {}
  fetchStoresEmails: (stores: string[]) => {}
  searchGooglePlace: (body: IPostGooglePlaceSearch) => {}
  searchGooglePlaceLoading: () => {}
  createStore: (body: PostStoreRequest) => {}
  createStoreModalOpen: () => {}
  createStoreModalClose: () => {}
  setStoreAddress: (address: IStoreAddress) => {}
  updateStoreFields: (field: any) => {}
}

interface IStoresTabState {
  address: string
  logo: string
  googlePlaceId: string
  nameErrorIntent: boolean
  zipcodeErrorIntent: boolean
  addressErrorIntent: boolean
}

const initialState: IStoresTabState = {
  logo: '',
  address: '',
  googlePlaceId: '',
  nameErrorIntent: false,
  zipcodeErrorIntent: false,
  addressErrorIntent: false,
}

const CepMaskInput = [
  {
    length: 5,
    regexp: /\d/,
    placeholder: '00000',
  },
  { fixed: '-' },
  {
    length: 3,
    regexp: /\d/,
    placeholder: '000',
  },
]

class StoresTab extends React.Component<IStoresTabProps, IStoresTabState> {
  constructor(props: IStoresTabProps) {
    super(props)
    this.state = initialState
  }

  componentDidMount = () => {
    if (this.props.retailer && !this.props.stores) {
      this.props.fetchRetailerStores(this.props.retailer.id)
      return
    }
  }

  componentDidUpdate = async (prevProps: any) => {
    // When the retailer is updated, fetch its stores and reset the operators
    if (prevProps.retailer !== this.props.retailer && this.props.retailer) {
      this.props.fetchRetailerStores(this.props.retailer.id)
      this.props.resetOperators()
      this.props.resetRetailerOriginationParameters()

      // If is showing the details of a store, go to the store tab to show all stores
      let pathTokens = this.props.location.pathname.split('/')

      if (this.props.store) {
        pathTokens[4] = ''
        this.props.history.push(pathTokens.join('/'))
        return
      }

      if (pathTokens[4]) {
        this.props.fetchStore(pathTokens[4])
        this.props.fetchStoresEmails([pathTokens[4]])
      }
    }
  }

  onCheckBoxChange = (e: CheckboxChangeEvent) => {
    if (this.props.googlePlaceSearch) {
      this.setState({
        googlePlaceId: e.target.checked ? this.props.googlePlaceSearch.placeId : '',
        address: e.target.checked ? this.props.googlePlaceSearch.address : '',
      })
    }
  }

  handleSearchButtonClick = (e: any) => {
    const { zipcode, searchText } = this.props
    this.props.searchGooglePlace({ name: searchText, zipcode: zipcode.replace('-', '') })
  }

  onRowClicked = (element: any, index: number) => {
    // Fetches a new store if none is set or if a new one was selected
    if (!this.props.store || this.props.store.id !== element.id) {
      this.props.fetchStore(element.id)
      this.props.fetchStoresEmails([element.id])
    }

    // Update URL
    let pathTokens = this.props.location.pathname.split('/')
    pathTokens[4] = element.id
    this.props.history.push(pathTokens.join('/'))
  }

  onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.updateStoreFields({ [e.target.name]: e.target.value })
  }

  onAddressByZipCode = async () => {
    const { zipcode } = this.props

    const zipcodeAdress = await ZipCodeSearch.searchAddressByZipCode(zipcode)

    if (zipcodeAdress == ZipcodeSearchErrors.ZIPCODE_NOT_FOUND) {
      toaster.showErrorToast('CEP não encontrado')
    } else if (zipcodeAdress == ZipcodeSearchErrors.INVALID_ZIPCODE) {
      toaster.showErrorToast('CEP inválido, por favor confirme se está correto')
    }

    this.props.setStoreAddress({
      streetName: zipcodeAdress.logradouro,
      district: zipcodeAdress.bairro,
      city: zipcodeAdress.localidade,
      uf: zipcodeAdress.uf,
      ibge: Number(zipcodeAdress.ibge),
    })
  }

  buildCompleteAddress = () => {
    const { streetName, streetNumber, complement, district, city, uf, zipcode } = this.props

    const fullAddress = `${streetName}, ${streetNumber} ${complement} - ${district}, ${city} - ${uf}, ${zipcode}`
    return fullAddress
  }

  buildFullAddressObject = (): FullAddress => {
    const { streetName, streetNumber, complement, district, city, uf, zipcode, ibge } = this.props

    return {
      zipCode: zipcode?.replace('-', ''),
      streetName,
      streetNumber,
      complement,
      district,
      city,
      federativeUnit: uf,
      ibge: ibge ? Number(ibge) : undefined,
    }
  }

  // Returns the form fields of the Create Store Modal
  createStoreFields = () => {
    return [
      <StyledTextRetailerName key={0}>{this.props?.retailer?.fantasyName}</StyledTextRetailerName>,
      <TextInput
        required
        label="Nome"
        name={'name'}
        key={1}
        width="100%"
        onChange={this.onChangeInput}
        value={this.props.name}
        errorIntent={this.state.nameErrorIntent}
      />,
      <MaskedInput
        required
        label="CEP"
        mask={CepMaskInput}
        placeholder="00000-000"
        key={2}
        name={'zipcode'}
        width="100%"
        onChange={this.onChangeInput}
        value={this.props.zipcode}
        errorIntent={this.state.zipcodeErrorIntent}
        onBlur={this.onAddressByZipCode}
      />,
      <Box key={3} direction="row" align="center">
        <TextInput
          required
          label="UF"
          placeholder={''}
          key={4}
          name={'uf'}
          width="60%"
          disabled={!shouldAllowStoreAddressEdit}
          onChange={this.onChangeInput}
          value={this.props.uf}
        />
        <TextInput
          required
          label="Cidade"
          placeholder={''}
          key={5}
          name={'city'}
          width="100%"
          disabled={!shouldAllowStoreAddressEdit}
          onChange={this.onChangeInput}
          value={this.props.city}
        />
      </Box>,
      <TextInput
        required
        label="Bairro"
        placeholder={''}
        key={6}
        name={'district'}
        width="100%"
        disabled={!shouldAllowStoreAddressEdit}
        onChange={this.onChangeInput}
        value={this.props.district}
      />,
      <TextInput
        required
        label="Logradouro"
        placeholder={''}
        key={7}
        name={'streetName'}
        width="100%"
        disabled={!shouldAllowStoreAddressEdit}
        onChange={this.onChangeInput}
        value={this.props.streetName}
      />,
      <Box key={8} direction="row" align="center">
        <TextInput
          required
          label="Número"
          placeholder={''}
          key={9}
          name={'streetNumber'}
          width="60%"
          disabled={!this.props.zipcode || !this.props.name}
          onChange={this.onChangeInput}
          value={this.props.streetNumber}
        />
        <TextInput
          label="Complemento"
          placeholder={''}
          key={10}
          name={'complement'}
          width="100%"
          disabled={!this.props.zipcode || !this.props.name}
          onChange={this.onChangeInput}
          value={this.props.complement}
        />
      </Box>,
      <Box key={11} direction="row" align="center" pad={{ right: '10px' }}>
        <TextInput
          label="Localização Google"
          placeholder={''}
          key={12}
          name={'searchText'}
          width="100%"
          disabled={!this.props.zipcode || !this.props.name}
          onChange={this.onChangeInput}
          value={this.props.searchText}
        />
        <StyledButton
          width={'30%'}
          key={13}
          disabled={!this.props.zipcode || !this.props.name || !this.props.searchText}
          onClick={this.handleSearchButtonClick}
        >
          {this.props.isLoadingGooglePlaceSearch ? <SmallLoadingIcon /> : <> Pesquisar </>}
        </StyledButton>
      </Box>,
      <Box margin={{ left: '20px' }} key={14}>
        {this.props.googlePlaceSearch === null ? (
          <GooglePlaceNameTextStyled>Localização não encontrada</GooglePlaceNameTextStyled>
        ) : this.props.googlePlaceSearch === undefined ? (
          <GooglePlaceNameTextStyled>Digite o nome do local para pesquisar.</GooglePlaceNameTextStyled>
        ) : (
          <Checkbox
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}
            onChange={this.onCheckBoxChange}
          >
            <Box gap="xsmall">
              <GooglePlaceNameTextStyled>{this.props.googlePlaceSearch.name}</GooglePlaceNameTextStyled>
              <GooglePlaceAddressTextStyled>{this.props.googlePlaceSearch.address}</GooglePlaceAddressTextStyled>
            </Box>
          </Checkbox>
        )}
      </Box>,
    ]
  }

  // Validates the create store fields and, if valid, submit to API Service
  onSaveStore = () => {
    const { googlePlaceId } = this.state
    const { zipcode, name } = this.props
    const address = this.buildCompleteAddress()
    const fullAddress = this.buildFullAddressObject()

    let nameErrorIntent = false
    let zipcodeErrorIntent = false
    let addressErrorIntent = false

    if (name.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Nome deve conter pelo menos um caracter.')
      nameErrorIntent = true
    } else {
      nameErrorIntent = false
    }

    if (!valida_cep(zipcode)) {
      toaster.showErrorToast('CEP inválido.')
      zipcodeErrorIntent = true
    } else {
      zipcodeErrorIntent = false
    }

    if (address.replace(/ /g, '').length === 0) {
      toaster.showErrorToast('Endereço deve conter pelo menos um caracter.')
      addressErrorIntent = true
    } else {
      addressErrorIntent = false
    }

    this.setState({
      nameErrorIntent,
      addressErrorIntent,
      zipcodeErrorIntent,
    })

    if (addressErrorIntent || zipcodeErrorIntent || nameErrorIntent) {
      return
    }

    const { retailer } = this.props

    const body: PostStoreRequest = {
      name,
      retailerId: retailer?.id || '',
      zipcode: zipcode.replace('-', ''),
      address,
      googlePlaceId,
      fullAddress,
    }

    this.props.createStore(body)

    this.setState({ googlePlaceId: initialState.googlePlaceId })
  }

  // Returns a data table with the given list of stores
  storesTableView = (stores: FetchStoreResponse[]) => {
    const storesData = stores.map((store: FetchStoreResponse) => ({
      ...store,
      status: store.status == StoreStatus.ACTIVE ? 'Ativa' : 'Inativa',
    }))

    return (
      <Box style={{ maxWidth: '1000px' }} direction="column">
        <Box width="100%" align="center" justify="between" margin={{ vertical: '0' }} direction="row-responsive">
          <SectionTitleStyle style={{ marginBottom: 0 }}>Lista de Lojas</SectionTitleStyle>

          <StyledButton onClick={this.props.createStoreModalOpen}>Cadastrar Loja</StyledButton>
        </Box>
        <TableContainer style={{ maxHeight: '600px' }}>
          {stores.length > 0 ? (
            <UMEDataTable
              header={[
                {
                  label: 'ID',
                  attribute: 'id',
                },
                {
                  label: 'Nome',
                  attribute: 'name',
                },
                {
                  label: 'Google Place Id',
                  attribute: 'googlePlaceId',
                },
                {
                  label: 'ZIP Code',
                  attribute: 'zipcode',
                },
                {
                  label: 'Endereço',
                  attribute: 'address',
                },
                { label: 'Status', attribute: 'status' },
              ]}
              data={storesData}
              onRowElementClick={this.onRowClicked}
            />
          ) : (
            <Box pad={{ top: '33px' }} align="center" justify="center" margin={{ vertical: '50px' }}>
              Não há lojas cadastradas.
            </Box>
          )}
        </TableContainer>
      </Box>
    )
  }

  render() {
    const { stores, retailer, isCreateStoreLoading, isCreateStoreModalOpen } = this.props

    if (!retailer || !stores) {
      return (
        <Box width="100%" height="50vh">
          <LoadingIcon />
        </Box>
      )
    }

    return (
      <>
        <FormModal
          title="Criar Loja"
          isModalOpen={isCreateStoreModalOpen}
          fields={this.createStoreFields()}
          onClose={this.props.createStoreModalClose}
          onCreate={this.onSaveStore}
          isLoadingButton={isCreateStoreLoading}
        />
        <Router history={this.props.history}>
          <Switch>
            <Route
              path={`/retailers/:retailerId/stores/:storeId`}
              component={(props: RouteComponentProps) => <StoreDetails {...props} />}
            />

            <Route path={`/retailers/:retailerId/stores`} component={() => this.storesTableView(stores)} />
          </Switch>
        </Router>
      </>
    )
  }
}

const mapStateToProps = (state: ReduxState) => ({
  stores: state.stores.stores,
  store: state.stores.store,
  retailer: state.retailers.retailer,
  googlePlaceSearch: state.stores.googlePlaceSearch,
  isLoadingGooglePlaceSearch: state.stores.isLoadingGooglePlaceSearch,
  isCreateStoreModalOpen: state.stores.isCreateStoreModalOpen,
  isCreateStoreLoading: state.stores.isCreateStoreLoading,
  zipcode: state.stores.zipcode,
  streetName: state.stores.streetName,
  streetNumber: state.stores.streetNumber,
  complement: state.stores.complement,
  district: state.stores.district,
  uf: state.stores.uf,
  city: state.stores.city,
  ibge: state.stores.ibge,
  name: state.stores.name,
  searchText: state.stores.searchText,
})

const mapDispatchToProps = (dispatch: any) => ({
  resetOperators: () => dispatch(OperatorsSliceReducer.actions.resetState()),
  resetRetailerOriginationParameters: () => dispatch(RetailersSliceReducer.actions.originationsParametersReset()),
  fetchRetailerStores: (retailerId: string) => dispatch(StoresSliceReducer.actions.fetchRetailerStores(retailerId)),
  fetchStore: (storeId: string) => dispatch(StoresSliceReducer.actions.fetchStore(storeId)),
  fetchStoresEmails: (stores: string[]) => dispatch(StoresSliceReducer.actions.fetchStoreEmails(stores)),
  searchGooglePlace: (body: IPostGooglePlaceSearch) => dispatch(StoresSliceReducer.actions.searchGooglePlace(body)),
  searchGooglePlaceLoading: () => dispatch(StoresSliceReducer.actions.searchGooglePlaceLoading()),
  createStore: (body: PostStoreRequest) => dispatch(StoresSliceReducer.actions.createStore(body)),
  createStoreModalOpen: () => dispatch(StoresSliceReducer.actions.createStoreModalOpen()),
  createStoreModalClose: () => dispatch(StoresSliceReducer.actions.createStoreModalClose()),
  setStoreAddress: (address: IStoreAddress) => dispatch(StoresSliceReducer.actions.setStoreAddress(address)),
  updateStoreFields: (fields: any) => dispatch(StoresSliceReducer.actions.updateStoreFields(fields)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StoresTab)
