import { Button } from '@bit/ume.design-kit.ui'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Box } from 'grommet'
import { FormPreviousLink } from 'grommet-icons'
import moment from 'moment'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { toaster } from '../../App'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import GrommetDatePicker from '../../molecules/DatePicker/GrommetDatePicker'
import { BatchCard } from '../../molecules/FinancialOriginations/BatchCard'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { IBatchResponse } from '../../services/bff-backoffice/liquidation-originations/interfaces/liquidation-originations.interfaces'

interface IOriginatonsBatchSearchPageProps {
  onClose: () => void
}

export const OriginationsBatchSearchPage = (props: IOriginatonsBatchSearchPageProps) => {
  let tomorrow = moment()
    .add(1, 'day')
    .format('YYYY-MM-DD')

  let [originationDate, setOriginationDate] = useState(String)
  let [loadingCounter, setLoadingCounter] = useState(0)
  let [loadingIcon, setLoadingIcon] = useState(false)
  let [loadedBatches, setLoadedBatches] = useState<IBatchResponse[]>([])

  // On Invoice due date selected
  const onDueDateSelect = (date: string) => {
    setOriginationDate(date)
  }

  const handleSearch = () => {
    // Validate given date
    if (!originationDate || originationDate.length === 0) {
      return toaster.showErrorToast(`Informe uma data válida`)
    }

    const backendOriginationDate = moment(originationDate, 'DD/MM/YYYY').format('YYYY-MM-DD')

    setLoadingIcon(true)
    setLoadingCounter(loadingCounter + 1)

    bffBackofficeApiService.liquidationOriginations
      .getBatches({
        originationsStartDate: backendOriginationDate,
        originationsEndDate: backendOriginationDate,
      })
      .then(response => {
        setLoadingIcon(false)
        setLoadedBatches(response.data)

        toaster.showSuccessToast(`Remessas encontradas!`)
      })
      .catch(() => {
        setLoadingIcon(false)
        setLoadedBatches([])
        toaster.showWarningToast(`Nenhuma remessa encontrada com esses parâmetros`)
      })
  }

  const renderSearch = () => {
    // Didn't search once
    if (!loadingCounter && !loadingIcon) {
      return <SearchFeedback>Nenhuma busca realizada.</SearchFeedback>
    }
    // Is loading
    if (loadingIcon) {
      return <LoadingIcon />
    }
    if (loadingCounter && !loadingIcon && !loadedBatches.length) {
      return <SearchFeedback> Nenhum resultado encontrado :( </SearchFeedback>
    }
    // Found results
    if (loadingCounter && !loadingIcon) {
      return loadedBatches.map((batch, index) => (
        <Link to={`/financial/originations/batch/${batch.id}`}>
          <BatchCard
            style={{ marginBottom: '40px' }}
            date={batch.batchDate}
            price={batch.principalSum}
            quantity={batch.originationsCount}
            key={index}
          />
        </Link>
      ))
    }
  }

  return (
    <Container>
      <Box margin={{ vertical: '6vh' }} direction="row" align="center" gap="xsmall">
        <PreviousButtonStyle
          onClick={() => props.onClose()}
          width="32px"
          height="32px"
          style={{ marginLeft: 0 }}
          icon={<FormPreviousLink color="white" />}
        />
        <PageTitle> Buscar remessas </PageTitle>
      </Box>

      <Box justify="start" direction="row" align="center" margin={{ top: '2vh' }}>
        <OriginationDateContainer>
          <span> Data de originação: </span>
          <OriginationDatePicker onDateSelect={onDueDateSelect} bounds={['2020-01-01', tomorrow]} />
        </OriginationDateContainer>
        <SearchButton onClick={handleSearch}>Buscar</SearchButton>
      </Box>

      <StyledHr />

      <PageTitle> Resultado da busca </PageTitle>

      <Box margin={{ top: '3vh' }} direction="column" align="start" justify="start">
        {renderSearch()}
      </Box>
    </Container>
  )
}

const SearchFeedback = styled.h1`
  color: ${UMEColors.gray.primary};
`

const SearchButton = styled(Button)`
  background-color: ${UMEColors.blue.dark};

  height: 32px;
  width: 104px;
  color: ${UMEColors.white.primary};
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;

  margin: 0;
  margin-left: 2vw;

  align-self: flex-end;
`

const StyledHr = styled.hr`
  opacity: 0.3;

  margin-top: 4vh;
  margin-bottom: 4vh;
`

const Container = styled.div`
  background-color: white;
  min-height: 95vh;
  background-color: ${UMEColors.gray.azure};
  text-align: left;
}
`

const PreviousButtonStyle = styled(Button)`
  padding: 0;
`
const PageTitle = styled.span`
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: left;
`
const OriginationDateContainer = styled.div`
  text-align: left;
  color: ${UMEColors.black.primary};
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: -0.44px;

  & > span {
    font-size: 18px;
  }
`

const OriginationDatePicker = styled(GrommetDatePicker)`
  margin-top: 4px;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid ${UMEColors.gray.light};
  border-radius: 4px;
  background-color: ${UMEColors.white.primary};

  // TODO - Not in Design Kit
  color: #585858;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.47px;
`
