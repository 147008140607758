import React, { Component } from 'react'
import ErrorStripe from './ErrorStripe'

interface Props {
  error: string
  details?: string
  // when the error is hidden, will call this callback
  onErrorTimeout?: () => void
  timeout?: number
}

interface State {
  hidden: boolean
}

export default class FormError extends Component<Props, State> {
  state = {
    hidden: true,
  }

  componentDidMount() {
    // after 100ms, will set hidden to false, to ease-in the error stripe
    setTimeout(() => {
      this.setState({
        hidden: false,
      })
    }, 100)
    setTimeout(
      () => {
        this.setState({
          hidden: true,
        })
        setTimeout(() => this.props.onErrorTimeout && this.props.onErrorTimeout(), 1200)
      },
      this.props.timeout ? this.props.timeout : 5000
    )
  }

  render() {
    return (
      <ErrorStripe isHidden={this.state.hidden}>
        <h3>Erro: {this.props.error}</h3>
        {this.props.details && <p> Detalhes: {this.props.details} </p>}
      </ErrorStripe>
    )
  }
}
