import { Button, Text } from '@bit/ume.design-kit.ui'
import { blue, gray } from '@bit/ume.design-kit.ui/utils/_colors'
import { Box } from 'grommet'
import styled from 'styled-components'

export const CardStyle = styled(Box)`
  border-radius: 8px;
  max-width: 300px;
  min-width: 250px;
  height: 850px;
  padding-top: 22px;
  border: dashed 2px ${gray.alto};
  padding: 25px;
  margin: 25px 0 0 50px;
`
export const Divider = styled.hr`
  border-top: 2px solid ${gray.alto};
  width: 100%;
`
export const LabelStyle = styled.label`
  margin-bottom: 0;
  color: ${gray.eclipse};
  width: 150px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0;
`
export const DataStyle = styled(Text)`
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0;
`
export const ButtonStyle = styled(Button)`
  width: 100%;
  margin: 15px 0 15px 0;
  padding: 10px 0 10px 0;
  font-family: Roboto;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: 300;
`
export const DifferenceTextStyle = styled.p`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  letter-spacing: 0;
`
export const LoadingBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 195px;
  width: 1000px;
`
export const LinkAnchor = styled.a`
  color: ${blue.bay};
  font-weight: bold;
  width: 150px;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0;
`
