import React from 'react'
import { Upload, Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { UploadProps } from 'antd/lib/upload'

export const UploadItem = (props: UploadProps) => (
  <Upload {...props}>
    <Button>
      <UploadOutlined /> Click to Upload
    </Button>
  </Upload>
)
