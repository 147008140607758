import { Card, Tag } from '@bit/ume.design-kit.ui'
import { gray } from '@bit/ume.design-kit.ui/Palette'
import styled from 'styled-components'

export const CardStyle = styled(Card)`
  border-radius: 8px;
  height: 553px;
  max-width: 1360px;
  padding: 0px 20px;
  margin-top: 12px;

  overflow-x: auto;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${gray.gainsboro};
    border-left: 2px solid white;
    border-right: 2px solid white;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${gray.eclipse};
    border-radius: 9px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${gray.nobel};
  }
`

export const TagStyle = styled(Tag)`
  &:nth-child(1) {
    height: 22px;
    width: 98px;
    border-radius: 4px;
    padding: 5px 8px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    justify-content: start;
  }
  &:nth-child(2) {
    margin-left: 12px;
  }
`
