import styled from 'styled-components'
import { black, gray } from '@bit/ume.design-kit.ui/utils/_colors'
import { Tag } from '@bit/ume.design-kit.ui'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 32px 32px 8px;

  > div {
    width: -webkit-fill-available;
  }

  > div > div > button {
    width: -webkit-fill-available;
  }
`
const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 4px 12px;
`

const BreadcrumbsBox = styled.div`
  display: block;
  font-size: 10px;
` 
  
const BreadcrumbsItem = styled.span`
  margin: 0 6px;  
  color: ${props => props.active ? black.primary : gray.nobel};
`

const AttendanceGroup = styled.span`
  display: flex;
  flex-direction: column;
  margin: 12px auto;
  height: 4em;

  .key {
    color: ${gray.nobel};
    font-size: 12px;
  }

  .value {
    color: ${black.primary};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
  }
`

const StatusTag = styled(Tag)`
  width: fit-content;
  height: unset;
  margin: 2px;
  padding: 2px;

  > span {
    height: auto;
    padding: unset;
  }

  &:hover {
    opacity: 1;
    cursor: auto;
  }
`

const TagBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0;
  padding: 0 4px;
  box-sizing: border-box;
  height: auto;
  width: 296px;
  border: 1px solid ${gray.light};
  border-radius: 8px;
  cursor: not-allowed;
  
  > div > span {
    cursor: not-allowed;
  }
`

const PageBox = styled.span`
  cursor: pointer;
  margin: auto 0;
`

export {
  AttendanceGroup,
  BreadcrumbsBox,
  BreadcrumbsItem,
  Container,
  Component,
  StatusTag,
  TagBox,
  PageBox,
}