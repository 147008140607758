import { Card, Clipboard, Loading, Text } from '@bit/ume.design-kit.ui'
import { gray } from '@bit/ume.design-kit.ui/Palette'
import { Box } from 'grommet'
import { User } from 'grommet-icons'
import moment from 'moment'
import * as React from 'react'
import Lightbox from 'react-lightbox-component'
import styled from 'styled-components'
import { toaster } from '../../../App'
import { ResponsiveGrid } from '../../../atoms/ResponsiveGrid/ResponsiveGrid'
import maskCpf from '../../../common/assets/utils/cpfMask'
import maskPhone from '../../../common/assets/utils/phoneMask'
import { IBorrowerSummaryProfile } from '../BorrowerProfilePage'
import UpdateUnverifiedPhoneModal from '../UpdateUnverifiedPhone/UpdateUnverifiedPhoneModal'
import { BorrowerDataBoxStyle, InfoTextStyle, LabelStyle, PictureCardStyle, PictureLabelStyle } from './style'

export interface IRegistrationDataCardProps {
  borrowerSummary?: IBorrowerSummaryProfile | Error
}

export interface BorrowerSettingsProps {
  disablePurchase: boolean
  allowedToPayAnywhere?: boolean
}

type State = {
  isUpdateUnverifiedPhoneModalVisible: boolean
}

const initialState: State = {
  isUpdateUnverifiedPhoneModalVisible: false,
}

export default class RegistrationDataCard extends React.Component<IRegistrationDataCardProps, State> {
  constructor(props: IRegistrationDataCardProps) {
    super(props)
    this.state = initialState
  }

  public render() {
    const { borrowerSummary } = this.props

    const openUpdateUnverifiedPhoneModal = () => {
      this.setState({ isUpdateUnverifiedPhoneModalVisible: true })
    }

    const handleUpdateUnverifiedPhoneModalOnClose = () => {
      this.setState({ isUpdateUnverifiedPhoneModalVisible: false })
    }

    const errorCard = (
      <Text color="red" light>
        Não foi possível carregar os dados para este cliente.
      </Text>
    )

    const borrowerSettingsSection = (borrowerSettings: BorrowerSettingsProps) => {
      const isBorrowerAbleToPurchaseText = borrowerSettings.disablePurchase ? 'Não' : 'Sim'

      const isBorrowerEnabledToPayAnywhereText =
        borrowerSettings.allowedToPayAnywhere === undefined
          ? '-'
          : borrowerSettings.allowedToPayAnywhere
          ? 'Sim'
          : 'Não'

      return (
        <Box>
          <h3>Configurações</h3>
          <Box fill>
            <ResponsiveGrid
              columns={['296px', '230px']}
              style={{
                gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                marginLeft: '8rem',
              }}
            >
              <BorrowerDataBoxStyle>
                <LabelStyle>Habilitado para compras:</LabelStyle>
                <InfoTextStyle color={borrowerSettings?.disablePurchase ? 'red' : undefined}>
                  {isBorrowerAbleToPurchaseText}
                </InfoTextStyle>
              </BorrowerDataBoxStyle>
              <BorrowerDataBoxStyle>
                <LabelStyle>Pix habilitado?:</LabelStyle>
                <InfoTextStyle>{isBorrowerEnabledToPayAnywhereText}</InfoTextStyle>
              </BorrowerDataBoxStyle>
            </ResponsiveGrid>
          </Box>
        </Box>
      )
    }

    const borrowerCard = (borrower: IBorrowerSummaryProfile) => (
      <Box gap="0.3rem">
        <Box direction="row">
          <Box basis="144px" justify="between">
            {borrower.portraitBase64 ? (
              <Box margin={{ top: '4px' }} height="116px" justify="center">
                <Lightbox
                  thumbnailMargin="0px"
                  images={[
                    {
                      src: !borrower.portraitBase64.startsWith('data:')
                        ? `data:image/jpeg;base64,${borrower.portraitBase64}`
                        : borrower.portraitBase64,
                      title: 'Foto do Comprador',
                      description: borrower.name,
                    },
                  ]}
                  thumbnailWidth="68px"
                  thumbnailHeight="auto"
                  thumbnailBorder="4px"
                />
              </Box>
            ) : (
              <PictureCardStyle>
                <Box justify="center" align="center">
                  <User color={gray.primary} size="32px" />
                  <PictureLabelStyle>IMAGEM</PictureLabelStyle>
                </Box>
              </PictureCardStyle>
            )}
          </Box>
          <Box fill>
            <ResponsiveGrid
              columns={['296px', '230px']}
              margin="12px"
              style={{
                gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                margin: '12px',
                marginRight: 0,
              }}
            >
              <Box margin={{ bottom: '8px' }}>
                <LabelStyle>Nome:</LabelStyle>
                <InfoTextStyle>{borrower.name}</InfoTextStyle>
              </Box>
              <BorrowerDataBoxStyle>
                <LabelStyle>ID do cliente:</LabelStyle>
                <InfoTextStyle>{borrower.borrowerId}</InfoTextStyle>
              </BorrowerDataBoxStyle>
              <BorrowerDataBoxStyle>
                <LabelStyle>CPF:</LabelStyle>
                <InfoTextStyle>
                  <div
                    title="Copiar CPF"
                    onClick={() => toaster.showSuccessToast('Copiado para área de transferência')}
                  >
                    <Clipboard label={maskCpf(borrower.cpf)} value={maskCpf(borrower.cpf)} />
                  </div>
                </InfoTextStyle>
              </BorrowerDataBoxStyle>
              <BorrowerDataBoxStyle>
                <LabelStyle>Telefone principal:</LabelStyle>
                {borrower.phoneNumber ? (
                  <InfoTextStyle>{maskPhone(borrower.phoneNumber)}</InfoTextStyle>
                ) : (
                  <>
                    <InfoTextStyle color={'red'}>Cadastro incompleto, telefone não verificado.</InfoTextStyle>
                    <a style={{ textDecoration: 'underline' }} onClick={openUpdateUnverifiedPhoneModal}>
                      Clique aqui para atualizar o telefone.
                    </a>
                  </>
                )}
              </BorrowerDataBoxStyle>
              {borrower?.phones
                ?.filter(phone => phone.phoneType === 'SECONDARY')
                ?.map(phone => (
                  <BorrowerDataBoxStyle>
                    <LabelStyle>Telefone adicional:</LabelStyle>
                    <InfoTextStyle>{maskPhone(phone.phoneNumber)}</InfoTextStyle>
                  </BorrowerDataBoxStyle>
                ))}
              <BorrowerDataBoxStyle>
                <LabelStyle>Nascimento:</LabelStyle>
                <InfoTextStyle>
                  {moment(borrower.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' (' + borrower.age + ' anos)'}
                </InfoTextStyle>
              </BorrowerDataBoxStyle>
              <BorrowerDataBoxStyle>
                <LabelStyle>E-mail:</LabelStyle>
                <InfoTextStyle>{borrower.email || '-'}</InfoTextStyle>
              </BorrowerDataBoxStyle>
              <BorrowerDataBoxStyle>
                <LabelStyle>Cadastro:</LabelStyle>
                <InfoTextStyle>{borrower.enableHighRecurrence ? 'Ume leve' : 'Ume convencional'}</InfoTextStyle>
              </BorrowerDataBoxStyle>
              <BorrowerDataBoxStyle>
                <LabelStyle>Membro desde:</LabelStyle>
                <InfoTextStyle>{moment(borrower.createdOn, 'YYYY-MM-DD').format('DD/MM/YYYY')}</InfoTextStyle>
              </BorrowerDataBoxStyle>
            </ResponsiveGrid>
          </Box>
        </Box>
        <SectionCardDivider />
        {borrowerSettingsSection(borrower.borrowerSettings)}
      </Box>
    )

    if (!borrowerSummary) {
      return (
        <CardStyle style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 220 }}>
          <Loading />
        </CardStyle>
      )
    }

    if (borrowerSummary instanceof Error) {
      return (
        <CardStyle style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 220 }}>
          {errorCard}
        </CardStyle>
      )
    }

    return (
      <CardStyle>
        {borrowerCard(borrowerSummary)}
        <UpdateUnverifiedPhoneModal
          borrowerId={borrowerSummary.borrowerId}
          isModalVisible={this.state.isUpdateUnverifiedPhoneModalVisible}
          onCloseModal={handleUpdateUnverifiedPhoneModalOnClose}
        />
      </CardStyle>
    )
  }
}

const CardStyle = styled(Card)`
  border-radius: 8px;
  padding: 24px;
  padding-bottom: 0px;

  max-width: 680px;
`
const SectionCardDivider = styled.hr`
  width: 100%;
  border: 1px solid rgba(16, 22, 26, 0.15);
  align-self: center;
  margin-top: -0.5rem;
`
