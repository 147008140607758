import { formatNumberToBRL, formatDate } from '../../../utils'

const originationsOperationsHeader = [
  { property: 'createdOn', label: 'Data', format: formatDate },
  { property: 'retailerName', label: 'Varejista' },
  { property: 'storeName', label: 'Loja' },
  { property: 'operatorName', label: 'Operador' },
  { property: 'borrowerName', label: 'Tomador' },
  { property: 'borrowerPhoneNumber', label: 'Telefone Tomador' },
  { property: 'principal', label: 'Principal', format: formatNumberToBRL },
  { property: 'numberOfInstallments', label: 'Número de Parcelas' },
  { property: 'installmentValue', label: 'Valor das Parcelas', format: formatNumberToBRL },
]

export const OriginationsOperationsConfig = {
  label: 'Originações (operações)',
  route: '/originations/operations',
  header: originationsOperationsHeader,
}
