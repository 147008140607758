import { AxiosError } from 'axios'
import { ApplicationOnlineErrorType } from '../../../services/bff-backoffice/coordinator/interfaces/applications.interfaces'

const HttpConnectionError = `Verifique se o seu dispositivo tem acesso a internet`
const HttpAxiosRequestError = `Erro - Contate a UME`
const HttpUnkownError = `Err desconhecido - Contente a UME`

const HttpErrorsToString: { [message: string]: string } = {
  [ApplicationOnlineErrorType.LIMIT_NOT_CREATED]: 'Erro ao criar limite para o cliente.',
  [ApplicationOnlineErrorType.REQUIRED_DENIAL_REASONS]: 'Você precisa informar pelo menos um motivo para a negação.',
  [ApplicationOnlineErrorType.APPLICATION_MUST_BE_WAITING_MANUAL_APPROVAL]:
    'Application deve estar esperando por aprovação manual.',
  [ApplicationOnlineErrorType.APPLICATION_NOT_FOUND]: 'Application não encontrado.',
  [ApplicationOnlineErrorType.NOT_AN_ONLINE_APPLICATION]: 'Não é possível avaliar um application que não seja online.',
}

export const transformHttpApplicationsErrorsToString = (error: AxiosError): string => {
  let message = null
  if (error.response) {
    // TODO - This API uses the error attribute in the response
    if (error?.response?.data && error?.response?.data?.message) {
      message = HttpErrorsToString[error?.response?.data?.message]
    } else {
      message = HttpUnkownError
    }
  } else if (error?.request) {
    message = HttpConnectionError
  } else {
    message = HttpAxiosRequestError
  }

  if (!message) {
    return `Serviço indisponível temporariamente`
  }

  return message
}
