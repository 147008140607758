import styled from 'styled-components'
import { medium, large, xlarge, small, xsmall } from '../../common/assets/_sizes'
import { blue } from '../../common/assets/_colors'
import { mobile } from '../../common/assets/_breakpoints'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${xlarge};
`

const ImgHero = styled.img`
  width: 100%;
  margin: 0 auto 0 auto;

  @media (min-width: ${mobile}) {
    width: 100vh;
  }
`

const InfoComponent = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;
  background-color: white;
  padding: ${large};

  > h1,
  h2,
  h3,
  h4 {
    margin: unset;
  }

  > button {
    background-color: ${blue.dark};
    border: none;
    color: white;
    padding: ${medium} ${large};
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: ${large};
    margin: ${small} ${xsmall};
    cursor: pointer;
  }

  @media (min-width: ${mobile}) {
    width: 100vh;
  }
`
export { Container, InfoComponent, ImgHero }
