import axios, { AxiosInstance } from 'axios'
import { IBorrowerResponse } from '../borrowers/interfaces/borrower.interfaces'
import { getAxiosInstance } from '../utils'
import {
  IForcedApprovalBody,
  IPostApplicationForcedApprovalAnalysisResponse,
} from './interfaces/applications.interfaces'
import { Borrower, IBorrowerEdition, IBorrowerLimits } from './interfaces/borrower.interfaces'
import { IChargeFeeInstallment } from './interfaces/charge-fees.interfaces'
import {
  IContract,
  IContractFetchQueryParams,
  IContracts,
  IInstallmentQueryParams,
  IOriginationSummary,
} from './interfaces/contracts.interfaces'
import {
  ICreateFinancingOptions,
  ICreateFinancingOptionsResponse,
  ICreateFinancingOptionsValidationResult,
} from './interfaces/financing-options.interfaces'
import { IInstallments, InstallmentSimulation, ISingleInstallment } from './interfaces/installment.interfaces'
import { IGetInvoicesQueryParams, IInvoice, InvoiceGeneratedAtType } from './interfaces/invoice.interfaces'
import {
  IGetLimitPolicy,
  ILimitPolicyResponse,
  IPostLimitsValidateBody,
  IPostLimitsValidateResponse,
  IPutLimits,
} from './interfaces/limits.interfaces'
import {
  IGetOriginationsParameters,
  IOriginationsParametersResponse,
  IPutOriginationsParameters,
} from './interfaces/parameters.interfaces'
import {
  FetchPaymentsByIdsResponse,
  IDebtRenegotiationRequest,
  IRenegotiationResponse,
  IRenegotiationsResponse,
  RenegotiationPaymentResponse,
} from './interfaces/renegotiation.interfaces'
import { IGetRetailerOperators, IOperator, IOperatorPostReq } from './interfaces/retailers.interfaces'
import {
  IBorrowerTagSystemProfilesList,
  IGetBorrowerTagSystem,
  IPrioritizationSummary,
  IPrioritizationSummaryGetReq,
  ITagAvailableList,
} from './interfaces/tag-system.interfaces'

export default class CoordinatorService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async findBorrowerById(borrowerId: string): Promise<Borrower> {
    const response = await this.axiosInstance.get<Borrower>(`/coordinator/borrowers/${borrowerId}`)
    return response.data
  }

  async simulateRenegotiation(body: IDebtRenegotiationRequest): Promise<IRenegotiationResponse> {
    const response = await this.axiosInstance.post<IRenegotiationResponse>(
      `/coordinator/renegotiations/simulations`,
      body
    )
    return response.data
  }

  async renegotiate(body: IDebtRenegotiationRequest): Promise<IRenegotiationResponse> {
    const response = await this.axiosInstance.post<IRenegotiationResponse>(`/coordinator/renegotiations`, body)
    return response.data
  }

  async getContracts(params: IContractFetchQueryParams): Promise<IContracts> {
    const response = await this.axiosInstance.get<IContracts>(`/coordinator/contracts`, { params })
    return response.data
  }

  async getInstallmentsFees(borrowerId: string): Promise<IChargeFeeInstallment[]> {
    const response = await this.axiosInstance.get<IChargeFeeInstallment[]>(`/coordinator/charge-fees/installments`, {
      params: { borrowerId },
    })
    return response.data
  }

  async getContractsInstallments(params: IInstallmentQueryParams): Promise<IInstallments> {
    const response = await this.axiosInstance.get<IInstallments>(`/coordinator/contracts/installments`, {
      params,
    })
    return response.data
  }

  async getContractById(id: string, allowUnacceptedContract?: boolean): Promise<IContract> {
    const response = await this.axiosInstance.get<IContract>(`/coordinator/contracts/${id}`, {
      params: allowUnacceptedContract,
    })
    return response.data
  }

  async getInvoices(params: IGetInvoicesQueryParams): Promise<IInvoice[]> {
    const response = await this.axiosInstance.get<IInvoice[]>(`/coordinator/invoices`, { params })
    return response.data
  }

  async getRenegotiations(borrowerId: string): Promise<IRenegotiationsResponse> {
    const response = await this.axiosInstance.get<IRenegotiationsResponse>(`/coordinator/renegotiations`, {
      params: { borrowerId },
    })
    return response.data
  }

  async getBorrowersTagSystem(params: IGetBorrowerTagSystem): Promise<IBorrowerTagSystemProfilesList> {
    const response = await this.axiosInstance.get<IBorrowerTagSystemProfilesList>(
      `/coordinator/tag-system/borrower-profiles`,
      { params }
    )
    return response.data
  }

  async getAllBorrowerLimits(borrowerId: string): Promise<IBorrowerLimits> {
    const response = await this.axiosInstance.get<IBorrowerLimits>(`/coordinator/borrowers/${borrowerId}/all-limits`)
    return response.data
  }

  async getContractsOriginationsSummary(borrowerId: string, retailerId?: string): Promise<IOriginationSummary> {
    const response = await this.axiosInstance.get<IOriginationSummary>(`/coordinator/contracts/originations/summary`, {
      params: { borrowerId, retailerId },
    })
    return response.data
  }

  async putBorrower(borrowerId: string, body: IBorrowerEdition): Promise<IBorrowerResponse> {
    const response = await this.axiosInstance.put<IBorrowerResponse>(`/coordinator/borrowers/${borrowerId}`, body)
    return response.data
  }

  async createInvoiceForInstallments(
    installmentsIds: string[],
    dueDate: string,
    generatedAt: InvoiceGeneratedAtType,
    invoiceValue?: number
  ): Promise<IInvoice> {
    const body = { installmentsIds, dueDate, invoiceValue, generatedAt }
    const response = await this.axiosInstance.post<IInvoice>(`/coordinator/invoices/multiple`, body)
    return response.data
  }

  async simulateInstallmentsByDate(installmentsIds: string[], date?: string): Promise<InstallmentSimulation[]> {
    const response = await this.axiosInstance.get<InstallmentSimulation[]>(
      `/coordinator/contracts/installments/simulate-multiple-installments`,
      { params: { installmentsIds: installmentsIds.toString(), date } }
    )
    return response.data
  }

  async validateNewFinancingOptions(body: ICreateFinancingOptions): Promise<ICreateFinancingOptionsValidationResult> {
    const response = await this.axiosInstance.post<ICreateFinancingOptionsValidationResult>(
      `/coordinator/financing-options/validate`,
      body
    )
    return response.data
  }

  async createFinancingOptions(body: ICreateFinancingOptions): Promise<ICreateFinancingOptionsResponse> {
    const response = await this.axiosInstance.put<ICreateFinancingOptionsResponse>(
      `/coordinator/financing-options`,
      body
    )
    return response.data
  }

  async validateApplicationApprovalAnalysis(
    body: IForcedApprovalBody
  ): Promise<IPostApplicationForcedApprovalAnalysisResponse> {
    const response = await this.axiosInstance.post<IPostApplicationForcedApprovalAnalysisResponse>(
      `/coordinator/applications/approve/validate`,
      body
    )
    return response.data
  }

  async forceApplicationApprove(body: IForcedApprovalBody): Promise<void> {
    const response = await this.axiosInstance.put<void>(`/coordinator/applications/approve`, body)
    return response.data
  }

  async getContractInstallmentById(id: string): Promise<ISingleInstallment> {
    const response = await this.axiosInstance.get<ISingleInstallment>(`/coordinator/contracts/installments/${id}`)
    return response.data
  }

  async validateLimits(body: IPostLimitsValidateBody): Promise<IPostLimitsValidateResponse> {
    const response = await this.axiosInstance.post<IPostLimitsValidateResponse>(`/coordinator/limits/validate`, body)
    return response.data
  }

  async updateLimits(body: IPutLimits) {
    const response = await this.axiosInstance.put(`/coordinator/limits`, body)
    return response.data
  }

  async getLimitPolicy(params: IGetLimitPolicy): Promise<ILimitPolicyResponse> {
    const response = await this.axiosInstance.get<ILimitPolicyResponse>(`/coordinator/limits/policies`, {
      params,
    })
    return response.data
  }

  async resetBorrowerPassword(): Promise<void> {
    const response = await this.axiosInstance.post<void>(`/coordinator/borrowers/reset-password`)
    return response.data
  }

  async getTags(): Promise<ITagAvailableList> {
    const response = await this.axiosInstance.get<ITagAvailableList>(`/coordinator/tag-system/tags`)
    return response.data
  }

  async getTagsPrioritizationSummary(params: IPrioritizationSummaryGetReq): Promise<IPrioritizationSummary> {
    const response = await this.axiosInstance.get<IPrioritizationSummary>(`/coordinator/tag-system/summary`, { params })
    return response.data
  }

  async getRenegotiationsPaymentsByIds(params: {
    ids: string[]
    paymentDate?: string
  }): Promise<FetchPaymentsByIdsResponse> {
    const response = await this.axiosInstance.get<FetchPaymentsByIdsResponse>(
      `/coordinator/renegotiations/payments/simulations`,
      {
        params,
      }
    )
    return response.data
  }

  async getRenegotiationById(renegotiationId: string): Promise<IRenegotiationResponse> {
    const response = await this.axiosInstance.get<IRenegotiationResponse>(
      `/coordinator/renegotiations/${renegotiationId}`
    )
    return response.data
  }

  async defaultRenegotiation(renegotiationId: string): Promise<IRenegotiationResponse> {
    const response = await this.axiosInstance.post<IRenegotiationResponse>(
      `/coordinator/renegotiations/${renegotiationId}/defaulted`
    )
    return response.data
  }

  async getOneRenegotiationPaymentById(paymentId: string, paymentDate?: string): Promise<RenegotiationPaymentResponse> {
    const response = await this.axiosInstance.get<RenegotiationPaymentResponse>(
      `/coordinator/renegotiations/payments/${paymentId}`,
      { params: paymentDate }
    )
    return response.data
  }

  async createInvoiceForMultiplePayments(
    renegotiationId: string,
    paymentIds: string[],
    dueDate: string,
    generatedAt: InvoiceGeneratedAtType
  ): Promise<IInvoice> {
    const body = { paymentIds, dueDate, generatedAt }

    const response = await this.axiosInstance.post<IInvoice>(
      `/coordinator/renegotiations/${renegotiationId}/payments/invoices`,
      body
    )
    return response.data
  }

  async getRetailerOperators(retailerId: string): Promise<IGetRetailerOperators> {
    const response = await this.axiosInstance.get<IGetRetailerOperators>(
      `/coordinator/retailers/${retailerId}/operators`
    )
    return response.data
  }

  async createOperator(body: IOperatorPostReq): Promise<IOperator> {
    const response = await this.axiosInstance.post<IOperator>(`/coordinator/operators`, body)
    return response.data
  }

  async putOriginationsParameters(body: IPutOriginationsParameters): Promise<IOriginationsParametersResponse> {
    const response = await this.axiosInstance.put<IOriginationsParametersResponse>(
      `/coordinator/parameters/originations`,
      body
    )
    return response.data
  }

  async getOriginationsParameters(params: IGetOriginationsParameters): Promise<IOriginationsParametersResponse> {
    const response = await this.axiosInstance.get<IOriginationsParametersResponse>(
      `/coordinator/parameters/originations`,
      { params }
    )
    return response.data
  }

  async startResetPasswordProcess(): Promise<void> {
    const response = await this.axiosInstance.post<void>(`/coordinator/borrowers/start-reset-password-process`)
    return response.data
  }
}

