import { gray, green, red, white } from '@bit/ume.design-kit.ui/Palette'
import { yellow } from '@bit/ume.design-kit.ui/utils/_colors'
import * as React from 'react'
import { IRenegotiationResponse } from '../../services/bff-backoffice/coordinator/interfaces/renegotiation.interfaces'
import { StatusTagStyle } from './style'

const moment = require('moment')

export interface IRenegotiationStatusProps {
  renegotiation?: IRenegotiationResponse | Error
}

export default class RenegotiationStatus extends React.PureComponent<IRenegotiationStatusProps> {
  public render() {
    const { renegotiation } = this.props

    if (!renegotiation) {
      return
    }

    const statusTag = (renegotiation: IRenegotiationResponse) => {
      const status =
        renegotiation.status !== 'DUE'
          ? renegotiation.status
          : renegotiation.payments.filter(p => !p.paymentTimestamp && p.dueDate < moment().format('YYYY-MM-DD'))
              .length > 0
          ? 'OVERDUE'
          : 'DUE'

      switch (status) {
        case 'DUE':
          return (
            <StatusTagStyle
              title="Clique para ver mais detalhes"
              backgroundColor={gray.primary}
              label={'Aberto'}
              iconBackground="none"
              iconColor={white.primary}
            />
          )
        case 'OVERDUE':
          return (
            <StatusTagStyle
              title="Clique para ver mais detalhes"
              backgroundColor={red.primary}
              label={'Atrasado'}
              iconBackground="none"
              iconColor={white.primary}
            />
          )
        case 'PAID':
          return (
            <StatusTagStyle
              disable
              backgroundColor={green.laurel}
              label={'Pago'}
              iconBackground="none"
              iconColor={white.primary}
            />
          )
        case 'DEFAULTED':
          return (
            <StatusTagStyle
              disable
              backgroundColor={yellow.primary}
              label={'Quebrado'}
              iconBackground="none"
              iconColor={white.primary}
            />
          )
        default:
          return <></>
      }
    }
    return renegotiation === undefined ? <></> : renegotiation instanceof Error ? <></> : statusTag(renegotiation)
  }
}
