import { Loading, Text } from '@bit/ume.design-kit.ui'
import { Box } from 'grommet'
import * as React from 'react'
import { ResponsiveGrid } from '../../atoms/ResponsiveGrid/ResponsiveGrid'
import { InfoTextStyle, LabelStyle } from '../../pages/CS-Profile/ContractsSummaryCard/style'
import { IRenegotiationResponse } from '../../services/bff-backoffice/coordinator/interfaces/renegotiation.interfaces'
import { booleanToSimOrNao, formatDateTreatingError, formatNumberToBRL } from '../../utils'
import { CardStyle, DataBoxStyle } from './style'

export interface IRenegotiationDetailsProps {
  renegotiation?: IRenegotiationResponse | Error
}

export default class InstallmentDetails extends React.PureComponent<IRenegotiationDetailsProps> {
  public render() {
    const { renegotiation } = this.props

    const errorCard = (
      <Text color="red" light>
        Não foi possível carregar os dados para este acordo.
      </Text>
    )

    const RenegotiationDetails = (renegotiation: IRenegotiationResponse) => (
      <Box>
        <ResponsiveGrid columns="150px" gap="small">
          {renegotiation.id && [
            <DataBoxStyle>
              <LabelStyle>ID acordo</LabelStyle>
              <InfoTextStyle>{renegotiation.id ? renegotiation.id : '---'}</InfoTextStyle>
            </DataBoxStyle>,
            <DataBoxStyle>
              <LabelStyle>Data</LabelStyle>
              <InfoTextStyle>
                {formatDateTreatingError(renegotiation.createdOn ? renegotiation.createdOn : '')}
              </InfoTextStyle>
            </DataBoxStyle>,
          ]}

          <DataBoxStyle>
            <LabelStyle>Dívida</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(renegotiation.totalDebt)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Desconto</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(renegotiation.monetaryDiscount)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Valor renegociado</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(renegotiation.totalDebt - renegotiation.monetaryDiscount)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Taxa de Juros</LabelStyle>
            <InfoTextStyle>{(renegotiation.interestRate * 100).toPrecision(3) + '% (a.m)'}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Qtd parcelas</LabelStyle>
            <InfoTextStyle>{renegotiation.numberOfPayments}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Valor parcelas</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(renegotiation.paymentsValue)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Total em parcelas</LabelStyle>
            <InfoTextStyle>{formatNumberToBRL(renegotiation.renegotiationDueValue)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Vcto 1 parcela</LabelStyle>
            <InfoTextStyle>{formatDateTreatingError(renegotiation.firstPaymentDueDate)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Possui entrada?</LabelStyle>
            <InfoTextStyle>{booleanToSimOrNao(Boolean(renegotiation.upfrontPaymentDueDate))}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Vcto entrada</LabelStyle>
            <InfoTextStyle>{formatDateTreatingError(renegotiation.upfrontPaymentDueDate)}</InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Valor entrada</LabelStyle>
            <InfoTextStyle>
              {formatNumberToBRL(renegotiation.upfrontPayment ? renegotiation.upfrontPayment : undefined)}
            </InfoTextStyle>
          </DataBoxStyle>

          <DataBoxStyle>
            <LabelStyle>Data quitaçao</LabelStyle>
            <InfoTextStyle>
              {formatDateTreatingError(renegotiation.paymentTimestamp ? renegotiation.paymentTimestamp : '')}
            </InfoTextStyle>
          </DataBoxStyle>
        </ResponsiveGrid>
      </Box>
    )
    return (
      <CardStyle width="968px">
        {renegotiation === undefined ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : renegotiation instanceof Error ? (
          errorCard
        ) : (
          RenegotiationDetails(renegotiation)
        )}
      </CardStyle>
    )
  }
}
