// Backoffice screen Errors
export const ERROR_LOADING_APPLICATION_STATUS = 'Erro ao carregar Resumo dos Applications'
export const ERROR_LOADING_ORIGINATIONS_SUMMARIES = 'Erro ao carregar Resumo de Originações'
export const ERROR_LOADING_ORIGINATIONS_ANALYSIS = 'Erro ao carregar Análises de Originações'
export const ERROR_LOADING_DROPPED_CONTRACTS = 'Erro ao carregar Carrinhos Abandonados'
export const ERROR_LOADING_RECENT_APPLICATIONS_BY_STORE = 'Erro ao carregar Applications recentes por Loja'
export const ERROR_LOADING_APPLICATIONS_BY_STORE = 'Erro ao carregar Applications por Loja '
export const ERROR_LOADING_OVERDUE_INSTALLMENTS = 'Erro ao carregar Parcelas'

// Report Errors
export const SERVER_ERROR = 'Erro ao buscar dados no servidor.'
export const MENU_NOT_SELECTED = 'Selecione uma opção no menu.'
export const START_DATE_AFTER_END_DATE = 'Data de ínicio depois da data de fim.'
export const INVALID_DATE = 'Data inválida.'
