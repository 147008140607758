import React from 'react'
import { Card, Text } from '@bit/ume.design-kit.ui'
import { black, gray, blue, red, yellow } from '@bit/ume.design-kit.ui/utils/_colors'
import { AttendanceGroup, StatusTag } from './styles'
import { numberToCurrency } from '../../common/assets/utils/currencyMask'
import { PENDING_TAGS, OVERDUE_TAGS } from './tags'

interface IAttendanceCardProps {
  color?: string
  name?: string
  contracts?: number
  totalDebt: number
  tag: string
  borrowerId: string
  history: any
}

const AttendanceCard = (props: IAttendanceCardProps) => {
  return(
    <Card
      onClick={() => props.history.push(`/profiles/${props.borrowerId}`)}
      height="320px"
      width="208px"
      style={{ borderRadius: "8px", cursor: "pointer" }} >
      <AttendanceGroup>
        <Text
          color={gray.spanish}
          margin="none"
          textAlign="start"
          className="key"
          >
          Nome:
        </Text>
        <Text
          color={black.primary}
          textAlign="start"
          margin="none"
          className="value"
        >
          {props.name}
        </Text>
      </AttendanceGroup>
      <AttendanceGroup>
        <Text
          color={gray.spanish}
          margin="none"
          textAlign="start"
          className="key"
          >
          Contratos:
        </Text>
        <Text
          color={black.primary}
          textAlign="start"
          margin="none"
          className="value"
        >
          {props.contracts} contrato(s)
        </Text>
      </AttendanceGroup>
      <AttendanceGroup>
        <Text
          color={gray.spanish}
          margin="none"
          textAlign="start"
          className="key"
          >
          Valor total devido:
        </Text>
        <Text
          color={black.primary}
          textAlign="start"
          margin="none"
          className="value"
        >
          {numberToCurrency(props.totalDebt, true)}
        </Text>
      </AttendanceGroup>
      <AttendanceGroup>
        <Text
          color={black.primary}
          margin="none"
          textAlign="start"
          className="value"
          >
          Tags
        </Text>
        <StatusTag
          index={1}
          key={1}
          label={`#${props.tag}`}
          backgroundColor={getColorTag(props.tag)}
        />
      </AttendanceGroup>
    </Card>
  )
}

const getColorTag = (tagName: string) => {
  let color = yellow.citrine

  PENDING_TAGS.forEach((tag: string) => {
    if (tag === tagName) {
      color = blue.bay
    }
  })

  OVERDUE_TAGS.forEach((tag: string) => {
    if (tag === tagName) {
      color = red.persian
    }
  })

  return color
}

export { AttendanceCard }