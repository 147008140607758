import { Button, Checkbox, Clipboard, DataTable, Loading, Text } from '@bit/ume.design-kit.ui'
import { blue, gray, green, red, white } from '@bit/ume.design-kit.ui/Palette'
import { yellow } from '@bit/ume.design-kit.ui/utils/_colors'
import { Box } from 'grommet'
import moment from 'moment'
import * as React from 'react'
import { toaster } from '../../App'
import { IInvoice } from '../../services/bff-backoffice/coordinator/interfaces/invoice.interfaces'
import { RenegotiationPaymentResponse } from '../../services/bff-backoffice/coordinator/interfaces/renegotiation.interfaces'
import { formatDate, formatDateTreatingError, formatNumberToBRL } from '../../utils'
import RenegotiationInvoiceSimulationModal from './RenegotiationPaymentSimulationModal'
import {
  DifferenceTextStyle,
  HelperTextStyle,
  LinkAnchorStyle,
  SimulationsBoxStyle,
  StyledModal,
  SummaryBox,
  TableBoxStyle,
  TagStyle,
  TextSummary,
  ValueSummary,
} from './style'

export interface IInstallmentsCardProps {
  history?: any
  payments?: RenegotiationPaymentResponse[] | Error
  renegotiationId: string
}

export interface IInstallmentsCardState {
  layer: boolean
  date: string
  simulationLoading: boolean
  paymentsToSimulate: RenegotiationPaymentResponse[]
  numberOfInvoices: number
  totalValue: number

  lastSelectedRenegotiationPaymentOrder: number
  renegotiationPaymentsCheckboxCheckedList: boolean[]
}

const initialState: IInstallmentsCardState = {
  layer: false,
  date: '',
  simulationLoading: false,
  paymentsToSimulate: [],
  numberOfInvoices: 0,
  totalValue: 0,

  lastSelectedRenegotiationPaymentOrder: 1,
  renegotiationPaymentsCheckboxCheckedList: [],
}

export default class InstallmentsCard extends React.Component<IInstallmentsCardProps, IInstallmentsCardState> {
  constructor(props: IInstallmentsCardProps) {
    super(props)
    if (this.props.payments && !(this.props.payments instanceof Error)) {
      this.state = {
        ...initialState,
        renegotiationPaymentsCheckboxCheckedList: new Array(this.props.payments?.length + 1).fill(false),
        lastSelectedRenegotiationPaymentOrder:
          this.props.payments.sort((a, b) => a.order - b.order).find(item => item.status === 'DUE')?.order || 1,
      }
    } else {
      this.state = initialState
    }
  }

  updateSummary(checked: boolean, value: number, payment: RenegotiationPaymentResponse) {
    if (payment.order > this.state.lastSelectedRenegotiationPaymentOrder) {
      return toaster.showErrorToast('Selecione em ordem')
    }

    const newRenegotiationPaymentsCheckboxCheckedList = Object.assign(
      [],
      this.state.renegotiationPaymentsCheckboxCheckedList
    )
    const nextRenegotiationPaymentsCheckboxChecked = newRenegotiationPaymentsCheckboxCheckedList[payment.order + 1]
    if (!nextRenegotiationPaymentsCheckboxChecked) {
      newRenegotiationPaymentsCheckboxCheckedList[payment.order] = checked

      if (checked) {
        this.setState({
          totalValue: this.state.totalValue + value + 0,
          numberOfInvoices: this.state.numberOfInvoices + 1,
          lastSelectedRenegotiationPaymentOrder: payment.order + 1,
          renegotiationPaymentsCheckboxCheckedList: newRenegotiationPaymentsCheckboxCheckedList,
        })
      } else {
        this.setState({
          totalValue: this.state.totalValue - value + 0,
          numberOfInvoices: this.state.numberOfInvoices - 1,
          lastSelectedRenegotiationPaymentOrder: payment.order,
          renegotiationPaymentsCheckboxCheckedList: newRenegotiationPaymentsCheckboxCheckedList,
        })
      }
    }
  }

  columnsDropDown = [
    {
      label: 'Selecione',
      attribute: 'id',
      formatCell: (p: RenegotiationPaymentResponse) =>
        p.status === 'DUE' ? (
          <Checkbox
            hoverBorder={white.primary}
            id={p.id}
            name="ids"
            checked={this.state.renegotiationPaymentsCheckboxCheckedList[p.order]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              this.updateSummary(event.target.checked, p.paymentDue ? p.paymentDue : 0, p)
            }
          />
        ) : (
          <Checkbox hoverBorder={white.primary} id={p.id} disabled />
        ),
    },
    {
      label: 'ID',
      attribute: 'id',
    },
    {
      attribute: 'dueDate',
      label: 'Vencimento',
      formatCell: (p: RenegotiationPaymentResponse) => formatDate(p.dueDate),
    },
    {
      label: 'Valor da Parcela',
      formatCell: (p: RenegotiationPaymentResponse) => formatNumberToBRL(p.value),
    },
    {
      label: <Box width="100px">Descontos/Acréscimos</Box>,
      formatCell: (p: RenegotiationPaymentResponse) => {
        let difference = 0

        if (p.paidValue) {
          difference = p.paidValue - p.value
        } else if (p.paymentDue) {
          difference = p.paymentDue - p.value
        }

        if (difference > 0) {
          return <DifferenceTextStyle color={red.primary}>{'+' + formatNumberToBRL(difference)}</DifferenceTextStyle>
        } else {
          return <DifferenceTextStyle color={green.darkPastel}>{formatNumberToBRL(difference)}</DifferenceTextStyle>
        }
      },
    },
    {
      attribute: 'paymentDue',
      label: 'Valor atual',
      formatCell: (r: RenegotiationPaymentResponse) => formatNumberToBRL(r.paymentDue ? r.paymentDue : 0),
    },
    {
      attribute: 'invoiceUrl',
      label: 'Link do boleto',
      formatCell: (p: RenegotiationPaymentResponse) =>
        p.invoices && p.invoices.length > 0 ? (
          <Box direction="row" align="center" gap="small">
            <LinkAnchorStyle
              target="_blank"
              href={p.invoices.sort((a: IInvoice, b: IInvoice) => Number(b.id) - Number(a.id))[0].url + '.pdf'}
            >
              {p.invoices[0]?.barcode?.slice(0, 5) ? p.invoices[0]?.barcode?.slice(0, 5) + '...' : '---------'}
            </LinkAnchorStyle>
            <div
              title="Copiar código de barras"
              onClick={() => toaster.showSuccessToast('Copiado para área de transferência')}
            >
              <Clipboard
                value={p.invoices.sort((a: IInvoice, b: IInvoice) => Number(b.id) - Number(a.id))[0].url + '.pdf'}
                color={blue.bay}
              />
            </div>
          </Box>
        ) : (
          '-'
        ),
    },
    {
      label: <Box width="100px">Ordem da Parcela</Box>,
      formatCell: (p: RenegotiationPaymentResponse) => p.order,
    },
    {
      attribute: 'createdOn',
      label: 'Data acordo',
      formatCell: (p: RenegotiationPaymentResponse) => formatDateTreatingError(p.createdOn ? p.createdOn : ''),
    },
    {
      label: 'Status',
      attribute: 'status',
      dropDownValues: [
        { label: 'Todos', value: 'all' },
        { label: 'Pendente', value: 'PENDING' },
        { label: 'Vencida', value: 'PAYMENT_OVERDUE' },
        { label: 'Pago', value: 'PAID' },
      ],
      formatCell: (p: RenegotiationPaymentResponse) => {
        switch (p.status) {
          case 'DUE':
            return p.dueDate < moment().format('YYYY-MM-DD') ? (
              <TagStyle
                title="Clique para ver mais detalhes"
                onClick={() => this.redirectOnClick(p)}
                backgroundColor={red.primary}
                label={'Atrasada'}
                iconBackground="none"
                iconColor={white.primary}
              />
            ) : (
              <TagStyle
                title="Clique para ver mais detalhes"
                onClick={() => this.redirectOnClick(p)}
                backgroundColor={gray.primary}
                label={'Aberta'}
                iconBackground="none"
                iconColor={white.primary}
              />
            )
          case 'PAID':
            return (
              <TagStyle
                title="Clique para ver mais detalhes"
                onClick={() => this.redirectOnClick(p)}
                backgroundColor={green.laurel}
                label={'Paga'}
                iconBackground="none"
                iconColor={white.primary}
              />
            )
          case 'DEFAULTED':
            return (
              <TagStyle
                disable
                backgroundColor={red.primary}
                label={'Quebrada'}
                iconBackground="none"
                iconColor={yellow.primary}
                onClick={() => this.redirectOnClick(p)}
              />
            )
          default:
            return 'no status'
        }
      },
    },
    {
      attribute: 'method',
      label: 'Forma de Pgto',
      formatCell: (p: RenegotiationPaymentResponse) => {
        if (!p.paymentTimestamp) {
          return '-'
        }
        switch (p.paymentMethod) {
          case 'STORE':
            return 'Loja'
          case 'INVOICE':
            return 'Boleto'
          case 'RENEGOTIATION':
            return 'Renegociação'
          default:
            return '-'
        }
      },
    },
    {
      attribute: 'paymentTimestamp',
      label: 'Data do Pgto',
      formatCell: (p: RenegotiationPaymentResponse) =>
        p.paymentTimestamp ? formatDateTreatingError(p.paymentTimestamp) : '-',
    },
    {
      label: 'ID Iugu',
      formatCell: (p: RenegotiationPaymentResponse) =>
        p.invoices?.length
          ? p.invoices?.reduce((prev, curr) => (prev.id < curr.id ? prev : curr)).partnerInvoiceId ?? '-'
          : '-',
    },
  ]

  getIdsToSimulate = () => {
    const elements = document.getElementsByName('ids')
    let installmentsToSimulate: string[] = []
    elements.forEach(element => {
      const inputElement: HTMLInputElement = element as HTMLInputElement
      if (inputElement.checked) {
        installmentsToSimulate.push(element.id)
      }
    })
    return installmentsToSimulate
  }

  simulateInvoices() {
    const idsToSimulate = this.getIdsToSimulate()
    if (this.props.payments && !(this.props.payments instanceof Error)) {
      if (this.props.payments.length === 0) {
        toaster.showErrorToast('Não há parcelas para simular.')
      } else if (idsToSimulate.length === 0) {
        toaster.showErrorToast('Escolha alguma parcela para simular.')
      } else {
        const paymentsToSimulate = this.props.payments.filter(inst => idsToSimulate.indexOf(inst.id as string) > -1)
        this.setState({ paymentsToSimulate })
        this.setState({ layer: true })
      }
    }
  }

  redirectOnClick = (payment: RenegotiationPaymentResponse) => {
    this.props.history.push({ pathname: `/payments/${payment.id}`, state: { installment: payment } })
  }

  public render() {
    const { payments } = this.props

    const errorCard = (
      <Box margin={{ top: '20px' }}>
        <Text color="red" light>
          Não foi possível carregar os dados para este cliente.
        </Text>
      </Box>
    )

    const PaymentsCard = (payments: RenegotiationPaymentResponse[]) => (
      <DataTable header={this.columnsDropDown} data={payments} fontSize="14px" />
    )

    return (
      <Box>
        {this.state.layer ? (
          <StyledModal
            modalTtitle="Gerar boleto"
            height="large"
            width="xxlarge"
            round="large"
            isOpen={true}
            onClose={() => {
              this.setState({ layer: false })
            }}
          >
            <SimulationsBoxStyle overflow="auto">
              <RenegotiationInvoiceSimulationModal
                renegotiationId={this.props.renegotiationId}
                payments={this.state.paymentsToSimulate}
                numberOfPayments={this.state.paymentsToSimulate.length}
              />
            </SimulationsBoxStyle>
          </StyledModal>
        ) : (
          <></>
        )}

        <Box>
          <TableBoxStyle>
            {!payments ? (
              <Box fill align="center" justify="center">
                <Loading />
              </Box>
            ) : payments instanceof Error ? (
              errorCard
            ) : (
              PaymentsCard(payments)
            )}
          </TableBoxStyle>
          <Box direction="row" align="baseline" justify="between">
            <Box margin={{ horizontal: 'large' }}>
              <HelperTextStyle>*Clique nas tags para ver mais detalhes de um pagamento</HelperTextStyle>
            </Box>
            <SummaryBox>
              <TextSummary style={{ margin: '10 25px 0 0' }}>
                Será gerado: <ValueSummary>{this.state.numberOfInvoices ? 1 : 0} boleto</ValueSummary>
              </TextSummary>
              <TextSummary style={{ margin: '10 25px 0 0' }}>
                Total de <ValueSummary>{formatNumberToBRL(this.state.totalValue)}</ValueSummary>
              </TextSummary>
              <Button style={{ margin: '10 25px 0 0', width: '150px' }} onClick={() => this.simulateInvoices()}>
                GERAR BOLETO
              </Button>
            </SummaryBox>
          </Box>
        </Box>
      </Box>
    )
  }
}
