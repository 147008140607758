import { Button } from '@bit/ume.design-kit.ui'
import * as UMEColors from '@bit/ume.design-kit.ui/utils/_colors'
import { Box, Text } from 'grommet'
import { FormPreviousLink } from 'grommet-icons'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components'
import { LoadingIcon } from '../../atoms/Loading-icon/LoadingIcon'
import { laptop } from '../../common/assets/_breakpoints'
import ApplicationOption from '../../molecules/Applications/ApplicationOption'
import ApplicationsTitle from '../../molecules/Applications/ApplicationsTitle'
import BatchApplicationsBreadcrumbs from '../../molecules/Applications/BatchApplicationsBreadcrumbs'
import UnknownError from '../../organisms/Applications/UnknownError'
import { ApplicationsSliceReducer } from '../../redux/reducers/applications/applications.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'

interface MatchParams {
  batchNumber?: string
}

interface Props extends RouteComponentProps<MatchParams> {}

const BatchApplicationsPage = ({ match, history }: Props) => {
  const dispatch = useDispatch()
  const batchNumber = match.params.batchNumber

  const { batchApplications, fetchBatchApplicationsError, isLoadingBatchApplications } = useTypedSelector(state => ({
    batchApplications: state.applications.batchApplications,
    isLoadingBatchApplications: state.applications.isLoadingBatchApplications,
    fetchBatchApplicationsError: state.applications.fetchBatchApplicationsError,
  }))

  useEffect(() => {
    if (batchNumber) {
      dispatch(ApplicationsSliceReducer.actions.fetchBatchApplications(batchNumber))
    }
  }, [batchNumber, dispatch])

  if (isLoadingBatchApplications) {
    return (
      <Box width="100%" height="90vh">
        <LoadingIcon />
      </Box>
    )
  }

  const handlePreviousButtonClick = () => {
    history.push(`/applications/batches`)
  }

  if (fetchBatchApplicationsError || !batchApplications) {
    if (fetchBatchApplicationsError?.status !== 404) {
      return <UnknownError />
    }
  }
  
  const Content = () => {
    if (fetchBatchApplicationsError?.status === 404) {
      return <Legend isError>Este pacote não possui applications a serem avaliados.</Legend>
    }

    return (
      <>
        <Legend>Selecione um dos applications abaixo apra avaliar.</Legend>
        <ApplicationsContainer>
          {batchApplications?.map(application => (
            <ApplicationOption createdOn={application.createdOn} id={application.id} key={application.id} />
          ))}
        </ApplicationsContainer>
      </>
    )
  }

  return (
    <Container>
      <BatchApplicationsBreadcrumbs batchNumber={batchNumber ? batchNumber : ''} />
      <ApplicationsTitle
        title={`Pacote ${batchNumber}`}
        icon={
          <PreviousButton
            onClick={handlePreviousButtonClick}
            width="32px"
            height="32px"
            style={{ marginLeft: 0 }}
            icon={<FormPreviousLink color="white" />}
          />
        }
      />
      <Content />
    </Container>
  )
}

const Container = styled(Box)`
  margin-top: 2vh;
  margin-bottom: 5vh;
  margin-left: 2vw;
  width: 60%;

  @media (max-width: ${laptop}) {
    width: 95%;
  }
`

const ApplicationsContainer = styled(Box)`
  margin-top: 2vh;
  margin-left: 2vw;
  width: 60%;

  @media (max-width: ${laptop}) {
    width: 95%;
  }
`

const PreviousButton = styled(Button)`
  padding: 0;
`

const Legend = styled(Text)`
  text-align: left;
  margin-top: 16px;
  margin-left: 32px;
  color: ${(props: any) => (props.isError ? UMEColors.red.persian : '#5b5b5b')};
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
`

export default BatchApplicationsPage
