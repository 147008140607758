import { Box } from 'grommet'
import { DocumentUpload, Validate } from 'grommet-icons'
import * as React from 'react'
import { HomeCard } from '../../molecules/Home/HomeCard/HomeCard'
import { Breadcrumbs } from '../../molecules/RiskHome/Breadcrumbs'
import RiskTitle from '../../molecules/RiskHome/Title/RiskTitle'
import { HomeContainerStyle, MainBoxStyle } from './style'

export interface RiskHomeProps {
  history?: any
}

export interface RiskHomeState {}

export default class RiskHome extends React.Component<RiskHomeProps, RiskHomeState> {
  constructor(props: RiskHomeProps) {
    super(props)
    this.state = {}
  }

  public render() {
    return (
      <HomeContainerStyle height="xlarge" gap="small">
        <Box gap="small">
          <Breadcrumbs />
          <RiskTitle />
          <MainBoxStyle>
            <HomeCard subtitle={'Limites'} icon={<DocumentUpload />} to={'/risk/limits'} />
            <HomeCard subtitle={'Opções de Financiamento'} icon={<DocumentUpload />} to={'/risk/financing-options'} />
            <HomeCard subtitle={'Aprovação Forçada'} icon={<Validate />} to={'/risk/forced-approval'} />
          </MainBoxStyle>
        </Box>
      </HomeContainerStyle>
    )
  }
}
