import { Loading, Text } from '@bit/ume.design-kit.ui'
import { blue, green } from '@bit/ume.design-kit.ui/Palette'
import { violet } from '@bit/ume.design-kit.ui/utils/_colors'
import { Box, Grid } from 'grommet'
import * as React from 'react'
import { numberToCurrency } from '../../../common/assets/utils/currencyMask'
import { IBorrowerLimit } from '../../../services/bff-backoffice/coordinator/interfaces/borrower.interfaces'
import { CardStyle, GridHeaderTitleStyle, GridInfoTextStyle, LimitTagStyle } from './style'

export interface ILimitsCardProps {
  limits?: IBorrowerLimit[] | Error
}

export default class LimitsCard extends React.PureComponent<ILimitsCardProps> {
  public render() {
    const { limits } = this.props

    const errorCard = (
      <Text color="red" light>
        Não foi possível carregar os dados para este cliente.
      </Text>
    )

    const limitsCard = (limits: IBorrowerLimit[]) => (
      <Box>
        <Grid
          rows={['32px', '36px', '36px']}
          columns={['140px', '85px', '85px']}
          areas={[
            { name: 'totalTitle', start: [1, 0], end: [1, 0] },
            { name: 'availableTitle', start: [2, 0], end: [2, 0] },
            { name: 'bigPurchaseTitle', start: [0, 1], end: [0, 1] },
            { name: 'recurrenceTitle', start: [0, 2], end: [0, 2] },
            { name: 'bigPurchaseTotalLimit', start: [1, 1], end: [1, 1] },
            { name: 'recurrenceTotalLimit', start: [1, 2], end: [1, 2] },
            { name: 'bigPurchaseAvailableLimit', start: [2, 1], end: [2, 1] },
            { name: 'recurrenceAvailableLimit', start: [2, 2], end: [2, 2] },
          ]}
        >
          <Box gridArea="totalTitle">
            <GridHeaderTitleStyle>Total</GridHeaderTitleStyle>
          </Box>
          <Box gridArea="availableTitle">
            <GridHeaderTitleStyle>Disponível</GridHeaderTitleStyle>
          </Box>
          <Box gridArea="bigPurchaseTitle">
            <LimitTagStyle disable backgroundColor={violet.primary} label="Grandes compras:" />
          </Box>
          <Box gridArea="recurrenceTitle">
            <LimitTagStyle disable backgroundColor={blue.vivid} label="Recorrentes:" />
          </Box>
          <Box gridArea="bigPurchaseTotalLimit">
            <GridInfoTextStyle>
              {numberToCurrency(limits.find(l => l.limitType === 'BIG_PURCHASE')?.approvedLimit || 0, true)}
            </GridInfoTextStyle>
          </Box>
          <Box gridArea="bigPurchaseAvailableLimit">
            <GridInfoTextStyle color={green.darkPastel}>
              {numberToCurrency(limits.find(l => l.limitType === 'BIG_PURCHASE')?.availableLimit || 0, true)}
            </GridInfoTextStyle>
          </Box>
          <Box gridArea="recurrenceTotalLimit">
            <GridInfoTextStyle>
              {numberToCurrency(limits.find(l => l.limitType === 'RECURRING_PURCHASE')?.approvedLimit || 0, true)}
            </GridInfoTextStyle>
          </Box>
          <Box gridArea="recurrenceAvailableLimit">
            <GridInfoTextStyle color={green.darkPastel}>
              {numberToCurrency(limits.find(l => l.limitType === 'RECURRING_PURCHASE')?.availableLimit || 0, true)}
            </GridInfoTextStyle>
          </Box>
        </Grid>
      </Box>
    )

    return (
      <CardStyle>
        {limits === undefined ? (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        ) : limits instanceof Error ? (
          errorCard
        ) : (
          limitsCard(limits)
        )}
      </CardStyle>
    )
  }
}
