import React from 'react'
import { Container, Component } from "./styles"
import { ContactInfo, Search } from "grommet-icons"
import { Heading, TextInput } from "@bit/ume.design-kit.ui"
import { purple } from "@bit/ume.design-kit.ui/utils/_colors"
import { Keyboard } from "grommet"

interface IBorrowerSearchProps {
  borrowerName: string | null
  borrowerCpf: number | null
  getValue: (event: any) => void
  onFocus?: (event: any) => void
  onChange: (event: any) => void
}

const BorrowerSearch = (props: IBorrowerSearchProps) => {
  return(
    <Container>
      <Component>
        <ContactInfo color={purple.primary} />
        <Heading level={3} size="small" margin="0 8px">
          CPF ou Nome
        </Heading>
      </Component>
      <Keyboard onEnter={props.getValue} onTab={props.getValue}>
        <TextInput
          onFocus={props.onFocus}
          onInput={props.onChange}
          value={props.borrowerCpf !== undefined ? props.borrowerCpf : props.borrowerName}
          placeholder="Digite o CPF ou Nome"
          reverse
          icon={<Search style={{ cursor: "pointer", pointerEvents: "visible"}} onClick={props.getValue} />} />
      </Keyboard>
    </Container>
  )
}

export default BorrowerSearch
