import styled from 'styled-components'

export const RegularButton = styled.button`
  margin-left: 1.5em;
  border: 1.4px solid blue;
  color: blue;
  background-color: white;
  border-radius: 5px;
  align-self: flex-end;
  height: 4rem;
  width: 8em;
  transition: 0.3s;
  outline: none;
  cursor: pointer;
  text-align: center;

  :hover {
    background-color: blue;
    color: white;
    transition: 0.3s;
  }
`
export const RegularButtonInsideOut = styled.button`
  margin: 1%;
  border: 1.4px solid white;
  color: white;
  background-color: blue;
  border-radius: 5px;
  margin-left: 2%;
  align-self: flex-end;
  height: 4rem;
  width: 6rem;
  transition: 0.3s;
  outline: none;
  cursor: pointer;
  text-align: center;

  :hover {
    background-color: white;
    color: blue;
    transition: 0.3s;
  }
`
export const ButtonGroupRight = styled.div`
  display: flex;
  flex-direction: row-reverse;
`