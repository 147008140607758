import React from 'react'
import { TextGreenBackground, TextOrangeBackground, TextRedBackground } from '../atoms/Texts/style'
import XLSX from 'xlsx'

export const applicationStatusDictionary: any = {
  APPROVED: <TextGreenBackground>APROVADOS</TextGreenBackground>,
  DENIED: <TextOrangeBackground>NEGADOS</TextOrangeBackground>,
  FAILURE: <TextRedBackground>FALHAS</TextRedBackground>,
  UNDER_ANALYSIS: 'EM ANÁLISE',
}

// Receives a XLSX sheet and returns the header row
export function get_header_row(sheet: any) {
  var headers = []
  var range = XLSX.utils.decode_range(sheet['!ref'])
  var C,
    R = range.s.r /* start in the first row */
  /* walk every column in the range */
  for (C = range.s.c; C <= range.e.c; ++C) {
    var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */

    var hdr = 'UNKNOWN ' + C // <-- replace with your desired default
    if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)

    headers.push(hdr)
  }
  return headers
}

// Returns a array of all object's keys
// Ex: [{ borrowerId, limitType  }, { limitValue }]
//    returns: [ 'borrowerId', 'limitType', 'limitValue' ]
export const getArrayOfObjectsKeys = (array: any[]) => {
  const header: any = {}
  for (const el of array) {
    for (const key in el) {
      header[key] = true
    }
  }
  return Object.keys(header)
}
