import moment from 'moment'
import React, { Component } from 'react'
import XLSX from 'xlsx'
import { toaster } from '../../App'
import { RegularButtonInsideOut } from '../../atoms/Buttons/style'
import { FlexCenterContainer, OverflowXContainer, WhiteContainer } from '../../atoms/Containers/style'
import Loading from '../../atoms/Loading/Loading'
import { INVALID_DATE, MENU_NOT_SELECTED, START_DATE_AFTER_END_DATE } from '../../config/messages/errors'
import { DateRange, GenericTable } from '../../molecules'
import { bffBackofficeApiService } from '../../services/bff-backoffice'
import { BACKEND_DATE_FORMAT, REGULAR_DATE_FORMAT } from '../../utils/index'
import { Configs } from './configs'
import { IReportsState } from './interfaces'

const options = [
  { label: 'Selecione uma opção' },
  { label: 'Pagamentos', route: '/payments' },
  { label: 'Originações', route: '/originations' },
  { label: 'Originações (operações)', route: '/originations/operations' },
  { label: 'Applications', route: '/applications' },
  { label: 'Ranking Operadores', route: '/operators' },
  { label: 'Ranking Lojas', route: '/stores' },
  { label: 'Vencimentos', route: '/installments/operations' },
]

export default class Reports extends Component<any, IReportsState> {
  constructor(props: any) {
    super(props)
    this.state = {
      payload: null,
      loading: false,
      startDate: null,
      endDate: null,
      history: [],
    }
  }

  handleDownloadPayload = async () => {
    let { history } = this.state
    let actualData = history[history.length - 1]

    const ws = XLSX.utils.json_to_sheet(actualData.payload)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, actualData.option)
    XLSX.writeFile(
      wb,
      `${actualData.option.toLowerCase()}_INICIO_${actualData.startDate}_FIM_${actualData.endDate}.xlsx`
    )
  }

  startDateChanged = (event: any) => {
    this.setState({ startDate: event.target.value })
  }

  endDateChanged = (event: any) => {
    this.setState({ endDate: event.target.value })
  }

  renderPayload = () => {
    let { history } = this.state
    if (!history || history.length === 0) return
    let data = history[history.length - 1]

    let startDate = data.startDate
    let endDate = data.endDate
    let payload = data.payload
    let option = data.option

    let searchLabel =
      startDate === endDate
        ? `${option} feitos no dia de hoje - ${startDate}`
        : `${option} feitos entre ${startDate} e ${endDate}`
    if (!payload || !Array.isArray(payload))
      return (
        <div>
          <h2> {searchLabel} </h2>
          <h2> Erro na comunicação com o servidor </h2>
        </div>
      )
    return (
      <>
        <FlexCenterContainer>
          <div>
            <h2>{searchLabel}</h2>
            <h3>{`Total: ${payload.length} ${option.toLowerCase()}.`}</h3>
            <h3>{payload.length > 100 && 'Tabela possui mais de 100 linhas, apenas download está disponível.'}</h3>
          </div>
          <RegularButtonInsideOut onClick={this.handleDownloadPayload}> Download </RegularButtonInsideOut>
        </FlexCenterContainer>
        {payload.length <= 100 && (
          <div>
            <h2>{searchLabel}</h2>
          </div>
        )}

        {payload.length <= 100 && <GenericTable header={Configs[option].header} data={payload} />}
      </>
    )
  }

  handleSearch = async (startDate: string | null, endDate: string | null, option: string) => {
    if (!startDate || !endDate) return toaster.showErrorToast(INVALID_DATE)
    else if (!option) return toaster.showErrorToast(MENU_NOT_SELECTED)

    // Check if endDate is before startDate
    let a = moment(startDate, REGULAR_DATE_FORMAT)
    let b = moment(endDate, REGULAR_DATE_FORMAT)
    if (a > b) return toaster.showErrorToast(START_DATE_AFTER_END_DATE)

    let { history } = this.state

    let route = options.find(el => option === el.label)

    if (!route || !route.route) return toaster.showErrorToast(MENU_NOT_SELECTED)

    this.setState({ loading: true })

    let result
    const params = {
      startDate: moment(a).format(BACKEND_DATE_FORMAT),
      endDate: moment(b).format(BACKEND_DATE_FORMAT),
    }

    switch (option) {
      case 'Pagamentos':
        result = await bffBackofficeApiService.backoffice.getPayments(params)
        break
      case 'Originações':
        result = await bffBackofficeApiService.backoffice.getOriginationsViewOperations(params)
        break
      case 'Originações (operações)':
        result = await bffBackofficeApiService.backoffice.getOriginationsViewForOperations(params)
        break
      case 'Applications':
        result = await bffBackofficeApiService.backoffice.getApplications(params)
        break
      case 'Ranking Operadores':
        result = await bffBackofficeApiService.backoffice.getOperators(params)
        break
      case 'Ranking Lojas':
        result = await bffBackofficeApiService.backoffice.getStores(params)
        break
      case 'Vencimentos':
        result = await bffBackofficeApiService.backoffice.getInstallments()
        break
      default:
        this.setState({ loading: false })
        return toaster.showErrorToast(MENU_NOT_SELECTED)
    }

    history = history.concat({ startDate, endDate, payload: result, option: option })
    this.setState({ loading: false, history: history })

    if (!result) return

    this.setState({ payload: result })
  }

  render() {
    const { loading } = this.state

    return (
      <WhiteContainer>
        <DateRange options={options} onSearch={this.handleSearch} />
        <OverflowXContainer>{loading ? <Loading /> : this.renderPayload()}</OverflowXContainer>
      </WhiteContainer>
    )
  }
}
