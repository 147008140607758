import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { bffBackofficeApiService } from '../../../services/bff-backoffice'
import {
  ApplicationBatchesResponse,
  IApplicationResponse,
  IBatchApplicationOptionsResponse,
  IScoresResponse,
} from '../../../services/bff-backoffice/application-engine/interfaces/application-engine.interfaces'
import { IBorrowerResponse } from '../../../services/bff-backoffice/borrowers/interfaces/borrower.interfaces'
import {
  IDocumentImageResponse,
  IImageResponse,
  IProcessStatusResponse,
} from '../../../services/bff-backoffice/datalake-acesso/interfaces/datalake-acesso.interfaces'
import { ApplicationsSliceReducer, EvaluateApplicationOnlineAction } from './applications.reducer'

function* fetchApplication(action: PayloadAction<string>) {
  try {
    const applicationId = action.payload
    yield put(ApplicationsSliceReducer.actions.fetchApplicationLoading())
    const result: IApplicationResponse = yield bffBackofficeApiService.applicationEngine.getApplicationById(
      applicationId
    )
    yield put(ApplicationsSliceReducer.actions.fetchApplicationSuccess(result))

    yield put(ApplicationsSliceReducer.actions.fetchBorrower(result.borrowerId))
  } catch (error) {
    yield put(ApplicationsSliceReducer.actions.fetchApplicationError(error))
  }
}

function* fetchApplicationScores(action: PayloadAction<string>) {
  try {
    const applicationId = action.payload
    yield put(ApplicationsSliceReducer.actions.fetchApplicationScoresLoading())
    const result: IScoresResponse = yield bffBackofficeApiService.applicationEngine.getScoresFromApplication(
      applicationId
    )
    yield put(ApplicationsSliceReducer.actions.fetchApplicationScoresSuccess(result))
  } catch (error) {
    yield put(ApplicationsSliceReducer.actions.fetchApplicationScoresError(error))
  }
}

function* fetchBorrower(action: PayloadAction<string>) {
  try {
    const borrowerId = action.payload
    yield put(ApplicationsSliceReducer.actions.fetchBorrowerLoading())
    const result: IBorrowerResponse = yield bffBackofficeApiService.borrowers.getBorrowerById(borrowerId)
    yield put(ApplicationsSliceReducer.actions.fetchBorrowerSuccess(result))
  } catch (error) {
    yield put(ApplicationsSliceReducer.actions.fetchBorrowerError(error))
  }
}

function* fetchBorrowerFaceImage(action: PayloadAction<string>) {
  try {
    const borrowerId = action.payload
    yield put(ApplicationsSliceReducer.actions.fetchBorrowerFaceImageLoading())
    const result: IImageResponse = yield bffBackofficeApiService.datalakeAcesso.getBorrowerFaceImage(borrowerId)
    yield put(ApplicationsSliceReducer.actions.fetchBorrowerFaceImageSuccess(result))
  } catch (error) {
    yield put(ApplicationsSliceReducer.actions.fetchBorrowerFaceImageError(error))
  }
}

function* fetchBorrowerDocumentImages(action: PayloadAction<string>) {
  try {
    const borrowerId = action.payload
    yield put(ApplicationsSliceReducer.actions.fetchBorrowerDocumentImagesLoading())
    const result: IDocumentImageResponse = yield bffBackofficeApiService.datalakeAcesso.getBorrowerDocumentImage(
      borrowerId
    )
    yield put(ApplicationsSliceReducer.actions.fetchBorrowerDocumentImagesSuccess(result))
  } catch (error) {
    yield put(ApplicationsSliceReducer.actions.fetchBorrowerDocumentImagesError(error))
  }
}

function* evaluateApplicationOnline(action: PayloadAction<EvaluateApplicationOnlineAction>) {
  try {
    throw new Error()
  } catch (error) {
    yield put(ApplicationsSliceReducer.actions.evaluateApplicationOnlineError(error))
  }
}

function* fetchBatches(action: PayloadAction<undefined>) {
  try {
    yield put(ApplicationsSliceReducer.actions.fetchBatchesLoading())
    const result: ApplicationBatchesResponse[] = yield bffBackofficeApiService.applicationEngine.getApplicationsBatches()
    yield put(ApplicationsSliceReducer.actions.fetchBatchesSuccess(result))
  } catch (error) {
    yield put(ApplicationsSliceReducer.actions.fetchBatchesError(error))
  }
}

function* fetchBatchApplications(action: PayloadAction<string>) {
  try {
    const batchNumber = action.payload
    yield put(ApplicationsSliceReducer.actions.fetchBatchApplicationsLoading())
    const result: IBatchApplicationOptionsResponse[] = yield bffBackofficeApiService.applicationEngine.getAppicationsByBatchNumber(
      batchNumber
    )
    yield put(ApplicationsSliceReducer.actions.fetchBatchApplicationsSuccess(result))
  } catch (error) {
    yield put(ApplicationsSliceReducer.actions.fetchBatchApplicationsError(error))
  }
}

function* fetchBiometryStatus(action: PayloadAction<string>) {
  try {
    const borrowerId = action.payload
    yield put(ApplicationsSliceReducer.actions.fetchBiometryStatusLoading())
    const result: IProcessStatusResponse = yield bffBackofficeApiService.datalakeAcesso.patchBorrowerProcessStatus(
      borrowerId
    )
    yield put(ApplicationsSliceReducer.actions.fetchBiometryStatusSuccess(result))
  } catch (error) {
    yield put(ApplicationsSliceReducer.actions.fetchBiometryStatusError(error))
  }
}

const applicationsSaga = [
  takeLatest(ApplicationsSliceReducer.actions.fetchApplication, fetchApplication),
  takeLatest(ApplicationsSliceReducer.actions.fetchApplicationScores, fetchApplicationScores),
  takeLatest(ApplicationsSliceReducer.actions.fetchBorrower, fetchBorrower),
  takeLatest(ApplicationsSliceReducer.actions.fetchBorrowerFaceImage, fetchBorrowerFaceImage),
  takeLatest(ApplicationsSliceReducer.actions.fetchBorrowerDocumentImages, fetchBorrowerDocumentImages),
  takeLatest(ApplicationsSliceReducer.actions.evaluateApplicationOnline, evaluateApplicationOnline),
  takeLatest(ApplicationsSliceReducer.actions.fetchBatches, fetchBatches),
  takeLatest(ApplicationsSliceReducer.actions.fetchBatchApplications, fetchBatchApplications),
  takeLatest(ApplicationsSliceReducer.actions.fetchBiometryStatus, fetchBiometryStatus),
]

export default applicationsSaga
