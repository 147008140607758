import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import { IBorrowerResponse, IImageResponse, IPhoneResponse } from './interfaces/borrower.interfaces'

export default class BorrowersService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async getBorrowerByDocument(document: string): Promise<IBorrowerResponse> {
    const response = await this.axiosInstance.get<IBorrowerResponse>(`/borrowers/find-one`, {
      params: { cpf: document },
    })
    return response.data
  }

  async getBorrowerPhotos(borrowerId: string): Promise<IImageResponse[]> {
    const response = await this.axiosInstance.get<IImageResponse[]>(`/borrowers/${borrowerId}/images`)
    return response.data
  }

  async getBorrowerPortraitPhoto(borrowerId: string): Promise<IImageResponse> {
    const response = await this.axiosInstance.get<IImageResponse>(`/borrowers/${borrowerId}/images/portrait`)
    return response.data
  }

  async createBorrowerPhoneNumber(borrowerId: string, phoneNumber: string): Promise<IPhoneResponse> {
    const body = { phoneNumber }
    const response = await this.axiosInstance.post<IPhoneResponse>(`/borrowers/${borrowerId}/phone-number`, body)
    return response.data
  }

  async getBorrowerById(borrowerId: string): Promise<IBorrowerResponse> {
    const response = await this.axiosInstance.get<IBorrowerResponse>(`/borrowers/${borrowerId}`)
    return response.data
  }
}
