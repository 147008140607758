import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { bffBackofficeApiService } from '../../../services/bff-backoffice'
import { IOriginationsParametersResponse, IPutOriginationsParameters } from '../../../services/bff-backoffice/coordinator/interfaces/parameters.interfaces'
import { IGetRetailerMdrRatesResponse } from '../../../services/bff-backoffice/liquidation-originations/interfaces/liquidation-originations.interfaces'
import { 
  IDebtFundingPackResponse, 
  IRetailerCategoriesResponse, 
  IRetailerResponse, 
  IRetailersResponse, 
  PostRetailerRequest
} from '../../../services/bff-backoffice/retailers/interfaces/retailers.interfaces'
import { selectState } from '../selectors'
import {
  EditRetailerBlockPayload,
  EditRetailerCategoriesPayload,
  PutRetailerLogo,
  RetailersSliceReducer,
} from './retailers.reducer'

function* fetchRetailers(action: PayloadAction<undefined>) {
  try {
    const retailers: IRetailersResponse = yield bffBackofficeApiService.retailers.getRetailers()
    yield put(RetailersSliceReducer.actions.fetchRetailersSuccess(retailers.retailers))
  } catch (error) {
    yield put(RetailersSliceReducer.actions.fetchRetailersError(error))
  }
}

function* fetchRetailer(action: PayloadAction<string>) {
  try {
    const retailerId = action.payload
    const retailer: IRetailerResponse = yield bffBackofficeApiService.retailers.getRetailerById(retailerId)
    yield put(RetailersSliceReducer.actions.fetchRetailerSuccess(retailer))
  } catch (error) {
    yield put(RetailersSliceReducer.actions.fetchRetailerError(error))
  }
}

function* fetchRetailerMdr(action: PayloadAction<string>) {
  try {
    const retailerId = action.payload
    const mdrRates: IGetRetailerMdrRatesResponse = yield bffBackofficeApiService.liquidationOriginations.getRetailerMdrRates(
      retailerId
    )
    yield put(RetailersSliceReducer.actions.fetchRetailerMdrSuccess(mdrRates))
  } catch (error) {
    yield put(RetailersSliceReducer.actions.fetchRetailerMdrError(error))
  }
}

function* updateRetailerStatus(action: PayloadAction<string>) {
  try {
    const retailerId = action.payload
    yield put(RetailersSliceReducer.actions.updateRetailerStatusLoading())
    const retailer: IRetailerResponse = yield bffBackofficeApiService.retailers.updateRetailerStatus(retailerId)
    yield put(RetailersSliceReducer.actions.updateRetailerStatusSuccess(retailer))
  } catch (error) {
    yield put(RetailersSliceReducer.actions.updateRetailerStatusError(error))
  }
}

function* putOriginationsParameters(action: PayloadAction<IPutOriginationsParameters>) {
  try {
    const originationParameters = action.payload
    yield put(RetailersSliceReducer.actions.putOriginationsParametersLoading())
    const createdOriginationParameters: IOriginationsParametersResponse = yield bffBackofficeApiService.coordinator.putOriginationsParameters(
      originationParameters
    )
    yield put(RetailersSliceReducer.actions.putOriginationsParametersSuccess(createdOriginationParameters))
  } catch (error) {
    yield put(RetailersSliceReducer.actions.putOriginationsParametersError(error))
  }
}

function* fetchOriginationsParameters(action: PayloadAction<string>) {
  try {
    const retailerId = action.payload
    const createdOriginationParameters: IOriginationsParametersResponse = yield bffBackofficeApiService.coordinator.getOriginationsParameters({
      retailerId,
    })
    yield put(RetailersSliceReducer.actions.fetchOriginationsParametersSuccess(createdOriginationParameters))
  } catch (error) {
    yield put(RetailersSliceReducer.actions.fetchOriginationsParametersError(error))
  }
}

function* fetchCategories(action: PayloadAction<undefined>) {
  try {
    const data: IRetailerCategoriesResponse = yield bffBackofficeApiService.retailers.getCategories()
    yield put(RetailersSliceReducer.actions.fetchCategoriesSuccess(data.categories))
  } catch (error) {
    yield put(RetailersSliceReducer.actions.fetchCategoriesError(error))
  }
}

function* fetchBlocks(action: PayloadAction<undefined>) {
  try {
    const data: IDebtFundingPackResponse[] = yield bffBackofficeApiService.retailers.getDebtFundingPacks()

    yield put(RetailersSliceReducer.actions.fetchBlocksSuccess(data))
  } catch (error) {
    yield put(RetailersSliceReducer.actions.fetchBlocksError(error))
  }
}

function* putRetailerLogo(action: PayloadAction<PutRetailerLogo>) {
  const retailerId = action.payload.retailerId
  try {
    yield put(RetailersSliceReducer.actions.putRetailerLogoLoading())
    const logo = action.payload.logo
    yield bffBackofficeApiService.retailers.putRetailerLogo(retailerId, { logo })
    yield put(RetailersSliceReducer.actions.putRetailerLogoSuccess())
  } catch (error) {
    yield put(RetailersSliceReducer.actions.putRetailerLogoError(error))
  }
}

function* createRetailer(action: PayloadAction<PostRetailerRequest>) {
  try {
    const body = action.payload
    console.log(body)
    yield put(RetailersSliceReducer.actions.createRetailerLoading())
    const retailer: IRetailerResponse = yield bffBackofficeApiService.retailers.createRetailer(body)
    yield put(RetailersSliceReducer.actions.createRetailerSuccess(retailer))
    yield put(RetailersSliceReducer.actions.fetchRetailers())
  } catch (error) {
    yield put(RetailersSliceReducer.actions.createRetailerError(error))
  }
}

function* editRetailerCategories(action: PayloadAction<EditRetailerCategoriesPayload>) {
  try {
    const { retailerId, categories } = action.payload
    yield put(RetailersSliceReducer.actions.editRetailerCategoriesLoading())
    const retailer: IRetailerResponse = yield bffBackofficeApiService.retailers.putRetailerCategories(retailerId, {
      categoryId: categories,
    })
    yield put(RetailersSliceReducer.actions.editRetailerCategoriesSuccess(retailer))
    yield put(RetailersSliceReducer.actions.editRetailerCategoriesModalClose())
  } catch (error) {
    yield put(RetailersSliceReducer.actions.editRetailerCategoriesError(error))
  }
}

function* editRetailerBlock(action: PayloadAction<EditRetailerBlockPayload>) {
  try {
    const { retailerId, debtFundingPack } = action.payload

    yield bffBackofficeApiService.retailers.updateRetailerDebtFundingPackId(retailerId , debtFundingPack.id )

    const retailer: IRetailerResponse = yield selectState(state => state.retailers.retailer)

    yield put(RetailersSliceReducer.actions.editRetailerBlockSuccess({ ...retailer, debtFundingPack }))
  } catch (error) {
    yield put(RetailersSliceReducer.actions.editRetailerBlockError(error))
  }
}

const retailersSaga = [
  takeLatest(RetailersSliceReducer.actions.fetchRetailers, fetchRetailers),
  takeLatest(RetailersSliceReducer.actions.fetchRetailer, fetchRetailer),
  takeLatest(RetailersSliceReducer.actions.fetchRetailerMdr, fetchRetailerMdr),
  takeLatest(RetailersSliceReducer.actions.updateRetailerStatus, updateRetailerStatus),
  takeLatest(RetailersSliceReducer.actions.putOriginationsParameters, putOriginationsParameters),
  takeLatest(RetailersSliceReducer.actions.fetchOriginationsParameters, fetchOriginationsParameters),
  takeLatest(RetailersSliceReducer.actions.fetchCategories, fetchCategories),
  takeLatest(RetailersSliceReducer.actions.putRetailerLogo, putRetailerLogo),
  takeLatest(RetailersSliceReducer.actions.createRetailer, createRetailer),
  takeLatest(RetailersSliceReducer.actions.editRetailerCategories, editRetailerCategories),
  takeLatest(RetailersSliceReducer.actions.editRetailerBlock, editRetailerBlock),
  takeLatest(RetailersSliceReducer.actions.fetchBlocks, fetchBlocks),
]

export default retailersSaga
